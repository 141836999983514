import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import LockIcon from '@material-ui/icons/LockOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ShowPWIcon from '@material-ui/icons/VisibilityOutlined';
import HidePWIcon from '@material-ui/icons/VisibilityOffOutlined';

import loginBackground from 'public/login.jpg';
import { getText } from 'src/utils/MultilingualLoader';
import Notifications from '../Main/Notifications';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    height: '100vh',
    background: `
      url(${loginBackground})
      no-repeat
      center
      center
      fixed
    `,
    webkitBackgroundSize: 'cover',
    mozBackgroundSize: 'cover',
    oBackgroundSize: 'cover',
    backgroundSize: 'cover',
  },
  paper: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(16),
    },
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    backgroundColor: 'RGB(255, 255, 255, 0.8)',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

const SignIn = ({
  classes,
  username,
  password,
  showPW,
  onSubmit,
  onChange,
  onClick
}) => (
    <div className={classes.root}>
      <CssBaseline />
      <Notifications />
      <main className={classes.content}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            {getText('sign_in')}
          </Typography>

          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="username">{getText('username')}</InputLabel>
              <Input
                id="username"
                name="username"
                autoFocus={true}
                value={username}
                onChange={onChange('username')}
                type="email"
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">{getText('password')}</InputLabel>
              <Input
                id="password"
                name="password"
                type={showPW ? "text" : "password"}
                autoComplete="password"
                value={password}
                onChange={onChange('password')}
                endAdornment={(
                  <InputAdornment position="end">
                    {
                      showPW
                      ? <IconButton onClick={onClick(false)}>
                          <HidePWIcon />
                        </IconButton>
                      : <IconButton onClick={onClick(true)}>
                          <ShowPWIcon />
                        </IconButton>
                    }
                  </InputAdornment>
                )}
              />
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              className={classes.submit}
              onClick={onSubmit}
            >
              {getText('sign_in')}
          </Button>
          </form>
        </Paper>
      </main>
    </div>
  );

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SignIn);
