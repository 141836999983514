import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PrevPageIcon from '@material-ui/icons/NavigateBefore';
import NextPageIcon from '@material-ui/icons/NavigateNext';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';


export const sidePanelWidth = 300;

const styles = theme => ({
    root:{
        backgroundColor: theme.palette.grey[200],
    },
    IconButton: {
        paddingLeft:  10,
        paddingRight: 10
    },
    input:{
      height:10,
      width:30,
    },
});

const RoutineHistoryTimeList = ({classes,handleChange,limit,handlePrevPageClick,handleNextPageClick,noPrev,noNext}) => {

  const options = [10,20,50]
  
  return (
    <Grid className={classes.root}>
        <span>&nbsp;&nbsp;Size &nbsp;</span>
        <Select 
          style ={{
            height:30
          }}
          variant="outlined"
          input={<OutlinedInput labelWidth={0} classes={{ input: classes.input }} />}
          value={limit}
          onChange={handleChange}
        >
          {options.map((option, index) =>
            <MenuItem key={index} value={option}>{option}</MenuItem>
          )}
        </Select>
        <IconButton 
            className={classes.IconButton}
            onClick={handlePrevPageClick}
            disabled={noPrev}
          >
          <PrevPageIcon />
        </IconButton>
        <IconButton
            className={classes.IconButton}
            onClick={handleNextPageClick}
            disabled={noNext}
          >
          <NextPageIcon />
        </IconButton>
    </Grid>
  );
};  

export default withStyles(styles)(RoutineHistoryTimeList);
