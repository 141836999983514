import React, { Component } from 'react';
import {
  InputLabel, Grid, Button, TextField, Typography, FormControl, Select, MenuItem, FormHelperText, Switch, withStyles,
  Dialog, DialogTitle, DialogContent, DialogActions, Backdrop, CircularProgress, FormControlLabel
} from '@material-ui/core';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { notificationActions, targetActions, robotActions } from 'src/redux/actions';
import { getText } from 'src/utils/MultilingualLoader';
import * as siteServices from 'src/services/site';
import RobotMap from '../../../Robots/RobotPanel/RobotConfigRoutine/RobotMap';

const styles = theme => ({
  formItem: {
    display: 'block',
    width: 300,
    marginBottom: 15,
  },
  backdrop: {
    zIndex: 9999,
  },
  switch: {
    marginLeft: 0
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    '&:hover': {
      cursor: 'pointer'
    }
  },
});

const maxMapWidth = window.innerWidth * 0.8;
const maxMapHeight = window.innerHeight * 0.9;

const mapStateToProps = state => {
  return {
    targets: state.target.targetOptions,
    robots: state.robot.robots,
    maps: state.robot.robotMapLists,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addNotification: (message) => {
      dispatch(notificationActions.addNotification(message));
    },
    getTargetOptions: (siteId) => {
      dispatch(targetActions.getTargetOptions(siteId))
    },
    getSiteRobots: (siteId) => {
      dispatch(robotActions.getRobots(siteId))
    },
    getRobotMapList: robotId => {
      dispatch(robotActions.getRobotConfigMapsAndRoutes(robotId));
    }
  };
};

class AddFloor extends Component {
  constructor(props) {
    super(props);
    const { floor, building } = props;
    this.state = {
      submitting: false,
      form: {
        name: floor ? floor.name : '',
        floor: floor ? floor.floor : 1,
        building_id: floor ? floor.building : building.id,
        robot_id: floor ? floor.params.robot_id : '',
        map_id: floor ? floor.map_id : '',
        routes: floor ? (floor.params.routes || []).map(v => v.id) : [],
        reset_slam: floor ? floor.params.reset_slam : false,
        delay_secs: floor ? floor.params.delay_secs : '',
        x: floor ? floor.params.x : '',
        y: floor ? floor.params.y : '',
        theta: floor ? floor.params.theta : '',
        near_elevator: floor ? floor.params.near_elevator : '',
        near_home: floor ? floor.params.near_home : ''
      },
      validate: {
      },
      mapOpen: false,
    }
  }

  componentDidMount() {
    const { building, maps, targets, getSiteRobots, getRobotMapList, getTargetOptions } = this.props;
    const { form } = this.state;
    getSiteRobots(building.site_id);
    console.log(form);
    if (form.robot_id && !maps[form.robot_id]) {
      getRobotMapList(form.robot_id)
    }
    if (!targets[building.site_id]) {
      getTargetOptions(building.site_id)
    }
  }

  handleChange = (key) => {
    return (event) => {
      const { maps, getRobotMapList } = this.props;
      const { form } = this.state;
      console.log(event.target.value);
      const newValues = {
        ...form,
        [key]: event && event.target ? event.target.value : event,
      };
      if (key === 'robot_id') {
        newValues.map_id = '';
        newValues.routes = [];
        if (newValues.robot_id && !maps[newValues.robot_id]) {
          getRobotMapList(newValues.robot_id)
        }
      } else if (key === 'map_id') {
        newValues.routes = [];
      }
      this.setState({
        form: newValues
      })
    };
  }

  handleChecked = (key) => {
    return (event) => {
      const { form } = this.state;
      const newValues = {
        ...form,
        [key]: event.target.checked,
      };
      if (key === 'reset_slam' && newValues[key] === false) {
        newValues.delay_secs = '';
        newValues.x = '';
        newValues.y = '';
        newValues.theta = '';
      }
      this.setState({
        form: newValues
      })
    };
  }

  openMapModal = () => {
    this.setState({ mapOpen: true });
  }

  handleChangeResetSlamParams = (x, y, theta) => {
    this.setState({
      form: {
        ...this.state.form,
        x,
        y,
        theta
      },
      mapOpen: false,
    })
  }

  closeMapModal = () => {
    this.setState({ mapOpen: false });
  }

  validator = () => {
    const { form } = this.state;
    let hasError = false;
    const validate = {};
    if (!form.name || !form.name.trim()) {
      validate.name = getText('required');
      hasError = true;
    }
    if (!form.floor) {
      validate.floor = getText('required');
      hasError = true;
    } else if (isNaN(Number(form.floor))) {
      validate.floor = getText('incorrect_format');
      hasError = true;
    }
    if (!form.robot_id) {
      validate.robot_id = getText('required');
      hasError = true;
    }
    if (!form.map_id) {
      validate.map_id = getText('required');
      hasError = true;
    }
    if (!form.routes || form.routes.length === 0) {
      validate.routes = getText('required');
      hasError = true;
    }
    if (form.reset_slam) {
      if (!form.delay_secs) {
        validate.delay_secs = getText('required');
        hasError = true;
      } else if (isNaN(Number(form.delay_secs))) {
        validate.delay_secs = getText('incorrect_format');
        hasError = true;
      }
      if (!form.x) {
        validate.x = getText('required');
        hasError = true;
      } else if (isNaN(Number(form.x))) {
        validate.x = getText('incorrect_format');
        hasError = true;
      }
      if (!form.y) {
        validate.y = getText('required');
        hasError = true;
      } else if (isNaN(Number(form.y))) {
        validate.y = getText('incorrect_format');
        hasError = true;
      }
      if (!form.theta) {
        validate.theta = getText('required');
        hasError = true;
      } else if (isNaN(Number(form.theta))) {
        validate.theta = getText('incorrect_format');
        hasError = true;
      }
    }

    this.setState({ validate });
    return hasError;
  }

  handleSubmit = async () => {
    const hasError = this.validator();
    if (hasError) {
      return;
    }

    const { floor, onClose, maps, addNotification } = this.props;
    const { form } = this.state;
    const map = maps[form.robot_id].maps.find(v => v.id === form.map_id);
    const routes = map.mapRoutes.filter(v => form.routes.includes(v.id)).map(v => ({ id: v.id, name: v.name }));
    const data = {
      name: form.name.trim(),
      floor: Number(form.floor),
      building_id: form.building_id,
      map_id: form.map_id,
      params: {
        ...(floor ? floor.params : {}),
        map_name: map.name,
        robot_id: form.robot_id,
        routes,
        reset_slam: form.reset_slam,
        delay_secs: form.reset_slam ? Number(form.delay_secs) : 0,
        x: form.reset_slam ? Number(form.x) : 0,
        y: form.reset_slam ? Number(form.y) : 0,
        theta: form.reset_slam ? Number(form.theta) : 0,
        near_elevator: form.near_elevator || undefined,
        near_home: form.near_home || undefined,
      }
    };

    this.setState({ submitting: true });
    if (floor) {
      try {
        await siteServices.updateSiteFloor(floor.id, data);
        addNotification(`${getText('update')}${getText('words_space')}${getText('floor')}${getText('words_space')}${getText('succeeded')}`);
        onClose(true);
      } catch (e) {
        addNotification(`${getText('update')}${getText('words_space')}${getText('floor')}${getText('words_space')}${getText('failed')}`);
      }
    } else {
      try {
        await siteServices.createSiteFloor(data);
        addNotification(`${getText('add')}${getText('words_space')}${getText('floor')}${getText('words_space')}${getText('succeeded')}`);
        onClose(true);
      } catch (e) {
        addNotification(`${getText('add')}${getText('words_space')}${getText('floor')}${getText('words_space')}${getText('failed')}`);
      }
    }
    this.setState({ submitting: false });
  }

  render() {
    const { floor, onClose, maps, targets, robots, building, classes } = this.props;
    const { submitting, form, validate, mapOpen } = this.state;
    const mapOptions = form.robot_id && maps[form.robot_id] ? maps[form.robot_id].maps || [] : [];
    const selectedMap = form.map_id ? mapOptions.find(v => v.id === form.map_id) : null;
    const routeOptions = selectedMap ? selectedMap.mapRoutes || [] : [];
    const targetOptions = targets[building.site_id] || [];

    return (
      <>
        <Dialog
          open={true}
          onClose={() => { onClose() }}
        >
          <DialogTitle>
            <Grid container justify="space-between" alignItems="center">
              <Typography variant="h5">
                {getText(floor ? 'edit_floor' : 'add_floor')}
              </Typography>
              <CloseIcon
                onClick={() => { onClose() }}
              />
            </Grid>
          </DialogTitle>
          <DialogContent>
            <TextField
              label="Name"
              required
              value={form.name}
              onChange={this.handleChange('name')}
              error={!!validate.name}
              helperText={validate.name}
              fullWidth
              className={classes.formItem}
            />
            <TextField
              label="Floor"
              required
              type="number"
              value={form.floor}
              onChange={this.handleChange('floor')}
              error={!!validate.floor}
              helperText={validate.floor}
              fullWidth
              className={classes.formItem}
              placeholder={getText('number')}
            />
            <FormControl error={!!validate.map_id} fullWidth required className={classes.formItem}>
              <InputLabel id="robot_id">{getText('robot')}</InputLabel>
              <Select
                labelId="robot_id"
                fullWidth
                value={form.robot_id}
                onChange={this.handleChange('robot_id')}
              >
                {(robots || []).map(map => (
                  <MenuItem key={map.id} value={map.id}>{map.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{validate.map_id}</FormHelperText>
            </FormControl>
            <FormControl error={!!validate.map_id} fullWidth required className={classes.formItem}>
              <InputLabel id="map_id">{getText('map')}</InputLabel>
              <Select
                labelId="map_id"
                fullWidth
                value={form.map_id}
                onChange={this.handleChange('map_id')}
              >
                {(mapOptions || []).map(map => (
                  <MenuItem key={map.id} value={map.id}>{map.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{validate.map_id}</FormHelperText>
            </FormControl>
            <FormControl error={!!validate.routes} fullWidth required className={classes.formItem}>
              <InputLabel id="routes">{getText('route')}</InputLabel>
              <Select
                labelId="routes"
                fullWidth
                value={form.routes}
                onChange={this.handleChange('routes')}
                multiple
              >
                {routeOptions.map(route => (
                  <MenuItem key={route.id} value={route.id}>{route.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{validate.routes || getText('floor_routes_tip')}</FormHelperText>
            </FormControl>
            <div style={{ marginBottom: 10 }}>
              <FormControlLabel
                control={<Switch color="primary" checked={form.reset_slam} onChange={this.handleChecked('reset_slam')} />}
                label={<span style={{ color: 'rgba(0,0,0,0.54)' }}>{getText('reset_slam')}</span>}
                labelPlacement="start"
                className={classes.switch}
              />
              &nbsp;
              <Button
                variant="contained"
                color="primary"
                onClick={this.openMapModal}
                disabled={!form.reset_slam || !selectedMap || !form.routes || !form.routes[0]}
              >
                <Typography variant="body2">
                  {getText('locate_on_map')}
                </Typography>
              </Button>
            </div>
            {form.reset_slam && (
              <>
                <TextField
                  label="delay_secs"
                  type="number"
                  value={form.delay_secs}
                  onChange={this.handleChange('delay_secs')}
                  error={!!validate.delay_secs}
                  helperText={validate.delay_secs}
                  fullWidth
                  required
                  className={classes.formItem}
                />
                <TextField
                  label="x"
                  type="number"
                  value={form.x}
                  onChange={this.handleChange('x')}
                  error={!!validate.x}
                  helperText={validate.x}
                  fullWidth
                  required
                  className={classes.formItem}
                />
                <TextField
                  label="y"
                  type="number"
                  value={form.y}
                  onChange={this.handleChange('y')}
                  error={!!validate.y}
                  helperText={validate.y}
                  fullWidth
                  required
                  className={classes.formItem}
                />
                <TextField
                  label="theta"
                  type="number"
                  value={form.theta}
                  onChange={this.handleChange('theta')}
                  error={!!validate.theta}
                  helperText={validate.theta}
                  fullWidth
                  required
                  className={classes.formItem}
                />
              </>
            )}
            <FormControl error={!!validate.near_elevator} fullWidth className={classes.formItem}>
              <InputLabel id="near_elevator">{getText('near_elevator')}</InputLabel>
              <Select
                labelId="near_elevator"
                fullWidth
                value={form.near_elevator}
                onChange={this.handleChange('near_elevator')}
              >
                {targetOptions.map(target => (
                  <MenuItem key={target.id} value={target.id}>{target.label}-{target.serial_no}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{validate.near_elevator}</FormHelperText>
            </FormControl>
            <FormControl error={!!validate.near_home} fullWidth className={classes.formItem}>
              <InputLabel id="near_home">{getText('near_home')}</InputLabel>
              <Select
                labelId="near_home"
                fullWidth
                value={form.near_home}
                onChange={this.handleChange('near_home')}
              >
                {targetOptions.map(target => (
                  <MenuItem key={target.id} value={target.id}>{target.label}-{target.serial_no}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{validate.near_home}</FormHelperText>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => { onClose() }} color="primary" variant="outlined">
              {getText('cancel')}
            </Button>
            <Button onClick={this.handleSubmit} color="primary" variant="outlined">
              {getText('ok')}
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop className={classes.backdrop} open={submitting}>
          <CircularProgress color="inherit" />
        </Backdrop>


        {selectedMap && form.routes && form.routes[0] &&
          <Dialog
            fullScreen
            open={mapOpen}
            onClose={this.closeMapModal}
          >
            <DialogTitle>
              <Typography variant="body2">{getText('relocalization_description')}</Typography>
              <CloseIcon
                className={classes.closeButton}
                onClick={this.closeMapModal}
              />
            </DialogTitle>
            <DialogContent>
              <RobotMap
                maxMapWidth={maxMapWidth}
                maxMapHeight={maxMapHeight}
                map={selectedMap}
                routeId={form.routes[0]}
                onRelocalize={this.handleChangeResetSlamParams}
              />
            </DialogContent>
          </Dialog>
        }
      </>
    );
  }

};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddFloor));
