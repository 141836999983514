import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import ReactNipple from "react-nipple";

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  dialog: {
    width: '500px',
    maxWidth: 'sm'
  },
  cardContent: {
    paddingTop: '24px',
    position: 'relative'
  }
});

const PaperComponent = props => {
  const {handledrag, inNipple, ...otherProps} = props
  return (
    <Draggable
      disabled={inNipple} 
      onStop={handledrag}
      >
      <Paper {...otherProps} />
    </Draggable>
  );
}

const MicroControlDialog = props => {

  const { classes, open, onClose, setNippleData, microMove, nippleOptions, mode } = props;
  const [nippleId, setNippleId] = useState(0);
  const [inNipple, setInNipple] = useState(false);
  return (
    <Dialog
      fullWidth
      className={classes.dialog}
      PaperComponent={PaperComponent}
      PaperProps={{handledrag: () => {setNippleId(nippleId+1);}, inNipple}}
      BackdropProps={{invisible: true}}
      open={open}
    >
      <DialogActions>
        {
          mode === 'mapping'
          ? <span>{getText('micro_control_description')}</span>
          : <span>{getText('micro_control_changing_mode')}</span>
        }
        <span style={{flex: 1}}></span>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
      {
        mode === 'mapping'
        ? <DialogContent className={classes.cardContent}
          >
            <ReactNipple
              key={nippleId}
              options={nippleOptions}
              style={{
                margin: 'auto',
                outline: '1px solid black',
                width: 200,
                height: 200
              }}
              onEnd={microMove}
              onMove={setNippleData}
              onMouseEnter={() => setInNipple(true)}
              onMouseLeave={() => setInNipple(false)}
            />
          </DialogContent>
        : <DialogContent className={classes.cardContent}
          onMouseEnter={() => setInNipple(true)}
          onMouseLeave={() => setInNipple(false)}
        />
      }
    </Dialog>
  );
}

MicroControlDialog.defaultProps = {
  open: false,
};

MicroControlDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  robot_id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  setNippleData: PropTypes.func.isRequired
}

export default withStyles(styles)(MicroControlDialog);