import React, {useEffect} from 'react'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import RobotList from "./RobotList";
import SearchBar from "./SearchBar";
import {connect} from 'react-redux'
import {caseActions, siteActions} from '../../../../redux/actions'

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto'
  },
  robotList: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  }
});

const mapStateToProps = (state) => {
  const { curSite } = state.site;
  return {
    curSite
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSiteAllFloors: siteId => dispatch(siteActions.getSiteAllFloors(siteId))
  };
};

const SidePanel = props => {
    const { classes, curSite, getSiteAllFloors } = props;
    
    useEffect(() => {
      if(curSite) {
        getSiteAllFloors(curSite)
      }
    }, [curSite])
    
    return (
      <Grid
        className={classes.root}
        container={true}
        justify="flex-start"
        direction="column"
        alignItems="stretch"
        spacing={0} >
        <Grid item={true}>
          <SearchBar />
          </Grid>
        <Grid item={true} className={classes.robotList}>
          <RobotList />
        </Grid>
      </Grid>
    );
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SidePanel));
