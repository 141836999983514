import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import history from 'src/utils/history';
import { getText } from 'src/utils/MultilingualLoader';
import { userActions, notificationActions } from 'src/redux/actions';

const mapStateToProps = state => {
  const { signedIn, submitting, role } = state.user;
  return { signedIn, submitting, role };
};

const mapDispatchToProps = dispatch => {
  return {
    signout: () => dispatch(userActions.signout()),
    notify: msg => dispatch(notificationActions.addNotification(msg))
  };
};

class PrivateRoute extends React.Component {
  constructor(props){
    super(props);
    const { signedIn, submitting, authRoles, role } = props;
    if (!signedIn || (authRoles && !authRoles.includes(role))){
      history.push('/signin');
      if (signedIn && !submitting){
        this.props.signout();
        // trigger notification right after redirection to signIn page
        setTimeout(() => this.props.notify(getText('unauthorized_role')), 0);
      }
    }
  }

  render() {
    const { component: Component, signedIn, role, authRoles, ...others } = this.props;
    return (
      <Route {...others} render={props => (
        signedIn && (!authRoles || authRoles.includes(role))
          ? <Component {...props} />
          : <Redirect to={{
              pathname: '/signin',
              state: { from: props.location }
            }} />
      )}/>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
