import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip'
import LinesEllipsis from 'react-lines-ellipsis';
import NewIcon from '@material-ui/icons/PriorityHigh';
import ClosedIcon from '@material-ui/icons/Check';
import ResolvedIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignedIcon from '@material-ui/icons/Assignment';
import AcceptedIcon from '@material-ui/icons/AssignmentInd';
import OnTheWayIcon from '@material-ui/icons/AssignmentReturn';
import ArrivedIcon from '@material-ui/icons/AssignmentReturned';

// import RobotIcon from 'src/icons/RobotIcon';
import { getText, getTimeText, getEventContent } from 'src/utils/MultilingualLoader';

const styles = () => ({
  root: {
    flex: 1
  },
  selected: {
    //!important to override :hover
    backgroundColor: 'rgba(76,127,114,0.25) !important'
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    borderBottom: '2px solid rgba(0,0,0,0.07)',
    // justifyContent: 'center' 
  },
  unread: {
    top: 0,
    left: 0,
    position: 'absolute',
    width: '5px',
    height: '100%',
    backgroundColor: 'rgba(76,127,114,0.75)'
  },
  caseBadge: {
    marginLeft: '8px'
  },
  listItemText: {
    padding: '0 16px 0 8px'
  },
  listItemTextUnread: {
    padding: '0 16px 0 8px',
    fontWeight: 'bold !important'
  },
  title: {
    display: 'flex',
    flexDirection: 'row'
  },
  spacer: {
    flex: 1
  },
  summary: {
    fontSize: '0.8rem'
  }
});

const caseContent = (_case) => {
  let types = '';
  let source = '';
  let name = '';
  if (_case.event) {
    types = _case.event.types;
    source = _case.event.source;
    if (source === 'robot') name = (_case.event.robot && _case.event.robot.name) || 'unknown';
    else name = (_case.event.camera && _case.event.camera.name) || 'unknown';
  }
  else if (_case.inspection){
    types = _case.inspection.algos;
    source = 'robot';
    name = (_case.inspection.robot && _case.inspection.robot.name) || 'unknown';
  }
  else if (_case.incident) {
    types = ['incident'];
    source = 'robot';
    name = (_case.incident.robot && _case.incident.robot.name) || 'unknown';
  }
  
  if (types.includes(',')) types = types.split(',').map(type => getText(type)).join(', ');
  else types = getText(types);
  
  return getEventContent(types, source, name);
}

const getColor = (priority) => {
  switch (priority) {
    case 'unassigned':
      return '#999999';
    case 'low':
      return '#FFC300';
    case 'medium':
      return '#FF5733';
    case 'high':
      return '#C70039';
    default:
      return '#999999';
  }
}

const CaseBadge = props => {
  const { status, priority, className } = props;
  const color = getColor(priority);
  switch (status) {
    case 'closed':
      return (<ClosedIcon htmlColor="#4C7F72" className={className} />);
    case 'resolved':
      return (<ResolvedIcon htmlColor="#4C7F72" className={className} />);
    case 'assigned':
      return (<AssignedIcon htmlColor={color} className={className} />);
    case 'accepted':
      return (<AcceptedIcon htmlColor={color} className={className} />);
    case 'on_the_way':
      return (<OnTheWayIcon htmlColor={color} className={className} />);
    case 'arrived':
      return (<ArrivedIcon htmlColor={color} className={className} />);
    // case 'Robot Assigned':
    //   return (<RobotIcon />);
    default:
      return (<NewIcon htmlColor={color} className={className} />);
  }
}

const CaseList = props => {
  const { classes, data: cases, onClick, selected } = props;

  return (
    <List component="nav" className={classes.root} id='case-list'>
      {
        cases.map((_case, index) => (
          <ListItem
            disableGutters
            key={_case.id}
            className={classes.listItem}
            alignItems="center"
            button={true}
            classes={{
              selected: classes.selected
            }}
            onClick={onClick(_case)}
            selected={selected && selected.id === _case.id}
          >

            <div className={classes.unread} style={{ visibility: _case.viewed ? 'hidden' : 'visible' }} />

            <Tooltip
              placement="top"
              enterDelay={200}
              title={_case.status}
            >
              <div>
                <CaseBadge
                  className={classes.caseBadge}
                  priority={_case.priority}
                  status={_case.status}
                />
              </div>
            </Tooltip>

            <ListItemText
              className={_case.viewed ? classes.listItemText : classes.listItemTextUnread}
              disableTypography={true}
              primary={
                <div>
                  <div className={classes.title}>
                    <Typography variant="body2" gutterBottom style={_case.viewed ? {} : { fontWeight: 'bold' }}>
                      {getText('case')} #{_case.id}
                    </Typography>
                    <div className={classes.spacer} />
                    <Typography variant="body2" gutterBottom style={_case.viewed ? {} : { fontWeight: 'bold' }}>
                      {getTimeText(_case.created_at, 'lll')}
                    </Typography>
                  </div>
                  <LinesEllipsis
                    text={caseContent(_case)}
                    className={classes.summary}
                    maxLine={2}
                    ellipsis="..."
                  />
                </div>
              }
            />
          </ListItem>
        ))
      }
    </List>
  );
};

CaseList.propTypes = {
  selected: PropTypes.any
};

CaseList.defaultProps = {
  selected: null,
  data: [],
  onClick: () => event => { }
};

export default withStyles(styles)(CaseList);
