import React, { useState, useEffect } from 'react';
import { withStyles, Button, Typography } from '@material-ui/core';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';

import MapStyle from './MapStyle.json';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  button: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(3),
  },
  label: {
    color: 'white',
    textTransform: 'initial'
  },
});

const markerIcon = {
  path: "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z",
  fillOpacity: 1,
  fillColor: '#4C7F72',
  scale: 1.2,
  labelOrigin: { x: 15, y: 30 }
};

const SiteMap = withScriptjs(withGoogleMap(props => {
  const { classes, defaultZoom, defaultCenter, siteLocations, onSiteClick } = props;
  const [map, setMap] = useState(null);
  useEffect(() => {
    if (map && window.google && siteLocations.length > 0) {
      try {
        const bounds = new window.google.maps.LatLngBounds();
        siteLocations.forEach(site => bounds.extend(site.position));
        // Adjust bounds not to zoom in too much
        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
          const extendPoint1 = new window.google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
          const extendPoint2 = new window.google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
          bounds.extend(extendPoint1);
          bounds.extend(extendPoint2);
        }
        map.fitBounds(bounds);
      } catch(e) {}
    }
  }, [map])

  return (
    <>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={onSiteClick(-1)}
      >
        <Typography variant="body2" className={classes.label}>
          {getText('others')}
        </Typography>
      </Button>
      <GoogleMap
        defaultZoom={defaultZoom}
        defaultCenter={defaultCenter}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          styles: MapStyle
        }}
        ref={map => setMap(map)}
      >
        <MarkerClusterer
          averageCenter
        >
          {siteLocations.map(site => {
            return (
              <Marker
                key={site.value}
                position={site.position}
                onClick={onSiteClick(site.value)}
                label={site.label}
                icon={markerIcon}
              />
            )
          })}
        </MarkerClusterer>
      </GoogleMap>
    </>
  )
}))

export default withStyles(styles)(SiteMap);