import React from 'react';
import { connect } from 'react-redux';

import PlayerCard from 'src/pages/Main/Player/PlayerCard';
import WebRTCViewer from './WebRTCViewer';

const mapStateToProps = state => {
  const { role } = state.user;
  return { role };
};

const mapDispatchToProps = dispatch => {
  return { };
};

class WebRTCContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playerId: 0
    };
    this.webRTCRefresh = this.webRTCRefresh.bind(this);
    this.interval = null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.camera && prevProps.camera && this.props.camera.id !== prevProps.camera.id)
      this.webRTCRefresh();
  }

  webRTCRefresh() {
    this.setState({
      playerId: this.state.playerId + 1
    });
  }

  reconnect() {
    console.log('reconnect');
    this.webRTCRefresh();
    this.interval = setInterval(() => {
      if(window.wsWISP && !window.wsWISP.isConnected()) {
        console.log('refresh');
        this.webRTCRefresh();
      } else {
        clearInterval(this.interval);
        this.interval = null;
      }
    }, 10000);
  }

  render() {
    const { camera, cameraHeight, downGrade, role } = this.props;

    return (
      <PlayerCard
        player={
          <WebRTCViewer
            key={this.state.playerId}
            camera={camera}
            minHeight={cameraHeight}
            downGrade={downGrade}
            refresh={this.webRTCRefresh}
            reconnect={this.reconnect.bind(this)}
          />
        }
        camera={camera}
        showHistory={role === 'customer' && window.location.pathname.includes('livestreaming')}
        minHeight={cameraHeight}
        refresh={this.webRTCRefresh}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebRTCContainer);
