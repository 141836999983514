import React from 'react';
import {useSelector} from 'react-redux';
import {Grid, makeStyles, useMediaQuery, Theme} from '@material-ui/core';

// import {RootState} from 'src/redux/reducers';
import {DetailNavigateInfoComponent} from './info';
import {MoreDetailLoadComponent} from './moreLoad';
// import MobileHeader from 'src/components/CommonHeader/MobileHeader';

const useStyles = makeStyles(theme => ({
  navigate: {
    position: 'sticky',
    zIndex: theme.zIndex.mobileStepper + 1,
    top: 0,
    // left: 260,
    minHeight: 72,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFF',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(5),
    borderBottomWidth: 1,
    borderBottomColor: (theme.palette).other.divider,
    borderBottomStyle: 'solid',
  },
}));

export function DetailNavigateComponet(){
  const classes = useStyles();

  return (
    <Grid
      container
      justify="space-between"
      classes={{container: classes.navigate}}
      wrap="nowrap"
      style={{
        top: 0,
        marginLeft: 0,
        width: `calc(${'100%'})`,
      }}
    >
      <DetailNavigateInfoComponent />
      <MoreDetailLoadComponent />
    </Grid>
  );
}
