/* eslint-disable @typescript-eslint/naming-convention */
import {SET_VISION_ORG} from 'src/redux/types/Vision/orgvision';

const initialState = {
  org: []
};

export default function roleReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
      case SET_VISION_ORG:
        return {
          ...state,
          org: payload.org,
        };
      default:
        return state;
    }
}
