import * as services from 'src/services/Vision/site';
import store from 'src/redux/store';
import {
  SET_SITES_WITH_CAMERAS,
  SET_SITES_WITH_CAMERAS_LOADING,
  SET_SITE_OPTIONS,
} from 'src/redux/types/Vision/site';

export async function getSiteOptions(forceRefresh = false, managements = false) {
  const {dispatch, getState} = store;
  const currentSiteOptions = getState().sitevision.siteOptions;
  if (currentSiteOptions.length > 0 && !forceRefresh) {
    return currentSiteOptions;
  }else{
    try {
      let res = !managements
        ? await services.getSimpleSites({limit: 1000})
        : await services.getSimpleSites({limit: 1000, managements: managements});
      res = res.data.ret;
      dispatch({
        type: SET_SITE_OPTIONS,
        payload: {
          siteOptions: res.results,
        },
      });
      return res.results;
    } catch (error) {
      return [];
    }
  }
}