import React from 'react';
import { Layer, Text } from 'react-konva';

import { getText } from 'src/utils/MultilingualLoader';

const MapLegend = props => {
  const { mapName, routeName, lidarType } = props;
  const hasMapName = mapName && mapName !== '' ? true : false;
  const hasRouteName = routeName && routeName !== '' ? true : false;
  const hasLidarType = lidarType !== ''
  return (
    <Layer>
      {hasMapName && <Text x={5} y={5} text={`${getText('map_name')}: ${mapName}`} />}
      {hasRouteName && <Text x={5} y={20} text={`${getText('route_name')}: ${routeName}`} />}
      {hasLidarType && <Text x={5} y={35} text={`${getText('lidar_type')}: ${lidarType}`} />}
    </Layer>
  );

};

export default MapLegend;

