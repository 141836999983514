import React from 'react';
import history from 'src/utils/history';
import $ from 'jquery';

import { connect } from 'react-redux';
import { caseActions, cameraActions } from 'src/redux/actions';
import SideNav from './SideNav'

const mapStateToProps = state => {
  const { role } = state.user;
  const { num_new, num_active, filters: caseFilters, loading: casesLoading } = state.case;
  const { filters: cameraFilters, loading: camerasLoading } = state.camera;
  return { role, num_new, num_active, caseFilters, cameraFilters, casesLoading, camerasLoading };
};

const mapDispatchToProps = dispatch => {
  return {
    getCases: filters => {
      dispatch(caseActions.getCases(filters));
    },
    setCaseFilter: filters => {
      dispatch(caseActions.setFilter(filters));
    },
    getCameras: filters => {
      dispatch(cameraActions.getCameras(filters));
    },
    setCameraFilter: filters => {
      dispatch(cameraActions.setFilter(filters));
    },
    unselectCase: () => dispatch(caseActions.selectCase(null))
  }
};

const defaultCaseFilter = {
  action: 'all',
  status: 'all',
  eventType: 'all',
  incidentType: 'all',
  eventSource: 'all',
  priority: 'all',
  searchQuery: '',
  timeType: 'all',
  startTime: null,
  endTime: null,
  unread: false,
  unclosed: false,
};

class SideNavContainer extends React.Component {
  constructor(props) {
    super(props);
    const pagePath = window.location.pathname;
    this.state = {
      pagePath: pagePath
    };
  }

  handleOnClick = path => event => {
    this.setState({
      pagePath: path
    });
    history.push(path);
    let caseFilters;
    let cameraFilters;
    const { casesLoading, camerasLoading, setCameraFilter, setCaseFilter, getCases, getCameras, unselectCase } = this.props;

    switch (path) {
      case '/cases':
        caseFilters = Object.assign({}, this.props.caseFilters, defaultCaseFilter);
        setCaseFilter(caseFilters);
        if (!casesLoading) {
          getCases(caseFilters);
          unselectCase();
        }
        $('#case-list').scrollTop(0);
        return;
      case '/cases/new':
        caseFilters = Object.assign({}, this.props.caseFilters, {...defaultCaseFilter, unread: true});
        setCaseFilter(caseFilters);
        if (!casesLoading) {
          getCases(caseFilters);
          unselectCase();
        }
        $('#case-list').scrollTop(0);
        return;
      case '/cases/active':
        caseFilters = Object.assign({}, this.props.caseFilters, {...defaultCaseFilter, unclosed: true});
        setCaseFilter(caseFilters);
        if (!casesLoading) {
          getCases(caseFilters);
          unselectCase();
        }
        $('#case-list').scrollTop(0);
        return;
      case '/livestreaming':
        cameraFilters = Object.assign({}, this.props.cameraFilters, {type: 'all'});
        setCameraFilter(cameraFilters);
        if (!camerasLoading) {
          getCameras(cameraFilters);
        }
        return;
      case '/livestreaming/robot':
        cameraFilters = Object.assign({}, this.props.cameraFilters, {type: 'robot'});
        setCameraFilter(cameraFilters);
        if (!camerasLoading) {
          getCameras(cameraFilters);
        }
        return;
      case '/livestreaming/camera':
        cameraFilters = Object.assign({}, this.props.cameraFilters, {type: 'camera'});
        setCameraFilter(cameraFilters);
        if (!camerasLoading) {
          getCameras(cameraFilters);
        }
        return;
      default:
        return;
    }
  }

  render() {
    const { role, num_new, num_active } = this.props;
    // const { pagePath } = this.state;
    const pagePath = window.location.pathname; // for link to case rather than menu click

    return (
      <SideNav
        handleOnClick={this.handleOnClick}
        role={role}
        pagePath={pagePath}
        num_new={num_new}
        num_active={num_active}
      />
    );
  }
};

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(SideNavContainer));
