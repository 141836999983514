import React from 'react';

import { connect } from 'react-redux';
import { robotActions } from 'src/redux/actions';
import SearchBar from 'src/pages/Main/SearchBar';
import RobotFilter from '../RobotFilter';

const mapStateToProps = state => {
  const { filters, loading } = state.robot;
  const { searchQuery } = filters;
  return { searchQuery, filters, loading };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilter: filters => {
      dispatch(robotActions.setFilter(filters));
    },
    getRobots: filters => {
      dispatch(robotActions.getRobots(filters));
    },
    unselectRobot: () => dispatch(robotActions.selectRobot(null)),
  };
};

class SearchBarContainer extends React.Component {
  handleSearchQueryChange = (searchQuery) => {
    const { filters, loading, setFilter, getRobots, unselectRobot } = this.props;
    const new_filter = Object.assign({}, filters, {
      searchQuery: searchQuery
    });
    setFilter(new_filter);
    if (!loading) {
      getRobots(new_filter);
      unselectRobot();
    }
  }

  render() {
    return (
      <SearchBar
        filterChild={<RobotFilter />}
        initialQuery={this.props.searchQuery}
        onChangeSearchQuery={this.handleSearchQueryChange}
      />
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchBarContainer);
