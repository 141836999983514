import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import { getText, getTimeText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    margin: '12px 0'
  },
  form: {
    width: '100%',
  },
  button: {
    backgroundColor: 'rgb(216, 215, 215)',
    float: 'right'
  },
  noteList: {
    flexWrap: 'wrap',
  },
  noteItem: {
    flex: '1 0 auto',
    display: 'block',
    padding: '11px 0 11px 0'
  },
  buttonText: {
    textTransform: 'initial',
  }
});

const CaseNote = (props) => {
    const { classes, note, notes, onChange, onSubmit } = props;
    return (
      <ExpansionPanel defaultExpanded={true} className={classes.root}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography variant="body1">
            {getText('additional_notes')}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.noteList}>
          {
            notes.map(_note => (
              <ListItem className={classes.noteItem} key={_note.id}>
                <Typography variant="body1">
                  {_note.user ?  _note.user.first_name : ''} {_note.user ? _note.user.last_name : ''} {getText('commented_at')} {getTimeText(_note.created_at, 'lll')}:
                </Typography>
                <TextField
                  fullWidth
                  disabled
                  multiline
                  variant="filled"
                  value={_note.content}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: '10px 12px 10px 12px', 
                      color: 'black',
                      backgroundColor: 'RGB(230, 230, 230)'
                    }
                  }}
                />
              </ListItem>
            ))
          }
          <form className={classes.form}>
            <FormControl required fullWidth>
              <TextField
                id="filled-multiline-static"
                multiline
                rows="10"
                margin="normal"
                variant="filled"
                value={note}
                onChange={onChange}
              />
            </FormControl>

            <Button
              className={classes.button}
              onClick={onSubmit}
            >
              <Typography variant="body2" className={classes.buttonText}>
                {getText('submit')}
              </Typography>
            </Button>
          </form>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
};

CaseNote.propTypes = {
  note: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

CaseNote.defaultProps = {
  note: '',
  onChange: () => () => {},
  onSubmit: () => () => {},
};

export default withStyles(styles)(CaseNote);
