import React from 'react';

import { connect } from 'react-redux';
import { caseActions } from 'src/redux/actions';
import NearbyCameras from './NearbyCameras'

const mapStateToProps = state => {
  return {
    cameras: state.case.cameras,
    selectedCameras: state.case.selectedCameras,
    selected: state.case.selected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectCamera: (camera, checked) => {
      dispatch(caseActions.selectCamera(camera, checked));
    },
  };
};

class NearbyCamerasContainer extends React.Component {
  constructor(props) {
    super(props);
    const height = (window.innerWidth - 200 - 350 - 16 - 24) / 4 * 9 / 16;
    this.state = {
      nearbyCameraHeight: window.innerWidth < 1280 ? height * 2 : height
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeVideo);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeVideo);
  }

  resizeVideo = () => {
    const height = (window.innerWidth - 200 - 350 - 16 - 24) / 4 * 9 / 16;
    this.setState({
      nearbyCameraHeight: window.innerWidth < 1280 ? height * 2 : height
    });
  }

  getDevice = (selected) => {
    let cameraID = null;
    let cameraType = '';
    let cameraName = '';
    if (selected && selected.event) {
      const source = selected.event.source;
      if (source === 'robot') {
        cameraID = selected.event.robot.id;
        cameraName = selected.event.robot.name;
        cameraType = 'robot';
      }
      else if (source === 'camera') {
        cameraID = selected.event.camera.id;
        cameraName = selected.event.camera.name;
        cameraType = 'camera';
      }
    }
    return { cameraID, cameraName, cameraType };
  }

  handleCameraSelect = (camera, checked) => {
    this.props.selectCamera(camera, checked);
  };

  render() {
    const { nearbyCameraHeight } = this.state;
    const { selected, cameras, selectedCameras } = this.props;
    const { cameraID } = this.getDevice(selected);
    const nearbyCameras = cameras.filter(item => item.id !== cameraID);
    if (nearbyCameras.length > 0) 
      return (
        <NearbyCameras
          cameras={nearbyCameras}
          selectedCameras={selectedCameras}
          handleCameraSelect={this.handleCameraSelect}
          nearbyCameraHeight={nearbyCameraHeight}
          />
      );
    else return null;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NearbyCamerasContainer);
