import moment from "moment";
import { stringify } from "qs";
import { GeneralAdapter } from "./adapter";
import { service } from "./monitoring";
import { config } from "./config";
import { api } from "./api";

export const robotAdapter = (robot) => ({
  id: robot.id,
  type: "robot",
  name: robot.name,
  user_id: robot.user_id,
  details: robot.cameras,
  status: GeneralAdapter.getRobotStatus(robot),
  state_data: robot.state,
  site_id: robot.site_id,
  site: robot.site,
});

export const getRobotsAdapter = {
  toServer: (data) => {
    return data;
  },
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = result ? result : [];

    const results = result.map(robotAdapter);

    return { error, results };
  },
};

export const getRobots = (
  (adapter) =>
  async (filters = {}) => {
    try {
      const query = mapFiltersToQuery(filters);
      const url = `/robot/?${query}`;
      let response = await service.get(url);
      response = adapter.toClient(response.data);
      const { error, results } = response;
      if (error) throw error;
      return results;
    } catch (e) {
      throw e;
    }
  }
)(getRobotsAdapter);

export const getRobotAdapter = {
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = result && robotAdapter(result);

    return { error, results: result };
  },
};

export const getRobot = ((adapter) => async (robotId) => {
  try {
    const url = `/robot/${robotId}`;
    let response = await service.get(url);
    response = adapter.toClient(response.data);
    const { error, results } = response;
    if (error) throw error;
    return results;
  } catch (e) {
    throw e;
  }
})(getRobotAdapter);

const mapFiltersToQuery = (filters) => {
  const queries = [];
  // Robot Status
  const { robotStatuses } = filters;
  GeneralAdapter.mapRobotStatuses(queries, robotStatuses);

  const { searchQuery } = filters;
  if (searchQuery)
    queries.push(
      `${encodeURIComponent("search")}=${encodeURIComponent(searchQuery)}`
    );

  // SiteId
  if (filters.siteId) {
    queries.push(
      `${encodeURIComponent("site_id")}=${encodeURIComponent(
        filters.siteId !== -1 ? filters.siteId : null
      )}`
    );
  }

  return queries.join("&");
};

const mapConfigMap = (map) => {
  // // There are many types of files, only png files are obtained
  const pngSignedFile = ((map && map.signed_files) || []).find(
    (f) => f.type === 'map_png' || f.type === 'map_map'
  )
  return {
    id: map.id,
    name: map.name,
    floor: map.floor,
    lidarType: map.lidar_type,
    floorId: map.floor_id,
    mapUrl: pngSignedFile && pngSignedFile.file && pngSignedFile.file.url,
    mapSize: pngSignedFile && pngSignedFile.file && pngSignedFile.file.size,
    mapConfig: map.map_config,
    mapRoute: map.route,
    chargingBase:
      map && map.charging_base_meta && map.charging_base_meta.base_pose,
    landmarks: map && map.landmarks,
    elevatorBase: map.elevator_base,
    uploadStatus: map.upload_status,
    transforms: map.transforms || [],
    rawData: map,
    elevatorUnreachable: map.elevator_unreachable,
    zoneId: map.zone_id,
    switchPose: map.switch_pose,
    lidarType: map.lidar_type
  }
};

const updateRobotStatusAdapter = {
  toServer: (data) => {
    return data;
  },
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    let { error, result: robot } = data;
    if (error) return { error };

    return {
      error,
      robot: {
        id: robot.id,
        type: "robot",
        name: robot.name,
        user_id: robot.user_id,
        details: robot.cameras,
        status: robot.status,
        state_data: robot.state,
      },
    };
  },
};

export const updateRobotStatus = ((adapter) => async (robot_id) => {
  try {
    const url = "/robot/";
    let response = await service.get(url + robot_id);
    response = adapter.toClient(response.data);
    const { error, robot } = response;
    if (error) throw error;
    return robot;
  } catch (e) {
    throw e;
  }
})(updateRobotStatusAdapter);

const generalAdapter = {
  toServer: (data) => data,
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    return { error, result };
  },
};

export const getRobotConfigs = ((adapter) => async (robot_id) => {
  try {
    const url = "/config/robot/";
    let response = await config.get(url + robot_id);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const updateRobotConfigs = ((adapter) => async (robotId, data) => {
  try {
    const url = "/config/robot/";
    let response = await config.patch(url + robotId, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

const getRobotDetectionConfigsAdapter = {
  toServer: (data) => {
    return data;
  },
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = {
      ...result,
      detection_rules: result.detection_rules.map((rule) => {
        const algos = {};
        if (rule.algos)
          rule.algos.split(",").forEach((algo) => {
            algos[algo] = true;
          });
        return {
          ...rule,
          algos,
        };
      }),
    };
    return { error, result };
  },
};

export const getRobotDetectionConfigs = ((adapter) => async (robot_id) => {
  try {
    const url = "/config/robot/";
    let response = await config.get(url + robot_id + "/detection");
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(getRobotDetectionConfigsAdapter);

const updateRobotDetectionConfigsAdapter = {
  toServer: (data) => {
    return {
      ...data,
      detection_rules: data.detection_rules
        ? data.detection_rules.map((rule) => ({
            ...rule,
            algos: rule.algos
              ? Object.keys(rule.algos)
                  .filter((algo) => rule.algos[algo])
                  .join(",")
              : "",
          }))
        : [],
    };
  },
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    return { error, result };
  },
};

export const updateRobotDetectionConfigs = (
  (adapter) => async (robot_id, data) => {
    try {
      const url = "/config/robot/";
      let response = await config.put(
        url + robot_id + "/detection",
        adapter.toServer(data)
      );
      response = adapter.toClient(response.data);
      const { error, result } = response;
      if (error) throw error;
      return result;
    } catch (e) {
      throw e;
    }
  }
)(updateRobotDetectionConfigsAdapter);

const getRobotAlgosAdapter = {
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = result && result.algos ? result.algos : [];
    if (typeof result === "object") result = Object.values(result);
    result = result.map((algo) => algo.name);
    return { error, result };
  },
};

export const getRobotAlgos = ((adapter) => async (robot_id) => {
  try {
    const url = "/robot/robots/";
    let response = await api.get(url + robot_id + "/get_meta");
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(getRobotAlgosAdapter);

const getRobotMapAdapter = {
  toServer: (data) => {
    return data;
  },
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = mapConfigMap(result);
    return { error, result };
  },
};

export const getRobotConfigMap = (
  (adapter) =>
  async (robotId, mapId, routeId = null) => {
    try {
      let url = `/config/robot/${robotId}/map/${mapId}`;
      if (routeId) url += `?route_id=${routeId}`;
      let response = await config.get(url);
      response = adapter.toClient(response.data);
      const { error, result } = response;
      if (error) throw error;
      return result;
    } catch (e) {
      throw e;
    }
  }
)(getRobotMapAdapter);

const getRobotMapsAdapter = {
  toServer: (data) => {
    return data;
  },
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    const results = result
      ? result
          .filter((map) => map.upload_status === "finished" && map.id)
          .map((map) => mapConfigMap(map))
      : [];
    return { error, results };
  },
};

export const getRobotMaps = ((adapter) => async (robotId) => {
  try {
    const url = `/config/robot/${robotId}/maps`;
    let response = await config.get(url);
    response = adapter.toClient(response.data);
    const { error, results } = response;
    if (error) throw error;
    return results;
  } catch (e) {
    throw e;
  }
})(getRobotMapsAdapter);

export const updateMap = (
  (adapter) =>
  async (robotId, mapId, data, routeId = null) => {
    try {
      let url = `/config/robot/${robotId}/map/${mapId}`;
      if (routeId) url += `?route_id=${routeId}`;
      let response = await config.put(url, data);
      response = adapter.toClient(response.data);
      const { error, result } = response;
      if (error) throw error;
      return result;
    } catch (e) {
      throw e;
    }
  }
)(getRobotMapAdapter);

export const deleteMap = ((adapter) => async (robotId, mapId) => {
  try {
    let url = `/config/robot/${robotId}/map/${mapId}?source=cloud`;
    let response = await config.delete(url);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

const getRobotMapsWithRoutesAdapter = {
  toServer: (data) => {
    return data;
  },
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    let mapsWithRoutes = {};

    result &&
      result
        .filter((map) => map.upload_status === "finished" && map.id)
        .forEach((map) => {
          if (!mapsWithRoutes.hasOwnProperty(map.id)) {
            let configMap = mapConfigMap(map);
            delete configMap.mapRoute;
            mapsWithRoutes[map.id] = {
              ...configMap,
              mapRoutes: (map.route && [map.route]) || [],
            };
          } else map.route && mapsWithRoutes[map.id].mapRoutes.push(map.route);
        });
    return { error, results: Object.values(mapsWithRoutes) };
  },
};

// get maps with routes
export const getRobotMapsWithRoutes = ((adapter) => async (robotId) => {
  try {
    const url = `/config/robot/${robotId}/routes`;
    let response = await config.get(url);
    response = adapter.toClient(response.data);
    const { error, results } = response;
    if (error) throw error;
    return results;
  } catch (e) {
    throw e;
  }
})(getRobotMapsWithRoutesAdapter);

export const copyMap = ((adapter) => async (data) => {
  try {
    const url = "config/robot/maps/copy";
    let response = await config.post(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const updateRoute = (
  (adapter) => async (robotId, mapId, routeId, data) => {
    try {
      const url = `/config/robot/${robotId}/route/${mapId}/${routeId}`;
      let response = await config.put(url, data);
      response = adapter.toClient(response.data);
      const { error, result } = response;
      if (error) throw error;
      return result;
    } catch (e) {
      throw e;
    }
  }
)(generalAdapter);

export const deleteRoute = ((adapter) => async (robotId, mapId, routeId) => {
  try {
    const url = `/config/robot/${robotId}/route/${mapId}/${routeId}`;
    let response = await config.delete(url);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const copyRoute = ((adapter) => async (data) => {
  try {
    const url = `/config/robot/routes/copy`;
    let response = await config.post(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const sendLiftCommand = ((adapter) => async (data) => {
  try {
    const url = `/config/lift/command`;
    let response = await config.post(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const backupMap = ((adapter) => async (data) => {
  try {
    let url = "config/robot/map/backup";
    let response = await config.post(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const backupRoute = ((adapter) => async (data) => {
  try {
    const url = "config/robot/route/backup";
    let response = await config.post(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const getBackupMaps = ((adapter) => async (userId) => {
  try {
    const url = `/config/robot/${userId}/maps/backup`;
    let response = await config.get(url);
    response = adapter.toClient(response.data);
    const { error, results } = response;
    if (error) throw error;
    return results;
  } catch (e) {
    throw e;
  }
})(getRobotMapsAdapter);

export const getBackupRoutes = ((adapter) => async (userId, mapId) => {
  try {
    const url = `/config/robot/${userId}/routes/backup/${mapId}`;
    let response = await config.get(url);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const restoreMap = ((adapter) => async (data) => {
  try {
    const url = `/config/robot/map/restore`;
    let response = await config.post(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(getRobotMapAdapter);

export const restoreRoute = ((adapter) => async (data) => {
  try {
    const url = `/config/robot/route/restore`;
    let response = await config.post(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const deleteBackupMap = ((adapter) => async (userId, mapId) => {
  try {
    const url = `/config/robot/${userId}/backup_map/${mapId}`;
    let response = await config.delete(url);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const deleteBackupRoute = (
  (adapter) => async (userId, mapId, routeId) => {
    try {
      const url = `/config/robot/${userId}/backup_route/${mapId}/${routeId}`;
      let response = await config.delete(url);
      response = adapter.toClient(response.data);
      const { error, result } = response;
      if (error) throw error;
      return result;
    } catch (e) {
      throw e;
    }
  }
)(generalAdapter);

export const updateBackupMap = (
  (adapter) =>
  async (userId, mapId, data, routeId = null) => {
    try {
      let url = `/config/robot/${userId}/backup_map/${mapId}`;
      if (routeId) url += `?route_id=${routeId}`;
      let response = await config.put(url, data);
      response = adapter.toClient(response.data);
      const { error, result } = response;
      if (error) throw error;
      return result;
    } catch (e) {
      throw e;
    }
  }
)(getRobotMapAdapter);

export const updateBackupRoute = (
  (adapter) => async (userId, mapId, routeId, data) => {
    try {
      const url = `/config/robot/${userId}/backup_route/${mapId}/${routeId}`;
      let response = await config.put(url, data);
      response = adapter.toClient(response.data);
      const { error, result } = response;
      if (error) throw error;
      return result;
    } catch (e) {
      throw e;
    }
  }
)(generalAdapter);

const getRobotStateAdapter = {
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = {
      routineEvents: result.routine_events,
      lastRoutineId: result.latest_routine_id,
      liftTripId: result.lift_trip_id,
      liftFrom: result.lift_from,
      liftTo: result.lift_to,
      liftTaskId: result.lift_task_id,
      liftCommandId: result.lift_command_id,
      liftCommandType: result.lift_command_type,
      liftStatus: result.lift_status,
      currentExecutionId: result.current_routine_execution_id,
    };
    return { result };
  },
};

export const getRobotState = ((adapter) => async (robotId) => {
  try {
    const url = `/config/robot/${robotId}/state`;
    let response = await config.get(url);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(getRobotStateAdapter);

const routineExecuteAdapter = {
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = {
      routineExecuteId: result.id,
    };
    return { result };
  },
};

export const routineExecute = ((adapter) => async (data) => {
  try {
    const url = `/config/robot/routines/executions`;
    let response = await config.post(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(routineExecuteAdapter);

const routineExecutionAdapter = {
  toServer: (data, offset) => {
    const params = {};
    if (data.routineId && data.routineId !== "all") {
      params["routine_id"] = data.routineId;
    }
    if (data.startDate) {
      params["started_at__gte"] = moment(data.startDate)
        .startOf("day")
        .toISOString();
    }
    if (data.endDate) {
      params["started_at__lt"] = moment(data.endDate)
        .endOf("day")
        .toISOString();
    }
    params["offset"] = offset;
    params["limit"] = 5;
    return params;
  },
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    const results = (result.result || []).map((execution) => ({
      id: execution.id,
      routineName: execution.snap_routine && execution.snap_routine.name,
      startedAt: execution.start_at
        ? moment(execution.start_at).format("YYYY/MM/DD HH:mm:ss")
        : null,
      state: execution.state,
      stages: execution.stages,
      endedAt:
        execution.timestamps &&
        (execution.timestamps.ended_at
          ? moment(execution.timestamps.ended_at).format("YYYY/MM/DD HH:mm:ss")
          : "----"),
    }));
    return { error, results, count: result.count };
  },
};

export const getRoutineExecutions = (
  (adapter) => async (robotId, filter, offset) => {
    try {
      const params = adapter.toServer(filter, offset);
      const url = `/config/robot/${robotId}/routine_executions/executions?${stringify(
        params
      )}`;
      let response = await config.get(url);
      response = adapter.toClient(response.data);
      const { error, results, count } = response;
      if (error) throw error;
      return { results, count };
    } catch (e) {
      throw e;
    }
  }
)(routineExecutionAdapter);

const updateRoutineAdapter = {
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    return { result };
  },
};

export const updateRoutineExecution = (
  (adapter) => async (robotId, routineExecutionId, index, state) => {
    try {
      const url = `/config/robot/${robotId}/routines/executions/${routineExecutionId}/stages/${index}/state`;
      let response = await config.put(url, { state });
      response = adapter.toClient(response.data);
      const { error, result } = response;
      if (error) throw error;
      return result;
    } catch (e) {
      throw e;
    }
  }
)(updateRoutineAdapter);

export const updateRoutineExecutionState = (
  (adapter) => async (robotId, routineExecutionId, state) => {
    try {
      const url = `/config/robot/${robotId}/routines/executions/${routineExecutionId}/state`;
      let response = await config.patch(url, { state });
      response = adapter.toClient(response.data);
      const { error, result } = response;
      if (error) throw error;
      return result;
    } catch (e) {
      throw e;
    }
  }
)(updateRoutineAdapter);

const executionDetail = {
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    return {
      result: {
        id: result.id,
        startedAt: result.start_at
          ? moment(result.start_at).format("YYYY/MM/DD HH:mm:ss")
          : null,
        state: result.state,
        stages: result.stages,
        endedAt:
          result.timestamps &&
          (result.timestamps.ended_at
            ? moment(result.timestamps.ended_at).format("YYYY/MM/DD HH:mm:ss")
            : "----"),
      },
    };
  },
};

export const getRoutineExecutionDetail = (
  (adapter) => async (robotId, executionId) => {
    try {
      const url = `/config/robot/${robotId}/routine_executions/executions/${executionId}`;
      let response = await config.get(url);
      response = adapter.toClient(response.data);
      const { error, result } = response;
      if (error) throw error;
      return { result };
    } catch (e) {
      throw e;
    }
  }
)(executionDetail);

const robotDifferencesAdapter = {
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    const robots = result.updates;
    return { result: { robots } };
  },
};

export const getRobotDifferences = ((adapter) => async (params) => {
  try {
    const url = `/robot/robots/differences`;
    let response = await api.post(url, params);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(robotDifferencesAdapter);

export const updateFullRoute = ((adapter) => async (robotId, params) => {
  try {
    const url = `/config/robot/${robotId}/routes/full_route`;
    let response = await config.put(url, params);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(robotDifferencesAdapter);

export async function deleteRoutineExecution(robotId, executionId) {
  try {
    const url = `/config/robot/${robotId}/routine_executions/executions/${executionId}`;
    let response = await config.delete(url);
  } catch (e) {
    throw e;
  }
}
