import React from 'react';
import { connect } from 'react-redux';

import RoutineHistoryTime from './RoutineHistoryTimeList';
import { routineHistoryActions } from 'src/redux/actions';

 const mapStateToProps = state => {
    const { filters,robotRoutineHistoryTime,robotRoutineHistoryPage,selectedRoutineHistoryTime} = state.routineHistory;
    const { selectedRobot } = state.inspection
    return {filters,selectedRobot,robotRoutineHistoryTime,robotRoutineHistoryPage,selectedRoutineHistoryTime};
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        setRoutineHistoryFilter: filters => {
          dispatch(routineHistoryActions.setRoutineHistoryFilter(filters));
        },
        getRoutineHistoryPage: (filters) => {
          dispatch(routineHistoryActions.getRoutineHistoryPage(filters));
        },
        selectRoutineHistoryTime: history_time => {
          dispatch(routineHistoryActions.selectRoutineHistoryTime(history_time))
        }
    };
  };
  

class RoutineHistoryTimeListContainer extends React.Component {
  constructor(props) {
    super(props);
    const { filters } = props;
    const { robotId,startTime,endTime,direction,firstKey,lastKey,limit } = filters;
    this.state = {
      robotId,
      startTime,
      endTime,
      direction,
      firstKey,
      lastKey,
      limit
    };
  }
    componentDidMount() {
        const { filters, selectedRobot,setRoutineHistoryFilter,getRoutineHistoryPage } = this.props;
        const routineHistoryFilters = Object.assign({}, filters, {...this.state, robotId: selectedRobot.id});
        setRoutineHistoryFilter(routineHistoryFilters);
        getRoutineHistoryPage(routineHistoryFilters)
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.selectedRobot&&prevProps.selectedRobot&&(this.props.selectedRobot.id !== prevProps.selectedRobot.id)){
            const { filters, selectedRobot,setRoutineHistoryFilter,getRoutineHistoryPage } = this.props;
            const routineHistoryFilters = Object.assign({}, filters, {...this.state, robotId: selectedRobot.id});
            setRoutineHistoryFilter(routineHistoryFilters);
            getRoutineHistoryPage(routineHistoryFilters)
            
        }
    }

  render() {
    const {robotRoutineHistoryPage,selectRoutineHistoryTime,selectedRoutineHistoryTime} = this.props
    return (
      <>
        <RoutineHistoryTime  
          robotRoutineHistoryPage={robotRoutineHistoryPage}
          selectRoutineHistoryTime={selectRoutineHistoryTime}
          selectedRoutineHistoryTime={selectedRoutineHistoryTime}/>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutineHistoryTimeListContainer);