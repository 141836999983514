import React from 'react';
import { connect } from 'react-redux';

import history from 'src/utils/history';
import { siteActions } from 'src/redux/actions';
import SiteList from './SiteList';
import LoadingText from 'src/components/LoadingText';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  return {
    sites: state.site.sites,
    loading: state.site.siteLoading,
    updating: state.site.siteUpdating
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSites: () => {
      dispatch(siteActions.getSites());
    },
    deleteSite: id => {
      dispatch(siteActions.deleteSite(id));
    },
    updateSite: data => {
      dispatch(siteActions.updateSite(data));
    },
  };
};

class SiteListContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      editId: -1,
      editValue: {},
      mapOpen: false
    }
  }

  componentDidMount(){
    if (!this.props.loading) this.props.getSites();
  }

  onClickAdd = () => {
    history.push('/sitemanage/addsite');
  }

  onClickAssign = () => {
    history.push('/sitemanage/associatemanager')
  }

  onClickDelete = id => event => {
    if (!this.props.updating && window.confirm(getText('confirm_delete_site'))){
      this.props.deleteSite(id);
    }
  }

  onClickEdit = id => event => {
    if (this.state.editId !== -1){
      return;
    }
    const { sites } = this.props;
    this.setState({
      editId: id,
      editValue: sites && sites.find(site => site.id === id)
    });
  }

  onClickSubmit = id => event => {
    if (this.state.editValue.name === ''){
      alert(getText('input_error_name'));
      return;
    }
    if (!this.props.updating){
      this.props.updateSite({
        id,
        ...this.state.editValue
      });
      this.setState({
        editId: -1,
        editValue: {}
      });
    }
  }

  handleChange = prop => event => {
    this.setState({
      editValue: {
        ...this.state.editValue,
        [prop]: event.target.value
      }
    });
  }

  handleMapOpen = event => {
    this.setState({
      mapOpen: true
    })
  }

  handleMapClose = event => {
    this.setState({
      mapOpen: false
    })
  }

  setPolygons = polygons => {
    this.setState({
      editValue: {
        ...this.state.editValue,
        polygons
      }
    });
  }

  render() {
    const { sites, loading } = this.props;
    const { editId, editValue, mapOpen } = this.state;
    return (
      !loading ?
        <SiteList
          sites={sites || []}
          editId={editId}
          editValue={editValue}
          mapOpen={mapOpen}
          onClickAdd={this.onClickAdd}
          onClickAssign={this.onClickAssign}
          onClickDelete={this.onClickDelete}
          onClickEdit={this.onClickEdit}
          onClickSubmit={this.onClickSubmit}
          handleChange={this.handleChange}
          onMapOpen={this.handleMapOpen}
          onMapClose={this.handleMapClose}
          setPolygons={this.setPolygons}
        />
        : <LoadingText />
    );
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SiteListContainer);
