import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, FormControl, InputLabel, Input, Select, MenuItem } from '@material-ui/core';

const styles = theme => ({
  root: {
    minWidth: 100
  },
  selectorLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  menuItem: {
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem',
    textTransform: 'capitalize'
  },
  title: {
    textTransform: 'capitalize'
  },
  input: {
    maxWidth: 150
  }
});

const DropDownSelectorHorizontal = props => {
  const { classes, title, options, value, onChange, children, disabled, multiple } = props;
  return (
    <FormControl 
      component="fieldset" 
      className={classes.root}
      disabled={disabled}
    >
      <div className={classes.selectorLine}>
        {title && <InputLabel className={classes.title} >{title}</InputLabel>}
        <Select
          value={value}
          onChange={onChange}
          multiple={Boolean(multiple)}
          input={<Input fullWidth classes={{ input: classes.input }} />}
        >
          {options.map((option, index) =>
            <MenuItem key={index} className={classes.menuItem} value={option.value}>{option.label}</MenuItem>
          )}
        </Select>
      </div>
      {children}
    </FormControl>
  );
};

DropDownSelectorHorizontal.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape(
    {
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ]).isRequired,
      label: PropTypes.string.isRequired
    }
  )).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]).isRequired,
  onChange: PropTypes.func
};

DropDownSelectorHorizontal.defaultProps = {
  onChange: (event, value) => { }
};

export default withStyles(styles)(DropDownSelectorHorizontal);
