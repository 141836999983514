import React from 'react';

import { connect } from 'react-redux';
import { caseActions } from 'src/redux/actions';
import CaseFilter from './CaseFilter';

import moment from 'moment';

const mapStateToProps = state => {
  const { filters, loading } = state.case;
  return { filters, loading };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilter: filters => {
      dispatch(caseActions.setFilter(filters));
    },
    getCases: filters => {
      dispatch(caseActions.getCases(filters));
    },
    unselectCase: () => dispatch(caseActions.selectCase(null))
  };
};

const getStartAndEndTime = (timeType, filters) => {
  let startTime = filters.startTime;
  let endTime = filters.endTime;
  switch (timeType) {
    case 'today':
      startTime = moment().startOf('day');
      endTime = null;
      break;
    case 'oneWeek':
      startTime = moment().subtract(1, 'week').startOf('day');
      endTime = null;
      break;
    case 'oneMonth':
      startTime = moment().subtract(1, 'month').startOf('day');
      endTime = null;
      break;
    case 'customized':
      startTime = startTime ? startTime : moment().startOf('day');
      endTime = endTime ? endTime : moment();
      break;
    case 'all':
      startTime = null;
      endTime = null;
      break;
    default:
      break;
  }
  return { startTime, endTime};
};

class CaseFilterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      status: 'all',
      action: 'all',
      eventType: 'all',
      incidentType: 'all',
      caseType: 'event',
      eventSource: 'all',
      priority: 'all',
      timeType: 'oneWeek',
      startTime: null,
      endTime: null,
      pageSize: 100,
    };
  }

  handleDialogOpen = event => {
    const { filters } = this.props;
    const { startTime, endTime } = getStartAndEndTime(filters.timeType, filters);
    this.setState({
      openDialog: true,
      status: filters.status,
      action: filters.action,
      eventType: filters.eventType,
      incidentType: filters.incidentType,
      caseType: filters.caseType,
      eventSource: filters.eventSource,
      priority: filters.priority,
      searchQuery: filters.searchQuery,
      timeType: filters.timeType,
      pageSize: filters.pageSize,
      startTime, endTime
    });
  };

  handleDialogClose = evnet => {
    this.setState({ openDialog: false });
  };

  handleTimeTypeChange = event => {
    const timeType = event.target.value;
    const { startTime, endTime } = getStartAndEndTime(timeType, this.props.filters)
    this.setState({ 
      timeType,
      startTime,
      endTime
    });
  }

  handleStartTimeChange = time => {
    this.setState({ startTime: time });
  };

  handleEndTimeChange = time => {
    this.setState({ endTime: time });
  };

  handleFilterChange = field => event => {
    this.setState({[field]: event.target.value})
  }

  handleFilterSave = event => {
    const { openDialog, ...filters } = this.state;
    const { loading, filters: globalFilters, setFilter, getCases, unselectCase } = this.props;
    setFilter(filters);
    if (!loading) {
      getCases(Object.assign({}, globalFilters, filters));
      unselectCase();
    }
    this.setState({ openDialog: false });
  };

  render() {
    const { timeType, startTime, endTime, status, action, eventType, incidentType, caseType, eventSource, priority, pageSize, openDialog } = this.state;

    return (
      <CaseFilter
        timeType={timeType}
        startTime={startTime}
        endTime={endTime}
        status={status}
        action={action}
        eventType={eventType}
        incidentType={incidentType}
        caseType={caseType}
        eventSource={eventSource}
        priority={priority}
        pageSize={pageSize}
        openDialog={openDialog}
        handleDialogOpen={this.handleDialogOpen}
        handleDialogClose={this.handleDialogClose}
        handleFilterChange={this.handleFilterChange}
        handleFilterSave={this.handleFilterSave}
        handleDatetimeOptionChange={this.handleDatetimeOptionChange}
        handleTimeTypeChange={this.handleTimeTypeChange}
        handleStartTimeChange={this.handleStartTimeChange}
        handleEndTimeChange={this.handleEndTimeChange}
      />
    );
  }
}

CaseFilterContainer.propTypes = {
};

CaseFilterContainer.defaultProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseFilterContainer);
