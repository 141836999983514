import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ManageList from "./ManageList";
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%'
  },
});

const SidePanel = props => {
  const { classes } = props;
  return (
    <Grid
      className={classes.root}
      container={true}
      justify="flex-start"
      direction="column"
      alignItems="stretch"
      spacing={0} >
      <Grid item={true}>
        <Typography variant="h6">
          {getText('manage_options')}
        </Typography>
      </Grid>
      <Grid item={true}>
        <ManageList className={classes.root}/>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(SidePanel);
