import React from 'react';
import { connect } from 'react-redux';

import RoutineHistoryContent from './RoutineHistoryContent';

const mapStateToProps = state => {
  const { robotRoutineHistoryTime,selectedRoutineHistoryTime,robotRoutineHistoryPage, } = state.routineHistory;
  const { selectedRobot } = state.inspection
  return { selectedRobot,selectedRoutineHistoryTime,robotRoutineHistoryTime,robotRoutineHistoryPage };
};

const mapDispatchToProps = dispatch => {
  return { };
};

class RoutineHistoryContentContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      robotRoutineHistoryEvents:[],
    }
  }
  render() {
    const { robotRoutineHistoryPage,selectedRoutineHistoryTime } = this.props;
    let robotRoutineHistoryEvents = {}
    if(robotRoutineHistoryPage&&robotRoutineHistoryPage.routine_histories){
      robotRoutineHistoryEvents = robotRoutineHistoryPage.routine_histories.find(robotRoutineHistoryPage => 
        robotRoutineHistoryPage.start_time === selectedRoutineHistoryTime)
    }
    
    return (
      <>
        <RoutineHistoryContent 
          selectedRoutineHistoryTime={this.props.selectedRoutineHistoryTime}
          robotRoutineHistoryEvents={robotRoutineHistoryEvents}
          robotRoutineHistoryTime={this.props.robotRoutineHistoryTime}
          selectedRobot={this.props.selectedRobot}/>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutineHistoryContentContainer);