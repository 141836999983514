import React from 'react';
import {useSelector} from 'react-redux';
// import {FormattedMessage} from 'react-intl';
import {Grid, Typography, useMediaQuery, Theme} from '@material-ui/core';
import moment from 'moment';

import useStyles from './index.style';
// import {RootState} from 'src/redux/reducers';
// import MobileHeader from './MobileHeader';


const CommonHeader = ({
  title,
  subtitle,
  lastUpdateTime,
  position,
  border = true,
  children,
  suffix,
  titleStyle,
}) => {
  const classes = useStyles();
  // const {isAlert} = useSelector((state) => state.global);
  const updateTimeHumanize = lastUpdateTime
    ? moment.duration(new Date().valueOf() - lastUpdateTime).humanize()
    : '';
  // const smScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <div
      className={classes.root}
      style={{
        borderBottomWidth: border ? 1 : 0,
        marginLeft: 0,
        top:0,
      }}
    >
      <Grid item container justify="space-between">
        {title === '' && children}
        <Grid item className={classes.flex} style={titleStyle}>
          {title !== '' && (
            <>
              <Grid container alignItems="center" direction="row">
                <Typography variant="h6" style={{padding: '7px 0'}}>
                  {title}
                </Typography>
                <Typography
                  style={{
                    paddingLeft: '6px',
                    paddingTop: '4px',
                    fontSize: '10px',
                    fontWeight: 'bold',
                  }}
                  color="primary"
                >
                  {suffix}
                </Typography>
              </Grid>
              <Typography variant="body2">
                {lastUpdateTime && (
                  <span className={classes.updateTime}>
                    {/* <FormattedMessage id="common.update-time" values={{time: updateTimeHumanize}} /> */}
                  </span>
                )}
              </Typography>
            </>
          )}
          {subtitle && (
            <Typography variant="body2" style={{padding: '0 0 8px 0', width: '100%'}}>
              {subtitle}
            </Typography>
          )}
          {position === 'bottom' && <Grid>{children}</Grid>}
        </Grid>
        {position !== 'bottom' && title !== '' && (
          <Grid item className={classes.flexEnd}>
            {children}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default CommonHeader;
