import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import RadioSelector from 'src/components/Selectors/RadioSelector';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({});

class CameraStateSelector extends React.Component{
  render(){
    const { value, onChange: handleChange } = this.props;
    const allStates = [
      { value: 'all', label: getText('all') },
      { value: 'online', label: getText('online') },
      { value: 'offline', label: getText('offline') }
    ];

    return (
      <RadioSelector
        title={getText('camera_state')}
        options={allStates}
        onChange={handleChange}
        value={value} />
    );
  }
};

CameraStateSelector.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

CameraStateSelector.defaultProps = {
  value: 'all',
  onChange: (event, value) => {}
};

export default withStyles(styles)(CameraStateSelector);
