import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Grid, CircularProgress} from '@material-ui/core';

import {getClips, setClipsFaceSelect} from 'src/redux/actions/Vision/clip';
import NoData from 'src/components/Vision/Empty/NoData';
import Spin from 'src/components/Vision/Spin';
import EventItem from './EventItem';
import useStyles from './index.style';
import moment from 'moment';
import {VirtualRender} from 'src/components/Vision/VirtualRender';
// import InfiniteList from 'src/components/virtualRender/index.new';
import {useHistory} from 'react-router';

const List = ({type}) => {
  const classes = useStyles();
  const {filter, hasNext, clips, loading, changeFilter} = useSelector(
    (state) => state.clip
  );
  const licensePlateBlacklist = useSelector(
    (state) => state.licensePlateBlacklist.licensePlateBlacklist
  );
  const [initialled, setInitialled] = useState(false);
  const [clipList, setClipList] = useState([]);
  const [len, setLen] = useState(40);
  const [virtualKey, setVirtualKey] = useState(Math.random());
  const history = useHistory();
  useEffect(() => {
    initialList();
  }, [type]);

  useEffect(() => {
    initialList();
  }, [changeFilter]);

  useEffect(() => {
    if (loading) {
      setInitialled(true);
    }
  }, [loading]);

  useEffect(() => {
    handleClipList(clips);
  }, [clips]);

  // useEffect(() => {
  //   //initialList();
  //   const test_param = {
  //     "type": "face_tracking:101, intrude:110",
  //     "limit": 40,
  //     "source": "camera",
  //     "offset": 0,
  //     "ordering": "-started_at",
  //     "api_type": "event"
  //   }
  //   console.log("sdsdsdaawdaLLLL");
  //   getClips(test_param);
  // }, [len]);

  const handleClipList = (list) => {
    const isNewFilter = filter.page === 0;
    setClipList(prevList => {
      const array = [];
      if (isNewFilter) {
        prevList = [];
      }
      let before = prevList[prevList.length - 1];
      list.forEach(item => {
        if (
          !before ||
          moment(before.started_at).format('ddd MMM D, YYYY') !==
            moment(item.started_at).format('ddd MMM D, YYYY')
        ) {
          array.push({type: 'splitInfo', text: moment(item.started_at).format('ddd MMM D, YYYY')});
          before = item;
        }
        array.push(item);
      });
      return [...prevList, ...array];
    });
    setLen((isNewFilter ? 0 : len) + (list.length || 1)); // Ensure len changed to close loading sign after call next.
  };

  const handleRefresh = () => {
    initialList();
    getClips({...filter, page: 0});
  };

  const initialList = () => {
    if (!loading) {
      setInitialled(false);
    }
    setClipList([]);
    setLen(40);
    setVirtualKey(Math.random());
  };

  const getMoreClip = () => {
    getClips({
      ...filter,
      page: filter.page + 1,
    });
  };

  const cleanFilter = () => {
    getClips({
      ...filter,
      face: null,
    });
  };
  const cellRender = (index) => {
    const v = clipList[index];
    if (v.type === 'splitInfo') {
      return (
        <div key={`${v.text}-${moment.now()}`} className={classes.dateTxt}>
          {v.text}
        </div>
      );
    }
    return (
      <Grid key={v.id} item xs={12} sm={6} md={4} lg={3}>
        <EventItem
          onClick={() =>
            history.push({
              pathname: '/events/' + v.id,
              state: {list: clipList.map(c => c.id).filter(id => !!id), from: 'events'},
            })
          }
          event={v}
          licensePlateBlacklist={licensePlateBlacklist}
          lpr={filter.lpr}
          onRefresh={handleRefresh}
        />
      </Grid>
    );
  };
  const elements = document.getElementsByTagName('main');
  const targetelement = elements.length > 0 ? elements[0] : undefined;
  return (
    <div className={classes.root}>
      {clipList.length === 0 && loading ? (
        <Spin spinning={true} />
      ) : clips.length === 0 ? (
        initialled &&
        (filter.face ? (
          <div className={classes.infoContainner}>
            <div className={classes.infoText}>
              There are no events that match this uploaded face.
            </div>
            <div className={classes.infoText}>
              <span onClick={() => setClipsFaceSelect(true)}>Try a different image </span> or{' '}
              <span onClick={cleanFilter}>browse all facial recognition events.</span>
            </div>
          </div>
        ) : (
          <NoData />
        ))
      ) : (
        <VirtualRender
          cellRender={cellRender}
          virtualLength={clipList.length}
          dataLength={len}
          hasMore={hasNext}
          next={getMoreClip}
          loader={
            <div style={{textAlign: 'center', minHeight: '50px', color: '#e1e1e1'}}>
              <CircularProgress color="inherit" />
            </div>
          }
          scrollThreshold={0.95}
          style={{overflow: 'hidden'}}
          key={virtualKey}
          // height="flex"
          scrollableTarget={targetelement}
        />
      )}
    </div>
  );
};

export default List;
