// import {Case} from 'src/models/case';
import * as eventServices from 'src/services/Vision/event';
import {
  SET_CLIP_LOADING,
  SET_CLIP_CLIPS,
  SET_CLIP_FILTER,
  SET_CLIP_CHANGE_FILTER,
  SET_CLIP_FACE_SELECT,
  // ClipFilter,
} from 'src/redux/types/Vision/clip';
import store from 'src/redux/store';

const {dispatch, getState} = store;

export function setClipsFilter(params) {
  try {
    dispatch({
      type: SET_CLIP_FILTER,
      payload: {
        filter: params,
      },
    });
  } catch (error) {}
}

export function setClipsFaceSelect(params) {
  try {
    dispatch({
      type: SET_CLIP_FACE_SELECT,
      payload: {
        showFaceSelect: params,
      },
    });
  } catch (error) {}
}

export async function getClips(params, changeFilter = false) {
  try {
    const currentLoading = getState().clip.loading;
    if (currentLoading) {
      return false;
    }
    dispatch({
      type: SET_CLIP_LOADING,
      payload: {
        loading: true,
      },
    });
    setClipsFilter(params);
    // Handle special case when face is not matching any tracking object.
    if (params.face && !Object.keys(params.face).length) {
      dispatch({
        type: SET_CLIP_CLIPS,
        payload: {
          loading: false,
          total: 0,
          hasNext: false,
          clips: [],
        },
      });
    } else if (params.type === 'face_detection' || params.api_type === 'event') {
      if (changeFilter) {
        dispatch({
          type: SET_CLIP_CHANGE_FILTER,
        });
      }
      const res = await eventServices.getEventList(params);
      dispatch({
        type: SET_CLIP_CLIPS,
        payload: {
          loading: false,
          total: res.total,
          hasNext: res.hasNext,
          clips: res.results,
        },
      });
    } else {
      if (params.api_type === 'case' && changeFilter) {
        dispatch({
          type: SET_CLIP_CHANGE_FILTER,
        });
      }
      // const res1 = await caseServices.getCaseDetail();
      const res = await eventServices.getEventList(params);
      dispatch({
        type: SET_CLIP_CLIPS,
        payload: {
          loading: false,
          total: res.total,
          clips: res.list.map((v) => v.event),
        },
      });
    }
    return true;
  } catch (error) {
    dispatch({
      type: SET_CLIP_LOADING,
      payload: {
        loading: false,
      },
    });
    return false;
  }
}
