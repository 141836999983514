import React, {useState} from 'react';
import {Grid, FormControlLabel, Switch, Tooltip, Typography, makeStyles} from '@material-ui/core';
import RequireIcon from '@material-ui/icons/ErrorOutline';
// import {FormattedMessage} from 'react-intl';

import * as api from 'src/services/Vision/event';
import message from 'src/components/Vision/Message';
// import {isUsingEvent2} from 'src/redux/actions/org';
import {useDetailContext} from '../../hook';

const useStyles = makeStyles(theme => ({
  switchWrap: {
    marginTop: theme.spacing(4),
  },
  switch: {
    color: '#F44336',
    '&$checked': {
      color: '#E31B0C',
    },
    '&$checked + $track': {
      backgroundColor: '#E31B0C',
    },
  },
}));

export function AlertInfoComponent() {
  const classes = useStyles();
  const {detail} = useDetailContext();
  const [isFalseAlarm, setIsFalseAlarm] = useState(() => {
    return detail.status === 'false_alarm';
  });
  const handleFalseAlert = async () => {
    try {
      if (!isFalseAlarm) {
        await api.markFalseAlert({id: detail.id, action: 'false_alarm'});
        message.success('Marked as false alert');
      } else {
        await api.resetFalseAlarm({id: detail.id, action: 'reset_new'});
        message.success('False alert undone');
      }
      setIsFalseAlarm(!isFalseAlarm);
    } catch (e) {
      message.error(e && e.message);
    }
  };
  return (
    <Grid container alignItems="center" classes={{container: classes.switchWrap}}>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={isFalseAlarm}
            onChange={handleFalseAlert}
            name="FalseAlert"
            className={classes.switch}
          />
        }
        label="False Alert"
      />
      <Tooltip
        placement="bottom-start"
        arrow
        title={
          <Typography>
            {isFalseAlarm ? (
              'tooltip.falseAlertsToggle.avtive'
            ) : (
              'tooltip.falseAlertsToggle'
            )}
          </Typography>
        }
      >
        <RequireIcon color="disabled" style={{height: '37px'}} />
      </Tooltip>
    </Grid>
  );
}
