import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Typography } from '@material-ui/core';

import { getText } from 'src/utils/MultilingualLoader';
import { cameraActions } from 'src/redux/actions';
import HistoryPlayer from './HistoryPlayer';

const mapStateToProps = (state, props) => {
  return {
    history: state.camera.history[props.camera.id],
    lineSize: state.camera.lineSize
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getHistory: data => dispatch(cameraActions.getHistory(data))
  };
};

class HistoryPlayerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slotStartTime: moment().startOf('day'),
      slotEndTime: moment().endOf('day'),
      updateInterval: null
    };
    this.handlePrevPageClick = this.handlePrevPageClick.bind(this);
    this.handleNextPageClick = this.handleNextPageClick.bind(this);
    this.handleGoLive = this.handleGoLive.bind(this);
    this.loadHistory = this.loadHistory.bind(this);
    this.updateHistory = this.updateHistory.bind(this);
  }

  componentDidMount() {
    this.loadHistory(false);
    this.setState({
      updateInterval: setInterval(this.updateHistory, 60000)
    });
  }

  componentWillUnmount() {
    if (this.state.updateInterval) clearInterval(this.state.updateInterval);
  }

  loadHistory(updating) {
    const { slotStartTime, slotEndTime } = this.state;
    const { camera, getHistory } = this.props;
    if (!this.props.history || !this.props.history.loading) getHistory({
      id: camera.id,
      type: camera.type,
      startTime: slotStartTime,
      endTime: slotEndTime,
      updating
    });
  }

  updateHistory() {
    if (this.state.slotStartTime.isSame(moment().startOf('day'))) this.loadHistory(true);
  }

  handlePrevPageClick() {
    this.setState((state, props) => ({
      slotStartTime: this.state.slotStartTime.subtract(1, 'd'),
      slotEndTime: this.state.slotEndTime.subtract(1, 'd')
    }), () => this.loadHistory(false));
  }

  handleNextPageClick() {
    this.setState((state, props) => ({
      slotStartTime: state.slotStartTime.add(1, 'd'),
      slotEndTime: state.slotEndTime.add(1, 'd')
    }), () => this.loadHistory(false));
  }

  handleGoLive() {
    this.setState({
      slotStartTime: moment().startOf('day'),
      slotEndTime: moment().endOf('day'),
    }, () => this.loadHistory(false));
    if (this.props.playerType !== 'live') this.props.shiftPlayer('live');
  }

  render() {
    const { history, playerType, shiftPlayer, minHeight, lineSize } = this.props;
    const { slotStartTime } = this.state;
    let playlist = history && history.videos ? history.videos : [];
    return (
      <React.Fragment>
        {history && (!history.loading || history.updating) ?
          <HistoryPlayer
            playlist={playlist.reverse()}
            playerType={playerType}
            shiftPlayer={shiftPlayer}
            minHeight={minHeight}
            curDate={moment(slotStartTime)}
            handlePrevPageClick={this.handlePrevPageClick}
            handleNextPageClick={this.handleNextPageClick}
            handleGoLive={this.handleGoLive}
            noPrev={moment().startOf('day').subtract(3, 'd').isSame(slotStartTime, 'day')}
            noNext={moment().startOf('day').isSame(slotStartTime, 'day')}
            lineSize={lineSize}
          />
          : <React.Fragment>
            {playerType === 'history' ?
              <div style={{ height: minHeight }}></div>
              : null}
            <Typography variant='body1' style={{ paddingTop: 30, textAlign: 'center', height: 100 }}>{getText('loading')}...</Typography>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryPlayerContainer);
