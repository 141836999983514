import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import moment from 'moment';
import MomentUtils from "@date-io/moment";

import DropDownSelectorVertical from 'src/components/Selectors/DropDownSelectorVertical';
import { getText, getDateLocale } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  timeCustom: {
    // marginTop: '0.5rem',
    display: 'flex',
    flexDirection: 'row'
  },
  timePicker: {
    flex: 1,
    paddingRight: '50px'
  },
});

const ranges = [
  { value: 'today', label: getText('today') },
  { value: 'oneWeek', label: getText('oneWeek') },
  { value: 'oneMonth', label: getText('oneMonth') },
  { value: 'customized', label: getText('customized') },
  // { value: 'all', label: getText('all') },
];

const TimeRangeSelector = props => {
  const { timeType, startTime, endTime, onChangeTimeType, onChangeStartTime, onChangeEndTime, classes } = props;
  return (
    <DropDownSelectorVertical
      title={getText('time')}
      options={ranges}
      value={timeType}
      onChange={onChangeTimeType}
      >
      {
        timeType === 'customized' ? 
        <MuiPickersUtilsProvider
          utils={MomentUtils} 
          moment={moment}
          locale={getDateLocale()}
        >
          <div className={classes.timeCustom}>
            <DateTimePicker
              className={classes.timePicker}
              value={startTime}
              disableFuture
              onChange={onChangeStartTime}
              label="Start Time:"
              showTodayButton
              cancelLabel={getText('cancel')}
              okLabel={getText('ok')}
              todayLabel={getText('today')}
              />
            <DateTimePicker
              className={classes.timePicker}
              value={endTime}
              disableFuture
              onChange={onChangeEndTime}
              label="End Time:"
              showTodayButton
              cancelLabel={getText('cancel')}
              okLabel={getText('ok')}
              todayLabel={getText('today')}
              />
          </div>
        </MuiPickersUtilsProvider> 
        : null
      }
    </DropDownSelectorVertical>
  );
};

TimeRangeSelector.propTypes = {
  timeType: PropTypes.string.isRequired,
  onChangeTimeType: PropTypes.func.isRequired,
  startTime: PropTypes.object,
  endTime: PropTypes.object,
  onChangeStartTime: PropTypes.func,
  onChangeEndTime: PropTypes.func,
};

TimeRangeSelector.defaultProps = {
  timeType: 'oneWeek',
  onChangeTimeType: (event, value) => {}
};

export default withStyles(styles)(TimeRangeSelector);
