import React from 'react';
import { connect } from 'react-redux';

import { siteActions } from 'src/redux/actions';
import SiteBoxList from './SiteBoxList';
import LoadingText from 'src/components/LoadingText';

const mapStateToProps = state => {
  return {
    boxes: state.site.boxes,
    boxLoading: state.site.boxLoading,
    boxUpdating: state.site.boxUpdating,
    sites: state.site.sites,
    siteLoading: state.site.siteLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBoxes: () => {
      dispatch(siteActions.getSiteBoxes());
    },
    associateBox: data => {
      dispatch(siteActions.updateSiteBox(data));
    },
    getSites: () => {
      dispatch(siteActions.getSites());
    }
  };
};

class SiteBoxListContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      editId: -1,
      editValue: {},
    }
  }

  componentDidMount(){
    if (!this.props.boxLoading) this.props.getBoxes();
    if (!this.props.siteLoading) this.props.getSites();
  }

  onClickEdit = id => event => {
    if (this.state.editId !== -1){
      return;
    }
    const { boxes } = this.props;
    this.setState({
      editId: id,
      editValue: boxes.find(box => box.id === id)
    });
  }

  onClickSubmit = boxId => event => {
    if (!this.props.boxUpdating){
      this.props.associateBox({
        id: boxId,
        site_id: this.state.editValue.site_id
      });
      this.setState({
        editId: -1,
        editValue: {}
      });
    }
  }

  handleChange = prop => event => {
    this.setState({
      editValue: {
        ...this.state.editValue,
        [prop]: event.target.value
      }
    });
  }

  render() {
    const { boxes, sites, boxLoading, siteLoading } = this.props;
    const { editId, editValue } = this.state;
    return (
      !boxLoading && !siteLoading ?
        <SiteBoxList
          boxes={boxes}
          sites={sites || []}
          editId={editId}
          editValue={editValue}
          onClickEdit={this.onClickEdit}
          onClickSubmit={this.onClickSubmit}
          handleChange={this.handleChange}
          />
        : <LoadingText />
    );
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SiteBoxListContainer);
