import React from 'react';
import { connect } from 'react-redux';

import Inspections from './Inspections';

const mapStateToProps = state => {
  const { algoOptions } = state.target;
  return { algoOptions };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};


class InspectionContainer extends React.Component {

  render() {
    const {targetId, onSelectSnapshot, onClose} = this.props;

    return (
        <Inspections targetId={targetId} onSelectSnapshot={onSelectSnapshot} onClose={onClose} />
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InspectionContainer);