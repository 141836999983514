import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  tableBody: {
    overflow: 'auto'
  },
  tableCellRoot: {
    padding: '12px 0px 12px 0px',
    '&:last-child':{
      paddingRight: 12
    }
  },
  selected: {
    //!important to override :hover
    backgroundColor: 'rgba(76,127,114,0.25) !important'
  },
  checkboxCell: {
    width: 60
  },
  statusCell: {
    width: 80
  },
  checkbox: {
    width: '24',
    padding: 0
  },
  statusOn: {
    padding: theme.spacing(0.5),
    border: '1px solid #4C7F72',
    borderRadius: '4px',
    color: '#4C7F72',
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: '0.9rem'
  },
  statusOff: {
    padding: theme.spacing(0.5),
    border: '1px solid #93052D',
    borderRadius: '4px',
    color: '#93052D',
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: '0.9rem'
  },
  sortLabel: {
    color: '#919191',
    fontSize: '1rem'
  },
  hiddenSortLabel: {
    visibility: 'hidden',
    '&:hover': {
      visibility: 'hidden'
    }
  }
});

const trimName = name => {
  if (name.length <= 20){
    return name;
  }
  else {
    return name.substr(0, 17) + '...';
  }
}

const CameraList = props => {
  const {classes, cameras, handleCameraSelect, selectedCameras, selectAllCameras, sortOrder, sortCameras} = props;
  let isSelectedAll = cameras.length > 0;
  for (let idx in cameras){
    if (!selectedCameras[cameras[idx].id]){
      isSelectedAll = false;
      break;
    }
  }
  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              classes={{root: classes.tableCellRoot}}
              className={classes.checkboxCell}
            >
              <Checkbox
                className={classes.checkbox}
                color="primary"
                checked={isSelectedAll}
                onChange={(e, checked) => {
                  selectAllCameras(checked);
              }}
              />
              <TableSortLabel
                active={true}
                direction={sortOrder.checked}
                onClick={() => {sortCameras('checked')}}
              />
            </TableCell>
            <TableCell
              classes={{root: classes.tableCellRoot}}
            >
              <TableSortLabel
                active={true}
                direction={sortOrder.name}
                onClick={() => {sortCameras('name')}}
              >
                <Typography className={classes.sortLabel}>
                  {getText('device_name')}
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell
              classes={{root: classes.tableCellRoot}}
              className={classes.statusCell}
              align='left'
            >
              <TableSortLabel
                active={true}
                direction={sortOrder.status}
                onClick={() => {sortCameras('status')}}
              >
                <Typography className={classes.sortLabel}>
                  {getText('status')}
                </Typography>
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <div className={classes.tableBody}>
      <Table>
        <TableBody>
        {
          cameras.map((camera, index) => {
            const status = camera.robotStatus || camera.cameraState;
            const online = status === 'online';
            return (
              <TableRow
                key={camera.id}
              >
                <TableCell
                  classes={{root: classes.tableCellRoot}}
                  className={classes.checkboxCell}
                  >
                  <Checkbox
                    className={classes.checkbox}
                    color="primary"
                    checked={selectedCameras[camera.id] ? selectedCameras[camera.id] : false}
                    onChange={(e, checked) => {
                      handleCameraSelect(camera, checked);
                    }}
                    />
                  <TableSortLabel className={classes.hiddenSortLabel} />
                </TableCell>
                <TableCell
                 classes={{root: classes.tableCellRoot}}
                  >
                  <Tooltip
                    placement="top"
                    enterDelay={200}
                    title={`${camera.name}${camera.site && camera.site.name ? ` - ${camera.site.name}` 
                      : camera.box && camera.box.site && camera.box.site.name ? ` - ${camera.box.site.name}` : ''}`}
                    >
                    <Typography variant="body1">
                      {trimName(camera.name)}
                    </Typography>
                  </Tooltip>

                  <span style={{display:'none'}}>{camera.id}</span>
                </TableCell>
                <TableCell
                  classes={{root: classes.tableCellRoot}}
                  className={classes.statusCell}
                  align='left'
                  >
                  <Typography variant="body1" className={online ? classes.statusOn : classes.statusOff}>
                    {getText(status)}
                  </Typography>
                </TableCell>
              </TableRow>
            )})
        }
        </TableBody>
      </Table>
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(CameraList);
