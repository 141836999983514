import { taskConstants } from 'src/redux/constants';
import { taskServices, inspectionServices, targetServices } from 'src/services';
import {notificationActions, taskActions} from './index';
import { getText } from 'src/utils/MultilingualLoader';
import { statusObj } from 'src/pages/Main/Tasks/TaskFilter/config';

function findHighest(list) {
	const array = list || [];
	let score = 0;
	let index = 0;
	array.forEach((item, i) => {
		if (item.score > score) {
			index = i;
			score = item.score;
		}
	});
	if (score > 0) {
		return array[index].label;
	}
	return '';
}

function success(result, curPage) {
	const tasks = result[0].list;
	const targets = result[1];
	const events = result[2];
	const algos = result[3];
	const map = new Map(targets.map(target => [target.id, target]));
	const eventMap = new Map(events.map(event => [event.value.split(':')[0], event]));
	const algoMap = new Map(algos.map(algo => [algo.value, algo]));
	return {
		type: taskConstants.GET_TASK_SUCCESS,
		list: tasks.map(task => {
				const target = map.get(task.target_id) || {};
				const algo = algoMap.get(task.algo) || {};
				const results = task.results.map(item => {
					const event = eventMap.get(item.algo.split(':')[0]) || {};
					return {
						label: event.label || item.algo,
						value: event.value || item.algo,
						...item,
					};
				});
				const tmp = eventMap.get(task.moderation.split(':')[0]);
				const moderation = tmp ? tmp.label : findHighest(results);
				const eventObj =  eventMap.get(task.event.split(':')[0]) || {};
				const event = task.event ? eventObj ? eventObj.label : task.event : '';
				return {
					...task,
					status: statusObj[task.status] || '',
					results,
					moderation,
					event,
					target_name: target.label || '',
					category: target.category || '',
					algoType: algo.label || task.algo,
				};
		}),
		count: result[0].count,
		curPage,
	};
}

export function getTasks(filters) {
  const request = () => {
    return {
      type: taskConstants.GET_TASK_REQUEST,
    };
  };

  const failure = (result) => {
    return {
      type: taskConstants.GET_TASK_FAILURE,
    };
	};
	
	const eventAction = (siteId, algos) => {
		return {
			type: taskConstants.SET_EVENT_OPTIONS,
			payload: {
				[siteId]: algos,
			},
		}
	};
	
	const algoAction = (siteId, algos) => {
		return {
			type: taskConstants.SET_ALGO_OPTIONS,
			payload: {
				[siteId]: algos,
			},
		}
	};

	const targetAction = (siteId, targets) => ({
		type: taskConstants.SET_TARGET_OPTIONS,
		payload: {
			[siteId]: targets,
		},
	});
  
  return (dispatch, getState) => {
		dispatch(request());
		const offset = 0;
		const state = getState();
		const siteId = state.site.curSite;
		if (!state.task.targetOptions[siteId] || !state.task.eventOptions[siteId] || !state.task.algoOptions[siteId]) {
			Promise.all(
				[
					taskServices.getTasks({...filters, offset, site_id: siteId}),
					targetServices.getTargetOptions({siteId}),
					taskServices.getEventOptions(siteId),
					targetServices.getAlgoOptions({siteId}),
				]
			).then(result => {
				dispatch(success(result, 1));
				dispatch(targetAction(siteId, result[1]));
				dispatch(eventAction(siteId, result[2]));
				dispatch(algoAction(siteId, result[3]));
			}).catch(error => {
				console.error(error);
				dispatch(failure());
			});
		} else {
			taskServices.getTasks({...filters, offset, site_id: siteId}).then(result => {
				dispatch(success([result, state.task.targetOptions[siteId], state.task.eventOptions[siteId], state.task.algoOptions[siteId]], 1));
			}).catch(error => {
				dispatch(failure());
			})
		}
  };
}

export const getMoreTasks = (filters, curPage) => {
	const request = () => {
		return {
			type: taskConstants.GET_TASK_REQUEST,
		};
	};

	const failure = () => {
		return {
			type: taskConstants.GET_TASK_FAILURE
		};
	};

	return (dispatch, getState) => {
		const offset = (curPage - 1) * filters.limit;

		dispatch(request());
		const state = getState();
		const siteId = state.site.curSite;
		const targets = state.task.targetOptions[siteId] || [];
		const events = state.task.eventOptions[siteId] || [];
		const algos = state.task.algoOptions[siteId] || [];
		taskServices.getTasks({...filters, offset, site_id: siteId})
			.then(results => {
				dispatch(success([results, targets, events, algos], curPage));
			})
			.catch(error => {
				dispatch(failure());
			});
	};
};

export const setFilter = filters => {
	return {
		type: taskConstants.SET_FILTER,
		filters
	};
};

export const review = (taskId, algoType) => {
	const success = (list, result) => {
		const array = list.map(item => item.id === result.id ? ({
				...item,
				result: true,
				moderation: item.results.find(cell => cell.value === result.moderation_types) ? item.results.find(cell => cell.value === result.moderation_types).label : '',
			}) : item)
		return {
			type: taskConstants.UPDATE_TASK_STATUS,
			payload: {
				list: array,
			},
		};
	};

	return (dispatch, getState) => {
		taskServices.review(taskId, algoType)
			.then(result => {
				const state = getState();
				dispatch(success(state.task.list, result.result));
				dispatch(notificationActions.addNotification(`${getText('moderate')}${getText('words_space')}${getText('succeeded')}`));
			})
			.catch(err => {
				dispatch(notificationActions.addNotification(`${getText('moderate')}${getText('words_space')}${getText('failed')}`));
			});
	}
}

export const createCaseFromInspection = (id, algoType, taskId) => {
	const success = (list, events, result) => {
		const eventMap = new Map(events.map(event => [event.value.split(':')[0], event]));
		if (result && result.algos && result.algos.length) {
			const event = eventMap.get(result.algos[0].split(':')[0]) || {};
			const array = list.map(item => item.id === taskId ? ({
				...item,
				event: event.label || '',
			}) : item);
			return {
				type: taskConstants.UPDATE_TASK_STATUS,
				payload: {
					list: array,
				},
			};
		}
		return {
			type: taskConstants.UPDATE_TASK_STATUS,
			payload: {},
		};
	};
  return (dispatch, getState) => {
		inspectionServices.updateInspection(id, {algo_types: algoType}).then((result) => {
			const state = getState();
			const siteId = state.site.curSite;
			const events = state.task.eventOptions[siteId] || [];
			dispatch(success(state.task.list, events, result));

			inspectionServices.createCaseFromInspection({inspection_id: id})
				.then(result => {
					dispatch(notificationActions.addNotification(`${getText('create_case')}${getText('words_space')}${getText('succeeded')}`));
				})
				.catch(err => {
					dispatch(notificationActions.addNotification(`${getText('create_case')}${getText('words_space')}${getText('failed')}`));
				});
		});
  }
}

export const updateTaskStatus = (taskId) => {
	const success = (list, result, events) => {
		const eventMap = new Map(events.map(event => [event.value.split(':')[0], event]));
		const results = result.results.map(item => {
			const event = eventMap.get(item.algo.split(':')[0]) || {};
			return {
				label: event.label || item.algo,
				value: event.value || item.algo,
				...item,
			};
		});
		const array = list.map(item => item.id === result.id ? ({
			...item,
			status: statusObj[result.status] || '',
			results,
		}) : item);
		return {
			type: taskConstants.UPDATE_TASK_STATUS,
			payload: {
				list: array,
			},
		}
	};

	return (dispatch, getState) => {
		const state = getState();
		const siteId = state.site.curSite;
		const url = window.location.href;
		const task = state.task;
		if (url.includes('/tasks') && siteId) { 
			const list = state.task.list;
			if (task.filters.moderateStatus === "null" && (list.length === 0 || taskId < list[0].id || (!list.find(item => item.id === taskId) && task.pagination.count <= 12))) {
				dispatch(notificationActions.addNotification(`${getText('status_update')}`));
			} else {
				taskServices.getTask(taskId).then(result => {
					const state = getState();
					const siteId = state.site.curSite;
					const events = state.task.eventOptions[siteId] || [];
					if (list.find(item => item.id === taskId)) {
						dispatch(success(list, result, events));
						dispatch(notificationActions.addNotification(`${result.name}${getText('words_space')}${getText('status_update')}`));
					}
				});
			}
		}
	}
}

export const notifyNewTask = (taskId) => {
	return (dispatch, getState) => {
		const state = getState();
		const task = state.task;
		const siteId = state.site.curSite;
		const url = window.location.href;
		if (url.includes('/tasks') && siteId) {
			if (task.pagination.count <= 12 && task.filters.moderateStatus === "null") {
				dispatch(notificationActions.addNotification(`${getText('new_task_created')}`));
			}
		}
	}
}
