import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import EventIcon from '@material-ui/icons/Announcement';
import LiveStreamingIcon from '@material-ui/icons/LiveTv';
import CreateIcon from '@material-ui/icons/Create';
import InspectionIcon from '@material-ui/icons/CameraAlt';
import RoutineHistoryIcon from '@material-ui/icons/PlaylistAddCheck';
import SensorIcon from '@material-ui/icons/WifiTethering';
import StoreIcon from '@material-ui/icons/Store';
import SearchIcon from '@material-ui/icons/Search';
import TaskIcon from '@material-ui/icons/Assignment';
import { Map as MapICon } from '@material-ui/icons';

import RobotIcon from 'src/components/icons/RobotIcon';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  listItemRoot: {
    padding: '12px 24px 12px 24px'
  },
  labelRoot: {
    margin: 0,
    padding: 0
  },
  itemSelected: {
    //!important to override :hover
    backgroundColor: 'rgba(76,127,114,0.25) !important'
  },
  label: {
    color: '#646474'
  },
  labelSelected: {
    color: '#646474',
    textShadow: '0 1px 1px #918181'
  },
  tableCellRoot: {
    padding: '14px 0px 14px 0px'
  },
  subItem: {
    '&:hover': {
      cursor: 'pointer'
    },
  },
  subItemSelected: {
    '&:hover': {
      cursor: 'pointer'
    },
    //!important to override :hover
    backgroundColor: 'rgb(76,127,114,0.1) !important'
  },
  subLabel: {
    borderBottom: 0,
    fontSize: '1rem',
    color: '#646474',
  },
  subLabelSelected: {
    borderBottom: 0,
    fontSize: '1rem',
    color: '#646474',
    textShadow: '0 1px 1px #918181',
  },
  icon: {
    minWidth: 0,
    marginRight: 16
  }
});

const isSelected = (pagePath, itemPath) => {
  if (itemPath === '/sitemanage') return pagePath.includes(itemPath);
  else return pagePath === itemPath;
};

const SideNavList = props => {
  const { classes, handleOnClick, role, pagePath, num_new, num_active } = props;

  const CaseStatus = props => {
    return (
      <Table>
        <TableBody>
          <TableRow
            hover={true}
            className={classes.subItem}
            selected={pagePath === '/cases/active'}
            classes={{ selected: classes.subItemSelected }}
            onClick={handleOnClick('/cases/active')}
          >
            <TableCell
              className={pagePath === '/cases/active' ? classes.subLabelSelected : classes.subLabel}
              classes={{root: classes.tableCellRoot}}
              style={{paddingLeft: 80}}
            >
              {getText('active')}:
            </TableCell>
            <TableCell
              align='right' 
              className={pagePath === '/cases/active' ? classes.subLabelSelected : classes.subLabel}
              classes={{root: classes.tableCellRoot}}
              style={{paddingRight: 5}}
            >
              {num_active}
            </TableCell>
          </TableRow>
          <TableRow
            hover={true}
            className={classes.subItem}
            selected={pagePath === '/cases/new'}
            classes={{ selected: classes.subItemSelected }}
            onClick={handleOnClick('/cases/new')}
          >
            <TableCell 
              className={pagePath === '/cases/new' ? classes.subLabelSelected : classes.subLabel}
              classes={{root: classes.tableCellRoot}}
              style={{paddingLeft: 80}}
              >
              {getText('unread')}:
            </TableCell>
            <TableCell 
              align='right' 
              className={pagePath === '/cases/new' ? classes.subLabelSelected : classes.subLabel}
              classes={{root: classes.tableCellRoot}}
              style={{paddingRight: 5}}
            >
              <font color='#C70039' text-shadow='0 1px 1px'>{num_new}</font>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const CameraType = props => {
    return (
      <Table>
        <TableBody>
          <TableRow
            hover={true}
            className={classes.subItem}
            selected={pagePath === '/livestreaming/robot'}
            classes={{ selected: classes.subItemSelected }}
            onClick={handleOnClick('/livestreaming/robot')}
          >
            <TableCell 
              className={pagePath === '/livestreaming/robot' ? classes.subLabelSelected : classes.subLabel}
              classes={{root: classes.tableCellRoot}}
              style={{paddingLeft: 80}}
            >
              {getText('robot')}
            </TableCell>
          </TableRow>
          <TableRow
            hover={true}
            className={classes.subItem}
            selected={pagePath === '/livestreaming/camera'}
            classes={{ selected: classes.subItemSelected }}
            onClick={handleOnClick('/livestreaming/camera')}
          >
            <TableCell 
              className={pagePath === '/livestreaming/camera' ? classes.subLabelSelected : classes.subLabel}
              classes={{root: classes.tableCellRoot}}
              style={{paddingLeft: 80}}
            >
              {getText('camera')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const listItems = [
    {
      label: getText('cases'), icon: <EventIcon />, path: '/cases',
      hide: !['monitoring_operator', 'customer', 'site_manager'].includes(role),
      children: <CaseStatus />
    },
    {
      label: getText('live_streaming'), icon: <LiveStreamingIcon />, path: '/livestreaming',
      hide: !['monitoring_operator', 'customer', 'site_manager'].includes(role),
      children: <CameraType />
    },
    {
      label: getText('robots'), icon: <RobotIcon />, path: '/robots',
      hide: !['monitoring_operator', 'customer', 'site_manager'].includes(role)
    },
    { 
      label: getText('maps'), icon: <MapICon />, path: '/maps', 
      hide: !['monitoring_operator', 'customer', 'site_manager'].includes(role) 
    },
    { label: getText('account_manage'), icon: <CreateIcon />, path: '/adminmanage', hide: role !== 'admin' },
    { label: getText('account_manage'), icon: <CreateIcon />, path: '/guardmanage', hide: role !== 'guard_admin' },
    { label: getText('routine_history'), icon: <RoutineHistoryIcon />, path: '/routine_history',  
      hide: !['customer', 'site_manager'].includes(role)  },
    { label: getText('site_manage'), icon: <CreateIcon />, path: '/sitemanage', hide: role !== 'customer' },
    { label: getText('inspections'), icon: <InspectionIcon />, path: '/inspections', 
      hide: !['customer', 'site_manager'].includes(role) },
    { label: getText('inspection_configs'), icon: <StoreIcon />, path: '/inspection_configs', hide: role !== 'customer' },
    // { label: getText('detection_tasks'), icon: <TaskIcon />, path: '/tasks', hide: role !== 'customer' },
    { label: getText('sensors'), icon: <SensorIcon />, path: '/sensors', hide: role !== 'customer' },
    { label: getText('events'), icon: <SearchIcon />, path: '/events', hide: role !== 'customer' && role !== 'site_manager' && role !== 'monitoring_operator'},

  ];

  return (
    <List component="nav">
      {listItems.filter(item => !item.hide).map((item, index) => (
        <React.Fragment key={item.label}>
          <ListItem
            classes={{ root: classes.listItemRoot, selected: classes.itemSelected }}
            button={true}
            selected={isSelected(pagePath, item.path)}
            onClick={handleOnClick(item.path)}
          >
            <ListItemIcon className={classes.icon}>
              {item.icon}
            </ListItemIcon>
            <ListItemText
              classes={isSelected(pagePath, item.path) ? { root: classes.labelRoot, primary: classes.labelSelected }
                : { root: classes.labelRoot, primary: classes.label }}
              primary={item.label}
            />
            {/* Spacer */}
            <Typography style={{ flex: 1 }} />
          </ListItem>
          {item.children}
        </React.Fragment>
      ))}
    </List>
  );
};

SideNavList.propTypes = {
  classes: PropTypes.object.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  num_new: PropTypes.number.isRequired,
  num_active: PropTypes.number.isRequired,
};

SideNavList.defaultProps = {
  handleOnClick: () => { },
  num_new: 0,
  num_active: 0
};

export default withStyles(styles)(SideNavList);
