import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  tableBody: {
    overflow: 'auto',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  tableCellRoot: {
    padding: '12px 12px 12px 12px',
    '&:last-child': {
      paddingRight: 12
    }
  },
  selected: {
    //!important to override :hover
    backgroundColor: 'rgba(76,127,114,0.25) !important'
  }
});

const trimName = name => {
  if (name.length <= 30) {
    return name;
  }
  else {
    return name.substr(0, 27) + '...';
  }
}

const RobotList = props => {
  const { classes, robots, selectedRobot, handleRobotSelect } = props;
  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              classes={{ root: classes.tableCellRoot }}
            >
              <Typography>
                {getText('robot')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <div className={classes.tableBody}>
        <Table>
          <TableBody>
            {
              robots.map((robot, index) => {
                return (
                  <TableRow
                    key={robot.id}
                    classes={{
                      selected: classes.selected
                    }}
                    selected={selectedRobot && selectedRobot.id === robot.id}
                    hover={true}
                    onClick={handleRobotSelect(robot)}
                  >
                    <TableCell
                      classes={{ root: classes.tableCellRoot }}
                    >
                      <Tooltip
                        placement="top"
                        enterDelay={200}
                        title={`${robot.name}${robot.site ? ` - ${robot.site.name}` : ''}`}
                      >
                        <Typography variant="body1">
                          {trimName(robot.name)}
                        </Typography>
                      </Tooltip>
                      <span style={{ display: 'none' }}>{robot.id}</span>
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  );
};

RobotList.propTypes = {
  selectedRobot: PropTypes.object,
  robots: PropTypes.array.isRequired,
  handleRobotSelect: PropTypes.func.isRequired
};

RobotList.defaultProps = {
  selectedRobot: null,
  robots: [],
  handleRobotSelect: () => { }
};

export default withStyles(styles)(RobotList);
