import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import SensorMap from './SensorMap';
import SensorFilter from './SensorFilter';

const styles = theme => ({
});

const SensorPanel = props => {
  const { classes, selectedRobot } = props;
  
  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item>
        <SensorFilter selectedRobot={selectedRobot} />
      </Grid>
      <Grid item>
        <SensorMap selectedRobot={selectedRobot} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(SensorPanel);
