import React, { useState, useMemo, useEffect, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import uuid from "uuid";
import {
  withStyles,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  TableHead,
  CircularProgress,
  Backdrop,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Edit as EditIcon, Check as CheckIcon } from "@material-ui/icons";

import DropDownSelectorHorizontal from "src/components/Selectors/DropDownSelectorHorizontal";
import { notificationActions } from "src/redux/actions";
import { getText } from "src/utils/MultilingualLoader";
import {
  getRoutineExecutions,
  routineExecute,
  getRoutineExecutionDetail,
  updateRoutineExecution,
  deleteRoutineExecution,
  updateRoutineExecutionState,
} from "src/services/robot";
import RoutineExecutionStatus from "./RoutineExecutionStatus";
import ExecutionPagination from "./ExecutionPagination";
import RoutineNextAction from "./RoutineNextAction";
import Modal from "src/components/Modal";
import RoutineList from "./RoutineList";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    margin: "12px 0",
  },
  selectorInput: {
    width: "140px",
    padding: "5px 20px 5px 20px",
  },
  cell: {
    textAlign: "center",
  },
  tooltip: {
    padding: theme.spacing(2),
  },
  cellNo: {
    maxWidth: 120,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  notifyBackdrop: {
    background: "transparent",
    zIndex: theme.zIndex.drawer + 1,
  },
  notifyMsg: {
    color: "#333",
    width: 400,
    padding: 30,
    backgroundColor: "#fff",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    marginTop: 10,
    cursor: "pointer",
  },
  middle: {
    verticalAlign: "middle",
  },
});

const options = [
  {
    label: "doing",
    value: "doing",
  },
  {
    label: "done",
    value: "done",
  },
  {
    label: "succeeded",
    value: "succeeded",
  },
  {
    label: "initial",
    value: "initial",
  },
  {
    label: "interrupted",
    value: "interrupted",
  },
  {
    label: "analyzed",
    value: "analyzed",
  },
];

const mapDispatchToProps = (dispatch) => {
  return {
    addNotification: (msg) => {
      dispatch(notificationActions.addNotification(msg));
    },
  };
};

const Execution = (props) => {
  const { classes, configs, selected, addNotification } = props;
  const [deleteExecution, setDeleteExecution] = useState(null);

  const routines = useMemo(() => {
    return [
      {
        label: getText("all"),
        value: "all",
      },
    ].concat(
      ((configs && configs.routines) || []).map((routine) => ({
        label: routine.name,
        value: routine.id,
      }))
    );
  }, [configs]);

  const [filter, setFilter] = useState({
    routineId: "all",
    startDate: moment(),
    endDate: moment(),
    priority: 2,
  });
  const [list, setList] = useState([]);
  const [selectedExecution, setSelectedExecution] = useState(null);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [curPage, setCurPage] = useState(1);
  const [wsExecutionId, setWsExectionId] = useState(null);
  const [wsStageIndex, setWsStageIndex] = useState(null);
  const [editExecutionId, setEditExecutionId] = useState(null);
  const [optionValue, setOptionValue] = useState(null);
  const [tips, setTips] = useState("");
  const [selectedRoutine, setSelectedRoutine] = useState(null);
  const [showRoutineList, setShowRoutineList] = useState(false);

  const lastExecution = useRef(null);

  useEffect(() => {
    if (selected.id) {
      const initFilter = {
        routineId: "all",
        startDate: moment(),
        endDate: moment(),
        priority: 2,
      };
      setFilter(initFilter);
      setList([]);
      loadData(selected.id, initFilter, 0);
    }
  }, [selected && selected.id]);

  useEffect(() => {
    window.wsAPP.on("robot.update_nav_state", handleNavStateChange);
    return () =>
      window.wsAPP.off("robot.update_nav_state", handleNavStateChange);
  }, [selected && selected.id, list, selectedExecution]);

  function handleNavStateChange(res) {
    if (res.arg.robot_id === (selected && selected.id)) {
      if (res.arg.routine_execution) {
        setWsExectionId(res.arg.routine_execution.id);
        setWsStageIndex(res.arg.routine_execution.stage_index);
      } else {
        setWsExectionId(null);
        setWsStageIndex(null);
      }
      if (
        (!res.arg.routine_execution && lastExecution.current) ||
        (res.arg.routine_execution &&
          (!lastExecution.current ||
            lastExecution.current.updated_at !==
              res.arg.routine_execution.updated_at))
      ) {
        const routineExecutionId =
          res.arg.routine_execution_id || lastExecution.current.id;

        if (routineExecutionId) {
          getRoutineExecutionDetail(res.arg.robot_id, routineExecutionId).then(
            (res) => {
              if (res.result) {
                setList(
                  list.map((execution) =>
                    execution.id === res.result.id
                      ? {
                          ...res.result,
                          routineName: execution.routineName,
                          endedAt: execution.endedAt,
                        }
                      : execution
                  )
                );
                if (
                  res.result.id === (selectedExecution && selectedExecution.id)
                ) {
                  setSelectedExecution(res.result);
                }
              }
            }
          );
        }
        lastExecution.current = res.arg.routine_execution;
      }
    }
  }

  const handleChange = (prop) => (e) => {
    const newFilter = {
      ...filter,
      [prop]: e.target ? e.target.value : e,
    };
    setFilter(newFilter);

    if (newFilter.routineId) {
      setCurPage(1);
      loadData(selected.id, newFilter, 0);
    }
  };

  function loadData(robotId, filter, offset) {
    setLoading(true);
    getRoutineExecutions(robotId, filter, offset)
      .then((res) => {
        setList(res.results);
        setCount(res.count);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function refresh() {
    loadData(selected.id, filter, 0);
  }

  function onPageClick(pageNo) {
    setCurPage(pageNo);
    loadData(selected.id, filter, (pageNo - 1) * 5);
  }

  function handleUpdate(robotId, executionId, stageIndex, optionValue) {
    return updateRoutineExecution(
      robotId,
      executionId,
      stageIndex,
      optionValue
    ).then(() => {
      setList(
        list.map((execution) =>
          execution.id === executionId
            ? {
                ...execution,
                stages: execution.stages.map((stage, index) =>
                  stageIndex === index
                    ? {
                        ...stage,
                        state: optionValue,
                      }
                    : stage
                ),
              }
            : execution
        )
      );
      setSelectedExecution({
        ...selectedExecution,
        stages: selectedExecution.stages.map((stage, index) =>
          stageIndex === index
            ? {
                ...stage,
                state: optionValue,
              }
            : stage
        ),
      });
    });
  }

  function delay(seconds) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(), seconds * 1000)
    );
  }

  function startRoutine(routineId, nextAction) {
    if (selected && selected.id) {
      setTips(getText("routine_task_creating"));
      routineExecute({
        robot_id: selected.id,
        routine_id: routineId,
        priority: filter.priority,
        routine_execution_daily_limit: (window.location.search || "").includes(
          "routine_execution_limit"
        )
          ? 0
          : 1,
      })
        .then((res) => {
          const message = {
            id: uuid(),
            act: "robot.start_routine",
            arg: {
              robot_id: selected.id,
              routine_execution_id: res.routineExecuteId,
              next_action: nextAction,
            },
          };
          setTips(getText("instruction_sending"));
          if (window.wsAPP) {
            delay(8).then(() => {
              window.wsAPP.sendMsg(message, {
                hasRes: true,
                timeout: 15000,
                callback: (res, err) => {
                  showTips(res, err, getText("start_routine"));
                  setCurPage(1);
                  loadData(selected.id, filter, 0);
                },
              });
            });
          }
        })
        .catch((e) => {
          setTips(e.message);
          setTimeout(() => setTips(""), 3000);
        });
    }
  }

  function showTips(res, err, successTip) {
    if (!err) {
      if (res.err.ec === 0) {
        setTips(
          `${getText("instruction_send_success")}${getText(
            "words_space"
          )}${successTip}`
        );
      } else {
        setTips(
          getText("instruction_send_fail") +
            res.err.em +
            `${res.err.dm ? `(${res.err.dm})` : ""}`
        );
      }
    } else {
      setTips(getText("instruction_send_fail") + err.message);
    }
    setTimeout(() => {
      setTips("");
    }, 3500);
  }

  function handleEditExecution(executionId, executionState) {
    if (options.map((option) => option.value).includes(executionState)) {
      setEditExecutionId(executionId);
      setOptionValue(executionState);
    }
  }

  function handleSubmitExecution(executionId) {
    updateRoutineExecutionState(selected.id, executionId, optionValue)
      .then((res) => {
        setList(
          list.map((execution) =>
            execution.id === executionId
              ? {
                  ...execution,
                  state: res.state,
                }
              : execution
          )
        );
      })
      .finally(() => {
        setEditExecutionId(null);
      });
  }

  function handleCompleteRoutine(routineId) {
    setSelectedRoutine({
      routineId,
      action: "robot.complete_routine",
      tips: getText("routine_complete"),
    });
  }

  function handleResumePauseRoutine(routineId) {
    setSelectedRoutine({
      routineId,
      action:
        wsExecutionId === routineId
          ? "robot.pause_routine"
          : "robot.resume_routine",
      tips: getText("routine_resume"),
    });
  }

  function handleNextActionConfrim(nextAction) {
    const { routineId, action, tips } = selectedRoutine;
    setSelectedRoutine(null);
    setTips(getText("instruction_sending"));
    if (window.wsAPP)
      window.wsAPP.sendMsg(
        {
          id: uuid(),
          act: action,
          arg: {
            routine_execution_id: routineId,
            robot_id: selected.id,
            next_action: nextAction,
          },
        },
        {
          hasRes: true,
          timeout: 15000,
          callback: (res, err) => {
            showTips(res, err, tips);
          },
        }
      );
  }

  async function handleDelete() {
    try {
      await deleteRoutineExecution(selected.id, deleteExecution.id);
      setList(list.filter((item) => item.id !== deleteExecution.id));
      addNotification(getText("operate_success"));
      setDeleteExecution(null);
    } catch (e) {}
  }

  return (
    <>
      <Grid container direction="column">
        <Grid container spacing={2} alignItems="center" justify="space-between">
          <Grid item xs container alignItems="center" justify="space-between">
            <DropDownSelectorHorizontal
              title={getText("routines")}
              options={routines}
              value={filter.routineId}
              onChange={handleChange("routineId")}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                id="date-picker-inline"
                label={getText("start_date")}
                value={filter.startDate}
                onChange={handleChange("startDate")}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                autoOk={true}
                style={{ width: 135 }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                id="date-picker-inline2"
                label={getText("end_date")}
                value={filter.endDate}
                onChange={handleChange("endDate")}
                autoOk={true}
                style={{ width: 135 }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <Button
              disabled={!filter.routineId || loading}
              color="primary"
              variant="outlined"
              style={{ marginRight: 16 }}
              onClick={refresh}
            >
              {getText("refresh")}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setShowRoutineList(true)}
            >
              {getText("start_routine")}
            </Button>
          </Grid>
        </Grid>
        <Table style={{ marginBottom: 6 }}>
          <TableHead>
            <TableRow>
              <TableCell>{getText("routines")}</TableCell>
              <TableCell>{getText("start_time")}</TableCell>
              <TableCell>{getText("end_time")}</TableCell>
              <TableCell>{getText("state")}</TableCell>
              <TableCell className={classes.cell}>
                {getText("actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((execution) => (
              <TableRow
                key={execution.id}
                style={{
                  background:
                    wsExecutionId === execution.id
                      ? "rgba(0,0,0,0.1)"
                      : "transparent",
                }}
              >
                <TableCell>
                  <div className={classes.cellNo} title={execution.routineName}>
                    {execution.routineName}
                  </div>
                </TableCell>
                <TableCell>{execution.startedAt}</TableCell>
                <TableCell>{execution.endedAt || "--"}</TableCell>
                <TableCell>
                  {execution.id === editExecutionId ? (
                    <Select
                      variant="outlined"
                      className={classes.middle}
                      value={optionValue}
                      input={
                        <OutlinedInput
                          labelWidth={0}
                          classes={{ input: classes.selectorInput }}
                        />
                      }
                      onChange={(event) => setOptionValue(event.target.value)}
                    >
                      {options.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <span
                      style={{
                        display: "inline-block",
                        verticalAlign: 10,
                      }}
                    >
                      {execution.state}
                    </span>
                  )}
                  {execution.id === editExecutionId ? (
                    <CheckIcon
                      className={classes.iconButton}
                      onClick={() => handleSubmitExecution(execution.id)}
                    />
                  ) : (
                    <EditIcon
                      className={classes.iconButton}
                      onClick={() =>
                        handleEditExecution(execution.id, execution.state)
                      }
                    />
                  )}
                </TableCell>
                <TableCell className={classes.cell}>
                  <Button
                    color="primary"
                    onClick={() => setSelectedExecution(execution)}
                  >
                    {getText("details")}
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => handleResumePauseRoutine(execution.id)}
                  >
                    {wsExecutionId === execution.id
                      ? getText("routine_pause")
                      : getText("routine_resume")}
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => handleCompleteRoutine(execution.id)}
                  >
                    {getText("routine_complete")}
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => setDeleteExecution(execution)}
                  >
                    {getText("delete")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading
          ? getText("loading")
          : list.length === 0
          ? getText("no_data")
          : null}
        {!loading && (
          <ExecutionPagination
            count={count}
            curPage={curPage}
            filter={filter}
            onPageClick={onPageClick}
          />
        )}
      </Grid>
      {selectedExecution && (
        <RoutineExecutionStatus
          execution={selectedExecution}
          robotId={selected.id}
          wsExecutionId={wsExecutionId}
          wsStageIndex={wsStageIndex}
          handleUpdate={handleUpdate}
          onClose={() => setSelectedExecution(null)}
        />
      )}
      <Backdrop className={classes.backdrop} open={Boolean(tips)}>
        <Grid className={classes.notifyMsg}>
          <CircularProgress color="inherit" />
          <Typography style={{ marginTop: 10 }}>{tips}</Typography>
        </Grid>
      </Backdrop>
      {selectedRoutine && (
        <RoutineNextAction
          onConfirm={handleNextActionConfrim}
          onCancel={() => setSelectedRoutine(null)}
        />
      )}
      {showRoutineList && (
        <RoutineList
          routines={routines}
          onConfirm={(routineId, nextAction) => {
            setShowRoutineList(false);
            startRoutine(routineId, nextAction);
          }}
          onCancel={() => setShowRoutineList(false)}
        />
      )}
      {deleteExecution && (
        <Modal
          title={getText("delete_execution") + "?"}
          onCancel={() => setDeleteExecution(null)}
          onConfirm={handleDelete}
        >
          {deleteExecution.routineName}
        </Modal>
      )}
    </>
  );
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(Execution));
