import React from 'react';
import {Grid, makeStyles} from '@material-ui/core';

import {ImageLoader} from '../imageLoader';

const useStyles = makeStyles(() => ({
  info: {
    width: '100%',
    height: 0,
    paddingTop: (163 / 287) * 100 + '%',
    position: 'relative',
  },
  mediaWrap: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    overflow: 'hidden',
    '& > *': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
}));

export function MediaDisplay({video, img}) {
  const classes = useStyles();
  return (
    <Grid container classes={{container: classes.info}} alignItems="center" justify="center">
      <div className={classes.mediaWrap}>
        {video ? (
          <video src={video} controls autoPlay muted />
        ) : img ? (
          <ImageLoader url={img} />
        ) : (
          'No message'
        )}
      </div>
    </Grid>
  );
}
