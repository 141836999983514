import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

import { getText } from 'src/utils/MultilingualLoader';
import { taskActions, notificationActions } from 'src/redux/actions';
import TaskList from './TaskList';

const mapStateToProps = state => {
  const siteId = state.site.curSite;
  const events = state.task.eventOptions[siteId] || [];
  return {
    list: state.task.list,
    pagination: state.task.pagination,
    filters: state.task.filters,
    events,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getTasks: filters => {
      dispatch(taskActions.getTasks(filters))
    },
    addNotification: msg => {
      dispatch(notificationActions.addNotification(msg));
    },
    createCase: (id, algoType, taskId) => {
      dispatch(taskActions.createCaseFromInspection(id, algoType, taskId));
    },
    review: (taskId, algoType) => {
      dispatch(taskActions.review(taskId, algoType));
    },
  };
};

class TaskListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewStatus: 'all',
    };
  }

  componentDidMount() {
    const {getTasks} = this.props;
    getTasks({
      limit: 12,
    });
  }

  createCase = (id, algoType, taskId) => {
    this.props.createCase(id, algoType, taskId)
  }

  render() {
    const {pagination, list, review, events} = this.props;

    return (
      <>
        {
          list && list.length !== 0 ?
            <TaskList
              tasklist={list}
              createCase={this.createCase}
              review={review}
              eventOptions={events}
              pagination={pagination}
            />
            : <Typography variant='body1' style={{padding: 16}}>{getText('no_data')}</Typography>
        }
      </>
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskListContainer);