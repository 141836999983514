import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, TextField, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    paddingLeft: 20,
    width: '100%'
  },
  textField: {
    margin: 0,
    width: 200
  },
  submitButton: {
    maxWidth: 100,
  },
  label: {
    color: 'white',
    textTransform: 'initial'
  },
  selectorLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  formLabel: {
    width: '100px'
  },
});

const InspectionFilter = props => {
  const {
    classes,
    name,
    category,
    floor,
    algo_config,
    algo_reference,
    handleChange,
    handleSubmit,
    handleAutoComplete,
    showMutator,
    categoryOptions,
    floorOptions,
    targetOptions,
    algoConfigOptions,
    algoReferenceOptions,
  } = props;

  return (
    <Grid
      className={classes.root}
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-end"
      spacing={2}
    >
      <Grid item>
        <TextField
          select
          className={classes.textField}
          label={`${getText('target_type')}`}
          value={category}
          onChange={handleChange('category')}
        >
          {categoryOptions.map((option, index) => (
            <MenuItem key={option.value + index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item>
        <Autocomplete
          options={category && category !== 'all' ? targetOptions.filter(item => item.category === category) : targetOptions}
          getOptionLabel={option => option && option.label ? option.label : ''}
          disableListWrap
          onChange={handleAutoComplete}
          value={name}
          renderInput={params => (
            <TextField
              {...params}
              className={classes.textField}
              label={getText('target_name')}
              margin="normal"
            />
          )}
        />
      </Grid>
      <Grid item>
        <TextField
          select
          className={classes.textField}
          label={`${getText('floor')}`}
          value={floor}
          onChange={handleChange('floor')}
        >
          {floorOptions.map((option, index) => (
            <MenuItem key={option.value + index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item>
        <TextField
          select
          className={classes.textField}
          label={`${getText('algo_config')}`}
          value={algo_config}
          onChange={handleChange('algo_config')}
        >
          {algoConfigOptions.map((option, index) => (
            <MenuItem key={option.value + index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item>
        <TextField
          select
          className={classes.textField}
          label={`${getText('algo_reference')}`}
          value={algo_reference}
          onChange={handleChange('algo_reference')}
        >
          {algoReferenceOptions.map((option, index) => (
            <MenuItem key={option.value + index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item className={classes.submit}>
        <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          className={classes.submitButton}
          onClick={handleSubmit}
        >
          <Typography variant="body2" className={classes.label}>
            {getText('submit')}
          </Typography>
        </Button>
      </Grid>
      <Grid item className={classes.submit}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth={true}
          className={classes.submitButton}
          onClick={showMutator}
        >
          <Typography variant="body2">
            {getText('copy_route')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(InspectionFilter);