import React from 'react';
import Moveable from 'react-moveable';
import { withStyles, Card } from '@material-ui/core';

import RealMap from './RealMap';
import { getDefaultMapCenter } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  card: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  image: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 99,
    maxWidth: '100%',
    maxHeight: '100%',
    opacity: 0.5
  },
});

class MapCalibration extends React.Component {
  componentDidMount() {
    const { calibration } = this.props;
    if (calibration && this.calibratedMap) {
      const transform = `matrix(${calibration.a_00}, ${calibration.a_01}, ${calibration.a_10}, ${calibration.a_11}, 0, 0)`;
      this.calibratedMap.style.transform = transform;
    }
  }

  cmRef = image => {
    this.calibratedMap = image;
  }

  bgRef = card => {
    this.background = card;
  }

  render() {
    const { classes, imageSrc, site, imageRatio, calibrationData, setCalibrationData } = this.props;
    const polygons = (site && site.polygons) || [];
    const polygonPoints = [...polygons.map(polygon => polygon.geo_points)].flat();
    let initialCenter;
    if (polygonPoints.length > 0) {
      initialCenter = { 
        lat: polygonPoints.map(position => position.lat).reduce((totalLat, lat) => totalLat + lat, 0) / polygonPoints.length,
        lng: polygonPoints.map(position => position.lng).reduce((totalLng, lng) => totalLng + lng, 0) / polygonPoints.length,
      };
    }
    else {
      initialCenter = getDefaultMapCenter();
    }

    return (
      <Card className={classes.card} ref={this.bgRef}>
        <img
          className={classes.image}
          ref={this.cmRef}
          src={imageSrc}
          alt="Calibrated Map"
          width={imageRatio >= 1 ? 500 : 500 * imageRatio}
          height={imageRatio < 1 ? 500 : 500 / imageRatio}
        />
        {this.background && this.calibratedMap &&
          <Moveable
            target={this.calibratedMap}
            snappable={true}
            bounds={{ left: 0, right: this.background.offsetWidth, top: 0, bottom: this.background.offsetHeight }}
            draggable={true}
            onDrag={({
              target,
              transform,
            }) => {
              target.style.transform = transform;
            }}
            resizable={true}
            onResize={({
              target, width, height,
              delta,
            }) => {
              delta[0] && (target.style.width = `${width}px`);
              delta[1] && (target.style.height = `${height}px`);
              const resizeMatrix = [width/height/imageRatio, 0, 0, 1];
              setCalibrationData({
                ...calibrationData,
                resizeMatrix
              });
            }}
            rotatable={true}
            onRotate={({
              target,
              transform,
              ...args
            }) => {
              target.style.transform = transform;
            }}
            onRotateEnd={({ target }) => {
              const transform = target.style.transform;
              const reDegree = /-?\d*.?\d*deg/;
              let newDegree = transform.match(reDegree) || 0;
              if (newDegree && newDegree[0] && newDegree[0].length > 3) {
                newDegree = parseFloat(newDegree[0].slice(0, newDegree[0].length - 3)) + calibrationData.rotateDegree;
              }
              setCalibrationData({
                ...calibrationData,
                rotateDegree: newDegree
              })
            }}
          />
        }
        <RealMap 
          initialCenter={initialCenter}
        />
      </Card>
    );
  }
}

export default withStyles(styles)(MapCalibration);