import React from 'react';
import { Layer, Arrow, Image } from 'react-konva';
import useImage from 'use-image';

import navigationImg from 'public/navigation.svg'

const imageWidth = 24;
const imageHeight = 36;

const RelocalizationImage = props => {
  const { position, scale } = props;
  const [ image ] = useImage(navigationImg);
  return <Image image={image} width={imageWidth/Math.sqrt(scale)} height={imageHeight/Math.sqrt(scale)} 
    x={Math.max(position.x, 0)} y={Math.max(position.y, 0)} offsetX={imageWidth/2/Math.sqrt(scale)} offsetY={imageHeight/Math.sqrt(scale)}
    rotation={position.theta} />;
};

const RobotReLocalization = props => {
  const { position, relocalizationPoint, curRelocalizationPosition, getImagePosition, scale } = props;
  let arrowPoints = Object.assign([], relocalizationPoint);
  if (arrowPoints.length === 2) {
    const headPosition = getImagePosition(arrowPoints[0], arrowPoints[1]);
    arrowPoints[0] = headPosition.x;
    arrowPoints[1] = headPosition.y;
  }
  arrowPoints = arrowPoints.concat([position.x, position.y]);
  let curImageRelocalizationPosition = null
  if (curRelocalizationPosition) {
    const position = getImagePosition(curRelocalizationPosition.x, curRelocalizationPosition.y);
    const theta = (360 - (curRelocalizationPosition.theta >= 0 ? curRelocalizationPosition.theta / Math.PI * 180
      : 360 + curRelocalizationPosition.theta / Math.PI * 180)) + 90 % 360;
    curImageRelocalizationPosition = {
      x: position.x,
      y: position.y,
      theta
    }
  }

  return (
    <React.Fragment>
    {
      curImageRelocalizationPosition && <Layer><RelocalizationImage position={curImageRelocalizationPosition} scale={scale} /></Layer> 
    }
    {
      arrowPoints.length === 4 &&
      <Layer>
        <Arrow points={arrowPoints} tension={0} stroke="blue" strokeWidth={1/Math.sqrt(scale)} />
      </Layer> 
    }
    </React.Fragment>);
};

export default RobotReLocalization;
