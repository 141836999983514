import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  withStyles,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
  Slider,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import {
  AddCircle as AddIcon,
  RemoveCircle as RemoveIcon,
} from "@material-ui/icons";
import { getText } from "src/utils/MultilingualLoader";

const styles = (theme) => ({
  list: {
    display: "inline-block",
    borderRadius: 5,
    border: "2px solid rgb(216, 215, 215)",
    padding: theme.spacing(1),
  },
  selectedItem: {
    //!important to override :hover
    backgroundColor: "rgba(76,127,114,0.25) !important",
  },

  listItem: {
    borderBottom: "2px solid rgba(0,0,0,0.07)",
    "&:last-child": {
      borderBottom: 0,
    },
  },
  textField: {
    width: "100%",
  },
  IconButton: {
    padding: 5,
    color: theme.palette.primary.main,
  },
});

const actOptions = ["snapshot", "video", "notify", "stop"];

const EditWaypoint = (props) => {
  const { classes, waypoint, updWaypoint } = props;
  const [actionIdx, setActionIdx] = useState(null);
  const { curSite } = useSelector((state) => state.site);
  const targetList = useSelector(
    (state) => state.target.targetOptions[curSite]
  );
  const actions = (waypoint && waypoint.actions) || [];

  const onWaypointChange = (field) => (event) => {
    const newWaypoint = Object.assign({}, waypoint);
    newWaypoint[field] = event.target.value;
    updWaypoint(newWaypoint);
  };

  const onActionChange = (field) => (event) => {
    const newWaypoint = Object.assign({}, waypoint);
    const action = newWaypoint.actions && newWaypoint.actions[actionIdx];
    action[field] = event.target.value;
    updWaypoint(newWaypoint);
  };

  const onActionArgChange = (field) => (event) => {
    const newWaypoint = Object.assign({}, waypoint);
    const action = newWaypoint.actions && newWaypoint.actions[actionIdx];
    if (action && action.arg) {
      let value = event.target.value;
      switch (field) {
        case "duration":
          value = parseInt(value);
          value = Math.max(value, 0);
          break;
        default:
          break;
      }
      action.arg[field] = String(value);
      updWaypoint(newWaypoint);
    }
  };

  const onActionArgSliderChange = (field) => (event, newValue) => {
    const newWaypoint = Object.assign({}, waypoint);
    const action = newWaypoint.actions && newWaypoint.actions[actionIdx];
    if (action && action.arg) {
      let value = (newValue * Math.PI) / 180;
      action.arg[field] = value;
      updWaypoint(newWaypoint);
    }
  };

  const addAction = () => {
    const newWaypoint = Object.assign({}, waypoint);
    if (!newWaypoint.actions) return;
    const action = {
      act: "",
      arg: {
        duration: 0,
        pitch: 0,
        theta: 0,
      },
      name: "",
    };
    newWaypoint.actions.push(action);
    setActionIdx(newWaypoint.actions.length - 1);
    updWaypoint(newWaypoint);
  };

  const removeAction = (idx) => {
    const newWaypoint = Object.assign({}, waypoint);
    if (!newWaypoint.actions || idx === null) return;
    newWaypoint.actions.splice(idx, 1);
    updWaypoint(newWaypoint);
  };

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item>
        <TextField
          variant="outlined"
          label={getText("way_point_name")}
          onChange={onWaypointChange("name")}
          value={(waypoint && waypoint.name) || ""}
        />
      </Grid>
      {actions && (
        <Grid item>
          <Typography>
            {getText("actions")}
            <IconButton
              className={classes.IconButton}
              onClick={() => addAction()}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              className={classes.IconButton}
              onClick={() => removeAction(actionIdx)}
            >
              <RemoveIcon />
            </IconButton>
          </Typography>
          <List className={classes.list}>
            {actions.map((action, idx) => (
              <ListItem
                key={idx}
                className={classes.listItem}
                button={true}
                classes={{
                  selected: classes.selectedItem,
                }}
                alignItems="center"
                selected={actionIdx === idx}
                onClick={() => setActionIdx(idx)}
              >
                {action.act}
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
      {actionIdx !== null && actions[actionIdx] && (
        <Grid item>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={1}
          >
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                variant="outlined"
                label={getText("action_name")}
                onChange={onActionChange("name")}
                value={actions[actionIdx].name || ""}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                variant="outlined"
                label={getText("act")}
                select={true}
                onChange={onActionChange("act")}
                value={actions[actionIdx].act || ""}
              >
                {actOptions.map((act) => (
                  <MenuItem key={act} value={act}>
                    {getText(act)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                variant="outlined"
                label={getText("target")}
                select={true}
                onChange={onActionArgChange("target_id")}
                value={
                  (actions[actionIdx].arg &&
                    actions[actionIdx].arg.target_id) ||
                  ""
                }
              >
                {targetList.map((target) => (
                  <MenuItem key={target.id} value={target.id}>
                    {target.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={classes.textField}
                type="number"
                variant="outlined"
                label={getText("duration")}
                onChange={onActionArgChange("duration")}
                value={
                  actions[actionIdx].arg ? actions[actionIdx].arg.duration : ""
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                {getText("pitch")}:{" "}
                {actions[actionIdx].arg
                  ? Math.round((actions[actionIdx].arg.pitch / Math.PI) * 180)
                  : 0}
              </Typography>
              <Slider
                min={-30}
                max={30}
                step={1}
                value={
                  actions[actionIdx].arg
                    ? (actions[actionIdx].arg.pitch / Math.PI) * 180
                    : 0
                }
                onChange={onActionArgSliderChange("pitch")}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                {getText("theta")}:{" "}
                {actions[actionIdx].arg
                  ? Math.round((actions[actionIdx].arg.theta / Math.PI) * 180)
                  : 0}
              </Typography>
              <Slider
                min={-179}
                max={180}
                step={1}
                value={
                  actions[actionIdx].arg
                    ? (actions[actionIdx].arg.theta / Math.PI) * 180
                    : 0
                }
                onChange={onActionArgSliderChange("theta")}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(EditWaypoint);
