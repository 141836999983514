import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  ellipse: {
    width: theme.spacing(0.5),
    height: theme.spacing(0.5),
    borderRadius: '50%',
    background: (theme.palette).other.border,
  },
}));
export function EllipseComponent({className}) {
  const classes = useStyles();
  return <span className={clsx(classes.ellipse, className)} />;
}
