import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { getText } from 'src/utils/MultilingualLoader';
import DropDownSelectorVertical from 'src/components/Selectors/DropDownSelectorVertical';

const styles = theme => ({
  // table: {
  //   margin: 48
  // },
  title: {
    marginBottom: 12
  },
  input: {
    minWidth: 100,
    padding: '5px 20px 5px 20px',
  },
  checkbox: {
    padding: 0
  },
  submit: {
    marginTop: theme.spacing(3),
    maxWidth: 100,
    marginBottom: 24
  },
  label: {
    color: 'white',
    textTransform: 'initial'
  },
});

const SiteList = props => {
  const {
    classes,
    sites,
    managers,
    siteId,
    selectedManagers,
    onSiteChange,
    onManagerCheck,
    onClickAssign,
    onClickGoBack
  } = props;

  return (
    <Grid
      container={true}
      justify="flex-start"
      direction="column"
      alignItems="stretch"
    >
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          className={classes.submit}
          onClick={onClickGoBack}
        >
          <Typography variant="body2" className={classes.label}>
            {getText('go_back')}
          </Typography>
        </Button>
      </Grid>
      <Grid item>
        <Grid
          className={classes.table}
          container={true}
          justify="flex-start"
          direction="row"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={4}>
            <Typography variant="h6" className={classes.title}>{getText('site')}</Typography>
            <DropDownSelectorVertical
              options={sites.map(site => { return { label: site.name, value: site.id } })}
              value={siteId}
              onChange={onSiteChange}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">{getText('managers')}</Typography>
            {
              managers.filter(manager => manager.user)
                .map(manager => { return { label: `${manager.user.first_name} ${manager.user.last_name}`, value: manager.user.id } })
                .map(manager =>
                  <MenuItem
                    disableGutters
                    className={classes.menuItem}
                    key={manager.value}
                    button={false}
                  >
                    <Checkbox
                      checked={selectedManagers[manager.value] ? selectedManagers[manager.value] : false}
                      color="primary"
                      className={classes.checkbox}
                      onChange={onManagerCheck(manager.value)} />
                    <ListItemText
                      disableTypography={true}
                      className={classes.itemText}
                      primary={
                        <div>
                          <Typography variant="body2">
                            {manager.label}
                          </Typography>
                        </div>
                      }
                    />
                  </MenuItem>)
            }
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              className={classes.submit}
              onClick={onClickAssign}
            >
              <Typography variant="body2" className={classes.label}>
                {getText('assign')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(SiteList);
