import React, { useMemo, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";

import Modal from "src/components/Modal";
import { getText } from "src/utils/MultilingualLoader";

const mapStateToProps = (state, props) => {
  const { robotMapLists } = state.robot;
  const { selected } = props;
  const robotMapList = selected && robotMapLists[selected.id];
  return { robotMapList };
};

const TargetLoopModal = ({
  robotMapList,
  targets,
  unreachalbeTargets,
  onCancel,
  onConfirm,
  actions,
  action,
}) => {
  const [selected, setSelected] = useState([]);
  const [unReachableSelected, setUnReachable] = useState([]);

  useEffect(() => {
    const targetsMap = new Map(
      targets.map((target) => [String(target.id), target])
    );
    setSelected(
      (action.arg.target_list || []).filter((targetId) => {
        const target = targetsMap.get(targetId);
        if (target && target.reachable === false) {
          return false;
        }
        return true;
      })
    );
    setUnReachable(unreachalbeTargets);
  }, []);

  const routeTargets = useMemo(() => {
    if (!robotMapList || robotMapList.loading || !targets) {
      return [];
    }
    const mapAction = actions[action.id - 1];
    const map = robotMapList.maps.find(
      (item) => item.id === mapAction.arg.map_id
    );
    if (!map) {
      return [];
    }
    const route = map.mapRoutes.find(
      (item) => item.id === mapAction.arg.route_id
    );
    if (!route) {
      return [];
    }
    const targetsMap = new Map(
      targets.map((target) => [String(target.id), target])
    );
    return (route.way_points || [])
      .map((waypoint) =>
        (waypoint.actions || []).map((action) => {
          const targetObj = targetsMap.get(String(action.arg.target_id));
          return action.act === "snapshot" && action.arg.target_id
            ? {
                id: String(action.arg.target_id),
                name: targetObj ? targetObj.label : "",
                reachable: targetObj ? targetObj.reachable : false,
              }
            : "";
        })
      )
      .flat()
      .filter((target) => Boolean(target));
  }, [robotMapList && robotMapList.maps, targets]);

  const targetsOutOfRoute = useMemo(() => {
    const existedTargets = routeTargets.map((item) => item.id);
    const targetsMap = new Map(
      targets.map((target) => [String(target.id), target])
    );
    return (action.arg.target_list || [])
      .filter((id) => !existedTargets.includes(id))
      .map((id) => {
        const targetObj = targetsMap.get(id);
        return {
          id: id,
          name: targetObj ? targetObj.label : "",
          reachable: targetObj ? targetObj.reachable : undefined,
        };
      })
      .filter((item) => item.reachable !== false);
  }, [routeTargets, targets]);

  const [reachableTargets, unreachableTargetList] = useMemo(() => {
    return [
      routeTargets.filter((item) => item.reachable),
      targets
        .filter((item) => !item.reachable)
        .map((item) => ({ ...item, id: String(item.id), name: item.label })),
    ];
  }, [routeTargets, targets]);

  function renderItem(target, index, outOfRoute) {
    return (
      <FormControlLabel
        style={{
          minWidth: 200,
          color: outOfRoute
            ? "rgba(255, 0, 0, 1)"
            : target.name
            ? "rgba(0, 0, 0, 0.8)"
            : "rgba(255, 0, 0, 0.8)",
        }}
        key={index}
        control={
          <Checkbox
            value={target.id}
            color="primary"
            onChange={(e) =>
              e.target.checked
                ? setSelected(selected.concat([String(target.id)]))
                : setSelected(
                    selected.filter((item) => item !== String(target.id))
                  )
            }
            checked={selected.includes(String(target.id))}
          />
        }
        label={
          outOfRoute
            ? `${target.name || target.id}(unused)`
            : !target.name
            ? `${target.id}(deleted)`
            : target.name
        }
      />
    );
  }

  function getFloorName(floor) {
    return Number.isFinite(floor) && floor !== 0
      ? floor > 0
        ? `(${floor}F)`
        : `(B${floor})`
      : "";
  }

  function renderUnreachableItem(target, index) {
    return (
      <FormControlLabel
        style={{
          minWidth: 200,
          color: target.name ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 0, 0, 0.8)",
        }}
        key={index}
        control={
          <Checkbox
            value={target.id}
            color="primary"
            onChange={(e) =>
              e.target.checked
                ? setUnReachable(
                    unReachableSelected.concat([String(target.id)])
                  )
                : setUnReachable(
                    unReachableSelected.filter(
                      (item) => item !== String(target.id)
                    )
                  )
            }
            checked={unReachableSelected.includes(String(target.id))}
          />
        }
        label={
          !target.name
            ? `${target.id}(deleted)`
            : target.name + getFloorName(target.floor)
        }
      />
    );
  }

  function handleSubmit() {
    onConfirm({
      targetList: selected,
      id: action.id,
      act: action.act,
      unReachableSelected,
    });
  }

  return (
    <Modal
      maxWidth="md"
      title={getText("select_targets")}
      onCancel={onCancel}
      onConfirm={handleSubmit}
    >
      {!robotMapList || robotMapList.loading ? (
        getText("loading")
      ) : (
        <Grid container direction="column" spacing={2}>
          <Grid container alignItems="center" justify="space-between">
            <Typography variant="body2">
              {getText("reachable_targets")}
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(evt) => {
                    evt.target.checked
                      ? setSelected(routeTargets.map((item) => String(item.id)))
                      : setSelected([]);
                  }}
                  checked={selected.length >= routeTargets.length}
                />
              }
              label={getText("all")}
            />
          </Grid>
          <Grid container alignItems="center">
            {targetsOutOfRoute.map((target, index) =>
              renderItem(target, index, true)
            )}
            {reachableTargets.map((target, index) =>
              renderItem(target, index, false)
            )}
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            style={{ marginTop: 8 }}
          >
            <Typography variant="body2">
              {getText("unreachable_targets")}
            </Typography>
          </Grid>
          <Grid container alignItems="center">
            {unreachableTargetList.map((target, index) =>
              renderUnreachableItem(target, index)
            )}
          </Grid>
        </Grid>
      )}
    </Modal>
  );
};

export default connect(mapStateToProps)(TargetLoopModal);
