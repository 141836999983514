import WebSocketAPI from "./WebSocketAPI";
import store from "src/redux/store";
import {
  cameraActions,
  robotActions,
  caseActions,
  userActions,
  notificationActions,
  taskActions,
} from "src/redux/actions";

export default class APPWS extends WebSocketAPI {
  timer = null;

  ping = () => {};

  navTimeouts = {};

  //override connect and close method for auto reconnect
  connectWS = async (url) => {
    try {
      await super.connectWS(url);
    } catch (e) {
      console.log(e);
    }
    const self = this;
    this.websocket.onclose = () => {
      setTimeout(() => self.connectWS(url), 5000);
    };
  };

  close = () => {
    if (this.websocket) {
      console.log("close without restart");
      this.websocket.onclose = () => {};
      super.close();
    }
  };

  receiveMsg = (message) => {
    let jsonMessage;
    try {
      jsonMessage = JSON.parse(message.data);
    } catch (err) {
      return;
    }
    const { act, err } = jsonMessage;

    if (err) {
      if (err.em) {
        store.dispatch(notificationActions.addNotification(err.em));
      }
      // try refresh token when not authorized
      if (err.ec === 104) {
        store.dispatch(userActions.tokenExpired());
      }
    }

    const request = this.requests[jsonMessage.id];
    //message responsed from server
    if (request) {
      switch (act) {
        case "box.camera.start_stream!":
        case "robot.start_stream!":
          const { device } = request;
          if (device)
            store.dispatch(cameraActions.getHLSURL(device, jsonMessage));
          break;
        case "robot.switch_mode!":
          const { robotId, targetMode } = this.requests[jsonMessage.id];
          store.dispatch(
            robotActions.updRobotModeFromServer({ robotId, targetMode })
          );
          break;
        case "robot.use_map_and_route!":
          const { robotId: robotId2 } = this.requests[jsonMessage.id];
          store.dispatch(robotActions.getRobotConfigs(robotId2));
          break;
        default:
          break;
      }
    }
    //message sent from server
    else {
      switch (act) {
        case "case.created":
          store.dispatch(caseActions.addNewCase(jsonMessage.arg));
          break;
        case "robot.update_nav_state":
          const robotId = jsonMessage.arg && jsonMessage.arg.robot_id;
          if (!robotId) break;
          if (this.navTimeouts[robotId])
            clearTimeout(this.navTimeouts[robotId]);
          store.dispatch(robotActions.updRobotNavFromServer(jsonMessage.arg));
          this.navTimeouts[robotId] = setTimeout(() => {
            store.dispatch(
              robotActions.clearRobotNav(jsonMessage.arg.robot_id)
            );
          }, 15000);
          break;
        case "robot_config.changed":
          store.dispatch(
            robotActions.getRobotConfigs(jsonMessage.arg.robot_id)
          );
          break;
        case "version.changed":
          const sources = jsonMessage.arg && jsonMessage.arg.sources;
          const robots = (sources && sources.robots) || [];
          // store.dispatch(cameraActions.updCamerasFromWS(sources));
          // store.dispatch(caseActions.updCamerasFromWS(sources));
          // store.dispatch(robotActions.updRobotsFromWS(robots));
          // store.dispatch(robotActions.updRobotConfigs(robots));
          if (!this.timer) {
            this.timer = setTimeout(() => {
              this.timer = null;
              clearTimeout(this.timer);

              store.dispatch(robotActions.handleVersionChanged());
            }, 5000);
          }
          break;
        case "detection_task.updated":
          store.dispatch(taskActions.updateTaskStatus(jsonMessage.arg.id));
          break;
        case "detection_task.created":
          store.dispatch(taskActions.notifyNewTask(jsonMessage.arg.id));
          break;
        default:
          return;
      }
    }

    super.receiveMsg(message);
  };
}
