import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import DropDownSelectorVertical from 'src/components/Selectors/DropDownSelectorVertical';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({});

const types = [
  { value: 'event', label: getText('event') },
  { value: 'inspection', label: getText('inspection') },
  { value: 'incident', label: getText('incident') },
];

const CaseTypeSelector = props => {
  const { value, onChange } = props;
  return (
    <DropDownSelectorVertical
      title={getText('case_type')}
      options={types}
      value={value}
      onChange={onChange} />
  );
};

CaseTypeSelector.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

CaseTypeSelector.defaultProps = {
  value: 'all',
  onChange: (event, value) => {}
};

export default withStyles(styles)(CaseTypeSelector);
