import { inspectionConstants } from "src/redux/constants";
import { robotServices, inspectionServices, taskServices } from 'src/services';
import { siteActions, notificationActions } from '.';
import { getText } from 'src/utils/MultilingualLoader';

export const getRobots = () => {
	const request = () => {
		return {
			type: inspectionConstants.GET_ROBOT_REQUEST
		};
	};

	const success = robots => {
		return {
			type: inspectionConstants.GET_ROBOT_SUCCESS,
			robots
		};
	};

	const failure = () => {
		return {
			type: inspectionConstants.GET_ROBOT_FAILURE
		};
	};

	return (dispatch, getState) => {
		let filters = siteActions.addSiteToFilter({}, getState());
		dispatch(request());
		robotServices.getRobots(filters)
			.then(robots => {
				dispatch(success(robots));
			})
			.catch(error => {
				dispatch(failure());
			});
	};
};

export const selectRobot = robot => {
  return {
    type: inspectionConstants.ROBOT_SELECTED,
    robot
  };
};

export const selectWaypoint = (waypointId, inspections) => {
  return {
    type: inspectionConstants.WAYPOINT_SELECTED,
		waypointId,
		inspections
  };
};

export const setFilter = filters => {
	return {
		type: inspectionConstants.SET_FILTER,
		filters
	};
};

export const setShowType = showType => {
	return {
		type: inspectionConstants.SET_SHOW_TYPE,
		showType
	};
};

export const getInspections = (filters, loadAll) => {
	const request = () => {
		return {
			type: inspectionConstants.GET_INSPECTION_REQUEST,
			robotId: filters.robotId
		};
	};

	const success = results => {
		return {
			type: inspectionConstants.GET_INSPECTION_SUCCESS,
			robotId: filters.robotId,
			inspections: results.inspections,
			waypoints: results.waypoints,
			counts: results.counts,
			loadAll
		};
	};

	const failure = () => {
		return {
			type: inspectionConstants.GET_INSPECTION_FAILURE,
			robotId: filters.robotId
		};
	};

	return (dispatch, getState) => {
		const limit = loadAll ? 1000 : filters.lineSize * 3;
		const robotConfig = getState().robot.robotConfigs[filters.robotId];
		const mapId = robotConfig && robotConfig.current_map_id;
		const routeId = robotConfig && robotConfig.current_route_id;
		dispatch(request());
		inspectionServices.getInspections({...filters, limit, mapId, routeId})
			.then(results => {
				dispatch(success(results));
			})
			.catch(error => {
				dispatch(failure());
			});
	};
};

export const getMoreInspections = filters => {
	const request = () => {
		return {
			type: inspectionConstants.GET_INSPECTION_REQUEST,
			robotId: filters.robotId
		};
	};

	const success = results => {
		return {
			type: inspectionConstants.GET_MORE_INSPECTION_SUCCESS,
			robotId: filters.robotId,
			inspections: results.inspections,
			waypoints: results.waypoints,
			counts: results.counts,
			morePages: filters.morePages
		};
	};

	const failure = () => {
		return {
			type: inspectionConstants.GET_INSPECTION_FAILURE,
			robotId: filters.robotId
		};
	};

	return (dispatch, getState) => {
		const robotInspections = getState().inspection.allInspections[filters.robotId];
		const offset = robotInspections && robotInspections.inspections ? robotInspections.inspections.length : 0;
		const limit = filters.morePages * filters.lineSize * 3;
		const robotConfig = getState().robot.robotConfigs[filters.robotId];
		const mapId = robotConfig && robotConfig.current_map_id;
		const routeId = robotConfig && robotConfig.current_route_id;

		dispatch(request());
		inspectionServices.getInspections({...filters, offset, limit, mapId, routeId})
			.then(results => {
				dispatch(success(results));
			})
			.catch(error => {
				dispatch(failure());
			});
	};
};

export const setPage = (robotId, page) => {
  return {
		type: inspectionConstants.SET_PAGE,
		robotId,
    page
  };
}

export const updateInspection = (robotId, inspectionId, data) => {
	const success = result => {
		return {
			type: inspectionConstants.UPDATE_INSPECTION_SUCCESS,
			robotId,
			inspectionId,
			updData: result
		};
	};

  return dispatch => {
    inspectionServices.updateInspection(inspectionId, data)
      .then((result) => {
				dispatch(success(result));
        dispatch(notificationActions.addNotification(`${getText('update_inspection')}${getText('words_space')}${getText('succeeded')}`));
      })
      .catch(err => {
        dispatch(notificationActions.addNotification(`${getText('update_inspection')}${getText('words_space')}${getText('failed')}`));
      });
  }
}

export const createCaseFromInspection = (robotId, inspectionId, data) => {
  const success = result => {
		return {
			type: inspectionConstants.CREATE_CASE_FROM_INSPECTION_SUCCESS,
			robotId,
			inspectionId,
			updData: result
		};
  };
  
  return dispatch => {
    inspectionServices.createCaseFromInspection(data)
      .then(result => {
				dispatch(success(result));
        dispatch(notificationActions.addNotification(`${getText('create_case')}${getText('words_space')}${getText('succeeded')}`));
      })
      .catch(err => {
        dispatch(notificationActions.addNotification(`${getText('create_case')}${getText('words_space')}${getText('failed')}`));
      });
  }
}

export const getEventTypes = (siteId) => {
	const success = result => {
		return {
			type: inspectionConstants.GET_EVENT_TYPES,
			eventTypes: result,
		};
	};

  return (dispatch, getState) => {
    taskServices.getEventOptions(siteId)
      .then((result) => {
				const eventTypes = getState().inspection.eventTypes;
				dispatch(success({
					...eventTypes,
					[siteId]: result
				}));
      });
  }
}