import { GeneralAdapter } from './adapter';
import moment from 'moment';

import { service } from './monitoring';
import { api } from './api';
import { mapActionToActionName } from 'src/utils/constantMapper';

const mapIncidentToClient = incident => {
  return incident ? {
    systemId: incident.system_id,
    type: incident.incident_type,
    createdAt: moment(incident.created_at),
    triggerId: incident.trigger_id,
    escalationId: incident.escalation_id,
    robot: incident.robot,
    metaData: incident.meta_data
  } : null;
};

const mapMediaToClient = media => {
  let snapshots = [];
  let bbox = {};
  let videos = [];
  if (media instanceof Array) {
    media.forEach(function (ele) {
      if (ele.name === "snapshot") {
        snapshots.push({
          id: ele.id,
          name: ele.name,
          fileSize: ele.file.file_size,
          contentType: ele.file.content_type,
          size: ele.file.size,
          url: ele.file.url,
          eventId: ele.event_id,
        });
      } else if (ele.name === "bbox") {
        bbox = {
          id: ele.id,
          name: ele.name,
          url: ele.file.url,
          eventId: ele.event_id,
        };
      } else if (ele.name === "video") {
        videos.push(
          {
            id: ele.id,
            name: ele.name,
            fileSize: ele.file.file_size,
            contentType: ele.file.content_type,
            url: ele.file.url,
            eventId: ele.event_id,
            startTime: moment(ele.data.started_at),
            endTime: moment(ele.data.ended_at)
          }
        );
      }
    });
  }
  else {
    snapshots = media.snapshots.map(snapshot => {
      return {
        id: snapshot.id,
        name: snapshot.name,
        fileSize: snapshot.file.file_size,
        contentType: snapshot.file.content_type,
        size: snapshot.file.size,
        url: snapshot.file.url,
        eventId: snapshot.event_id,
      };
    });
    bbox = {
      id: media.bbox.id,
      name: media.bbox.name,
      url: media.bbox.file.url,
      eventId: media.bbox.event_id,
    };
    videos = media.videos.map(video => {
      return {
        id: video.id,
        name: video.name,
        fileSize: video.file.file_size,
        contentType: video.file.content_type,
        url: video.file.url,
        eventId: video.event_id,
        startTime: moment(video.started_at),
        endTime: moment(video.ended_at)
      }
    })
  }
  return { snapshots, bbox, videos };
};

const mapInspectionToClient = inspection => {
  const media = inspection.media ? inspection.media.map(medium => ({
    id: medium.id,
    type: medium.name,
    url: medium.file ? medium.file.url : null
  })) : []
  return {
    id: inspection.id,
    startTime: moment(inspection.started_at),
    endTime: moment(inspection.ended_at),
    robotId: inspection.robot_id,
    robot: inspection.robot,
    algos: inspection.algo_types,
    media
  }
}

export const mapEventToClient = event => {
  return event ? {
    id: event.id,
    cameraId: event.camera_id,
    robotId: event.robot_id,
    robot: event.robot,
    robotCamera: event.robot_camera,
    camera: event.camera,
    boxId: event.camera && event.camera.box_id,
    types: event.types,
    startTime: moment(event.started_at),
    endTime: moment(event.ended_at),
    location: event.location === '' ? 'not_set' : event.location,
    source: event.source,
    ...mapMediaToClient(event.media),
  } : null;
};

const mapHistoriesToClient = (histories) => {
  return histories.map(history => {
    return {
      ...history,
      action: mapActionToActionName[history.action],
      created_at: moment(history.created_at)
    }
  }).sort((a, b) => {
    if (!a.id) return -1;
    if (!b.id) return 1;
    return a.id > b.id ? -1 : 1;
  })
}

const mapNotesToClient = notes => {
  return notes.map(note => {
    return {
      id: note.id,
      content: note.note,
      user: note.user,
      created_at: moment(note.created_at),
      updated_at: moment(note.updated_at)
    }
  })
}

const mapCaseToClient = _case => {
  if (!_case) return {};
  const { incident, event } = _case;

  return {
    status: _case.status ? _case.status : '',
    action: _case.action ? _case.action : '',
    priority: _case.priority ? _case.priority : '',
    created_at: _case.created_at ? moment(_case.created_at) : '',
    updated_at: _case.updated_at ? moment(_case.updated_at) : '',
    id: _case.id ? _case.id : '',
    report: !_case.report || (_case.report === '') ? 'Not Entered' : _case.report,
    type: _case.type ? _case.type : '',
    incident: _case.type && _case.type === 'incident' ? mapIncidentToClient(incident) : null,
    event: _case.type && _case.type === 'event' ? mapEventToClient(event) : null,
    media: _case.media ? mapMediaToClient(_case.media) : {},
    histories: _case.histories ? mapHistoriesToClient(_case.histories) : [],
    notes: _case.notes ? mapNotesToClient(_case.notes) : [],
    viewed: _case.viewed ? _case.viewed : false,
    inspectionId: _case.inspection_id,
    inspection: _case.inspection ? mapInspectionToClient(_case.inspection) : null
  }
}

const getCasesAdapter = {
  toServer: data => {
    return data
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = result ? result : {};
    result.cases = result.cases ? result.cases : [];
    result.case_stats = result.case_stats ? result.case_stats : {};

    let cases = [];
    try {
      result.cases.forEach(_case => {
        const _caseParsed = mapCaseToClient(_case);
        cases.push(_caseParsed);
      });
    } catch (e) {
      console.log('map case to client error', e)
    }
    result.cases = cases;
    return { error, result };
  }
};

const mapFiltersToQuery = filters => {
  const queries = [];
  if (!filters) return queries;

  const { status, eventType, incidentType, eventSource, priority, caseType,
    timeType, startTime, endTime, searchQuery, lastId, pageSize, unread, unclosed, siteId } = filters;
  if (searchQuery)
    queries.push(`${encodeURIComponent('search')}=${encodeURIComponent(searchQuery)}`);

  // Status
  if (caseType === 'event' && status && status !== 'all')
    queries.push(`${encodeURIComponent('status')}=${encodeURIComponent(status)}`);

  // Action
  // if (caseType === 'event' && action && action !== 'all')
  //   queries.push(`${encodeURIComponent('action')}=${encodeURIComponent(action)}`);

  // Event Type
  if (caseType === 'event' && eventType && eventType !== 'all')
    queries.push(`event__types__match=%25${eventType}%25`);

  // Incident Type
  if (caseType === 'incident' && incidentType && incidentType !== 'all')
    queries.push(`${encodeURIComponent('incident__incident_type')}=${encodeURIComponent(incidentType)}`);

  // Event Source
  if (caseType === 'event' && eventSource && eventSource !== 'all')
    queries.push(`${encodeURIComponent('event__source')}=${encodeURIComponent(eventSource)}`);

  // Priority
  if (priority && priority !== 'all')
    queries.push(`${encodeURIComponent('priority')}=${encodeURIComponent(priority)}`);

  // Time
  if (timeType !== 'all' && startTime) {
    // Convert query time to UTC 
    let startTimeUTC = moment.utc(startTime).format('YYYY-MM-DDTHH:mm:ss');
    if (caseType === 'event') {
      queries.push(`${encodeURIComponent('event__started_at__gte')}=${encodeURIComponent(startTimeUTC)}`);
    }
    else {
      queries.push(`${encodeURIComponent('created_at__gte')}=${encodeURIComponent(startTimeUTC)}`);
    }
  }
  if (timeType === 'customized' && endTime) {
    let endTimeUTC = moment.utc(endTime).format('YYYY-MM-DDTHH:mm:ss');
    if (caseType === 'event') {
      queries.push(`${encodeURIComponent('event__ended_at__lte')}=${encodeURIComponent(endTimeUTC)}`);
    }
    else {
      queries.push(`${encodeURIComponent('created_at__lte')}=${encodeURIComponent(endTimeUTC)}`);
    }
  }

  //Pagination
  if (lastId) {
    queries.push(`${encodeURIComponent('id__lt')}=${encodeURIComponent(lastId)}`);
  }
  if (pageSize) {
    queries.push(`${encodeURIComponent('limit')}=${encodeURIComponent(pageSize)}`);
  }

  // Unread
  if (unread) {
    queries.push('viewed=false');
  }

  // Unclosed
  if (unclosed) {
    queries.push('status__exclude=closed');
  }

  // Site Id
  if (siteId) {
    if (caseType === 'event') {
      queries.push(`${encodeURIComponent('event__site_id')}=${encodeURIComponent(siteId !== -1 ? siteId : null)}`);
    }
    else if (caseType === 'inspection') {
      queries.push(`${encodeURIComponent('inspection__robot__site_id')}=${encodeURIComponent(siteId !== -1 ? siteId : null)}`);
    }
    else if (caseType === 'incident') {
      queries.push(`${encodeURIComponent('incident__robot__site_id')}=${encodeURIComponent(siteId !== -1 ? siteId : null)}`);
    }
  }

  // Case Type
  if (caseType) {
    queries.push(`${encodeURIComponent('type')}=${encodeURIComponent(caseType)}`);
  }

  return queries.join('&');
};

export const getCases = (adapter => async (data = {}, filters = {}) => {
  try {
    const query = mapFiltersToQuery(filters);
    const url = `/case/?${query}`;
    let response = await service.get(url);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(getCasesAdapter);

const updateCaseAdapter = {
  toServer: data => {
    let req = {
      id: data.caseId,
      action: data.action,
    };
    if ('priority' in data) {
      req['priority'] = data.priority;
    }
    if ('note' in data) {
      req['note'] = data.note;
    }
    if ('types' in data) {
      req['types'] = data.types;
    }
    if ('notify_phones' in data) {
      req['notify_phones'] = data.notify_phones;
    }
    if ('notify_emails' in data) {
      req['notify_emails'] = data.notify_emails;
    }
    return req;
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = result ? result : {};
    result.case = result.case ? mapCaseToClient(result.case) : {};

    return { error, result };
  }
};

export const updateCase = (adapter => async (data, filters) => {
  try {
    // use filters to get case statistics in the update response
    const query = mapFiltersToQuery(filters);
    const url = `/case/?${query}`;
    data = adapter.toServer(data);
    let response = await service.put(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(updateCaseAdapter);

const generalAdapter = {
  toServer: data => data,
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    return { result };
  }
}

export const deleteCase = (adapter => async caseId => {
  try {
    const url = '/case/' + caseId;
    let response = await service.delete(url);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const getCaseTypes = (adapter => async deviceId => {
  try {
    const url = `/robot/robots/${deviceId}/get_event_types`;
    let response = await api.get(url);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);


