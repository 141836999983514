import React from 'react';
import { connect } from 'react-redux';
import { caseActions } from 'src/redux/actions';

import CaseList from './CaseList';
import LoadingText from 'src/components/LoadingText';

const mapStateToProps = state => {
  const { cases, loading, curPage, filters, selected } = state.case;
  const { curSite } = state.site;
  return { cases, loading, curPage, filters, selected, curSite };
};

const mapDispatchToProps = dispatch => {
  return {
    getCases: filters => {
      dispatch(caseActions.getCases(filters));
    },
    setFilter: filters => {
      dispatch(caseActions.setFilter(filters));
    },
    selectCase: (_case) => {
      dispatch(caseActions.selectCase(_case));
      if (!_case.viewed) {
        dispatch(caseActions.updateCase({
          caseId: _case.id,
          action: "viewed"
        }));
      }
    },
    unselectCase: () => dispatch(caseActions.selectCase(null))
  };
};

class CaseListContainer extends React.Component {

  componentDidMount() {
    const { loading, filters, setFilter, getCases } = this.props;
    if (!loading) {
      const defaultCaseFilter = {
        action: 'all',
        status: 'all',
        eventType: 'all',
        incidentType: 'all',
        eventSource: 'all',
        priority: 'all',
        searchQuery: '',
        timeType: 'all',
        startTime: null,
        endTime: null,
        unread: false,
        unclosed: false,
      };
      const pagePath = window.location.pathname;
      let caseFilters;
      switch (pagePath) {
        case '/cases':
          caseFilters = Object.assign({}, filters, {...defaultCaseFilter});
          setFilter(caseFilters);
          getCases(caseFilters);
          return;
        case '/cases/new':
          caseFilters = Object.assign({}, filters, { ...defaultCaseFilter, status: 'new', unread: true});
          setFilter(caseFilters);
          getCases(caseFilters);
          return;
        case '/cases/active':
          caseFilters = Object.assign({}, filters, { ...defaultCaseFilter, unclosed: true});
          setFilter(caseFilters);
          getCases(caseFilters);
          return;
        default:
          return;
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { curSite, loading, getCases, unselectCase } = this.props;
    if (curSite && (!prevProps.curSite || curSite !== prevProps.curSite) && !loading) {
      getCases();
      unselectCase();
    }
  }

  componentWillUnmount() {
    this.props.unselectCase();
  }

  handleCaseClick = _case => event => {
    this.props.selectCase(_case);
  };

  render() {
    const { selected, curPage, filters, loading, cases } = this.props;
    const { pageSize } = filters;
    let data = [];
    data = cases.slice((curPage - 1) * pageSize, curPage * pageSize);

    return (
      !loading ?
        <CaseList
          selected={selected}
          data={data}
          onClick={this.handleCaseClick}
        />
        : <LoadingText />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseListContainer);

export { CaseListContainer };