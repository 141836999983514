import React, { Component } from "react";
import { connect } from "react-redux";

import { referenceActions, notificationActions } from "src/redux/actions";
import AlgoReference from "./AlgoReference";
import { getText } from "src/utils/MultilingualLoader";

const mapStateToProps = (state) => {
  const { algoOptions } = state.target;
  return {
    algoOptions,
    siteId: state.site.curSite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAlgoReference: (params, cb) => {
      dispatch(referenceActions.addAlgoReference(params, cb));
    },
    updateAlgoReference: (params, id) => {
      dispatch(referenceActions.updateAlgoReference(params, id));
    },
    deleteAlgoReference: (id) => {
      dispatch(referenceActions.deleteAlgoReference(id));
    },
    addNotification: (msg) => {
      dispatch(notificationActions.addNotification(msg));
    },
  };
};

class AlgoReferenceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.data,
      region: props.region,
      deleted: false,
    };
  }

  componentDidMount() {
    if (this.state.refs.length === 0) {
      this.handleAddRef();
    }
  }

  setRegion = (value) => {
    this.setState({
      region: value,
    });
  };

  findNewRegin(regionKey) {
    const { region } = this.props;
    if (!region) {
      throw Error("type not exist");
    }
    return region[regionKey];
  }

  findNewRef() {
    const { region } = this.props;
    if (!region) {
      throw Error("type not exist");
    }

    const types = region.types;
    return region[types[0]];
  }

  handleAddRef = () => {
    const { refs, validate } = this.state;
    const region = this.findNewRef();
    this.setState({
      refs: [
        ...refs,
        {
          snapshot: "",
          inspection_id: "",
          regions: [this.newRegion(region)],
        },
      ],
      validate: {
        ...validate,
        refs: [
          ...validate.refs,
          {
            snapshot: "",
            inspection_id: "",
            regions: [{}],
          },
        ],
      },
    });
  };

  handleChangeSnapshot = (refIndex, snapshot) => {
    const { refs } = this.state;
    const newRefs = refs.map((item, index) =>
      refIndex === index
        ? {
            ...item,
            snapshot: snapshot.url,
            inspection_id: snapshot.id,
          }
        : item
    );
    this.setState({
      refs: newRefs,
    });
  };

  newRegion(region) {
    return {
      no: "",
      type: region.type,
      multiple: region.multiple,
      points: "",
      status: region.status[0].value,
      options: region.status,
    };
  }

  handleAddRegion = (refIndex, regionKey) => () => {
    const { refs, validate } = this.state;
    const region = this.findNewRegin(regionKey);
    const newRefs = refs.map((item, index) =>
      refIndex === index
        ? {
            ...item,
            regions: [...item.regions, this.newRegion(region)],
          }
        : item
    );
    this.setState({
      refs: newRefs,
      validate: {
        ...validate,
        refs: validate.refs.map((item, index) =>
          validate.refs.length - 1 === index
            ? {
                ...item,
                regions: [...item.regions, {}],
              }
            : item
        ),
      },
    });
  };

  handleDeleteRegion = (refIndex, regionIndex) => () => {
    const { refs, validate } = this.state;
    const newRefs = refs.map((item, index) =>
      refIndex === index
        ? {
            ...item,
            regions: item.regions.filter((cell, i) => i !== regionIndex),
          }
        : item
    );
    this.setState({
      refs: newRefs,
      validate: {
        ...validate,
        refs: validate.refs.map((item, index) =>
          refIndex === index
            ? {
                ...item,
                regions: item.regions.filter((cell, i) => i !== regionIndex),
              }
            : item
        ),
      },
    });
  };

  handleChangeRef = (refIndex, regionIndex) => (key, value) => {
    const { refs, validate } = this.state;
    const newRefs = refs.map((item, index) =>
      refIndex === index
        ? {
            ...item,
            regions: item.regions.map((cell, i) =>
              i === regionIndex
                ? {
                    ...cell,
                    [key]: value,
                  }
                : cell
            ),
          }
        : item
    );
    this.setState({
      refs: newRefs,
      validate: {
        ...validate,
        refs: validate.refs.map((item, index) =>
          refIndex === index
            ? {
                ...item,
                regions: item.regions.map((cell, i) =>
                  i === regionIndex
                    ? {
                        [key]: value || parseInt(value) === 0 ? "" : "required",
                      }
                    : cell
                ),
              }
            : item
        ),
      },
    });
  };

  handleDeleteRef = (refIndex) => () => {
    const { refs, validate } = this.state;
    const newRefs = refs.filter((item, index) => refIndex !== index);
    this.setState({
      refs: newRefs,
      validate: {
        ...validate,
        refs: validate.refs.filter((item, index) => refIndex !== index),
      },
    });
  };

  validate() {
    const { refs } = this.state;
    const result = {};
    let errorCount = 0;
    const refResult = refs.map((item) => {
      return {
        regions: item.regions.map((region) => {
          // errorCount += !region.no && region.no !== 0 ? 1 : 0;
          errorCount += region.points ? 0 : 1;
          return {
            // no: !region.no && region.no !== 0 ? 'required' : '',
            region: region.points ? "" : "required",
          };
        }),
      };
    });
    this.setState({
      validate: {
        ...result,
        refs: refResult,
      },
    });
    return errorCount;
  }

  handleConfirm = () => {
    const {
      addAlgoReference,
      updateAlgoReference,
      algoConfigId,
      addNotification,
    } = this.props;
    const { refs, type, id } = this.state;
    const params = {
      algoConfigId,
      type,
      refs,
    };
    const count = this.validate();
    if (count === 0) {
      if (refs.find((ref) => ref.regions.find((region) => region.error))) {
        addNotification(getText("clear_invalid_data"));
        return;
      }
      if (window.confirm(getText("confirm_save"))) {
        id
          ? updateAlgoReference(params, id)
          : addAlgoReference(params, (newId) => {
              this.setState({
                id: newId,
              });
            });
      }
    } else {
      addNotification(getText("invalid_input"));
    }
  };

  handleDeleteReference = async () => {
    const { deleteAlgoReference } = this.props;
    const { id } = this.state;
    if (id) {
      if (window.confirm(getText("confirm_delete"))) {
        await deleteAlgoReference(id);
      } else {
        return;
      }
    }
    this.setState({
      deleted: true,
    });
  };

  render() {
    const { show, target } = this.props;
    const { refs, multiple, validate, deleted, region } = this.state;
    if (!show || deleted) {
      return null;
    }

    return (
      <AlgoReference
        refs={refs}
        target={target}
        multiple={multiple}
        validate={validate}
        regionConfig={region}
        handleChangeKey={this.handleChangeKey}
        handleAutoComplete={this.handleAutoComplete}
        handleAddRef={this.handleAddRef}
        handleDeleteRef={this.handleDeleteRef}
        handleChangeRef={this.handleChangeRef}
        handleDeleteRegion={this.handleDeleteRegion}
        handleAddRegion={this.handleAddRegion}
        handleChangeSnapshot={this.handleChangeSnapshot}
        handleConfirm={this.handleConfirm}
        handleDeleteReference={this.handleDeleteReference}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlgoReferenceContainer);
