import React from 'react';
import { connect } from 'react-redux';

import history from 'src/utils/history';
import { siteActions } from 'src/redux/actions';
import AssociateSiteManager from './AssociateSiteManager';
import LoadingText from 'src/components/LoadingText';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  return {
    sites: state.site.sites,
    managers: state.site.managers,
    siteLoading: state.site.siteLoading,
    managerLoading: state.site.managerLoading,
    siteUpdating: state.site.siteUpdating
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSites: () => {
      dispatch(siteActions.getSites());
    },
    getManagers: id => {
      dispatch(siteActions.getManagers(1, 100));
    },
    assignManagers: data => {
      dispatch(siteActions.assignManagers(data));
    },
  };
};

class AssociateSiteManagerContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      siteId: -1,
      selectedManagers: {}
    }
  }

  componentDidMount(){
    if (!this.props.siteLoading) this.props.getSites();
    if (!this.props.managerLoading) this.props.getManagers();
  }

  componentDidUpdate(prevProps, prevState){
    if (this.state.siteId !== prevState.siteId){
      let selectedManagers = {};
      const selectedSite = this.props.sites && this.props.sites.find(site => site.id === this.state.siteId);
      if (selectedSite && selectedSite.managements) selectedSite.managements.forEach(management => {
        if (management && management.site_manager && management.site_manager.user)
          selectedManagers[management.site_manager.user.id] = true;
      });
      this.setState({
        selectedManagers
      });
    }
  }

  onSiteChange = event => {
    this.setState({
      siteId: event.target.value
    });
  }

  onManagerCheck = id => (event, checked) => {
    this.setState({
      selectedManagers: {
        ...this.state.selectedManagers,
        [id]: checked
      }
    });
  }

  onClickAssign = () => {
    const { siteId, selectedManagers } = this.state;
    if (siteId === -1){
      alert(getText('input_error_site'));
      return;
    }
    if (Object.values(selectedManagers).filter(value=>value).length === 0){
      alert(getText('input_error_selected_manager'));
      return;
    }
    if (!this.props.siteUpdating){
      this.props.assignManagers({
        site_id: siteId,
        managements: Object.keys(selectedManagers)
          .filter(key=>selectedManagers[key])
          .map(key=>{return {site_manager_id: key}})
      });
    }
  }

  onClickGoBack = () => {
    history.push('/sitemanage/site');
  }

  render() {
    const { sites, managers, siteLoading, managerLoading } = this.props;
    const { siteId, selectedManagers } = this.state;
    return (
      !siteLoading && !managerLoading ?
        <AssociateSiteManager
          sites={sites || []}
          managers={managers}
          siteId={siteId}
          selectedManagers={selectedManagers}
          onSiteChange={this.onSiteChange}
          onManagerCheck={this.onManagerCheck}
          onClickAssign={this.onClickAssign}
          onClickGoBack={this.onClickGoBack}
          />
        : <LoadingText />
    );
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssociateSiteManagerContainer);
