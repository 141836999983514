import React from 'react';

import { connect } from 'react-redux';
import { cameraActions } from 'src/redux/actions';
import SearchBar from 'src/pages/Main/SearchBar';
import CameraFilter from '../CameraFilter';

const mapStateToProps = state => {
  const { filters, loading } = state.camera;
  const { searchQuery } = filters;
  return { searchQuery, filters, loading };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilter: filters => {
      dispatch(cameraActions.setFilter(filters));
    },
    getCameras: filters => {
      dispatch(cameraActions.getCameras(filters));
    }
  };
};

class SearchBarContainer extends React.Component {
  handleSearchQueryChange = (searchQuery) => {
    const { filters, setFilter, getCameras, loading } = this.props;
    const search_filter = {
      searchQuery: searchQuery
    };
    setFilter(search_filter);
    if (!loading) getCameras(Object.assign({}, filters, search_filter));
  }

  render() {
    return (
      <SearchBar
        filterChild={<CameraFilter />}
        initialQuery={this.props.searchQuery}
        onChangeSearchQuery={this.handleSearchQueryChange}
      />
    );
  }
}

SearchBarContainer.propTypes = {
};

SearchBarContainer.defaultProps = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchBarContainer);
