import moment from 'moment';

import { api } from './api';
import { GeneralAdapter } from './adapter';

const inspectionsAdapter = {
  toServer: data => {
      return data;
  },
  toClient: data => {
      data = GeneralAdapter.toClient(data);
      const { error, result } = data;
      if (error) return { error };
      let inspections = result.results ? result.results : [];
      inspections = inspections.map(mapInspectionsToClient);
      const waypoints = {};
      inspections.forEach(inspection => {
        if (inspection.way_point && inspection.way_point.id)
          if (!waypoints[inspection.way_point.id])
            waypoints[inspection.way_point.id] = [inspection];
          else
            waypoints[inspection.way_point.id].push(inspection);
      });
      const counts = result.count;
      const results = { inspections, waypoints, counts }
      return { results };
  }
};

const mapInspectionsToClient = inspection => {
  return {
    ...inspection,
    started_at: inspection.started_at && moment(inspection.started_at),
    ended_at: inspection.ended_at && moment(inspection.ended_at),
    algos: inspection.algo_types && inspection.algo_types.map(algo => algo.name),
    results: inspection.analysis_results && inspection.analysis_results.map(item => item.name),
  };
};

const mapFiltersToQuery = filters => {
  const queries = [];

  const { limit, offset, startDate, endDate, sortBy, mediumType, matchMap, matchRoute,
    mapId, routeId, robotId, inspectionType, target } = filters;
    
  // Robot Id
  queries.push(`${encodeURIComponent('robot_id')}=${encodeURIComponent(robotId)}`);

  // Page Size
  if (limit)
    queries.push(`${encodeURIComponent('limit')}=${encodeURIComponent(limit)}`);

  // Offset
  if (offset)
    queries.push(`${encodeURIComponent('offset')}=${encodeURIComponent(offset)}`);

  // Start time
  if (startDate) {
    const startTimeUTC = moment.utc(startDate).format('YYYY-MM-DD HH:mm:ss');
    queries.push(`${encodeURIComponent('started_at__gte')}=${encodeURIComponent(startTimeUTC)}`);
  }
  
  // End time
  if (endDate) {
    const endTimeUTC = moment.utc(endDate).format('YYYY-MM-DD HH:mm:ss');
    queries.push(`${encodeURIComponent('ended_at__lte')}=${encodeURIComponent(endTimeUTC)}`);
  }

  // Target id
  if (target && target.id) {
    queries.push(`${encodeURIComponent('target_id')}=${encodeURIComponent(target.id)}`);
  }

  // Type
  if (inspectionType)
    queries.push(`${encodeURIComponent('type')}=${encodeURIComponent(inspectionType)}`);

  // Sort by
  if (sortBy && sortBy === 'target__name')
    queries.push(`ordering=target__name,-ended_at`);

  // Media type
  if (mediumType && mediumType !== 'all')
    queries.push(`${encodeURIComponent('medium_type')}=${encodeURIComponent(mediumType)}`);

  // Map Id
  if (matchMap && mapId){
    queries.push(`${encodeURIComponent('map_id')}=${encodeURIComponent(mapId)}`);
  }

  // Route Id
  if (matchRoute && routeId){
    queries.push(`${encodeURIComponent('route_id')}=${encodeURIComponent(routeId)}`);
  }

  return queries.join('&');
};

export const getInspections = (adapter => async (filters = {}) => {
  try {
    const query = mapFiltersToQuery(filters);
    const url = `/inspection/inspections?target__visible=all&${query}`;
    let response = await api.get(url);
    response = adapter.toClient(response.data);
    const { error, results } = response;
    if (error) throw error;
    return results;
  } catch(e) {
    throw e;
  }
})(inspectionsAdapter);

const updateInspectionAdapter = {
  toServer: data => data,
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = {
      algos: result.algo_types && result.algo_types.split(','),
      results: result.analysis_results && result.analysis_results.split(','),
    }
    return { result };
  }
}

export const updateInspection = (adapter => async (id, data) => {
  try {
    const url = '/inspection/inspections/' + id;
    let response = await api.patch(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(updateInspectionAdapter);


const createCaseFromInspectionAdapter = {
  toServer: data => data,
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = {
      caseId: result.id
    }
    return { result };
  }
}

export const createCaseFromInspection = (adapter => async data => {
  try {
    let url = '/case/cases/inspection';
    const GOD = process.env.REACT_APP_GOD;
    const REACT_APP_BASE_HOST = process.env.REACT_APP_BASE_HOST;
    
    if(GOD === 'poly') {
      url = `${REACT_APP_BASE_HOST}/api/v2/patrol/case/cases/inspect`
    }
    
    let response = await api.post(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(createCaseFromInspectionAdapter);
