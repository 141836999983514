import React, { useState } from "react";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  withStyles,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { getText } from "src/utils/MultilingualLoader";

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const RelocalizeOptions = (props) => {
  const { classes, onClose, onConfirm } = props;
  const [state, setState] = useState({
    reselect: false,
  });

  const handleChange = (prop) => (e, checked) => {
    setState({
      ...state,
      [prop]: checked,
    });
  };

  return (
    <Dialog open={true} fullWidth maxWidth="xs">
      <DialogTitle>
        <Typography variant="body2">{getText("relocalize_options")}</Typography>
        <CloseIcon className={classes.closeButton} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.reselect}
                onChange={handleChange("reselect")}
                name="reselect"
                color="primary"
              />
            }
            label="重选位置"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          {getText("cancel")}
        </Button>
        <Button onClick={() => onConfirm(state)} color="primary">
          {getText("ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(RelocalizeOptions);
