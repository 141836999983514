import moment from 'moment';

import { userConstants } from 'src/redux/constants';
import { userActions } from 'src/redux/actions';
import { getUserInfo } from 'src/utils/auth';
import { getText } from 'src/utils/MultilingualLoader';

let buffer = []; // create an empty array which will be populated with all actions dispatched by Redux

const refreshTokenMiddleware = store => next => action => {
  buffer.push(action);
  const submitting = store.getState().user.submitting;
  if (action.type === userConstants.TOKEN_EXPIRED) {
    const expire = getUserInfo().expire;
    if (expire && moment.utc().isBefore(expire)){
      buffer = [];
      if (!submitting){
        store.dispatch(userActions.signout());
        alert(getText('token_changed'));
      }
    }
    else if (!submitting){
      store.dispatch(userActions.refreshToken());
    }
  }
  // reDispatch async actions triggered while the token was refreshing
  else if (action.type === userConstants.REFRESH_TOKEN_SUCCESS){
    // find the first faild XHR
    let firstFailedIndex = Math.max(buffer.findIndex(action => action.type === userConstants.TOKEN_EXPIRED) - 1, 0);
    while (firstFailedIndex > 0 && typeof buffer[firstFailedIndex] !== 'function') firstFailedIndex--;
    let reDispatchBuffer = buffer.slice(firstFailedIndex);
    for (let idx = 0; idx < reDispatchBuffer.length; idx++){
      if (typeof reDispatchBuffer[idx] === 'function' && reDispatchBuffer[idx].name !== 'refresh'){
        store.dispatch(reDispatchBuffer[idx]);
      }
    }
    buffer = [];
  }
  // signout if refresh token failed
  else if (action.type === userConstants.REFRESH_TOKEN_FAILURE){
    buffer = [];
    if (!submitting){
      store.dispatch(userActions.signout());
      alert(getText('token_expired'));
    }
  }
  else {
    // buffer at most 20 actions
    if (buffer.length > 20){
      buffer.splice(0, buffer.length - 20);
    }
  }
  return next(action);
};

export default refreshTokenMiddleware;