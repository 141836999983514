import {stringify} from 'qs';
import { event_api } from 'src/services/Vision/api';

export const turingManufacturer = 'Turing';
export const thirdPartyManufacturer = 'Third Party';
export const unknownManufacturer = 'Unknown';

export function getManufacturer(manufacturer){
  if (!manufacturer) return unknownManufacturer;
  if (manufacturer === 'Turing-U') return turingManufacturer;
  return thirdPartyManufacturer;
}


export const minCameraAdapter = {
  toClientOrg: (res) =>{
    let ans = res.results.map((camera) => ({
      id: camera.id,
      name: camera.name,
      isTuring: getManufacturer(camera.manufacturer) === turingManufacturer,
    }));
    return ans
  }
    ,
  toClient: (res) =>
    res.map((camera) => ({
      id: camera.id,
      name: camera.name,
      isTuring: getManufacturer(camera.manufacturer) === turingManufacturer,
    }))
};

export async function getMinCameras(params) {
  try {
    const url = `/api/v1/cameras?${stringify(params)}`;
    const response = await event_api.get(url);
    return minCameraAdapter.toClient(response.data.ret);
  } catch (e) {
    throw e;
  }
}

export async function operatorGetMinCameras(params) {
  try {
    const url = `/api/v1/camera/cameras/operator_get_cameras?user_id=518&${stringify(params)}`;
    const response = await event_api.get(url);
    return minCameraAdapter.toClientOrg(response.data.ret);
  } catch (e) {
    throw e;
  }
}