import React from "react";

import Pagination from "./Pagination.js";

class PaginationContainer extends React.Component {
  handlePageClick = (page) => (e) => {
    const { onPageClick } = this.props;
    onPageClick && onPageClick(page);
  };

  render() {
    const { counts, pageSize, curPage } = this.props;
    const maxPage = Math.ceil(counts / pageSize);
    let pageOptions = [curPage];
    const left = Math.max(curPage - 2, 1);
    for (let i = curPage - 1; i >= left; i--) pageOptions.unshift(i);
    const right = Math.min(
      curPage + Math.max(5 - pageOptions.length, 0),
      maxPage
    );
    for (let i = curPage + 1; i <= right; i++) pageOptions.push(i);
    for (let i = left - 1; pageOptions.length < 5 && i > 0; i--)
      pageOptions.unshift(i);

    return (
      <Pagination
        noPrev={curPage <= 1}
        noNext={curPage >= maxPage}
        curPage={curPage}
        pageOptions={pageOptions}
        onPageClick={this.handlePageClick}
      />
    );
  }
}

export default PaginationContainer;
