/* eslint-disable @typescript-eslint/naming-convention */
import {SET_ORG, SET_ORG_LOADING} from 'src/redux/types/Vision/org';

const initialState = {
  orgLoading: false,
  org: null,
};

export default function roleReducer(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case SET_ORG_LOADING:
      return {
        ...state,
        orgLoading: payload.orgLoading,
      };
    case SET_ORG:
      return {
        ...state,
        orgLoading: payload.orgLoading,
        org: payload.org,
      };

    default:
      return state;
  }
}
