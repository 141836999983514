import { stringify } from 'qs';
import { GeneralAdapter } from './adapter';
import { api } from './api';
import { convert } from 'src/pages/Main/Targets/InspectionConfigs/AlgoReferenceList/config';

const inspectionDataAdapter = {
  toServer: data => {
    return data;
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    const { results, count } = result;
    return {
      count,
      list: results.map(item => ({
        id: item.id,
        snapshot: item.mediums[0] && item.mediums[0].file.url,
        isPanoramic: item.mediums[0] && item.mediums[0].file.panoramic,
      }))
    };
  }
}

const algoConfigAdapter = {
  toServer: data => {
    const { mapId, routeId, algoConfigId, refs, type } = data;
    return {
      map_id: mapId,
      route_id: routeId,
      algo_config_id: algoConfigId,
      ref_data: {
        ref_images: convert(type, refs.map(refImage => ({
          url: refImage.snapshot,
          inspection_id: refImage.inspection_id,
          regions: refImage.regions.map(region => ({
            id: region.no,
            type: region.type,
            points: region.points,
            state: region.status,
          }))
        }))),
      },
    };
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    const { results, count } = result;
    return {
      count,
      list: results.map(item => ({
        id: item.id,
        snapshot: item.mediums[0].file.url,
      }))
    };
  }
}

const algoReferenceAdapter = {
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    const { results } = result;
    if (results) {
      return results.map(reference => ({
        mapId: reference.map_id,
        routeId: reference.route_id,
        id: reference.id,
        refs: ((reference.ref_data && reference.ref_data.ref_images && reference.ref_data.ref_images) || []).map(item => {
          return {
            snapshot: item.url,
            inspection_id: item.inspection_id,
            regions: Object.keys(item).filter(key => (key !== 'url' && key !== 'inspection_id')).map(key => item[key]).flat().map(region => ({
              no: region.id,
              type: region.type,
              points: region.points,
              status: region.state,
              labels: region.labels,
            })),
          };
        }),
      }));
    }
    return [];
  }
}

function queryParams(filters) {
  const { limit, offset, targetId } = filters;
  const params = [];
  params.push(`limit=${limit}`);
  params.push(`offset=${offset}`);
  params.push(`target_id=${targetId}`);
  params.push(`medium_type=snapshot`);
  return params.join('&');
}

export const getInspections = async (filters) => {
  try {
    const params = queryParams(filters);
    const url = `/inspection/inspections?${params}`;
    const response = await api.get(url);
    return inspectionDataAdapter.toClient(response.data);
  } catch (e) {
    throw e;
  }
}

export const addAlgoReference = async (params) => {
  try {
    const url = `/algo_reference/algo_references`;
    const serverParams = algoConfigAdapter.toServer(params);
    const response = await api.post(url, serverParams);
    return response.data;
  } catch (e) {
    throw e;
  }
}

export const updateAlgoReference = async (params, id) => {
  try {
    const url = `/algo_reference/algo_references/${id}`;
    const serverParams = algoConfigAdapter.toServer(params);
    const response = await api.put(url, serverParams);
    return response.data;
  } catch (e) {
    throw e;
  }
}

export const getAlgoReference = async (params) => {
  try {
    const url = `/algo_reference/algo_references?${stringify(params)}`;
    const response = await api.get(url);
    return algoReferenceAdapter.toClient(response.data);
  } catch (e) {
    throw e;
  }
}

export const deleteAlgoReference = async (id) => {
  try {
    const url = `/algo_reference/algo_references/${id}`;
    const response = await api.delete(url);
    return response.data;
  } catch (e) {
    throw e;
  }
}
