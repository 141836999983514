import Grid from "@material-ui/core/Grid/Grid";
import SidePanel from "./SidePanel/SidePanel";
import {withStyles} from "@material-ui/core";
import React from "react";
import { Route, Switch } from 'react-router-dom';

import SiteList from './SiteList';
import SiteManagerList from './SiteManagerList';
import SiteRobotList from './SiteRobotList';
import SiteBoxList from './SiteBoxList';
import AddSite from './AddSite';
import AddSiteManager from './AddSiteManager';
import AssociateSiteManager from './AssociateSiteManager';
import SiteBuilding from './SiteBuilding';

const sidePanelWidth = 200;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto'
  },
  sidePanel: {
    padding: theme.spacing(2),
    width: sidePanelWidth,
    height: '100%',
    overflow: 'auto'
  },
  mainPanel: {
    width: `calc(100% - ${sidePanelWidth}px)`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // display: 'flex',
    flex: 1,
    height: '100%',
    overflow: 'auto'
  },
  toolbar: theme.mixins.toolbar,
});

const SiteManage = props => {
  const { classes } = props;
  return (
      <Grid
          className={classes.root}
          direction="row"
          container={true}
          justify="flex-start"
          spacing={0}
      >
        <Grid className={classes.sidePanel} item={true}>
          <SidePanel/>
        </Grid>
        <Grid className={classes.mainPanel} item={true}>
          <Switch>
            <Route path='/sitemanage/site' component={SiteList} />
            <Route path='/sitemanage/manager' component={SiteManagerList} />
            <Route path='/sitemanage/robot' component={SiteRobotList} />
            <Route path='/sitemanage/box' component={SiteBoxList} />
            <Route path='/sitemanage/addsite' component={AddSite} />
            <Route path='/sitemanage/addmanager' component={AddSiteManager} />
            <Route path='/sitemanage/associatemanager' component={AssociateSiteManager} />
            <Route path='/sitemanage/building' component={SiteBuilding} />
          </Switch>
        </Grid>
      </Grid>
  );
};

export default withStyles(styles)(SiteManage);
