import React, { Component } from 'react';
import {
  InputLabel, Grid, Button, TextField, Typography, FormControl, Select, MenuItem, FormHelperText, IconButton,
  Dialog, DialogTitle, DialogContent, DialogActions, Backdrop, CircularProgress, withStyles
} from '@material-ui/core';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { notificationActions } from 'src/redux/actions';
import { getText } from 'src/utils/MultilingualLoader';
import * as siteServices from 'src/services/site';

const styles = theme => ({
  formItem: {
    width: 300,
    marginBottom: 15,
  },
  backdrop: {
    zIndex: 9999,
  }
});

const mapStateToProps = state => {
  return {
    sites: state.site.sites,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addNotification: (message) => {
      dispatch(notificationActions.addNotification(message));
    },
  };
};

class AddBuilding extends Component {
  constructor(props) {
    super(props);
    const { building } = props;
    this.state = {
      submitting: false,
      form: {
        name: building ? building.name : '',
        site_id: building ? building.site_id : '',
      },
      validate: {
        name: '',
        site_id: ''
      }
    }
  }

  handleChange = (key) => {
    return (event) => {
      const { form } = this.state;
      const newValue = {
        ...form,
        [key]: event && event.target ? event.target.value : event,
      };
      this.setState({
        form: newValue
      })
    };
  }

  validator = () => {
    const { form } = this.state;
    let hasError = false;
    const validate = {};
    if (!form.name || !form.name.trim()) {
      validate.name = getText('required');
      hasError = true;
    }
    if (!form.site_id) {
      validate.site_id = getText('required');
      hasError = true;
    }
    this.setState({
      validate
    });
    return hasError;
  }

  handleSubmit = async () => {
    const { building, onClose, addNotification } = this.props;
    const { form } = this.state;
    const hasError = this.validator();
    if (hasError) {
      return;
    }

    const data = {
      name: form.name.trim(),
      site_id: form.site_id,
    };
    this.setState({ submitting: true });
    if (building) {
      try {
        await siteServices.updateSiteBuilding(building.id, data);
        addNotification(`${getText('update')}${getText('words_space')}${getText('building')}${getText('words_space')}${getText('succeeded')}`);
        onClose(true);
      } catch (e) {
        addNotification(`${getText('update')}${getText('words_space')}${getText('building')}${getText('words_space')}${getText('failed')}`);
      }
    } else {
      try {
        await siteServices.createSiteBuilding(data);
        addNotification(`${getText('add')}${getText('words_space')}${getText('building')}${getText('words_space')}${getText('succeeded')}`);
        onClose(true);
      } catch (e) {
        addNotification(`${getText('add')}${getText('words_space')}${getText('building')}${getText('words_space')}${getText('failed')}`);
      }
    }
    this.setState({ submitting: false });
  }

  render() {
    const { building, onClose, sites, classes } = this.props;
    const { submitting, form, validate } = this.state;
    return (
      <>
        <Dialog
          maxWidth="md"
          open={true}
          onClose={() => onClose()}
        >
          <DialogTitle>
            <Grid container justify="space-between" alignItems="center">
              <Typography variant="h5">
                {getText(building ? 'edit_building' : 'add_building')}
              </Typography>
              <IconButton onClick={() => { onClose() }}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <TextField
              label={getText('name')}
              required
              value={form.name}
              onChange={this.handleChange('name')}
              error={!!validate.name}
              helperText={validate.name}
              fullWidth
              className={classes.formItem}
            />
            <div style={{ height: 10 }} />
            <FormControl fullWidth required error={!!validate.site_id} className={classes.formItem}>
              <InputLabel id="site_id">{getText('site')}</InputLabel>
              <Select
                labelId="site_id"
                value={form.site_id}
                onChange={this.handleChange('site_id')}
              >
                {(sites || []).map(site => (
                  <MenuItem key={site.id} value={site.id}>{site.label || site.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{validate.site_id}</FormHelperText>
            </FormControl>
            <div style={{ height: 10 }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose()} color="primary" variant="outlined">
              {getText('cancel')}
            </Button>
            <Button onClick={this.handleSubmit} color="primary" variant="outlined">
              {getText('ok')}
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop className={classes.backdrop} open={submitting}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }

};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddBuilding));
