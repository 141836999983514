import React from "react";
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { getText } from "src/utils/MultilingualLoader";

const styles = (theme) => ({
  title: {
    position: "relative",
    flex: 1, // stretch title height to align between cards
  },
  pointName: {
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const Modal = ({ classes, children, maxWidth, onConfirm, onCancel, title }) => {
  return (
    <Dialog
      onClose={onCancel}
      open={true}
      maxWidth={maxWidth || "xs"}
      fullWidth={true}
    >
      <DialogTitle>
        <Typography>{title}</Typography>{" "}
        <CloseIcon className={classes.closeButton} onClick={onCancel} />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          {getText("cancel")}
        </Button>
        <Button color="primary" variant="contained" onClick={onConfirm}>
          {getText("ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(Modal);
