import React from 'react';

import EventPlayer from 'src/pages/Main/Player/EventPlayer';
import EventSnapshot from './EventSnapshot';
// import moment from 'moment';

class EventMedia extends React.Component {
  constructor(props) {
    super(props);
    const height = (window.innerWidth - 200 - 350 - 16 - 24) / 2 * 9 / 16;
    this.state = {
      eventRecordHeight: window.innerWidth < 1280 ? height * 2 : height
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeVideo);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeVideo);
  }

  resizeVideo = () => {
    const height = (window.innerWidth - 200 - 350 - 16 - 24) / 2 * 9 / 16;
    this.setState({
      eventRecordHeight: window.innerWidth < 1280 ? height * 2 : height
    });
  }

  render() {
    const { eventRecordHeight } = this.state
    const { selected } = this.props;
    const videos = selected.event ? selected.event.videos : [];
    const snapshots = selected.event ? selected.event.snapshots : [];
    const hasValidVideo = videos.some(video => video.fileSize > 0);

    let playList = Object.assign([], videos);
    // list for test
    // let playList = [
    //   {url: 'http://clips.vorwaerts-gmbh.de/VfE_html5.mp4', startTime: moment('2019-03-12T20:53:00Z'), endTime: moment('2019-03-12T20:54:00Z')}, 
    //   {url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4', startTime: moment('2019-03-12T20:54:00Z'), endTime: moment('2019-03-12T20:54:15Z')}, 
    //   {url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4', startTime: moment('2019-03-12T20:54:15Z'), endTime: moment('2019-03-12T20:54:30Z')} 
    // ];
    // let event = {startTime: moment('2019-03-12T20:53:55Z'), endTime: moment('2019-03-12T20:54:20Z'), id: 'test'};
    return (
      <>
        {hasValidVideo ?
          <EventPlayer
            // event={event}
            event={selected.event}
            caseId={selected.id}
            playList={playList}
            minHeight={eventRecordHeight}
          />
          : <EventSnapshot
            snapshots={snapshots}
            selected={selected}
          />
        }
      </>
    );
  }
}

export default EventMedia;
