import * as _userConstants from "./user";
import * as _caseConstants from "./case";
import * as _guardConstants from "./guard";
import * as _cameraConstants from "./camera";
import * as _wsConstants from "./websocket";
import * as _robotConstants from "./robot";
import * as _notificationConstants from "./notification";
import * as _manageConstants from "./manage";
import * as _siteConstants from "./site";
import * as _inspectionConstants from "./inspection";
import * as _sensorConstants from "./sensor";
import * as _targetConstants from "./target";
import * as _taskConstants from "./task";
import * as _referenceConstants from "./reference";
import * as _mapConstants from "./map";
import * as _routineHistoryConstants from "./routineHistory";
import * as _messageConstants from "./message";

export const userConstants = _userConstants;
export const caseConstants = _caseConstants;
export const guardConstants = _guardConstants;
export const cameraConstants = _cameraConstants;
export const wsConstants = _wsConstants;
export const robotConstants = _robotConstants;
export const notificationConstants = _notificationConstants;
export const manageConstants = _manageConstants;
export const siteConstants = _siteConstants;
export const inspectionConstants = _inspectionConstants;
export const sensorConstants = _sensorConstants;
export const targetConstants = _targetConstants;
export const taskConstants = _taskConstants;
export const referenceConstants = _referenceConstants;
export const mapConstants = _mapConstants;
export const routineHistoryConstants = _routineHistoryConstants;
export const messageConstants = _messageConstants;
