import React from 'react';
import { Stage, Layer, Image, Line } from 'react-konva';

export default function RobotMapItem(props) {
  const { image, mapWidth, mapHeight, route, getImagePosition } = props;

  const mapRoute = (route && route.way_points) || [];
  let pathLines = [];
  let prev = null;
  if (image && mapRoute) {
    mapRoute.forEach((wayPoint, idx) => {
      const position = wayPoint.point && getImagePosition(wayPoint.point.x, wayPoint.point.y);
      if (position && position.x && position.y && !isNaN(position.x) && !isNaN(position.y)) {
        if (prev) {
          pathLines.push(
            <Line key={'line' + idx}
              points={[position.x, position.y, prev.x, prev.y]}
              stroke="green"
              strokeWidth={1} />
          )
        }
        prev = position;
      }
    });
  }

  return (
    <Stage
      height={mapHeight} width={mapWidth} x={0} y={0}
    >
      <Layer>
        <Image x={0} y={0} image={image} height={mapHeight} width={mapWidth} />
      </Layer>
      <Layer>
        {pathLines}
      </Layer>
    </Stage>
  );
}
