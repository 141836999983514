import React from 'react';
import { connect } from 'react-redux';

import { inspectionActions } from 'src/redux/actions';
import Waypoints from './Waypoints';

const mapStateToProps = (state) => {
  return {
    selectedWaypoint: state.inspection.selectedWaypoint
  };
}

const mapDispatchToProps = dispatch => {
  return {
    selectWaypoint: (waypointId, inspections) => dispatch(inspectionActions.selectWaypoint(waypointId, inspections))
  };
}

class WaypointsContainer extends React.Component {
  handleClick = (waypointId, inspections) => () => {
    this.props.selectWaypoint(waypointId, inspections);
  }

  render() {
    const { getImagePosition, waypoints, selectedWaypoint } = this.props;
    return (
      <Waypoints
        getImagePosition={getImagePosition}
        waypoints={waypoints}
        selectedWaypoint={selectedWaypoint}
        onClick={this.handleClick}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaypointsContainer);
