import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { getText } from "src/utils/MultilingualLoader";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    margin: "12px 0",
  },
  tableCell: {
    borderStyle: "none",
  },
  form: {
    width: "100%",
  },
  button: {
    backgroundColor: "rgb(216, 215, 215)",
    float: "right",
  },
});

const RobotDetectionConfig = (props) => {
  const { classes, configs, onSubmit } = props;
  return (
    <form className={classes.form}>
      <Table>
        <TableBody>
          {configs.map(
            (config) =>
              !config.hide && (
                <TableRow key={config.title}>
                  <TableCell className={classes.tableCell} padding="default">
                    <Typography variant="body2">{config.title}</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} padding="default">
                    {config.content}
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
      <Button className={classes.button} onClick={onSubmit}>
        <Typography variant="body2">{getText("submit")}</Typography>
      </Button>
    </form>
  );
};

RobotDetectionConfig.propTypes = {
  configs: PropTypes.array.isRequired,
};

RobotDetectionConfig.defaultProps = {
  configs: [],
};

export default withStyles(styles)(RobotDetectionConfig);
