import React from 'react';
import { connect } from 'react-redux';

import SensorMap from './SensorMap';

const mapStateToProps = (state, props) => {
  const { robotMapLists } = state.robot;
  const robotMapList = robotMapLists[props.selectedRobot.id];
  const { sensors, filters, sensorType, showType } = state.sensor;
  const sensorData = sensors[props.selectedRobot.id];
  const { mapId, routeId, radius } = filters;
  return { robotMapList, sensorData, mapId, routeId, sensorType, showType, radius };
}

const mapDispatchToProps = {
}

class SensorMapContainer extends React.Component {
  constructor(props) {
    super(props);
    const width = window.innerWidth - 200 - 200 - 16;
    const height = window.innerHeight - 64 - 48 - 152;
    this.state = {
      maxWidth: width,
      maxHeight: height
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeCard);
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.resizeCard);
  }

  resizeCard = () => {
    const width = window.innerWidth - 200 - 200 - 16;
    const height = window.innerHeight - 64 - 48 - 96;
    this.setState({
      maxWidth: width,
      maxHeight: height
    });
  }

  render() {
    const { robotMapList, sensorData, mapId, routeId, sensorType, showType, radius } = this.props;
    const { maxWidth, maxHeight } = this.state;
    const robotMap = mapId && robotMapList && robotMapList.maps && robotMapList.maps.find(map => map.id === mapId);
    const robotRoute = robotMap && robotMap.mapRoutes && robotMap.mapRoutes.find(route => route.id === routeId);
    const mapLoading = robotMap ? robotMap.loading : null;
    const mapUrl = robotMap ? robotMap.mapUrl : null;
    const mapConfig = robotMap ? robotMap.mapConfig : null;
    const sensorClusters = (sensorData && sensorData.clusters) || [];
    const sensorPoints = (sensorData && sensorData.points) || [];
    
    return (
      <SensorMap
        mapUrl={mapUrl || ''}
        mapConfig={mapConfig || {}}
        mapLoading={mapLoading}
        route={robotRoute}
        showType={showType}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        sensorClusters={sensorClusters}
        sensorPoints={sensorPoints}
        sensorType={sensorType}
        radius={radius}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SensorMapContainer);

