import React, { useState } from 'react';
import {
  withStyles, Grid, Dialog, DialogTitle, DialogContent, Table, TableBody, TableRow, TableCell,
  Typography, Button, TextField, FormGroup, FormControlLabel, Checkbox
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import RobotMap from './RobotMap';
import DropDownSelectorHorizontal from 'src/components/Selectors/DropDownSelectorHorizontal';
import OnOffSwitch from 'src/components/Switches/OnOffSwitch';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  dialog: {
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    '&:hover': {
      cursor: 'pointer'
    }
  },
  tableCell: {
    borderStyle: 'none',
  },
  button: {
    marginLeft: 12
  },
  buttonText: {
    color: 'white',
    textTransform: 'initial'
  },
  submitButtons: {
    marginTop: theme.spacing(2),
    float: 'right'
  },
  textInput: {
    margin: '0 0 5px 5px',
    width: '100px'
  }
});

const RoutineAction = props => {
  const { classes, action, open, confirmEditAction, selectedRoutineIdx, selectedActionIdx, robotMapList,
    handleActionChange, onRelocalize, deleteAction, cancelCreateAction, confirmCreateAction, targets, actions } = props;
  const selectedMapId = action && action.arg && action.arg.map_id;
  const selectedMap = selectedMapId && robotMapList && robotMapList.maps && robotMapList.maps.find(map => map.id === selectedMapId);
  const routes = selectedMap && selectedMap.mapRoutes;
  const [mapOpen, setMapOpen] = useState(false);

  const maxMapWidth = window.innerWidth * 0.8;
  const maxMapHeight = window.innerHeight * 0.9;
  let routineAction = null;

  function getLoopTargets() {
    if (!robotMapList) {
      return [];
    }
    if ( 
      (selectedActionIdx === -1 && actions.length > 0 && actions[actions.length - 1].act === 'load_map_route')
      || (actions.length > selectedActionIdx && actions[selectedActionIdx - 1] && actions[selectedActionIdx - 1].act === 'load_map_route')
    ) {
      const loadMapAction = selectedActionIdx === -1 ? actions[actions.length - 1] : actions[selectedActionIdx - 1];
      const mapId = loadMapAction.arg.map_id;
      const routeId = loadMapAction.arg.route_id;
      const map = robotMapList.maps.find(map => map.id === mapId);
      if (map) {
        const route = map.mapRoutes.find(route => route.id === routeId);
        if (route) {
          const targetMap = new Map(targets.map(target => [String(target.id), target]));
          const wayPoints = route.way_points;
          return wayPoints.map(wayPoint => (wayPoint.actions || [])
            .map(action => action.act === 'snapshot' && action.arg.target_id ? {
              targetId: action.arg.target_id,
              wayPointId: wayPoint.id,
              target: targetMap.get(String(action.arg.target_id)) || {}
            } : ''))
            .flat()
            .filter(target => Boolean(target));
        }
      }
    }
    return [];
  }

  switch (action && action.act) {
    case 'load_map_route':
      routineAction = (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell} padding="default">
                <Typography variant="body2">
                  {getText('delay_secs')}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell} padding="default">
                <input
                  key={'delay_secs'}
                  type="number"
                  style={{ fontSize: '1rem' }}
                  value={action.arg && action.arg.delay_secs}
                  onChange={handleActionChange('delay_secs', 'Integer', selectedRoutineIdx, selectedActionIdx)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell} padding="default">
                <Typography variant="body2">
                  {getText('map_id')}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell} padding="default">
                <DropDownSelectorHorizontal
                  title={''}
                  options={(robotMapList && robotMapList.maps && robotMapList.maps.map(map => ({ label: `${map.name} ${map.id}`, value: map.id }))) || []}
                  value={action.arg && action.arg.map_id}
                  onChange={handleActionChange('map_id', 'string', selectedRoutineIdx, selectedActionIdx)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell} padding="default">
                <Typography variant="body2">
                  {getText('route_id')}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell} padding="default">
                <DropDownSelectorHorizontal
                  title={''}
                  options={(routes && routes.map(route => ({ label: `${route.name} ${route.id}`, value: route.id }))) || []}
                  value={action.arg && action.arg.route_id}
                  onChange={handleActionChange('route_id', 'string', selectedRoutineIdx, selectedActionIdx)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell} padding="default">
                <Typography variant="body2">
                  {getText('reset_slam')}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell} padding="default">
                <OnOffSwitch
                  value={action.arg && action.arg.reset_slam}
                  onChange={handleActionChange('reset_slam', 'boolean', selectedRoutineIdx, selectedActionIdx)}
                />
              </TableCell>
            </TableRow>
            {action.arg && action.arg.reset_slam &&
              <TableRow>
                <TableCell className={classes.tableCell} padding="default">
                  <Typography variant="body2">
                    {getText('robot_location')}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} padding="default">
                  <div>
                    <TextField
                      className={classes.textInput}
                      label="x"
                      type="number"
                      InputLabelProps={{shrink: true}}
                      onChange={handleActionChange('x', 'float', selectedRoutineIdx, selectedActionIdx)}
                      value={action.arg && action.arg.x}
                    />
                    <TextField
                      className={classes.textInput}
                      label="y"
                      type="number"
                      InputLabelProps={{shrink: true}}
                      onChange={handleActionChange('y', 'float', selectedRoutineIdx, selectedActionIdx)}
                      value={action.arg && action.arg.y}
                    />
                    <TextField
                      className={classes.textInput}
                      label="theta"
                      type="number"
                      InputLabelProps={{shrink: true}}
                      onChange={handleActionChange('theta', 'float', selectedRoutineIdx, selectedActionIdx)}
                      value={action.arg && action.arg.theta}
                    />
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setMapOpen(true)}
                    className={classes.button}
                    disabled={!selectedMap}
                  >
                    <Typography variant="body2" className={classes.buttonText}>
                      {getText('locate_on_map')}
                    </Typography>
                  </Button>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      );
      break;
    case 'take_elevator':
      routineAction = (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell} padding="default">
                <Typography variant="body2">
                  {getText('from_floor')}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell} padding="default">
                <input
                  key={'from_floor'}
                  type="number"
                  style={{ fontSize: '1rem' }}
                  value={action.arg && action.arg.from_floor}
                  onChange={handleActionChange('from_floor', 'Integer', selectedRoutineIdx, selectedActionIdx)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell} padding="default">
                <Typography variant="body2">
                  {getText('to_floor')}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell} padding="default">
                <input
                  key={'to_floor'}
                  type="number"
                  style={{ fontSize: '1rem' }}
                  value={action.arg && action.arg.to_floor}
                  onChange={handleActionChange('to_floor', 'Integer', selectedRoutineIdx, selectedActionIdx)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
      break;
    case 'target_loop':
      const loopTargets = getLoopTargets();
      routineAction = (
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox
                color="primary"
                onChange={(evt)=>{
                  handleActionChange('target_list', 'array', selectedRoutineIdx, selectedActionIdx)({
                    target:{
                      value: evt.target.checked ? loopTargets.map(v=>v.targetId) : []
                    }
                  })
                }}
                checked={action.arg.target_list && action.arg.target_list.length === loopTargets.length}
              />}
              label={getText('all')}
            />
          </Grid>
          <Grid item xs container alignItems="center">
            {loopTargets.map((loopTarget, index) => (
              <FormControlLabel
                style={{minWidth: 200}}
                key={index}
                control={<Checkbox
                  value={loopTarget.targetId}
                  color="primary"
                  onChange={handleActionChange('target_loop', 'string', selectedRoutineIdx, selectedActionIdx)}
                  checked={action.arg.target_list && action.arg.target_list.includes(loopTarget.targetId)}
                />}
                label={loopTarget.target.label}
              />
            ))}
          </Grid>
        </Grid>
      );
      break;
    case 'one_loop': case 'patrol': case 'go_home': case 'leave_home':
      break;
    default:
      break;
  }

  return (
    <>
      <Dialog
        className={classes.dialog}
        maxWidth="md"
        open={open}
      >
        <DialogTitle>
          {selectedActionIdx === -1 ? getText('create_action') : getText('edit_action')}
        </DialogTitle>
        <DialogContent>
          {routineAction}
          <div className={classes.submitButtons}>
            {selectedActionIdx !== -1 &&
              <Button
                variant="contained"
                color="primary"
                onClick={deleteAction(selectedRoutineIdx, selectedActionIdx)}
                className={classes.button}
              >
                <Typography variant="body2" className={classes.buttonText}>
                  {getText('delete_action')}
                </Typography>
              </Button>
            }
            <Button
              variant="contained"
              color="primary"
              onClick={cancelCreateAction}
              className={classes.button}
            >
              <Typography variant="body2" className={classes.buttonText}>
                {getText('cancel')}
              </Typography>
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={selectedActionIdx === -1 ? confirmCreateAction : confirmEditAction(selectedRoutineIdx, selectedActionIdx)}
              className={classes.button}
            >
              <Typography variant="body2" className={classes.buttonText}>
                {getText('confirm')}
              </Typography>
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {selectedMap &&
        <Dialog
          className={classes.dialog}
          fullScreen
          open={mapOpen}
          onClose={() => setMapOpen(false)}
        >
          <DialogTitle>
            <Typography variant="body2">{getText('relocalization_description')}</Typography>
            <CloseIcon
              className={classes.closeButton}
              onClick={() => setMapOpen(false)}
            />
          </DialogTitle>
          <DialogContent>
            <RobotMap
              maxMapWidth={maxMapWidth}
              maxMapHeight={maxMapHeight}
              map={selectedMap}
              routeId={action && action.arg && action.arg.route_id}
              curRelocalizationPosition={action && action.arg && action.arg.x && action.arg.y && action.arg.theta ? {
                x: action.arg.x,
                y: action.arg.y,
                theta: action.arg.theta
              } : null}
              onRelocalize={(x, y, theta) => {
                onRelocalize(selectedRoutineIdx, selectedActionIdx)(x, y, theta);
                setMapOpen(false);
              }}
            />
          </DialogContent>
        </Dialog>
      }
    </>
  );
};

export default withStyles(styles)(RoutineAction);