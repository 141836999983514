import React from 'react';
import {
  connect
} from 'react-redux';

import {
  notificationActions
} from 'src/redux/actions';

import Notifications from "./Notifications";

const mapStateToProps = state => {
  const {
    messages
  } = state.notification;
  return {
    messages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    msgShowed: () => {
      dispatch(notificationActions.notificationShowed());
    }
  };
};

class NotificationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      msg: null
    };
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      open: false,
    }, () => {
      this.props.msgShowed();
    });
  }

  showMessage = messages => {
    if (messages && messages.length > 0) {
      this.setState({
        open: true,
        msg: messages[0]
      });
    }
    // close if message is removed out of this component
    if (this.state.open && (!messages || messages.length === 0)) {
      this.setState({
        open: false,
        msg: null
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.messages !== this.props.messages) {
      this.showMessage(this.props.messages);
    }
  }

  render() {
    const {
      open, msg
    } = this.state;

    return (
      <Notifications open={open} msg={msg} onClose={this.handleClose} />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationContainer);
