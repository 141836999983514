import React from 'react';
import uuid from 'uuid';

const assignID = (Component) => {
  return class extends React.Component {
    constructor(props){
      super(props);
      this.componentId = uuid();
    }
    render(){
      return <Component componentId={this.componentId} {...this.props} />
    };
  };
}

export default assignID;