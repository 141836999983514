import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import {Grid, Dialog, DialogTitle, DialogContent, FormLabel, TextField, Button} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
  },
  tableGrid: {
    overflow: 'auto',
  },
  filter: {
    width: '100%',
    paddingBottom: 20,
  },
  label: {
    width: 100,
  },
  textField: {
    minWidth: 400,
  }
});

const RouteConfigs = props => {
  const {
    classes,
    maps,
    map,
    routes,
    newRoute,
    oldRoute,
    copyLoading,
    hideMutator,
    handleSubmit,
    handleAutoComplete,
  } = props;

  return (
    <Dialog open={true} onClose={hideMutator} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle>
        {getText('copy_route')}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid container item alignItems="center">
            <FormLabel component="label" className={classes.label}>{getText('map')}</FormLabel>
            <Grid>
              <Autocomplete
                options={maps}
                getOptionLabel={option => option && option.label ? option.label : ''}
                disableListWrap
                onChange={handleAutoComplete('map')}
                value={map}
                renderInput={params => (
                  <TextField 
                    {...params}
                    className={classes.textField}
                    margin="normal" 
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="center">
            <FormLabel component="label" className={classes.label}>{getText('old_route')}</FormLabel>
            <Grid>
              <Autocomplete
                options={routes}
                getOptionLabel={option => option && option.label ? option.label : ''}
                disableListWrap
                onChange={handleAutoComplete('oldRoute')}
                value={oldRoute}
                renderInput={params => (
                  <TextField 
                    {...params}
                    className={classes.textField}
                    margin="normal" 
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="center">
            <FormLabel component="label" className={classes.label}>{getText('new_route')}</FormLabel>
            <Grid>
              <Autocomplete
                options={routes}
                getOptionLabel={option => option && option.label ? option.label : ''}
                disableListWrap
                onChange={handleAutoComplete('newRoute')}
                value={newRoute}
                renderInput={params => (
                  <TextField 
                    {...params}
                    className={classes.textField}
                    margin="normal" 
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="center" justify="flex-end" style={{marginBottom: 20}}>
            <Button variant="outlined" onClick={handleSubmit} disabled={copyLoading}>{getText('submit')}</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};  

export default withStyles(styles)(RouteConfigs);
