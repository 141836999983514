import React, { Component } from 'react';
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Select,
  withStyles,
  RadioGroup,
  Radio,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  IconButton,
  Switch,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { Delete as DeleteIcon, AddCircle as AddIcon } from '@material-ui/icons';
import MomentUtils from "@date-io/moment";
import moment from 'moment';
import { timezoneList } from 'src/utils/locale/timezone';
import { getText, getDateLocale } from 'src/utils/MultilingualLoader';
import styles from './Schedule.style';

const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

class ScheduleConfig extends Component {
  constructor(props) {
    super(props);
    const { schedule } = props;
    this.state = this.getInitState(schedule);
  }

  componentDidUpdate(prevProps) {
    const { schedule } = this.props;
    if (prevProps.schedule !== schedule) {
      this.setState(this.getInitState(schedule));
    }
  }

  getInitState = (schedule) => {
    let localeTimezone = '';
    try {
      if (window.Intl) {
        localeTimezone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    } catch (e) {
      // do nothing
    }

    return ({
      form: {
        name: (schedule && schedule.name) || '',
        routine_id: (schedule && schedule.routine_id) || '',
        repetitive: schedule && typeof schedule.repetitive === 'boolean' ? schedule.repetitive : true,
        days_of_week: (schedule && schedule.days_of_week) ? [...schedule.days_of_week] : [], // only used when repetitive is true
        date: schedule && schedule.date ? moment(schedule.date) : null, // only used when repetitive is false
        start_times: (schedule && schedule.start_times) ? schedule.start_times.map(minutes => {
          if (typeof minutes === 'number') {
            const hour = Math.floor(minutes / 60);
            const minute = minutes % 60;
            return moment().hours(hour).minutes(minute);
          }
          return null;
        }) : [null],
        disabled: (schedule && schedule.disabled) || false,
        timezone: (schedule && schedule.timezone) || localeTimezone
      },
      validate: {
        name: '',
        routine_id: '',
        days_of_week: '',
        date: '',
        start_times: '',
        timezone: '',
      }
    })
  }

  handleChange = (key) => (event) => {
    const value = event && event.target ? event.target.value : event;
    this.setState(state => {
      return {
        form: {
          ...state.form,
          [key]: value
        }
      }
    }, this.validate)
  }

  handleChangeRepetitive = event => {
    const value = event.target.value === '1' ? true : false;
    this.setState({
      form: {
        ...this.state.form,
        repetitive: value,
        days_of_week: [],
        date: null,
      }
    }, this.validate)
  }

  handleChangeTime = (type, index) => time => {
    this.setState(state => {
      const start_times = state.form.start_times;
      if (type === 'add') {
        start_times.push(null);
      } else if (type === 'delete') {
        start_times.splice(index, 1);
      } else if (type === 'update') {
        start_times[index] = time;
      }
      return {
        form: {
          ...state.form,
          start_times
        }
      }
    }, this.validate);
  }

  validate = () => {
    const { form } = this.state;
    const validate = {};
    if (!form.name || !form.name.trim()) {
      validate.name = getText('required');
    }
    if (!form.routine_id) {
      validate.routine_id = getText('required');
    }
    if (form.repetitive && form.days_of_week.length === 0) {
      validate.days_of_week = getText('required');
    }
    if (!form.repetitive && !form.date) {
      validate.date = getText('required');
    }
    if (!form.timezone) {
      validate.timezone = getText('required');
    }
    if (form.start_times.filter(v => !!v).length === 0) {
      validate.start_times = getText('routine_schedule_start_times_error');
    }
    this.setState({ validate });

    return Object.keys(validate) > 0;
  }

  handleSave = () => {
    const hasError = this.validate();
    if (!hasError) {
      const { onSubmit } = this.props;
      const { form } = this.state;
      const data = {
        name: form.name.trim(),
        routine_id: form.routine_id,
        repetitive: form.repetitive,
        start_times: form.start_times.filter(v => !!v).map(v => v.hours() * 60 + v.minutes()),
        disabled: form.disabled,
        timezone: form.timezone,
        // timezone: "Local"
      };
      if (form.repetitive) {
        data.days_of_week = form.days_of_week;
      } else {
        data.date = form.date.clone().hours(0).minutes(0).seconds(0).milliseconds(0).format();
      }
      onSubmit(data);
    }

  }

  render() {
    const { classes, routines, schedule, onCancel, onDelete } = this.props;
    const { form, validate } = this.state;

    return (
      <>
        <TableRow className={classes.tableRowTop}>
          <TableCell className={classes.tableCell} padding="default">
            <Typography variant="body2" className={classes.label}>
              {getText('name')}
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell} padding="default">
            <OutlinedInput
              type='text'
              classes={{ input: classes.selectorInput }}
              value={form.name}
              onChange={this.handleChange('name')}
            />
            <div className={classes.helpText}>{validate.name}</div>
          </TableCell>
        </TableRow>

        <TableRow className={classes.tableRowTop}>
          <TableCell className={classes.tableCell} padding="default">
            <Typography variant="body2" className={classes.label}>
              {getText('enable')}
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell} padding="default">
            <Switch
              color="primary"
              checked={!form.disabled}
              onChange={(event) => { this.handleChange('disabled')(!event.target.checked) }}
              name="enable"
            />
          </TableCell>
        </TableRow>

        <TableRow className={classes.tableRowTop}>
          <TableCell className={classes.tableCell} padding="default">
            <Typography variant="body2" className={classes.label}>
              {getText('routine')}
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell} padding="default">
            <Select
              variant="outlined"
              input={<OutlinedInput labelWidth={0} classes={{ input: classes.selectorInput }} />}
              value={form.routine_id}
              onChange={this.handleChange('routine_id')}
            >
              {routines.map((routine) =>
                <MenuItem key={routine.id} value={routine.id}>{routine.id} {routine.name}</MenuItem>
              )}
            </Select>
            <div className={classes.helpText}>{validate.routine_id}</div>
          </TableCell>
        </TableRow>

        <TableRow className={classes.tableRowTop}>
          <TableCell className={classes.tableCell} padding="default">
            <Typography variant="body2" className={classes.label}>
              {getText('routine_schedule_repetitive')}
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell} padding="default">
            <RadioGroup
              value={form.repetitive ? '1' : '0'}
              onChange={this.handleChangeRepetitive}
              className={classes.radioGroup}
            >
              <FormControlLabel value="1" control={<Radio size="small" color="primary" />} label={getText('routine_schedule_repeat_weekly')} />
              <FormControlLabel value="0" control={<Radio size="small" color="primary" />} label={getText('routine_schedule_repeat_once')} />
            </RadioGroup>
          </TableCell>
        </TableRow>

        <TableRow className={classes.tableRowTop}>
          <TableCell className={classes.tableCell} padding="default">
            <Typography variant="body2" className={classes.label}>
              {getText('timezone')}
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell} padding="default">
            <Select
              variant="outlined"
              input={<OutlinedInput labelWidth={0} classes={{ input: classes.selectorInput }} />}
              value={form.timezone}
              onChange={this.handleChange('timezone')}
            >
              {timezoneList.map((timezone) =>
                <MenuItem key={timezone} value={timezone}>{timezone}</MenuItem>
              )}
            </Select>
            <div className={classes.helpText}>{validate.timezone}</div>
          </TableCell>
        </TableRow>

        <TableRow className={classes.tableRowTop}>
          <TableCell className={classes.tableCell} padding="default">
            <Typography variant="body2" className={classes.label}>
              {form.repetitive ? getText('routine.schedule.day') : getText('date')}
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell} padding="default">
            {
              form.repetitive ? (
                <>
                  <Select
                    variant="outlined"
                    multiple
                    input={<OutlinedInput labelWidth={0} classes={{ input: classes.selectorInput }} />}
                    value={form.days_of_week}
                    onChange={this.handleChange('days_of_week')}
                  >
                    {weekdays.map((day, index) =>
                      <MenuItem key={day} value={index} classes={{ selected: classes.selected }}>{getText(day)}</MenuItem>
                    )}
                  </Select>
                  <div className={classes.helpText}>{validate.days_of_week}</div>
                </>
              ) : (
                  <>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale={getDateLocale()}>
                      <DatePicker
                        value={form.date}
                        onChange={this.handleChange('date')}
                        showTodayButton
                        format="yyyy/MM/DD"
                        cancelLabel={getText('cancel')}
                        okLabel={getText('ok')}
                        todayLabel={getText('today')}
                        TextFieldComponent={(props) => (<OutlinedInput classes={{ input: classes.selectorInput }} {...props} />)}
                      />
                    </MuiPickersUtilsProvider>
                    <div className={classes.helpText}>{validate.date}</div>
                  </>
                )
            }
          </TableCell>
        </TableRow>

        <TableRow className={classes.tableRowTop}>
          <TableCell className={classes.tableCell} padding="default">
            <Typography variant="body2" className={classes.label}>
              {getText('time')}
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell} padding="default">
            {
              form.start_times.map((time, index) => (
                <div key={index}>
                  <MuiPickersUtilsProvider utils={MomentUtils} locale={getDateLocale()}>
                    <TimePicker
                      value={time}
                      onChange={this.handleChangeTime('update', index)}
                      cancelLabel={getText('cancel')}
                      okLabel={getText('ok')}
                      format="HH:mm"
                      TextFieldComponent={(props) => (<OutlinedInput classes={{ input: classes.timeInput }} {...props} />)}
                    />
                  </MuiPickersUtilsProvider>
                  {index < form.start_times.length - 1 ? (
                    <IconButton
                      className={classes.IconButton}
                      onClick={this.handleChangeTime('delete', index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : (
                      <>
                        {index > 0 && (
                          <IconButton
                            className={classes.IconButton}
                            onClick={this.handleChangeTime('delete', index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}

                        <IconButton
                          className={classes.IconButton}
                          onClick={this.handleChangeTime('add')}
                        >
                          <AddIcon />
                        </IconButton>
                      </>
                    )}
                </div>
              ))
            }
            <div className={classes.helpText}>{validate.start_times}</div>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan="2" className={classes.submitButtons}>
            {!schedule && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onCancel}
              >
                <Typography variant="body2" className={classes.buttonText}>
                  {getText('cancel')}
                </Typography>
              </Button>
            )}

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleSave}
            >
              <Typography variant="body2" className={classes.buttonText}>
                {getText('save')}
              </Typography>
            </Button>
            {schedule && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onDelete}
              >
                <Typography variant="body2" className={classes.buttonText}>
                  {getText('delete')}
                </Typography>
              </Button>
            )}

          </TableCell>
        </TableRow>
      </>
    )

  }
}

export default withStyles(styles)(ScheduleConfig);