import React from 'react';
import { connect } from 'react-redux';

import { caseActions } from 'src/redux/actions';
import CaseNote from './CaseNote';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    addNotes: (caseId, note) => dispatch(caseActions.updateCase({
      caseId: caseId,
      action: "add_note",
      note: note
    }))
  };
};

class CaseNoteContainer extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      note: ''
    }
  }

  componentDidUpdate(prevProps, prevState){
    if (prevProps.caseId !== this.props.caseId){
      this.setState({
        note: ''
      });
    }
  }

  handleSubmit = e => {
    const { note } = this.state;
    const { caseId } = this.props;
    if (note === ''){
      alert(getText('input_error_note'));
      return;
    }
    this.props.addNotes(caseId, note);
    this.setState({
      note: ''
    });
  }

  handleChange = e => {
    this.setState({
      note: e.target.value
    });
  }

  render() {
    return (
      <CaseNote
        note={this.state.note}
        notes={this.props.notes}
        submitting={false}
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
        />
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CaseNoteContainer);
