import { userConstants } from 'src/redux/constants';
import { userServices } from 'src/services';
import history from 'src/utils/history';
import { notificationActions } from '.';

export const signin = (username, password) => {
  const request = () => {
    return { type: userConstants.LOGIN_REQUEST };
  };

  const success = (result) => {
    return {
      type: userConstants.LOGIN_SUCCESS,
      result
    };
  };

  const failure = (error) => {
    return { type: userConstants.LOGIN_FAILURE, result: error };
  };

  return dispatch => {
    dispatch(request());
    return userServices.signin({ username, password })
      .then(result => {
        dispatch(success(result));
        switch(result.role){
          case 'admin':
            history.push('/adminmanage');
            break;
          case 'guard_admin':
            history.push('/guardmanage');
            break;
          default:
            history.push('/cases');
            return;
        }
      })
      .catch(error => {
        dispatch(failure(error.message));
        dispatch(notificationActions.addNotification(error.message));
      });
  };
};

const closeWS = () => {
  if (window.wsAPP){
    window.wsAPP.close();
  }
  if (window.wsWISP){
    window.wsWISP.close();
  }
}

export const signout = () => {
  const request = () => {
    return { type: userConstants.LOGOUT_REQUEST };
  };

  const success = () => {
    return { type: userConstants.LOGOUT_SUCCESS };
  };

  // const failure = () => {
  //   return { type: userConstants.LOGOUT_FAILURE };
  // };

  return dispatch => {
    dispatch(request());
    userServices.signout()
      .then(result => {
        dispatch(success());
        closeWS();
        history.push('/signin');
      })
      .catch(error => {
        // TODO: how to handle signout failure
        // dispatch(failure());
        dispatch(success());
        closeWS();
        history.push('/signin');
      });
  };
};

export const tokenExpired = () => {
  return {
    type: userConstants.TOKEN_EXPIRED
  }
}

export const refreshToken = () => {
  const request = () => {
    return {
      type: userConstants.REFRESH_TOKEN_REQUEST,
    };
  };
  
  const success = () => {
    return {
      type: userConstants.REFRESH_TOKEN_SUCCESS,
    };
  };

  const failure = () => {
    return {
      type: userConstants.REFRESH_TOKEN_FAILURE,
    };
  };

  const refresh = (dispatch, getState) => {
    dispatch(request());
    userServices.refreshToken()
      .then(() => {
        dispatch(success());
      })
      .catch(error => {
        dispatch(failure());
      });
  };

  return refresh;
};