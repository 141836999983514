import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import DropDownSelectorVertical from 'src/components/Selectors/DropDownSelectorVertical';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({});

const statuses = [
  { value: 'all', label: getText('all') },
  { value: 'new', label: getText('no_action') },
  { value: 'assigned', label: getText('officer_assigned') },
  { value: 'notified', label: getText('officer_notified') },
  { value: 'accepted', label: getText('officer_accepted') },
  { value: 'on_the_way', label: getText('officer_on_the_way') },
  { value: 'arrived', label: getText('officer_arrived') },
  { value: 'resolved', label: getText('resolved') },
  { value: 'closed', label: getText('closed') },
];

const StatusSelector = props => {
  const { value, onChange } = props;
  return (
    <DropDownSelectorVertical
      title={getText('status')}
      options={statuses}
      value={value}
      onChange={onChange} />
  );
};

StatusSelector.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

StatusSelector.defaultProps = {
  value: 'all',
  onChange: (event, value) => {}
};

export default withStyles(styles)(StatusSelector);
