import React, { useState } from 'react';
import {
  withStyles,
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
  FormLabel,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';

import { getText } from 'src/utils/MultilingualLoader';
import RegionCard from './RegionCard';
import InspectionList from '../../Inspections';

const styles = theme => ({
  container: {
    padding: theme.spacing(1)
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    width: 80,
  },
  inspection: {
    objectFix: 'contain',
    width: '50%'
  },
  footer: {
    marginTop: 22,
  },
  textField: {
    width: '400px'
  },
  deleteContainer: {
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    right: 0,
    top: 0,
    cursor: 'pointer',
  }
});

const AlgoReference = (props) => {
  const {
    classes,
    refs,
    multiple,
    target,
    handleAddRef,
    validate,
    regionConfig,
    handleDeleteRef,
    handleChangeRef,
    handleDeleteRegion,
    handleAddRegion,
    handleChangeSnapshot,
    handleConfirm,
    handleDeleteReference,
  } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [refIndex, setRefIndex] = useState(0);

  return (
    <Grid className={classes.container} container spacing={1} direction="column">
      <Card>
        <CardContent>
          <Grid className={classes.deleteContainer}>
            <DeleteIcon onClick={handleDeleteReference} className={classes.icon} style={{ top: -10 }} />
          </Grid>
          {refs.map((item, index) => (
            <Grid key={index} item xs>
              <Card>
                <CardContent>
                  <Grid className={classes.deleteContainer}>
                    <DeleteIcon onClick={handleDeleteRef(index)} className={classes.icon} />
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item container>
                      <FormLabel component="label" className={classes.label}>{getText('image')}</FormLabel>
                      {item.snapshot && <Grid item xs>
                        <img
                          className={classes.inspection}
                          alt="inspection"
                          src={item.snapshot}
                        />
                      </Grid>}
                      <Button color="primary" style={{ marginRight: 28 }} onClick={() => {
                        setRefIndex(index);
                        setShowDialog(true);
                      }}>{getText('select_image')}</Button>
                    </Grid>
                    <Grid container spacing={1} direction="column">
                      {
                        item.regions.map((region, i) => (
                          <Grid key={i} item xs>
                            <RegionCard
                              no={region.no}
                              validate={validate.refs[index].regions[i]}
                              options={region.options}
                              snapshot={item.snapshot}
                              points={region.points}
                              status={region.status}
                              error={region.error}
                              type={region.type}
                              regionConfig={regionConfig}
                              multiple={region.multiple}
                              handleChangeRef={handleChangeRef(index, i)}
                              handleDeleteRegion={handleDeleteRegion(index, i)}
                            />
                          </Grid>
                        ))
                      }
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Grid item container justify="flex-end">
                    {multiple.map((add, a) => (
                      <Button key={a} onClick={handleAddRegion(index, add.key)} color="primary">{`${getText('add_region')}(${add.type})`}</Button>
                    ))}
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          ))}
          <Grid className={classes.footer} container justify="flex-end">
            <Button color="primary" onClick={handleAddRef}>{getText('add_image')}</Button>
            <Button color="primary" onClick={handleConfirm}>
              {getText('ok')}
            </Button>
          </Grid>
        </CardContent>
      </Card>
      {showDialog && (
        <InspectionList
          targetId={target.id}
          onClose={() => setShowDialog(false)}
          onSelectSnapshot={(val) => {
            handleChangeSnapshot(refIndex, val);
          }}
        />
      )}
    </Grid>
  );
};

export default withStyles(styles)(AlgoReference);
