import React, { useMemo, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { getText } from "src/utils/MultilingualLoader";
import { routineAdapter } from "./adapter";
import Pagination from "src/components/Pagination";
import Routine from "./Routine";
import Modal from "src/components/Modal";
import { robotActions } from "src/redux/actions";

const styles = (theme) => ({
  table: {
    width: "100%",
  },
  cellNo: {
    maxWidth: 120,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  action: {
    textAlign: "center",
  },
});

const pageSize = 5;

const mapStateToProps = (state, props) => {
  const { robotMapLists } = state.robot;
  const { selected } = props;
  const robotMapList = selected && robotMapLists[selected.id];
  return { robotMapList };
};

const mapDispatchToProps = (dispatch) => ({
  updateRobotConfigs: (robotId, updConfigs) =>
    dispatch(robotActions.updateRobotConfigs(robotId, updConfigs)),
});

const RoutineList = ({
  classes,
  selected,
  configs,
  targets,
  robotMapList,
  updateRobotConfigs,
}) => {
  const [curPage, setCurPage] = useState(1);
  const [activeRoutine, setActiveRoutine] = useState(null);
  const [deleteRoutine, setDeleteRoutine] = useState(null);

  useEffect(() => {
    if (selected && selected.id) {
      setCurPage(1);
      setActiveRoutine(null);
    }
  }, [selected && selected.id]);

  const routines = useMemo(() => {
    if (!configs) {
      return [];
    }
    return routineAdapter(configs);
  }, [configs]);

  const filterRoutines = routines.slice(
    (curPage - 1) * pageSize,
    curPage * pageSize
  );

  function handleSubmit() {
    updateRobotConfigs(selected.id, {
      routines: ((configs && configs.routines) || []).filter(
        (routine) => routine.id !== deleteRoutine.id
      ),
      routine_schedules: ((configs && configs.routine_schedules) || []).filter(
        (item) => item.routine_id !== deleteRoutine.id
      ),
    });
    setDeleteRoutine(null);
  }

  return (
    <Grid container>
      <Grid container alignItems="center" justify="flex-end">
        <Button
          color="primary"
          variant="outlined"
          startIcon={!activeRoutine ? null : <ArrowBackIcon />}
          onClick={() => {
            setActiveRoutine(activeRoutine ? null : {});
          }}
        >
          {!activeRoutine
            ? getText("append_routine")
            : getText("back_to_routine_list")}
        </Button>
      </Grid>
      {!activeRoutine ? (
        <Grid container>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{getText("id")}</TableCell>
                <TableCell>{getText("name")}</TableCell>
                <TableCell>{getText("routine_schedule_cycle")}</TableCell>
                <TableCell>{getText("date")}</TableCell>
                <TableCell>{getText("time")}</TableCell>
                {/* <TableCell>{getText("created_at")}</TableCell> */}
                <TableCell className={classes.action}>
                  {getText("action")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterRoutines.map((routine) => (
                <TableRow key={routine.id}>
                  <TableCell>{routine.id}</TableCell>
                  <TableCell>
                    <div className={classes.cellNo} title={routine.name}>
                      {routine.name}
                    </div>
                  </TableCell>
                  <TableCell>{routine.schedule}</TableCell>
                  <TableCell>{routine.startDate}</TableCell>
                  <TableCell>{routine.startTime}</TableCell>
                  {/* <TableCell>{routine.createTime}</TableCell> */}
                  <TableCell className={classes.action}>
                    <Button
                      color="primary"
                      onClick={() => setActiveRoutine(routine)}
                    >
                      {getText("edit")}
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => setDeleteRoutine(routine)}
                    >
                      {getText("delete")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            counts={routines.length}
            pageSize={pageSize}
            curPage={curPage}
            onPageClick={(page) => {
              setCurPage(page);
            }}
          />
        </Grid>
      ) : (
        <Routine
          onCancel={() => setActiveRoutine(null)}
          robotMapList={robotMapList}
          routines={configs && configs.routines}
          schedules={configs && configs.routine_schedules}
          routine={activeRoutine}
          targets={targets}
          selected={selected}
        />
      )}
      {deleteRoutine && (
        <Modal
          title={getText("delete_routine") + "?"}
          onCancel={() => setDeleteRoutine(null)}
          onConfirm={handleSubmit}
        >
          {deleteRoutine.name}
        </Modal>
      )}
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RoutineList));
