import React from 'react';
import { connect } from 'react-redux';
import { manageActions } from 'src/redux/actions';
import { mapManageTypeToConstant } from 'src/utils/constantMapper';
import AddCompanyPanel from './AddCompanyPanel';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    createAccount: data => {
      dispatch(manageActions.createAccount(data));
    }
  };
};

class AddCompanyPanelContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      title: '',
      selectedType: mapManageTypeToConstant(props.match.path.substr(props.match.path.lastIndexOf('/') + 1)),
      error: {
        title: false
      }
    };
  }

  handleSubmit = event => {
    const { title, selectedType } = this.state;
    if (title === ''){
      this.setState({
        error: {
          title: true
        }
      });
      alert(getText('input_error_title'));
      return;
    }
    this.props.createAccount({
      selectedType,
      title
    });
  }

  handleChange = prop => event => {
    this.setState({
      ...this.state,
      [prop]: event.target.value,
      error: {
        [prop]: false
      }
    });
  }

  render() {
    return (
      <AddCompanyPanel
        title={this.state.title}
        error={this.state.error}
        submitting={false}
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
        />
    );
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCompanyPanelContainer);
