import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import DropDownSelectorVertical from 'src/components/Selectors/DropDownSelectorVertical';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({});

const sources = [
    { value: 'all',label: getText('all') },
    { value: 'camera',label: getText('camera') },
    { value: 'robot',label: getText('robot') }
];

const EventSourceSelector = props => {
    const { value, onChange } = props;
    return (
      <DropDownSelectorVertical
        title={getText('source')}
        options={sources}
        value={value}
        onChange={onChange} />
    );
};

EventSourceSelector.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

EventSourceSelector.defaultProps = {
    value: 'all',
    onChange: (event, value) => {}
};

export default withStyles(styles)(EventSourceSelector);
