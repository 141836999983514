import React from 'react';
import { connect } from 'react-redux';

import { referenceActions } from 'src/redux/actions';
import InspectionPagination from './InspectionPagination';

const mapStateToProps = state => {
  return {
    filters: state.reference.filters
  }; 
};

const mapDispatchToProps = dispatch => {
  return {
    getMoreInspections: (filters, page) => {
      dispatch(referenceActions.getMoreInspections(filters, page));
    },
  };
};

class InspectionPaginationContainer extends React.Component {
  handlePageClick = page => e => {
    const { filters, getMoreInspections, targetId, mapId, routeId } = this.props;
    if (this.props.curPage !== page) {
      getMoreInspections({ ...filters, targetId, mapId, routeId}, page);
    }
  }

  render() {
    const { curPage, count, filters } = this.props;
    if (count <= filters.limit) {
      return null;
    }
    const maxPage = Math.ceil(count / filters.limit);
    let pageOptions = [ curPage ];
    const left = Math.max(curPage - 2, 1)
    for (let i = curPage - 1; i >= left; i--)
      pageOptions.unshift(i);
    const right = Math.min(curPage + Math.max(5 - pageOptions.length, 0), maxPage);
    for (let i = curPage + 1; i <= right; i++)
      pageOptions.push(i);
    for (let i = left - 1; pageOptions.length < 5 && i > 0; i--)
      pageOptions.unshift(i);

    return (
      <InspectionPagination
        noPrev={curPage <= 1}
        noNext={curPage >= maxPage}
        curPage={curPage}
        pageOptions={pageOptions}
        onPageClick={this.handlePageClick}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionPaginationContainer);