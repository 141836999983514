import React from 'react';
import { connect } from 'react-redux';

import { targetActions } from 'src/redux/actions';
import TargetFilter from './TargetFilter';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  const { filters, targetOptions, categoryOptions, floorOptions } = state.target;
  return {
    filters,
    targetOptions,
    categoryOptions,
    floorOptions,
    curSite: state.site.curSite
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilter: filters => {
      dispatch(targetActions.setFilter(filters));
    },
    getStoreList: (filters) => {
      dispatch(targetActions.getStoreList(filters));
    },
    getTargetOptions: () => {
      dispatch(targetActions.getTargetOptions());
    },
    getFloorOptions: () => {
      dispatch(targetActions.getFloorOptions());
    },
    getCategoryOptions: () => {
      dispatch(targetActions.getCategoryOptions());
    }
  };
};

const algoConfigOptions = [
  {
    value: 'all',
    label: getText('all'),
  },
  {
    value: true,
    label: getText('completed'),
  },
  {
    value: false,
    label: getText('uncompleted'),
  }
];

const algoReferenceOptions = [
  {
    value: 'all',
    label: getText('all'),
  },
  {
    value: true,
    label: getText('completed'),
  },
  {
    value: false,
    label: getText('uncompleted'),
  }
];

class StoreFilterContainer extends React.Component {
  constructor(props) {
    super(props);
    const { filters } = props;
    const { floor, name, algo, category, algo_config, algo_reference } = filters;
    this.state = {
      floor,
      name,
      algo,
      category,
      algo_config,
      algo_reference,
    };
  }

  componentDidMount() {
    const { getTargetOptions, getFloorOptions, getCategoryOptions, curSite, targetOptions, floorOptions, categoryOptions } = this.props;
    if (curSite && !targetOptions[curSite]) {
      getTargetOptions();
    }
    if (curSite && !floorOptions[curSite]) {
      getFloorOptions();
    }
    if (curSite && !categoryOptions[curSite]) {
      getCategoryOptions();
    }
  }

  componentDidUpdate(prevProps) {
    const { curSite, getTargetOptions, getFloorOptions, getCategoryOptions, getStoreList, setFilter, targetOptions, floorOptions, categoryOptions } = this.props;

    if (curSite && (!prevProps.curSite || curSite !== prevProps.curSite)) {
      if (curSite && !targetOptions[curSite]) {
        getTargetOptions();
      }
      if (curSite && !floorOptions[curSite]) {
        getFloorOptions();
      }
      if (curSite && !categoryOptions[curSite]) {
        getCategoryOptions();
      }
      setFilter({
        floor: 'all',
        name: {},
        algo: 'all',
        category: 'all',
        algo_config: 'all',
        algo_reference: 'all',
      });
      this.setState({
        floor: 'all',
        name: {},
        algo: 'all',
        category: 'all',
        algo_config: 'all',
        algo_reference: 'all',
      });
      getStoreList({ limit: 10 });
    }
  }

  handleAutoComplete = (e, newValue) => {
    this.setState({
      name: newValue,
    })
  }

  handleChange = prop => (event) => {
    const newValue = event.target.value;
    this.setState({
      [prop]: newValue,
      name: (prop === 'category' && this.state.name && this.state.name.category !== newValue) ? '' : this.state.name,
    });
  }

  handleSubmit = event => {
    const { filters, setFilter, getStoreList } = this.props;
    const targetFilters = Object.assign({}, filters, { ...this.state });
    setFilter(targetFilters);
    let newFilters = {
      ...targetFilters,
      name: this.state.name && this.state.name.label ? this.state.name.label : ''
    };
    Object.keys(newFilters).forEach(key => {
      if (newFilters[key] === 'all' || (!newFilters[key] && newFilters[key] !== false)) {
        delete newFilters[key];
      }
    });
    getStoreList(newFilters);
  };

  render() {
    const { floor, algo, name, category, algo_config, algo_reference } = this.state;
    const { targetOptions, floorOptions, categoryOptions, curSite, showMutator } = this.props;

    return (
      <TargetFilter
        floor={floor}
        algo={algo}
        name={name}
        category={category}
        algo_config={algo_config}
        algo_reference={algo_reference}
        categoryOptions={categoryOptions[curSite] || []}
        floorOptions={floorOptions[curSite] || []}
        targetOptions={targetOptions[curSite] || []}
        algoConfigOptions={algoConfigOptions}
        algoReferenceOptions={algoReferenceOptions}
        showMutator={showMutator}
        handleChange={this.handleChange}
        handleAutoComplete={this.handleAutoComplete}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreFilterContainer);
