import { targetConstants } from 'src/redux/constants';
import { targetServices, robotServices } from 'src/services';
import { siteActions } from '.';
import { notificationActions } from './index';
import { getText } from 'src/utils/MultilingualLoader';

export const getStoreList = (filters) => {
	const request = () => {
		return {
			type: targetConstants.GET_TARGET_REQUEST,
		};
	};

	const success = (result) => {
		return {
			type: targetConstants.GET_TARGET_SUCCESS,
			list: result.list,
			count: result.count,
			curPage: 1,
		};
	};

	const failure = (result) => {
		return {
			type: targetConstants.GET_TARGET_FAILURE,
		};
	};

	return (dispatch, getState) => {
		dispatch(request());
		const offset = 0;
		const state = getState();
		targetServices.getStoreList({ ...filters, offset, site_id: state.site.curSite })
			.then(result => {
				dispatch(success(result));
			})
			.catch(error => {
				dispatch(failure());
			});
	};
};

export const getMoreStores = (filters, curPage) => {
	const request = () => {
		return {
			type: targetConstants.GET_TARGET_REQUEST,
		};
	};

	const success = (results) => {
		return {
			type: targetConstants.GET_TARGET_SUCCESS,
			list: results.list,
			count: results.count,
			curPage: curPage,
		};
	};

	const failure = () => {
		return {
			type: targetConstants.GET_TARGET_FAILURE
		};
	};

	return (dispatch, getState) => {
		const offset = (curPage - 1) * filters.limit;

		dispatch(request());
		const state = getState();
		targetServices.getStoreList({ ...filters, offset, site_id: state.site.curSite })
			.then(results => {
				dispatch(success(results));
			})
			.catch(error => {
				dispatch(failure());
			});
	};
};

export const setFilter = filters => {
	return {
		type: targetConstants.SET_FILTER,
		filters
	};
};

export const setPage = (robotId, page) => {
	return {
		type: targetConstants.SET_PAGE,
		robotId,
		page
	};
}

export const getAlgoOptions = () => {
	const request = () => {
		return {
			type: targetConstants.GET_OPTIONS_REQUEST,
		};
	};

	const success = (results, algoOptions, siteId) => {
		return {
			type: targetConstants.GET_OPTIONS_SUCCESS,
			payload: {
				algoOptions: {
					...algoOptions,
					[siteId]: results
				},
			}
		};
	};

	const failure = () => {
		return {
			type: targetConstants.GET_OPTIONS_FAILURE
		};
	};

	return (dispatch, getState) => {
		dispatch(request());
		const filters = siteActions.addSiteToFilter({}, getState());
		const state = getState();
		targetServices.getAlgoOptions(filters)
			.then(results => {
				dispatch(success(results, state.target.algoOptions, state.site.curSite));
			})
			.catch(error => {
				dispatch(failure());
			});
	};
};

export const getTargetOptions = (siteId) => {
	const request = () => {
		return {
			type: targetConstants.GET_OPTIONS_REQUEST,
		};
	};

	const success = (results, oldTargetOptions, siteId) => {
		return {
			type: targetConstants.GET_OPTIONS_SUCCESS,
			payload: {
				targetOptions: {
					...oldTargetOptions,
					[siteId]: results
				}
			}
		};
	};

	const failure = () => {
		return {
			type: targetConstants.GET_OPTIONS_FAILURE
		};
	};

	return (dispatch, getState) => {
		let filters = siteId ? { siteId } : siteActions.addSiteToFilter({}, getState());
		const state = getState();
		if (state.target.targetOptions[filters.siteId]) {
			return;
		}
		dispatch(request());
		targetServices.getTargetOptions(filters)
			.then(results => {
				dispatch(success(results, state.target.targetOptions, filters.siteId));
			})
			.catch(error => {
				dispatch(failure());
			});
	};
};

export const getCategoryOptions = () => {
	const request = () => {
		return {
			type: targetConstants.GET_OPTIONS_REQUEST,
		};
	};

	const success = (results, categoryOptions, siteId) => {
		return {
			type: targetConstants.GET_OPTIONS_SUCCESS,
			payload: {
				categoryOptions: {
					...categoryOptions,
					[siteId]: results
				}
			}
		};
	};

	const failure = () => {
		return {
			type: targetConstants.GET_OPTIONS_FAILURE
		};
	};

	return (dispatch, getState) => {
		let filters = siteActions.addSiteToFilter({}, getState());
		dispatch(request());
		const state = getState();
		targetServices.getCategoryOptions(filters)
			.then(results => {
				dispatch(success(results, state.target.categoryOptions, state.site.curSite));
			})
			.catch(error => {
				dispatch(failure());
			});
	};
};

export const getFloorOptions = () => {
	const request = () => {
		return {
			type: targetConstants.GET_OPTIONS_REQUEST,
		};
	};

	const success = (results, floorOptions, siteId) => {
		return {
			type: targetConstants.GET_OPTIONS_SUCCESS,
			payload: {
				floorOptions: {
					...floorOptions,
					[siteId]: results
				}
			}
		};
	};

	const failure = () => {
		return {
			type: targetConstants.GET_OPTIONS_FAILURE
		};
	};

	return (dispatch, getState) => {
		let filters = siteActions.addSiteToFilter({}, getState());
		dispatch(request());
		const state = getState();
		targetServices.getFloorOptions(filters)
			.then(results => {
				dispatch(success(results, state.target.floorOptions, state.site.curSite));
			})
			.catch(error => {
				dispatch(failure());
			});
	};
};

export const getRobots = (filters) => {
	const request = () => {
		return {
			type: targetConstants.GET_ROBOT_REQUEST
		}
	}

	const success = robots => {
		return {
			type: targetConstants.GET_ROBOT_SUCCESS,
			robots
		};
	};

	const failure = () => {
		return {
			type: targetConstants.GET_ROBOT_FAILURE
		}
	}

	return (dispatch, getState) => {
		dispatch(request());
		filters = siteActions.addSiteToFilter(filters ? filters : getState().robot.filters, getState());
		robotServices.getRobots(filters)
			.then(robots => {
				dispatch(success(robots));
			})
			.catch(error => {
				dispatch(failure());
			});
	};
};

export const getRobotConfigMapsAndRoutes = robot_ids => {
	const request = () => {
		return {
			type: targetConstants.GET_MAPS_REQUEST,
		};
	};

	const success = maps => {
		return {
			type: targetConstants.GET_MAPS_SUCCESS,
			maps
		};
	};

	const failure = () => {
		return {
			type: targetConstants.GET_MAPS_FAILURE,
		};
	};

	return dispatch => {
		if (!robot_ids || robot_ids.length === 0) {
			dispatch(success([]));
		}
		dispatch(request());
		Promise.all(robot_ids.map(robot_id => ([robotServices.getRobotMapsWithRoutes(robot_id), robotServices.getRobotMaps(robot_id)])).flat())
			.then((maps) => {
				// combine maps with routes and maps without route
				maps.forEach((map, index) => {
					if (index % 2 === 1) {
						maps[index].forEach(map => {
							if (!maps[index - 1].find(mapWithRoutes => mapWithRoutes.id === map.id)) {
								delete map.mapRoute;
								map.mapRoutes = [];
								maps[index - 1].push(map);
							}
						})
					}
				});
				const result = maps.filter((a, index) => index % 2 === 0).flat();
				const resultSet = new Set();
				const idSet = new Set();
				result.forEach(item => {
					if (!idSet.has(item.id)) {
						idSet.add(item.id);
						resultSet.add(item);
					}
				});
				dispatch(success(Array.from(resultSet)));
			})
			.catch(err => {
				dispatch(failure());
			});
	};
};

export const copyRoutes = (params) => {
	const request = () => {
		return {
			type: targetConstants.COPY_ROUTES_REQUEST,
		};
	};

	const success = maps => {
		return {
			type: targetConstants.COPY_ROUTES_SUCCESS,
			maps
		};
	};

	const failure = () => {
		return {
			type: targetConstants.COPY_ROUTES_FAILURE,
		};
	};

	return (dispatch) => {
		dispatch(request());
		targetServices.copyRoutes(params)
			.then(robots => {
				dispatch(success());
				dispatch(notificationActions.addNotification(`${getText('copy_route')}${getText('words_space')}${getText('succeeded')}`));
			})
			.catch(error => {
				dispatch(failure());
				dispatch(notificationActions.addNotification(`${getText('copy_route')}${getText('words_space')}${getText('failed')}`));
			});
	};
};

export const updateReferenceImage = (targetId, inspectionId) => {
	const success = (res, list) => {
		return {
			type: targetConstants.UPDATE_TARGET_LIST,
			list: list.map(target => target.id === res.id ? {
				...target,
				reference_image: res.reference_image,
			} : target),
		};
	};

	return (dispatch, getState) => {
		targetServices.updateReferenceImage(targetId, inspectionId)
			.then(res => {
				const state = getState();
				const list = state.target.list;
				dispatch(success(res.result, list));
				dispatch(notificationActions.addNotification(`${getText('action')}${getText('words_space')}${getText('succeeded')}`));
			})
			.catch(error => {
				dispatch(notificationActions.addNotification(`${getText('action')}${getText('words_space')}${getText('failed')}`));
			});
	};
};
