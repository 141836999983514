import React from 'react';
import { connect } from 'react-redux';

import Cameras from './Cameras';
import SiteMap from '../SiteMap';

const mapStateToProps = state => {
  return {
    curSite: state.site.curSite,
  };
};

const mapDispatchToProps = dispatch => {
  return { };
};

class CamerasContainer extends React.Component {
  render() {
    const { curSite } = this.props;
    return (
      <>
        {curSite ? <Cameras /> : <SiteMap />}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CamerasContainer);