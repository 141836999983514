import React from 'react';
import { connect } from 'react-redux';

import history from 'src/utils/history';
import { siteActions } from 'src/redux/actions';
import SiteManagerList from './SiteManagerList';
import LoadingText from 'src/components/LoadingText';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  return {
    managers: state.site.managers,
    managerPagination: state.site.managerPagination,
    loading: state.site.managerLoading,
    updating: state.site.managerUpdating
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getManagers: (curPage) => {
      dispatch(siteActions.getManagers(curPage));
    },
    deleteManager: id => {
      dispatch(siteActions.deleteManager(id));
    },
    updateManager: data => {
      dispatch(siteActions.updateManager(data));
    },
  };
};

class SiteManagerListContainer extends React.Component {

  componentDidMount(){
    if (!this.props.loading) this.props.getManagers(1);
  }

  onClickAdd = () => {
    history.push('/sitemanage/addmanager');
  }

  onClickDelete = id => event => {
    if (!this.props.updating && window.confirm(getText('confirm_delete_site_manager')))
      this.props.deleteManager(id);
  }

  render() {
    const { managers, managerPagination, loading } = this.props;
    return (
      !loading ?
        <SiteManagerList
          managers={managers}
          pagination={managerPagination}
          onClickAdd={this.onClickAdd}
          onClickDelete={this.onClickDelete}
          />
        : <LoadingText />
    );
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SiteManagerListContainer);
