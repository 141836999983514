import React from 'react';
import {makeStyles, useMediaQuery, Theme} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 'max(24px, calc(100% - 1457px)/2)',
    paddingRight: 'max(24px, calc(100% - 1457px)/2)',
    backgroundColor: '#FFF',
    // marginTop: 76,
    transition: 'margin-top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}));


const CommonBody = ({style = {}, children, id}) => {
  const classes = useStyles();

  return (
    <div id={id} className={classes.root} style={{...style, marginLeft : 0}}>
      {children}
    </div>
  );
};

export default CommonBody;
