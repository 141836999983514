import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

import { getText } from 'src/utils/MultilingualLoader';
import { referenceActions, notificationActions } from 'src/redux/actions';
import InspectionList from './InspectionList';

const mapStateToProps = state => {
  return {
    list: state.reference.list,
    pagination: state.reference.pagination,
    filters: state.reference.filters,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getInspections: filters => {
      dispatch(referenceActions.getInspections(filters))
    },
    addNotification: msg => {
      dispatch(notificationActions.addNotification(msg));
    }
  };
};

class InspectionListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewStatus: 'all',
      statusOptions: [
        {
          value: 'all',
          label: 'all'
        },
        {
          value: 'ready',
          label: 'ready'
        },
        {
          value: 'finished',
          label: 'finished'
        },
        {
          value: 'failed',
          label: 'failed'
        }
      ]
    };
  }

  componentDidMount() {
    const {targetId, getInspections} = this.props;
    getInspections({
      targetId: targetId,
      limit: 8,
    });
  }

  render() {
    const {pagination, list, targetId, onSelectSnapshot, onClose} = this.props;

    return (
      <>
        {
          list && list.length !== 0 ?
            <InspectionList
              targetId={targetId}
              inspectionList={list}
              statusOptions={this.state.statusOptions}
              pagination={pagination}
              onSelectSnapshot={onSelectSnapshot}
              onClose={onClose}
            />
            : <Typography variant='body1' style={{padding: 16}}>{getText('no_data')}</Typography>
        }
      </>
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InspectionListContainer);