import React from 'react';
import { withStyles, Grid, Card, GridList, GridListTile, Typography, Dialog, DialogTitle, DialogContent, Zoom } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    height: '100%'
  },
  mediaList: {
    margin: '0 !important',
    padding: '0 !important',
    flexWrap: 'nowrap',
    width: '100%',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  listTile: {
    margin: '0 !important',
    padding: '0 !important',
  },
  banner: {
    zIndex: 1,
    backgroundColor: '#FFFFFF'
  },
  title: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  diaContent: {
    textAlign: 'center'
  },
  zoomedImg: {
    margin: 'auto',
    height: '90%',
    overflow: 'auto',
  },
});

class EventSnapshot extends React.Component {
  constructor(props) {
    super(props);
    const height = (window.innerWidth - 200 - 350 - 16 - 24) / 2 * 9 / 16;
    this.state = {
      isOpen: false,
      imgSrc: null,
      mediaHeight: window.innerWidth < 1280 ? height * 2 : height
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeVideo);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeVideo);
  }

  resizeVideo = () => {
    const height = (window.innerWidth - 200 - 350 - 16 - 24) / 2 * 9 / 16;
    this.setState({
      mediaHeight: window.innerWidth < 1280 ? height * 2 : height
    });
  }

  render() {
    const { classes, selected, snapshots } = this.props;
    const { mediaHeight, isOpen, imgSrc } = this.state;
    const title = `${getText('case')}: #${selected.id}`;
    const media = snapshots || [];
    
    return (
      <Card
        square={true}
        className={classes.root}
      >
        <Grid
          direction="column"
          container={true}
          justify="flex-start"
          spacing={0}
        >
          <Grid item={true}>
            <GridList cols={1} cellHeight={mediaHeight} className={classes.mediaList}>
              {
                media.map(medium => (
                  <GridListTile key={`${medium.id}_snapshot`} className={classes.listTile}>
                    <img width='100%' height='100%' src={medium.url}
                      alt={getText('snapshot')}
                      onClick={() => {
                        this.setState({
                          isOpen: true,
                          imgSrc: medium.url
                        });
                      }}
                    />
                  </GridListTile>
                ))
              }
            </GridList>
          </Grid>
          <Grid item={true} className={classes.banner}>
            <Typography variant="body1" className={classes.title}>
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Dialog
          TransitionComponent={Zoom}
          fullScreen
          open={isOpen}
          onClose={() => {
            this.setState({
              isOpen: false,
              imgSrc: null
            });
          }}
        >
          <DialogTitle>
            <CloseIcon
              className={classes.closeButton}
              onClick={() => {
                this.setState({
                  isOpen: false,
                  imgSrc: null
                });
              }}
            />
          </DialogTitle>
          <DialogContent className={classes.diaContent}>
            <Typography variant="body1" className={classes.title}>{title}</Typography>
            <img className={classes.zoomedImg} src={imgSrc} alt={getText('snapshot')} />
          </DialogContent>
        </Dialog>
      </Card >
    )
  }
}

export default withStyles(styles)(EventSnapshot);