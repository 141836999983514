import store from 'src/redux/store';
import * as services from 'src/services/Vision/camera';
import {
  SET_FILTER,
  CLEAR_FILTER,
  SET_CAMERA_LOADING,
  SET_CAMERA_LIST,
  UPDATE_CAMERA_LIST,
  CLEAR_CAMERA_LIST,
  SET_MIN_CAMERAS_MAPPING,
} from 'src/redux/types/Vision/camera';


export async function getCameraOptions(
  siteIds,
  forceRefresh = false
){
  const {dispatch, getState} = store;
  const {minCamerasMapping} = getState().cameravision;
  const {role} = getState().user;

  const filteredSiteIds = siteIds.filter(v => forceRefresh || !minCamerasMapping[v]);
  const promises = [];
  if (role === 'monitoring_operator' ) {
    filteredSiteIds.forEach((siteId) => {
      promises.push(services.operatorGetMinCameras({site_id: siteId}));
    })
  }else{
    filteredSiteIds.forEach((siteId) => {
      promises.push(services.getMinCameras({site_id: siteId}));
    })
  };

  try {
    const res = await Promise.all(promises);
    const mapping = filteredSiteIds.reduce((data, siteId, index) => {
      data[siteId] = res[index];
      return data;
    }, {});
    dispatch({
      type: SET_MIN_CAMERAS_MAPPING,
      payload: {
        minCamerasMapping: mapping,
      },
    });
  } catch (error) {
    console.log(error);
    return;
  }
}