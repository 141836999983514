import { sensorConstants } from "src/redux/constants";
import { sensorServices } from 'src/services';
import { notificationActions } from '.';
import { getText } from 'src/utils/MultilingualLoader';

export const getSensorClusters = filters => {
	const request = () => {
		return {
			type: sensorConstants.GET_SENSOR_CLUSTERS_REQUEST,
			robotId: filters.robotId
		};
	};

	const success = results => {
		return {
			type: sensorConstants.GET_SENSOR_CLUSTERS_SUCCESS,
			robotId: filters.robotId,
			clusters: results.clusters
		};
	};

	const failure = () => {
		return {
			type: sensorConstants.GET_SENSOR_CLUSTERS_FAILURE,
			robotId: filters.robotId
		};
	};

	const required = ['mapId', 'robotId', 'startTime', 'endTime'];
	const fieldName = ['map_id', 'robot_id', 'start_time', 'end_time'];

	return (dispatch) => {
		for (let idx in required) {
			if (!filters[required[idx]]) {
				dispatch(notificationActions.addNotification(`${getText('require')}${getText('words_space')}${getText(fieldName[idx])}`));
				return;
			}
		}
		dispatch(request());
		sensorServices.getSensorClusters(filters)
			.then(results => {
				dispatch(success(results));
			})
			.catch(error => {
				dispatch(failure());
				dispatch(notificationActions.addNotification(`${getText('get_sensor_clusters')}${getText('words_space')}${getText('failed')}`));
			});
	};
}

export const getSensorPoints = filters => {
	const request = () => {
		return {
			type: sensorConstants.GET_SENSOR_POINTS_REQUEST,
			robotId: filters.robotId
		};
	};

	const success = results => {
		return {
			type: sensorConstants.GET_SENSOR_POINTS_SUCCESS,
			robotId: filters.robotId,
			points: results
		};
	};

	const failure = () => {
		return {
			type: sensorConstants.GET_SENSOR_POINTS_FAILURE,
			robotId: filters.robotId
		};
	};

	const required = ['mapId', 'robotId', 'startTime', 'endTime'];
	const fieldName = ['map_id', 'robot_id', 'start_time', 'end_time'];

	return (dispatch) => {
		for (let idx in required) {
			if (!filters[required[idx]]) {
				dispatch(notificationActions.addNotification(`${getText('require')}${getText('words_space')}${getText(fieldName[idx])}`));
				return;
			}
		}
		dispatch(request());
		sensorServices.getSensorPoints(filters)
			.then(results => {
				dispatch(success(results));
			})
			.catch(error => {
				dispatch(failure());
				dispatch(notificationActions.addNotification(`${getText('get_sensor_points')}${getText('words_space')}${getText('failed')}`));
			});
	};
}

export const setFilter = filters => {
	return {
		type: sensorConstants.SET_FILTER,
		filters
	};
};

export const setSensorType = type => {
	return {
		type: sensorConstants.SET_SENSOR_TYPE,
		sensorType: type
	};
}

export const setShowType = type => {
	return {
		type: sensorConstants.SET_SHOW_TYPE,
		showType: type
	};
}
