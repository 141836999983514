import { api } from './api';
import { GeneralAdapter } from './adapter';

const getCompaniesAdapter = {
  toServer: data => {
    return data
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    let { results } = result;
    results = results ? results : [];
    results = results.map(_company => {
      const { id, title } = _company;
      return {
        id,
        title
      };
    });
    return { results };
  }
};

export const getCompanies = (adapter => async (data = {}) => {
  try {
    const url = '/guard_company/guard_companies?limit=100';
    data = adapter.toServer(data);
    let response = await api.get(url, data);
    response = adapter.toClient(response.data);
    const { error, results } = response;
    if (error) throw error;
    return { list: results };
  } catch(e) {
    throw e;
  }
})(getCompaniesAdapter);

const getGuardAdminsAdapter = {
  toServer: data => {
    return data
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    let { results } = result;
    results = results ? results : [];
    results = results.map(_guard_admin => {
      return {
        id: _guard_admin.user.id,
        role: _guard_admin.user.role,
        username: _guard_admin.user.username,
        last_name: _guard_admin.user.last_name,
        first_name: _guard_admin.user.first_name,
        lang: _guard_admin.user.lang,
        activated: _guard_admin.user.activated,
        timezone: _guard_admin.user.timezone,
        organizationId: _guard_admin.organization.id,
        organizationTitle: _guard_admin.organization.title,
        phone_number: _guard_admin.phone_number
      };
    });
    return { results };
  }
};

export const getGuardAdmins = (adapter => async (data = {}) => {
  try {
    const url = '/guard_admin/guard_admins?limit=100';
    data = adapter.toServer(data);
    let response = await api.get(url, data);
    response = adapter.toClient(response.data);
    const { error, results } = response;
    if (error) throw error;
    return { list: results };
  } catch(e) {
    throw e;
  }
})(getGuardAdminsAdapter);

const getCustomersAdapter = {
  toServer: data => {
    return data
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    let { results, next } = result;
    results = results ? results : [];
    results = results.map(_customer => {
      return {
        id: _customer.id,
        username: _customer.user.username,
        label: _customer.status.label,
        balance: _customer.balance,
        enable_monitoring_service: _customer.enable_monitoring_service,
        guard_company_id: _customer.guard_company_id
      };
    });
    return { results, next };
  }
};

export const getCustomers = (adapter => async (data = {}) => {
  try {
    const url = '/customer/customers?limit=20';
    data = adapter.toServer(data);
    let response = await api.get(url, data);
    response = adapter.toClient(response.data);
    const { error, results, next } = response;
    if (error) throw error;
    return { list: results, next };
  } catch(e) {
    throw e;
  }
})(getCustomersAdapter);

const getGuardsAdapter = {
  toServer: data => {
    return data
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    let { results } = result;
    results = results ? results : [];
    results = results.map(_guard => {
      return {
        id: _guard.user.id,
        role: _guard.user.role,
        username: _guard.user.username,
        last_name: _guard.user.last_name,
        first_name: _guard.user.first_name,
        lang: _guard.user.lang,
        activated: _guard.user.activated,
        timezone: _guard.user.timezone,
        organization: _guard.organization,
        phone_number: _guard.phone_number
      };
    });
    return { results };
  }
};

export const getGuards = (adapter => async (data = {}) => {
  try {
    const url = '/guard/guards?limit=100';
    data = adapter.toServer(data);
    let response = await api.get(url, data);
    response = adapter.toClient(response.data);
    const { error, results } = response;
    if (error) throw error;
    return { list: results };
  } catch(e) {
    throw e;
  }
})(getGuardsAdapter);

const getOperatorsAdapter = {
  toServer: data => {
    return data
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    let { results } = result;
    results = results ? results : [];
    results = results.map(_operator => {
      return {
        id: _operator.user.id,
        role: _operator.user.role,
        username: _operator.user.username,
        last_name: _operator.user.last_name,
        first_name: _operator.user.first_name,
        lang: _operator.user.lang,
        activated: _operator.user.activated,
        timezone: _operator.user.timezone,
        organization: _operator.organization,
        phone_number: _operator.phone_number
      };
    });
    return { results };
  }
};

export const getOperators = (adapter => async (data = {}) => {
  try {
    const url = '/monitoring_operator/monitoring_operators?limit=100';
    data = adapter.toServer(data);
    let response = await api.get(url, data);
    response = adapter.toClient(response.data);
    const { error, results } = response;
    if (error) throw error;
    return { list: results };
  } catch(e) {
    throw e;
  }
})(getOperatorsAdapter);

const getGuardAdminProfileAdapter = {
  toServer: data => {
    return data
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result =
      {
        id: result.user.id,
        role: result.user.role,
        username: result.user.username,
        last_ame: result.user.last_name,
        first_name: result.user.first_name,
        lang: result.user.lang,
        activated: result.user.activated,
        timezone: result.user.timezone,
        organization: result.organization,
        phone_number: result.phone_number
      };
    return { result };
  }
};

export const getGuardAdminProfile = (adapter => async uid => {
  try {
    const url = `/guard_admin/guard_admins/${uid}`;
    let response = await api.get(url);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch(e) {
    throw e;
  }
})(getGuardAdminProfileAdapter);

export const getMoreCustomers = (adapter => async (nextUrl, data = {}) => {
  try {
    const url = '/customer' + nextUrl.slice(nextUrl.lastIndexOf('/'));
    data = adapter.toServer(data);
    
    let response = await api.get(url, data);
    response = adapter.toClient(response.data);
    const { error, results, next } = response;
    if (error) throw error;
    return { list: results, next };
  } catch(e) {
    throw e;
  }
})(getCustomersAdapter);  

const addAccount = adapter => async (data = {}) => {
  try {
    const url = data.url;
    data = adapter.toServer(data);
    let response = await api.post(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch(e) {
    throw e;
  }
};

const addCompanyAdapter = {
  toServer: data => {
    return {
      title: data.title
    }
  },
  toClient: response => {
    const { status, statusText } = response;
    if ( status === 201 && statusText === 'Created'){
      let result = statusText;
      return { result };
    }
    let error = 'error';
    return { error };
  }
};

//add company has different response format
export const addCompany = (adapter => async (data = {}) => {
  try {
    const url = '/guard_company/guard_companies';
    data = adapter.toServer(data);
    let response = await api.post(url, data);
    response = adapter.toClient(response);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch(e) {
    throw e;
  }
})(addCompanyAdapter);

const addGuardAdminAdapter = {
  toServer: data => {
    return {
      user: {
        username: data.username,
        last_name: data.last_name,
        first_name: data.first_name
      },
      organization: data.organization,
      phone_number: data.phone_number
    }
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    return { result };
  }
};

export const addGuardAdmin = data => {
  data.url = '/guard_admin/guard_admins';
  return addAccount(addGuardAdminAdapter)(data);
};

const addGuardAdapter = {
  toServer: data => {
    return {
      user: {
        username: data.username,
        last_name: data.last_name,
        first_name: data.first_name
      },
      organization: data.organization,
      phone_number: data.phone_number
    }
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    return { result };
  }
};

export const addGuard = data => {
  data.url = '/guard/guards';
  return addAccount(addGuardAdapter)(data);
};

const addOperatorAdapter = {
  toServer: data => {
    return {
      user: {
        username: data.username,
        last_name: data.last_name,
        first_name: data.first_name
      },
      organization: data.organization,
      phone_number: data.phone_number
    }
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    return { result };
  }
};

export const addOperator = data => {
  data.url = '/monitoring_operator/monitoring_operators';
  return addAccount(addOperatorAdapter)(data);
};

const generalToClientAdapter = data => {
  data = GeneralAdapter.toClient(data);
  let { error, result } = data;
  if (error) return { error };
  return { result };
}

const updateAccount = adapter => async (data = {}) => {
  try {
    let targetUrl = `${data.url}${data.id}`;
    data = adapter.toServer(data);
    let response = await api.put(targetUrl, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch(e) {
    throw e;
  }
};

const updateCustomerAdapter = {
  toServer: data => {
    return {
      id: data.id,
      enable_monitoring_service: data.enable_monitoring_service,
      guard_company_id: data.guard_company_id
    };
  },
  toClient: data => {
    return generalToClientAdapter(data);
  }
};

export const updateCustomer = data => {
  data.url = '/customer/customers/';
  return updateAccount(updateCustomerAdapter)(data);
};

const updateCompanyAdapter = {
  toServer: data => {
    return {
      id: data.id,
      title: data.title
    };
  },
  toClient: data => {
    return generalToClientAdapter(data);
  }
};

export const updateCompany = data => {
  data.url = '/guard_company/guard_companies/';
  return updateAccount(updateCompanyAdapter)(data);
};

const deleteAccount = adapter => async (data = {}) => {
  try {
    let targetUrl = `${data.url}${data.id}`;
    let response = await api.delete(targetUrl);
    response = adapter(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch(e) {
    throw e;
  }
};

export const deleteCompany = data => {
  data.url = '/guard_company/guard_companies/';
  return deleteAccount(generalToClientAdapter)(data);
};

export const deleteGuardAdmin = data => {
  data.url = '/guard_admin/guard_admins/'
  return deleteAccount(generalToClientAdapter)(data);
};

export const deleteOperator = data => {
  data.url = '/monitoring_operator/monitoring_operators/'
  return deleteAccount(generalToClientAdapter)(data);
};

const addCustomerAdapter = {
  toServer: data => {
    const dataToServer = {
      first_name: data.firstName,
      last_name: data.lastName,
      username: data.username,
      password: data.password,
      lang: data.lang,
      timezone: data.timezone,
    }
    if (data.referral) {
      dataToServer.referral = data.referral;
    }
    return dataToServer
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    return { result };
  }
};

export const addCustomer = (adapter => async (data = {}) => {
  try {
    const url = '/user/auth/register';
    data = adapter.toServer(data);
    let response = await api.post(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return { result };
  } catch(e) {
    throw e;
  }
})(addCustomerAdapter);  