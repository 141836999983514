import React from 'react';
import {connect} from 'react-redux';
import uuid from 'uuid';

import MicroControlDialog from './MicroControlDialog';

const mapStateToProps = state => ({
  robot: state.robot.selected
});

class MicroControlDialogContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coolDown: false,
      nippleData: null
    };
  }

  componentDidUpdate(prevProps){
    const { open, mode, onStartMicroControl } = this.props;
    if (!prevProps.open && open && mode !== 'mapping') onStartMicroControl();
  }

  setNippleData = (evt, nippleData) => {
    this.setState({ nippleData });
  };

  getMoveData = (distance, angle) => {
    let angularSpeed = -Math.cos(angle) * distance / 120;
    //max speed: 1.5
    let speed = distance * Math.sin(angle) / 60;
    return { distance: speed, angle: angularSpeed };
  }

  microMove = () => {
    if (this.state.nippleData && !this.state.coolDown && window.wsAPP) {
      const { robot } = this.props;
      const { distance, angle } = this.getMoveData(this.state.nippleData.distance, this.state.nippleData.angle.radian);

      window.wsAPP.sendMsg({
        id: uuid(),
        act: 'robot.micro_move',
        arg: {
          robot_id: robot && robot.id,
          distance,
          angle
        }
      });

      this.setState({
        coolDown: true,
        nippleData: null
      });

      const that = this;
      setTimeout(() => {
        that.setState({
          coolDown: false
        });
      }, 1000);
    }
  };

  onClose = () => {
    this.props.onStopAction();
    this.props.onClose();
  }

  render() {
    const { open, mode } = this.props;
    const nippleOptions = {
      mode: 'static',
      color: 'green',
      size: 120,
      position: { top: "50%", left: "50%" }
    };
    return (
      <MicroControlDialog
        open={open}
        onClose={this.onClose}
        microMove={this.microMove}
        setNippleData={this.setNippleData}
        nippleOptions={nippleOptions}
        mode={mode}
      />
    );
  }
}

export default connect(mapStateToProps)(MicroControlDialogContainer);