import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import { webRTCWhiteList, webRTCBlackList } from "src/utils/webRTCWhiteList";
import WebRTCContainer from "src/pages/Main/Player/WebRTCPlayer/WebRTCContainer";
import CameraPlayerContainer from "./CameraPlayerContainer";

const styles = (theme) => ({});

const CameraPanel = (props) => {
  const { selectedCameras, lineSize, cameraHeight } = props;
  const [downGrade, setDownGrade] = useState({});

  return (
    <Grid
      container={true}
      justify="flex-start"
      direction="row"
      alignItems="stretch"
      spacing={3}
    >
      {selectedCameras.map((camera, index) => (
        <Grid item key={camera.id} xs={12 / lineSize}>
          {camera.type === "robot" &&
          !downGrade[camera.id] &&
          webRTCWhiteList &&
          (process.env.REACT_APP_DOMAIN === ".turingvideo.cn" ||
            webRTCWhiteList.length === 0 ||
            webRTCWhiteList.includes(camera.id)) &&
          webRTCBlackList &&
          !webRTCBlackList.includes(camera.id) ? (
            <WebRTCContainer
              camera={camera}
              cameraHeight={cameraHeight}
              downGrade={() =>
                setDownGrade({
                  ...downGrade,
                  [camera.id]: true,
                })
              }
            />
          ) : (
            <CameraPlayerContainer
              camera={camera}
              cameraHeight={cameraHeight}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

CameraPanel.propTypes = {
  selectedCameras: PropTypes.array.isRequired,
  lineSize: PropTypes.number.isRequired,
};

CameraPanel.defaultProps = {
  selectedCameras: [],
  lineSize: 2,
};

export default withStyles(styles)(CameraPanel);
