import React from "react";
import { connect } from "react-redux";
import uuid from "uuid/v4";

import { notificationActions } from "src/redux/actions";
import { robotServices } from "src/services";
import Actions from "./Actions";
import { getText } from "src/utils/MultilingualLoader";

const mapStateToProps = (state) => {
  const { selected } = state.robot;
  const robotConfig =
    (selected && state.robot.robotConfigs[selected.id]) || null;
  return { selected, robotConfig };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notify: (msg_text) => {
      dispatch(notificationActions.addNotification(msg_text));
    },
  };
};

class RobotActionContainer extends React.Component {
  state = {
    loading: false,
    notifyMsg: null,
  };

  sendWSMessage = (message, options) => {
    if (window.wsAPP) window.wsAPP.sendMsg(message, options);
  };

  handlePlaySound = () => {
    const { notify, selected } = this.props;
    notify(
      `${selected && selected.name}${getText("words_space")}${getText(
        "play_alarm"
      )}`
    );
    this.sendWSMessage({
      id: uuid(),
      act: "robot.play_sound",
      arg: {
        robot_id: selected.id,
      },
    });
  };

  handleRobotScan = () => {
    const { notify, selected } = this.props;
    notify(
      `${selected && selected.name}${getText("words_space")}${getText(
        "scan_surroundings"
      )}`
    );
    this.sendWSMessage({
      id: uuid(),
      act: "robot.directive.investigate",
      arg: {
        robot_id: selected.id,
      },
    });
  };

  handleSwitchMode = (target_mode) => () => {
    const { notify, selected } = this.props;
    notify(
      `${selected && selected.name}${getText("words_space")}${getText(
        "switching_to"
      )} "${target_mode}" ${getText("mode")}`
    );
    this.sendWSMessage(
      {
        id: uuid(),
        act: "robot.switch_mode",
        arg: {
          robot_id: selected.id,
          mode: target_mode,
        },
      },
      {
        hasRes: true,
        robotId: selected.id,
        targetMode: target_mode,
      }
    );
  };

  handleGoHome = () => {
    const { notify, selected } = this.props;
    notify(
      `${selected && selected.name}${getText("words_space")}${getText(
        "go_home_description"
      )}`
    );
    this.sendWSMessage({
      id: uuid(),
      act: "robot.go_home",
      arg: {
        robot_id: selected.id,
      },
    });
  };

  handleLeaveHome = () => {
    const { notify, selected } = this.props;
    notify(
      `${selected && selected.name}${getText("words_space")}${getText(
        "leave_home_description"
      )}`
    );
    this.sendWSMessage({
      id: uuid(),
      act: "robot.leave_home",
      arg: {
        robot_id: selected.id,
      },
    });
  };

  handleTakeElevator = (from, to) => {
    const { notify, selected } = this.props;
    notify(
      `${selected && selected.name}${getText("words_space")}${getText(
        "take_elevator_description"
      )}`
    );
    this.sendWSMessage({
      id: uuid(),
      act: "robot.take_elevator",
      arg: {
        robot_id: selected.id,
        lift_from: from,
        lift_to: to,
      },
    });
  };

  handleElevatorCommand = (commandType) => () => {
    const { notify, selected } = this.props;
    const data = {
      robot_id: selected && selected.id,
      command_type: commandType,
    };
    robotServices
      .sendLiftCommand(data)
      .then(() => {
        notify(
          `${selected && selected.name}${getText("words_space")}${getText(
            commandType
          )}${getText("words_space")}${getText("succeeded")}`
        );
      })
      .catch(() => {
        notify(
          `${selected && selected.name}${getText("words_space")}${getText(
            commandType
          )}${getText("words_space")}${getText("failed")}`
        );
      });
  };

  handleResumeRoutine = () => {
    const { notify, selected } = this.props;
    notify(
      `${selected && selected.name}${getText("words_space")}${getText(
        "resume_routine_description"
      )}`
    );
    this.sendWSMessage({
      id: uuid(),
      act: "robot.resume_routine",
      arg: {
        robot_id: selected.id,
      },
    });
  };

  handleNotify = (msg) => {
    this.setState({
      notifyMsg: msg,
    });
    setTimeout(() => {
      this.setState({
        notifyMsg: null,
      });
    }, 5000);
  };

  handleNimbo = (act) => {
    const { selected } = this.props;
    this.setState({
      loading: true,
    });
    this.sendWSMessage(
      {
        id: uuid(),
        act,
        arg: {
          robot_id: selected.id,
          service: "nimbo",
        },
      },
      {
        hasRes: true,
        timeout: 15000,
        callback: (res, err) => {
          if (!err) {
            if (res.err.ec === 0) {
              this.handleNotify(getText("operate_success"));
            } else {
              this.handleNotify(res.err.em);
            }
          } else {
            this.handleNotify(err.message);
          }
          this.setState({
            loading: false,
          });
        },
      }
    );
  };

  render() {
    const { selected, robotConfig, setPanelContent } = this.props;
    const { loading, notifyMsg } = this.state;
    const mode =
      selected && selected.state_data ? selected.state_data.mode : null;
    if (!selected) return null;
    const disableElevatorCommand = !robotConfig || !robotConfig.lift_trip_id;
    const disableTakeElevator = !robotConfig || !robotConfig.current_route_id;

    return (
      <Actions
        setPanelContent={setPanelContent}
        selected={selected}
        mode={mode}
        disableElevatorCommand={disableElevatorCommand}
        disableTakeElevator={disableTakeElevator}
        loading={loading}
        notifyMsg={notifyMsg}
        onPlaySound={this.handlePlaySound}
        onRobotScan={this.handleRobotScan}
        onStartPatrol={this.handleSwitchMode("patrol")}
        onStartMicroControl={this.handleSwitchMode("mapping")}
        onStopAction={this.handleSwitchMode("idle")}
        onGoHome={this.handleGoHome}
        onLeaveHome={this.handleLeaveHome}
        onTakeElevator={this.handleTakeElevator}
        onElevatorCommand={this.handleElevatorCommand}
        onResumeRoutine={this.handleResumeRoutine}
        onHandleNimbo={this.handleNimbo}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RobotActionContainer);
