export const LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';

export const TOKEN_EXPIRED = 'USER_TOKEN_EXPIRED';

export const REFRESH_TOKEN_REQUEST = 'USER_REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'USER_REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'USER_REFRESH_TOKEN_FAILURE';
