import moment from 'moment';

import { GeneralAdapter } from './adapter';
import { auth } from './auth';

// Login
const signinAdapter = {
  toServer: data => {
    const { username, password } = data;
    return { username, password };
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = {
      token: result.token,
      expire: moment(result.expire)
    };
    return { error, result };
  }
};

const parseJwt = token => {
  let userInfo = {};
  let base64Url = token.split('.')[1];
  let base64 = decodeURIComponent(atob(base64Url).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  try {
    userInfo = JSON.parse(base64);
  } catch(e) {
    return {};
  }
  return {
    role: userInfo.role,
    userId: userInfo.user_id,
    username: userInfo.username,
  };
};

export const signin = (adapter => async data => {
  try {
    const authUrl = "/auth/";
    let authResponse = await auth.post(authUrl, data);
    authResponse = adapter.toClient(authResponse.data);
    if (authResponse.error) throw authResponse.error;
    const userInfo = parseJwt(authResponse.result.token);
    userInfo.expire = authResponse.result.expire;
    window.localStorage && window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
    let result = { role: userInfo.role };
    return result;
  } catch (e) {
    throw e;
  }
})(signinAdapter);

// Logout
const signoutAdapter = {
  toServer: data => {
    return data;
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    return { error, result };
  }
};

export const signout = (adapter => async () => {
  try {
    const url = '/auth/logout';
    let response = await auth.post(url);
    response = adapter.toClient(response.data)
    const { error, result } = response;
    if (error) throw error;
    window.localStorage.removeItem('userInfo');
    return result;
  } catch (e) {
    window.localStorage.removeItem('userInfo');
    throw e;
  }
})(signoutAdapter);

export const refreshToken = (adapter => async data => {
  try {
    const authUrl = "/auth/refresh";
    let authResponse = await auth.post(authUrl);
    authResponse = adapter.toClient(authResponse.data);
    if (authResponse.error) throw authResponse.error;
    const userInfo = parseJwt(authResponse.result.token);
    userInfo.expire = authResponse.result.expire;
    window.localStorage && window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
    return;
  } catch (e) {
    throw e;
  }
})(signinAdapter);