import React from 'react';

import { getText, getDefaultMapCenter } from 'src/utils/MultilingualLoader';
import DrawSiteMap from './DrawSiteMap';

class SiteMapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: null,
      curAction: null,
      polygons: props.polygons || [],
      newPolygon: {
        name: '',
        geo_points: []
      }
    };
  }

  //If google API is not available
  componentDidCatch(error, info) {} 

  handleGoogleMapClick = event => {
    const { curAction } = this.state;
    switch (curAction) {
      case 'add':
        let point = event.latLng;
        if (point) {
          point = point.toJSON();
          this.setState((state, props) => {
            let newPolygon = {
              name: state.newPolygon.name,
              geo_points: [...state.newPolygon.geo_points, point]
            };
            return {
              newPolygon
            };
          });
        }
        break;
      default:
        break;
    }
  }

  handleGaodeMapClick = event => {
    const { curAction } = this.state;
    switch (curAction) {
      case 'add':
        let point = event.lnglat;
        if (point) {
          this.setState((state, props) => {
            let newPolygon = {
              name: state.newPolygon.name,
              geo_points: [...state.newPolygon.geo_points, {lat: point.lat, lng: point.lng}]
            };
            return {
              newPolygon
            };
          });
        }
        break;
      default:
        break;
    }
  }

  handleNameChange = event => {
    let name = event.target.value;
    this.setState((state, props) => ({
      newPolygon: {
        name,
        geo_points: state.newPolygon.geo_points
      }
    }));
  }
  
  setAction = action => event => {
    this.setState({
      curAction: action
    });
  }

  handleRepaint = event => {
    this.setState({ 
      polygons: [],
      newPolygon: {
        name: '',
        geo_points: []
      }
    }, this.setAction(null));
  }

  handleSubmit = event => {
    this.props.setPolygons(this.state.polygons);
    this.props.onMapClose();
  }

  handleConfirm = event => {
    if (this.state.newPolygon.name === '') {
      alert(getText('input_error_polygon_name'));
      return;
    }
    const polygons = [...this.state.polygons, this.state.newPolygon];
    this.setState({
      curAction: null,
      newPolygon: {
        name: '',
        geo_points: []
      },
      polygons
    });
  }

  handleCancel = event => {
    this.setState({
      curAction: null,
      newPolygon: {
        name: '',
        geo_points: []
      }
    });
  }

  handlePolygonEdit = (index, newPolygonPoints) => {
    const geo_points = newPolygonPoints.map(point => point.toJSON());
    // -1 refers to the newly added polygon
    if (index === -1)
      this.setState((state, props) => ({
        newPolygon: {
          name: state.newPolygon.name,
          geo_points
        }
      }));
    else
      this.setState((state, props) => {
        const polygons = Object.assign([], state.polygons);
        if (polygons[index]) polygons[index].geo_points = geo_points;
        return {
          polygons
        }
      });
  }

  render() {
    const { newPolygon, polygons, curAction, center } = this.state;
    const { onMapClose } = this.props;
    let defaultCenter;
    const defaultZoom = 5;
    const polygonPoints = [...polygons.map(polygon => polygon.geo_points), newPolygon.geo_points].flat();
    if (polygonPoints.length > 0 && curAction !== 'add') {
      defaultCenter = { 
        lat: polygonPoints.map(position => position.lat).reduce((totalLat, lat) => totalLat + lat, 0) / polygonPoints.length,
        lng: polygonPoints.map(position => position.lng).reduce((totalLng, lng) => totalLng + lng, 0) / polygonPoints.length,
      };
    }
    else {
      defaultCenter = center ? center : getDefaultMapCenter();
    }
    const polygonName = newPolygon.name;

    return (
      <DrawSiteMap
        defaultZoom={defaultZoom}
        defaultCenter={defaultCenter}
        newPolygon={newPolygon}
        polygons={polygons}
        polygonName={polygonName}
        curAction={curAction}
        onNameChange={this.handleNameChange}
        onGoogleMapClick={this.handleGoogleMapClick}
        onGaodeMapClick={this.handleGaodeMapClick}
        onRepaint={this.handleRepaint}
        setAction={this.setAction}
        onConfirm={curAction === 'add' ? this.handleConfirm : this.handleSubmit}
        onCancel={curAction === 'add' ? this.handleCancel : onMapClose}
        onPolygonEdit={this.handlePolygonEdit}
      /> 
    );
  }
}

export default SiteMapContainer;
