import { referenceConstants } from 'src/redux/constants';

const initialState = {
  list: [],
  loading: false,
  filters: {
    limit: 8,
    reviewStatus: 'all',
  },
  pagination: {
    curPage: 1,
    count: 200,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case referenceConstants.GET_REFERENCE_SUCCESS:
      return {
        ...state,
        list: action.list,
        counts: action.counts,
        pagination: {
          ...state.pagination,
          curPage: action.curPage,
          count: action.count,
        },
        loading: false,
      };
    case referenceConstants.SET_FILTER:
      return {
        ...state,
        filters: Object.assign({}, state.filters, action.filters)
      };
    default:
      return state
  }
};
