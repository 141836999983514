import React from 'react';
import { connect } from 'react-redux';
import { Grid, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

import CaseDetail from './CaseDetail';
import CaseNote from './CaseNote';
import CaseReport from './CaseReport';
import CaseHistory from './CaseHistory';
import CaseClose from './CaseClose';
import CaseDelete from './CaseDelete';
import { caseActions, robotActions, cameraActions } from "src/redux/actions";
import PriorityButton from "./PriorityButton";
import ModifyEventTypes from './ModifyEventTypes';
import { getText, getTimeText } from 'src/utils/MultilingualLoader';

const mapCaseToEvent = _case => {
  return _case && _case.event ? {
    caseId: _case.id,
    action: _case.action,
    status: _case.status,
    report: _case.report,
    type: _case.type,
    event_id: _case.event.id,
    event_types: _case.event.types,
    event_location: _case.event.location,
    event_startTime: _case.event.startTime,
    event_endTime: _case.event.endTime,
    priority: _case.priority,
    media: _case.media,
    event_cameraId: _case.event.cameraId,
    event_camera: _case.event.camera,
    event_robotId: _case.event.robotId,
    event_robot: _case.event.robot,
    event_boxId: _case.event.boxId,
    event_source: _case.event.source,
    histories: _case.histories,
    notes: _case.notes,
    viewed: _case.viewed,
  } : {
    caseId: '',
    action: '',
    status: '',
    eventId: '',
    types: '',
    location: '',
    startTime: '',
    endTime: '',
    priority: '',
  };
};

const mapCaseToInspection = _case => {
  return _case && _case.inspection ? {
    caseId: _case.id,
    action: _case.action,
    status: _case.status,
    report: _case.report,
    type: _case.type,
    inspection_id: _case.inspection.id,
    inspection_types: _case.inspection.algos,
    inspection_startTime: _case.inspection.startTime,
    inspection_endTime: _case.inspection.endTime,
    priority: _case.priority,
    inspection_robotId: _case.inspection.robotId,
    inspection_robot: _case.inspection.robot,
    inspection_source: 'robot',
    histories: _case.histories,
    notes: _case.notes,
    viewed: _case.viewed,
  } : {
    caseId: '',
    action: '',
    status: '',
    eventId: '',
    types: '',
    startTime: '',
    endTime: '',
    priority: '',
  };
};

const mapStateToProps = state => {
  const { selected, updating } = state.case;
  const { cameraAlgos } = state.camera;
  const robotCaseTypes = state.case.caseTypes;
  const event = selected.event && mapCaseToEvent(selected);
  const inspection = selected.inspection && mapCaseToInspection(selected);
  // TODO: merge events and inspections into cases with a more united way
  let eventTypes = null;
  if (event) {
    if (event.event_source === 'robot') {
      eventTypes = event.event_robotId && robotCaseTypes[event.event_robotId]
        && robotCaseTypes[event.event_robotId].types && robotCaseTypes[event.event_robotId].types;
    }
    else eventTypes = event.event_cameraId && cameraAlgos[event.event_cameraId] && cameraAlgos[event.event_cameraId].algos;
  }
  if (inspection) {
    eventTypes = inspection.inspection_robotId && robotCaseTypes[inspection.inspection_robotId]
      && robotCaseTypes[inspection.inspection_robotId].types && robotCaseTypes[inspection.inspection_robotId].types;
  }

  return { selected, event, inspection, updating, eventTypes, cameraAlgos, robotCaseTypes };
};

const mapDispatchToProps = dispatch => {
  return {
    changePriority: (caseId, priority) => {
      dispatch(caseActions.updateCase({
        caseId: caseId,
        action: "update_priority",
        priority: priority
      }));
    },
    changeTypes: (caseId, types) => {
      dispatch(caseActions.updateCase({
        caseId,
        action: "update_types",
        types
      }));
    },
    closeCase: caseId => {
      dispatch(caseActions.updateCase({
        caseId: caseId,
        action: "close"
      }));
    },
    deleteCase: caseId => dispatch(caseActions.deleteCase(caseId)),
    getRobotAlgos: robotId => {
      dispatch(robotActions.getRobotAlgos(robotId));
    },
    getRobotCaseTypes: robotId => dispatch(caseActions.getCaseTypes(robotId)),
    getCameraAlgos: (cameraId, boxId) => {
      dispatch(cameraActions.getCameraAlgos(cameraId, boxId));
    },
  };
};

class CaseContentContainer extends React.Component {
  constructor(props) {
    super(props);
    let types = {};
    if (props.event && props.event.event_types) props.event.event_types.split(',').forEach(type => types[type] = true);
    if (props.inspection && props.inspection.inspection_types) props.inspection.inspection_types.split(',').forEach(type => types[type] = true);
    this.state = {
      types,
      typesEditing: false
    };
  }

  componentDidMount() {
    const { event, inspection } = this.props;

    if (!this.props.eventTypes) {
      if (event) {
        if (event.event_robotId && (!this.props.robotCaseTypes[event.event_robotId] || !this.props.robotCaseTypes[event.event_robotId].loading))
          this.props.getRobotCaseTypes(event.event_robotId);
        else if (event.event_boxId && event.event_cameraId
          && (!this.props.cameraAlgos[event.event_cameraId] || !this.props.cameraAlgos[event.event_cameraId].loading))
          this.props.getCameraAlgos(event.event_cameraId, event.event_boxId);
      }
      if (inspection) {
        if (inspection.inspection_robotId && (!this.props.robotCaseTypes[inspection.inspection_robotId] || !this.props.robotCaseTypes[inspection.inspection_robotId].loading))
          this.props.getRobotCaseTypes(inspection.inspection_robotId);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { event, inspection } = this.props
    if ((event && event.caseId && (!prevProps.event || prevProps.event.caseId !== event.caseId))
      || ((inspection && inspection.caseId && (!prevProps.inspection || prevProps.inspection.caseId !== inspection.caseId)))) {
      let types = {};
      if (event && event.event_types) event.event_types.split(',').forEach(type => types[type] = true);
      if (inspection && inspection.inspection_types) inspection.inspection_types.split(',').forEach(type => types[type] = true);
      this.setState({
        types,
        typesEditing: false
      });

      if (!this.props.eventTypes) {
        if (event) {
          if (event.event_robotId && (!this.props.robotCaseTypes[event.event_robotId] || !this.props.robotCaseTypes[event.event_robotId].loading))
            this.props.getRobotCaseTypes(event.event_robotId);
          else if (event.event_boxId && event.event_cameraId
            && (!this.props.cameraAlgos[event.event_cameraId] || !this.props.cameraAlgos[event.event_cameraId].loading))
            this.props.getCameraAlgos(event.event_cameraId, event.event_boxId);
        }
        if (inspection) {
          if (inspection.inspection_robotId && (!this.props.robotCaseTypes[inspection.inspection_robotId] || !this.props.robotCaseTypes[inspection.inspection_robotId].loading))
            this.props.getRobotCaseTypes(inspection.inspection_robotId);
        }
      }
    }
  }

  handleChangePriority = caseId => priority => e => {
    this.props.changePriority(caseId, priority);
  };

  closeCase = caseId => () => {
    if (window.confirm(getText('confirm_close_case'))) {
      this.props.closeCase(caseId);
    }
  }

  deleteCase = caseId => () => {
    if (window.confirm(getText('confirm_delete_case'))) {
      this.props.deleteCase(caseId);
    }
  }

  handleEditTypes = event => {
    this.setState({
      typesEditing: true,
    });
  }

  handleChangeTypes = type => event => {
    this.setState({
      types: {
        ...this.state.types,
        [type]: event.target.checked
      }
    })
  }

  handleSubmitTypes = e => {
    const { event, inspection, updating, changeTypes } = this.props;
    let types = Object.keys(this.state.types).filter(type => this.state.types[type]).join(',');
    if (!updating) changeTypes((event && event.caseId) || (inspection && inspection.caseId), types);
    this.setState({
      typesEditing: false,
    });
  }

  render() {
    const { selected, event, inspection, eventTypes } = this.props;
    const incident = selected.incident;
    const { types, typesEditing } = this.state;
    const typesText = Object.keys(types).filter(type => types[type]).map(type => getText(type)).join(',');
    // const typesText = eventTypes && Object.keys(types).filter(type => types[type]).map(type => {
    //   let eventType = eventTypes.find(eventType => eventType.name === type);
    //   return (eventType && eventType.label) || '';
    // }).join(',');

    const details = (event || inspection) && [
      {
        title: getText('case_id'),
        content: selected.id
      },
      {
        title: inspection ? getText('inspection_id') : getText('event_id'),
        content: inspection ? inspection.inspection_id : event.event_id
      },
      {
        title: getText('types'),
        content: (<>
          {
            <>
              {typesText}
              {typesEditing
                ? <IconButton style={{ padding: 0, float: 'right' }} onClick={this.handleSubmitTypes}><CheckIcon /></IconButton>
                : <IconButton style={{ padding: 0, float: 'right' }} onClick={this.handleEditTypes}><EditIcon /></IconButton>
              }
            </>
          }
        </>)
      },
      {
        title: '',
        hide: !typesEditing,
        content: (<ModifyEventTypes types={types} typeOptions={eventTypes || []} onChange={this.handleChangeTypes} />)
      },
      {
        title: event && event.event_source === 'camera' ? getText('camera_name') : getText('robot_name'),
        content: event && event.event_source === 'camera' ? event.event_camera && event.event_camera.name :
          (inspection ? inspection.inspection_robot && inspection.inspection_robot.name : event.event_robot && event.event_robot.name)
      },
      {
        title: event && event.event_source === 'camera' ? getText('camera_id') : getText('robot_id'),
        content: event && event.event_source === 'camera' ? event.event_camera && event.event_camera.id :
          (inspection ? inspection.inspection_robotId : event.event_robot && event.event_robot.id)
      },
      {
        title: getText('location'),
        content: event && event.event_location !== 'not_set' ? event.event_location : getText('not_set')
      },
      {
        title: getText('state'),
        content: getText(selected.status)
      },
      {
        title: getText('priority'),
        content: (<PriorityButton priority={selected.priority} onChangePriority={this.handleChangePriority(selected.id)} />)
      },
      {
        title: getText('start_time'),
        content: inspection ? getTimeText(inspection.inspection_startTime, 'llll') : getTimeText(event.event_startTime, 'llll')
      },
      {
        title: getText('end_time'),
        content: inspection ? getTimeText(inspection.inspection_endTime, 'llll') : getTimeText(event.event_endTime, 'llll')
      },
    ];

    const incidentDetails = incident && [
      {
        title: getText('case_id'),
        content: selected.id
      },
      {
        title: getText('system_id'),
        content: incident.systemId
      },
      {
        title: getText('trigger_id'),
        content: incident.triggerId
      },
      {
        title: getText('escalation_id'),
        content: incident.escalationId
      },
      {
        title: getText('type'),
        content: incident.type
      },
      {
        title: getText('created_at'),
        content: getTimeText(incident.createdAt, 'llll')
      },
      {
        title: getText('robot_name'),
        content: incident.robot && incident.robot.name
      },
      {
        title: getText('way_point_name'),
        content: incident.metaData && incident.metaData.robot && incident.metaData.robot.nearby_way_point_name
      },
      {
        title: getText('state'),
        content: getText(selected.status)
      },
      {
        title: getText('priority'),
        content: (<PriorityButton priority={selected.priority} onChangePriority={this.handleChangePriority(selected.id)} />)
      },
    ];

    return (
      <Grid
        container={true}
        justify="flex-start"
        direction="row"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item={true} xs={12} lg={6}>
          <CaseDetail
            details={details || incidentDetails}
          />
          <CaseReport
            report={selected.report}
            media={selected.media}
          />
        </Grid>
        <Grid item={true} xs={12} lg={6}>
          <CaseNote
            caseId={selected.id}
            notes={selected.notes}
          />
          <CaseClose
            closeCase={this.closeCase(selected.id)}
          />
          <CaseDelete
            deleteCase={this.deleteCase(selected.id)}
          />
          <CaseHistory
            histories={selected.histories}
          />
        </Grid>
      </Grid>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseContentContainer);
