export const vehicleColorOptions = [
    {name: 'black', value: 'black', label: 'Black', color: '#111111'},
    {name: 'gray/silver', value: 'gray,silver', label: 'Gray / Silver', color: '#808080'},
    {name: 'brown', value: 'brown', label: 'Brown', color: '#544213'},
    {name: 'tan', value: 'tan', label: 'Tan', color: '#d2b48c'},
    // {name: 'silver', value: 'silver', label: 'Silver', color: '#cbcbcb'},
    {name: 'white', value: 'white', label: 'White', color: '#ffffff'},
    {name: 'blue', value: 'blue', label: 'Blue', color: '#419bf9'},
    {name: 'green', value: 'green', label: 'Green', color: '#4caf50'},
    {name: 'red', value: 'red', label: 'Red', color: '#f44336'},
    {name: 'orange', value: 'orange', label: 'Orange', color: '#ffa500'},
    {name: 'yellow', value: 'yellow', label: 'Yellow', color: '#ffff00'},
    {name: 'gold', value: 'gold', label: 'Gold', color: '#ffd700'},
    {name: 'purple', value: 'purple', label: 'Purple', color: '#800080'},
    // {name: 'unknown', value: 'unknown', label: 'Unknown'},
  ];
  export const vehicleTypeOptions = [
    // {name: 'Campervan', value: 'campervan', label: 'Campervan'},
    // {name: 'Convertible', value: 'convertible', label: 'Convertible'},
    // {name: 'Coupe', value: 'coupe', label: 'Coupe'},
    // {name: 'Hatchback', value: 'hatchback', label: 'Hatchback'},
    {name: 'Heavy truck', value: 'heavytruck', label: 'Heavy truck'},
    {name: 'MPV', value: 'mpv', label: 'MPV'},
    {name: 'Pickup truck', value: 'pickup', label: 'Pickup truck'},
    {name: 'Sedan/Hatchback', value: 'sedan,hatchback', label: 'Sedan / Hatchback'},
    {name: 'SUV', value: 'suv', label: 'SUV'},
    {name: 'Van/Campervan', value: 'van,campervan', label: 'Van / Campervan'},
    {name: 'Unknown', value: 'Unknown', label: 'Unknown'},
  ];
  export const vehicleMakeOptions = [
    {name: 'AC', value: 'ac', label: 'AC'},
    {name: 'AC Propulsion', value: 'ac propulsion', label: 'AC Propulsion'},
    {name: 'Acura', value: 'acura', label: 'Acura'},
    {name: 'A.D. Tramontana', value: 'a.d. tramontana', label: 'A.D. Tramontana'},
    {name: 'Alfa Romeo', value: 'alfa romeo', label: 'Alfa Romeo'},
    {name: 'Almac', value: 'almac', label: 'Almac'},
    {name: 'Alternative Cars', value: 'alternative cars', label: 'Alternative Cars'},
    {name: 'Amuza', value: 'amuza', label: 'Amuza'},
    {name: 'Anteros', value: 'anteros', label: 'Anteros'},
    {name: 'Arash', value: 'arash', label: 'Arash'},
    {name: 'Ariel', value: 'ariel', label: 'Ariel'},
    {name: 'Arrinera', value: 'arrinera', label: 'Arrinera'},
    {name: 'ASL', value: 'asl', label: 'ASL'},
    {name: 'Asterio', value: 'asterio', label: 'Asterio'},
    {name: 'Aston Martin', value: 'aston martin', label: 'Aston Martin'},
    {name: 'Audi', value: 'audi', label: 'Audi'},
    {name: 'BAC', value: 'bac', label: 'BAC'},
    {name: 'Bajaj', value: 'bajaj', label: 'Bajaj'},
    {
      name: 'Beijing Automobile Works',
      value: 'beijing automobile works',
      label: 'Beijing Automobile Works',
    },
    {name: 'Bentley', value: 'bentley', label: 'Bentley'},
    {name: 'BMW', value: 'bmw', label: 'BMW'},
    {name: 'Bolloré', value: 'bolloré', label: 'Bolloré'},
    {name: 'Bolwell', value: 'bolwell', label: 'Bolwell'},
    {name: 'Brilliance / Huachen', value: 'brilliance / huachen', label: 'Brilliance / Huachen'},
    {name: 'Bristol', value: 'bristol', label: 'Bristol'},
    {name: 'British Leyland', value: 'british leyland', label: 'British Leyland'},
    {name: 'Brm Buggy', value: 'brm buggy', label: 'Brm Buggy'},
    {name: 'Brooke', value: 'brooke', label: 'Brooke'},
    {name: 'Buddy', value: 'buddy', label: 'Buddy'},
    {name: 'Bufori', value: 'bufori', label: 'Bufori'},
    {name: 'Bugatti', value: 'bugatti', label: 'Bugatti'},
    {name: 'Buick', value: 'buick', label: 'Buick'},
    {name: 'BYD', value: 'byd', label: 'BYD'},
    {name: 'Cadillac', value: 'cadillac', label: 'Cadillac'},
    {name: 'Caparo', value: 'caparo', label: 'Caparo'},
    {name: 'Carbontech', value: 'carbontech', label: 'Carbontech'},
    {name: 'Carice', value: 'carice', label: 'Carice'},
    {name: "Chang'an", value: "chang'an", label: "Chang'an"},
    {name: 'Changhe', value: 'changhe', label: 'Changhe'},
    {name: 'Chery', value: 'chery', label: 'Chery'},
    {name: 'Chevrolet', value: 'chevrolet', label: 'Chevrolet'},
    {name: 'Chevron', value: 'chevron', label: 'Chevron'},
    {name: 'Citroën', value: 'citroën', label: 'Citroën'},
    {name: 'Chrysler', value: 'chrysler', label: 'Chrysler'},
    {name: 'Commuter Cars', value: 'commuter cars', label: 'Commuter Cars'},
    {name: 'Connaught', value: 'connaught', label: 'Connaught'},
    {name: 'Covini', value: 'covini', label: 'Covini'},
    {name: 'Dacia', value: 'dacia', label: 'Dacia'},
    {name: 'Daihatsu', value: 'daihatsu', label: 'Daihatsu'},
    {name: 'Datsun', value: 'datsun', label: 'Datsun'},
    {name: 'De La Chapelle', value: 'de la chapelle', label: 'De La Chapelle'},
    {name: 'DMC', value: 'dmc', label: 'DMC'},
    {name: 'Diardi', value: 'diardi', label: 'Diardi'},
    {name: 'Dodge', value: 'dodge', label: 'Dodge'},
    {name: 'Donkervoort', value: 'donkervoort', label: 'Donkervoort'},
    {name: 'Dongfeng', value: 'dongfeng', label: 'Dongfeng'},
    {name: 'Donto', value: 'donto', label: 'Donto'},
    {name: 'Ds Automobiles', value: 'ds automobiles', label: 'Ds Automobiles'},
    {
      name: 'Dynasti Electric Car Corp.',
      value: 'dynasti electric car corp.',
      label: 'Dynasti Electric Car Corp.',
    },
    {name: 'E-Vade', value: 'e-vade', label: 'E-Vade'},
    {name: 'Effedi', value: 'effedi', label: 'Effedi'},
    {name: 'Egy-Tech Engineering', value: 'egy-tech engineering', label: 'Egy-Tech Engineering'},
    {name: 'Electric Raceabout', value: 'electric raceabout', label: 'Electric Raceabout'},
    {name: 'Elfin', value: 'elfin', label: 'Elfin'},
    {name: 'Emgrand', value: 'emgrand', label: 'Emgrand'},
    {name: 'Englon', value: 'englon', label: 'Englon'},
    {name: 'Eterniti', value: 'eterniti', label: 'Eterniti'},
    {name: 'Etox', value: 'etox', label: 'Etox'},
    {name: 'Equus', value: 'equus', label: 'Equus'},
    {name: 'Exagon', value: 'exagon', label: 'Exagon'},
    {name: 'Faralli & Mazzanti', value: 'faralli & mazzanti', label: 'Faralli & Mazzanti'},
    {name: 'FAW', value: 'faw', label: 'FAW'},
    {name: 'Ferrari', value: 'ferrari', label: 'Ferrari'},
    {name: 'FIAT', value: 'fiat', label: 'FIAT'},
    {name: 'Fisker', value: 'fisker', label: 'Fisker'},
    {name: 'Foday', value: 'foday', label: 'Foday'},
    {name: 'Force', value: 'force', label: 'Force'},
    {name: 'Ford', value: 'ford', label: 'Ford'},
    {name: 'Ford Australia', value: 'ford australia', label: 'Ford Australia'},
    {name: 'Ford Germany', value: 'ford germany', label: 'Ford Germany'},
    {name: 'Fornasari', value: 'fornasari', label: 'Fornasari'},
    {name: 'Fraser', value: 'fraser', label: 'Fraser'},
    {name: 'Gac Group', value: 'gac group', label: 'Gac Group'},
    {name: 'Galpin', value: 'galpin', label: 'Galpin'},
    {name: 'Geely', value: 'geely', label: 'Geely'},
    {name: 'Genesis', value: 'genesis', label: 'Genesis'},
    {name: 'Gibbs', value: 'gibbs', label: 'Gibbs'},
    {name: 'Gillet', value: 'gillet', label: 'Gillet'},
    {name: 'Ginetta', value: 'ginetta', label: 'Ginetta'},
    {name: 'GMC', value: 'gmc', label: 'GMC'},
    {name: 'Gonow', value: 'gonow', label: 'Gonow'},
    {
      name: 'Great Wall / Changcheng',
      value: 'great wall / changecheng',
      label: 'Great Wall / Changcheng',
    },
    {name: 'Greentech Automotive', value: 'greentech automotive', label: 'Greentech Automotive'},
    {name: 'Grinnall', value: 'grinnall', label: 'Grinnall'},
    {name: 'GTA Motor', value: 'gta motor', label: 'GTA Motor'},
    {name: 'Gumpert', value: 'gumpert', label: 'Gumpert'},
    {name: 'Gurgel', value: 'gurgel', label: 'Gurgel'},
    {name: 'Hennessey', value: 'hennessey', label: 'Hennessey'},
    {name: 'Hindustan', value: 'hindustan', label: 'Hindustan'},
    {name: 'Holden', value: 'holden', label: 'Holden'},
    {name: 'Honda', value: 'honda', label: 'Honda'},
    {name: 'Hongqi', value: 'hongqi', label: 'Hongqi'},
    {name: 'Hradyesh', value: 'hradyesh', label: 'Hradyesh'},
    {name: 'Htt Technologies', value: 'htt technologies', label: 'Htt Technologies'},
    {name: 'Hulme', value: 'hulme', label: 'Hulme'},
    {name: 'Hyundai', value: 'hyundai', label: 'Hyundai'},
    {name: 'ICML', value: 'icml', label: 'ICML'},
    {name: 'IFR', value: 'ifr', label: 'IFR'},
    {name: 'Iran Khodro', value: 'iran khodro', label: 'Iran Khodro'},
    {name: 'IKCO', value: 'ikco', label: 'IKCO'},
    {name: 'Imperia', value: 'imperia', label: 'Imperia'},
    {name: 'Infiniti', value: 'infiniti', label: 'Infiniti'},
    {name: 'IVM', value: 'ivm', label: 'IVM'},
    {name: 'Invicta', value: 'invicta', label: 'Invicta'},
    {name: 'Isdera', value: 'isdera', label: 'Isdera'},
    {name: 'Isuzu', value: 'isuzu', label: 'Isuzu'},
    {name: 'JAC', value: 'jac', label: 'JAC'},
    {name: 'Jaguar', value: 'jaguar', label: 'Jaguar'},
    {name: 'Jeep', value: 'jeep', label: 'Jeep'},
    {name: 'Jensen Motors', value: 'jensen motors', label: 'Jensen Motors'},
    {name: 'Jetcar', value: 'jetcar', label: 'Jetcar'},
    {name: 'Jonway', value: 'jonway', label: 'Jonway'},
    {name: 'Joss', value: 'joss', label: 'Joss'},
    {name: 'Kaipan', value: 'kaipan', label: 'Kaipan'},
    {name: 'Kantanka', value: 'kantanka', label: 'Kantanka'},
    {name: 'Karma', value: 'karma', label: 'Karma'},
    {name: 'Koenigsegg', value: 'koenigsegg', label: 'Koenigsegg'},
    {name: 'Korres', value: 'korres', label: 'Korres'},
    {name: 'KIA', value: 'kia', label: 'KIA'},
    {name: 'Kiat', value: 'kiat', label: 'Kiat'},
    {name: 'Kish Khodro', value: 'kish khodro', label: 'Kish Khodro'},
    {name: 'KTM', value: 'ktm', label: 'KTM'},
    {name: 'Lada', value: 'lada', label: 'Lada'},
    {name: 'Lamborghini', value: 'lamborghini', label: 'Lamborghini'},
    {name: 'Lancia', value: 'lancia', label: 'Lancia'},
    {name: 'Land Rover', value: 'land rover', label: 'Land Rover'},
    {name: 'Landwind', value: 'landwind', label: 'Landwind'},
    {name: 'Laraki', value: 'laraki', label: 'Laraki'},
    {name: 'Leblanc', value: 'leblanc', label: 'Leblanc'},
    {name: 'Leitch', value: 'leitch', label: 'Leitch'},
    {name: 'Leopard', value: 'leopard', label: 'Leopard'},
    {name: 'Lexus', value: 'lexus', label: 'Lexus'},
    {name: 'Li-Ion', value: 'li-ion', label: 'Li-Ion'},
    {name: 'Lifan', value: 'lifan', label: 'Lifan'},
    {name: 'Lightning', value: 'lightning', label: 'Lightning'},
    {name: 'Lincoln', value: 'lincoln', label: 'Lincoln'},
    {name: 'Lister', value: 'lister', label: 'Lister'},
    {name: 'Local Motors', value: 'local motors', label: 'Local Motors'},
    {name: 'Lobini', value: 'lobini', label: 'Lobini'},
    {name: 'Lotec', value: 'lotec', label: 'Lotec'},
    {name: 'Lotus Cars', value: 'lotus cars', label: 'Lotus Cars'},
    {name: 'Lucra Cars', value: 'lucra cars', label: 'Lucra Cars'},
    {name: 'Luxgen', value: 'luxgen', label: 'Luxgen'},
    {name: 'Mahindra', value: 'mahindra', label: 'Mahindra'},
    {name: 'Marussia', value: 'marussia', label: 'Marussia'},
    {name: 'Maruti Suzuki', value: 'maruti suzuki', label: 'Maruti Suzuki'},
    {name: 'Maserati', value: 'maserati', label: 'Maserati'},
    {name: 'Mastretta', value: 'mastretta', label: 'Mastretta'},
    {name: 'Mazda', value: 'mazda', label: 'Mazda'},
    {name: 'Mclaren', value: 'mclaren', label: 'Mclaren'},
    {name: 'Mercedes Benz', value: 'mercedes benz', label: 'Mercedes Benz'},
    {name: 'MG', value: 'mg', label: 'MG'},
    {name: 'Micro', value: 'mirco', label: 'Micro'},
    {name: 'Mini', value: 'mini', label: 'Mini'},
    {name: 'Mitsubishi', value: 'mitsubishi', label: 'Mitsubishi'},
    {name: 'Mitsuoka', value: 'mitsuoka', label: 'Mitsuoka'},
    {name: 'Morgan', value: 'morgan', label: 'Morgan'},
    {name: 'Mullen', value: 'mullen', label: 'Mullen'},
    {name: 'Mycar', value: 'mycar', label: 'Mycar'},
    {name: 'Myvi-Perodua', value: 'myvi-perodua', label: 'Myvi-Perodua'},
    {name: 'Nissan', value: 'nissan', label: 'Nissan'},
    {name: 'Noble', value: 'noble', label: 'Noble'},
    {name: 'Nota', value: 'nota', label: 'Nota'},
    {name: 'Oldsmobile', value: 'oldsmobile', label: 'Oldsmobile'},
    {name: 'Opel', value: 'opel', label: 'Opel'},
    {name: 'Optimal Energy', value: 'optimal energy', label: 'Optimal Energy'},
    {name: 'Orca', value: 'orca', label: 'Orca'},
    {name: 'Oltcit', value: 'oltcit', label: 'Oltcit'},
    {name: 'Pagani', value: 'pagani', label: 'Pagani'},
    {name: 'Panhard', value: 'panhard', label: 'Panhard'},
    {name: 'Panoz', value: 'panoz', label: 'Panoz'},
    {name: 'Perana', value: 'perana', label: 'Perana'},
    {name: 'Perodua', value: 'perodua', label: 'Perodua'},
    {name: 'Peugeot', value: 'peugeot', label: 'Peugeot'},
    {name: 'P.G.O.', value: 'p.g.o.', label: 'P.G.O.'},
    {name: 'Polarsun', value: 'polarsun', label: 'Polarsun'},
    {name: 'Plymouth', value: 'plymouth', label: 'Plymouth'},
    {name: 'Porsche', value: 'porsche', label: 'Porsche'},
    {name: 'Proto', value: 'proto', label: 'Proto'},
    {name: 'Oullim', value: 'oullim', label: 'Oullim'},
    {name: 'Proton', value: 'proton', label: 'Proton'},
    {name: 'Puritalia', value: 'puritalia', label: 'Puritalia'},
    {name: 'Qoros', value: 'qoros', label: 'Qoros'},
    {name: 'Qvale', value: 'qvale', label: 'Qvale'},
    {name: 'Radical', value: 'radical', label: 'Radical'},
    {name: 'Reliant', value: 'reliant', label: 'Reliant'},
    {name: 'Renault', value: 'renault', label: 'Renault'},
    {name: 'Reva', value: 'reva', label: 'Reva'},
    {name: 'Rimac', value: 'rimac', label: 'Rimac'},
    {name: 'Rinspeed', value: 'rinspeed', label: 'Rinspeed'},
    {name: 'Roding', value: 'roding', label: 'Roding'},
    {name: 'Roewe', value: 'roewe', label: 'Roewe'},
    {name: 'Rolls-Royce', value: 'rolls-royce', label: 'Rolls-Royce'},
    {name: 'Rossin-Bertin', value: 'rossin-bertin', label: 'Rossin-Bertin'},
    {name: 'Rossion', value: 'rossion', label: 'Rossion'},
    {name: 'Rover', value: 'rover', label: 'Rover'},
    {name: 'Saab', value: 'saab', label: 'Saab'},
    {name: 'Saleen', value: 'saleen', label: 'Saleen'},
    {name: 'Saic-Gm-Wuling', value: 'saic-gm-wuling', label: 'Saic-Gm-Wuling'},
    {name: 'Saipa', value: 'saipa', label: 'Saipa'},
    {name: 'Saker', value: 'saker', label: 'Saker'},
    {name: 'Samsung', value: 'samsung', label: 'Samsung'},
    {name: 'SAN', value: 'san', label: 'SAN'},
    {name: 'Sbarro', value: 'sbarro', label: 'Sbarro'},
    {name: 'Scion', value: 'scion', label: 'Scion'},
    {name: 'Seat', value: 'seat', label: 'Seat'},
    {name: 'Shanghai Maple', value: 'shanghai maple', label: 'Shanghai Maple'},
    {name: 'SIN', value: 'sin', label: 'SIN'},
    {name: 'Škoda', value: 'škoda', label: 'Škoda'},
    {name: 'Smart', value: 'smart', label: 'Smart'},
    {name: 'Spada Vetture Sport', value: 'spada vetture sport', label: 'Spada Vetture Sport'},
    {name: 'Spyker', value: 'spyker', label: 'Spyker'},
    {name: 'Ssangyong', value: 'ssangyong', label: 'Ssangyong'},
    {name: 'Ssc North America', value: 'ssc north america', label: 'Ssc North America'},
    {
      name: 'Street & Racing Technology',
      value: 'street & racing technology',
      label: 'Street & Racing Technology',
    },
    {name: 'Subaru', value: 'subaru', label: 'Subaru'},
    {name: 'Suzuki', value: 'suzuki', label: 'Suzuki'},
    {name: 'Tanom', value: 'tanom', label: 'Tanom'},
    {name: 'Tata', value: 'tata', label: 'Tata'},
    {name: 'Tauro', value: 'tauro', label: 'Tauro'},
    {name: 'Tawon Car', value: 'tawon car', label: 'Tawon Car'},
    {name: 'Td Cars', value: 'td cars', label: 'Td Cars'},
    {name: 'Tesla', value: 'tesla', label: 'Tesla'},
    {name: 'Thai Rung', value: 'thai rung', label: 'Thai Rung'},
    {name: 'Toyota', value: 'toyota', label: 'Toyota'},
    {name: 'Trekka', value: 'trekka', label: 'Trekka'},
    {name: 'Trident', value: 'trident', label: 'Trident'},
    {name: 'Triumph', value: 'triumph', label: 'Triumph'},
    {name: 'Troller', value: 'troller', label: 'Troller'},
    {name: 'Trumpchi', value: 'trumpchi', label: 'Trumpchi'},
    {name: 'Tushek', value: 'tushek', label: 'Tushek'},
    {name: 'TVR', value: 'tvr', label: 'TVR'},
    {name: 'TVS', value: 'tvs', label: 'TVS'},
    {name: 'Ultima', value: 'ultima', label: 'Ultima'},
    {name: 'UMM', value: 'umm', label: 'UMM'},
    {name: 'UEV', value: 'uev', label: 'UEV'},
    {name: 'URI', value: 'uri', label: 'URI'},
    {name: 'UAZ', value: 'uaz', label: 'UAZ'},
    {name: 'Vauxhall Motors', value: 'vauxhall motors', label: 'Vauxhall Motors'},
    {name: 'Vector', value: 'vector', label: 'Vector'},
    {name: 'Vencer', value: 'vencer', label: 'Vencer'},
    {name: 'Venirauto', value: 'venirauto', label: 'Venirauto'},
    {name: 'Venturi', value: 'venturi', label: 'Venturi'},
    {name: 'Vepr', value: 'vepr', label: 'Vepr'},
    {name: 'Volkswagen', value: 'volkswagen', label: 'Volkswagen'},
    {name: 'Volvo', value: 'volvo', label: 'Volvo'},
    {name: 'Vinfast', value: 'vinfast', label: 'Vinfast'},
    {name: 'W Motors', value: 'w motors', label: 'W Motors'},
    {name: 'Wallyscar', value: 'wallyscar', label: 'Wallyscar'},
    {name: 'Westfield', value: 'westfield', label: 'Westfield'},
    {name: 'Wheego', value: 'wheego', label: 'Wheego'},
    {name: 'Wiesmann', value: 'wiesmann', label: 'Wiesmann'},
    {name: 'Xenia', value: 'xenia', label: 'Xenia'},
    {name: 'YES!', value: 'yes!', label: 'YES!'},
    {name: 'Youabian Puma', value: 'youabian puma', label: 'Youabian Puma'},
    {name: 'Zastava Automobiles', value: 'zastava automobiles', label: 'Zastava Automobiles'},
    {name: 'Zender Cars', value: 'zender cars', label: 'Zender Cars'},
    {name: 'Zenos Cars', value: 'zenos cars', label: 'Zenos Cars'},
    {name: 'Zenvo', value: 'zenvo', label: 'Zenvo'},
    {name: 'ZIL', value: 'zil', label: 'ZIL'},
    {name: 'Zx Auto', value: 'zx auto', label: 'Zx Auto'},
  ];
  
  export const commonVehicleMakerOptions = [
    'Ford',
    'Chrysler',
    'Jeep',
    'Tesla',
    'Chevrolet',
    'Lincoln',
    'Buick',
    'GMC',
    'Cadillac',
    'Ram',
    'Dodge',
    'Volvo',
    'Toyota',
    'Nissan',
    'Honda',
    'Lexus',
    'Infiniti',
    'Mazda',
    'Subaru',
    'Acura',
    'Scion',
    'BMW',
    'Mini',
    'Audi',
    'Mercedes Benz',
    'Volkswagen',
    'Porsche',
    'Hyundai',
    'KIA',
    'Ferrari',
    'Fiat',
    'Jaguar',
    'Land Rover',
  ];
  
  // contrast color ref https://www.canva.com/colors/color-wheel/
  export const contrastColorOptions = {
    black: '#f1f1f1',
    gray: '#F44336',
    silver: '#F44336',
    brown: '#132554',
    tan: '#8CAAD2',
    white: '#F44336',
    blue: '#F99F41',
    green: '#AF4CAB',
    red: '#36E7F4',
    orange: '#005AFF',
    yellow: '#0000FF',
    gold: '#0028FF',
    purple: '#008000',
  };
  