import { sensorConstants } from "src/redux/constants";

const initialState = {
  sensors: {},
  sensorType: '',
  filters: {
    startTime: null,
    endTime: null,
    radius: 1,
    mapId: null,
    routeId: null
  },
  showType: 'cluster'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case sensorConstants.GET_SENSOR_CLUSTERS_REQUEST:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          [action.robotId]: {
            loading: true
          }
        }
      };
    case sensorConstants.GET_SENSOR_CLUSTERS_SUCCESS:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          [action.robotId]: {
            ...state.sensors[action.robotId],
            clusters: action.clusters,
            loading: false
          }
        }
      };
    case sensorConstants.GET_SENSOR_CLUSTERS_FAILURE:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          [action.robotId]: {
            ...state.sensors[action.robotId],
            loading: false
          }
        }
      };
    case sensorConstants.GET_SENSOR_POINTS_REQUEST:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          [action.robotId]: {
            loading: true
          }
        }
      };
    case sensorConstants.GET_SENSOR_POINTS_SUCCESS:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          [action.robotId]: {
            ...state.sensors[action.robotId],
            points: action.points,
            loading: false
          }
        }
      };
    case sensorConstants.GET_SENSOR_POINTS_FAILURE:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          [action.robotId]: {
            ...state.sensors[action.robotId],
            loading: false
          }
        }
      };
    case sensorConstants.SET_FILTER:
      return {
        ...state,
        filters: Object.assign({}, state.filters, action.filters)
      };
    case sensorConstants.SET_SENSOR_TYPE:
      return {
        ...state,
        sensorType: action.sensorType
      };
    case sensorConstants.SET_SHOW_TYPE:
      return {
        ...state,
        showType: action.showType
      };
    default:
      return state;
  }
};
