import React from 'react';
import { connect } from "react-redux";
import SignIn from './SignIn';
import { userActions } from 'src/redux/actions';

const mapStateToProps = state => {
  return {
    submitting: state.user.submitting
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signin: (username, password) => {
      dispatch(userActions.signin(username, password));
    }
  };
};

class SignInContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      username: '',
      password: '',
      showPW: false,
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleSubmit = () => {
    if (!this.props.submitting){
      const { username, password } = this.state;
      this.props.signin(username, password);
    }
  }

  handleChange = prop => event => {
    this.setState({
      ...this.state,
      [prop]: event.target.value
    });
  }

  handleClick = show => () => {
    this.setState({
      showPW: show
    });
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) this.handleSubmit();
  }

  render() {
    return (
        <SignIn
          username={this.state.username}
          password={this.state.password}
          showPW={this.state.showPW}
          submitting={false}
          onClick={this.handleClick}
          onSubmit={this.handleSubmit}
          onChange={this.handleChange}
        />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInContainer);

export { SignInContainer };
