import React from 'react';
import { connect } from 'react-redux';

import { manageActions } from 'src/redux/actions';
import { manageConstants } from 'src/redux/constants';
import history from 'src/utils/history';
import ManageList from "./ManageList";
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  const { selectedType } = state.manage;
  return { selectedType };
};

const mapDispatchToProps = dispatch => {
  return {
    changeType: selectedType => {
      dispatch(manageActions.changeType(selectedType));
    }
  };
};

class ManageListContainer extends React.Component {
  handleOnclick = item => e => {
    this.props.changeType(item.type);
    history.push(item.path);
  }

  render() {
    let listItems = [];
    const { selectedType } = this.props;
    const rolePath = window.location.pathname;
    if (rolePath.includes('adminmanage')){
      listItems = [
        { label: getText('company'), path: '/adminmanage/company', type: manageConstants.COMPANY },
        { label: getText('guard_admin'), path: '/adminmanage/guardadmin', type: manageConstants.GUARD_ADMIN },
        { label: getText('customer'), path: '/adminmanage/customer', type: manageConstants.CUSTOMER }
      ];
    }
    else if (rolePath.includes('guardmanage')){
      listItems = [
        { label: getText('monitoring_operator'), path: '/guardmanage/operator', type: manageConstants.OPERATOR },
        { label: getText('guard'), path: '/guardmanage/guard', type: manageConstants.GUARD }
      ];
    }
    return (
      <ManageList
        listItems={listItems}
        handleOnclick={this.handleOnclick}
        selectedType={selectedType}
        />
    );
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageListContainer);
