import { siteConstants } from 'src/redux/constants';
import { siteServices, robotServices } from 'src/services';
import { notificationActions } from '.';
import history from 'src/utils/history';
import { getText } from 'src/utils/MultilingualLoader';
import urlParse from 'url-parse'

export const getSites = () => {
  const request = () => {
    return {
      type: siteConstants.GET_SITE_REQUEST
    };
  };

  const success = list => {
    return {
      type: siteConstants.GET_SITE_SUCCESS,
      list: list,
    };
  };

  const failure = () => {
    return {
      type: siteConstants.GET_SITE_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    siteServices.getSites()
      .then(result => {
        dispatch(success(result.list));
      })
      .catch(error => {
        dispatch(failure());
      });
  };
};

export const createSite = data => {
  const request = () => {
    return {
      type: siteConstants.CREATE_SITE_REQUEST
    };
  };

  const success = list => {
    return {
      type: siteConstants.CREATE_SITE_SUCCESS,
    };
  };

  const failure = () => {
    return {
      type: siteConstants.CREATE_SITE_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    siteServices.createSite(data)
      .then(result => {
        dispatch(success());
        dispatch(notificationActions.addNotification(`${getText('add')}${getText('words_space')}${getText('site')}${getText('words_space')}${getText('succeeded')}`));
        history.push('/sitemanage/site');
      })
      .catch(error => {
        dispatch(failure());
        dispatch(notificationActions.addNotification(`${getText('add')}${getText('words_space')}${getText('site')}${getText('words_space')}${getText('failed')}`));
        history.push('/sitemanage/site');
      });
  };
};

export const deleteSite = id => {
  const request = () => {
    return {
      type: siteConstants.DELETE_SITE_REQUEST
    };
  };

  const success = list => {
    return {
      type: siteConstants.DELETE_SITE_SUCCESS,
    };
  };

  const failure = () => {
    return {
      type: siteConstants.DELETE_SITE_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    siteServices.deleteSite(id)
      .then(result => {
        dispatch(success());
        dispatch(notificationActions.addNotification(`${getText('delete')}${getText('words_space')}${getText('site')}${getText('words_space')}${getText('succeeded')}`));
        dispatch(getSites());
        history.push('/sitemanage/site');
      })
      .catch(error => {
        dispatch(failure());
        dispatch(notificationActions.addNotification(`${getText('delete')}${getText('words_space')}${getText('site')}${getText('words_space')}${getText('failed')}`));
        history.push('/sitemanage/site');
      });
  };
};

export const updateSite = data => {
  const request = () => {
    return {
      type: siteConstants.UPDATE_SITE_REQUEST
    };
  };

  const success = () => {
    return {
      type: siteConstants.UPDATE_SITE_SUCCESS,
    };
  };

  const failure = () => {
    return {
      type: siteConstants.UPDATE_SITE_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    siteServices.updateSite(data)
      .then(result => {
        dispatch(success());
        dispatch(notificationActions.addNotification(`${getText('update')}${getText('words_space')}${getText('site')}${getText('words_space')}${getText('succeeded')}`));
        dispatch(getSites());
        history.push('/sitemanage/site');
      })
      .catch(error => {
        dispatch(failure());
        dispatch(notificationActions.addNotification(`${getText('update')}${getText('words_space')}${getText('site')}${getText('words_space')}${getText('failed')}`));
        history.push('/sitemanage/site');
      });
  };
};

export const getManagers = (curPage, limit) => {
  const request = () => {
    return {
      type: siteConstants.GET_MANAGER_REQUEST
    };
  };

  const success = result => {
    return {
      type: siteConstants.GET_MANAGER_SUCCESS,
      list: result.list,
      curPage,
      count: result.count,
    };
  };

  const failure = () => {
    return {
      type: siteConstants.GET_MANAGER_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    siteServices.getManagers({offset: (curPage - 1) * (limit || 10), limit})
      .then(result => {
        dispatch(success(result));
      })
      .catch(error => {
        dispatch(failure());
      });
  };
};

export const createManager = data => {
  const request = () => {
    return {
      type: siteConstants.CREATE_MANAGER_REQUEST
    };
  };

  const success = list => {
    return {
      type: siteConstants.CREATE_MANAGER_SUCCESS,
    };
  };

  const failure = () => {
    return {
      type: siteConstants.CREATE_MANAGER_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    siteServices.createManager(data)
      .then(result => {
        dispatch(success());
        dispatch(notificationActions.addNotification(`${getText('add')}${getText('words_space')}${getText('site_manager')}${getText('words_space')}${getText('succeeded')}`));
        history.push('/sitemanage/manager');
      })
      .catch(error => {
        dispatch(failure());
        dispatch(notificationActions.addNotification(`${getText('add')}${getText('words_space')}${getText('site_manager')}${getText('words_space')}${getText('failed')}`));
        history.push('/sitemanage/manager');
      });
  };
};

export const deleteManager = id => {
  const request = () => {
    return {
      type: siteConstants.DELETE_MANAGER_REQUEST
    };
  };

  const success = list => {
    return {
      type: siteConstants.DELETE_MANAGER_SUCCESS,
    };
  };

  const failure = () => {
    return {
      type: siteConstants.DELETE_MANAGER_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    siteServices.deleteManager(id)
      .then(result => {
        dispatch(success());
        dispatch(notificationActions.addNotification(`${getText('delete')}${getText('words_space')}${getText('site_manager')}${getText('words_space')}${getText('succeeded')}`));
        dispatch(getManagers());
        history.push('/sitemanage/manager');
      })
      .catch(error => {
        dispatch(failure());
        dispatch(notificationActions.addNotification(`${getText('delete')}${getText('words_space')}${getText('site_manager')}${getText('words_space')}${getText('failed')}`));
        history.push('/sitemanage/manager');
      });
  };
};

export const assignManagers = data => {
  const request = () => {
    return {
      type: siteConstants.ASSIGN_MANAGERS_REQUEST
    };
  };

  const success = () => {
    return {
      type: siteConstants.ASSIGN_MANAGERS_SUCCESS
    };
  };

  const failure = () => {
    return {
      type: siteConstants.ASSIGN_MANAGERS_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    siteServices.assignManagers(data)
      .then(result => {
        dispatch(success());
        dispatch(notificationActions.addNotification(`${getText('assign')}${getText('words_space')}${getText('site_manager')}${getText('words_space')}${getText('succeeded')}`));
        dispatch(getSites())
      })
      .catch(error => {
        dispatch(failure());
        dispatch(notificationActions.addNotification(`${getText('assign')}${getText('words_space')}${getText('site_manager')}${getText('words_space')}${getText('failed')}`));
      });
  };
};

export const getSiteRobots = () => {
  const request = () => {
    return {
      type: siteConstants.GET_ROBOT_REQUEST
    };
  };

  const success = robots => {
    return {
      type: siteConstants.GET_ROBOT_SUCCESS,
      robots
    };
  };

  const failure = () => {
    return {
      type: siteConstants.GET_ROBOT_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    robotServices.getRobots()
      .then(robots => {
        dispatch(success(robots));
      })
      .catch(error => {
        dispatch(failure());
      });
  };
};

export const updateSiteRobot = data => {
  const request = () => {
    return {
      type: siteConstants.UPDATE_ROBOT_REQUEST
    };
  };

  const success = () => {
    return {
      type: siteConstants.UPDATE_ROBOT_SUCCESS,
    };
  };

  const failure = () => {
    return {
      type: siteConstants.UPDATE_ROBOT_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    siteServices.updateSiteRobot(data)
      .then(result => {
        dispatch(success());
        dispatch(notificationActions.addNotification(`${getText('assign')}${getText('words_space')}${getText('robot')}${getText('words_space')}${getText('succeeded')}`));
        dispatch(getSiteRobots());
        history.push('/sitemanage/robot');
      })
      .catch(error => {
        dispatch(failure());
        dispatch(notificationActions.addNotification(`${getText('assign')}${getText('words_space')}${getText('robot')}${getText('words_space')}${getText('failed')}`));
        history.push('/sitemanage/robot');
      });
  };
};

export const getSiteBoxes = () => {
  const request = () => {
    return {
      type: siteConstants.GET_BOX_REQUEST
    };
  };

  const success = list => {
    return {
      type: siteConstants.GET_BOX_SUCCESS,
      list
    };
  };

  const failure = () => {
    return {
      type: siteConstants.GET_BOX_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    siteServices.getSiteBoxes()
      .then(result => {
        dispatch(success(result.list));
      })
      .catch(error => {
        dispatch(failure());
      });
  };
};

export const updateSiteBox = data => {
  const request = () => {
    return {
      type: siteConstants.UPDATE_BOX_REQUEST
    };
  };

  const success = () => {
    return {
      type: siteConstants.UPDATE_BOX_SUCCESS,
    };
  };

  const failure = () => {
    return {
      type: siteConstants.UPDATE_BOX_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    siteServices.updateSiteBox(data)
      .then(result => {
        dispatch(success());
        dispatch(notificationActions.addNotification(`${getText('assign')}${getText('words_space')}${getText('box')}${getText('words_space')}${getText('succeeded')}`));
        dispatch(getSiteBoxes());
        history.push('/sitemanage/box');
      })
      .catch(error => {
        dispatch(failure());
        dispatch(notificationActions.addNotification(`${getText('assign')}${getText('words_space')}${getText('box')}${getText('words_space')}${getText('failed')}`));
        history.push('/sitemanage/box');
      });
  };
};

export const setCurSite = siteId => {
  return {
    type: siteConstants.SET_CUR_SITE,
    siteId
  }
}

export const addSiteToFilter = (filter, state) => {
  return {
    ...filter,
    siteId: state.site.curSite
  }
}

export const getSiteBuildings = (filter) => {
  const request = () => {
    return {
      type: siteConstants.GET_SITE_BUILDING_REQUEST
    };
  };

  const success = (list, total) => {
    return {
      type: siteConstants.GET_SITE_BUILDING_SUCCESS,
      filter,
      total: total || 0,
      list: list || [],
    };
  };

  const failure = () => {
    return {
      type: siteConstants.GET_SITE_BUILDING_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    siteServices.getSiteBuildings(filter)
      .then(result => {
        dispatch(success(result.list, result.count));
      })
      .catch(error => {
        dispatch(failure());
      });
  };
};

export const getSiteFloors = (filter = {}) => {
  const { building_id, site_id, ...otherParams } = filter;
  const request = () => {
    return {
      type: siteConstants.GET_SITE_FLOOR_REQUEST
    };
  };

  const success = list => {
    return {
      type: siteConstants.GET_SITE_FLOOR_SUCCESS,
      buildingId: building_id,
      list: list,
    };
  };

  const failure = () => {
    return {
      type: siteConstants.GET_SITE_FLOOR_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    siteServices.getSiteFloors({ ...otherParams, building: building_id, site: site_id, limit: 100 })
      .then(result => {
        dispatch(success(result.list));
      })
      .catch(error => {
        dispatch(failure());
      });
  };
};

export const getSiteAllFloors = (siteId) => {
  return dispatch => {
    let data = { site: siteId, limit: 50 }
    let allFloors = []
    const getPaginationFloor = () => {
      siteServices.getSiteFloors(data) //
      .then(result => {
        allFloors = [
          ...allFloors,
          ...(result.list || [])
        ]
        
        if(result.next) {
          let {query:{limit, offset, site}} = urlParse(result.next, true)
          data = {
            limit: parseInt(limit),
            offset: parseInt(offset),
            site: parseInt(site)
          }
          getPaginationFloor()
        } else {
          dispatch({
            type: siteConstants.SAVE_SITE_FLOORS,
            siteId,
            floors: allFloors,
          });
        }
      })
    }
  
    getPaginationFloor()
  };
}
