import React from 'react';
import { connect } from 'react-redux';

import { inspectionActions } from 'src/redux/actions';
import InspectionPagination from './InspectionPagination.js';

const mapStateToProps = state => {
  return {
    selectedRobot: state.inspection.selectedRobot,
    filters: state.inspection.filters
  }; 
};

const mapDispatchToProps = dispatch => {
  return {
    getMoreInspections: filters => {
      dispatch(inspectionActions.getMoreInspections(filters));
    },
    setPage: (robotId, page) => {
      dispatch(inspectionActions.setPage(robotId, page));
    },
  };
};

class InspectionPaginationContainer extends React.Component {
  handlePageClick = page => e => {
    const { lastPage, selectedRobot, filters, setPage, getMoreInspections, inspectionLoading } = this.props;
    if (page <= lastPage) {
      setPage(selectedRobot.id, page);
    }
    else if (!inspectionLoading) {
      getMoreInspections({ ...filters, robotId: selectedRobot.id, morePages: page - lastPage });
    }
  }

  render() {
    const { curPage, counts, lineSize } = this.props;
    const maxPage = Math.ceil(counts / (lineSize * 3));
    let pageOptions = [ curPage ];
    const left = Math.max(curPage - 2, 1)
    for (let i = curPage - 1; i >= left; i--)
      pageOptions.unshift(i);
    const right = Math.min(curPage + Math.max(5 - pageOptions.length, 0), maxPage);
    for (let i = curPage + 1; i <= right; i++)
      pageOptions.push(i);
    for (let i = left - 1; pageOptions.length < 5 && i > 0; i--)
      pageOptions.unshift(i);

    return (
      <InspectionPagination
        noPrev={curPage <= 1}
        noNext={curPage >= maxPage}
        curPage={curPage}
        pageOptions={pageOptions}
        onPageClick={this.handlePageClick}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionPaginationContainer);