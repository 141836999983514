import {useMemo, useState, useRef, useEffect} from 'react';
import {DebouncedFunc, debounce} from 'lodash';
import {useSelector} from 'react-redux';

// import {RootState} from 'src/redux/reducers';
// import {TrackingObjectOption} from 'src/models/employee';
import {getTrackingObjectOptions} from 'src/services/Vision/employee';

export function useTrackingObjectSearch(){
  const [trackingObjectOptions, setTrackingObjectOptions] = useState([]);
  const unmount = useRef(false);
  const trackingSettings = useSelector(
    (state) => state.trackingSetting.trackingSettings
  );
  const search = useMemo(
    () =>
      debounce(async (searchKey) => {
        if (unmount.current) return;
        const trackingObjectOptions = await getTrackingObjectOptions(
          searchKey,
          trackingSettings ? trackingSettings.identify_by : undefined
        );
        if (!unmount.current) {
          setTrackingObjectOptions(trackingObjectOptions);
        }
      }, 500),
    [trackingSettings ? trackingSettings.identify_by : undefined]
  );

  useEffect(() => {
    return () => {
      unmount.current = true;
    };
  }, []);
  return {trackingObjectOptions, search};
}
