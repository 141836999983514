import React from "react";
import { Grid } from "@material-ui/core";

import { getText } from "src/utils/MultilingualLoader";
import ExpansionPanel from "../ExpansionPanel";
import Tabs from "../Tabs";
import RoutineExecution from "../RobotRoutineExecution";
import RobotConfigRoutine from "../RobotConfigRoutine";
import RobotRoutineSchedule from "../RobotRoutineSchedule";
import RoutineList from "../RoutineList";

const RobotPatrol = ({ selected, configs, targets }) => {
  return (
    <ExpansionPanel title={getText("patrol")}>
      <Tabs>
        <Grid container title={getText("routine_execution")}>
          <RoutineExecution selected={selected} configs={configs} />
        </Grid>
        {/* <Grid container title={getText("routines")}>
          <RobotConfigRoutine
            selected={selected}
            configs={configs}
            targets={targets}
          />
        </Grid>
        <Grid container title={getText("routine_schedule")}>
          <RobotRoutineSchedule selected={selected} configs={configs} />
        </Grid> */}
        <Grid container title={getText("routines")}>
          <RoutineList
            selected={selected}
            configs={configs}
            targets={targets}
          />
        </Grid>
      </Tabs>
    </ExpansionPanel>
  );
};

export default RobotPatrol;
