import {
  // CameraState,
  SET_FILTER,
  CLEAR_FILTER,
  SET_CAMERA_LIST,
  UPDATE_CAMERA_LIST,
  SET_CAMERA_LOADING,
  CLEAR_CAMERA_LIST,
  SET_MIN_CAMERAS_MAPPING,
} from 'src/redux/types/Vision/camera';

const initState = {
  filter: {
    id: '',
    state: '',
    siteId: '',
    boxId: '',
  },
  siteId: '',
  loading: false,
  list: [],
  minCamerasMapping: {},
  pagination: {
    total: 0,
    current: 1,
    pageSize: 10,
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function deviceReducer(state = initState, action){
  switch (action.type) {
    case SET_MIN_CAMERAS_MAPPING:
      return {
        ...state,
        minCamerasMapping: {
          ...state.minCamerasMapping,
          ...(action.payload.minCamerasMapping || {}),
        },
      };
    case SET_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    }
    case SET_CAMERA_LOADING: {
      return {...state, loading: action.payload.loading};
    }
    case UPDATE_CAMERA_LIST: {
      return {...state, list: action.payload.list};
    }
    case SET_CAMERA_LIST: {
      return {
        ...state,
        list: action.payload.list,
        loading: action.payload.loading,
        pagination: action.payload.pagination,
      };
    }
    case CLEAR_FILTER: {
      return {
        ...state,
        filter: initState.filter,
      };
    }
    case CLEAR_CAMERA_LIST: {
      return {
        ...state,
        list: [],
      };
    }
    default:
      return state;
  }
}
