import {
  robotConstants
} from "src/redux/constants";

const initialState = {
  robots: [],
  loading: false,
  updating: false,
  robotConfigs: {},
  robotDetectionConfigs: {},
  robotAlgos: {},
  // current map from config server
  robotCurMaps: {},
  // list of maps with routes from config server
  robotMapLists: {},
  // backup maps from config server
  backupMapLists: {},
  filters: {
    robotStatuses: 'all',
    searchQuery: '',
  },
  selected: null,
  sortOrder: {
    sortColumn: 'status',
    name: 'asc',
    status: 'desc'
  },
  //robots of all sites without any filter
  allRobots: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case robotConstants.GET_ROBOT_REQUEST:
      return {
        ...state,
        loading: true
      };

    case robotConstants.GET_ROBOT_SUCCESS:
      /*
        action: {
        type: ...,
        robots: [<robot object>]
        }
      */
      sortRobots(state.sortOrder.sortColumn, state.sortOrder.status, action.robots);
      //update selected robot if exists
      if (state.selected) {
        let updSelected = action.robots.find(robot => robot.id === state.selected.id);
        if (updSelected) {
          state.selected = Object.assign(state.selected, updSelected);
        }
      }
      return {
        ...state,
        loading: false,
        selected: state.selected,
        robots: action.robots
      };

    case robotConstants.GET_ROBOT_FAILURE:
      return {
        ...state,
        loading: false
      }

    case robotConstants.GET_ALL_ROBOTS_SUCCESS:
      return {
        ...state,
        allRobots: action.robots
      }

    case robotConstants.ROBOT_SELECTED:
      /*
        action: {
          type: ...,
          robot: <robot object>
        }
        */
      return {
        ...state,
        selected: action.robot
      };

    case robotConstants.ROBOT_SELECTED_UPDATED:
      /*
        action: {
        type: ...,
        robot: <robot id>
        updated: <robot object>
        }
      */
      let updRobot = state.robots.find(robot => robot.id === action.robot);
      if (updRobot) {
        Object.assign(updRobot, action.updated)
      }
      return {
        ...state,
        selected: action.updated,
        robots: Object.assign([], state.robots)
      };

    case robotConstants.ROBOT_GET_CONFIG_MAP_REQUEST:
      return {
        ...state,
        robotCurMaps: {
          ...state.robotCurMaps,
          [action.robotId]: {
            loading: true
          }
        }
      };

    case robotConstants.ROBOT_GET_CONFIG_MAP_SUCCESS:
      return {
        ...state,
        robotCurMaps: {
          ...state.robotCurMaps,
          [action.robotId]: {
            loading: false,
            ...action.mapData
          }
        }
      };

    case robotConstants.ROBOT_GET_CONFIG_MAP_FAILURE:
      return {
        ...state,
        robotCurMaps: {
          ...state.robotCurMaps,
          [action.robotId]: {
            loading: false
          }
        }
      };

    case robotConstants.UPDATE_NAV:
      if (state.selected && state.selected.id === action.robotId) {
        state.selected = {
          ...state.selected,
          location: action.location ? {
            ...action.location
          } : null,
          signalStrength: action.signalStrength,
          navState: action.navState || state.selected.navState
        };
      }
      for (let idx in state.robots) {
        if (state.robots[idx].id === action.robotId) {
          state.robots[idx] = {
            ...state.robots[idx],
            location: action.location ? {
              ...action.location
            } : null,
            signalStrength: action.signalStrength,
            navState: action.navState || state.robots[idx].navState
          };
          break;
        }
      }
      return {
        ...state,
        selected: state.selected,
        robots: state.robots
      };

    case robotConstants.UPDATE_MODE:
      if (state.selected && state.selected.id === action.robotId) {
        state.selected = {
          ...state.selected,
          state_data: {
            ...state.selected.state_data,
            mode: action.mode
          }
        };
        for (let idx in state.robots) {
          if (state.robots[idx].id === action.robotId) {
            state.robots[idx] = {
              ...state.robots[idx],
              state_data: {
                ...state.robots[idx].state_data,
                mode: action.mode
              }
            };
            break;
          }
        }
      }
      return {
        ...state,
        selected: state.selected,
        robots: state.robots
      };

    case robotConstants.SET_FILTER:
      return {
        ...state,
        filters: Object.assign({}, state.filters, action.filters)
      };

    case robotConstants.SORT_ROBOTS:
      let newOrder = state.sortOrder[action.sortColumn] === 'desc' ? 'asc' : 'desc';
      sortRobots(action.sortColumn, newOrder, state.robots);
      return {
        ...state,
        robots: state.robots,
        sortOrder: {
          ...state.sortOrder,
          sortColumn: action.sortColumn,
          [action.sortColumn]: newOrder
        }
      };

    case robotConstants.ROBOT_GET_CONFIGS_REQUEST:
      return {
        ...state,
        robotConfigs: {
          ...state.robotConfigs,
          [action.robot]: {
            ...state.robotConfigs[action.robot],
            updating: true
          }
        }
      };

    case robotConstants.ROBOT_GET_CONFIGS_SUCCESS:
      return {
        ...state,
        robotConfigs: {
          ...state.robotConfigs,
          [action.robot]: {
            ...action.configs,
            updating: false
          }
        }
      };

    case robotConstants.ROBOT_GET_CONFIGS_FAILURE:
      return {
        ...state,
        robotConfigs: {
          ...state.robotConfigs,
          [action.robot]: {
            ...state.robotConfigs[action.robot],
            updating: false
          }
        }
      };
      
    case robotConstants.ROBOT_UPDATE_CONFIGS_REQUEST:
      return {
        ...state,
        robotConfigs: {
          ...state.robotConfigs,
          [action.robotId]: {
            ...state.robotConfigs[action.robotId],
            updating: true
          }
        }
      };

    case robotConstants.ROBOT_UPDATE_CONFIGS_SUCCESS:
      return {
        ...state,
        robotConfigs: {
          ...state.robotConfigs,
          [action.robotId]: {
            ...state.robotConfigs[action.robotId],
            ...action.configs,
            updating: false
          }
        }
      };

    case robotConstants.ROBOT_UPDATE_CONFIGS_FAILURE:
      return {
        ...state,
        robotConfigs: {
          ...state.robotConfigs,
          [action.robotId]: {
            ...state.robotConfigs[action.robotId],
            updating: false
          }
        }
      };

    case robotConstants.ROBOT_GET_DETECTION_CONFIGS_SUCCESS:
      return {
        ...state,
        robotDetectionConfigs: {
          ...state.robotDetectionConfigs,
          [action.robot]: {
            ...action.configs,
            updating: false
          }
        }
      };

    case robotConstants.ROBOT_UPDATE_DETECTION_CONFIGS_REQUEST:
      return {
        ...state,
        robotDetectionConfigs: {
          ...state.robotDetectionConfigs,
          [action.robot]: {
            ...state.robotDetectionConfigs[action.robot],
            updating: true
          }
        }
      };

    case robotConstants.ROBOT_UPDATE_DETECTION_CONFIGS_SUCCESS:
      return {
        ...state,
        robotDetectionConfigs: {
          ...state.robotDetectionConfigs,
          [action.robot]: {
            ...state.robotDetectionConfigs[action.robot],
            ...action.configs,
            updating: false
          }
        }
      };

    case robotConstants.ROBOT_UPDATE_DETECTION_CONFIGS_FAILURE:
      return {
        ...state,
        robotDetectionConfigs: {
          ...state.robotDetectionConfigs,
          [action.robot]: {
            ...state.robotDetectionConfigs[action.robot],
            updating: false
          }
        }
      };

    case robotConstants.ROBOT_GET_ALGOS_REQUEST:
      return {
        ...state,
        robotAlgos: {
          ...state.robotAlgos,
          [action.robot]: {
            ...state.robotAlgos[action.robot],
            loading: true
          }
        }
      };

    case robotConstants.ROBOT_GET_ALGOS_SUCCESS:
      return {
        ...state,
        robotAlgos: {
          ...state.robotAlgos,
          [action.robot]: {
            algos: action.algos,
            loading: false
          }
        }
      };

    case robotConstants.ROBOT_GET_ALGOS_FAILURE:
      return {
        ...state,
        robotAlgos: {
          ...state.robotAlgos,
          [action.robot]: {
            ...state.robotAlgos[action.robot],
            loading: false
          }
        }
      };

    case robotConstants.ROBOT_GET_MAPS_REQUEST:
      return {
        ...state,
        robotMapLists: {
          ...state.robotMapLists,
          [action.robot]: {
            ...state.robotMapLists[action.robot],
            loading: true
          }
        }
      };

    case robotConstants.ROBOT_GET_MAPS_SUCCESS:
      let maps = (action.maps && action.maps.sort(sortByMapIdTime)) || [];
      return {
        ...state,
        robotMapLists: {
          ...state.robotMapLists,
          [action.robot]: {
            maps,
            loading: false
          }
        }
      };

    case robotConstants.ROBOT_GET_MAPS_FAILURE:
      return {
        ...state,
        robotMapLists: {
          ...state.robotMapLists,
          [action.robot]: {
            ...state.robotMapLists[action.robot],
            loading: false
          }
        }
      };

    case robotConstants.ROBOT_UPDATE_CONFIG_MAP_REQUEST:
      let robotMap = state.robotCurMaps[action.robotId];
      let curMapUpdated = robotMap && robotMap.id === action.mapId;

      return curMapUpdated ? {
        ...state,
        robotCurMaps: {
          ...state.robotCurMaps,
          [action.robotId]: {
            updating: true,
            ...state.robotCurMaps[action.robotId]
          }
        },
        robotMapLists: {
          ...state.robotMapLists,
          [action.robotId]: {
            ...state.robotMapLists[action.robotId],
            updating: true
          }
        }
      } : {
          ...state,
          robotMapLists: {
            ...state.robotMapLists,
            [action.robotId]: {
              ...state.robotMapLists[action.robotId],
              updating: true
            }
          }
        };

    case robotConstants.ROBOT_UPDATE_CONFIG_MAP_SUCCESS:
      // update map response may not have route and should not override the original route info
      robotMap = state.robotCurMaps[action.robotId];
      curMapUpdated = action.mapData && robotMap && action.mapData.id === robotMap.id;
      if (curMapUpdated) {
        robotMap = Object.assign(robotMap, action.mapData);
      }
      let mapList = Object.assign([], state.robotMapLists[action.robotId] ? state.robotMapLists[action.robotId].maps : []);
      if (action.mapData && action.mapData.id) {
        let updMapIdx = mapList.findIndex(map => map.id === action.mapData.id);
        if (updMapIdx !== -1) {
          mapList[updMapIdx] = Object.assign(mapList[updMapIdx], action.mapData);
        }
        else mapList.push(action.mapData);
      }
      return curMapUpdated ? {
        ...state,
        robotCurMaps: {
          ...state.robotCurMaps,
          [action.robotId]: {
            updating: false,
            ...robotMap
          }
        },
        robotMapLists: {
          ...state.robotMapLists,
          [action.robotId]: {
            ...state.robotMapLists[action.robotId],
            updating: false,
            maps: mapList
          }
        }
      } : {
          ...state,
          robotMapLists: {
            ...state.robotMapLists,
            [action.robotId]: {
              ...state.robotMapLists[action.robotId],
              updating: false,
              maps: mapList
            }
          }
        };

    case robotConstants.ROBOT_UPDATE_CONFIG_MAP_FAILURE:
      robotMap = state.robotCurMaps[action.robotId];
      curMapUpdated = robotMap && robotMap.id === action.mapId;

      return curMapUpdated ? {
        ...state,
        robotCurMaps: {
          ...state.robotCurMaps,
          [action.robotId]: {
            updating: false,
            ...state.robotCurMaps[action.robotId]
          }
        },
        robotMapLists: {
          ...state.robotMapLists,
          [action.robotId]: {
            ...state.robotMapLists[action.robotId],
            updating: false
          }
        }
      } : {
          ...state,
          robotMapLists: {
            ...state.robotMapLists,
            [action.robotId]: {
              ...state.robotMapLists[action.robotId],
              updating: false
            }
          }
        };

    case robotConstants.DELETE_MAP_SUCCESS:
      mapList = Object.assign([], state.robotMapLists[action.robotId] ? state.robotMapLists[action.robotId].maps : []);
      let delMapIdx = mapList.findIndex(map => map.id === action.mapId);
      if (delMapIdx !== -1) {
        mapList.splice(delMapIdx, 1);
      }
      return {
        ...state,
        robotMapLists: {
          ...state.robotMapLists,
          [action.robotId]: {
            ...state.robotMapLists[action.robotId],
            maps: mapList
          }
        }
      };

    case robotConstants.COPY_MAP_SUCCESS:
      mapList = Object.assign([], state.robotMapLists[action.robotId] ? state.robotMapLists[action.robotId].maps : []);
      mapList.push(action.map);
      return {
        ...state,
        robotMapLists: {
          ...state.robotMapLists,
          [action.robotId]: {
            ...state.robotMapLists[action.robotId],
            maps: mapList
          }
        }
      }

    case robotConstants.UPDATE_ROUTE_SUCCESS:
      mapList = Object.assign([], state.robotMapLists[action.robotId] ? state.robotMapLists[action.robotId].maps : []);
      let updMapIdx = mapList.findIndex(map => map.id === action.mapId);
      if (updMapIdx !== -1) {
        let map = mapList[updMapIdx];
        let updRouteIdx = map.mapRoutes && map.mapRoutes.findIndex(route => route.id === action.routeId);
        if (updRouteIdx !== -1) {
          map.mapRoutes[updRouteIdx] = action.route;
        }
        else {
          map.mapRoutes.push(action.route);
        }
      }
      return {
        ...state,
        robotMapLists: {
          ...state.robotMapLists,
          [action.robotId]: {
            ...state.robotMapLists[action.robotId],
            maps: mapList
          }
        }
      };

    case robotConstants.DELETE_ROUTE_SUCCESS:
      mapList = Object.assign([], state.robotMapLists[action.robotId] ? state.robotMapLists[action.robotId].maps : []);
      updMapIdx = mapList.findIndex(map => map.id === action.mapId);
      if (updMapIdx !== -1) {
        let map = mapList[updMapIdx];
        let delRouteIdx = map.mapRoutes && map.mapRoutes.findIndex(route => route.id === action.routeId);
        if (delRouteIdx !== -1) {
          map.mapRoutes.splice(delRouteIdx, 1);
        }
      }
      return {
        ...state,
        robotMapLists: {
          ...state.robotMapLists,
          [action.robotId]: {
            ...state.robotMapLists[action.robotId],
            maps: mapList
          }
        }
      };

    case robotConstants.COPY_ROUTE_SUCCESS:
      mapList = Object.assign([], state.robotMapLists[action.robotId] ? state.robotMapLists[action.robotId].maps : []);
      updMapIdx = mapList.findIndex(map => map.id === action.mapId);
      if (updMapIdx !== -1) {
        let map = mapList[updMapIdx];
        if (!map.mapRoutes) map.mapRoutes = [];
        map.mapRoutes.push(action.route);
      }
      return {
        ...state,
        robotMapLists: {
          ...state.robotMapLists,
          [action.robotId]: {
            ...state.robotMapLists[action.robotId],
            maps: mapList
          }
        }
      };

    case robotConstants.GET_BACKUP_MAPS_REQUEST:
      return {
        ...state,
        backupMapLists: {
          ...state.backupMapLists,
          [action.userId]: {
            ...state.backupMapLists[action.userId],
            loading: true
          }
        }
      };

    case robotConstants.GET_BACKUP_MAPS_SUCCESS:
      maps = (action.mapList && action.mapList.sort(sortByMapIdTime)) || [];
      return {
        ...state,
        backupMapLists: {
          ...state.backupMapLists,
          [action.userId]: {
            maps,
            loading: false
          }
        }
      };

    case robotConstants.GET_BACKUP_MAPS_FAILURE:
      return {
        ...state,
        backupMapLists: {
          ...state.backupMapLists,
          [action.userId]: {
            ...state.backupMapLists[action.userId],
            loading: false
          }
        }
      };

    case robotConstants.GET_BACKUP_ROUTES_SUCCESS:
      let backupMaps = Object.assign([], state.backupMapLists[action.userId] ? state.backupMapLists[action.userId].maps : []);
      let backupMap = backupMaps.find(map => map.id === action.mapId);
      if (backupMap) {
        backupMap.mapRoutes = action.routes;
      }
      return {
        ...state,
        backupMapLists: {
          ...state.backupMapLists,
          [action.userId]: {
            ...state.backupMapLists[action.userId],
            maps: backupMaps
          }
        }
      };

    case robotConstants.UPDATE_ROBOTS_FROM_WS:
      let updRobots = Object.assign([], state.robots);
      action.robots.forEach(robot => {
        let updRobotIdx = updRobots.findIndex(_robot => _robot.id === robot.id);
        if (updRobotIdx !== -1) {
          updRobots[updRobotIdx] = robot;
        }
        if (state.selected && state.selected.id === robot.id) {
          state.selected = Object.assign(state.selected, robot);
        }
      })

      return {
        ...state,
        selected: state.selected,
        robots: updRobots
      }

    case robotConstants.RESTORE_MAP_SUCCESS:
      mapList = Object.assign([], state.robotMapLists[action.robotId] ? state.robotMapLists[action.robotId].maps : []);
      let restoreMapIdx = mapList.findIndex(map => map.id === (action.map && action.map.id));
      // add restored map to map list if not existed
      if (restoreMapIdx === -1) {
        mapList.push(action.map);
      }
      return {
        ...state,
        robotMapLists: {
          ...state.robotMapLists,
          [action.robotId]: {
            ...state.robotMapLists[action.robotId],
            maps: mapList
          }
        }
      };

    case robotConstants.DELETE_BACKUP_MAP_SUCCESS:
      mapList = Object.assign([], state.backupMapLists[action.userId] ? state.backupMapLists[action.userId].maps : []);
      delMapIdx = mapList.findIndex(map => map.id === action.mapId);
      if (delMapIdx !== -1) {
        mapList.splice(delMapIdx, 1);
      }
      return {
        ...state,
        backupMapLists: {
          ...state.backupMapLists,
          [action.userId]: {
            ...state.backupMapLists[action.userId],
            maps: mapList
          }
        }
      };

    case robotConstants.DELETE_BACKUP_ROUTE_SUCCESS:
      mapList = Object.assign([], state.backupMapLists[action.userId] ? state.backupMapLists[action.userId].maps : []);
      updMapIdx = mapList.findIndex(map => map.id === action.mapId);
      if (updMapIdx !== -1) {
        let map = mapList[updMapIdx];
        let delRouteIdx = map.mapRoutes && map.mapRoutes.findIndex(route => route.id === action.routeId);
        if (delRouteIdx !== -1) {
          map.mapRoutes.splice(delRouteIdx, 1);
        }
      }
      return {
        ...state,
        backupMapLists: {
          ...state.backupMapLists,
          [action.userId]: {
            ...state.backupMapLists[action.userId],
            maps: mapList
          }
        }
      };
    
    case robotConstants.UPDATE_BACKUP_MAP_SUCCESS:
      mapList = Object.assign([], state.backupMapLists[action.userId] ? state.backupMapLists[action.userId].maps : []);
      let backupMapIdx = mapList.findIndex(map => map.id === (action.map && action.map.id));
      if (backupMapIdx !== -1) {
        mapList[backupMapIdx] = Object.assign(mapList[backupMapIdx], action.map);
      }
      return {
        ...state,
        backupMapLists: {
          ...state.backupMapLists,
          [action.userId]: {
            ...state.backupMapLists[action.userId],
            maps: mapList
          }
        }
      };

    case robotConstants.UPDATE_BACKUP_ROUTE_SUCCESS:
      mapList = Object.assign([], state.backupMapLists[action.userId] ? state.backupMapLists[action.userId].maps : []);
      updMapIdx = mapList.findIndex(map => map.id === action.mapId);
      if (updMapIdx !== -1) {
        let map = mapList[updMapIdx];
        let updRouteIdx = map.mapRoutes && map.mapRoutes.findIndex(route => route.id === action.routeId);
        if (updRouteIdx !== -1) {
          map.mapRoutes[updRouteIdx] = action.route;
        }
        else {
          map.mapRoutes.push(action.route);
        }
      }
      return {
        ...state,
        backupMapLists: {
          ...state.backupMapLists,
          [action.userId]: {
            ...state.backupMapLists[action.userId],
            maps: mapList
          }
        }
      };

    default:
      return state;
  }
};

const sortRobots = (column, order, robots) => {
  switch (column) {
    case 'name':
      return order === 'asc' ? robots.sort(sortByName) : robots.sort(sortByName).reverse();
    case 'status':
      return order === 'desc' ? robots.sort(sortByStatus) : robots.sort(sortByStatus).reverse();
    default:
      return robots;
  }
}

const sortByName = (a, b) => {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();
  if (nameA < nameB) {
    return -1;
  }
  else if (nameA > nameB) {
    return 1;
  }
  else {
    return 0;
  }
}

const sortByStatus = (a, b) => {
  if (a.status === b.status) {
    return sortByName(a, b);
  }
  return a.status === 'online' ? -1 : 1;
}

const sortByMapIdTime = (a, b) => {
  if (!a.id) {
    return 1;
  } 
  else if (!b.id) {
    return -1;
  }
  // get unix timestamp from mapId
  const timeA = a.id.slice(-13);
  const timeB = b.id.slice(-13);
  return timeA > timeB ? -1 : 1;
}