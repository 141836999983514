import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@material-ui/core";

import DropDownSelectorHorizontal from "src/components/Selectors/DropDownSelectorHorizontal";
import { getText } from "src/utils/MultilingualLoader";

const nextActionOptions = [
  {
    label: getText("stay"),
    value: "stay",
  },
  {
    label: getText("go_home"),
    value: "go_home",
  },
  {
    label: getText("recover"),
    value: "recover",
  },
];

const RoutineNextAction = ({ onConfirm, onCancel }) => {
  const [nextAction, setNextAction] = useState("go_home");

  return (
    <Dialog onClose={onCancel} open={true} maxWidth="xs" fullWidth={true}>
      <DialogTitle>
        <Typography>{getText("set_next_action")}</Typography>
      </DialogTitle>
      <DialogContent>
        <DropDownSelectorHorizontal
          title={getText("routine_next_action")}
          options={nextActionOptions}
          value={nextAction}
          onChange={(e) => setNextAction(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          {getText("cancel")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onConfirm(nextAction)}
        >
          {getText("ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoutineNextAction;
