import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    height: 40,
    width: '100%'
  },
  input: {
    height: '100%',
    paddingLeft: 0
  }
});

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    const { initialQuery } = props;
    this.state = {
      searchQuery: initialQuery,
      timer: null
    };
  }

  handleQueryChange = (event) => {
    const that = this;
    const value = event.target.value;
    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }
    that.setState({
      searchQuery: value,
      timer: setTimeout(() => {
        that.setState({
          timer: null
        }, () => {
          that.props.onChangeSearchQuery(value);
        });
      }, 500)
    });
  }

  submitQuery = () => {
    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }
    this.props.onChangeSearchQuery(this.state.searchQuery);
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.submitQuery();
    }
  }

  render() {
    const { classes, filterChild } = this.props;
    let inputProps = {
      className: classes.input,
      startAdornment: (
        <InputAdornment
          position="start"
          >
          <IconButton
            onClick={this.submitQuery}
            >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      ),
    }
    if (filterChild){
      inputProps.endAdornment = (
        <InputAdornment position="end">
          {filterChild}
        </InputAdornment>
      );
    }
    return (
      <Toolbar disableGutters className={classes.root} variant="dense">
        <TextField
          id="search-filter-bar"
          className={classes.textField}
          variant="outlined"
          onChange={this.handleQueryChange}
          onKeyPress={this.handleKeyPress}
          value={this.state.searchQuery}
          InputProps={inputProps}
          placeholder={getText('search')}
        />
      </Toolbar>
    );
  }
};

SearchBar.propTypes = {
  initialQuery: PropTypes.string,
  filterChild: PropTypes.any,
  onChangeSearchQuery: PropTypes.func
};

SearchBar.defaultProps = {
  initialQuery: "",
  onChangeSearchQuery: () => {}
};

export default withStyles(styles)(SearchBar);
