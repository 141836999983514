import * as services from 'src/services/Vision/licensePlateBlacklist';
import {SET_LICENSE_PLATE_BLACKLIST} from 'src/redux/types/Vision/licensePlateBlacklist';
// import {LicensePlateBlacklist, LicensePlateBlacklistFilter} from 'src/models/licensePlateBlacklist';
import store from 'src/redux/store';

const {dispatch, getState} = store;

export async function getLicensePlateBlacklist(params, forceRefresh = false){
  const currentLicensePlateBlacklist = getState().licensePlateBlacklist.licensePlateBlacklist;
  if (
    !forceRefresh &&
    (params.site
      ? currentLicensePlateBlacklist[params.site]
      : Object.keys(currentLicensePlateBlacklist).length > 0)
  ) {
    return;
  }

  try {
    let page = 0;
    const limit = 100;
    let all = [];
    while (true) {
      let res = await services.getLicensePlateBlacklist({...params, page, limit});
      res = res.data.ret;
      all = all.concat(res.results || []);
      if (res.count <= (page + 1) * limit) {
        break;
      }
      page += 1;
    }

    const serialized = {};
    all.forEach(v => {
      serialized[v.site_id] = serialized[v.site_id] || [];
      serialized[v.site_id].push(v);
    });
    dispatch({
      type: SET_LICENSE_PLATE_BLACKLIST,
      payload: {
        licensePlateBlacklist: params.site
          ? {...currentLicensePlateBlacklist, ...serialized}
          : serialized,
      },
    });
  } catch (error) {
    return;
  }
}
