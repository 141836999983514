/* eslint-disable @typescript-eslint/naming-convention */
import React, {useRef, useEffect, useReducer} from 'react';
import {Grid} from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

export const VirtualRender = React.memo(
  // eslint-disable-next-line react/prop-types
  ({virtualLength, cellRender, style, ...props}) => {
    const wrap = useRef(null);
    const memorized = useRef({
      topHeight: 0,
      bottomHeight: 0,
      measureResultMap: new Map(),
      //InfiniteScroll in this project all has no scrollableTarget and height props
      //so take data on document.documentElement
      //if InfiniteScroll use scrollableTarget and height props,this will be error
      targetElement: document.documentElement,
      offsetY: 0,
      wrapElement: null,
      scrollHeight: 0,
    });
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const currnetMemorized = memorized.current;
    const measureResultMap = currnetMemorized.measureResultMap;

    const measureResultSetter = (measureResult, index) => {
      if (measureResultMap.get(index)) return;
      measureResultMap.set(index, measureResult);
    };

    const onScroll = () => {
      forceUpdate();
    };

    useEffect(() => {
      const wrapElement = (wrap.current).parentElement;

      memorized.current.wrapElement = wrapElement;
      memorized.current.offsetY =
        (wrapElement).getBoundingClientRect().y +
        currnetMemorized.targetElement.scrollTop;
      forceUpdate();
    }, []);

    useEffect(() => {
      memorized.current.scrollHeight = memorized.current.wrapElement?memorized.current.scrollHeight : 0;
    }, [virtualLength]);

    const virtualList = [];
    const measureList = [];

    if (currnetMemorized.wrapElement) {
      const scrollTop = currnetMemorized.targetElement.scrollTop;
      const clientHeight = currnetMemorized.targetElement.clientHeight;
      const offsetY = currnetMemorized.offsetY;
      const scrollHeight = currnetMemorized.scrollHeight;

      currnetMemorized.topHeight = 0;
      currnetMemorized.bottomHeight = 0;

      for (let index = 0; index < virtualLength; index++) {
        const size = measureResultMap.get(index);
        if (size) {
          const y = size.y + offsetY;
          if (y + size.height - scrollTop + clientHeight * 20 < 0) {
            currnetMemorized.topHeight = Math.max(size.y + size.height, currnetMemorized.topHeight);
            //Invisible
            continue;
          }
          if (y - scrollTop > clientHeight * 20) {
            if (!currnetMemorized.bottomHeight) {
              currnetMemorized.bottomHeight = scrollHeight - size.y;
            }
            //Invisible
            continue;
          }
          virtualList.push(cellRender(index));
          continue;
        }
        measureList.push(
          <MeasureRender
            key={`measure-render-${index}`}
            item={cellRender(index)}
            index={index}
            measureResultSetter={measureResultSetter}
          />
        );
      }
    }

    return (
      <InfiniteScroll
        {...props}
        style={Object.assign(style || {}, {position: 'relative'})}
        onScroll={onScroll}
        hasChildren={true}
      >
        <Grid container spacing={2} ref={wrap}>
          <Grid
            style={{
              width: '100%',
              height: currnetMemorized.topHeight + 'px',
            }}
            container
          />
          {virtualList}
          <Grid
            style={{
              width: '100%',
              height: currnetMemorized.bottomHeight + 'px',
            }}
            container
          />
          {measureList}
        </Grid>
      </InfiniteScroll>
    );
  }
);

const MeasureRender = ({item, index, measureResultSetter}) => {
  const itemRef = useRef(null);
  useEffect(() => {
    const dom = itemRef.current;
    measureResultSetter(
      {
        y: dom.offsetTop,
        height: dom.offsetHeight,
      },
      index
    );
  }, [index, measureResultSetter]);
  return React.cloneElement(item, {ref: itemRef});
};
