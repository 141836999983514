import { event_api } from 'src/services/Vision/api';
// import {TrackingSettings} from 'src/models/trackingSetting';

export async function getTrackingSettings() {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL || ''}/face/settings`;
    const response = await event_api.get(url);
    return response.data.ret;
  } catch (e) {
    throw e;
  }
}