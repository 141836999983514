import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  buttonContainer: {
    height: '100%'
  },
  button: {
    background: 'rgba(76,127,114,0.40)',
    borderRadius: '6px',
    border: '1px solid',
    width: '100%',
    height: '100%'
  },
  button_disabled: {
    background: '#CCCCCC',
    borderRadius: '6px',
    border: '1px solid',
    width: '100%',
    height: '100%'
  },
  label: {
    fontSize: '0.875rem',
    textTransform: 'initial',
  },
  buttonIcon: {
    minWidth: 0,
    marginRight: 10
  },
  tooltip: {
    fontSize: '0.875rem',
  }
});

const ActionBox = props => {
  const { classes, actions, onClick } = props;
  return (
    <Grid
      container={true}
      justify="flex-start"
      direction="row"
      alignItems="stretch"
      spacing={3}
    >
      {
        actions.map((action, index) => (
          <Grid item={true} xs={4} lg={2} key={index}>
            <Tooltip
              placement="top"
              enterDelay={200}
              disableHoverListener={action.tooltip === undefined}
              disableFocusListener={action.tooltip === undefined}
              disableTouchListener={action.tooltip === undefined}
              title={action.tooltip || ''}
              classes={{ tooltip: classes.tooltip }}
            >
              <div className={classes.buttonContainer}>
                <Button
                  className={!action.disabled ? classes.button : classes.button_disabled}
                  onClick={onClick(index)}
                  disabled={action.disabled ? true : false}
                >
                  <ListItemIcon className={classes.buttonIcon}>
                    {action.icon}
                  </ListItemIcon>
                  <Typography
                    variant="body2"
                    className={classes.label}
                  >
                    {action.label}
                  </Typography>
                </Button>
              </div>
            </Tooltip>
          </Grid>
        ))
      }
    </Grid>
  );
};

ActionBox.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      cols: PropTypes.number,
      name: PropTypes.string,
      label: PropTypes.string,
    }).isRequired),
};

ActionBox.defaultProps = {
  actions: [],
  onClick: index => () => { }
};

export default withStyles(styles)(ActionBox);
