import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { inspectionActions, robotActions, sensorActions } from 'src/redux/actions';
import RobotList from "./RobotList.js";

// reuse redux models for inspections
const mapStateToProps = state => {
  const { robots, selectedRobot, robotLoading } = state.inspection;
  const { robotCurMaps, robotConfigs, robotMapLists } = state.robot;
  const { curSite } = state.site;
  const { filters: sensorfilters } = state.sensor;
  const configs = selectedRobot ? robotConfigs[selectedRobot.id] : null;
  return { robots, selectedRobot, robotLoading, robotCurMaps, configs, curSite, sensorfilters, robotMapLists };
};

const mapDispatchToProps = dispatch => {
  return {
    getRobots: () => dispatch(inspectionActions.getRobots()),
    selectRobot: robot => dispatch(inspectionActions.selectRobot(robot)),
    unselectRobot: () => dispatch(inspectionActions.selectRobot(null)),
    getSensorClusters: filters => dispatch(sensorActions.getSensorClusters(filters)),
    getSensorPoints: filters => dispatch(sensorActions.getSensorPoints(filters)),
    setFilter: filters => dispatch(sensorActions.setFilter(filters)),
    getRobotConfigs: robotId => {
      dispatch(robotActions.getRobotConfigs(robotId));
    },
    getRobotConfigMap: (robotId, mapId) => {
      dispatch(robotActions.getRobotConfigMap(robotId, mapId));
    },
    getRobotMaps: robotId => {
      dispatch(robotActions.getRobotConfigMapsAndRoutes(robotId));
    },
  };
};

class RobotListContainer extends React.Component {
  componentDidMount() {
    const { robotLoading, getRobots } = this.props;
    if (!robotLoading) getRobots();
  }

  componentDidUpdate(prevProps) {
    const { curSite, robotLoading, getRobots, unselectRobot, sensorfilters, setFilter, getSensorClusters, getSensorPoints } = this.props;
    if (this.props.configs && this.props.configs.current_map_id && 
      (!prevProps.configs || prevProps.configs.current_map_id !== this.props.configs.current_map_id)) {
        this.getMap();
        setFilter({mapId: this.props.configs.current_map_id});
        getSensorClusters({...sensorfilters, mapId: this.props.configs.current_map_id});
        getSensorPoints({...sensorfilters, mapId: this.props.configs.current_map_id});
    }

    if (curSite && (!prevProps.curSite || curSite !== prevProps.curSite) && !robotLoading) {
      getRobots();
      unselectRobot();
    }
  }

  componentWillUnmount() {
    this.props.unselectRobot();
  }

  getMap() {
    const { selectedRobot, configs, getRobotConfigMap } = this.props;
    if (selectedRobot && configs && configs.current_map_id) {
      getRobotConfigMap(selectedRobot.id, configs.current_map_id);
    }
  }

  handleRobotSelect = robot => () => {
    const { selectRobot, setFilter, robotCurMaps, robotMapLists, 
      getRobotConfigs, getRobotMaps } = this.props;
    const defaultFilter = {
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      radius: 1,
      robotId: robot.id,
      mapId: '',
    };
    selectRobot(robot);
    getRobotConfigs(robot.id);
    setFilter(defaultFilter);
    const robotMap = robotCurMaps[robot.id];
    if (!robotMap || !robotMap.loading) this.getMap();
    const robotMapList = robotMapLists[robot.id];
    if (!robotMapList || !robotMapList.loading) getRobotMaps(robot.id);
  }
  
  render() {
    let robots = this.props.robots || [];

    return (
      <RobotList
        selectedRobot={this.props.selectedRobot}
        robots={robots}
        handleRobotSelect={this.handleRobotSelect}
      />
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RobotListContainer);
