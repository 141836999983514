import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    margin: '12px 0'
  },
  detail: {
    flexDirection: 'column',
    justify: 'flex-start',
    alignItems: 'flex-start'
  },
  sentence: {
    paddingLeft: '2rem',
  },
  buttonDiv: {
    marginTop: '1rem',
    width: '100%'
  },
  button: {
    display: 'block',
    background: 'rgba(76,127,114,0.75)',
    borderRadius: '4px',
    float: 'right'
  },
  buttonText: {
    color: '#FFFFFF',
    textTransform: 'initial',
  }
});

const CaseClose = (props) => {
    const { classes, closeCase } = props;
    return (
      <ExpansionPanel className={classes.root}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography variant="body1">
            {getText('close_case')}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.detail}>
          <Typography variant="body1" className={classes.sentence}>
            {getText('close_case_text')}
          </Typography>
          <div className={classes.buttonDiv}>
            <Button
              className={classes.button}
              onClick={closeCase}
            >
              <Typography variant="body2" className={classes.buttonText}>
                {getText('close_case')}
              </Typography>
            </Button>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
};

CaseClose.propTypes = {
  closeCase: PropTypes.func.isRequired,
};

CaseClose.defaultProps = {
  closeCase: () => () => {},
};

export default withStyles(styles)(CaseClose);
