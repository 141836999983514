import React from 'react';
import {Tooltip, ListItem, Typography} from '@material-ui/core';
// import {SelectOptionType} from 'turing-ui';

export default function renderCameraOption(option, index, selected, onChange,) {
  return option.tooltip ? (
    <Tooltip
      key={index}
      arrow
      placement="bottom"
      title={<Typography style={{width: 140, fontSize: 12}}>{option.tooltip}</Typography>}
    >
      <ListItem
        selected={selected === option.value}
        key={index}
        onClick={() => onChange(option)}
        button
      >
        {option.label}
      </ListItem>
    </Tooltip>
  ) : (
    <ListItem
      selected={selected === option.value}
      key={index}
      onClick={() => onChange(option)}
      button
    >
      {option.label}
    </ListItem>
  );
}
