import React from "react";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import * as _ from "lodash";

import { robotActions } from "src/redux/actions";
import RobotPatrolConfig from "./RobotPatrolConfig";
import { getText } from "src/utils/MultilingualLoader";
import OnOffSwitch from "src/components/Switches/OnOffSwitch";

const mapStateToProps = (state, props) => {
  const { selected } = props;
  return {
    robot: selected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRobotConfigs: (robot_id, updConfigs) => {
      dispatch(robotActions.updateRobotConfigs(robot_id, updConfigs));
    },
  };
};

const configRange = {
  patrol_friction: [0.5, 2],
  patrol_max_side_offset: [0.01, 0.3],
  elevator_linear_speed: [0.2, 2],
  elevator_obstacle_padding: [0.3, 0.6],
  elevator_max_side_offset: [0.01, 0.3],
  blocking_linear_speed: [0.2, 2],
  blocking_obstacle_padding: [0.3, 0.6],
  blocking_max_side_offset: [0.01, 0.3],
  patrol_min_visible_distance: [2.0, 10.0],
  elevator_min_visible_distance: [2.0, 5.0],
};

class RobotPatrolConfigContainer extends React.Component {
  constructor(props) {
    super(props);
    const { configs } = props;
    this.state = {
      configs: this.fromPropConfigs(configs),
    };
  }

  componentDidUpdate(prevProps) {
    const curConfigs = Object.assign({}, this.props.configs);
    const prevConfigs = Object.assign({}, prevProps.configs);
    delete curConfigs.updating;
    delete prevConfigs.updating;
    const { configs: stateConfigs } = this.state;
    const robotChanged =
      this.props.selected &&
      prevProps.selected &&
      this.props.selected.id !== prevProps.selected.id;
    if (robotChanged || !_.isEqual(curConfigs, prevConfigs)) {
      const newStateConfigs = robotChanged
        ? this.fromPropConfigs(curConfigs)
        : Object.assign({}, stateConfigs);
      // update configs in state. only update changed configs.
      for (const prop in stateConfigs) {
        // changed field is not array
        if (prop === "depth_camera_serial_numbers1") {
          if (
            curConfigs["depth_camera_serial_numbers"] &&
            curConfigs["depth_camera_serial_numbers"].length > 0
          ) {
            newStateConfigs["depth_camera_serial_numbers1"] =
              curConfigs["depth_camera_serial_numbers"][0];
          } else {
            newStateConfigs["depth_camera_serial_numbers1"] = "";
          }
        } else if (prop === "depth_camera_serial_numbers2") {
          if (
            curConfigs["depth_camera_serial_numbers"] &&
            curConfigs["depth_camera_serial_numbers"].length > 1
          ) {
            newStateConfigs["depth_camera_serial_numbers2"] =
              curConfigs["depth_camera_serial_numbers"][1];
          } else {
            newStateConfigs["depth_camera_serial_numbers2"] = "";
          }
        } else if (
          curConfigs[prop] !== undefined &&
          !Array.isArray(curConfigs[prop]) &&
          (curConfigs[prop] !== prevConfigs[prop] ||
            curConfigs[prop] !== stateConfigs[prop])
        ) {
          switch (prop) {
            case "routine_execution_check_time":
              newStateConfigs[prop] = this.minutesToString(curConfigs[prop]);
              break;
            case "patrol_schedule_start_time":
            case "patrol_schedule_end_time":
              break;
            case "sensor_types":
              break;
            default:
              newStateConfigs[prop] = curConfigs[prop];
              break;
          }
        }
        // changed field is array
        else if (
          curConfigs[prop] !== undefined &&
          Array.isArray(curConfigs[prop]) &&
          Array.isArray(prevConfigs[prop])
        ) {
          let changed = false;
          for (const idx in curConfigs[prop]) {
            if (curConfigs[prop][idx] !== prevConfigs[prop][idx]) {
              changed = true;
              break;
            }
          }
          if (changed) {
            switch (prop) {
              default:
                newStateConfigs[prop] = curConfigs[prop];
                break;
            }
          }
        }
        // complete all fields for a new config state
        else if (robotChanged || Object.keys(prevConfigs).length === 0) {
          newStateConfigs[prop] = curConfigs[prop] || null;
        }
      }
      this.setState({
        configs: newStateConfigs,
      });
    }
  }

  fromPropConfigs = (configs) => {
    const routine_execution_check_time =
      configs &&
      configs.routine_execution_check_time &&
      this.minutesToString(configs.routine_execution_check_time);
    return {
      patrol_linear_speed: configs && configs.patrol_linear_speed,
      patrol_obstacle_padding: configs && configs.patrol_obstacle_padding,
      elevator_obstacle_padding: configs && configs.elevator_obstacle_padding,
      patrol_friction: configs && configs.patrol_friction,
      patrol_max_side_offset: configs && configs.patrol_max_side_offset,
      elevator_linear_speed: configs && configs.elevator_linear_speed,
      elevator_max_side_offset: configs && configs.elevator_max_side_offset,
      blocking_linear_speed: configs && configs.blocking_linear_speed,
      blocking_obstacle_padding: configs && configs.blocking_obstacle_padding,
      blocking_max_side_offset: configs && configs.blocking_max_side_offset,
      patrol_min_visible_distance:
        configs && configs.patrol_min_visible_distance,
      elevator_min_visible_distance:
        configs && configs.elevator_min_visible_distance,
      auto_charge_enabled: configs && configs.auto_charge_enabled,
      routine_execution_check_time,
      charging_base_id: configs && configs.charging_base_id,
      depth_camera_serial_numbers1:
        configs &&
        configs.depth_camera_serial_numbers &&
        configs.depth_camera_serial_numbers.length > 0 &&
        configs.depth_camera_serial_numbers[0],
      depth_camera_serial_numbers2:
        configs &&
        configs.depth_camera_serial_numbers &&
        configs.depth_camera_serial_numbers.length > 1 &&
        configs.depth_camera_serial_numbers[1],
    };
  };

  handleChange = (field, type) => (event) => {
    let value = event.target.value;
    if (type === "Integer") value = parseInt(value);
    this.setState({
      configs: {
        ...this.state.configs,
        [field]: value,
      },
    });
  };

  handleSwitch = (field) => (event) => {
    this.setState({
      configs: {
        ...this.state.configs,
        [field]: event.target.checked,
      },
    });
  };

  isInRange(newConfigs) {
    for (let key in configRange) {
      if (newConfigs[key]) {
        if (newConfigs[key] < configRange[key][0]) {
          alert(
            `${getText(key).replace(/\[.+\]/, "")}${getText("words_space")}${
              newConfigs[key]
            }${getText("words_space")}${getText("input_error_below_lowest")}`
          );
          return false;
        }
        if (newConfigs[key] > configRange[key][1]) {
          alert(
            `${getText(key).replace(/\[.+\]/, "")}${getText("words_space")}${
              newConfigs[key]
            }${getText("words_space")}${getText("input_error_above_highest")}`
          );
          return false;
        }
      }
    }

    if (
      newConfigs.blocking_obstacle_padding < newConfigs.patrol_obstacle_padding
    ) {
      alert(
        `${getText("blocking_obstacle_padding").replace(
          /\[.*\]/g,
          ""
        )}${getText("words_space")}${getText("not_less_than")}${getText(
          "words_space"
        )}${getText("patrol_obstacle_padding")}`
      );
      return false;
    } else if (
      newConfigs.blocking_max_side_offset > newConfigs.patrol_max_side_offset
    ) {
      alert(
        `${getText("blocking_max_side_offset").replace(/\[.*\]/g, "")}${getText(
          "words_space"
        )}${getText("not_greater_than")}${getText("words_space")}${getText(
          "patrol_max_side_offset"
        ).replace(/\[.*\]/g, "")}`
      );
      return false;
    } else if (
      newConfigs.blocking_linear_speed > newConfigs.patrol_linear_speed
    ) {
      alert(
        `${getText("blocking_linear_speed").replace(/\[.*\]/g, "")}${getText(
          "words_space"
        )}${getText("not_greater_than")}${getText("words_space")}${getText(
          "patrol_linear_speed"
        ).replace(/\[.*\]/g, "")}`
      );
      return false;
    }
    return true;
  }

  minutesToString = (totalMinutes) => {
    let hours = Math.floor(totalMinutes / 60).toString();
    let minutes = (totalMinutes % 60).toString();
    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;
    return `${hours}:${minutes}`;
  };

  stringToMinutes = (timeString) => {
    let time = timeString.split(":");
    if (time.length < 2 || time[0] === "" || time[1] === "") {
      return null;
    }
    let minutes = parseInt(time[0]) * 60 + parseInt(time[1]);
    return minutes;
  };

  handleSubmit = () => {
    const updConfigs = Object.assign({}, this.state.configs);
    if (updConfigs.routine_execution_check_time) {
      updConfigs.routine_execution_check_time = this.stringToMinutes(
        updConfigs.routine_execution_check_time
      );
    }
    if (updConfigs.patrol_linear_speed) {
      updConfigs.patrol_linear_speed = parseFloat(
        updConfigs.patrol_linear_speed
      );
    }
    if (updConfigs.patrol_obstacle_padding) {
      updConfigs.patrol_obstacle_padding = parseFloat(
        updConfigs.patrol_obstacle_padding
      );
    }
    if (updConfigs.elevator_obstacle_padding) {
      updConfigs.elevator_obstacle_padding = parseFloat(
        updConfigs.elevator_obstacle_padding
      );
    }
    for (let key in configRange) {
      if (updConfigs[key]) {
        updConfigs[key] = Number(updConfigs[key]);
      }
    }
    if (!this.isInRange(updConfigs)) {
      return;
    }
    if (
      this.props.configs &&
      !this.props.configs.updating &&
      this.props.selected
    ) {
      this.props.updateRobotConfigs(this.props.selected.id, {
        ...updConfigs,
        charging_base_id: Number(updConfigs.charging_base_id),
        depth_camera_serial_numbers: [
          updConfigs.depth_camera_serial_numbers1 || null,
          updConfigs.depth_camera_serial_numbers2 || null,
        ],
      });
    }
  };

  getConfigs = (stateConfigs) => [
    {
      title: getText("patrol_linear_speed"),
      content: (
        <input
          type="number"
          step={0.1}
          style={{ fontSize: "1rem" }}
          value={stateConfigs["patrol_linear_speed"] || ""}
          onChange={this.handleChange("patrol_linear_speed", "string")}
        />
      ),
    },
    {
      title: getText("patrol_obstacle_padding"),
      content: (
        <input
          type="number"
          step={0.1}
          style={{ fontSize: "1rem" }}
          value={stateConfigs["patrol_obstacle_padding"] || ""}
          onChange={this.handleChange("patrol_obstacle_padding", "string")}
        />
      ),
    },
    {
      title: getText("patrol_friction"),
      content: (
        <input
          type="number"
          step={0.1}
          style={{ fontSize: "1rem" }}
          value={stateConfigs["patrol_friction"] || ""}
          onChange={this.handleChange("patrol_friction", "number")}
        />
      ),
    },
    {
      title: getText("patrol_max_side_offset"),
      content: (
        <input
          type="number"
          step={0.1}
          style={{ fontSize: "1rem" }}
          value={stateConfigs["patrol_max_side_offset"] || ""}
          onChange={this.handleChange("patrol_max_side_offset", "number")}
        />
      ),
    },
    {
      title: getText("elevator_obstacle_padding"),
      content: (
        <input
          type="number"
          step={0.1}
          style={{ fontSize: "1rem" }}
          value={stateConfigs["elevator_obstacle_padding"] || ""}
          onChange={this.handleChange("elevator_obstacle_padding", "number")}
        />
      ),
    },
    {
      title: getText("elevator_linear_speed"),
      content: (
        <input
          type="number"
          step={0.1}
          style={{ fontSize: "1rem" }}
          value={stateConfigs["elevator_linear_speed"] || ""}
          onChange={this.handleChange("elevator_linear_speed", "number")}
        />
      ),
    },
    {
      title: getText("elevator_max_side_offset"),
      content: (
        <input
          type="number"
          step={0.1}
          style={{ fontSize: "1rem" }}
          value={stateConfigs["elevator_max_side_offset"] || ""}
          onChange={this.handleChange("elevator_max_side_offset", "number")}
        />
      ),
    },
    {
      title: getText("blocking_linear_speed"),
      content: (
        <input
          type="number"
          step={0.1}
          style={{ fontSize: "1rem" }}
          value={stateConfigs["blocking_linear_speed"] || ""}
          onChange={this.handleChange("blocking_linear_speed", "number")}
        />
      ),
    },
    {
      title: getText("blocking_obstacle_padding"),
      content: (
        <input
          type="number"
          step={0.1}
          style={{ fontSize: "1rem" }}
          value={stateConfigs["blocking_obstacle_padding"] || ""}
          onChange={this.handleChange("blocking_obstacle_padding", "number")}
        />
      ),
    },
    {
      title: getText("blocking_max_side_offset"),
      content: (
        <input
          type="number"
          step={0.1}
          style={{ fontSize: "1rem" }}
          value={stateConfigs["blocking_max_side_offset"] || ""}
          onChange={this.handleChange("blocking_max_side_offset", "number")}
        />
      ),
    },
    {
      title: getText("patrol_min_visible_distance"),
      content: (
        <input
          type="number"
          step={0.1}
          style={{ fontSize: "1rem" }}
          value={stateConfigs["patrol_min_visible_distance"] || ""}
          onChange={this.handleChange("patrol_min_visible_distance", "number")}
        />
      ),
    },
    {
      title: getText("elevator_min_visible_distance"),
      content: (
        <input
          type="number"
          step={0.1}
          style={{ fontSize: "1rem" }}
          value={stateConfigs["elevator_min_visible_distance"] || ""}
          onChange={this.handleChange(
            "elevator_min_visible_distance",
            "number"
          )}
        />
      ),
    },
    {
      title: getText("auto_charge_enabled"),
      content: (
        <OnOffSwitch
          value={
            stateConfigs["auto_charge_enabled"]
              ? stateConfigs["auto_charge_enabled"]
              : false
          }
          onChange={this.handleSwitch("auto_charge_enabled")}
        />
      ),
    },
    {
      title: getText("routine_execution_check_time"),
      content: (
        <TextField
          type="time"
          style={{ fontSize: "1rem" }}
          InputLabelProps={{ shrink: true }}
          inputProps={{ step: 60 }}
          value={stateConfigs["routine_execution_check_time"] || ""}
          onChange={this.handleChange("routine_execution_check_time", "string")}
        />
      ),
    },
    {
      title: getText("charging_base_id"),
      content: (
        <input
          type="number"
          step={1}
          style={{ fontSize: "1rem" }}
          value={stateConfigs["charging_base_id"] || ""}
          onChange={this.handleChange("charging_base_id", "number")}
        />
      ),
    },
    {
      title: getText("depth_camera_serial_numbers") + 1,
      content: (
        <input
          style={{ fontSize: "1rem" }}
          value={stateConfigs["depth_camera_serial_numbers1"] || ""}
          onChange={this.handleChange("depth_camera_serial_numbers1", "number")}
        />
      ),
    },
    {
      title: getText("depth_camera_serial_numbers") + 2,
      content: (
        <input
          style={{ fontSize: "1rem" }}
          value={stateConfigs["depth_camera_serial_numbers2"] || ""}
          onChange={this.handleChange("depth_camera_serial_numbers2", "number")}
        />
      ),
    },
  ];

  render() {
    const { configs: stateConfigs } = this.state;
    let configs = this.getConfigs(stateConfigs);
    return <RobotPatrolConfig configs={configs} onSubmit={this.handleSubmit} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RobotPatrolConfigContainer);
