import React from "react";
import { Stage, Layer, Image } from "react-konva";

import MapLocations from "./MapLocations";
import RobotLocation from "./RobotLocation";
import RobotRelocalization from "./RobotRelocalization";
import VirtualWall from "./VirtualWall";
import MapLegend from "./MapLegend";

class RobotMapStage extends React.Component {
  render() {
    const {
      mapWidth,
      mapHeight,
      image,
      scale,
      mapName,
      lidarType,
      configRoute,
      chargingBase,
      switchPose,
      getImagePosition,
      cameraLocations,
      landmarks,
      robotLocation,
      position,
      action,
      virtualWallPoints,
      relocalizationPoint,
      elevatorBase,
      curRelocalizationPosition,
      newVirtualWallPolygons,
      appendingVirtualWallPolygon,
      onMapClick,
    } = this.props;
    const sizedPosition = {
      x: position && position.x / scale,
      y: position && position.y / scale,
    };

    return (
      <Stage
        onClick={() => onMapClick(sizedPosition)}
        height={mapHeight}
        width={mapWidth}
        x={0}
        y={0}
      >
        <Layer>
          <Image
            x={0}
            y={0}
            image={image}
            height={mapHeight}
            width={mapWidth}
          />
        </Layer>
        <MapLocations
          configRoute={configRoute}
          cameraLocations={cameraLocations}
          chargingBase={chargingBase}
          landmarks={landmarks}
          switchPose={switchPose}
          elevatorBase={elevatorBase}
          getImagePosition={getImagePosition}
          virtualWall={
            ![
              "virtual_wall",
              "virtual_wall_append",
              "virtual_wall_delete",
            ].includes(action)
              ? virtualWallPoints
              : []
          }
          scale={scale}
        />
        <RobotLocation
          robotLocation={robotLocation}
          getPosition={getImagePosition}
          scale={scale}
        />
        {action === "relocalize" && (
          <RobotRelocalization
            position={sizedPosition}
            curRelocalizationPosition={curRelocalizationPosition}
            relocalizationPoint={relocalizationPoint}
            getImagePosition={getImagePosition}
            scale={scale}
          />
        )}
        {[
          "virtual_wall",
          "virtual_wall_append",
          "virtual_wall_delete",
        ].includes(action) && (
          <VirtualWall
            position={sizedPosition}
            newVirtualWallPolygons={newVirtualWallPolygons}
            appendingVirtualWallPolygon={appendingVirtualWallPolygon}
            getImagePosition={getImagePosition}
            scale={scale}
          />
        )}
        <MapLegend
          lidarType={lidarType}
          mapName={mapName}
          routeName={configRoute && configRoute.name}
        />
      </Stage>
    );
  }
}

export default RobotMapStage;
