import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { mapActions } from 'src/redux/actions'
import RobotList from "src/components/robot-list";
import MapsPanel from "./MapsPanel";
import SiteMap from '../SiteMap';

export const sidePanelWidth = 200;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto'
  },
  robotList: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: sidePanelWidth,
    height: '100%',
    overflow: 'auto'
  },
  mapPanel: {
    width: `calc(100% - ${sidePanelWidth}px)`,
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flex: 1,
    height: '100%',
    overflow: 'auto'
  },
});

const Maps = props => {
  const { classes } = props;
  const { robots, selectedRobotId } = useSelector(state => state.map);
  const { curSite } = useSelector(state => state.site);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(mapActions.getRobots(curSite));
  }, [curSite]);

  const handleSelect = robotId  => () => {
    dispatch(mapActions.selectRobot(robotId));
    dispatch(mapActions.getMaps(robotId));
  };

  if (!curSite) {
    return (<SiteMap />)
  }
  return (
    <Grid
      className={classes.root}
      direction="row"
      container={true}
      justify="flex-start"
      alignItems="flex-start"
      spacing={0}
      >
      <Grid className={classes.robotList} item={true}>
        <RobotList
          robots={robots}
          selectedId={selectedRobotId}
          onSelect={handleSelect}
        />
      </Grid>
      <Grid className={classes.mapPanel} item={true}>
        {selectedRobotId && <MapsPanel />}
      </Grid>
    </Grid>
  );
};  

export default withStyles(styles)(Maps);