import React, {useState} from 'react';
import {Grid, makeStyles} from '@material-ui/core';

// import EditModal from 'src/pages/employees/EditModal';
// import {TrackingTarget} from 'src/models/employee';
// import {ClipFilter} from 'src/redux/types/clip';
import {useDetailContext} from '../../../hook';
import TableCard from 'src/pages/Vision/employees/TableCard';
// import {useFlagTrackingObject} from 'src/pages/employees/Table/hook';

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    width: 'auto',
  },
  wrap: {
    marginTop: theme.spacing(3),
  },
  trackingObject: {
    height: theme.spacing(7.5),
    width: theme.spacing(7.5),
    position: 'relative',
    '&>img': {
      borderRadius: theme.spacing(1),
      width: '100%',
    },
  },
  employee: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    background: theme.palette.primary.contrastText,
    fontSize: '16px',
    borderRadius: '0 4px 0 0',
  },
  moreVert: {
    color: '#9e9e9e',
    borderRadius: theme.spacing(0.5),
  },
  moreVertActive: {
    background: '#f5f5f5',
    color: theme.palette.primary.main,
  },
  operateWrap: {
    borderRadius: theme.spacing(0.5),
    overflow: 'hidden',
  },
  operateButton: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textTransform: 'none',
    width: '100%',
    justifyContent: 'flex-start',
  },
  pointer: {
    cursor: 'pointer',
  },
  removed: {
    fontStyle: 'italic',
    color: (theme.palette).other.secondary,
  },
  info: {
    color: (theme.palette).other.secondary,
    marginLeft: theme.spacing(0.5),
  },
}));

export function TrackingObjectsComponent() {
  const classes = useStyles();
  const {detail} = useDetailContext();
  const trackingObjects = detail.event.tracking_objects || [];
  return (
    <Grid container classes={{container: classes.wrap}}>
      {trackingObjects.map(trackingObject => {
        return <TrackObjectComponent trackingObject={trackingObject} key={trackingObject.id} />;
      })}
    </Grid>
  );
}


function TrackObjectComponent({trackingObject}) {
  const classes = useStyles();
  if (!trackingObject) return null;
  return (
    <Grid item alignItems="center" container classes={{item: classes.flex}}>
      <Grid item container>
        <TrackObjectOperateComponent trackingObject={trackingObject} />
      </Grid>
    </Grid>
  );
}

function TrackObjectOperateComponent({trackingObject}) {
  // const {refresh, isSharing} = useDetailContext();
  // const [showMode, setShowMode] = useState(false);
  // const linkToEvent = useLinkToEvent();
  // const {onFlag} = useFlagTrackingObject();
  // const search = () => {
  //   linkToEvent({face: trackingObject});
  // };
  // const closeEdit = (hasChanged) => {
  //   setShowMode(false);
  //   hasChanged && refresh && refresh();
  // };
  // const handleFlag = async () => {
  //   await onFlag(trackingObject);
  //   refresh && refresh();
  // };
  return (
    <>
      <TableCard
        row={trackingObject}
        // onView={() => setShowMode(true)}
        // onLinkToClips={search}
        disableHover={true}
        // onFlag={handleFlag}
      />
      {/* {showMode && <EditModal employee={trackingObject} onClose={closeEdit} />} */}
    </>
  );
}
