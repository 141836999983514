import React from 'react';
import {Grid} from '@material-ui/core';

import CommonBody from 'src/components/Vision/CommonBody';
import {useDetailContext, useIsAlert} from '../../hook';
import {AlertInfoComponent} from './alertInfo';
import {CommentComponent} from './comment';
import {DetailMediaComponent} from './media';
import {DetailAnalyzeComponent} from './analyze';

export function DetailContentComponent() {
  const isAlert = useIsAlert();
  const {detail} = useDetailContext();
  return (
    <CommonBody>
      <Grid container direction="column">
        <DetailMediaComponent detail={detail} />
        <DetailAnalyzeComponent />
        <CommentComponent key={`${detail.id}comment`} />
        {isAlert && <AlertInfoComponent key={`${detail.id}alert`} />}
      </Grid>
    </CommonBody>
  );
}
