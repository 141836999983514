import {
  createStore,
  applyMiddleware
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import refreshTokenMiddleware from '../utils/refreshTokenMiddleware';
import rootReducer from 'src/redux/reducers';

let middleware = [ refreshTokenMiddleware, thunkMiddleware ];

/* eslint-disable no-underscore-dangle */
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
/* eslint-enable */

export default store;
