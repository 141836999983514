import React from 'react';

import {
  withStyles
} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  tableGrid: {
    height: '100%',
    overflow: 'auto'
  },
  table: {
  },
  header: {
    fontSize: '1rem'
  },
  row: {
    fontSize: '1rem',
    '@media (max-width: 1900px)': {
      fontSize: '0.8rem'
    }
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)'
  },
  iconButton: {
    '&:hover': {
      cursor: 'pointer',
    }
  },
});

const tableHeaders = [
  'id',
  'username',
  'last_name',
  'first_name',
  'organization_id',
  'organization_title',
  'phone_number',
  ' '
];

const GuardAdminList = props => {
  const { classes, data, onClickAdd, selectedType, onClickDelete } = props;
  return (
    <Grid
      container={true}
      justify="flex-start"
      direction="row"
      alignItems="stretch"
      spacing={0}
    >
      <Grid item={true} xs={12}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onClickAdd(selectedType)}
        >
          {getText('add_guard_admin')}
        </Button>
      </Grid>
      <Grid item={true} xs={12} className={classes.tableGrid}>
        <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header, index) => (<TableCell key={index} className={classes.header}>{getText(header)}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data ? data.map((guardAdmin, index) => (
              <TableRow key={guardAdmin.id}>
                <TableCell className={classes.row}>{guardAdmin.id}</TableCell>
                <TableCell className={classes.row}>{guardAdmin.username}</TableCell>
                <TableCell className={classes.row}>{guardAdmin.last_name}</TableCell>
                <TableCell className={classes.row}>{guardAdmin.first_name}</TableCell>
                <TableCell className={classes.row}>{guardAdmin.organizationId}</TableCell>
                <TableCell className={classes.row}>{guardAdmin.organizationTitle}</TableCell>
                <TableCell className={classes.row}>{guardAdmin.phone_number}</TableCell>
                <TableCell className={classes.row}>
                  <DeleteIcon className={classes.iconButton} onClick={onClickDelete(selectedType, guardAdmin.id)} />
                </TableCell>
              </TableRow>
          )) : null}
        </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(GuardAdminList);
