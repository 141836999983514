import React from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import { AddCircle as AddIcon } from "@material-ui/icons";
import * as _ from "lodash";

import { robotActions } from "src/redux/actions";
import { getText } from "src/utils/MultilingualLoader";
import ScheduleConfig from "./ScheduleConfig";
import styles from "./Schedule.style";

const mapDispatchToProps = {
  updateRobotConfigs: (robot_id, updConfigs) =>
    robotActions.updateRobotConfigs(robot_id, updConfigs),
};

class RobotConfigContainer extends React.Component {
  constructor(props) {
    super(props);
    const { configs } = props;
    this.state = {
      schedules: (configs && configs.routine_schedules) || [],
      selectedScheduleIdx: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.configs) {
      const robotChanged =
        this.props.selected &&
        (!prevProps.selected ||
          this.props.selected.id !== prevProps.selected.id);
      if (
        robotChanged ||
        !_.isEqual(
          this.props.configs.routine_schedules,
          prevProps.configs && prevProps.configs.routine_schedules
        )
      ) {
        this.setState({
          schedules: this.props.configs.routine_schedules || [],
          selectedScheduleIdx: null,
        });
      }
    }
  }

  cancelCreateSchedule = () => {
    this.setState({
      selectedScheduleIdx: null,
    });
  };

  deleteSchedule = () => {
    const { selected, updateRobotConfigs } = this.props;
    const { selectedScheduleIdx, schedules } = this.state;
    const newSchedules = [...schedules];
    if (
      !window.confirm(
        `${getText("delete_routine_schedule")} ${
          schedules[selectedScheduleIdx] &&
          schedules[selectedScheduleIdx].routine_id
        }`
      )
    )
      return;
    newSchedules.splice(selectedScheduleIdx, 1);
    updateRobotConfigs(selected.id, {
      routine_schedules: newSchedules,
    });
    this.setState({
      schedules: newSchedules,
      selectedScheduleIdx: null,
    });
  };

  submitSchedule = (data) => {
    const { selected, updateRobotConfigs } = this.props;
    const { schedules, selectedScheduleIdx } = this.state;
    const newSchedules = [...schedules];
    if (selectedScheduleIdx === -1) {
      if (schedules.some((v) => v.name === data.name)) {
        window.alert(getText("routine_schedule_same_name_error"));
        return;
      }
      newSchedules.push(data);
    } else {
      newSchedules[selectedScheduleIdx] = data;
    }
    updateRobotConfigs(selected.id, {
      routine_schedules: newSchedules,
    });
    this.setState({
      schedules: newSchedules,
      selectedScheduleIdx: null,
    });
  };

  render() {
    const { classes, configs } = this.props;
    const { selectedScheduleIdx, schedules } = this.state;
    const routines = (configs && configs.routines) || [];

    return (
      <>
        <form className={classes.form}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCell} padding="default">
                  <Typography variant="body2">
                    {getText("routine_schedule")}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} padding="default">
                  <Select
                    variant="outlined"
                    className={classes.middle}
                    input={
                      <OutlinedInput
                        labelWidth={0}
                        classes={{ input: classes.selectorInput }}
                      />
                    }
                    value={
                      selectedScheduleIdx !== null ? selectedScheduleIdx : ""
                    }
                    onChange={(event) =>
                      this.setState({ selectedScheduleIdx: event.target.value })
                    }
                    disabled={selectedScheduleIdx === -1}
                  >
                    {schedules
                      .map((schedule, index) => ({
                        label: `${schedule.name}`,
                        value: index,
                      }))
                      .map((option, index) => (
                        <MenuItem
                          key={index}
                          className={classes.menuItem}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                  </Select>
                  {selectedScheduleIdx !== -1 && (
                    <IconButton
                      className={classes.IconButton}
                      onClick={() => {
                        this.setState({ selectedScheduleIdx: -1 });
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
              {selectedScheduleIdx !== null && (
                <ScheduleConfig
                  routines={routines}
                  schedule={
                    selectedScheduleIdx === -1
                      ? undefined
                      : schedules[selectedScheduleIdx]
                  }
                  onCancel={this.cancelCreateSchedule}
                  onDelete={this.deleteSchedule}
                  onSubmit={this.submitSchedule}
                />
              )}
            </TableBody>
          </Table>
        </form>
      </>
    );
  }
}

export default connect(
  undefined,
  mapDispatchToProps
)(withStyles(styles)(RobotConfigContainer));
