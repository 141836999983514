import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import InspectionList from './InspectionList';
import LoadingText from 'src/components/LoadingText';
import { getText } from 'src/utils/MultilingualLoader';
import { caseActions, inspectionActions } from 'src/redux/actions';

const mapStateToProps = (state, props) => {
  const { allInspections, filters, eventTypes } = state.inspection;
  const { curSite } = state.site;
  const robotInspections = allInspections[props.selectedRobot.id];
  const caseTypes = state.case.caseTypes[props.selectedRobot.id];
  return { robotInspections, filters, caseTypes, curSite, eventTypes };
};

const mapDispatchToProps = dispatch => {
  return {
    updateInspection: (robotId, inspectionId, data) => dispatch(inspectionActions.updateInspection(robotId, inspectionId, data)),
    createCase: (robotId, inspectionId, data) => dispatch(inspectionActions.createCaseFromInspection(robotId, inspectionId, data)),
    getCaseTypes: robotId => dispatch(caseActions.getCaseTypes(robotId))
  };
};

class InspectionListContainer extends React.Component {
  createCase = id => {
    this.props.createCase(this.props.selectedRobot.id, id, {inspection_id: id})
  }

  updateInspectionAlgos = (inspectionId, algos) => {
    this.props.updateInspection(this.props.selectedRobot.id, inspectionId, {algo_types: Object.keys(algos).filter(algo => algos[algo]).join(',')});
  }

  updateInspectionResults = (inspectionId, results) => {
    this.props.updateInspection(this.props.selectedRobot.id, inspectionId, {analysis_results: Object.keys(results).filter(item => results[item]).join(',')});
  }

  componentDidUpdate() {
    const {caseTypes} = this.props;
    if (!caseTypes || (!caseTypes.types && !caseTypes.loading)) this.props.getCaseTypes(this.props.selectedRobot.id);
  }

  render() {
    const { robotInspections, filters, caseTypes, curSite, eventTypes } = this.props;
    if (!robotInspections || robotInspections.loading) return (<LoadingText />);
    const { inspections, curPage, lastPage, counts, loading } = robotInspections;
    const inspections_paged = inspections ? inspections.slice((curPage - 1) * filters.lineSize * 3, curPage * filters.lineSize * 3) : [];
    return (
      <>
        {
          inspections_paged.length !== 0 ?
            <InspectionList
              inspections={inspections_paged}
              lineSize={filters.lineSize}
              curPage={curPage}
              lastPage={lastPage}
              counts={counts}
              inspectionLoading={loading}
              caseTypes={caseTypes && caseTypes.types}
              eventTypes={eventTypes}
              curSite={curSite}
              createCase={this.createCase}
              updateInspectionAlgos={this.updateInspectionAlgos}
              updateInspectionResults={this.updateInspectionResults}
            />
            : <Typography variant='body1'>{getText('no_inspection')}</Typography>
        }
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionListContainer);
