import React from 'react';
import { connect } from 'react-redux';

import { siteActions, caseActions } from 'src/redux/actions';
import SiteBar from './SiteBar';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  return {
    sites: state.site.sites,
    curSite: state.site.curSite,
    siteLoading: state.site.siteLoading,
    caseLoading: state.case.loading,
    filters: state.case.filters
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSites: () => dispatch(siteActions.getSites()),
    setCurSite: siteId => dispatch(siteActions.setCurSite(siteId)),
    getCases: filter => dispatch(caseActions.getCases(filter))
  };
};

class SiteBarContainer extends React.Component {
  componentDidMount() {
    const { siteLoading, getSites } = this.props;
    if (!siteLoading) getSites();
  }

  componentDidUpdate(prevProps) {
    //auto select other when there is no site
    if (prevProps.sites === null && this.props.sites && this.props.sites.length === 0) this.props.setCurSite(-1);
  }

  handleSiteChange = event => {
    const { setCurSite } = this.props;
    setCurSite(event.target.value);
  }

  handleSiteReset = event => {
    this.props.setCurSite(null);
  }

  render() {
    const { curSite, sites, siteLoading } = this.props;
    let siteOptions = sites ? sites.map(site => ({value: site.id, label: site.name})) : [];
    if (!siteLoading) siteOptions.push({value: -1, label: getText('others')});
    return (
      <SiteBar
        siteOptions={siteOptions}
        curSite={curSite || ''}
        onSiteChange={this.handleSiteChange}
        onSiteReset={this.handleSiteReset}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteBarContainer);
