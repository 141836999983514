import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, Button, FormControl, Input, InputLabel, 
  Card, CardHeader, CardContent, Select, MenuItem } from '@material-ui/core';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  card: {
    width: '50%',
    marginTop: theme.spacing(1),
    margin: 'auto'
  },
  submit: {
    marginTop: theme.spacing(3),
    maxWidth: 100,
    float: 'right',
    marginBottom: 24
  },
  label: {
    color: 'white',
    textTransform: 'initial'
  },
});

const AddSiteManager = ({
  classes,
  username,
  first_name,
  last_name,
  phone_number,
  levels,
  level,
  error,
  onSubmit,
  onChange,
}) => (
    <Card className={classes.card}>
      <CardHeader
        title={getText('add_site_manager')}
      />
      <CardContent>
        <form>
          <FormControl margin="normal" required fullWidth error={error.title}>
            <InputLabel htmlFor="username">{getText('email')}</InputLabel>
            <Input
              id="username"
              name="username"
              autoFocus={true}
              value={username}
              onChange={onChange('username')}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="first_name">{getText('first_name')}</InputLabel>
            <Input
              id="first_name"
              name="first_name"
              value={first_name}
              onChange={onChange('first_name')}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="last_name">{getText('last_name')}</InputLabel>
            <Input
              id="last_name"
              name="last_name"
              value={last_name}
              onChange={onChange('last_name')}
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="level">{getText('level')}</InputLabel>
            <Select
              id="organization"
              name="organization"
              value={level}
              onChange={onChange('level')}
            >

              {levels.map(level =>
                <MenuItem key={level} value={level}>{level}</MenuItem>
              )}

            </Select>
          </FormControl>
          <FormControl margin="normal" required fullWidth error={error.phone_number}>
            <InputLabel htmlFor="phone_number">{getText('phone_number')}</InputLabel>
            <Input
              id="phone_number"
              name="phone_number"
              value={phone_number}
              onChange={onChange('phone_number')}
            />
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            fullWidth={true}
            className={classes.submit}
            onClick={onSubmit}
          >
            <Typography variant="body2" className={classes.label}>
              {getText('submit')}
            </Typography>
          </Button>
        </form>
      </CardContent>
    </Card>
  );

AddSiteManager.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddSiteManager);
