import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Grid, Toolbar, TextField, InputAdornment, GridList, GridListTile, GridListTileBar
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import LazyLoad from 'react-lazy-load';

import CircularLoading from 'src/components/circular-loading';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  const { selectedRobotId, maps } = state.map;
  const robotMaps = (selectedRobotId && maps[selectedRobotId] && maps[selectedRobotId].maps) || [];
  const mapLoading = (selectedRobotId && maps[selectedRobotId] && maps[selectedRobotId].loading) || false;

  return { selectedRobotId, robotMaps, mapLoading };
};

const mapDispatchToProps = {
};

const styles = theme => ({
  gridListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  listTile: {
    width: '100% !important',
    maxWidth: 200,
    padding: '0 !important',
    margin: 2,
    '&:hover': {
      cursor: 'pointer',
    }
  },
  listTileSelected: {
    width: '100% !important',
    maxWidth: 200,
    padding: '0 !important',
    margin: 2,
    border: '4px solid',
    borderColor: theme.palette.primary.main
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  img: {
    padding: '0 !important'
  }
});

class MapsPanel extends React.Component {
  state = {
    searchMapName: ''
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedRobotId !== this.props.selectedRobotId) {
      this.setState({
        curMap: null,
        curAction: null
      });
    }
  }

  filterMapsByName = name => {
    this.setState({
      searchMapName: name
    });
  }

  render() {
    const { classes, robotMaps, mapLoading, curMap, setCurMap } = this.props;
    const { searchMapName } = this.state;

    const showedRobotMaps = searchMapName ? robotMaps.filter(map => 
      map.name && map.name.toLowerCase().includes(searchMapName.toLowerCase())) : robotMaps;

    return (
      <Grid
        item
        container
        direction="column"
      >
        <Grid item>
          <Toolbar disableGutters variant="dense">
            <TextField
              className={classes.textField}
              variant="outlined"
              onChange={event => {
                this.filterMapsByName(event.target.value)
              }}
              value={searchMapName}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              //TODO: use style override in classes
              inputProps={{ style: { padding: 6 } }}
              placeholder={getText('map_name')}
            />
          </Toolbar>
        </Grid>
        <Grid item>
          {
            !mapLoading ?
              <div className={classes.gridListContainer}>
                <GridList className={classes.gridList} cellHeight={150} cols={6}>
                  {showedRobotMaps.map(map => {
                    const selected = curMap && map.id === curMap.id;
                    return (
                      <GridListTile 
                        key={map.id}
                        className={selected ? classes.listTileSelected : classes.listTile}
                        onClick={() => setCurMap(map)}
                      >
                        {/* <LazyLoad height={300} offsetHorizontal={350}> */}
                          <img className={classes.img} src={map.mapUrl} alt={map.name} />
                        {/* </LazyLoad> */}
                        <GridListTileBar
                          titlePosition="top"
                          title={map.name}
                          classes={{
                            root: classes.titleBar,
                            title: classes.title,
                          }}
                        />
                      </GridListTile>
                  )})}
                </GridList>
              </div>
              : <CircularLoading />
          }
        </Grid>
      </Grid>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapsPanel));