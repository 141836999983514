import { robotServices } from 'src/services';
import { mapConstants } from '../constants';
import { notificationActions } from '.';
import { getText } from '../../utils/MultilingualLoader';

export const getRobots = (siteId) => {
  const success = robots => {
    return {
      type: mapConstants.GET_ROBOT_SUCCESS,
      robots
    };
  };

  return dispatch => {
    robotServices.getRobots({siteId})
      .then(robots => {
        dispatch(success(robots));
      })
      .catch(error => {});
  };
};

export const selectRobot = robotId => ({
  type: mapConstants.SELECT_ROBOT_ID,
  robotId
});

export const getMaps = robotId => {
  const request = () => {
    return {
      type: mapConstants.GET_MAPS_REQUEST,
      robotId
    };
  };

  const success = maps => {
    return {
      type: mapConstants.GET_MAPS_SUCCESS,
      robotId,
      maps
    };
  };

  const failure = () => {
    return {
      type: mapConstants.GET_MAPS_FAILURE,
      robotId
    };
  };

  return dispatch => {
    dispatch(request());
    Promise.all([robotServices.getRobotMapsWithRoutes(robotId), robotServices.getRobotMaps(robotId)])
      .then((maps) => {
        // combine maps with routes and maps without route
        maps[1].forEach(map => {
          if (!maps[0].find(mapWithRoutes => mapWithRoutes.id === map.id)) {
            delete map.mapRoute;
            map.mapRoutes = [];
            maps[0].push(map);
          }
        })
        dispatch(success(maps[0]));
      })
      .catch(err => {
        dispatch(failure());
      });
  };
};

export const updateMap = (robotId, mapId, data, routeId = null) => {
  // TODO implement success, request and failure reducer
  const request = () => {
    return {
      type: mapConstants.UPDATE_MAP_REQUEST,
      robotId,
      mapId
    };
  };

  const success = mapData => {
    return {
      type: mapConstants.UPDATE_MAP_SUCCESS,
      robotId,
      mapData
    };
  };

  const failure = () => {
    return {
      type: mapConstants.UPDATE_MAP_FAILURE,
      robotId,
      mapId
    };
  };

  return dispatch => {
    dispatch(request());
    robotServices.updateMap(robotId, mapId, data, routeId)
      .then((mapData) => {
        dispatch(notificationActions.addNotification(`${getText('update_map')}${getText('words_space')}${getText('succeeded')}`));
        dispatch(success(mapData));
      })
      .catch(err => {
        dispatch(notificationActions.addNotification(`${getText('update_map')}${getText('words_space')}${getText('failed')}`));
        dispatch(failure());
      });
  };
};