import React from 'react';
import { withStyles, Table, TableHead, TableBody, TableRow, TableCell, Typography, Tooltip } from '@material-ui/core';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  tableBody: {
    overflow: 'auto',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  tableCellRoot: {
    padding: '12px 12px 12px 12px',
    '&:last-child': {
      paddingRight: 12
    }
  },
  selected: {
    //!important to override :hover
    backgroundColor: 'rgba(76,127,114,0.25) !important'
  }
});

const trimName = name => {
  if (name.length <= 30) {
    return name;
  }
  else {
    return name.substr(0, 27) + '...';
  }
}

const RobotList = props => {
  const { classes, robots, selectedId, onSelect } = props;
  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              classes={{ root: classes.tableCellRoot }}
            >
              <Typography>
                {getText('robot')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <div className={classes.tableBody}>
        <Table>
          <TableBody>
            {
              robots.map((robot, index) => {
                const robotId = robot && robot.id;
                return (
                  <TableRow
                    key={robotId}
                    classes={{
                      selected: classes.selected
                    }}
                    selected={robotId === selectedId}
                    hover={true}
                    onClick={onSelect(robotId)}
                  >
                    <TableCell
                      classes={{ root: classes.tableCellRoot }}
                    >
                      <Tooltip
                        placement="top"
                        enterDelay={200}
                        title={`${robot.name}${robot.site ? ` - ${robot.site.name}` : ''}`}
                      >
                        <Typography variant="body1">
                          {trimName(robot.name)}
                        </Typography>
                      </Tooltip>
                      <span style={{ display: 'none' }}>{robot.id}</span>
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(RobotList);
