import {makeStyles, Theme, createStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      cursor: 'pointer',
      borderRadius: 8,
      border: 'solid 1px transparent',
      backgroundColor: 'transparent',
      width: '96px',
      '&:not($selectable):hover': {
        border: 'solid 1px #e0e0e0',
        backgroundColor: '#fafafa',
      },
      '&:not($selectable):hover $name': {
        display: 'none',
      },
      '&:not($selectable):hover $buttons': {
        display: 'flex',
      },
      '&$selected': {
        border: 'solid 1px #d8dcfd',
        backgroundColor: '#d8dcfd',
      },
    },
    simpleCard: {
      borderRadius: 8,
      border: 'solid 1px transparent',
      backgroundColor: 'transparent',
      width: '96px',
    },
    selectable: {},
    selected: {},
    content: {
      padding: 8,
      position: 'relative',
    },
    photoBox: {
      borderRadius: 8,
      overflow: 'hidden',
      width: 80,
      height: 80,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#eeeeee',
      '&:not($selectable):hover $action': {
        display: 'flex',
      },
    },
    photo: {
      display: 'block',
      width: '100%',
      height: '100%',
    },
    nameAbbr: {
      fontSize: 34,
      color: '#9e9e9e',
      letterSpacing: '1px',
    },
    bottom: {
      marginTop: 8,
      height: 20,
    },
    name: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
    },
    buttons: {
      display: 'none',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      paddingLeft: 2,
      paddingRight: 2,
    },
    action: {
      display: 'none',
      width: '16px',
      height: '16px',
      position: 'absolute',
      alignItems: 'center',
      top: '14px',
      right: '14px',
      borderRadius: 4,
      backgroundColor: 'rgba(0,0,0, .54)',
      color: 'rgba(255,255,255, .87)',
    },
    button: {
      padding: 0,
      fontSize: 12,
    },
    icon: {
      fontSize: 20,
    },
    checkboxContainer: {
      position: 'absolute',
      top: 10,
      left: 10,
      backgroundColor: '#fff',
      borderRadius: 10,
      width: 20,
      height: 20,
    },
    checkboxIcon: {
      color: theme.palette.primary.main,
      fontSize: 20,
    },
    contact: {
      position: 'absolute',
      display: 'flex',
      backgroundColor: '#fff',
      minWidth: theme.spacing(2.25),
      height: theme.spacing(2.25),
      left: theme.spacing(1),
      bottom: theme.spacing(4.5),
      textAlign: 'center',
      borderTopRightRadius: theme.spacing(0.75),
    },
    contactIcon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    flagIcon: {
      color: theme.palette.error.dark,
    },
  })
);

export default useStyles;
