import {
  SET_CLIP_CLIPS,
  SET_CLIP_LOADING,
  SET_CLIP_FILTER,
  SET_CLIP_CHANGE_FILTER,
  SET_CLIP_FACE_SELECT,
} from 'src/redux/types/Vision/clip';

export const defaultFilter = {
  type: '',
  tracking_object_key: '',
  lpr: '',
  api_type: 'event',
  site: '',
  camera: '',
  org: '',
  face: null,
  range: {type: 'customized', range: [null, null]},
  ordering: '-started_at',
  page: 0,
  limit: 40, // 12,
};

const initialState = {
  loading: false,
  filter: defaultFilter,
  total: 0,
  hasNext: false,
  clips: [],
  changeFilter: false,
  showFaceSelect: false,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function clipReducer(state = initialState, action){
  const {type, payload} = action;
  switch (type) {
    case SET_CLIP_LOADING:
      return {
        ...state,
        loading: payload.loading,
      };
    case SET_CLIP_CLIPS:
      return {
        ...state,
        loading: payload.loading,
        total: payload.total,
        clips: payload.clips,
        hasNext: payload.hasNext,
      };
    case SET_CLIP_FILTER:
      return {
        ...state,
        filter: payload.filter,
      };
    case SET_CLIP_CHANGE_FILTER:
      return {
        ...state,
        changeFilter: !state.changeFilter,
      };
    case SET_CLIP_FACE_SELECT:
      return {
        ...state,
        showFaceSelect: payload.showFaceSelect,
      };
    default:
      return state;
  }
}
