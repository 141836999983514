import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

import Spin from 'src/components/Vision/Spin';
import {isPeople, isVehicle} from 'src/redux/actions/Vision/eventType';
import {DetailFrom, DetailType} from '../definition';
import {useDetail, useEventType} from '../hook';
import {DetailContext} from '../context';
import {DetailNavigateComponet} from './navigate';
import {DetailContentComponent} from './content';


export function DetailComponent({id, from, prev, next}) {
  const {detail, refresh} = useDetail(id);
  const [view, setView] = useState(false);
  const history = useHistory();
  const eventTypeOption = useEventType();
  const sites = [];
  const cameras = [];
  if (detail === null) return <Spin spinning={true} />;
  const type = detail.event.types[0] ? detail.event.types[0].name : '';
  const isPeopleType = isPeople(type);
  const isVehicleType = isVehicle(type);
  const isAlert = history.location.pathname.indexOf('events') !== -1;
  const dt = isPeopleType ? DetailType.People : (isVehicleType ? DetailType.Vehicle : DetailType.Motorcycle);
  return (
    <DetailContext.Provider
      value={{
        detailType: dt,
        detailFrom: isAlert ? DetailFrom.Alert : DetailFrom.Event,
        detail,
        refresh,
        prev,
        next,
        setView,
        eventTypeOption,
        sites,
        cameras,
        from,
      }}
    >
      <>
        <DetailNavigateComponet />
        <DetailContentComponent />
      </>
    </DetailContext.Provider>
  );
}
