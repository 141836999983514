import React from 'react';
import { connect } from 'react-redux';

import { inspectionActions, robotActions,routineHistoryActions } from 'src/redux/actions';
import RobotList from "./RobotList.js";

const mapStateToProps = state => {
  const { robots, selectedRobot, robotLoading, showType, eventTypes } = state.inspection;
  const { robotCurMaps, robotConfigs } = state.robot;
  const { curSite } = state.site;
  const configs = selectedRobot ? robotConfigs[selectedRobot.id] : null;
  return { robots, selectedRobot, robotLoading, showType, robotCurMaps, configs, curSite, eventTypes };
};

const mapDispatchToProps = dispatch => {
  return {
    getRobots: () => dispatch(inspectionActions.getRobots()),
    selectRobot: robot => dispatch(inspectionActions.selectRobot(robot)),
    unselectRobot: () => dispatch(inspectionActions.selectRobot(null)),
    getInspections: (filters, loadAll) => dispatch(inspectionActions.getInspections(filters, loadAll)),
    setFilter: filters => dispatch(inspectionActions.setFilter(filters)),
    getRobotConfigs: robot_id => {
      dispatch(robotActions.getRobotConfigs(robot_id));
    },
    getRobotConfigMap: (robotId, mapId) => {
      dispatch(robotActions.getRobotConfigMap(robotId, mapId));
    },
    getEventTypes: (siteId) => {
      dispatch(inspectionActions.getEventTypes(siteId));
    },
    selectRoutineHistoryTime: history_time => {
      dispatch(routineHistoryActions.selectRoutineHistoryTime(history_time))
    }
  };
};

class RobotListContainer extends React.Component {
  componentDidMount() {
    const { robotLoading, getRobots, curSite, getEventTypes } = this.props;
    if (!robotLoading) getRobots();
    if (curSite) getEventTypes(curSite);
  }

  componentDidUpdate(prevProps) {
    const { curSite, robotLoading, getRobots, unselectRobot, eventTypes, getEventTypes } = this.props;
    if (this.props.configs && this.props.configs.current_map_id && 
      (!prevProps.configs || prevProps.configs.current_map_id !== this.props.configs.current_map_id)) {
        this.getMap();
    }

    if (curSite && (!prevProps.curSite || curSite !== prevProps.curSite) && !robotLoading) {
      getRobots();
      unselectRobot();
    }

    if (curSite && (!prevProps.curSite || prevProps.curSite !== curSite) && !eventTypes[curSite]) {
      getEventTypes(curSite);
    }
  }

  componentWillUnmount() {
    this.props.unselectRobot();
  }

  getMap() {
    const { selectedRobot, configs, getRobotConfigMap } = this.props;
    if (selectedRobot && configs && configs.current_map_id) {
      getRobotConfigMap(selectedRobot.id, configs.current_map_id);
    }
  }

  handleRobotSelect = robot => event => {
    const { selectRobot, getInspections, setFilter, showType, robotCurMaps, getRobotConfigs,selectRoutineHistoryTime } = this.props;
    const defaultFilter = {
      lineSize: 4,
      sortBy: 'ended_at',
      mediumType: 'all',
      matchMap: false,
      matchRoute: false,
      startDate: null,
      endDate: null,
      inspectionType: 'check',
      robotId: robot.id,
      target: {}
    };
    selectRobot(robot);
    setFilter(defaultFilter);
    getInspections(defaultFilter, showType === 'map');
    const robotMap = robotCurMaps[robot.id];
    getRobotConfigs(robot.id);
    if (!robotMap || !robotMap.loading ) this.getMap();
    selectRoutineHistoryTime(null)
  }

  render() {
    let robots = this.props.robots || [];

    return (
      <RobotList
        selectedRobot={this.props.selectedRobot}
        robots={robots}
        handleRobotSelect={this.handleRobotSelect}
      />
    );
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RobotListContainer);
