import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const styles = theme => ({
  root: {
    paddingBottom: '20px'
  },
  formLabel: {
    paddingBottom: '6px'
  },
  radioDot: {
    paddingLeft: '10px',
    paddingRight: '2px',
    paddingTop: '2px',
    paddingBottom: '2px'
  }
});

const RadioSelector = props => {
    const { classes, value, onChange: handleChange, options, title } = props;
    return (
      <FormControl component="fieldset" className={classes.root}>
        <FormLabel
          className={classes.formLabel}
          component="legend" >
          {title}
        </FormLabel>
        <RadioGroup
          row
          value={value}
          onChange={handleChange} >
          {
            options.map((opt, index) => (
              <FormControlLabel
                key={index}
                value={opt.value}
                control={
                  <Radio
                    className={classes.radioDot}
                  />
                }
                label={opt.label}
              />
            ))
          }
        </RadioGroup>
      </FormControl>
    );
};

RadioSelector.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape(
    {
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    }
  )).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

RadioSelector.defaultProps = {
  onChange: (event, value) => {}
};

export default withStyles(styles)(RadioSelector);
