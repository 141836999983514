import React from "react";
import { connect } from "react-redux";
import { TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import * as _ from "lodash";

import { robotActions } from "src/redux/actions";
import RobotConfig from "./RobotConfig";
import { getText } from "src/utils/MultilingualLoader";
import OnOffSwitch from "src/components/Switches/OnOffSwitch";
import DropDownSelectorVertical from "src/components/Selectors/DropDownSelectorVertical";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRobotConfigs: (robot_id, updConfigs) => {
      dispatch(robotActions.updateRobotConfigs(robot_id, updConfigs));
    },
  };
};

const sensorTypeOptions = [
  { value: "g5st", label: getText("g5st") },
  { value: "patrol_performance", label: getText("patrol_performance") },
];

const lidarModelOptions = [
  { value: "S1", label: "S1" },
  { value: "A1", label: "A1" },
  { value: "A3", label: "A3" },
];

const TimeRangeInput = function({value, onChange}){
  return <TextField
          type="time"
          style={{ fontSize: "1rem" }}
          InputLabelProps={{ shrink: true }}
          inputProps={{ step: 60 }}
          value={value}
          onChange={onChange}/>
}

class RobotConfigContainer extends React.Component {
  constructor(props) {
    super(props);
    const { configs } = props;
    this.state = {
      configs: this.fromPropConfigs(configs),
    };
  }

  componentDidUpdate(prevProps) {
    const curConfigs = Object.assign({}, this.props.configs);
    const prevConfigs = Object.assign({}, prevProps.configs);
    delete curConfigs.updating;
    delete prevConfigs.updating;
    const { configs: stateConfigs } = this.state;
    const robotChanged =
      this.props.selected &&
      prevProps.selected &&
      this.props.selected.id !== prevProps.selected.id;
    if (robotChanged || !_.isEqual(curConfigs, prevConfigs)) {
      const newStateConfigs = robotChanged
        ? this.fromPropConfigs(curConfigs)
        : Object.assign({}, stateConfigs);
      // update configs in state. only update changed configs.
      for (const prop in stateConfigs) {
        // changed field is not array
        if (
          curConfigs[prop] !== undefined &&
          !Array.isArray(curConfigs[prop]) &&
          (curConfigs[prop] !== prevConfigs[prop] ||
            curConfigs[prop] !== stateConfigs[prop])
        ) {
          switch (prop) {
            case "patrol_schedule_start_time":
            case "patrol_schedule_end_time":
            case 'charging_exemption_start_time':
            case 'charging_exemption_end_time':
              newStateConfigs[prop] = this.minutesToString(curConfigs[prop]);
              break;
            case "sensor_types":
              let selectedTypes = {};
              curConfigs[prop] &&
                curConfigs[prop]
                  .split(",")
                  .filter((type) =>
                    sensorTypeOptions
                      .map((option) => option.value)
                      .includes(type)
                  )
                  .forEach((type) => {
                    if (type) selectedTypes[type] = true;
                  });
              newStateConfigs[prop] = selectedTypes;
              break;
            default:
              newStateConfigs[prop] = curConfigs[prop];
              break;
          }
        }
        // changed field is array
        else if (
          curConfigs[prop] !== undefined &&
          Array.isArray(curConfigs[prop]) &&
          Array.isArray(prevConfigs[prop])
        ) {
          let changed = false;
          for (const idx in curConfigs[prop]) {
            if (curConfigs[prop][idx] !== prevConfigs[prop][idx]) {
              changed = true;
              break;
            }
          }
          if (changed) {
            switch (prop) {
              default:
                newStateConfigs[prop] = curConfigs[prop];
                break;
            }
          }
        }
        // complete all fields for a new config state
        else if (robotChanged || Object.keys(prevConfigs).length === 0) {
          newStateConfigs[prop] = curConfigs[prop] || null;
        }
      }
      this.setState({
        configs: newStateConfigs,
      });
    }
  }

  fromPropConfigs = (configs) => {
    const {charging_check_fail_enabled, charging_exemption_enabled, detect_cliff_when_zero_distance} = configs || {}
    const patrol_schedule_start_time =
      configs &&
      configs.patrol_schedule_start_time &&
      this.minutesToString(configs.patrol_schedule_start_time);
    const patrol_schedule_end_time =
      configs &&
      configs.patrol_schedule_end_time &&
      this.minutesToString(configs.patrol_schedule_end_time);
    const charging_exemption_start_time = configs && configs.charging_exemption_start_time && this.minutesToString(configs.charging_exemption_start_time)
    const charging_exemption_end_time = configs && configs.charging_exemption_end_time && this.minutesToString(configs.charging_exemption_end_time)
    let selected_types = {};
    if (configs && configs.sensor_types) {
      configs.sensor_types
        .split(",")
        .filter((type) =>
          sensorTypeOptions.map((option) => option.value).includes(type)
        )
        .forEach((type) => {
          if (type) selected_types[type] = true;
        });
    }
    return {
      home_cargo_mode: configs && configs.home_cargo_mode,
      home_head_angle: configs && configs.home_head_angle,
      light_enabled: configs && configs.light_enabled,
      media_alarm_file: configs && configs.media_alarm_file,
      media_background_file: configs && configs.media_background_file,
      media_speak_message: configs && configs.media_speak_message,
      patrol_check_wheel_stuck: configs && configs.patrol_check_wheel_stuck,
      routine_execution_schedule_enabled: configs && configs.routine_execution_schedule_enabled,
      disable_antifall_when_enter_elevator: configs && configs.disable_antifall_when_enter_elevator,
      patrol_schedule_enabled: configs && configs.patrol_schedule_enabled,
      charging_check_fail_enabled,
      charging_exemption_enabled,
      detect_cliff_when_zero_distance,
      patrol_schedule_end_time: patrol_schedule_end_time,
      patrol_schedule_start_time: patrol_schedule_start_time,
      charging_exemption_start_time,
      charging_exemption_end_time,
      power_range_high: configs && configs.power_range_high,
      power_range_low: configs && configs.power_range_low,
      slam_optimization_interval: configs && configs.slam_optimization_interval,
      slam_submap_trim_number: configs && configs.slam_submap_trim_number,
      streaming_hd: configs && configs.streaming_hd,
      streaming_overlay_map: configs && configs.streaming_overlay_map,
      streaming_audio_enabled: configs && configs.streaming_audio_enabled,
      sensor_enabled: configs && configs.sensor_enabled,
      sensor_types: selected_types,
      sensor_lidar_model: configs && configs.sensor_lidar_model,
      way_point_names: configs && configs.way_point_names,
      goal_distance_tolerance_one_loop:
        configs && configs.goal_distance_tolerance_one_loop,
      passed_way_point_ratio: configs && configs.passed_way_point_ratio,
      disable_depth_camera_in_elevator: configs && configs.disable_depth_camera_in_elevator,
      continue_navigation_when_sensor_data_outdated: configs && configs.continue_navigation_when_sensor_data_outdated
    };
  };

  minutesToString = (totalMinutes) => {
    let hours = Math.floor(totalMinutes / 60).toString();
    let minutes = (totalMinutes % 60).toString();
    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;
    return `${hours}:${minutes}`;
  };

  stringToMinutes = (timeString) => {
    let time = timeString.split(":");
    if (time.length < 2 || time[0] === "" || time[1] === "") {
      return null;
    }
    let minutes = parseInt(time[0]) * 60 + parseInt(time[1]);
    return minutes;
  };

  handleChange = (field, type) => (event) => {
    let value = event.target.value;
    if (type === "Integer") value = parseInt(value);
    this.setState({
      configs: {
        ...this.state.configs,
        [field]: value,
      },
    });
  };

  handleSwitch = (field) => (event) => {
    this.setState({
      configs: {
        ...this.state.configs,
        [field]: event.target.checked,
      },
    });
  };

  handleSensorTypeChange = (type) => (event) => {
    this.setState({
      configs: {
        ...this.state.configs,
        sensor_types: {
          ...this.state.configs.sensor_types,
          [type]: event.target.checked,
        },
      },
    });
  };

  handleWayPointNamesChange = (event) => {
    const wayPointNames = event.target.value
      .split(",")
      .map((name) => name.trim());
    this.setState({
      configs: {
        ...this.state.configs,
        way_point_names: wayPointNames,
      },
    });
  };

  handleSubmit = () => {
    const updConfigs = Object.assign({}, this.state.configs);
    if (updConfigs.patrol_schedule_start_time) {
      updConfigs.patrol_schedule_start_time = this.stringToMinutes(
        updConfigs.patrol_schedule_start_time
      );
    }
    if (updConfigs.patrol_schedule_end_time) {
      updConfigs.patrol_schedule_end_time = this.stringToMinutes(
        updConfigs.patrol_schedule_end_time
      );
    }
    
    if(updConfigs.charging_exemption_start_time) {
      updConfigs.charging_exemption_start_time = this.stringToMinutes(updConfigs.charging_exemption_start_time)
    }
    
    if(updConfigs.charging_exemption_end_time) {
      updConfigs.charging_exemption_end_time = this.stringToMinutes(updConfigs.charging_exemption_end_time)
    }
    
    if (updConfigs.goal_distance_tolerance_one_loop) {
      updConfigs.goal_distance_tolerance_one_loop = parseFloat(
        updConfigs.goal_distance_tolerance_one_loop
      );
    }
    if (updConfigs.passed_way_point_ratio) {
      updConfigs.passed_way_point_ratio = parseFloat(
        updConfigs.passed_way_point_ratio
      );
    }
    if (updConfigs.sensor_types) {
      updConfigs.sensor_types = Object.keys(updConfigs.sensor_types)
        .filter((type) => updConfigs.sensor_types[type])
        .join(",");
    }

    if (
      updConfigs["power_range_high"] > 90 ||
      updConfigs["power_range_high"] < 0 ||
      updConfigs["power_range_high"] <= updConfigs["power_range_low"]
    ) {
      return alert(getText("input_error_power_range_high"));
    }
    if (
      updConfigs["power_range_low"] < 30 ||
      updConfigs["power_range_low"] > 100
    ) {
      return alert(getText("input_error_power_range_low"));
    }
    if (
      this.props.configs &&
      !this.props.configs.updating &&
      this.props.selected
    ) {
      let enable_nav = false;
      if (
        this.state.configs.sensor_types &&
        this.state.configs.sensor_types.patrol_performance
      ) {
        enable_nav = true;
      }
      this.props.updateRobotConfigs(this.props.selected.id, {
        ...updConfigs /* enable_nav_update: enable_nav */,
      });
    }
  };
  
  getCommonSwitchConfig(configName, textName = configName) {
    const { configs: stateConfigs } = this.state;
    // 返回数组是为了方便插入configs数组中
    return [{
      title: getText(textName),
      content: (
        <OnOffSwitch
          value={
            stateConfigs[configName]
              ? stateConfigs[configName]
              : false
          }
          onChange={this.handleSwitch(configName)}
        />
      ),
    }]
  }

  getConfigs = (stateConfigs) => [
    {
      title: getText("home_head_angle"),
      content: (
        <input
          type="number"
          style={{ fontSize: "1rem" }}
          value={stateConfigs["home_head_angle"] || ""}
          onChange={this.handleChange("home_head_angle", "Integer")}
        />
      ),
    },
    {
      title: getText("light_enabled"),
      content: (
        <OnOffSwitch
          value={
            stateConfigs["light_enabled"]
              ? stateConfigs["light_enabled"]
              : false
          }
          onChange={this.handleSwitch("light_enabled")}
        />
      ),
    },
    {
      title: getText("media_alarm_file"),
      content: (
        <input
          type="text"
          style={{ fontSize: "1rem" }}
          value={stateConfigs["media_alarm_file"] || ""}
          onChange={this.handleChange("media_alarm_file", "string")}
        />
      ),
    },
    {
      title: getText("media_background_file"),
      content: (
        <input
          type="text"
          style={{ fontSize: "1rem" }}
          value={stateConfigs["media_background_file"] || ""}
          onChange={this.handleChange("media_background_file", "string")}
        />
      ),
    },
    {
      title: getText("media_speak_message"),
      content: (
        <input
          type="text"
          style={{ fontSize: "1rem" }}
          value={stateConfigs["media_speak_message"] || ""}
          onChange={this.handleChange("media_speak_message", "string")}
        />
      ),
    },
    {
      title: getText("patrol_check_wheel_stuck"),
      content: (
        <OnOffSwitch
          value={
            stateConfigs["patrol_check_wheel_stuck"]
              ? stateConfigs["patrol_check_wheel_stuck"]
              : false
          }
          onChange={this.handleSwitch("patrol_check_wheel_stuck")}
        />
      ),
    },
    {
      title: getText("patrol_schedule_enabled"),
      content: (
        <OnOffSwitch
          value={
            stateConfigs["patrol_schedule_enabled"]
              ? stateConfigs["patrol_schedule_enabled"]
              : false
          }
          onChange={this.handleSwitch("patrol_schedule_enabled")}
        />
      ),
    },
    {
      title: getText("patrol_schedule_start_time"),
      content: (
        <TextField
          type="time"
          style={{ fontSize: "1rem" }}
          InputLabelProps={{ shrink: true }}
          inputProps={{ step: 60 }}
          value={stateConfigs["patrol_schedule_start_time"] || ""}
          onChange={this.handleChange("patrol_schedule_start_time", "string")}
        />
      ),
    },
    {
      title: getText("patrol_schedule_end_time"),
      content: (
        <TextField
          type="time"
          style={{ fontSize: "1rem" }}
          InputLabelProps={{ shrink: true }}
          inputProps={{ step: 60 }}
          value={stateConfigs["patrol_schedule_end_time"] || ""}
          onChange={this.handleChange("patrol_schedule_end_time", "string")}
        />
      ),
    },
    {
      title: getText("goal_distance_tolerance_one_loop"),
      content: (
        <input
          type="number"
          style={{ fontSize: "1rem" }}
          value={stateConfigs["goal_distance_tolerance_one_loop"] || ""}
          onChange={this.handleChange(
            "goal_distance_tolerance_one_loop",
            "string"
          )}
        />
      ),
    },
    {
      title: getText("passed_way_point_ratio"),
      content: (
        <input
          type="number"
          style={{ fontSize: "1rem" }}
          value={stateConfigs["passed_way_point_ratio"] || ""}
          onChange={this.handleChange("passed_way_point_ratio", "string")}
        />
      ),
    },
    {
      title: getText("power_range_high"),
      content: (
        <input
          type="number"
          max="90"
          style={{ fontSize: "1rem" }}
          value={stateConfigs["power_range_high"] || ""}
          onChange={this.handleChange("power_range_high", "Integer")}
        />
      ),
    },
    {
      title: getText("power_range_low"),
      content: (
        <input
          type="number"
          min="30"
          style={{ fontSize: "1rem" }}
          value={stateConfigs["power_range_low"] || ""}
          onChange={this.handleChange("power_range_low", "Integer")}
        />
      ),
    },
    {
      title: getText("sensor_lidar_model"),
      content: (
        <DropDownSelectorVertical
          options={lidarModelOptions}
          value={stateConfigs["sensor_lidar_model"] || ""}
          onChange={this.handleChange("sensor_lidar_model", "string")}
        />
      ),
    },
    {
      title: getText("slam_optimization_interval"),
      content: (
        <input
          type="number"
          style={{ fontSize: "1rem" }}
          value={stateConfigs["slam_optimization_interval"] || ""}
          onChange={this.handleChange("slam_optimization_interval", "Integer")}
        />
      ),
    },
    {
      title: getText("slam_submap_trim_number"),
      content: (
        <input
          type="number"
          style={{ fontSize: "1rem" }}
          value={stateConfigs["slam_submap_trim_number"] || ""}
          onChange={this.handleChange("slam_submap_trim_number", "Integer")}
        />
      ),
    },
    {
      title: getText("streaming_hd"),
      content: (
        <OnOffSwitch
          value={
            stateConfigs["streaming_hd"] ? stateConfigs["streaming_hd"] : false
          }
          onChange={this.handleSwitch("streaming_hd")}
        />
      ),
    },
    {
      title: getText("streaming_audio_enabled"),
      content: (
        <OnOffSwitch
          value={
            stateConfigs["streaming_audio_enabled"]
              ? stateConfigs["streaming_audio_enabled"]
              : false
          }
          onChange={this.handleSwitch("streaming_audio_enabled")}
        />
      ),
    },
    {
      title: getText("sensor_enabled"),
      content: (
        <OnOffSwitch
          value={
            stateConfigs["sensor_enabled"]
              ? stateConfigs["sensor_enabled"]
              : false
          }
          onChange={this.handleSwitch("sensor_enabled")}
        />
      ),
    },
    {
      title: getText("sensor_types"),
      content: (
        <>
          {sensorTypeOptions.map((type) => (
            <FormControlLabel
              key={type.value}
              control={
                <Checkbox
                  checked={
                    stateConfigs["sensor_types"] &&
                    stateConfigs["sensor_types"][type.value]
                      ? stateConfigs["sensor_types"][type.value]
                      : false
                  }
                  onChange={this.handleSensorTypeChange(type.value)}
                  color="primary"
                />
              }
              label={type.label}
            />
          ))}
        </>
      ),
    },
    {
      title: getText("way_point_names"),
      content: (
        <input
          style={{ fontSize: "1rem" }}
          value={
            (stateConfigs["way_point_names"] &&
              stateConfigs["way_point_names"].join(",")) ||
            ""
          }
          onChange={this.handleWayPointNamesChange}
        />
      ),
    },
    ...this.getCommonSwitchConfig('charging_check_fail_enabled'),
    ...this.getCommonSwitchConfig('charging_exemption_enabled'),
    {
      title: getText("charging_exemption_start_time"),
      content: (
        <TimeRangeInput value={stateConfigs["charging_exemption_start_time"] || ""} onChange={this.handleChange("charging_exemption_start_time", "string")}/>
      ),
    },
    {
      title: getText("charging_exemption_end_time"),
      content: (
        <TimeRangeInput value={stateConfigs["charging_exemption_end_time"] || ""} onChange={this.handleChange("charging_exemption_end_time", "string")}/>
      ),
    },
    ...this.getCommonSwitchConfig('detect_cliff_when_zero_distance'),
    {
      title: getText("inspection_queue"),
      content: (
        <OnOffSwitch
          value={
            stateConfigs["routine_execution_schedule_enabled"]
              ? stateConfigs["routine_execution_schedule_enabled"]
              : false
          }
          onChange={this.handleSwitch("routine_execution_schedule_enabled")}
        />
      ),
    },
    {
      title: getText("ladder_prevent_falling"),
      content: (
        <OnOffSwitch
          value={
            typeof stateConfigs["disable_antifall_when_enter_elevator"] === 'boolean'
              // disable_antifall_when_enter_elevator的含义是禁用乘梯防跌落，而这里的文案是"乘梯防跌落"，二者的含义正好是相反的，
              // 所以需要取反
              ? !stateConfigs["disable_antifall_when_enter_elevator"]
              : false
          }
          onChange={(event) => {
            this.setState({
              configs: {
                ...this.state.configs,
                'disable_antifall_when_enter_elevator': !event.target.checked,
              },
            });
          }}
        />
      ),
    },
    {
      title: getText("depth_camera_camera_in_elevator"),
      content: (
        <OnOffSwitch
          value={
            typeof stateConfigs["disable_depth_camera_in_elevator"] === 'boolean'
              ? !stateConfigs["disable_depth_camera_in_elevator"]
              : false
          }
          onChange={(event) => {
            this.setState({
              configs: {
                ...this.state.configs,
                'disable_depth_camera_in_elevator': !event.target.checked,
              },
            });
          }}
        />
      ),
    },
    {
      title: getText("continue_navigation_when_sensor_data_outdated"),
      content: (
        <OnOffSwitch
          value={ stateConfigs["continue_navigation_when_sensor_data_outdated"] || false}
          onChange={this.handleSwitch("continue_navigation_when_sensor_data_outdated")}
        />
      ),
    },
  ];

  render() {
    const { configs: stateConfigs } = this.state;
    let configs = this.getConfigs(stateConfigs);
    return <RobotConfig configs={configs} onSubmit={this.handleSubmit} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RobotConfigContainer);
