import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { withStyles, Paper, Table, TableHead, TableBody, TableRow, TableCell, IconButton, Typography } from '@material-ui/core';
import {
  Done as CompleteIcon, Close as SkippedIcon, KeyboardArrowUp as CloseIcon,
  KeyboardArrowDown as OpenIcon
} from '@material-ui/icons';

import { robotServices, targetServices } from 'src/services';
import { getText } from 'src/utils/MultilingualLoader';
import LoadingText from 'src/components/LoadingText';
import ContainedButton from 'src/components/Buttons/ContainedButton';

const styles = theme => ({
  paper: {
    backgroundColor: theme.palette.grey[200],
    minHeight: 100,
  },
  tableCell1: {
    width: '10%'
  },
  tableCell2: {
    width: '30%'
  },
  tableCell3: {
    width: '30%',
    fontWeight: 'bold'
  },
  tableCell4: {
    width: '10%',
    color: '#C70039',
    fontSize: '1rem'
  },
  hide: {
    display: 'none'
  },
  message: {
    margin: 'auto',
    textAlign: 'center',
  },
  buttonRight: {
    float: 'right',
    marginTop: -theme.spacing(2),
    marginRight: theme.spacing(1)
  }
});

function mapRobotStateToRoutineStatus(robotState, targetList) {
  const routineEvents = [];
  let skipped = 0;
  let total = 0;
  let completed = 0;
  // find the last completed waypoint event
  if (robotState.routineEvents) {
    let lastCompletedIdx = null;
    for (let idx = robotState.routineEvents.length - 1; idx >= 0; idx--) {
      const routineEvent = robotState.routineEvents[idx];
      const wayPointActionEvents = routineEvent && routineEvent.way_point_action_events;
      if (wayPointActionEvents) {
        for (let idx = wayPointActionEvents.length - 1; idx >= 0; idx--) {
          total++;
          const wayPointActionEvent = wayPointActionEvents[idx];
          if (!lastCompletedIdx && wayPointActionEvent && wayPointActionEvent.finish_time) {
            lastCompletedIdx = total;
          }
        }
      }
    }
    lastCompletedIdx = total - lastCompletedIdx + 1;

    let wayPointActionEventIdx = 1;
    for (let i = 0; i < robotState.routineEvents.length; i++) {
      const routineEvent = robotState.routineEvents[i];
      const act = routineEvent.routine_action && routineEvent.routine_action.act;
      const finished = routineEvent.finish_time ? true : false;
      switch (act) {
        case 'load_map_route': case 'take_elevator': case 'go_home': case 'leave_home':
          routineEvents.push({
            act,
            status: finished ? 'completed' : wayPointActionEventIdx < lastCompletedIdx ? 'failed' : 'pending'
          })
          break;
        case 'one_loop': case 'patrol':
          let wayPointActionEvents = [];
          let hasSkip = false;
          if (routineEvent.way_point_action_events) {
            for (const wayPointActionEvent of routineEvent.way_point_action_events) {
              const wayPointAction = wayPointActionEvent.way_point_action;
              const targetId = wayPointAction && wayPointAction.arg && wayPointAction.arg.target_id;
              const target = targetId && targetList && targetList.find(target => target.id === parseInt(targetId));
              let name = target && target.label;
              if (!name) {
                name = wayPointAction && wayPointAction.name;
              }
              const act = wayPointAction && wayPointAction.act;
              const waypoint_finished = wayPointActionEvent.finish_time ? true : false;
              let status = 'pending';
              if (waypoint_finished) {
                completed++;
                status = 'completed';
              }
              else if (finished || wayPointActionEventIdx < lastCompletedIdx) {
                skipped++;
                hasSkip = true;
                status = 'skipped';
              }
              wayPointActionEventIdx++;
              wayPointActionEvents.push({ act, status, name });
            }
          }
          routineEvents.push({
            act,
            wayPointActionEvents,
            status: hasSkip ? 'uncompleted' : finished ? 'completed' : 'pending'
          })
          break;
        default:
          break;
      }
    }
  }

  return {
    completed,
    total,
    skipped,
    routineId: robotState.lastRoutineId || '',
    routineEvents
  };
}

const RoutineStatus = props => {
  const { classes, robot, setPanelContent } = props;
  const { curSite } = useSelector(state => state.site);
  const { robotConfigs, selected } = useSelector(state => state.robot);
  const [robotState, setRobotState] = useState({});
  const [targetList, setTargetList] = useState([]);
  const [oneloopOpen, setOneloopOpen] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    Promise.all([robotServices.getRobotState(robot.id), targetServices.getTargetOptions({siteId: curSite})])
      .then((ret) => {
        setRobotState(ret[0]);
        setTargetList(ret[1]);
       
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false)
      })
    const refresh = setInterval(() => updRoutineStatus(robot.id), 5000);
    return () => {
      clearInterval(refresh);
    }
  }, []);
  
  async function updRoutineStatus(robotId) {
    try {
      const robotState = await robotServices.getRobotState(robotId);
      setRobotState(robotState);
    } catch (e) {}
  };

  const routineStatus = mapRobotStateToRoutineStatus(robotState, targetList);
  const { completed, total, skipped, routineId, routineEvents } = routineStatus;
  const robotConfig = selected && robotConfigs[selected.id];
  const routine = robotConfig && robotConfig.routines && robotConfig.routines.find(routine => routine.id === routineId);
  const routineName = routine && routine.name;
  
  return (
    <Paper className={classes.paper}>
      {routineEvents.length > 0 ?
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell1}></TableCell>
              <TableCell className={classes.tableCell2}>{`${completed}/${total} ${getText('completed')}`}</TableCell>
              <TableCell className={classes.tableCell2}>{`${getText('routine')}: ${routineId} ${routineName}`}</TableCell>
              <TableCell className={classes.tableCell2} align="right">
                <ContainedButton onClick={() => setPanelContent('main')}>{getText('go_back')}</ContainedButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell4} align="right">{skipped}</TableCell>
              <TableCell className={classes.tableCell2}>{getText('skipped')}</TableCell>
              <TableCell className={classes.tableCell2}></TableCell>
              <TableCell className={classes.tableCell2}></TableCell>
            </TableRow>
            {
              routineEvents.map((routineEvent, idx) => {
                if (routineEvent.wayPointActionEvents) {
                  return (
                    <React.Fragment key={idx}>
                      <TableRow>
                        <TableCell className={classes.tableCell1} align="right">
                          {routineEvent.status === 'completed' ? <CompleteIcon htmlColor="#4C7F72" /> :
                            routineEvent.status === 'uncompleted' ? <SkippedIcon htmlColor="#C70039" /> : null}
                        </TableCell>
                        <TableCell className={classes.tableCell2}>{routineEvent.status === 'completed' ? getText('completed') :
                          routineEvent.status === 'uncompleted' ? getText('uncompleted') : getText('pending')}</TableCell>
                        <TableCell className={classes.tableCell3}>{getText(routineEvent.act)}</TableCell>
                        <TableCell className={classes.tableCell2}>
                          <IconButton aria-label="expand row" size="small" onClick={() => setOneloopOpen({
                            ...oneloopOpen,
                            [idx]: !oneloopOpen[idx]
                          })}>
                            {oneloopOpen[idx] ? <CloseIcon /> : <OpenIcon />}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      {
                        oneloopOpen[idx] && routineEvent.wayPointActionEvents.map((wayPointActionEvent, idx) => {
                          return (
                            <TableRow key={idx}>
                              <TableCell className={classes.tableCell1} align="right">
                                {wayPointActionEvent.status === 'completed' ? <CompleteIcon htmlColor="#4C7F72" /> :
                                  wayPointActionEvent.status === 'skipped' ? <SkippedIcon htmlColor="#C70039" /> : null}
                              </TableCell>
                              <TableCell className={classes.tableCell2}>{wayPointActionEvent.status === 'completed' ? getText('completed') :
                                wayPointActionEvent.status === 'skipped' ? getText('skipped') : getText('pending')}</TableCell>
                              <TableCell className={classes.tableCell3}>{getText(wayPointActionEvent.name)}</TableCell>
                              <TableCell className={classes.tableCell2}>{getText(`waypoint.action.${wayPointActionEvent.act}`)}</TableCell>
                            </TableRow>
                          );
                        })
                      }
                    </React.Fragment>
                  );
                }
                else {
                  return (
                    <TableRow key={idx}>
                      <TableCell className={classes.tableCell1} align="right">
                        {routineEvent.status === 'completed' ? <CompleteIcon htmlColor="#4C7F72"/> : 
                          routineEvent.status === 'failed' ? <SkippedIcon htmlColor="#C70039"/> : null}
                      </TableCell>
                      <TableCell className={classes.tableCell2}>{routineEvent.status === 'completed' ? getText('completed') :
                        routineEvent.status === 'failed' ? getText('failed') : getText('pending')}</TableCell>
                      <TableCell className={classes.tableCell3}>{getText(routineEvent.act)}</TableCell>
                      <TableCell className={classes.tableCell2}></TableCell>
                    </TableRow>
                  );
                }
              })
            }
          </TableBody>
        </Table>
        : loading ? <LoadingText /> : <>
            <Typography className={classes.message} variant="body1">
              {getText('no_routine_status')}
            </Typography>
            <div className={classes.buttonRight}>
              <ContainedButton onClick={() => setPanelContent('main')}>{getText('go_back')}</ContainedButton>
            </div>
          </>
      }
    </Paper>
  );
};

export default withStyles(styles)(RoutineStatus);