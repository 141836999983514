import Grid from "@material-ui/core/Grid/Grid";
import SidePanel from "./SidePanel/SidePanel";
import {withStyles} from "@material-ui/core";
import React from "react";
import CameraPanel from "./CameraPanel";

export const sidePanelWidth = 350;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto'
  },
  sidePanel: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: sidePanelWidth,
    height: '100%',
    overflow: 'auto',
  },
  mainPanel: {
    width: `calc(100% - ${sidePanelWidth}px)`,
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // display: 'flex',
    flex: 1,
    height: '100%',
    overflow: 'auto',
  },
  toolbar: theme.mixins.toolbar,
});

const Cameras = props => {
  const { classes } = props;
  return (
    <Grid
        className={classes.root}
        direction="row"
        container={true}
        justify="flex-start"
        alignItems="flex-start"
        spacing={0}
    >
      <Grid className={classes.sidePanel} item={true}>
        <SidePanel/>
      </Grid>
      <Grid className={classes.mainPanel} item={true}>
        <CameraPanel />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Cameras);
