import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    position: 'sticky',
    zIndex: 1001,
    paddingLeft: 24,
    paddingRight: 24,
    // paddingLeft: 'max(24px, calc(100% - 1457px)/2)',
    // paddingRight: 'max(24px, calc(100% - 1457px)/2)',
    backgroundColor: '#FFF',
    borderBottom: '1px solid #e0e0e0',
    minHeight: 72,
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '72px',
  },
  updateTime: {
    height: '56px',
    marginLeft: '24px',
    marginTop: '3px',
    fontSize: '10px',
    color: 'rgba(1,1,1,0.60)',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  flex: {
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  flexEnd: {
    display: 'inline-flex',
    alignItems: 'center',
  },
});

export default useStyles;
