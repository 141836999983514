import moment from 'moment';
import { GeneralAdapter } from './adapter';
import { config } from './config';

const getRoutineHistoryTimeAdapter = {
    toServer: data => data,
    toClient: data => {
      data = GeneralAdapter.toClient(data);
      let { error, result } = data;
      if (error) return { error };
      return { error, result };
    }
  };
  
  export const getRoutineHistoryTime = (adapter => async (robot_id) => {
    try {
      const url = '/config/history/routine_histories?robot_id=';
      let response = await config.get(url + robot_id);
      response = adapter.toClient(response.data);
      const { error, result } = response;
      if (error) throw error;
      console.log('result'+Object.entries(result))
      return result;
    } catch (e) {
      throw e;
    }
  })(getRoutineHistoryTimeAdapter);

  const RoutineHistoryAdapter = {
    toServer: data => {
        return data;
    },
    toClient: data => {
        data = GeneralAdapter.toClient(data);
        let { error, result } = data;
        if (error) return { error };
        return { result};
    }

  };
  
  const mapRoutineHistoryToClient = result => {
    return {
      ...result,
      start_time: result.started_at && moment(result.start_time),
      end_time: result.ended_at && moment(result.end_time),
    };
  };
  
  const mapFiltersToQuery = filters => {
    const queries = [];
    const { robotId,startTime, endTime, direction, firstKey,lastKey,limit } = filters['filters'];
    // Robot Id
    queries.push(`${encodeURIComponent('robot_id')}=${encodeURIComponent(robotId)}`);
  
    // Start time
    if (startTime) {
      const startTimeUTC = moment.utc(startTime).format('YYYY-MM-DD HH:mm:ss');
      queries.push(`${encodeURIComponent('created_at__gtq')}=${encodeURIComponent(startTimeUTC)}`);
    }
    
    // End time
    if (endTime) {
      const endTimeUTC = moment.utc(endTime).format('YYYY-MM-DD HH:mm:ss');
      queries.push(`${encodeURIComponent('created_at__ltq')}=${encodeURIComponent(endTimeUTC)}`);
    }
    //Direction
    if(direction)
    queries.push(`${encodeURIComponent('direction')}=${encodeURIComponent(direction)}`);

    //First key
    // if(firstKey)
    queries.push(`${encodeURIComponent('first_key')}=${encodeURIComponent(firstKey)}`);

    //Last key
    // if(lastKey)
    queries.push(`${encodeURIComponent('last_key')}=${encodeURIComponent(lastKey)}`);

    // Page Size
    if (limit)
      queries.push(`${encodeURIComponent('limit')}=${encodeURIComponent(limit)}`);
  
    return queries.join('&');
  };
  
  export const getRoutineHistoryPages = (adapter => async (filters = {}) => {
    try {

      const query = mapFiltersToQuery(filters);
      const url = `/config/history/routine_histories?${query}`;
      let response = await config.get(url);
      response = adapter.toClient(response.data);
      const { error, result } = response;
      if (error) throw error;
      return result;
    } catch(e) {
      throw e;
    }
  })(RoutineHistoryAdapter);

  