import React from "react";
import { connect } from "react-redux";
import uuid from "uuid";
import {
  withStyles,
  Grid,
  IconButton,
  Card,
  CardMedia,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  ListItemIcon,
  Typography,
  TextField,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  NavigateBefore as PrevIcon,
  NavigateNext as NextIcon,
  Edit as EditIcon,
  Check as SubmitIcon,
  Close as CancelIcon,
  EditLocation as LocationIcon,
} from "@material-ui/icons";
import Loader from "react-loader-advanced";
import ReactCursorPosition from "react-cursor-position";
import { MapInteractionCSS } from "react-map-interaction";
import { cloneDeep } from "lodash";

import { robotActions } from "src/redux/actions";
import { getText } from "src/utils/MultilingualLoader";
import { getImagePosition, getMapPosition } from "../RobotMap";
import RobotMapItem from "./RobotMapItem";
import SearchBar from "./SearchBar";
import MapCalibration from "./MapCalibration";
import CopyRoute from "./CopyRoute";
import EditWaypoint from "./EditRoute/EditWaypoint";
import DropDownSelectorHorizontal from "src/components/Selectors/DropDownSelectorHorizontal";
import SelectMapFloor from './SelectMapFloor'

const mapStateToProps = (state) => {
  const { allRobots, robotMapLists, selected, robotConfigs } = state.robot;
  const robotMapList = selected && robotMapLists[selected.id];
  const robotConfig = selected && robotConfigs[selected.id];
  const mapInUse = robotConfig && robotConfig.current_map_id;
  const routeInUse = robotConfig && robotConfig.current_route_id;
  const { sites, curSite } = state.site;
  const site = sites && sites.find((site) => site.id === curSite);
  return {
    robot: selected,
    robots: allRobots,
    robotMapList,
    mapInUse,
    routeInUse,
    site,
  };
};

const mapDispatchToProps = {
  getRobotMapList: (robotId) =>
    robotActions.getRobotConfigMapsAndRoutes(robotId),
  copyMap: (from, to, mapId) => robotActions.copyMap(from, to, mapId),
  copyRoute: (from, to, mapIdFrom, mapIdTo, routeId) =>
    robotActions.copyRoute(from, to, mapIdFrom, mapIdTo, routeId),
  updateMap: (robotId, mapId, data) =>
    robotActions.updateMap(robotId, mapId, data),
  updateRoute: (robotId, mapId, routeId, data) =>
    robotActions.updateRoute(robotId, mapId, routeId, data),
  deleteMap: (robotId, mapId) => robotActions.deleteMap(robotId, mapId),
  deleteRoute: (robotId, mapId, routeId) =>
    robotActions.deleteRoute(robotId, mapId, routeId),
  backupMap: (robotId, mapId) => robotActions.backupMap(robotId, mapId),
  backupRoute: (robotId, mapId, routeId) =>
    robotActions.backupRoute(robotId, mapId, routeId),
  getRobotConfigMap: (robotId, mapId, routeId) =>
    robotActions.getRobotConfigMap(robotId, mapId, routeId),
  getRobotConfigs: (robotId) => robotActions.getRobotConfigs(robotId)
};

const styles = (theme) => ({
  searchBar: {
    height: (window.innerHeight - 48) * 0.05,
  },
  map: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  calibratedMap: {
    height: (window.innerHeight - 48) * 0.8,
  },
  iconButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  textAlignRight: {
    textAlign: "right",
  },
  tableCell: {
    paddingTop: 4,
    paddingBottom: 4,
    borderStyle: "none",
  },
  buttonMap: {
    margin: theme.spacing(1),
    marginLeft: 0,
    color: "white",
    textTransform: "initial",
  },
  buttonOperation: {
    maxWidth: 400,
    background: "rgba(76,127,114,0.40)",
    borderRadius: "6px",
    border: "1px solid",
    width: "100%",
    height: "100%",
  },
  buttonOperationLabel: {
    fontSize: "1rem",
    textTransform: "initial",
  },
});

function multiplyTransformMatrices(matrixA, matrixB) {
  if (matrixA.length !== 4 || matrixB.length !== 4) return [1, 0, 0, 1];
  return [
    matrixA[0] * matrixB[0] + matrixA[1] * matrixB[2],
    matrixA[0] * matrixB[1] + matrixA[1] * matrixB[3],
    matrixA[2] * matrixB[0] + matrixA[3] * matrixB[2],
    matrixA[2] * matrixB[1] + matrixA[3] * matrixB[3],
  ];
}

class RobotMapList extends React.Component {
  state = {
    curAction: null,
    image: null,
    imageSize: null,
    scale: 1,
    translation: { x: 0, y: 0 },
    // 1 for the first map index, null for not selected
    curMapIndex: null,
    mapName: "",
    editingMapName: false,
    editReachable: false,
    elevatorUnreachable: false,
    curRouteId: "",
    routeName: "",
    editingRouteName: false,
    elevatorBase: null,
    updRouteWayPointData: null,
    customizedRouteId: "",
    customizedRouteName: "",
    customizedWaypoints: [],
    calibrationData: null,
    queryId: "",
    menuAnchorEl: null,
  };

  componentDidMount() {
    const { robot, getRobotMapList } = this.props;
    getRobotMapList(robot && robot.id);
  }

  componentDidUpdate(prevProps, prevState) {
    const { robotMapList } = this.props;
    const { curMapIndex } = this.state;

    if (
      robotMapList &&
      robotMapList.maps &&
      curMapIndex <= robotMapList.maps.length
    ) {
      const mapId =
        robotMapList.maps[curMapIndex - 1] &&
        robotMapList.maps[curMapIndex - 1].id;
      const prevMapId =
        prevProps.robotMapList &&
        prevProps.robotMapList.maps &&
        prevProps.robotMapList.maps[prevState.curMapIndex - 1] &&
        prevProps.robotMapList.maps[prevState.curMapIndex - 1].id;
      if (mapId !== prevMapId) {
        const mapUrl =
          robotMapList.maps[curMapIndex - 1] &&
          robotMapList.maps[curMapIndex - 1].mapUrl;
        this.setState(
          {
            mapName: "",
            elevatorUnreachable: false,
            editingMapName: false,
            editReachable: false,
            curRouteId: "",
            routeName: "",
            editingRouteName: false,
            elevatorBase: null,
            updRouteWayPointData: null,
            image: null,
            imageSize: null,
            curAction: null,
            scale: 1,
            translation: { x: 0, y: 0 },
            customizedRouteId: "",
            customizedRouteName: "",
            customizedWaypoints: [],
            calibrationData: null,
            menuAnchorEl: null,
          },
          () => mapUrl && this.loadImage(mapUrl)
        );
      }
    }

    // show the first map when map list is loaded
    if (
      robotMapList &&
      robotMapList.loading === false &&
      robotMapList.maps &&
      robotMapList.maps.length > 0 &&
      (!prevProps.robotMapList || prevProps.robotMapList.loading === true)
    ) {
      this.setState({ curMapIndex: 1 });
    }

    // set current map to the new last when the previous last is deleted
    if (
      robotMapList &&
      robotMapList.maps &&
      curMapIndex > robotMapList.maps.length
    ) {
      if (robotMapList.maps.length > 0) {
        this.setState({ curMapIndex: robotMapList.maps.length });
      } else {
        this.setState({
          curMapIndex: null,
          curRouteId: "",
          image: null,
          imageSize: null,
        });
      }
    }

    // render again when the calibrated map has been referenced
    if (
      this.state.curAction === "calibrate_map" &&
      prevState.curAction !== "calibrate_map"
    ) {
      this.forceUpdate();
    }
  }

  cmRef = (map) => {
    this.calibratedMap = map;
  };

  loadImage(mapSrc) {
    const image = new window.Image();
    this.setState({
      image: null,
      imageSize: null,
    });
    image.onload = () => {
      this.setState({
        image,
        imageSize: {
          width: image.width,
          height: image.height,
        },
      });
    };
    image.src = mapSrc;
  }

  sendWSMessage = (message, options) => {
    if (window.wsAPP) window.wsAPP.sendMsg(message, options);
  };

  onCopyMapClick = (robotId, curMap) => {
    const { robot, copyMap } = this.props;
    if (robot && curMap && robotId) {
      copyMap(robot.id, robotId, curMap.id);
    }
  };

  confirmCopyRoute = (from, mapIdFrom, routeId) => (to, mapIdTo) => () => {
    const { copyRoute } = this.props;
    if (from && to && mapIdFrom && mapIdTo && routeId) {
      copyRoute(from, to, mapIdFrom, mapIdTo, routeId);
    }
    this.setState({ curAction: null });
  };

  onDownloadClick = (mapId, files) => () => {
    const { robot } = this.props;
    this.sendWSMessage({
      id: uuid(),
      act: "robot.download_map",
      arg: {
        robot_id: robot && robot.id,
        map_id: mapId,
        files,
      },
    });
  };

  onMapNameSubmit = (map, name) => {
    const { robot, updateMap } = this.props;
    if (!robot || !map) return;
    updateMap(robot.id, map.id, { ...map.rawData, name });
  };

  onElevatorUnreachable = (map, elevatorUnreachable) => {
    const { robot, updateMap } = this.props;
    if (!robot || !map) return;
    updateMap(robot.id, map.id, {
      ...map.rawData,
      elevator_unreachable: elevatorUnreachable,
    });
  };

  onCalibrationSubmit = (map) => {
    const { robot, updateMap } = this.props;
    const { calibrationData } = this.state;
    if (!robot || !map) return;
    const degree = (calibrationData.rotateDegree / 360) * 2 * Math.PI;
    const rotateMatrix = degree
      ? [
          Math.cos(degree),
          Math.sin(degree),
          -Math.sin(degree),
          Math.cos(degree),
        ]
      : [1, 0, 0, 1];
    const calibrationMatrix = multiplyTransformMatrices(
      calibrationData.resizeMatrix || [1, 0, 0, 1],
      rotateMatrix
    );
    if (window.confirm(`${getText("calibrate_map")}: ${calibrationMatrix}`)) {
      updateMap(robot.id, map.id, {
        ...map.rawData,
        calibration: {
          b_0: 0,
          b_1: 0,
          a_00: calibrationMatrix[0],
          a_01: calibrationMatrix[1],
          a_10: calibrationMatrix[2],
          a_11: calibrationMatrix[3],
        },
      });
    }
    this.setState({
      curAction: null,
      calibrationData: null,
    });
  };

  onRouteNameSubmit = (map, route, name) => {
    const { robot, updateRoute } = this.props;
    if (!robot || !map || !route) return;
    updateRoute(robot.id, map.id, route.id, { ...route, name });
  };

  onRouteWaypointsSubmit = (map, route) => {
    const { robot, updateRoute } = this.props;
    const { updRouteWayPointData } = this.state;
    const wayPoints = updRouteWayPointData && updRouteWayPointData.wayPoints;
    if (!robot || !map || !route) return;
    updateRoute(robot.id, map.id, route.id, {
      ...route,
      way_points: wayPoints,
    });
    this.setState({
      curAction: null,
      updRouteWayPointData: null,
    });
  };

  reloadMapAndRoute = () => {
    const { robotMapList, robot } = this.props;
    const { curMapIndex, curRouteId } = this.state;
    if (!robot || !curMapIndex) return;
    const mapId =
      robotMapList &&
      robotMapList.maps &&
      robotMapList.maps[curMapIndex - 1] &&
      robotMapList.maps[curMapIndex - 1].id;
    const {id: robot_id} = robot
    let arg = {
      robot_id,
    };
    if (mapId) arg.map_id = mapId;
    if (curRouteId) arg.route_id = curRouteId;
    this.sendWSMessage(
      {
        id: uuid(),
        act: "robot.use_map_and_route",
        arg,
      },
      {
        hasRes: true,
        robotId: robot.id,
        callback: () => {
          const {getRobotConfigMap, getRobotConfigs} = this.props
          // get new map after replacing robot map
          getRobotConfigMap(robot_id, mapId, curRouteId)
          getRobotConfigs(robot_id)
        }
      }
    );
  };

  onElevatorBaseSubmit = (map) => {
    const { robot, updateMap } = this.props;
    const { elevatorBase } = this.state;
    if (!elevatorBase.id) return alert(getText("input_error_elevator_base_id"));
    updateMap(robot.id, map.id, {
      ...map.rawData,
      elevator_base: elevatorBase,
    });
    this.setState({ curAction: null, elevatorBase: null });
  };

  deleteMap = (mapId) => {
    const { robot, deleteMap, mapInUse } = this.props;
    if (mapId === mapInUse) return alert(getText("cannot_delete_map_in_use"));
    if (
      robot &&
      robot.id &&
      mapId &&
      window.confirm(
        `${getText("delete_map")}${getText("words_space")}${mapId}`
      )
    ) {
      deleteMap(robot.id, mapId);
    }
  };

  backupMap = (mapId) => {
    const { robot, backupMap } = this.props;
    if (robot && robot.id && mapId) {
      backupMap(robot.id, mapId);
    }
  };

  deleteRoute = (mapId, routeId) => {
    const { robot, deleteRoute, routeInUse } = this.props;
    if (routeId === routeInUse)
      return alert(getText("cannot_delete_route_in_use"));
    if (
      robot &&
      robot.id &&
      mapId &&
      routeId &&
      window.confirm(
        `${getText("delete_route")}${getText("words_space")}${routeId}`
      )
    ) {
      deleteRoute(robot.id, mapId, routeId);
    }
  };

  backupRoute = (mapId, routeId) => {
    const { robot, backupRoute } = this.props;
    if (robot && robot.id && mapId && routeId) {
      backupRoute(robot.id, mapId, routeId);
    }
  };

  createCustomizedRoute(map, route) {
    const { robot, updateRoute } = this.props;
    const {
      customizedRouteName,
      customizedRouteId,
      customizedWaypoints,
    } = this.state;
    if (!robot || !map || !route) return;
    const newMetaId =
      route.meta_id &&
      route.meta_id.substring(0, route.meta_id.lastIndexOf(":")) +
        customizedRouteId;
    const customizedRoute = Object.assign(cloneDeep(route), {
      id: customizedRouteId,
      meta_id: newMetaId,
      name: customizedRouteName,
    });
    if (customizedRoute.way_points) {
      customizedRoute.way_points = customizedRoute.way_points.map(
        (waypoint, idx) =>
          customizedWaypoints.includes(idx)
            ? waypoint
            : {
                ...waypoint,
                actions: [],
              }
      );
    }
    updateRoute(robot.id, map.id, customizedRouteId, customizedRoute);
  }

  switchMap = (num) => {
    this.setState((state, props) => ({
      curMapIndex: state.curMapIndex + num,
    }));
  };

  submitQuery = () => {
    const { robotMapList } = this.props;
    const { queryId } = this.state;
    if (!queryId) return alert(getText("input_error_query_mapId"));
    if (robotMapList && robotMapList.maps) {
      const matchedIdx = robotMapList.maps.findIndex(
        (map) => map.id === queryId
      );
      if (matchedIdx !== -1) this.setState({ curMapIndex: matchedIdx + 1 });
      else return alert(getText("map_id_not_found"));
    }
  };

  arePointsClose = (p1, p2) => {
    if (p1 && p1.x && p1.y && p2 && p2.x && p2.y) {
      return (
        Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2)) <= 5
      );
    }
    return false;
  };

  onMouseMove = (mapConfig, imageSize, displaySize) => (position) => {
    const { curAction } = this.state;
    const getMouseMoveMapPosition = getMapPosition(
      mapConfig,
      imageSize,
      displaySize
    );
    const { updRouteWayPointData } = this.state;
    switch (curAction) {
      case "move_point":
        const movingPointIdx = updRouteWayPointData.movingPointIdx;
        const updWayPoints =
          (updRouteWayPointData && updRouteWayPointData.wayPoints) || [];
        if (
          movingPointIdx !== null &&
          updWayPoints[movingPointIdx] &&
          updWayPoints[movingPointIdx].point
        ) {
          const newPosition = getMouseMoveMapPosition(position.x, position.y);
          updWayPoints[movingPointIdx].point.x = newPosition.x;
          updWayPoints[movingPointIdx].point.y = newPosition.y;
          this.setState((state, props) => ({
            updRouteWayPointData: {
              ...state.updRouteWayPointData,
              wayPoints: updWayPoints,
            },
          }));
        }
        return;
      default:
        return;
    }
  };

  onMapClick = (mapConfig, imageSize, displaySize) => (position) => {
    const { curAction } = this.state;
    const getClickMapPosition = getMapPosition(
      mapConfig,
      imageSize,
      displaySize
    );
    const getImgPosition = getImagePosition(mapConfig, imageSize, displaySize);
    const { robotMapList } = this.props;
    const {
      curMapIndex,
      curRouteId,
      updRouteWayPointData,
      customizedWaypoints,
    } = this.state;
    const mapId =
      curMapIndex &&
      robotMapList &&
      robotMapList.maps &&
      robotMapList.maps[curMapIndex - 1] &&
      robotMapList.maps[curMapIndex - 1].id;
    const curMap =
      robotMapList &&
      robotMapList.maps &&
      curMapIndex &&
      robotMapList.maps[curMapIndex - 1];
    const curRoute =
      curMap &&
      curMap.mapRoutes &&
      curMap.mapRoutes.find((route) => route.id === curRouteId);
    const wayPoints = (curRoute && curRoute.way_points) || [];
    const updWayPoints =
      (updRouteWayPointData && updRouteWayPointData.wayPoints) || [];

    switch (curAction) {
      case "set_base":
        const basePosition = getClickMapPosition(position.x, position.y);
        this.setState((state, props) => ({
          elevatorBase: {
            ...state.elevatorBase,
            base: {
              x: basePosition.x,
              y: basePosition.y,
            },
          },
          curAction: "set_elevator_base",
        }));
        return;
      case "set_enter":
        const enterPosition = getClickMapPosition(position.x, position.y);
        this.setState((state, props) => ({
          elevatorBase: {
            ...state.elevatorBase,
            enter: {
              x: enterPosition.x,
              y: enterPosition.y,
            },
          },
          curAction: "set_elevator_base",
        }));
        return;
      case "set_exit":
        const exitPosition = getClickMapPosition(position.x, position.y);
        this.setState((state, props) => ({
          elevatorBase: {
            ...state.elevatorBase,
            exit: {
              x: exitPosition.x,
              y: exitPosition.y,
            },
          },
          curAction: "set_elevator_base",
        }));
        return;
      case "delete_point":
        for (let idx = updWayPoints.length - 1; idx >= 0; idx--) {
          const point = updWayPoints[idx] && updWayPoints[idx].point;
          const pointSizedImgPosition =
            (point && point.x && point.y && getImgPosition(point.x, point.y)) ||
            {};
          if (this.arePointsClose(pointSizedImgPosition, position)) {
            updWayPoints && updWayPoints.splice(idx, 1);
            this.setState((state, props) => ({
              updRouteWayPointData: {
                ...state.updRouteWayPointData,
                wayPoints: updWayPoints,
              },
            }));
            break;
          }
        }
        return;
      case "edit_point":
        for (let idx = updWayPoints.length - 1; idx >= 0; idx--) {
          const point = updWayPoints[idx] && updWayPoints[idx].point;
          const pointSizedImgPosition =
            (point && point.x && point.y && getImgPosition(point.x, point.y)) ||
            {};

          if (this.arePointsClose(pointSizedImgPosition, position)) {
            this.setState((state, props) => ({
              updRouteWayPointData: {
                ...state.updRouteWayPointData,
                selectedPointIdx: idx,
              },
            }));
            break;
          }
        }
        return;
      case "add_point_before":
      case "add_point_after":
        const basePointIdx =
          updRouteWayPointData && updRouteWayPointData.basePointIdx;
        // set the base point to add before/after
        if (basePointIdx === null) {
          for (let idx = updWayPoints.length - 1; idx >= 0; idx--) {
            const point = updWayPoints[idx] && updWayPoints[idx].point;
            const pointSizedImgPosition =
              (point &&
                point.x &&
                point.y &&
                getImgPosition(point.x, point.y)) ||
              {};

            if (this.arePointsClose(pointSizedImgPosition, position)) {
              this.setState((state, props) => ({
                updRouteWayPointData: {
                  ...state.updRouteWayPointData,
                  basePointIdx: idx,
                },
              }));
              break;
            }
          }
          return;
        }
        // add a new way point
        else if (mapId && curRouteId) {
          let newPointPosition = Object.assign({}, position);
          for (let idx = updWayPoints.length - 1; idx >= 0; idx--) {
            const point = updWayPoints[idx] && updWayPoints[idx].point;
            const pointSizedImgPosition =
              (point &&
                point.x &&
                point.y &&
                getImgPosition(point.x, point.y)) ||
              {};

            if (this.arePointsClose(pointSizedImgPosition, newPointPosition)) {
              newPointPosition = pointSizedImgPosition;
              break;
            }
          }
          newPointPosition = getClickMapPosition(
            newPointPosition.x,
            newPointPosition.y
          );
          const newPoint = {
            id: new Date().getTime(),
            name: "",
            actions: [],
            isInterpolated: false,
            mapId: mapId,
            routeId: curRouteId,
            point: {
              x: newPointPosition.x,
              y: newPointPosition.y,
            },
          };
          let newPointIdx = basePointIdx;
          if (curAction === "add_point_after") {
            newPointIdx += 1;
          }
          updWayPoints && updWayPoints.splice(basePointIdx + 1, 0, newPoint);
          this.setState((state, props) => ({
            curAction: "edit_point",
            updRouteWayPointData: {
              ...state.updRouteWayPointData,
              basePointIdx: null,
              selectedPointIdx: newPointIdx,
              wayPoints: updWayPoints,
            },
          }));
        }
        return;
      case "move_point":
        const movingPointIdx =
          updRouteWayPointData && updRouteWayPointData.movingPointIdx;
        if (movingPointIdx === null) {
          for (let idx = updWayPoints.length - 1; idx >= 0; idx--) {
            const point = updWayPoints[idx] && updWayPoints[idx].point;
            const pointSizedImgPosition =
              (point &&
                point.x &&
                point.y &&
                getImgPosition(point.x, point.y)) ||
              {};
            if (this.arePointsClose(pointSizedImgPosition, position)) {
              this.setState((state, props) => ({
                updRouteWayPointData: {
                  ...state.updRouteWayPointData,
                  movingPointIdx: idx,
                },
              }));
              break;
            }
          }
        } else {
          this.setState((state, props) => ({
            updRouteWayPointData: {
              ...state.updRouteWayPointData,
              movingPointIdx: null,
            },
          }));
        }
        return;
      case "customize_waypoints":
        for (
          let wayPointIdx = 1;
          wayPointIdx < wayPoints.length - 1;
          wayPointIdx++
        ) {
          const point = wayPoints[wayPointIdx] && wayPoints[wayPointIdx].point;
          const pointSizedImgPosition =
            (point && point.x && point.y && getImgPosition(point.x, point.y)) ||
            {};
          if (this.arePointsClose(pointSizedImgPosition, position)) {
            const nextCustomizedWaypoints = Object.assign(
              [],
              customizedWaypoints
            );
            const idx = nextCustomizedWaypoints.indexOf(wayPointIdx);
            if (idx !== -1) {
              nextCustomizedWaypoints.splice(idx, 1);
            } else {
              nextCustomizedWaypoints.push(wayPointIdx);
            }
            this.setState((state, props) => ({
              customizedWaypoints: nextCustomizedWaypoints,
            }));
            break;
          }
        }
        return;
      default:
        return;
    }
  };

  render() {
    const { classes, robot, robots, robotMapList, site } = this.props;
    const {
      curAction,
      image,
      imageSize,
      scale,
      translation,
      curMapIndex,
      mapName,
      editingMapName,
      elevatorUnreachable,
      editReachable,
      curRouteId,
      routeName,
      editingRouteName,
      elevatorBase: stateElevatorBase,
      updRouteWayPointData,
      menuAnchorEl,
      queryId,
      calibrationData,
      customizedRouteId,
      customizedRouteName,
      customizedWaypoints,
    } = this.state;
    const curMap =
      robotMapList &&
      robotMapList.maps &&
      curMapIndex &&
      robotMapList.maps[curMapIndex - 1];
    const routeOptions =
      curMap && curMap.mapRoutes
        ? curMap.mapRoutes.map((route) => ({
            value: route.id,
            label: `${route.name} ${route.id}`,
          }))
        : [];
    const curRoute =
      curMap &&
      curMap.mapRoutes &&
      curMap.mapRoutes.find((route) => route.id === curRouteId);

    // map size with original radio
    const maxMapWidth = ((window.innerWidth * 0.8 - 48) * 10) / 12;
    const maxMapHeight = (window.innerHeight - 48) * 0.7;
    let mapWidthWithRadio = null;
    let mapHeightWithRadio = null;
    if (imageSize) {
      const radio =
        imageSize.height !== 0 ? imageSize.width / imageSize.height : 0;
      mapWidthWithRadio = maxMapWidth;
      mapHeightWithRadio = radio !== 0 ? mapWidthWithRadio / radio : 0;
      if (mapHeightWithRadio > maxMapHeight) {
        mapHeightWithRadio = maxMapHeight;
        mapWidthWithRadio = mapHeightWithRadio * radio;
      }
    }

    return (
      <>
        <Grid
          container={true}
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={0}
        >
          <Grid item xs={1}>
            <IconButton
              className={classes.iconButton}
              onClick={() => this.switchMap(-1)}
              disabled={!curMapIndex || curMapIndex === 1}
            >
              <PrevIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={10}
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item className={classes.searchBar}>
              <SearchBar
                mapOptions={(robotMapList && robotMapList.maps) || []}
                mapIndex={curMapIndex}
                setMapIdx={(idx) => this.setState({ curMapIndex: idx })}
                queryId={queryId}
                setQuery={(id) => this.setState({ queryId: id })}
                submitQuery={this.submitQuery}
              />
            </Grid>
            {curAction === "calibrate_map" ? (
              <Grid item className={classes.calibratedMap}>
                <MapCalibration
                  imageSrc={curMap && curMap.mapUrl}
                  site={site}
                  calibration={curMap && curMap.calibration}
                  imageRatio={
                    imageSize.width && imageSize.height
                      ? imageSize.width / imageSize.height
                      : 1
                  }
                  calibrationData={calibrationData}
                  setCalibrationData={(data) =>
                    this.setState({ calibrationData: data })
                  }
                />
              </Grid>
            ) : (
              <Grid item className={classes.map}>
                <Card>
                  {/* Grid to centralize the map */}
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                  >
                    <Loader
                      style={{ zIndex: 0, minHeight: maxMapHeight }}
                      show={!image ? true : false}
                      message={
                        robotMapList &&
                        robotMapList.maps &&
                        robotMapList.maps.length === 0
                          ? getText("no_map")
                          : getText("loading_map")
                      }
                      hideContentOnLoad
                    >
                      {image && (
                        <CardMedia className={classes.map} src="img">
                          <MapInteractionCSS
                            scale={scale}
                            translation={translation}
                            onChange={({ scale, translation }) => {
                              this.setState({
                                scale,
                                translation:
                                  scale === 1 ? { x: 0, y: 0 } : translation,
                              });
                            }}
                            minScale={1}
                            maxScale={10}
                          >
                            <ReactCursorPosition
                              // force posotion to update when scale changes
                              key={scale}
                            >
                              <RobotMapItem
                                image={image}
                                mapWidth={mapWidthWithRadio}
                                mapHeight={mapHeightWithRadio}
                                scale={scale}
                                curAction={curAction}
                                route={curRoute}
                                updRouteWayPointData={updRouteWayPointData}
                                newElevatorBase={stateElevatorBase}
                                customizedWaypoints={customizedWaypoints}
                                onMapClick={this.onMapClick(
                                  curMap && curMap.mapConfig,
                                  imageSize,
                                  {
                                    width: mapWidthWithRadio,
                                    height: mapHeightWithRadio,
                                  }
                                )}
                                onMouseMove={this.onMouseMove(
                                  curMap && curMap.mapConfig,
                                  imageSize,
                                  {
                                    width: mapWidthWithRadio,
                                    height: mapHeightWithRadio,
                                  }
                                )}
                                getImagePosition={getImagePosition(
                                  curMap && curMap.mapConfig,
                                  imageSize,
                                  {
                                    width: mapWidthWithRadio,
                                    height: mapHeightWithRadio,
                                  }
                                )}
                              />
                            </ReactCursorPosition>
                          </MapInteractionCSS>
                        </CardMedia>
                      )}
                    </Loader>
                  </Grid>
                </Card>
              </Grid>
            )}
            <Grid item>
              {curMap &&
                (() => {
                  switch (curAction) {
                    case "calibrate_map":
                      return (
                        <Grid
                          item
                          container={true}
                          direction="row"
                          justify="flex-start"
                          alignItems="stretch"
                          spacing={3}
                        >
                          <Grid item xs={3}>
                            <Button
                              className={classes.buttonOperation}
                              onClick={() => this.onCalibrationSubmit(curMap)}
                            >
                              <ListItemIcon>
                                <SubmitIcon />
                              </ListItemIcon>
                              <Typography
                                className={classes.buttonOperationLabel}
                              >
                                {getText("submit")}
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={3}>
                            <Button
                              className={classes.buttonOperation}
                              onClick={() =>
                                this.setState({
                                  curAction: null,
                                  calibrationData: null,
                                })
                              }
                            >
                              <ListItemIcon>
                                <CancelIcon />
                              </ListItemIcon>
                              <Typography
                                className={classes.buttonOperationLabel}
                              >
                                {getText("cancel")}
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    case "edit_route":
                    case "add_point_before":
                    case "add_point_after":
                    case "delete_point":
                    case "edit_point":
                    case "move_point":
                      return (
                        <Grid
                          item
                          container={true}
                          direction="column"
                          justify="flex-start"
                          alignItems="stretch"
                          spacing={3}
                        >
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.buttonMap}
                              onClick={() =>
                                this.setState((state, props) => ({
                                  curAction: "edit_point",
                                  updRouteWayPointData: {
                                    ...state.updRouteWayPointData,
                                    basePointIdx: null,
                                    selectedPointIdx: null,
                                    movingPointIdx: null,
                                  },
                                }))
                              }
                            >
                              {getText("edit_point")}
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.buttonMap}
                              onClick={() =>
                                this.setState((state, props) => ({
                                  curAction: "add_point_before",
                                  updRouteWayPointData: {
                                    ...state.updRouteWayPointData,
                                    basePointIdx: null,
                                    selectedPointIdx: null,
                                    movingPointIdx: null,
                                  },
                                }))
                              }
                            >
                              {getText("add_point_before")}
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.buttonMap}
                              onClick={() =>
                                this.setState((state, props) => ({
                                  curAction: "add_point_after",
                                  updRouteWayPointData: {
                                    ...state.updRouteWayPointData,
                                    basePointIdx: null,
                                    selectedPointIdx: null,
                                    movingPointIdx: null,
                                  },
                                }))
                              }
                            >
                              {getText("add_point_after")}
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.buttonMap}
                              onClick={() =>
                                this.setState((state, props) => ({
                                  curAction: "delete_point",
                                  updRouteWayPointData: {
                                    ...state.updRouteWayPointData,
                                    basePointIdx: null,
                                    selectedPointIdx: null,
                                    movingPointIdx: null,
                                  },
                                }))
                              }
                            >
                              {getText("delete_point")}
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.buttonMap}
                              onClick={() =>
                                this.setState((state, props) => ({
                                  curAction: "move_point",
                                  updRouteWayPointData: {
                                    ...state.updRouteWayPointData,
                                    basePointIdx: null,
                                    selectedPointIdx: null,
                                    movingPointIdx: null,
                                  },
                                }))
                              }
                            >
                              {getText("move_point")}
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.buttonMap}
                              onClick={() =>
                                this.onRouteWaypointsSubmit(curMap, curRoute)
                              }
                            >
                              {getText("submit")}
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.buttonMap}
                              onClick={() =>
                                this.setState({
                                  curAction: null,
                                  updRouteWayPointData: null,
                                })
                              }
                            >
                              {getText("cancel")}
                            </Button>
                          </Grid>
                          <Grid item>
                            {updRouteWayPointData &&
                              updRouteWayPointData.selectedPointIdx !== null &&
                              updRouteWayPointData.wayPoints && (
                                <EditWaypoint
                                  waypoint={
                                    updRouteWayPointData.wayPoints[
                                      updRouteWayPointData.selectedPointIdx
                                    ]
                                  }
                                  updWaypoint={(point) => {
                                    const wayPoints = Object.assign(
                                      [],
                                      updRouteWayPointData.wayPoints
                                    );
                                    wayPoints[
                                      updRouteWayPointData.selectedPointIdx
                                    ] = point;
                                    this.setState((state) => ({
                                      updRouteWayPointData: {
                                        ...state.updRouteWayPointData,
                                        wayPoints,
                                      },
                                    }));
                                  }}
                                />
                              )}
                          </Grid>
                        </Grid>
                      );
                    case "set_elevator_base":
                    case "set_base":
                    case "set_enter":
                    case "set_exit":
                      return (
                        <Grid
                          item
                          container={true}
                          direction="row"
                          justify="flex-start"
                          alignItems="stretch"
                          spacing={3}
                        >
                          <Grid item xs={3}>
                            <TextField
                              variant="outlined"
                              label="Id"
                              onChange={(event) =>
                                this.setState({
                                  elevatorBase: {
                                    ...this.state.elevatorBase,
                                    id: event.target.value,
                                  },
                                })
                              }
                              value={
                                (stateElevatorBase && stateElevatorBase.id) ||
                                ""
                              }
                            />
                          </Grid>
                          <Grid item xs={9}>
                            <Typography variant="subtitle1">
                              {getText("set_elevator_base_description")}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Button
                              className={classes.buttonOperation}
                              onClick={() =>
                                this.setState({ curAction: "set_base" })
                              }
                            >
                              <ListItemIcon>
                                <LocationIcon />
                              </ListItemIcon>
                              <Typography
                                className={classes.buttonOperationLabel}
                              >
                                {getText("set_base")}
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={3}>
                            <Button
                              className={classes.buttonOperation}
                              onClick={() =>
                                this.setState({ curAction: "set_enter" })
                              }
                            >
                              <ListItemIcon>
                                <LocationIcon />
                              </ListItemIcon>
                              <Typography
                                className={classes.buttonOperationLabel}
                              >
                                {getText("set_enter")}
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={3}>
                            <Button
                              className={classes.buttonOperation}
                              onClick={() =>
                                this.setState({ curAction: "set_exit" })
                              }
                            >
                              <ListItemIcon>
                                <LocationIcon />
                              </ListItemIcon>
                              <Typography
                                className={classes.buttonOperationLabel}
                              >
                                {getText("set_exit")}
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={3}>
                            <Button
                              className={classes.buttonOperation}
                              onClick={() => this.onElevatorBaseSubmit(curMap)}
                            >
                              <ListItemIcon>
                                <SubmitIcon />
                              </ListItemIcon>
                              <Typography
                                className={classes.buttonOperationLabel}
                              >
                                {getText("submit")}
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    case "customize_waypoints":
                      return (
                        <Grid
                          item
                          container={true}
                          direction="row"
                          justify="flex-start"
                          alignItems="stretch"
                          spacing={3}
                        >
                          <Grid item xs={3}>
                            <TextField
                              variant="outlined"
                              label="id"
                              disabled={true}
                              value={customizedRouteId || ""}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              variant="outlined"
                              label="name"
                              onChange={(event) =>
                                this.setState({
                                  customizedRouteName: event.target.value,
                                })
                              }
                              value={customizedRouteName || ""}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="subtitle1">
                              {getText("customize_waypoint_description")}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Button
                              className={classes.buttonOperation}
                              onClick={() =>
                                this.createCustomizedRoute(curMap, curRoute)
                              }
                            >
                              <Typography
                                className={classes.buttonOperationLabel}
                              >
                                {getText("create_route")}
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={3}>
                            <Button
                              className={classes.buttonOperation}
                              onClick={() =>
                                this.setState({
                                  curAction: null,
                                  customizedRouteId: "",
                                  customizedRouteName: "",
                                  customizedWaypoints: [],
                                })
                              }
                            >
                              <Typography
                                className={classes.buttonOperationLabel}
                              >
                                {getText("cancel")}
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    default:
                      return (
                        <>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  className={classes.tableCell}
                                  width={200}
                                >
                                  {getText("map_id")}:
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {curMap.id}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  className={classes.tableCell}
                                  width={200}
                                >
                                  {getText("map_name")}:
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {editingMapName ? (
                                    <>
                                      <input
                                        type="text"
                                        style={{ fontSize: "1rem" }}
                                        value={mapName}
                                        onChange={(event) =>
                                          this.setState({
                                            mapName: event.target.value,
                                          })
                                        }
                                      />
                                      <IconButton
                                        className={classes.iconButton}
                                        onClick={() => {
                                          this.onMapNameSubmit(curMap, mapName);
                                          this.setState({
                                            editingMapName: false,
                                          });
                                        }}
                                      >
                                        <SubmitIcon />
                                      </IconButton>
                                    </>
                                  ) : (
                                    <>
                                      {curMap.name}
                                      <IconButton
                                        className={classes.iconButton}
                                        onClick={() =>
                                          this.setState({
                                            editingMapName: true,
                                            mapName: curMap.name,
                                          })
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <SelectMapFloor classes={classes} curMap={curMap}/>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  className={classes.tableCell}
                                  width={200}
                                >
                                  {getText("elevator_unreachable")}:
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {editReachable ? (
                                    <>
                                      <DropDownSelectorHorizontal
                                        title={""}
                                        options={[
                                          { label: "是", value: true },
                                          { label: "否", value: false },
                                        ]}
                                        value={elevatorUnreachable}
                                        onChange={(event) => {
                                          this.setState({
                                            elevatorUnreachable:
                                              event.target.value,
                                          });
                                        }}
                                      />
                                      <IconButton
                                        className={classes.iconButton}
                                        onClick={() => {
                                          this.onElevatorUnreachable(
                                            curMap,
                                            elevatorUnreachable
                                          );
                                          this.setState({
                                            editReachable: false,
                                          });
                                        }}
                                      >
                                        <SubmitIcon />
                                      </IconButton>
                                    </>
                                  ) : (
                                    <>
                                      {curMap.elevatorUnreachable
                                        ? getText("yes")
                                        : getText("no")}
                                      <IconButton
                                        className={classes.iconButton}
                                        onClick={() =>
                                          this.setState({
                                            editReachable: true,
                                            elevatorUnreachable:
                                              curMap.elevatorUnreachable,
                                          })
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonMap}
                            disabled={robot.status !== "online"}
                            onClick={this.onDownloadClick(
                              curMap.id,
                              curMap.rawData && curMap.rawData.signed_files
                            )}
                          >
                            {getText("download_map_to_robot")}
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonMap}
                            onClick={(event) => {
                              this.setState({
                                curAction: "copy_map",
                                menuAnchorEl: event.currentTarget,
                              });
                            }}
                          >
                            {getText("copy_map_to")}
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonMap}
                            onClick={() =>
                              this.setState({
                                curAction: "set_elevator_base",
                                elevatorBase: Object.assign(
                                  {},
                                  curMap.elevatorBase
                                ),
                              })
                            }
                          >
                            {getText("set_elevator_base")}
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonMap}
                            onClick={() =>
                              this.setState({
                                curAction: "calibrate_map",
                                calibrationData: {
                                  resizeMatrix: [1, 0, 0, 1],
                                  rotateDegree: 0,
                                },
                              })
                            }
                          >
                            {getText("calibrate_map")}
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonMap}
                            disabled={robot.status !== "online"}
                            onClick={this.reloadMapAndRoute}
                          >
                            {getText("reload_map_and_route")}
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonMap}
                            onClick={() => this.deleteMap(curMap.id)}
                          >
                            {getText("delete_map")}
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonMap}
                            onClick={() => this.backupMap(curMap.id)}
                          >
                            {getText("backup_map")}
                          </Button>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  className={classes.tableCell}
                                  width={200}
                                >
                                  {getText("route")}:
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <DropDownSelectorHorizontal
                                    title={""}
                                    options={routeOptions}
                                    value={curRouteId}
                                    onChange={(event) =>
                                      this.setState({
                                        curRouteId: event.target.value,
                                      })
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                              {curRoute && (
                                <>
                                  <TableRow>
                                    <TableCell
                                      className={classes.tableCell}
                                      width={200}
                                    >
                                      {getText("route_id")}:
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {curRouteId}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      className={classes.tableCell}
                                      width={200}
                                    >
                                      {getText("route_name")}:
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {editingRouteName ? (
                                        <>
                                          <input
                                            type="text"
                                            style={{ fontSize: "1rem" }}
                                            value={routeName}
                                            onChange={(event) =>
                                              this.setState({
                                                routeName: event.target.value,
                                              })
                                            }
                                          />
                                          <IconButton
                                            className={classes.iconButton}
                                            onClick={() => {
                                              this.onRouteNameSubmit(
                                                curMap,
                                                curRoute,
                                                routeName
                                              );
                                              this.setState({
                                                editingRouteName: false,
                                              });
                                            }}
                                          >
                                            <SubmitIcon />
                                          </IconButton>
                                        </>
                                      ) : (
                                        <>
                                          {curRoute.name}
                                          <IconButton
                                            className={classes.iconButton}
                                            onClick={() =>
                                              this.setState({
                                                editingRouteName: true,
                                                routeName: curRoute.name || "",
                                              })
                                            }
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </>
                              )}
                            </TableBody>
                          </Table>
                          {curRoute && (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonMap}
                                onClick={(event) => {
                                  this.setState({
                                    curAction: "copy_route",
                                  });
                                }}
                              >
                                {getText("copy_route_to")}
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonMap}
                                onClick={() => {
                                  this.setState({
                                    curAction: "edit_point",
                                    updRouteWayPointData: {
                                      wayPoints: Object.assign(
                                        [],
                                        curRoute.way_points
                                      ),
                                      basePointIdx: null,
                                      selectedPointIdx: null,
                                    },
                                  });
                                }}
                              >
                                {getText("edit_route")}
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonMap}
                                onClick={() =>
                                  this.deleteRoute(curMap.id, curRouteId)
                                }
                              >
                                {getText("delete_route")}
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonMap}
                                onClick={() =>
                                  this.backupRoute(curMap.id, curRouteId)
                                }
                              >
                                {getText("backup_route")}
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonMap}
                                onClick={() => {
                                  this.setState({
                                    curAction: "customize_waypoints",
                                    customizedRouteId: new Date()
                                      .getTime()
                                      .toString(),
                                    customizedRouteName: "",
                                    // select start and end point by default
                                    customizedWaypoints:
                                      curRoute.way_points &&
                                      curRoute.way_points.length > 2
                                        ? [0, curRoute.way_points.length - 1]
                                        : [],
                                  });
                                }}
                              >
                                {getText("customize_waypoints")}
                              </Button>
                            </>
                          )}
                        </>
                      );
                  }
                })()}
            </Grid>
          </Grid>
          <Grid item xs={1} className={classes.textAlignRight}>
            <IconButton
              className={classes.iconButton}
              onClick={() => this.switchMap(1)}
              disabled={
                !curMapIndex ||
                (robotMapList &&
                  robotMapList.maps &&
                  curMapIndex >= robotMapList.maps.length)
              }
            >
              <NextIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Menu
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(menuAnchorEl)}
          onClose={() =>
            this.setState({
              curAction: null,
              menuAnchorEl: null,
            })
          }
        >
          {robots.map((robot) => (
            <MenuItem
              key={robot.id}
              onClick={() => {
                this.onCopyMapClick(robot.id, curMap);
                this.setState({
                  curAction: null,
                  menuAnchorEl: null,
                });
              }}
            >
              {robot.name}
            </MenuItem>
          ))}
        </Menu>
        {curAction === "copy_route" && (
          <CopyRoute
            robots={robots || []}
            onClose={() => this.setState({ curAction: null })}
            onConfirm={this.confirmCopyRoute(
              robot.id,
              curMap && curMap.id,
              curRoute && curRoute.id
            )}
          />
        )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RobotMapList));
