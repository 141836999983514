export const GET_TARGET_REQUEST = 'TARGET_GET_TARGET_REQUEST';
export const GET_TARGET_SUCCESS = 'TARGET_GET_TARGET_SUCCESS';
export const GET_TARGET_FAILURE = 'TARGET_GET_TARGET_FAILURE';

export const UPDATE_TARGET_LIST = 'TARGET_UPDATE_TARGET_LIST';

export const SET_PAGE = 'TARGET_SET_PAGE';
export const SET_FILTER = 'TARGET_SET_FILTER';

export const GET_OPTIONS_REQUEST = 'TARGET_GET_OPTIONS_REQUEST';
export const GET_OPTIONS_SUCCESS = 'TARGET_GET_OPTIONS_SUCCESS';
export const GET_OPTIONS_FAILURE = 'TARGET_GET_OPTIONS_FAILURE';

export const GET_MAPS_REQUEST = 'TARGET_GET_MAPS_REQUEST';
export const GET_MAPS_SUCCESS = 'TARGET_GET_MAPS_SUCCESS';
export const GET_MAPS_FAILURE = 'TARGET_GET_MAPS_FAILURE';

export const GET_ROBOT_REQUEST = 'TARGET_GET_ROBOT_REQUEST';
export const GET_ROBOT_SUCCESS = 'TARGET_GET_ROBOT_SUCCESS';
export const GET_ROBOT_FAILURE = 'TARGET_GET_ROBOT_FAILURE';

export const COPY_ROUTES_REQUEST = 'TARGET_COPY_ROUTES_REQUEST';
export const COPY_ROUTES_SUCCESS = 'TARGET_COPY_ROUTES_SUCCESS';
export const COPY_ROUTES_FAILURE = 'TARGET_COPY_ROUTES_FAILURE';