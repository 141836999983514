import React, {useState, useEffect, useMemo} from 'react';
import { connect } from 'react-redux';
import {
  Table, TableBody, TableRow, TableCell,TableHead,
  Dialog, 
  DialogTitle, 
  DialogContent,
  withStyles,
  Select,
  OutlinedInput,
  MenuItem,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  iconButton: {
    marginRight: '0.5rem',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selectorInput: {
    width: '140px',
    padding: '5px 20px 5px 20px',
  },
  middle: {
    verticalAlign: 'middle',
  },
});

const options = [
  {
    label: 'doing',
    value: 'doing',
  },
  {
    label: 'done',
    value: 'done',
  },
  {
    label: 'succeeded',
    value: 'succeeded',
  }
];

const mapStateToProps = (state) => {
  const { targetOptions } = state.target;
  const { curSite } = state.site;
  const targets = targetOptions[curSite] || [];
  return { targets };
};

const RoutineExecutionStatus = ({execution, robotId, targets, wsExecutionId, wsStageIndex, onClose, handleUpdate, classes}) => {
  const [editId, setEditId] = useState(null);
  const [optionValue, setOptionValue] = useState(null);

  const stages = useMemo(() => {
    if (execution.stages && targets) {
      const targetMap = new Map(targets.map(target => [String(target.id), target.label]));
      return execution.stages.map(stage => {
        if (stage.target_stats && stage.target_stats.left_target_ids) {
          return {
            ...stage,
            targets: stage.target_stats.left_target_ids.map(id => targetMap.get(id) ? targetMap.get(id) : null).join(', ')
          }
        }
        return stage;
      });
    }
    return [];
  }, [execution.stages, targets]);

  useEffect(() => {
    setOptionValue(null);
  }, [editId]);

  function onClickSubmit(stageIndex) {
    if (optionValue) {
      handleUpdate(robotId, execution.id, stageIndex, optionValue).then(() => {
        setEditId(null);
      });
    } else {
      setEditId(null);
    }
  }

  function onClickEdit(stageIndex, state) {
    setEditId(stageIndex);
    if (options.map(v => v.value).includes(state)) {
      setTimeout(() => setOptionValue(state));
    }
  }

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle style={{display: 'flex'}}>
        {getText('routine_execution')}-{execution.id}
        <span style={{position: 'absolute', right: 20}}>{getText('end_time')}: {execution.endedAt}</span>
      </DialogTitle>
      <DialogContent style={{paddingBottom: 30}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{getText('id')}</TableCell>
              <TableCell>{getText('mode')}</TableCell>
              <TableCell>{getText('state')}</TableCell>
              <TableCell>{getText('floor')}</TableCell>
              <TableCell>{getText('routine_total_targets')}</TableCell>
              <TableCell>{getText('routine_left_targets_length')}</TableCell>
              <TableCell>{getText('routine_left_targets')}</TableCell>
              <TableCell>{getText('actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              stages.map((stage, index) => (
                <TableRow key={index} style={{background: (wsExecutionId === execution.id && wsStageIndex === stage.index) ?  'rgba(0,0,0,0.1)' : 'transparent'}}>
                  <TableCell>{stage.index}</TableCell>
                  <TableCell>{stage.action}</TableCell>
                  <TableCell>{editId === index ? (
                      <Select
                        variant="outlined"
                        className={classes.middle}
                        input={<OutlinedInput labelWidth={0} classes={{ input: classes.selectorInput }} />}
                        value={optionValue}
                        onChange={event => setOptionValue(event.target.value)}
                      >
                        {options.map((option, index) =>
                          <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                        )}
                      </Select>
                    ) : stage.state}
                  </TableCell>
                  <TableCell>{stage.floor && stage.floor.floor}</TableCell>
                  <TableCell>{stage.target_stats && stage.target_stats.all_target_ids && stage.target_stats.all_target_ids.length}</TableCell>
                  <TableCell>{stage.target_stats && stage.target_stats.left_target_ids && stage.target_stats.left_target_ids.length}</TableCell>
                  <TableCell>{stage.targets}</TableCell>
                  <TableCell>
                    {editId === index ?
                      <CheckIcon className={classes.iconButton} onClick={() => onClickSubmit(index)} />
                      : <EditIcon className={classes.iconButton} onClick={() => onClickEdit(index, stage.state)} />
                    }
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default  connect(mapStateToProps)(withStyles(styles)(RoutineExecutionStatus));