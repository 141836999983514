import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(1),
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: (theme.palette.primary).background,
  },
  divider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(4),
    height: theme.spacing(5),
  },
  mt: {
    marginTop: theme.spacing(1.25),
  },
}));

export default useStyles;
