import React from 'react';
import { connect } from 'react-redux';

import { caseActions } from 'src/redux/actions';
import Pagination from './Pagination';

const mapStateToProps = state => {
  const { loading, lastPage, hasMore, curPage } = state.case;
  return { loading, lastPage, hasMore, curPage };
};

const mapDispatchToProps = dispatch => {
  return {
    setPage: page => {
      dispatch(caseActions.setPage(page));
    },
    getMoreCases: filters => {
      dispatch(caseActions.getMoreCases(filters));
    },
  }
};

class PaginationContainer extends React.Component {
  handleNextPageClick = e => {
    const { curPage, lastPage, hasMore, loading } = this.props;
    if (curPage < lastPage) {
      this.props.setPage(curPage + 1);
    }
    else if (!loading && hasMore) {
      this.props.getMoreCases(this.props.filters);
    }
  }

  handlePrevPageClick = e => {
    this.props.setPage(this.props.curPage - 1);
  }

  render() {
    const { curPage, lastPage, hasMore } = this.props;
    return (
      <Pagination
        handlePrevPageClick={this.handlePrevPageClick}
        handleNextPageClick={this.handleNextPageClick}
        noPrev={curPage === 1}
        noNext={!hasMore && curPage === lastPage}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaginationContainer);
