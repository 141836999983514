import React from 'react';

import {connect} from 'react-redux';
import {cameraActions} from 'src/redux/actions';
import CameraList from './CameraList';

const mapStateToProps = state => {
  const { cameras, loading, selectedCameras, sortOrder, filters } = state.camera;
  const { curSite } = state.site;
  return { cameras, loading, selectedCameras, sortOrder, filters, curSite };
};

const mapDispatchToProps = dispatch => {
  return {
    getCameras: (filters) => {
      dispatch(cameraActions.getCameras(filters));
    },
    setFilter: filters => {
      dispatch(cameraActions.setFilter(filters));
    },
    selectCamera: (camera, checked) => {
      dispatch(cameraActions.selectCamera(camera, checked));
    },
    selectAllCameras: (checked) => {
      dispatch(cameraActions.selectAllCameras(checked));
    },
    sortCameras: (sortColumn) => {
      dispatch(cameraActions.sortCameras(sortColumn));
    }
  };
};

class CameraListContainer extends React.Component {
  componentDidMount(){
    const { loading, setFilter, getCameras } = this.props;
    if (loading) return;
    const pagePath = window.location.pathname;
    let cameraFilters;
    switch(pagePath){
      case '/livestreaming':
        cameraFilters = Object.assign({}, this.props.filters, {type: 'all'});
        setFilter(cameraFilters);
        getCameras(cameraFilters);
        return;
      case '/livestreaming/robot':
        cameraFilters = Object.assign({}, this.props.filters, {type: 'robot'});
        setFilter(cameraFilters);
        getCameras(cameraFilters);
        return;
      case '/livestreaming/camera':
        cameraFilters = Object.assign({}, this.props.filters, {type: 'camera'});
        setFilter(cameraFilters);
        getCameras(cameraFilters);
        return;
      default:
        return;
    }
  }

  componentDidUpdate(prevProps) {
    const { curSite, loading, getCameras } = this.props;
    if (curSite && (!prevProps.curSite || curSite !== prevProps.curSite) && !loading) {
      getCameras();
    }
  }

  handleCameraSelect = (camera, checked) => {
    this.props.selectCamera(camera, checked);
  };

  selectAllCameras = (checked) => {
    this.props.selectAllCameras(checked);
  }

  sortCameras = (sortColumn) => {
    this.props.sortCameras(sortColumn);
  }

  render() {
    const { selectedCameras, sortOrder } = this.props;
    let cameras = this.props.cameras || [];

    return (
        <CameraList
            cameras={cameras}
            selectedCameras={selectedCameras}
            handleCameraSelect={this.handleCameraSelect}
            selectAllCameras={this.selectAllCameras}
            sortOrder={sortOrder}
            sortCameras={this.sortCameras}
        />
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CameraListContainer);
