import {contrastColorOptions} from 'src/pages/Vision/Events/Filter/staticOption';

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.setAttribute('crossOrigin', 'anonymous');
    image.onload = () => resolve(image);
    image.onerror = reject;
    image.src = src;
  });
};

export const cropImg = async (object, length) => {
  //Set zoom ratio here
  const zoomRatio = 1.5;
  const image = await loadImage(object.img_url);
  const canvas = document.createElement('canvas');
  const len = length || 120;
  const side = Math.min(image.width, image.height);
  canvas.width = len;
  canvas.height = len;
  const ctx = canvas.getContext('2d');

  if (!ctx) return '';

  // No bounding box info return copy of original img.
  if (! (object.bounding_box ? object.bounding_box.Height : null)) {
    ctx.drawImage(image, 0, 0, side, side, 0, 0, len, len);
    return canvas.toDataURL();
  }

  const [startX, startY, width, height] = [
    object.bounding_box ? image.width * object.bounding_box.Left : null,
    object.bounding_box ? image.height * object.bounding_box.Top : null,
    object.bounding_box ? image.width * object.bounding_box.Width : null,
    object.bounding_box ? image.height * object.bounding_box.Height : null,
  ];

  //make sure ratio do not surpass origin image
  const maxRatio = Math.min(image.height / height, image.width / width, zoomRatio);
  const longerSide = Math.max(height, width);
  const [centerX, centerY] = [startX + width / 2, startY + height / 2];

  ctx.drawImage(
    image,
    Math.max(0, centerX - (maxRatio / 2) * longerSide),
    Math.max(0, centerY - (maxRatio / 2) * longerSide),
    Math.min(side, longerSide * maxRatio),
    Math.min(side, longerSide * maxRatio),
    0,
    0,
    len,
    len
  );

  return canvas.toDataURL();
};

export const drawBox = async (object, imgUrl) => {
  // Load imgUrl first if there are any.
  const image = await loadImage(imgUrl ? imgUrl : object.img_url);
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const ctx = canvas.getContext('2d');

  if (!ctx) return '';
  ctx.drawImage(image, 0, 0);
  if (object.bounding_box ? object.bounding_box.Height : null) {
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 2;
    ctx.strokeRect(
      object.bounding_box ? image.width * object.bounding_box.Left : null,
      object.bounding_box ? image.height * object.bounding_box.Top : null,
      object.bounding_box ? image.width * object.bounding_box.Width : null,
      object.bounding_box ? image.height * object.bounding_box.Height : null
    );
  }
  return canvas.toDataURL();
};

export const drawLPRBox = async (imgUrl, lprArr) => {
  // Load imgUrl first if there are any.
  const image = await loadImage(imgUrl);
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const ctx = canvas.getContext('2d');

  if (!ctx) return '';
  ctx.drawImage(image, 0, 0);
  ctx.lineWidth = 2;
  lprArr.forEach(lpr => {
    ctx.strokeStyle = lpr.color.color ? contrastColorOptions[lpr.color.color] : 'red';
    ctx.strokeRect(
      lpr.polygon[0][0],
      lpr.polygon[0][1],
      lpr.polygon[2][0] - lpr.polygon[0][0],
      lpr.polygon[2][1] - lpr.polygon[0][1]
    );
  });
  return canvas.toDataURL();
};
