import React from 'react';
import { connect } from 'react-redux';
import { siteActions, robotActions, notificationActions } from 'src/redux/actions';
import SiteBuildingList from './SiteBuildingList';
import LoadingText from 'src/components/LoadingText';
import { getText } from 'src/utils/MultilingualLoader';
import * as siteServices from 'src/services/site';

const mapStateToProps = state => {
  return {
    buildings: state.site.buildings,
    loading: state.site.buildingLoading,
    filter: state.site.buildingFilter,
    total: state.site.buildingTotal,
    sites: state.site.sites,
    floorLoading: state.site.floorLoading,
    floors: state.site.floors,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSiteBuildings: (filter) => {
      dispatch(siteActions.getSiteBuildings(filter));
    },
    getSiteFloors: (buildingId) => {
      dispatch(siteActions.getSiteFloors({ building_id: buildingId }));
    },
    getSites: () => {
      dispatch(siteActions.getSites())
    },
    addNotification: message => {
      dispatch(notificationActions.addNotification(message));
    }
  };
};

class SiteBuildingListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editBuilding: false,
      editBuildingValue: null,
      deleteBuilding: false,
      editFloor: false,
      editFloorValue: null,
      buildingOfEditFloor: null,
      deleteFloor: false,
    }
  }

  componentDidMount() {
    const { loading, filter, getSiteBuildings, sites, getSites } = this.props;
    if (!loading) {
      getSiteBuildings(filter);
    }
    if (!sites || sites.length === 0) {
      getSites();
    }
  }

  handleChangePage = (event, newPage) => {
    const { filter, getSiteBuildings } = this.props;
    getSiteBuildings({ ...filter, page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    const { filter, getSiteBuildings } = this.props;
    const newLimit = parseInt(event.target.value, 10);
    getSiteBuildings({ page: Math.floor((filter.page * filter.limit) / newLimit + 1), limit: newLimit });
  }

  handleAddBuilding = () => {
    this.setState({ editBuilding: true, editBuildingValue: null });
  }

  handleEditBuilding = (data) => {
    this.setState({ editBuilding: true, editBuildingValue: data });
  }

  handleCloseEditBuilding = (changed) => {
    if (changed) {
      this.props.getSiteBuildings(this.props.filter);
    }
    this.setState({
      editBuilding: false,
      editBuildingValue: null,
    });
  }

  handleDeleteBuilding = (buildingId) => {
    const { addNotification, filter, getSiteBuildings } = this.props;
    const { deleteBuilding } = this.state;
    if (deleteBuilding) return;
    this.setState({ deleteBuilding: true });
    siteServices.deleteSiteBuilding(buildingId).then(() => {
      addNotification(`${getText('delete')}${getText('words_space')}${getText('building')}${getText('words_space')}${getText('succeeded')}`);
      getSiteBuildings(filter);
      this.setState({ deleteBuilding: false });
    }).catch(() => {
      addNotification(`${getText('delete')}${getText('words_space')}${getText('building')}${getText('words_space')}${getText('failed')}`);
      this.setState({ deleteBuilding: false });
    })
  }

  handleAddFloor = (building) => {
    this.setState({ editFloor: true, editFloorValue: null, buildingOfEditFloor: building });
  }

  handleEditFloor = (building, data) => {
    this.setState({ editFloor: true, editFloorValue: data, buildingOfEditFloor: building });
  }

  handleCloseEditFloor = (changed) => {
    if (changed) {
      this.props.getSiteFloors(this.state.buildingOfEditFloor.id);
    }
    this.setState({
      editFloor: false,
      editFloorValue: null,
      buildingOfEditFloor: null
    });
  }

  handleDeleteFloor = (buildingId, floorId) => {
    const { addNotification, getSiteFloors } = this.props;
    const { deleteFloor } = this.state;
    if (deleteFloor) return;
    this.setState({ deleteFloor: true });
    siteServices.deleteSiteFloor(floorId).then(() => {
      addNotification(`${getText('delete')}${getText('words_space')}${getText('floor')}${getText('words_space')}${getText('succeeded')}`);
      getSiteFloors(buildingId);
      this.setState({ deleteFloor: false });
    }).catch(() => {
      addNotification(`${getText('delete')}${getText('words_space')}${getText('floor')}${getText('words_space')}${getText('failed')}`);
      this.setState({ deleteFloor: false });
    })
  }

  render() {
    const { loading, buildings, filter, total, floorLoading, floors, sites, getSiteFloors } = this.props;
    const { editBuilding, editBuildingValue, editFloor, editFloorValue, buildingOfEditFloor } = this.state;
    return (
      !loading ?
        <SiteBuildingList
          filter={filter}
          total={total}
          buildings={buildings}
          floorLoading={floorLoading}
          floors={floors}
          sites={sites}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          editBuilding={editBuilding}
          editBuildingValue={editBuildingValue}
          editFloor={editFloor}
          editFloorValue={editFloorValue}
          buildingOfEditFloor={buildingOfEditFloor}
          handleAddBuilding={this.handleAddBuilding}
          handleEditBuilding={this.handleEditBuilding}
          handleCloseEditBuilding={this.handleCloseEditBuilding}
          handleDeleteBuilding={this.handleDeleteBuilding}
          handleAddFloor={this.handleAddFloor}
          handleEditFloor={this.handleEditFloor}
          handleCloseEditFloor={this.handleCloseEditFloor}
          handleDeleteFloor={this.handleDeleteFloor}
          getSiteFloors={getSiteFloors}
        />
        : <LoadingText />
    );
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteBuildingListContainer);
