import React, {useState} from 'react';
import { 
  withStyles, 
  Grid, 
  Menu,
  MenuItem,
} from '@material-ui/core';
import TaskPagination from './TaskPagination';
import TaskMedium from './TaskMedium';

const styles = (theme) => ({
  root: {
    paddingBottom: 20,
    padding: theme.spacing(2),
  },
  table: {
    width: '100%',
    fontSize: '1rem'
  },
  row: {
    width: 200,
  },
  wrapper: {
    width: 180,
  },
  pre: {
    whiteSpace: 'pre-wrap'
  }
});

const TaskList = (props) => {
  const {classes, tasklist, pagination, createCase, review, eventOptions} = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [reviewTask, setReviewTask] = useState(null);
  const [caseTask, setCaseTask] = useState(null);
  const [types, setTypes] = useState([]);
  const open = Boolean(anchorEl);

  const handleReview = (task, list) => (e) => {
    setAnchorEl(e.currentTarget);
    setReviewTask({
      id: task.id,
      inspection_id: task.inspection_id,
    });
    setTypes(list);
  }

  const handleCase = (task, list) => (e) => {
    setAnchorEl(e.currentTarget);
    setCaseTask({
      id: task.id,
      inspection_id: task.inspection_id,
    });
    setTypes(list);
  }

  function clear() {
    setAnchorEl(null);
    setCaseTask(null);
    setReviewTask(null);
    setTypes([]);
  }

  return (
    <Grid className={classes.root}>
      <Grid
        item
        container={true}
        direction='row'
        justify='flex-start'
        alignItems='stretch'
        spacing={2}
      >
        {tasklist.map(task => (
          <Grid 
            item
            sm={6}
            xs={4}
            md={4}
            lg={3}
            key={task.id}
          >
            <TaskMedium
              task={task}
              handleReview={handleReview}
              handleCase={handleCase}
            />
          </Grid>
        ))}
      </Grid>
      {
        reviewTask && (
          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={clear}
          >
            {types.map((type, index) => (
              <MenuItem key={index} onClick={() => {
                review(reviewTask.id, type.value)
                clear();
              }}>{type.label}:{type.score}</MenuItem>
            ))}
          </Menu>
        )
      }
      {
        caseTask && (
          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={clear}
          >
            {
              eventOptions.map((algo, index) => (
                <MenuItem key={index + 100} onClick={() => {
                  clear();
                  createCase(caseTask.inspection_id, algo.value, caseTask.id);
                }}>{algo.label}</MenuItem>
              ))
            }
          </Menu>
        )
      }
      <TaskPagination
        curPage={pagination.curPage}
        count={pagination.count}
      />
    </Grid>
  );
};

export default withStyles(styles)(TaskList);