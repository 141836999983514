import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";
import PrivateRoute from "src/components/private-route";

import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import Header from "./Header";
import SideNav from "./SideNav";
import Cameras from "./Cameras";
import Cases from "./Cases";
import Robots from "./Robots";
import AccountManage from "./AccountManage";
import SiteManage from "./SiteManage";
import Inspections from "./Inspections";
import Sensors from "./Sensors";
import SiteBar from "./SiteBar";
import Notifications from "./Notifications";
import Messages from "./Messages";
import Targets from "./Targets";
import Events from "../Vision/Events"
import DetectionTasks from "./Tasks";
import Maps from "./Maps";
import routineHistory from "./RoutineHistory";
import Detail from "../Vision/Events/detail";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    display: "flex",
    height: "100vh",
    flexDirection: "column",
    overflowX: "hidden",
  },
  toolbar: theme.mixins.toolbar,
});

const Main = (props) => {
  const { classes, role } = props;
  const pathname = window.location.pathname;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
      <SideNav />
      <Notifications />
      <Messages />
      <main className={classes.content}>
        {/* Toolbar Spacer */}
        <div className={classes.toolbar} />
        {!pathname.includes("sitemanage") && !pathname.includes("events") &&
          ["monitoring_operator", "customer", "site_manager"].includes(
            role
          ) && <SiteBar />}
        <Switch>
          <PrivateRoute
            exact
            path="/"
            component={Cases}
            authRoles={["monitoring_operator", "customer", "site_manager"]}
          />
          <PrivateRoute
            path="/cases"
            component={Cases}
            authRoles={["monitoring_operator", "customer", "site_manager"]}
          />
          <PrivateRoute
            path="/livestreaming"
            component={Cameras}
            authRoles={["monitoring_operator", "customer", "site_manager"]}
          />
          <PrivateRoute
            path="/robots"
            component={Robots}
            authRoles={["monitoring_operator", "customer", "site_manager"]}
          />
          <PrivateRoute
            path="/maps"
            component={Maps}
            authRoles={["monitoring_operator", "customer", "site_manager"]}
          />
          <PrivateRoute
            path="/adminmanage"
            component={AccountManage}
            authRoles={["admin"]}
          />
          <PrivateRoute
            path="/guardmanage"
            component={AccountManage}
            authRoles={["guard_admin"]}
          />
          <PrivateRoute
            path="/sitemanage"
            component={SiteManage}
            authRoles={["customer"]}
          />
          <PrivateRoute
            path="/routine_history"
            component={routineHistory}
            authRoles={["customer", "site_manager"]}
          />
          <PrivateRoute
            path="/inspections"
            component={Inspections}
            authRoles={["customer", "site_manager"]}
          />
          <PrivateRoute
            path="/inspection_configs"
            component={Targets}
            authRoles={["customer"]}
          />
          {/* <PrivateRoute path="/tasks" component={DetectionTasks} authRoles={['customer']} /> */}
          <PrivateRoute
            path="/sensors"
            component={Sensors}
            authRoles={["customer"]}
          />
          <PrivateRoute
            path="/events"
            component={Events}
            authRoles={["customer", "site_manager", "monitoring_operator"]}
          />
          <PrivateRoute
            path="/events/people"
            component={Detail}
            authRoles={["customer", "site_manager", "monitoring_operator"]}
          />
          <PrivateRoute
            path="/events/vehicle"
            component={Detail}
            authRoles={["customer", "site_manager", "monitoring_operator"]}
          />
          <PrivateRoute
            path="/events/motorcycle"
            component={Detail}
            authRoles={["customer", "site_manager", "monitoring_operator"]}
          />
        </Switch>
      </main>
    </div>
  );
};

Main.propTypes = {
  classes: PropTypes.object.isRequired,
};

Main.defaultProps = {};

export default withStyles(styles)(Main);
