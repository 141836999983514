// empty whitelist means all devices are allowed
let webRTCWhiteList = [];
// empty blacklist means no device is disallowed
let webRTCBlackList = [];

try {
  webRTCWhiteList = require("configs/local_webRTCWhiteList.json");
} catch (e) {
  webRTCWhiteList = ["none"];
  // console.log(e)
}

export { webRTCWhiteList, webRTCBlackList };
