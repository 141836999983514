import {
  manageConstants
} from "src/redux/constants";

const initialState = {
  list: [],
  loading: false,
  updating: false,
  selectedType: null,
  next: null,
  curPage: 0,
  lastPage: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case manageConstants.GET_LIST_REQUEST:
      return {
        ...state,
        list: [],
        loading: true
      };
    case manageConstants.GET_LIST_SUCCESS:
      const { list, next } = action;
      return {
        ...state,
        list,
        loading: false,
        next,
        curPage: 1,
        lastPage: 1
      };
    case manageConstants.GET_LIST_FAILURE:
      return {
        ...state,
        loading: false
      };
    case manageConstants.GET_MORE_ACCOUNTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case manageConstants.GET_MORE_ACCOUNTS_SUCCESS:
      //If the user is still looking at the last page the browser has and waiting for the next page requested
      let nextPage = (state.curPage + 1) * 20 > state.list.length ? state.curPage + 1 : state.curPage;
      let moreAccounts = state.list.concat(action.list);
      return {
        ...state,
        loading: false,
        list: moreAccounts,
        next: action.next,
        curPage: nextPage,
        lastPage: state.lastPage + 1
      }
    case manageConstants.SET_PAGE:
      return {
        ...state,
        curPage: action.page
      }
    case manageConstants.CHANGE_TYPE:
      return {
        ...state,
        selectedType: action.selectedType
      };
    case manageConstants.UPDATE_ACCOUNT_REQUEST:
      return {
        ...state,
        updating: true
      };
    case manageConstants.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        updating: false
      };
    case manageConstants.UPDATE_ACCOUNT_FAILURE:
      return {
        ...state,
        updating: false
      };
    default:
      return state;
  }
};
