import {
  wsConstants
} from 'src/redux/constants';

const initialState = {
  /*
    'wsName': {
      messages: [],
      status: 'open'
    }
  */
  APP: {
    messages: {},
    status: 'closed',
  },
  WISP: {
    messages: {},
    status: 'closed',
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case wsConstants.STATUS_CHANGE:
      return {
        ...state,
        [action.wsName]: {
          ...state[action.wsName],
          status: action.status
        }
      }
    case wsConstants.RECEIVE_MSG:
      let componentMsg = Object.assign([], state[action.wsName].messages[action.componentId]);
      componentMsg.push(action.message);
      return {
        ...state,
        [action.wsName]: {
          ...state[action.wsName],
          messages: {
            ...state[action.wsName].messages,
            [action.componentId]: componentMsg
          }
        }
      };
    case wsConstants.EMPTY_MSG_QUEUE:
      return {
        ...state,
        [action.wsName]: {
          ...state[action.wsName],
          messages: {
            ...state[action.wsName].messages,
            [action.componentId]: []
          }
        }
      };
    case wsConstants.UNREG_COMPONENT:
      let ws = Object.assign({}, state[action.wsName]);
      delete ws.messages[action.componentId];
      return {
        ...state,
        [action.wsName]: ws
      };
    default:
      return state;
  }
};
