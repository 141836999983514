import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from "@material-ui/core/Typography/Typography";

import { getText, getTimeText } from 'src/utils/MultilingualLoader';


const styles = theme => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    margin: '12px 0'
  },
});

const CaseHistory = (props) => {
  const { classes, histories } = props;
  return (
    <ExpansionPanel className={classes.root}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1">
          {getText('history')}
        </Typography>
        </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Table>
          <TableBody>
            {
              histories.map(history => (
                <TableRow key={history.id}>
                  <TableCell className={classes.tableCell} padding="default">
                    <Typography variant="body2">
                      {getTimeText(history.created_at, 'lll')}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} padding="default">
                    <Typography variant="body2">
                      {getText(history.action)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} padding="default">
                    <Typography variant="body2">
                      {history.user.first_name} {history.user.last_name}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
};

CaseHistory.propTypes = {
  histories: PropTypes.array.isRequired,
};

CaseHistory.defaultProps = {
  histories: [],
};

export default withStyles(styles)(CaseHistory);
