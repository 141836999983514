import React from 'react';
import { withStyles, FormControl, InputLabel, Input, Select, MenuItem } from '@material-ui/core';

const styles = theme => ({
  root: {
    minWidth: 100
  },
  selectorLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  menuItem: {
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem',
    textTransform: 'capitalize'
  },
  title: {
    textTransform: 'capitalize'
  },
  input: {
    maxWidth: 100
  }
});

const Selector = props => {
  const { classes, title, options, value, onChange, children, disabled } = props;
  return (
    <FormControl 
      component="fieldset" 
      className={classes.root}
      disabled={disabled}
    >
      <div className={classes.selectorLine}>
        {title && <InputLabel className={classes.title} >{title}</InputLabel>}
        <Select
          value={value}
          onChange={onChange}
          input={<Input fullWidth classes={{ input: classes.input }} />}
        >
          {options.map((option, index) =>
            <MenuItem key={index} className={classes.menuItem} value={option.value}>{option.label}</MenuItem>
          )}
        </Select>
      </div>
      {children}
    </FormControl>
  );
};

export default withStyles(styles)(Selector);
