import React from 'react';
import { connect } from 'react-redux';
import { manageActions } from 'src/redux/actions';
import { mapManageTypeToConstant } from 'src/utils/constantMapper';
import AddCustomerPanel from './AddCustomerPanel';
import { getText } from 'src/utils/MultilingualLoader';
import { validatePassword } from 'src/utils/validators.js';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    createAccount: data => {
      dispatch(manageActions.createAccount(data));
    }
  };
};

class AddCustomerPanelContainer extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    password2: '',
    lang: '',
    timezone: '',
    referral: '',
    inputErrors: {}
  }

  handleSubmit = event => {
    const { firstName, lastName, username, password, password2, lang, timezone, referral } = this.state;
    const inputErrors = {};
    const vp = validatePassword(password);
    if (vp) {
      inputErrors.password = vp;
      inputErrors.password2 = vp;
    }
    
    if (password !== password2) {
      inputErrors.password = getText('input_error_password_not_match');
      inputErrors.password2 = getText('input_error_password_not_match')
    }
    const reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!reEmail.test(String(username).toLowerCase())){
      inputErrors.username = getText('input_error_email');
    }
    Object.keys(this.state).forEach(key => {
      if (!['inputErrors', 'referral'].includes(key) && !this.state[key]) {
        inputErrors[key] = 'required';
      }
    });
    if (Object.keys(inputErrors).length !== 0) {
      return this.setState({ inputErrors });
    }

    const selectedType = mapManageTypeToConstant('addcustomer');
    this.props.createAccount({
      selectedType,
      firstName,
      lastName,
      username,
      password,
      lang,
      timezone,
      referral,
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      inputErrors: {
        ...this.state.inputErrors,
        [event.target.name]: ''
      }
    });
  }

  render() {
    const { firstName, lastName, username, password, password2, lang, timezone, referral, inputErrors } = this.state;
    return (
      <AddCustomerPanel
        firstName={firstName}
        lastName={lastName}
        username={username}
        password={password}
        password2={password2}
        lang={lang}
        timezone={timezone}
        referral={referral}
        inputErrors={inputErrors}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        />
    );
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCustomerPanelContainer);
