import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { getText } from 'src/utils/MultilingualLoader';
import EditOperator from 'src/pages/Main/AccountManage/EditModal';
// import EditOperator from '../../lprModal';

const styles = theme => ({
  tableGrid: {
    height: '100%',
    overflow: 'auto'
  },
  table: {
  },
  header: {
    fontSize: '1rem'
  },
  row: {
    fontSize: '1rem'
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)'
  },
  iconButton: {
    '&:hover': {
      cursor: 'pointer',
    }
  },
});

const tableHeaders = [
  "id",
  "username",
  "last_name",
  "first_name",
  "organization_id",
  "phone_number",
  " "
];

const Edit_operator = Modal_Open => {
  Modal_Open(true)
}

const OperatorList = props => {
  const { classes, data, onClickAdd, selectedType, onClickDelete, onClickOperaterEdit, UpdateList} = props;
  
  const [open, setOpen] = useState(false);
  const [toEdit, setData] = useState(null);
  return (
    <Grid
      container={true}
      justify="flex-start"
      direction="row"
      alignItems="stretch"
      spacing={0}
    >
      <Grid item={true} xs={12}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onClickAdd(selectedType)}
        >
          {getText('add_operator')}
        </Button>
      </Grid>
      <Grid item={true} xs={12} className={classes.tableGrid}>
        <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header, index) => (<TableCell key={index} className={classes.header}>{getText(header)}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data ? data.map((operator, index) => (
              <TableRow key={operator.id}>
                  <TableCell className={classes.row}>{operator.id}</TableCell>
                  <TableCell className={classes.row}>{operator.username}</TableCell>
                  <TableCell className={classes.row}>{operator.last_name}</TableCell>
                  <TableCell className={classes.row}>{operator.first_name}</TableCell>
                  <TableCell className={classes.row}>{operator.organization}</TableCell>
                  <TableCell className={classes.row}>{operator.phone_number}</TableCell>
                  <TableCell className={classes.row}>
                    <DeleteIcon className={classes.iconButton} onClick={onClickDelete(selectedType, operator.id)} />
                    <EditIcon className={classes.iconButton} onClick={() => {setData(operator);Edit_operator(setOpen);UpdateList(selectedType)}}/>
                  </TableCell>
              </TableRow>
          )) : null}
          {/* {open ? (<p>yesssss</p>) : null} */}
          {open ? (<EditOperator onClose={setOpen} data={toEdit}/>) : null}
        </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(OperatorList);
