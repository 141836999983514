import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

import RoutineHistoryPanel from './RoutineHistoryPanel';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  const { selectedRobot, showType } = state.inspection;
  return { selectedRobot, showType };
};

const mapDispatchToProps = dispatch => {
  return { };
};

class InspectionPanelContainer extends React.Component {
  render() {
    const { selectedRobot, showType } = this.props;
    return (
        <>{
          selectedRobot ? 
            <RoutineHistoryPanel 
              selectedRobot={selectedRobot} 
              showType={showType}
            /> 
            : <Typography>{getText('no_robot_selected')}</Typography>
        }</>
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InspectionPanelContainer);
