import { targetConstants } from 'src/redux/constants';

const initialState = {
  list: [],
  loading: false,
  filters: {
    limit: 10,
    name: '',
    floor: '',
    category: '',
    algo_config: 'all',
    algo_reference: 'all',
    algo: 'all',
  },
  pagination: {
    curPage: 1,
    count: 200,
  },
  algoOptions: {},
  targetOptions: {},
  categoryOptions: {},
  floorOptions: {},
  maps: [],
  robots: [],
  copyLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case targetConstants.GET_TARGET_SUCCESS:
      return {
        ...state,
        list: action.list,
        counts: action.counts,
        pagination: {
          ...state.pagination,
          curPage: action.curPage,
          count: action.count,
        },
        loading: false,
      };
    case targetConstants.UPDATE_TARGET_LIST:
      return {
        ...state,
        list: action.list,
      };
    case targetConstants.SET_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          curPage: action.page
        }
      };
    case targetConstants.SET_FILTER:
      return {
        ...state,
        filters: Object.assign({}, state.filters, action.filters)
      };
    case targetConstants.GET_OPTIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case targetConstants.GET_MAPS_SUCCESS:
      return {
        ...state,
        maps: action.maps,
      };
    case targetConstants.GET_ROBOT_SUCCESS:
      return {
        ...state,
        robots: action.robots,
      };
    case targetConstants.COPY_ROUTES_REQUEST:
      return {
        ...state,
        copyLoading: true,
      };
    case targetConstants.COPY_ROUTES_SUCCESS:
    case targetConstants.COPY_ROUTES_FAILURE:
      return {
        ...state,
        copyLoading: false,
      };
    default:
      return state
  }
};
