import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Table, TableBody, TableRow, Paper,TableCell } from '@material-ui/core';
import moment from 'moment'

import { getTimeText } from 'src/utils/MultilingualLoader';

export const sidePanelWidth = 200;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto'
  },
  paper: {
    backgroundColor: theme.palette.grey[200],
    minHeight: '100%',
  },
  tableRow :{
    cursor:'pointer',
  },
  selected:{
    backgroundColor:  'rgba(0,0,0,0.2) !important'
  },
  msg: {
    padding: '0 10px',
    textAlign: 'center'
  }
});



const RoutineHistoryTimeList = props => {
  const { classes, robotRoutineHistoryPage,selectRoutineHistoryTime,selectedRoutineHistoryTime } = props;
  return (
    <Paper className={classes.paper} spacing={0}>
      <Grid
        className={classes.root}
        >
          <Table>
            <TableBody>
              {robotRoutineHistoryPage&&robotRoutineHistoryPage.updating===true ? 
                 <Grid>&nbsp;Loading...</Grid>:
                  robotRoutineHistoryPage&&robotRoutineHistoryPage.routine_histories&&
                  robotRoutineHistoryPage.routine_histories.map((ele,index) => 
                 <TableRow hover={true} key={index} 
                    classes={{
                     selected: classes.selected
                    }}
                    selected={selectedRoutineHistoryTime && selectedRoutineHistoryTime === ele.start_time}>
                   <TableCell  key={ele} onClick={() =>selectRoutineHistoryTime(ele.start_time)} className={classes.msg}>
                     {getTimeText(moment(ele.start_time), 'LLLL').replace('日','日间')
                      .split('间').map((item, i) => <p key={i}>{item}</p>)}
                   </TableCell>
                 </TableRow>)
                 }
            </TableBody>
          </Table>
      </Grid>
    </Paper>
  );
};  

export default withStyles(styles)(RoutineHistoryTimeList);
