import React from "react";
import { Layer, Line, Circle, Image } from "react-konva";
import useImage from "use-image";

import dockerImg from "public/docker.svg";
import cameraImg from "public/camera.svg";
import landmarkImg from "public/landmark.svg";
import switchImg from "public/switch.svg";

const waypointRadius = 5;
const pathStrokeWidth = 3;
const imageWidth = 24;
const imageHeight = 36;

const DockerImage = (props) => {
  const { position, scale } = props;
  const [image] = useImage(dockerImg);
  return (
    <Image
      image={image}
      width={imageWidth / Math.sqrt(scale)}
      height={imageHeight / Math.sqrt(scale)}
      x={Math.max(position.x, 0)}
      y={Math.max(position.y, 0)}
      offsetX={imageWidth / 2 / Math.sqrt(scale)}
      offsetY={imageHeight / Math.sqrt(scale)}
    />
  );
};

const SwitchPose = (props) => {
  const { position, scale } = props;
  const [image] = useImage(switchImg);
  return (
    <Image
      image={image}
      width={imageWidth / Math.sqrt(scale)}
      height={imageHeight / Math.sqrt(scale)}
      x={Math.max(position.x, 0)}
      y={Math.max(position.y, 0)}
      offsetX={imageWidth / 2 / Math.sqrt(scale)}
      offsetY={imageHeight / Math.sqrt(scale)}
    />
  );
};

const CameraImage = (props) => {
  const { position, scale } = props;
  const [image] = useImage(cameraImg);
  return (
    <Image
      image={image}
      width={imageWidth / Math.sqrt(scale)}
      height={imageHeight / Math.sqrt(scale)}
      x={Math.max(position.x, 0)}
      y={Math.max(position.y, 0)}
      offsetX={imageWidth / 2 / Math.sqrt(scale)}
      offsetY={imageHeight / Math.sqrt(scale)}
    />
  );
};

const LandmarkImage = (props) => {
  const { position, scale } = props;
  const [image] = useImage(landmarkImg);
  return (
    <Image
      image={image}
      width={imageWidth / Math.sqrt(scale)}
      height={imageHeight / Math.sqrt(scale)}
      x={Math.max(position.x, 0)}
      y={Math.max(position.y, 0)}
      offsetX={imageWidth / 2 / Math.sqrt(scale)}
      offsetY={imageHeight / Math.sqrt(scale)}
    />
  );
};

const MapLocations = (props) => {
  const { getImagePosition, scale, elevatorBase, switchPose } = props;

  const configRoute = (props.configRoute && props.configRoute.way_points) || [];
  let path_lines = [];
  let waypoints = [];
  let prev = null;
  prev = null;
  configRoute.forEach((wayPoint, idx) => {
    const position =
      wayPoint.point && getImagePosition(wayPoint.point.x, wayPoint.point.y);
    if (
      position &&
      position.x &&
      position.y &&
      !isNaN(position.x) &&
      !isNaN(position.y)
    ) {
      waypoints.push(
        <Circle
          key={"dot" + idx}
          radius={waypointRadius / Math.sqrt(scale)}
          x={position.x}
          y={position.y}
          fill="blue"
        />
      );
      if (prev) {
        path_lines.push(
          <Line
            key={"line" + idx}
            points={[position.x, position.y, prev.x, prev.y]}
            stroke="green"
            strokeWidth={pathStrokeWidth / Math.sqrt(scale)}
          />
        );
      }
      prev = position;
    }
  });

  const cameraLocations = props.cameraLocations || [];
  let cameras = [];
  cameraLocations.forEach((entry, idx) => {
    const position = getImagePosition(entry.location.x, entry.location.y);
    if (position.x && position.y && !isNaN(position.x) && !isNaN(position.y)) {
      cameras.push(<CameraImage key={idx} position={position} scale={scale} />);
    }
  });

  const landmarkLocations = props.landmarks || [];
  let landmarks = [];
  landmarkLocations.forEach((landmark, idx) => {
    const position = getImagePosition(landmark.x, landmark.y);
    if (position.x && position.y && !isNaN(position.x) && !isNaN(position.y)) {
      landmarks.push(
        <LandmarkImage key={idx} position={position} scale={scale} />
      );
    }
  });

  const dockPosition = props.chargingBase
    ? getImagePosition(props.chargingBase.x, props.chargingBase.y)
    : null;
  const virtualWall = props.virtualWall ? props.virtualWall : [];
  let virtualWallAreas = [];
  virtualWall.forEach((polygon, index) => {
    let polygonPoints = [];
    polygon &&
      polygon.forEach((point) => {
        if (!point) return;
        const position = getImagePosition(point[0], point[1]);
        if (
          position.x &&
          position.y &&
          !isNaN(position.x) &&
          !isNaN(position.y)
        )
          polygonPoints.push(position.x, position.y);
      });

    if (polygonPoints.length >= 3) {
      virtualWallAreas.push(
        <Line
          key={index}
          points={polygonPoints}
          closed={true}
          fill="gray"
          stroke="black"
          strokeWidth={1 / Math.sqrt(scale)}
        />
      );
    }
  });

  const basePosition =
    elevatorBase &&
    elevatorBase.base &&
    getImagePosition(elevatorBase.base.x, elevatorBase.base.y);
  const enterPosition =
    elevatorBase &&
    elevatorBase.enter &&
    getImagePosition(elevatorBase.enter.x, elevatorBase.enter.y);
  const exitPosition =
    elevatorBase &&
    elevatorBase.exit &&
    getImagePosition(elevatorBase.exit.x, elevatorBase.exit.y);
  const switchPosition =
    switchPose && getImagePosition(switchPose.x, switchPose.y);

  return (
    <Layer>
      {virtualWallAreas}
      {path_lines}
      {/* {waypoints} */}
      {dockPosition && !isNaN(dockPosition.x) && !isNaN(dockPosition.y) && (
        <DockerImage position={dockPosition} scale={scale} />
      )}
      {switchPosition &&
        Number.isFinite(switchPosition.x) &&
        Number.isFinite(switchPosition.y) && (
          <SwitchPose position={switchPosition} scale={scale} />
        )}
      {cameras}
      {landmarks}
      {basePosition && (
        <Circle
          radius={3 / Math.sqrt(scale)}
          fill="green"
          x={basePosition.x}
          y={basePosition.y}
        />
      )}
      {enterPosition && (
        <Circle
          radius={3 / Math.sqrt(scale)}
          fill="blue"
          x={enterPosition.x}
          y={enterPosition.y}
        />
      )}
      {exitPosition && (
        <Circle
          radius={3 / Math.sqrt(scale)}
          fill="purple"
          x={exitPosition.x}
          y={exitPosition.y}
        />
      )}
    </Layer>
  );
};

export default MapLocations;
