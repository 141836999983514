import { taskConstants } from 'src/redux/constants';

const initialState = {
  list: [],
  loading: false,
  filters: {
    limit: 12,
    name: '',
    reviewStatus: 'all',
    moderateStatus: 'all',
    orderStatus: '-id',
    startDate: null,
    endDate: null,
  },
  pagination: {
    curPage: 1,
    count: 200,
  },
  targetOptions: {},
  eventOptions: {},
  algoOptions: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case taskConstants.GET_TASK_SUCCESS:
      return {
        ...state,
        list: action.list,
        counts: action.counts,
        pagination: {
          ...state.pagination,
          curPage: action.curPage,
          count: action.count,
        },
        loading: false,
      };
    case taskConstants.SET_FILTER:
      return {
        ...state,
        filters: Object.assign({}, state.filters, action.filters)
      };
    case taskConstants.SET_EVENT_OPTIONS:
      return {
        ...state,
        eventOptions: {
          ...state.eventOptions,
          ...action.payload,
        },
      };
    case taskConstants.SET_ALGO_OPTIONS:
      return {
        ...state,
        algoOptions: {
          ...state.algoOptions,
          ...action.payload,
        },
      };
    case taskConstants.SET_TARGET_OPTIONS:
      return {
        ...state,
        targetOptions: {
          ...state.targetOptions,
          ...action.payload,
        },
      };
    case taskConstants.UPDATE_TASK_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state
  }
};
