import React from 'react';
import { 
  withStyles,
  Grid, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  Typography,
} from '@material-ui/core';

import InspectionList from './InspectionList';
import InspectionFilter from './InspectionFilter';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    minHeight: 300
  }
})

const InspectionPanel = (props) => {
  const {classes, targetId, onSelectSnapshot, onClose} = props;

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle>
        <Typography variant="body1">{getText('inspection_list')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.root}>
          {/* <Grid>
            <InspectionFilter targetId={target.id} />
          </Grid> */}
          <Grid>
            <InspectionList targetId={targetId} onSelectSnapshot={onSelectSnapshot} onClose={onClose} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(InspectionPanel);