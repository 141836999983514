import React, {useMemo} from 'react';
// import {FormattedMessage, useIntl} from 'react-intl';
import {Grid, Typography, Divider, TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  vehicleColorOptions,
  vehicleTypeOptions,
  vehicleMakeOptions,
  commonVehicleMakerOptions,
} from '../staticOption';
import Label from './Label';
import useStyles from './index.style';

function VehicleFilterLine({onChange, ...props}) {
  const classes = useStyles();
  const propsAdapter = useMemo(
    () => ({
      colors: vehicleColorOptions.filter(color =>
        props.color ? props.color.toLowerCase().includes(color.value.toLowerCase()) : false
      ),
      types: vehicleTypeOptions.filter(type =>
        props.type ? props.type.toLowerCase().includes(type.value.toLowerCase()) : false
      ),
      make: vehicleMakeOptions.find(make => make.value.toLowerCase() === ( props.make ? props.make.toLowerCase() : undefined )),
    }),
    [props]
  );

  function handleChange(key, item) {
    if (key === 'colors' || key === 'types') {
      const index = propsAdapter[key].findIndex(v => v.value === (item).value);
      const arr = propsAdapter[key] || [];
      if (index === undefined || index === -1) {
        console.log([...arr, item]);
        onChange &&
          onChange({
            ...props,
            [key.split('s')[0]]: [...arr, item]
              .map((v) => v.value)
              .join(',')
              .toLowerCase(),
          });
      } else {
        arr.splice(index, 1);
        onChange &&
          onChange({
            ...props,
            [key.split('s')[0]]: arr
              .map((v) => v.value)
              .join(',')
              .toLowerCase(),
          });
      }
    } else {
      onChange && onChange({...props, make: item ? item.value : undefined});
    }
  }

  return (
    <Grid container wrap="nowrap" className={classes.container}>
      <VehicleColor onChange={handleChange} colors={propsAdapter.colors} />
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <VehicleType onChange={handleChange} types={propsAdapter.types} />
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <VehicleMake onChange={handleChange} make={propsAdapter.make} />
    </Grid>
  );
}

function VehicleColor({colors, onChange}) {
  return (
    <Grid item sm={6}>
      {/* <Typography variant="subtitle2">
        <FormattedMessage id="clipsView.color" />
      </Typography> */}
      <Grid container item>
        {vehicleColorOptions.map(item => (
          <Label
            selected={!!(colors ? colors.find(v => v.value === item.value) : undefined)}
            onClick={() => onChange('colors', item)}
            key={item.value}
            text={item.label}
            color={item.color}
          />
        ))}
      </Grid>
    </Grid>
  );
}

function VehicleType({types, onChange}) {
  return (
    <Grid item sm={4}>
      {/* <Typography variant="subtitle2">
        <FormattedMessage id="clipsView.bodyType" />
      </Typography> */}
      <Grid container item>
        {vehicleTypeOptions.map(item => (
          <Label
            selected={!!(types ? types.find(v => v.value === item.value) : undefined)}
            onClick={() => onChange('types', item)}
            key={item.value}
            text={item.label}
          />
        ))}
      </Grid>
    </Grid>
  );
}

function VehicleMake({make, onChange}) {
  const classes = useStyles();
  // const intl = useIntl();s
  const commonVehicleMakers = useMemo(
    () =>
      vehicleMakeOptions.filter(maker =>
        commonVehicleMakerOptions.some(option => maker.name === option)
      ),
    []
  );

  return (
    <Grid item sm={2}>
      {/* <Typography variant="subtitle2">
        <FormattedMessage id="clipsView.vehicleMake" />
      </Typography> */}
      <Autocomplete
        className={classes.mt}
        fullWidth
        clearOnBlur
        value={make || null}
        options={commonVehicleMakers}
        onChange={(e, option) => onChange('make', option)}
        getOptionLabel={option => (option.label === undefined ? (option) : option.label)}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={'vehicleMakeHintText'}
          />
        )}
      />
    </Grid>
  );
}

export default VehicleFilterLine;
