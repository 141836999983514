import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    margin: '16px 0 16px 0',
  },
  dateTxt: {
    width: '100%',
    padding: '8px 8px 20px',
  },
  infoContainner: {
    marginTop: '25%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  infoText: {
    fontSize: 16,
    lineHeight: 1.75,
    textAlign: 'center',
    color: '#00001e',

    '& span': {
      color: '#3c5bff',
      cursor: 'pointer',
    },
  },
});

export default useStyles;
