import React from "react";
import {
  withStyles,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  MenuItem,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Tabs,
  Tab,
  AppBar,
} from "@material-ui/core";

import { getText } from "src/utils/MultilingualLoader";
import MonacoEditor from "./Editor";
import AlgoReferenceList from "./AlgoReferenceList";
import { algoConfig } from "./AlgoReferenceList/config";

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    minWidth: 550,
    minHeight: 200,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "space-around",
    justifyContent: "space-around",
  },
  inner: {
    minHeight: 300,
  },
  schedule: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  alert: {
    minWidth: 300,
    minHeight: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    display: "flex",
    alignItems: "center",
    width: 100,
  },
  label2: {
    display: "flex",
    alignItems: "center",
    width: 100,
    marginBottom: 5,
  },
  addSpace: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

const InspectionConfig = (props) => {
  const {
    classes,
    open,
    onClose,
    algoOptions,
    algoConfigs,
    validate,
    handleAdd,
    handleChange,
    handleSave,
    handleDelete,
    handleSwitchTab,
    isInvalid,
    target,
  } = props;

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>
        <Grid className={classes.title}>
          <Typography variant="body1">{getText("algo_config")}</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid className={classes.inner}>
          <Grid>
            {algoConfigs.map((item, index) => (
              <Card key={item.id || index + 9999} style={{ marginBottom: 20 }}>
                <Grid item>
                  <AppBar position="static" color="default">
                    <Tabs
                      value={item.tab || 0}
                      onChange={handleSwitchTab(index)}
                      indicatorColor="primary"
                      aria-label="disabled tabs example"
                    >
                      <Tab label={getText("algo_parameters")} />
                      <Tab
                        label={getText("algo_reference")}
                        disabled={!item.id}
                      />
                    </Tabs>
                  </AppBar>
                </Grid>
                <CardContent style={{ background: "#fafafa" }}>
                  <Grid container direction="column" spacing={1}>
                    {!item.tab && (
                      <>
                        <Grid container item>
                          <FormLabel
                            component="label"
                            className={classes.label}
                          >
                            {getText("algorithm")}
                          </FormLabel>
                          <TextField
                            style={{ marginBottom: 10, minWidth: 180 }}
                            disabled={!!item.id}
                            error={
                              validate[index].algo &&
                              validate[index].algo.status
                            }
                            helperText={
                              validate[index].algo && validate[index].algo.msg
                            }
                            value={item.algo}
                            onChange={handleChange("algo", index)}
                            select
                          >
                            {algoOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item container>
                          <FormLabel
                            component="label"
                            className={classes.label}
                          >
                            {getText("action")}
                          </FormLabel>
                          <RadioGroup
                            aria-label="action"
                            name="action"
                            value={item.action}
                            onChange={handleChange("action", index)}
                          >
                            <Grid container>
                              <FormControlLabel
                                value="snapshot"
                                control={
                                  <Radio color="primary" disabled={!!item.id} />
                                }
                                label="snapshot"
                              />
                              <FormControlLabel
                                value="video"
                                control={
                                  <Radio color="primary" disabled={!!item.id} />
                                }
                                label="video"
                              />
                              <FormControlLabel
                                value="notify"
                                control={
                                  <Radio color="primary" disabled={!!item.id} />
                                }
                                label="notify"
                              />
                              <FormControlLabel
                                value="stop"
                                control={
                                  <Radio color="primary" disabled={!!item.id} />
                                }
                                label="stop"
                              />
                            </Grid>
                          </RadioGroup>
                        </Grid>
                        <Grid item container>
                          <FormLabel
                            component="label"
                            className={classes.label}
                          >
                            {getText("enable")}
                          </FormLabel>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={!!item.id}
                                color="primary"
                                checked={item.mode}
                                onChange={handleChange("mode", index)}
                                name="mode"
                              />
                            }
                          />
                        </Grid>
                        <Grid item container>
                          <FormLabel
                            component="label"
                            className={classes.label2}
                          >
                            {getText("parameters")}
                          </FormLabel>
                          <Grid xs item>
                            <MonacoEditor
                              value={item.rules}
                              onChange={handleChange("rules", index)}
                            />
                            {validate[index].rules &&
                              validate[index].rules.msg && (
                                <Grid style={{ color: "red" }}>
                                  {validate[index].rules.msg}
                                </Grid>
                              )}
                          </Grid>
                        </Grid>
                        <Grid item container justify="flex-end">
                          <Button
                            onClick={() => {
                              if (!item.id) {
                                handleDelete(index, item.id)();
                              } else if (
                                window.confirm(getText("confirm_delete"))
                              ) {
                                handleDelete(index, item.id)();
                              }
                            }}
                            style={{ marginTop: 12 }}
                            color="primary"
                          >
                            {item.id ? getText("delete") : getText("cancel")}
                          </Button>
                          <Button
                            onClick={() => {
                              if (isInvalid(index)) {
                                return;
                              }
                              if (
                                window.confirm(
                                  `${item.id
                                    ? getText("confirm_update")
                                    : getText("confirm_save")
                                  }?`
                                )
                              ) {
                                handleSave(index, item.id)();
                              }
                            }}
                            style={{ marginTop: 12 }}
                            color="primary"
                          >
                            {item.id ? getText("update") : getText("ok")}
                          </Button>
                        </Grid>
                      </>
                    )}
                    {Boolean(algoConfig[target.type]) && (
                      <AlgoReferenceList
                        algoConfigId={item.id}
                        target={target}
                        type={target.type}
                        show={item.tab === 1}
                      />
                    )}
                    {/* {item.tab === 0 && <Grid container justify="center">
                      <Button onClick={handleAdd} variant="outlined" color="primary">{getText('add_algo')}</Button>
                    </Grid>} */}
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </Grid>
          {/* {algoConfigs.length === 0 && <Grid container justify="center">
            <Button onClick={handleAdd} variant="outlined" color="primary">{getText('add_algo')}</Button>
          </Grid>} */}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(InspectionConfig);
