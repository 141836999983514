import React from 'react';
import { connect } from 'react-redux';

import { robotActions } from 'src/redux/actions';
import { robotServices } from 'src/services';
import RobotLiftStatus from './RobotLiftStatus';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = (state, props) => {
  const { selected } = props;
  return {
    robot: selected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRobotStatus: robot_id => {
      dispatch(robotActions.updateRobotStatus(robot_id));
    }
  };
};

class RobotLiftStatusContainer extends React.Component {
  state = {
    robotState: null
  }

  componentDidMount() {
    const { robot } = this.props;
    this.getRobotState(robot && robot.id);
  }

  componentDidUpdate(prevProps) {
    const { robot } = this.props;
    if (robot && (!prevProps.robot || robot.id !== prevProps.robot.id)) {
      this.getRobotState(robot.id);
    }
  }

  async getRobotState(robotId) {
    if (robotId) {
      const robotState = await robotServices.getRobotState(robotId);
      this.setState({robotState});
    }
  }

  render() {
    const { selected } = this.props;
    const { robotState } = this.state;
    if (!selected) return (null);
    let details = [];
    robotState && details.push(...[
      {
        title: getText('robot.state.lift_trip_id'),
        content: robotState.liftTripId
      },
      {
        title: getText('robot.state.lift_from'),
        content: robotState.liftFrom
      },
      {
        title: getText('robot.state.lift_to'),
        content: robotState.liftTo
      },
      {
        title: getText('robot.state.lift_task_id'),
        ccontent: robotState.liftTaskId
      },
      {
        title: getText('robot.state.lift_command_id'),
        content: robotState.liftCommandId
      },
      {
        title: getText('robot.state.lift_command_type'),
        content: robotState.liftCommandType
      },
      {
        title: getText('robot.state.lift_status'),
        content: robotState.liftStatus
      },
    ]);
    return (<RobotLiftStatus details={details} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RobotLiftStatusContainer);
