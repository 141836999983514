import WebSocketAPI from "./WebSocketAPI";
import store from 'src/redux/store';
import { wsActions } from 'src/redux/actions';

export default class WISP extends WebSocketAPI {
  constructor(name) {
    super(name);
    this.componentIds = {};
    this.connect_queue = [];
    this.connecting = false;
    this.callback = null;
  }

  //Mutual exclusion for multiple WISP connection at the same time
  connectWS = (url, onCloseCallback) => {
    return new Promise(async (resolve, reject) => {
      this.connect_queue.push(url);
      if (this.connecting) return resolve();
      this.connecting = true;
      while (!this.isConnected() && this.connect_queue.length > 0) {
        const firstUrl = this.connect_queue.shift();
        try {
          await super.connectWS(firstUrl);
        } catch(e) {
          console.log('connect error');
        }
      }
      this.connecting = false;
      this.callback = onCloseCallback;
      if (this.isConnected()) this.connect_queue = [];
      return resolve();
    });
  }

  close = () => {
    this.componentIds = {};
    this.connect_queue = [];
    this.connecting = false;
    super.close();
    if(this.callback) {
      this.callback();
    }
  }

  receiveMsg = message => {
    let jsonMessage;
    try {
      jsonMessage = JSON.parse(message.data);
    } catch (err) {
      return;
    }

    const request = this.requests[jsonMessage.id];
    // message responsed from server
    if (request && request.hasRes) {
      // map room_member_id to componentId
      if ((jsonMessage.act === 'view!' || jsonMessage.act === 'present!') 
        && jsonMessage.ret && jsonMessage.ret.room_member_id && request.componentId) {
        // register the component for the messages with its room member id from the server 
        this.componentIds[jsonMessage.ret.room_member_id] = request.componentId;
      }
    }

    //message sent from server
    else if (jsonMessage.arg && jsonMessage.arg.room_member_id) {
      const componentId = this.componentIds[jsonMessage.arg.room_member_id]
      componentId && store.dispatch(wsActions.receiveMsg('WISP', componentId, jsonMessage));
    }

    super.receiveMsg(message);
  }
}