import React from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Card,
  CardMedia,
  IconButton,
  Typography,
  Grid,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import Loader from "react-loader-advanced";
import ReactCursorPosition from "react-cursor-position";
import { MapInteractionCSS } from "react-map-interaction";

import { robotActions } from "src/redux/actions";
import { getText } from "src/utils/MultilingualLoader";
import OnOffSwitch from "src/components/Switches/OnOffSwitch";
import RobotMapStage from "./RobotMapStage";

export const getImagePosition =
  (mapConfig, imageSize, displaySize) => (x, y) => {
    if (!mapConfig || !imageSize || !displaySize) return { x: null, y: null };
    const { origin, resolution: r } = mapConfig;
    if (!origin || !r) return { x: null, y: null };
    let imageX = ((x - origin[0]) / r / imageSize.width) * displaySize.width;
    let imageY =
      ((imageSize.height - (y - origin[1]) / r) / imageSize.height) *
      displaySize.height;
    if (isNaN(imageX) || isNaN(imageY)) return { x: null, y: null };
    return {
      x: imageX,
      y: imageY,
    };
  };

export const getMapPosition = (mapConfig, imageSize, displaySize) => (x, y) => {
  if (!mapConfig || !imageSize || !displaySize) return { x: null, y: null };
  const { origin, resolution: r } = mapConfig;
  if (!origin || !r) return { x: null, y: null };
  let mapX = (imageSize.width / displaySize.width) * x * r + origin[0];
  let mapY = imageSize.height * r * (1 - y / displaySize.height) + origin[1];
  if (isNaN(mapX) || isNaN(mapY)) return { x: null, y: null };
  return {
    x: mapX,
    y: mapY,
  };
};

const mapStateToProps = (state) => {
  const { selected, robotCurMaps, robotConfigs } = state.robot;
  const configMap = selected && robotCurMaps[selected.id];
  const robotConfig = selected && robotConfigs[selected.id];
  return { robot: selected, configMap, robotConfig };
};

const mapDispatchToProps = (dispatch) => ({
  getRobotConfigs: (robotId) => dispatch(robotActions.getRobotConfigs(robotId)),
  updateRobotConfigs: (robotId, updConfigs) =>
    dispatch(robotActions.updateRobotConfigs(robotId, updConfigs)),
  getRobotConfigMap: (robotId, mapId, routeId) =>
    dispatch(robotActions.getRobotConfigMap(robotId, mapId, routeId)),
  clearRobotNav: (robotId) => dispatch(robotActions.clearRobotNav(robotId)),
});

const styles = (theme) => ({
  map: {
    flex: 1,
    width: "100%",
  },
  title: {
    textAlign: "center",
    padding: theme.spacing(1),
  },
  banner: {
    zIndex: 1,
    backgroundColor: "#FFFFFF",
  },
  switch: {
    margin: theme.spacing(1),
  },
  refreshButton: {
    padding: "0 12px",
  },
});

function getMapData(configMap) {
  const mapName = configMap && configMap.name;
  const mapUrl = configMap && configMap.mapUrl;
  const mapConfig = configMap && configMap.mapConfig;
  const lidarType = configMap && configMap.lidarType;
  const configRoute = configMap && configMap.mapRoute;
  const chargingBase = configMap && configMap.chargingBase;
  const landmarks = configMap && configMap.landmarks;
  const elevatorBase = configMap && configMap.elevatorBase;
  const virtualWallPoints =
    configMap && configMap.mapConfig && configMap.mapConfig.virtual_wall;
  const mapLoading = !mapUrl && configMap && configMap.loading;
  return {
    mapName,
    mapUrl,
    mapConfig,
    lidarType,
    configRoute,
    mapLoading,
    chargingBase,
    elevatorBase,
    landmarks,
    virtualWallPoints,
  };
}

function getSwitchPose(configMap, robotConfig) {
  if (!configMap || !robotConfig) {
    return null;
  }
  const maps = (robotConfig.connecting_floors || [])
    .map((item) => item.parts || [])
    .flat()
    .map((item) => item.map_id);
  return maps.includes(configMap.id) ? configMap.switchPose : null;
}

class RobotMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      imageSize: null,
      scale: 1,
      translation: { x: 0, y: 0 },
      enableNavUpdate: props.robotConfig
        ? props.robotConfig.enable_nav_update
        : false,
      relocalizationPoint: [],
      appendingVirtualWallPolygon: [],
    };
  }

  componentDidMount() {
    const { robot, configMap, getRobotConfigMap, robotConfig } = this.props;
    const { mapUrl } = getMapData(configMap);
    const current_map_id =
      (robot && robot.navState && robot.navState.map_id) ||
      (robotConfig && robotConfig.current_map_id);
    const current_route_id =
      (robot && robot.navState && robot.navState.route_id) ||
      (robotConfig && robotConfig.current_route_id);
    if (
      !mapUrl &&
      (!configMap || !configMap.loading) &&
      robot &&
      robot.id &&
      current_map_id &&
      current_route_id
    ) {
      getRobotConfigMap(robot.id, current_map_id, current_route_id);
    }
    if (mapUrl) {
      this.loadImage(mapUrl);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      robot,
      configMap,
      getRobotConfigMap,
      robotConfig,
      action,
      clearRobotNav,
    } = this.props;
    const { mapUrl } = getMapData(configMap);
    const {
      configMap: prevConfigMap,
      robot: prevRobot,
      robotConfig: prevRobotConfig,
    } = prevProps;
    const { mapUrl: prevMapUrl } = getMapData(prevConfigMap);
    // reset state when robot changes
    if (robot && (!prevProps.robot || robot.id !== prevProps.robot.id)) {
      this.setState({
        image: null,
        imageSize: null,
        scale: 1,
        translation: { x: 0, y: 0 },
        enableNavUpdate: robotConfig ? robotConfig.enable_nav_update : false,
        relocalizationPoint: [],
        appendingVirtualWallPolygon: [],
      });
    }

    // update robot config map when robot config current map or current route changes
    const current_map_id =
      (robot && robot.navState && robot.navState.map_id) ||
      (robotConfig && robotConfig.current_map_id);
    const current_route_id =
      (robot && robot.navState && robot.navState.route_id) ||
      (robotConfig && robotConfig.current_route_id);
    const prev_map_id =
      (prevRobot && prevRobot.navState && prevRobot.navState.map_id) ||
      (prevRobotConfig && prevRobotConfig.current_map_id);
    const prev_route_id =
      (prevRobot && prevRobot.navState && prevRobot.navState.route_id) ||
      (prevRobotConfig && prevRobotConfig.current_route_id);
    if (
      robotConfig &&
      robot &&
      robot.id &&
      (!configMap || !configMap.loading) &&
      ((current_map_id && current_map_id !== prev_map_id) ||
        (current_route_id && current_route_id !== prev_route_id))
    ) {
      getRobotConfigMap(robot.id, current_map_id, current_route_id);
    }

    // update show robot location status when robot config changes
    if (
      robotConfig &&
      robotConfig.enable_nav_update !== undefined &&
      (!prevProps.robotConfig ||
        robotConfig.enable_nav_update !==
          prevProps.robotConfig.enable_nav_update)
    ) {
      this.setState({
        enableNavUpdate: robotConfig.enable_nav_update,
      });
    }

    // clear robot location when map changes
    if (robotConfig && robot && robot.id && current_map_id !== prev_map_id) {
      clearRobotNav(robot.id);
    }

    if (action !== prevProps.action) {
      this.setState({
        relocalizationPoint: [],
        appendingVirtualWallPolygon: [],
      });
    }

    if (mapUrl && mapUrl !== prevMapUrl) {
      this.loadImage(mapUrl);
    }
  }

  refreshMap = () => {
    const { robot, getRobotConfigs, robotConfig } = this.props;
    if (!robotConfig || !robotConfig.updating) {
      getRobotConfigs(robot && robot.id);
    }
  };

  handleSwitch = (event) => {
    const { robotConfig, robot, updateRobotConfigs } = this.props;
    const enableNavUpdate = event.target.checked;
    const newRobotConfig = {};
    this.setState({ enableNavUpdate }, () => {
      //sensor_types' value such as: g5st,patrol_performance or patrol_performance,g5st
      if (!enableNavUpdate) {
        if (
          robotConfig.sensor_types &&
          robotConfig.sensor_types.includes("patrol_performance")
        ) {
          const regexPatrol = /,?patrol_performance,?/;
          newRobotConfig.sensor_types = robotConfig.sensor_types.replace(
            regexPatrol,
            ""
          );
        }
      }
      updateRobotConfigs(robot && robot.id, {
        ...newRobotConfig,
        enable_nav_update: enableNavUpdate,
      });
    });
  };

  loadImage(mapSrc) {
    const { setMapImageReady } = this.props;
    const image = new window.Image();
    this.setState({
      image: null,
      imageSize: null,
    });
    image.onload = () => {
      this.setState({
        image,
        imageSize: {
          width: image.width,
          height: image.height,
        },
      });
      setMapImageReady && setMapImageReady(true);
    };
    image.src = mapSrc;
  }

  onMapClick = (mapConfig, imageSize, displaySize) => (position) => {
    const {
      action,
      newVirtualWallPolygons,
      setNewVirtualWallPolygons,
      onGoTo,
      onRelocalize,
    } = this.props;
    const { relocalizationPoint, appendingVirtualWallPolygon } = this.state;
    const getClickMapPosition = getMapPosition(
      mapConfig,
      imageSize,
      displaySize
    );
    const getClickImagePosition = getImagePosition(
      mapConfig,
      imageSize,
      displaySize
    );
    switch (action) {
      case "go_to":
        const mapPosition = getClickMapPosition(position.x, position.y);
        onGoTo(mapPosition.x, mapPosition.y);
        return;

      case "relocalize":
        if (relocalizationPoint.length === 0) {
          const headPosition = getClickMapPosition(position.x, position.y);
          this.setState({
            relocalizationPoint: [headPosition.x, headPosition.y],
          });
        } else {
          const headPosition = {
            x: relocalizationPoint[0],
            y: relocalizationPoint[1],
          };
          const endPosition = getClickMapPosition(position.x, position.y);
          if (
            headPosition.x === endPosition.x &&
            headPosition.y === endPosition.y
          ) {
            alert(getText("input_error_relocalization"));
          } else {
            const theta = this.getAngle(headPosition, endPosition);
            if (
              window.confirm(
                `Relocalize robot at ${headPosition.x}, ${headPosition.y} with angle ${theta}`
              )
            ) {
              onRelocalize(headPosition.x, headPosition.y, theta);
            }
          }
          this.setState({ relocalizationPoint: [] });
        }
        return;

      case "virtual_wall_append":
        //last point closing the polygon
        if (
          appendingVirtualWallPolygon.length >= 6 &&
          Math.sqrt(
            Math.pow(position.x - appendingVirtualWallPolygon[0], 2) +
              Math.pow(position.y - appendingVirtualWallPolygon[1], 2)
          ) < 10
        ) {
          const virtualWallsPolygons = Object.assign(
            [],
            newVirtualWallPolygons
          );
          const mapPolygon = [];
          for (
            let idx = 0;
            idx < appendingVirtualWallPolygon.length - 1;
            idx += 2
          ) {
            const position = getClickMapPosition(
              appendingVirtualWallPolygon[idx],
              appendingVirtualWallPolygon[idx + 1]
            );
            mapPolygon.push([position.x, position.y]);
          }
          const position = getClickMapPosition(
            appendingVirtualWallPolygon[0],
            appendingVirtualWallPolygon[1]
          );
          mapPolygon.push([position.x, position.y]);
          virtualWallsPolygons.push(mapPolygon);
          setNewVirtualWallPolygons(virtualWallsPolygons);
          this.setState({ appendingVirtualWallPolygon: [] });
        } else if (
          appendingVirtualWallPolygon.length >= 4 &&
          this.hasIntersection(position, appendingVirtualWallPolygon)
        ) {
          alert(getText("input_error_virtual_wall"));
        } else {
          const virtualWallPolygon = Object.assign(
            [],
            appendingVirtualWallPolygon
          );
          this.setState({
            appendingVirtualWallPolygon: virtualWallPolygon.concat([
              position.x,
              position.y,
            ]),
          });
        }
        return;
      case "virtual_wall_delete":
        newVirtualWallPolygons.some((polygon, idx) => {
          const polygonPoints = [];
          polygon &&
            polygon.forEach((point) => {
              const position =
                point && getClickImagePosition(point[0], point[1]);
              if (
                position &&
                position.x &&
                position.y &&
                !isNaN(position.x) &&
                !isNaN(position.y)
              )
                polygonPoints.push(position.x, position.y);
            });
          const polygonSelected = this.pointInPolygon(position, polygonPoints);
          if (polygonSelected) {
            const virtualWallsPolygons = Object.assign(
              [],
              newVirtualWallPolygons
            );
            virtualWallsPolygons.splice(idx, 1);
            setNewVirtualWallPolygons(virtualWallsPolygons);
          }
          return polygonSelected;
        });
        return;
      default:
        return;
    }
  };

  getAngle = (p1, p2) => {
    const diffX = p2.x - p1.x;
    const diffY = p2.y - p1.y;
    if (diffX === 0) return diffY > 0 ? Math.PI / 2 : -Math.PI / 2;
    if (diffX > 0) {
      return Math.atan(diffY / diffX);
    } else if (diffY > 0) {
      return Math.PI - Math.atan(-diffY / diffX);
    } else {
      return Math.atan(diffY / diffX) - Math.PI;
    }
  };

  onSegment = (p1, p2, q) => {
    if (
      q[0] <= Math.max(p1[0], p2[0]) &&
      q[0] >= Math.min(p1[0], p2[0]) &&
      q[1] <= Math.max(p1[1], p2[1]) &&
      q[1] >= Math.min(p1[1], p2[1])
    )
      return true;
    else return false;
  };

  orientation = (p1, p2, q) => {
    const ret =
      (p2[1] - p1[1]) * (q[0] - p2[0]) - (p2[0] - p1[0]) * (q[1] - p2[1]);
    if (ret === 0) return 0;
    return ret > 0 ? 1 : 2;
  };

  doIntersect = (p1, p2, q1, q2) => {
    const o1 = this.orientation(p1, p2, q1);
    const o2 = this.orientation(p1, p2, q2);
    const o3 = this.orientation(q1, q2, p1);
    const o4 = this.orientation(q1, q2, p2);
    if (o1 !== o2 && o3 !== o4) return true;
    if (o1 === 0 && this.onSegment(p1, p2, q1)) return true;
    if (o2 === 0 && this.onSegment(p1, p2, q2)) return true;
    if (o3 === 0 && this.onSegment(q1, q2, p1)) return true;
    if (o4 === 0 && this.onSegment(q1, q2, p2)) return true;
    return false;
  };

  hasIntersection = (position, points) => {
    for (let idx = 0; idx < points.length - 4; idx += 2) {
      const p1 = [points[idx], points[idx + 1]];
      const p2 = [points[idx + 2], points[idx + 3]];
      const q1 = [points[points.length - 2], points[points.length - 1]];
      const q2 = [position.x, position.y];
      if (this.doIntersect(p1, p2, q1, q2)) return true;
    }
    //check for last 2 points selected
    const p1 = [points[points.length - 4], points[points.length - 3]];
    const p2 = [points[points.length - 2], points[points.length - 1]];
    const q2 = [position.x, position.y];
    const o2 = this.orientation(p1, p2, q2);
    if (o2 === 0 && this.onSegment(p1, p2, q2)) return true;
    return false;
  };

  pointInPolygon = (position, polygon) => {
    if (polygon.length < 3) return false;
    let count = 0;
    const q1 = [position.x, position.y];
    const q2 = [Number.MAX_SAFE_INTEGER, position.y];
    for (let idx = 0; idx < polygon.length - 2; idx += 2) {
      const p1 = [polygon[idx], polygon[idx + 1]];
      const p2 = [polygon[idx + 2], polygon[idx + 3]];
      if (this.doIntersect(p1, p2, q1, q2)) {
        if (this.orientation(p1, p2, q1) === 0) {
          return this.onSegment(p1, p2, q1);
        }
        count++;
      }
    }
    return count === 1;
  };

  render() {
    const {
      classes,
      mapWidth,
      mapHeight,
      maxMapWidth,
      maxMapHeight,
      robot,
      configMap,
      robotConfig,
      action,
      newVirtualWallPolygons,
    } = this.props;
    const {
      image,
      imageSize,
      scale,
      translation,
      enableNavUpdate,
      relocalizationPoint,
      appendingVirtualWallPolygon,
    } = this.state;
    const {
      mapName,
      mapConfig,
      configRoute,
      mapLoading,
      mapUrl,
      chargingBase,
      landmarks,
      virtualWallPoints,
      elevatorBase,
      lidarType
    } = getMapData(configMap);
    const switchPose = getSwitchPose(configMap, robotConfig);
    const robotLocation = robot && robot.location;
    // map size with original radio
    let mapWidthWithRadio = null;
    let mapHeightWithRadio = null;
    if (imageSize && maxMapWidth && maxMapHeight) {
      const radio =
        imageSize.height !== 0 ? imageSize.width / imageSize.height : 0;
      mapWidthWithRadio = maxMapWidth;
      mapHeightWithRadio = radio !== 0 ? mapWidthWithRadio / radio : 0;
      if (mapHeightWithRadio > maxMapHeight) {
        mapHeightWithRadio = maxMapHeight;
        mapWidthWithRadio = mapHeightWithRadio * radio;
      }
    }

    return (
      <Card>
        <Grid
          direction="column"
          container={true}
          justify="flex-start"
          alignItems="center"
          spacing={0}
        >
          <Grid item={true}>
            <Loader
              style={{
                zIndex: 0,
                minWidth: mapWidth,
                minHeight: mapHeight || maxMapHeight,
              }}
              show={mapLoading || !image ? true : false}
              message={
                (mapLoading && getText("loading")) ||
                (mapUrl && getText("loading_image")) ||
                (!mapLoading && !mapUrl && getText("please_upload_map"))
              }
              hideContentOnLoad
            >
              {image && (
                <CardMedia className={classes.map} src="img">
                  <MapInteractionCSS
                    scale={scale}
                    translation={translation}
                    onChange={({ scale, translation }) => {
                      this.setState({
                        scale,
                        translation: scale === 1 ? { x: 0, y: 0 } : translation,
                      });
                    }}
                    minScale={1}
                    maxScale={10}
                  >
                    <ReactCursorPosition
                      // force posotion to update when scale changes
                      key={scale}
                    >
                      <RobotMapStage
                        mapWidth={mapWidth || mapWidthWithRadio}
                        mapHeight={mapHeight || mapHeightWithRadio}
                        image={image}
                        scale={scale}
                        mapName={mapName}
                        lidarType={lidarType}
                        switchPose={switchPose}
                        configRoute={configRoute}
                        chargingBase={chargingBase}
                        landmarks={landmarks}
                        robotLocation={enableNavUpdate ? robotLocation : null}
                        action={action}
                        virtualWallPoints={virtualWallPoints}
                        newVirtualWallPolygons={newVirtualWallPolygons}
                        appendingVirtualWallPolygon={
                          appendingVirtualWallPolygon
                        }
                        relocalizationPoint={relocalizationPoint}
                        elevatorBase={elevatorBase}
                        getImagePosition={getImagePosition(
                          mapConfig,
                          imageSize,
                          {
                            width: mapWidth || mapWidthWithRadio,
                            height: mapHeight || mapHeightWithRadio,
                          }
                        )}
                        onMapClick={this.onMapClick(mapConfig, imageSize, {
                          width: mapWidth || mapWidthWithRadio,
                          height: mapHeight || mapHeightWithRadio,
                        })}
                      />
                    </ReactCursorPosition>
                  </MapInteractionCSS>
                </CardMedia>
              )}
            </Loader>
          </Grid>
          <Grid
            item
            container={true}
            justify="center"
            alignItems="center"
            className={classes.banner}
          >
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                {getText("map")}
                <IconButton
                  className={classes.refreshButton}
                  onClick={this.refreshMap}
                >
                  <RefreshIcon />
                </IconButton>
                {getText("robot_location")}:
              </Typography>
            </Grid>
            <Grid item>
              <OnOffSwitch
                value={enableNavUpdate}
                onChange={this.handleSwitch}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RobotMap));
