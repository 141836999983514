import uuid from 'uuid';

import store from 'src/redux/store';
import { wsActions } from 'src/redux/actions';
import { getText } from 'src/utils/MultilingualLoader';

export default class WebSocketAPI {
  constructor(name) {
    this.name = name;
    this.websocket = null;
    this.requests = {};
    this.pingInterval = null;
    this.pongRequired = 0;
    this.handler = {};
    // Do not use arrow function for Inheritance
    this.connectWS = this.connectWS.bind(this);
    this.ping = this.ping.bind(this);
    this.close = this.close.bind(this);
    this.sendMsg = this.sendMsg.bind(this);
    this.receiveMsg = this.receiveMsg.bind(this);
  }

  connectWS(url) {
    return new Promise((resolve, reject) => {
      if ("WebSocket" in window && !this.isConnected() && !this.isConnecting()) {
        this.websocket = new WebSocket(url);
        if (!this.pingInterval) {
          this.pingInterval = setInterval(this.ping, 5000);
        }
        this.websocket.onmessage = this.receiveMsg;
        this.websocket.onopen = () => {
          store.dispatch(wsActions.statusChange(this.name, 'open'));
          resolve();
        };
        this.websocket.onclose = () => {
          store.dispatch(wsActions.statusChange(this.name, 'closed'));
          reject('websocket connection closed');
        }
        this.websocket.onerror = () => {
          store.dispatch(wsActions.statusChange(this.name, 'error'));
          reject('websocket connection error');
        }
      }
      else reject();
    });
  }

  isConnected() {
    return this.websocket && this.websocket.readyState === 1;
  }

  isConnecting() {
    return this.websocket && this.websocket.readyState === 0;
  }

  ping() {
    if (!this.isConnected()) return;
    //close websocket if missing pong
    if (this.pongRequired > 0) {
      console.log('ping timeout')
      this.close();
      return;
    }

    const pingMsg = {
      'id': uuid(),
      'act': 'ping',
      'arg': {}
    }
    const jsonMessage = JSON.stringify(pingMsg);
    this.websocket.send(jsonMessage);
    this.pongRequired += 1;
  }

  close() {
    if (this.websocket) this.websocket.close();
    if (this.pingInterval) clearInterval(this.pingInterval);
    this.websocket = null;
    this.requests = {};
    this.pingInterval = null;
    this.pongRequired = 0;
    console.log(this.name + ' closed');
  }

  sendMsg(message, options = {}) {
    return new Promise((resolve, reject) => {
      if (!this.isConnected()) return reject('websocket not connected');
      const stringMessage = JSON.stringify(message);
      const { hasRes, timeout, callback } = options;
      if (hasRes) {
        const request_id = message.id;
        let request = options;
        // set timeout handler
        if (timeout) {
          request['timeout'] = setTimeout(() => {
            callback && callback(null, new Error(getText('websocket_response_timeout')));
          }, timeout);
        }
        this.requests[request_id] = request;
      }
      this.websocket.send(stringMessage);
      resolve();
    });
  }

  receiveMsg(message) {
    let jsonMessage;
    try {
      jsonMessage = JSON.parse(message.data);
    } catch (err) {
      jsonMessage = null;
    }

    try {
      if (this.handler[jsonMessage.act]) {
        this.handler[jsonMessage.act].forEach(fn => fn(jsonMessage));
      }
    } catch (err) {
      console.error(err);
    }

    // pong message
    if (jsonMessage && jsonMessage.act === 'pong') {
      this.pongRequired = 0;
      return;
    }

    // request - response
    const request = this.requests[jsonMessage.id];
    if (request && request.hasRes) {
      // clear timeOut
      if (request.timeout) {
        clearTimeout(this.requests[jsonMessage.id].timeout);
      }

      // invoke callback
      if (request.callback) {
        if (jsonMessage) this.requests[jsonMessage.id].callback(jsonMessage, null);
        else this.requests[jsonMessage.id].callback(null, new Error(getText('json_msg_parse_error')));
      }

      // dispatch message to registered component
      if (request.componentId) {
        store.dispatch(wsActions.receiveMsg(this.name, this.requests[jsonMessage.id].componentId, jsonMessage));
      }

      // delete request after response received
      if (!request.resNumRqd || !--request.resNumRqd) {
        delete this.requests[jsonMessage.id];
      }
    }
  }

  on(type, fn) {
    if (this.handler[type]) {
      if (!this.handler[type].includes(fn)) {
        this.handler[type].push(fn);
      }
    } else {
      this.handler[type] = [fn];
    }
  }

  off(type, fn) {
    if (this.handler[type]) {
      if (fn === undefined) {
        this.handler[type] = [];
      } else {
        this.handler[type] = this.handler[type].filter(item => item !== fn);
      }
    }
  }
}
