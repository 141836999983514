import React, {useState} from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import TargetList from "./TargetList";
import TargetFilter from "./TargetFilter";
import InspectionConfigs from './InspectionConfigs';
import RouteConfigs from './RouteConfigs';

const styles = theme => ({
  root: {
  },
  tableGrid: {
    overflow: 'auto',
  },
  filter: {
    width: '100%',
    paddingBottom: 20,
  },
  label: {
    width: 120,
  }
});

const Stores = props => {
  const { classes } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [target, setTarget] = useState({});
  const [mutator, setMutator] = useState(false);

  return (
    <Grid
      className={classes.root}
      container={true}
      spacing={0}
    >
      <Grid className={classes.filter}>
        <TargetFilter showMutator={() => {
          setMutator(true);
        }} />
      </Grid>
      <Grid item={true} xs={12} className={classes.tableGrid}>
        <TargetList setDialogOpen={setDialogOpen} setTarget={setTarget} />
      </Grid>
      {dialogOpen && <InspectionConfigs open={dialogOpen} target={target} onClose={() => setDialogOpen(false)} />}
      {mutator && <RouteConfigs hideMutator={() => {
        setMutator(false);
      }} />}
    </Grid>
  );
};  

export default withStyles(styles)(Stores);
