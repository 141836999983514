import React from 'react';

import { connect } from 'react-redux';
import { caseActions } from 'src/redux/actions';
import SearchBar from 'src/pages/Main/SearchBar';
import CaseFilter from '../CaseFilter';

const mapStateToProps = state => {
  const { filters, loading } = state.case;
  const { searchQuery } = filters;
  return { searchQuery, filters, loading };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilter: filters => {
      dispatch(caseActions.setFilter(filters));
    },
    getCases: filters => {
      dispatch(caseActions.getCases(filters));
    },
    unselectCase: () => dispatch(caseActions.selectCase(null))
  };
};

class SearchBarContainer extends React.Component {
  handleSearchQueryChange = (searchQuery) => {
    const { filters, setFilter, getCases, unselectCase, loading } = this.props;
    const search_filter = {
      searchQuery: searchQuery,
    };
    setFilter(search_filter);
    if (!loading) {
      getCases(Object.assign({}, filters, search_filter));
      unselectCase();
    }
  }

  render() {
    return (
      <SearchBar
        filterChild={<CaseFilter />}
        initialQuery={this.props.searchQuery}
        onChangeSearchQuery={this.handleSearchQueryChange}
      />
    );
  }
}

SearchBarContainer.propTypes = {
};

SearchBarContainer.defaultProps = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchBarContainer);
