import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";

import HistoryPlayer from "../HistoryPlayer";

const styles = (theme) => ({
  root: {
    height: "100%",
  },
  title: {
    textAlign: "center",
    padding: theme.spacing(1),
  },
  banner: {
    zIndex: 1,
    backgroundColor: "#FFFFFF",
  },
  refreshButton: {
    padding: "0 12px",
  },
});

class PlayerCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playerType: "live",
    };
    this.shiftPlayer = this.shiftPlayer.bind(this);
  }

  shiftPlayer(playerType) {
    this.setState({
      playerType: playerType,
    });
  }

  render() {
    const {
      classes,
      player,
      camera,
      showHistory,
      refresh,
      minHeight,
    } = this.props;
    const { playerType } = this.state;

    return (
      <Card square={true} className={classes.root}>
        <Grid
          direction="column"
          container={true}
          justify="flex-start"
          alignItems="stretch"
          style={{ height: "100%" }}
          spacing={0}
        >
          {playerType === "live" && (
            <Grid item xs>
              {player}
            </Grid>
          )}
          {showHistory && (
            <HistoryPlayer
              camera={camera}
              playerType={playerType}
              shiftPlayer={this.shiftPlayer}
              minHeight={minHeight}
            />
          )}
          <Grid item={true} className={classes.banner}>
            <Typography variant="body1" className={classes.title}>
              {camera.name}
              {refresh && (
                <IconButton className={classes.refreshButton} onClick={refresh}>
                  <RefreshIcon />
                </IconButton>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

PlayerCard.propTypes = {
  classes: PropTypes.object.isRequired,
  player: PropTypes.node.isRequired,
  showHistory: PropTypes.bool,
  camera: PropTypes.object,
  refresh: PropTypes.func,
};

PlayerCard.defaultProps = {
  showHistory: false,
};

export default withStyles(styles)(PlayerCard);
