import React from 'react';
import { withStyles, Grid, Card } from '@material-ui/core';

import MapTransform from './MapTransform';

const styles = theme => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

class Map extends React.Component {
  render() {
    const { classes, mapsPanelState, setMapsPanelState } = this.props;
    const { curAction, curMap } = mapsPanelState;

    const maxMapWidth = window.innerWidth - 400 - 16;
    const maxMapHeight = window.innerHeight * 0.5;
    const mapImageSize = curMap.mapSize || [maxMapWidth, maxMapHeight]
    let mapWidthWithRadio = null;
    let mapHeightWithRadio = null;
    const radio = mapImageSize[1] !== 0 ? mapImageSize[0] / mapImageSize[1] : 0;
    mapWidthWithRadio = maxMapWidth;
    mapHeightWithRadio = radio !== 0 ? mapWidthWithRadio / radio : 0;
    if (mapHeightWithRadio > maxMapHeight) {
      mapHeightWithRadio = maxMapHeight;
      mapWidthWithRadio = mapHeightWithRadio * radio;
    }

    switch(curAction) {
      case 'transform':
        return (
          <MapTransform
            mapsPanelState={mapsPanelState}
            setMapsPanelState={setMapsPanelState}
          />
        );
      default:
        return (
          <Card className={classes.card}>
            <img
              src={curMap.mapUrl}
              alt={curMap.name}
              width={mapWidthWithRadio}
              height={mapHeightWithRadio}
            />
          </Card>
        );
    }
  }
}

export default withStyles(styles)(Map);