import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
})

const LineSizeButton = props => {
    const { classes, anchorEl, lineSizes, handleOpenMenu, handleCloseMenu, handleOnClick } = props;
    const open = Boolean(anchorEl);
    return (
      <React.Fragment>
        <IconButton
          className={classes.account}
          onClick={handleOpenMenu}>
          <ViewComfyIcon />
        </IconButton>
        <Menu
          id="menu-linesize"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleCloseMenu}
          >
          <MenuItem disabled>
            <Typography variant="subtitle1">
              {getText('cameras_per_row')}:
            </Typography>
          </MenuItem>
          {
            lineSizes.map((size, index) => (
              <MenuItem key={size} onClick={handleOnClick(size)}>
                {size}
              </MenuItem>
            ))
          }      
        </Menu>
      </React.Fragment>
    );
}

LineSizeButton.propTypes = {
  classes: PropTypes.object.isRequired,
  lineSizes: PropTypes.array.isRequired,
  handleOpenMenu: PropTypes.func.isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
  handleOnClick: PropTypes.func.isRequired,
};

LineSizeButton.defaultProps = {
};

export default withStyles(styles)(LineSizeButton);
