import React, {PureComponent, lazy, Suspense} from 'react';

const ReactMonacoEditor = lazy(() => import('./ReactMonacoEditor'));

function findValueBoundry(code, offset) {
  let start = offset;
  let end = offset;
  const startBoundry = [
    10, //LF
    13, //CR
    32, //space
    34, // "
    // 44, // ,
    58, //:
  ];
  // just for the condition where init start position is just before the end "
  if (start >= 1 && ![10, 13, 32, 58].includes(code.charCodeAt(start - 1))) {
    start -= 1;
  }
  while (!startBoundry.includes(code.charCodeAt(start)) && start > 0) {
    start -= 1;
  }
  let endBoundry;
  if (code.charCodeAt(start) === 34) {
    endBoundry = [34];
  } else {
    endBoundry = [10, 13, 32, 44];
  }
  while (!endBoundry.includes(code.charCodeAt(end)) && end < code.length - 1) {
    end += 1;
  }
  return {start, end};
}

class Editor extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.code) {
      return {
        code: props.value,
      };
    }
    return null;
  }
  constructor(props) {
    super(props);
    this.state = {
      code: props.value || '',
    };
  }
  editorDidMount = (editor, monaco) => {
    this.editor = editor;
    editor.onDidChangeCursorPosition((e) => {
      this.position = e.position;
    });
  };
  replaceSelectedValue = (newValue) => {
    const code = this.state.code || '';
    const model = this.editor && this.editor.getModel();
    if (model && this.position) {
      const offset = model.getOffsetAt(this.position);
      const {start, end} = findValueBoundry(code, offset);
      if (code.charCodeAt(start) !== 34) {
        newValue = `"${newValue}"`;
      }
      const newCode =
        code.substring(0, start === end ? start : start + 1) +
        (newValue || '') +
        code.substring(end);
      this.onChangeCode(newCode);
    }
  };
  getSelectedValue = () => {
    const code = this.state.code || '';
    const model = this.editor && this.editor.getModel();
    if (model && this.position) {
      const offset = model.getOffsetAt(this.position);
      const {start, end} = findValueBoundry(code, offset);
      if (start === end) {
        return '';
      }
      return code.substring(start + 1, end);
    }
    return '';
  };
  onChangeCode = (newValue) => {
    this.setState({
      code: newValue,
    });
    const {onChange} = this.props;
    onChange(newValue);
  };
  render() {
    const {width, height} = this.props;
    const code = this.state.code;
    const options = {
      selectOnLineNumbers: true,
      minimap: {enabled: false},
    };
    return (
      <div style={{border: '1px solid #e5e5e5'}}>
        <Suspense fallback={<div>...loading</div>}>
          <ReactMonacoEditor
            width={width || '100%'}
            height={height || '160px'}
            language="json"
            value={code}
            options={options}
            onChange={this.onChangeCode}
            editorDidMount={this.editorDidMount}
          />
        </Suspense>
      </div>
    );
  }
}

export default Editor;
