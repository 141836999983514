import React from 'react';
import {
  withStyles, Grid, Table, TableHead, TableBody, TableRow, TableCell, Input, Button, Card, CardContent, Typography,
  Dialog, DialogTitle, DialogContent, TablePagination
} from '@material-ui/core';
import { getText } from 'src/utils/MultilingualLoader';
import SiteBuildingRow from './SiteBuildingRow';
import AddBuilding from './AddBuilding';
import AddFloor from './AddFloor';

const styles = theme => ({
  button: {
    margin: 24,
  },
  label: {
    color: 'white',
    textTransform: 'initial'
  },
  tableGrid: {
    height: '100%',
  },
  tableCard: {
    overflow: 'auto'
  },
  table: {
    width: '100%',
  },
  header: {
    fontSize: '1rem'
  },
  row: {
    fontSize: '1rem'
  },
  iconButton: {
    marginRight: '0.5rem',
    '&:hover': {
      cursor: 'pointer',
    }
  },
});

const tableHeaders = [
  ' ',
  'building_name',
  'site',
  'actions'
];

const SiteBuildingList = props => {
  const {
    classes,
    filter,
    total,
    buildings,
    floorLoading,
    floors,
    sites,
    handleChangePage,
    handleChangeRowsPerPage,
    editBuilding,
    editBuildingValue,
    editFloor,
    editFloorValue,
    buildingOfEditFloor,
    handleAddBuilding,
    handleEditBuilding,
    handleCloseEditBuilding,
    handleDeleteBuilding,
    handleAddFloor,
    handleEditFloor,
    handleCloseEditFloor,
    handleDeleteFloor,
    getSiteFloors
  } = props;

  return (
    <Grid
      container={true}
      justify="flex-start"
      direction="row"
      alignItems="stretch"
      spacing={0}
    >
      <Grid item={true} xs={12}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleAddBuilding}
        >
          <Typography variant="body2" className={classes.label}>
            {getText('add_building')}
          </Typography>
        </Button>
      </Grid>
      <Grid item={true} xs={12} className={classes.tableGrid}>
        <Card className={classes.tableCard}>
          <CardContent>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => (
                    <TableCell key={index} className={classes.header}>{getText(header)}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {buildings.map(building => (
                  <SiteBuildingRow
                    key={building.id} 
                    row={building}
                    floorLoading={floorLoading[building.id]}
                    floors={floors[building.id]}
                    sites={sites}
                    handleEditBuilding={handleEditBuilding}
                    handleDeleteBuilding={handleDeleteBuilding}
                    handleAddFloor={handleAddFloor}
                    handleEditFloor={handleEditFloor}
                    handleDeleteFloor={handleDeleteFloor}
                    getSiteFloors={getSiteFloors}
                  />
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25]}
              count={total}
              rowsPerPage={filter.limit}
              page={filter.page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </CardContent>
        </Card>
      </Grid>

      {editBuilding && <AddBuilding building={editBuildingValue} onClose={handleCloseEditBuilding} />}
      {editFloor && <AddFloor floor={editFloorValue} building={buildingOfEditFloor} onClose={handleCloseEditFloor} />}
    </Grid>
  );
};

export default withStyles(styles)(SiteBuildingList);
