import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  Button,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";

import { getText } from "src/utils/MultilingualLoader";
import TargetPagination from "./TargetPagination";
import SplitButton from "./SplitButton";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
  },
  button: {
    margin: 24,
  },
  label: {
    color: "white",
    textTransform: "initial",
  },
  tableCard: {
    overflow: "auto",
  },
  table: {
    width: "100%",
    fontSize: "1rem",
  },
  serial_no: {
    maxWidth: 200,
    wordBreak: 'break-all',
  },
  numberCell: {
    width: "18%",
  },
  labelCell: {
    width: "18%",
  },
  typeCell: {
    width: "12%",
  },
  floorCell: {
    width: "10%",
  },
  header: {
    fontSize: "1rem",
  },
  row: {
    fontSize: "1rem",
  },
  iconButton: {
    marginRight: "0.5rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
  alert: {
    minWidth: 300,
    minHeight: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const tableHeaders = [
  "target_no",
  "target_name",
  "target_type",
  "floor",
  "algo_reference",
  "action",
  "reference_image",
];

const buttonOptions = [
  {
    label: getText("edit_algo"),
  },
  {
    label: getText("enable"),
  },
  {
    label: getText("disable"),
  },
];

const StorePanel = (props) => {
  const {
    classes,
    targets,
    pagination,
    category,
    setDialogOpen,
    setTarget,
    showInspectionModal,
    showReferenceTarget,
    handleEnable,
    handleDisable,
  } = props;

  return (
    <Grid item={true} xs={12} className={classes.root}>
      <Card className={classes.tableCard}>
        <CardContent>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {tableHeaders.map((header, index) => (
                  <TableCell key={index} className={classes.header}>
                    {getText(header)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {targets.map((target, index) => {
                return (
                  <TableRow hover={true} key={index}>
                    <TableCell className={classes.numberCell}>
                      <div className={classes.serial_no}>{target.number}</div>
                    </TableCell>
                    <TableCell className={classes.labelCell}>
                      {target.label}
                    </TableCell>
                    <TableCell className={classes.typeCell}>
                      {target.type}
                    </TableCell>
                    <TableCell className={classes.floorCell}>
                      {target.floor}
                    </TableCell>
                    <TableCell className={classes.floorCell}>
                      {getText(target.algo_references_exist ? 'completed' : 'uncompleted')}
                    </TableCell>
                    <TableCell className={classes.editCell}>
                      <SplitButton
                        options={buttonOptions}
                        primary={target.algo_configs_exist}
                        handleButtonClick={(index) => {
                          if (index === 0) {
                            setTarget(target);
                            setDialogOpen(true);
                          } else if (index === 1) {
                            if (
                              window.confirm(`${getText("confirm_update")}?`)
                            ) {
                              handleEnable(target.id)();
                            }
                          } else if (index === 2) {
                            if (
                              window.confirm(`${getText("confirm_update")}?`)
                            ) {
                              handleDisable(target.id)();
                            }
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell className={classes.editCell}>
                      <Button
                        color="primary"
                        onClick={() => showInspectionModal(target)}
                      >
                        {getText("set")}
                      </Button>
                      <Button
                        color="primary"
                        disabled={!Boolean(target.reference_image)}
                        onClick={() => showReferenceTarget(target)}
                      >
                        {getText("view")}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <TargetPagination
        curPage={pagination.curPage}
        count={pagination.count}
        loading={false}
      />
    </Grid>
  );
};

export default withStyles(styles)(StorePanel);
