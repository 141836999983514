import React, {useState, useEffect} from 'react';
import {makeStyles, useTheme, Theme, Zoom, Fab} from '@material-ui/core';
import {ArrowUpward as ArrowUpIcon} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    right: 50,
    bottom: 50,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));



const BackTop = ({
  visibilityHeight = 300,
  onClick,
  children,
}) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);

    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  function toggleVisible() {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > visibilityHeight) {
      setVisible(true);
    } else if (scrolled <= visibilityHeight) {
      setVisible(false);
    }
  }

  function scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
    onClick && onClick();
  }

  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <>
      <Zoom
        in={visible}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${visible ? transitionDuration.exit : 0}ms`,
        }}
        unmountOnExit
      >
        {children ? (
          <>{children}</>
        ) : (
          <Fab onClick={scrollToTop} className={classes.root} color="primary">
            <ArrowUpIcon />
          </Fab>
        )}
      </Zoom>
    </>
  );
};

export default BackTop;
