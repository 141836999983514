import React, {useState, useRef, useEffect} from 'react';
import moment from 'moment';
import {debounce} from 'lodash';
import {
  Grid,
  FormControl,
  InputAdornment,
  Input,
  makeStyles,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  ModeComment as ModeCommentIcon,
  CheckCircle as CheckCircleIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';

import * as api from 'src/services/Vision/event';
import message from 'src/components/Vision/Message';
import {useDetailContext} from '../../hook';

const useStyles = makeStyles(theme => ({
  comment: {
    marginTop: theme.spacing(3),
  },
  commentInput: {
    width: '100%',
    height: 45,
  },
  commentStartIcon: {
    color: (theme.palette).other.secondary,
  },
  commentEndIcon: {
    color: theme.palette.primary.main,
  },
}));

export function CommentComponent() {
  const classes = useStyles();
  const {detail, refresh, isSharing} = useDetailContext();
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();
  //before icon button click event fire(like clear function in this component)
  //the input element's blur event would trigger early
  //ui will change immediately,and click event will not fire
  //use throttle to delay ui refresh
  const changeActiveState = debounce(setIsActive, 200);
  const updateComment = async (note) => {
    try {
      await api.addNote({id: detail.id, action: 'add_note', note});
      refresh && refresh();
      message.success('Your comment has been successfully added to this event');
    } catch (e) {
      message.error('Error,Please try again');
    }
  };

  useEffect(() => {
    if (ref.current && !isActive && ref.current.value !== (detail.note || '')) {
      updateComment(ref.current.value);
    }
  });

  const keydown = (event) => {
    if (event.key === 'Enter') {
      if (ref.current !== null){
        ref.current.blur();
      }
    }
  };

  const clear = () => {
    if (ref.current) {
      ref.current.value = '';
      ref.current.focus();
    }
  };

  return (
    <Grid container classes={{container: classes.comment}}>
      <FormControl variant="standard" className={classes.commentInput}>
        <Input
          onFocus={() => changeActiveState(true)}
          defaultValue={detail.note}
          placeholder="Add a comment"
          inputRef={ref}
          disabled={isSharing}
          startAdornment={
            <InputAdornment position="start">
              <ModeCommentIcon className={classes.commentStartIcon} />
            </InputAdornment>
          }
          endAdornment={
            isActive && (
              <InputAdornment position="end">
                <IconButton size="small" onClick={clear}>
                  <ClearIcon color="disabled" />
                </IconButton>
                <IconButton size="small" onClick={() => ref.current ? ref.current.blur() : undefined}>
                  <CheckCircleIcon className={classes.commentEndIcon} />
                </IconButton>
              </InputAdornment>
            )
          }
          onBlur={() => changeActiveState(false)}
          onKeyDown={keydown}
        />
      </FormControl>
      {!isActive && detail.author && detail.noteTime && (
        <Typography color="textSecondary" variant="body2">{`Last updated by ${
          detail.author
        } on ${moment(detail.noteTime).format('MMM DD, YYYY [at] hh:mm a')}`}</Typography>
      )}
    </Grid>
  );
}
