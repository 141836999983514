import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PrevPageIcon from '@material-ui/icons/NavigateBefore';
import NextPageIcon from '@material-ui/icons/NavigateNext';

const styles = theme => ({
  IconButton: {
    paddingLeft: 0,
    paddingRight: 0
  }
})

const Pagination = props => {
    const { classes, handlePrevPageClick, handleNextPageClick, noPrev, noNext } = props
    return (
      <React.Fragment>
        <IconButton
          className={classes.IconButton}
          onClick={handlePrevPageClick}
          disabled={noPrev}
          >
          <PrevPageIcon />
        </IconButton>
        <IconButton
          className={classes.IconButton}
          onClick={handleNextPageClick}
          disabled={noNext}
          >
          <NextPageIcon />
        </IconButton>
      </React.Fragment>
    );
}

Pagination.propTypes = {
  handlePrevPageClick: PropTypes.func.isRequired,
  handleNextPageClick: PropTypes.func.isRequired,
  noPrev: PropTypes.bool.isRequired,
  noNext: PropTypes.bool.isRequired,
};

Pagination.defaultProps = {
  handlePrevPageClick: () => {},
  handleNextPageClick: () => {},
  noPrev: true,
  noNext: true,
};

export default withStyles(styles)(Pagination);
