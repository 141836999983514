import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  tableGrid: {
    height: '100%',
    overflow: 'auto'
  },
  table: {
    width: '60%'
  },
  header: {
    fontSize: '1rem'
  },
  row: {
    fontSize: '1rem'
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)'
  },
  iconButton: {
    marginRight: '0.5rem',
    '&:hover': {
      cursor: 'pointer',
    }
  },
});

const tableHeaders = [
  'id',
  'title',
  ' '
];

const CompanyList = props => {
  const {
    classes,
    data,
    editId,
    editValue,
    onClickAdd,
    selectedType,
    onClickDelete,
    onClickEdit,
    handleChange,
    onClickSubmit,
  } = props;

  return (
    <Grid
      container={true}
      justify="flex-start"
      direction="row"
      alignItems="stretch"
      spacing={0}
    >
      <Grid item={true} xs={12}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onClickAdd(selectedType)}
        >
          {getText('add_company')}
        </Button>
      </Grid>
      <Grid item={true} xs={12} className={classes.tableGrid}>
        <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header, index) => (<TableCell key={index} className={classes.header}>{getText(header)}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data ? data.map((company, index) => {
            const editing = company.id === editId;
            return (
              <TableRow key={company.id}>
                <TableCell className={classes.row}>{company.id}</TableCell>
                <TableCell className={classes.row}>{editing ?
                  <Input
                    id="title"
                    name="title"
                    autoFocus={true}
                    value={editValue.title}
                    onChange={handleChange('title')}
                  />
                    : company.title}</TableCell>
                <TableCell className={classes.row}>
                  {editing ?
                    <CheckIcon className={classes.iconButton} onClick={onClickSubmit(selectedType)} />
                    : <EditIcon className={classes.iconButton} onClick={onClickEdit(company.id)} />
                  }
                  <DeleteIcon className={classes.iconButton} onClick={onClickDelete(selectedType, company.id)} />
                </TableCell>
              </TableRow>
          )}) : null}
        </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CompanyList);
