import {useCallback, useState, useRef, useEffect} from 'react';
import uuid from 'uuid';

import message from 'src/components/Vision/Message';

export function useDownloadVideo(){
  const [isDownloading, setIsDownloading] = useState(false);
  const unmount = useRef(false);

  const download = useCallback((url) => {
    setIsDownloading(true);
    fetch(url, {headers: {Pragma: 'no-cache'}})
      .then(async response => {
        const blob = await response.blob();
        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('download', uuid() + '.mp4');
        link.setAttribute('href', downloadUrl);
        const event = new MouseEvent('click');
        link.dispatchEvent(event);
        if (unmount.current) return;
        setIsDownloading(false);
      })
      .catch(() => {
        message.error('Download failed');
      });
  }, []);

  useEffect(
    () => () => {
      unmount.current = true;
    },
    []
  );

  return {
    isDownloading,
    download,
  };
}
