import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

// import AlertComponent from 'src/components/Alert';
import BackTop from 'src/components/Vision/BackTop';
import CommonBody from 'src/components/Vision/CommonBody';
// import {RootState} from 'src/redux/reducers';
import {getSiteOptions} from 'src/redux/actions/Vision/site';
import {getCameraOptions} from 'src/redux/actions/Vision/camera';
import {getTrackingSettings} from 'src/redux/actions/Vision/trackingSetting';
// import {isFacialRecognition} from 'src/redux/actions/eventType';
import {getLicensePlateBlacklist} from 'src/redux/actions/Vision/licensePlateBlacklist';
import Header from './Header/index';
import Filter from './Filter/index';
import List from './List/index';
import Detail from './detail';
import {getOrgs, manualSetOrg} from 'src/redux/actions/Vision/orgvision';
import store from 'src/redux/store';

const {dispatch, getState} = store;

const Events = () => {
  const orgSettings = useSelector((state) => state.org.org);
  // const {jwtRole} = useSelector((state) => state.account);
  const {filter} = useSelector((state) => state.clip);
  // const {isAlert} = useSelector((state) => state.global);
  const [type, setType] = useState(filter.type || '');
  const [showInfo, setShowInfo] = useState(false);

  // const history = useHistory();
  // const isSiteManager = jwtRole === 'site_manager';

  useEffect(() => {
    getTrackingSettings().then(()=>{
      manualSetOrg();
      if (getState().user.role !== 'monitoring_operator'){
        getSiteOptions().then(sites => {
          getCameraOptions(sites.map(v => v.id)).then(() => {
            manualSetOrg();
          });
        });
      }else{
        getOrgs();
      }
      getLicensePlateBlacklist({}, true);
    })
  }, []);

  useEffect(() => {
    setShowInfo(!(orgSettings ? orgSettings.enable_tracking : undefined));
  }, [orgSettings]);

  // const infoActions = [
  //   {buttonText: 'View Settings', handler: () => history.push('settings/analytics')},
  // ];

  const pagePath = window.location.pathname;
  const paths = pagePath.split("/");
  let id = null;
  if (paths.length === 3){
    if (paths[2].length > 0){
      id = paths[2]
    }
  }

  return (
    (id && id !== 'people' && id !== 'vehicle' && id !== 'motorcycle') ?
        <Detail detailId={id}>{id}</Detail> :
    <>
      <div
        style={{
          position: 'sticky',
          zIndex: 1001,
          top: 0,
          // flex: 1,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#FFF',
        }}
      >
        <Header type={type} setType={setType} />
      </div>
      <CommonBody>
        <Filter type={type} />
        <List type={type} />
      </CommonBody>
      <BackTop />
    </>
  );
};
export default Events;
