import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import DropDownSelectorVertical from 'src/components/Selectors/DropDownSelectorVertical';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({});

const types = [
  { value: 'all', label: getText('all') },
  { value: 'ROBOT_DOCKING_FAILED', label: 'ROBOT_DOCKING_FAILED' },
  { value: 'ROBOT_LEAVING_FAILED', label: 'ROBOT_LEAVING_FAILED' },
  { value: 'ROBOT_POWER_LOW', label: 'ROBOT_POWER_LOW' },
  { value: 'ROBOT_STEPPED_ON', label: 'ROBOT_STEPPED_ON' },
  { value: 'ROBOT_LIFTED_UP', label: 'ROBOT_LIFTED_UP' },
  { value: 'ROBOT_LOCATION_JUMPED', label: 'ROBOT_LOCATION_JUMPED' },
  { value: 'ROBOT_LOW_LOCALIZATION_SCORE', label: 'ROBOT_LOW_LOCALIZATION_SCORE' },
  { value: 'ROBOT_SLAM_LIDAR_TIMEOUT', label: 'ROBOT_SLAM_LIDAR_TIMEOUT' },
  { value: 'ROBOT_WHEEL_STUCK', label: 'ROBOT_WHEEL_STUCK' },
  { value: 'ROBOT_CHARGING_FAILED', label: 'ROBOT_CHARGING_FAILED' },
  { value: 'ROBOT_PATH_BLOCKED', label: 'ROBOT_PATH_BLOCKED' },
  { value: 'NOTIFY_POINT_REACHED', label: 'NOTIFY_POINT_REACHED' },
];

const incidentTypeSelector = props => {
  const { value, onChange } = props;
  return (
    <DropDownSelectorVertical
      title={getText('incident_type')}
      options={types}
      value={value}
      onChange={onChange} />
  );
};

incidentTypeSelector.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

incidentTypeSelector.defaultProps = {
  value: 'all',
  onChange: (event, value) => {}
};

export default withStyles(styles)(incidentTypeSelector);
