import React from 'react';
import { connect } from 'react-redux';

import { targetActions, notificationActions } from 'src/redux/actions';
import InspectionConfigs from './InspectionConfigs';
import { targetServices } from 'src/services';
import {
  checkValueChange,
  checkConfigs,
} from 'src/pages/Main/Targets/utils';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  const { algoOptions, maps } = state.target;
  return {
    algoOptions,
    maps,
    siteId: state.site.curSite
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAlgoOptions: () => {
      dispatch(targetActions.getAlgoOptions());
    },
    addNotification: (msg) => {
      dispatch(notificationActions.addNotification(msg));
    }
  };
};

const modeOptions = [
  { value: true, label: 'on' },
  { value: false, label: 'off' },
];

const actionOptions = [
  { value: 'snapshot', label: 'snapshot' },
  { value: 'video', label: 'video' },
  { value: 'notify', label: 'notify' },
  { value: 'stop', label: 'stop' },
];

const defaultAlgo = {
  algo: '',
  action: 'snapshot',
  mode: true,
  schedule: [],
  rules: '{}',
  thresholds: undefined,
  tab: 0,
};

class StoreDialogContainer extends React.Component {
  constructor(props) {
    super(props);
    // const { algoConfigs } = props;
    this.state = {
      algoConfigs: [],
      validate: [],
    };
  }

  componentDidMount() {
    const { siteId, algoOptions, getAlgoOptions } = this.props;
    if (siteId && !algoOptions[siteId]) {
      getAlgoOptions();
    }
    targetServices.getAlgoConfigs(this.props.target.id).then((result) => {
      const { list, error } = result;
      if (!error) {
        this.setState({
          validate: Array(list.length).fill({}),
          algoConfigs: list
        });
        if (list.length === 0) {
          this.handleAdd();
        }
      }
    });
  }

  handleChange = (key, index) => event => {
    const value = typeof event === 'string' ? event : (key === 'mode' ? event.target.checked : event.target.value);
    const { algoConfigs, validate } = this.state;
    const validateResult = checkValueChange(key, value);
    this.setState({
      algoConfigs: algoConfigs.map((item, i) => i === index ? {
        ...item,
        [key]: value,
      } : item),
      validate: validate.map((item, i) => i === index ? {
        ...item,
        [key]: validateResult
      } : item)
    });
  }

  isInvalid = (index) => {
    const { algoConfigs } = this.state;
    const algoConfig = algoConfigs[index];
    const { errorCount, validateResult } = checkConfigs(algoConfig);
    if (errorCount !== 0) {
      const { validate } = this.state;
      this.setState({
        validate: validate.map((item, i) => i === index ? validateResult : item)
      });
      return true;
    }
    return false;
  }

  handleSave = (index, algoConfigId) => async () => {
    const { algoConfigs } = this.state;
    const algoConfig = algoConfigs[index];
    const { errorCount, validateResult } = checkConfigs(algoConfig);
    if (errorCount !== 0) {
      const { validate } = this.state;
      this.setState({
        validate: validate.map((item, i) => i === index ? validateResult : item)
      });
    } else {
      if (algoConfigId) {
        const { error } = await targetServices.updateAlgoConfig(algoConfigId, algoConfigs[index]);
        if (!error) {
          this.props.addNotification(getText('operate_success'));
        }
      } else {
        const { algoOptions, target, siteId } = this.props;
        const algo = algoConfigs[index].algo;
        const algoItem = (algoOptions[siteId] || []).find(item => item.value === algo);
        let algoId = undefined;
        if (algoItem) {
          algoId = algoItem.id;
        }
        const { error, result } = await targetServices.addAlgoConfig(target.id, algoId, algoConfigs[index]);
        if (!error) {
          this.setState({
            algoConfigs: algoConfigs.map((item, i) => i === index ? {
              ...item,
              targetId: target.id,
              algoId: algoId,
              id: result.id
            } : item)
          });
          this.props.addNotification(getText('operate_success'));
        }
      }
    }
  }

  handleDelete = (index, algoId) => async () => {
    const { algoConfigs, validate } = this.state;
    if (!algoId) {
      this.setState({
        algoConfigs: algoConfigs.filter((item, i) => i !== index),
        validate: validate.filter((item, i) => i !== index)
      });
    } else {
      const { error } = await targetServices.deleteAlgoConfig(algoId);
      if (!error) {
        this.setState({
          algoConfigs: algoConfigs.filter((item, i) => i !== index),
          validate: validate.filter((item, i) => i !== index)
        });
        this.props.addNotification(getText('operate_success'));
      }
    }
  }

  handleAdd = () => {
    const { algoConfigs, validate } = this.state;
    const { algoOptions, siteId, target } = this.props;
    let algo = '';
    if (algoOptions[siteId] && algoOptions[siteId].length > 0) {
      const algoItem = algoOptions[siteId].find(v => v.label.includes(target.type));
      algo = algoItem && algoItem.value || algoOptions[siteId][0].value;
    }
    this.setState({
      algoConfigs: [
        ...algoConfigs,
        {
          ...defaultAlgo,
          algo,
        },
      ],
      validate: [
        ...validate,
        {},
      ]
    });
  }

  hanldeClose = () => {
    this.setState({
      algoConfigs: [],
      validate: []
    });
    this.props.onClose();
  }

  handleSwitchTab = (itemIndex) => (e, value) => {
    const { algoConfigs } = this.state;
    this.setState({
      algoConfigs: algoConfigs.map((item, i) => i === itemIndex ? {
        ...item,
        tab: value,
      } : item)
    });
  }

  render() {
    const { algoOptions, open, siteId, target, maps } = this.props;
    const { algoConfigs, validate } = this.state;

    return (
      <InspectionConfigs
        modeOptions={modeOptions}
        algoOptions={algoOptions[siteId] || []}
        actionOptions={actionOptions}
        algoConfigs={algoConfigs}
        validate={validate}
        target={target}
        open={open}
        maps={maps}
        onClose={this.hanldeClose}
        handleChange={this.handleChange}
        handleAdd={this.handleAdd}
        handleSave={this.handleSave}
        handleDelete={this.handleDelete}
        isInvalid={this.isInvalid}
        handleSwitchTab={this.handleSwitchTab}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreDialogContainer);