import React from 'react';
import { withStyles, Card, CardMedia, Grid } from '@material-ui/core';
import ReactCursorPosition from 'react-cursor-position';
import { MapInteractionCSS } from 'react-map-interaction';

import { getText } from 'src/utils/MultilingualLoader';
import RobotMapStage from '../RobotMap/RobotMapStage';
import { getImagePosition, getMapPosition } from '../RobotMap';

const styles = theme => ({
  map: {
    flex: 1,
    width: '100%',
  }
});

class RobotMap extends React.Component {
    state = {
      image: null,
      imageSize: null,
      scale: 1,
      translation: { x: 0, y: 0 },
      relocalizationPoint: [],
    };

  componentDidMount() {
    const { map } = this.props;
    if (map && map.mapUrl) {
      this.loadImage(map.mapUrl);
    }
  }

  loadImage(mapSrc) {
    const { setMapImageReady } = this.props;
    const image = new window.Image();
    this.setState({
      image: null,
      imageSize: null
    });
    image.onload = () => {
      this.setState({
        image,
        imageSize: {
          width: image.width,
          height: image.height
        },
      });
      setMapImageReady && setMapImageReady(true);
    };
    image.src = mapSrc;
  };

  onMapClick = (mapConfig, imageSize, displaySize) => position => {
    const { onRelocalize } = this.props;
    const { relocalizationPoint } = this.state;
    const getClickMapPosition = getMapPosition(mapConfig, imageSize, displaySize);
    if (relocalizationPoint.length === 0) {
      const headPosition = getClickMapPosition(position.x, position.y);
      this.setState({ relocalizationPoint: [headPosition.x, headPosition.y] });
    }
    else {
      const headPosition = {x: relocalizationPoint[0], y: relocalizationPoint[1]};
      const endPosition = getClickMapPosition(position.x, position.y);
      if (headPosition.x === endPosition.x && headPosition.y === endPosition.y) {
        alert(getText('input_error_relocalization'));
      }
      else {
        const theta = this.getAngle(headPosition, endPosition);
        if (window.confirm(`Relocalize robot at ${headPosition.x}, ${headPosition.y} with angle ${theta}`)) {
          onRelocalize(headPosition.x, headPosition.y, theta);
        }
      }
      this.setState({ relocalizationPoint: [] });
    }
    return;
  }

  getAngle = (p1, p2) => {
    const diffX = p2.x - p1.x;
    const diffY = p2.y - p1.y;
    if (diffX === 0) return diffY > 0 ? Math.PI / 2 : - Math.PI / 2;
    if (diffX > 0) {
      return Math.atan(diffY / diffX);
    }
    else if (diffY > 0) {
      return Math.PI - Math.atan(- diffY / diffX);
    }
    else {
      return Math.atan(diffY / diffX) - Math.PI;
    }
  }

  render() {
    const { classes, maxMapWidth, maxMapHeight, map, routeId, curRelocalizationPosition } = this.props;
    const { image, imageSize, scale, translation, relocalizationPoint } = this.state;
    const configRoute = map && map.mapRoutes && map.mapRoutes.find(route => route.id === routeId);
    const elevatorBase = map && map.elevatorBase;

    // map size with original radio
    let mapWidthWithRadio = null;
    let mapHeightWithRadio = null;
    if (imageSize && maxMapWidth && maxMapHeight) {
      const radio = imageSize.height !== 0 ? imageSize.width / imageSize.height : 0;
      mapWidthWithRadio = maxMapWidth;
      mapHeightWithRadio = radio !== 0 ? mapWidthWithRadio / radio : 0;
      if (mapHeightWithRadio > maxMapHeight) {
        mapHeightWithRadio = maxMapHeight;
        mapWidthWithRadio = mapHeightWithRadio * radio;
      }
    }

    return (
      <Card>
        <Grid
          direction="column"
          container={true}
          justify="flex-start"
          alignItems="center"
          spacing={0}
        >
          <Grid item>
            {image &&
              <CardMedia
                className={classes.map}
                src="img" >
                <MapInteractionCSS
                  scale={scale}
                  translation={translation}
                  onChange={({ scale, translation }) => {
                    this.setState({
                      scale,
                      translation: scale === 1 ? { x: 0, y: 0 } : translation
                    })
                  }
                  }
                  minScale={1}
                  maxScale={10}
                >
                  <ReactCursorPosition
                    // force posotion to update when scale changes
                    key={scale}
                  >
                    <RobotMapStage
                      mapWidth={mapWidthWithRadio}
                      mapHeight={mapHeightWithRadio}
                      image={image}
                      scale={scale}
                      action={'relocalize'}
                      configRoute={configRoute}
                      elevatorBase={elevatorBase}
                      relocalizationPoint={relocalizationPoint}
                      curRelocalizationPosition={curRelocalizationPosition}
                      getImagePosition={getImagePosition(map && map.mapConfig, imageSize, {
                        width: mapWidthWithRadio,
                        height: mapHeightWithRadio
                      })}
                      onMapClick={this.onMapClick(map && map.mapConfig, imageSize, {
                        width: mapWidthWithRadio,
                        height: mapHeightWithRadio
                      })}
                    />
                  </ReactCursorPosition>
                </MapInteractionCSS>
              </CardMedia>
            }
            </Grid>
        </Grid>
      </Card>
    );
  }
}

export default withStyles(styles)(RobotMap);