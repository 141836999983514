import React from 'react';

import { connect } from 'react-redux';
import { robotActions } from 'src/redux/actions';
import RobotFilter from './RobotFilter';


const mapStateToProps = state => {
  const { filters, loading } = state.robot;
  const { role } = state.user;
  return { filters, loading, role };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilter: filters => {
      dispatch(robotActions.setFilter(filters));
    },
    getRobots: filters => {
      dispatch(robotActions.getRobots(filters));
    },
    unselectRobot: () => dispatch(robotActions.selectRobot(null)),
  };
};

class RobotFilterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      robotStatuses: 'all'
    };
  }

  handleDialogOpen = () => {
    this.setState({
      openDialog: true,
      robotStatuses: this.props.filters.robotStatuses,
    });
  };

  handleDialogClose = event => {
    this.setState({ openDialog: false });
  };

  handleRobotStatusChange = (e) => {
    this.setState({ robotStatuses: e.target.value});
  };

  handleFilterSave = event => {
    const { openDialog, ...filters } = this.state;
    const { loading, setFilter, getRobots, unselectRobot } = this.props;
    setFilter(filters);
    if (!loading) {
      getRobots(filters);
      unselectRobot();
    }
    this.setState({ openDialog: false });
  };

  render() {
    return (
        <RobotFilter
            robotStatuses={this.state.robotStatuses}
            role={this.props.role}
            openDialog={this.state.openDialog}
            onOpenDialog={this.handleDialogOpen}
            onCloseDialog={this.handleDialogClose}
            onSaveFilter={this.handleFilterSave}
            onChangeRobotStatus={this.handleRobotStatusChange}
        />
    );
  }
}

RobotFilterContainer.propTypes = {
};

RobotFilterContainer.defaultProps = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RobotFilterContainer);
