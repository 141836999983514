import { GeneralAdapter } from './adapter';
import { service } from './monitoring';

// Get all guards
const getGuardsAdapter = {
  toServer: data => {
    return data;
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = result ? result : [];

    result = result.map(guard => {
      return {
        id: guard.user.id,
        first_name: guard.user.first_name,
        last_name: guard.user.last_name,
        username: guard.user.username,
        email: guard.user.email,
        role: guard.user.role,
        phone_number: guard.phone_number,
        phone_number_verified: guard.phone_number_verified,
      };
    });

    return { error, result };
  }
};

export const getGuards = (adapter => async (data = {}) => {
  try {
    const url = '/guard/';
    data = adapter.toServer(data);
    let response = await service.get(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(getGuardsAdapter);
