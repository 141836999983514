import {SET_EVENT_TYPES} from 'src/redux/types/Vision/eventType';

const initialState = {
  eventTypes: [],
};

export default function eventTypeReducer(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case SET_EVENT_TYPES:
      return {
        ...state,
        eventTypes: payload.eventTypes,
      };
    default:
      return state;
  }
}
