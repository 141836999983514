export const CHANGE_TYPE = 'MANAGE_CHANGE_TYPE';

export const GET_LIST_REQUEST = 'MANAGE_GET_LIST_REQUEST';
export const GET_LIST_SUCCESS = 'MANAGE_GET_LIST_SUCCESS';
export const GET_LIST_FAILURE = 'MANAGE_GET_LIST_FAILURE';
export const GET_MORE_ACCOUNTS_REQUEST = 'MANAGE_GET_MORE_ACCOUNTS_REQUEST';
export const GET_MORE_ACCOUNTS_SUCCESS = 'MANAGE_GET_MORE_ACCOUNTS_SUCCESS';
export const UPDATE_ACCOUNT_REQUEST = 'MANAGE_UPDATE_ACCOUNT_REQUEST';
export const UPDATE_ACCOUNT_SUCCESS = 'MANAGE_UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILURE = 'MANAGE_UPDATE_ACCOUNT_FAILURE';

export const COMPANY = 'MANAGE_COMPANY';
export const GUARD_ADMIN = 'MANAGE_GUARD_ADMIN';
export const OPERATOR = 'MANAGE_OPERATOR';
export const GUARD = 'MANAGE_GUARD';
export const CUSTOMER = 'MANAGE_CUSTOMER';

export const SET_PAGE = 'MANAGE_SET_PAGE'
