import React from "react";
import {
  Grid,
  withStyles,
  Switch,
  FormControlLabel,
  OutlinedInput,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import { Delete as DeleteIcon, AddCircle as AddIcon } from "@material-ui/icons";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { getText, getDateLocale } from "src/utils/MultilingualLoader";

moment.locale(getDateLocale());
const weekdays = moment.weekdays();

const styles = (theme) => ({
  label: {
    width: 80,
    marginRight: theme.spacing(1),
  },
  selectorInput: {
    width: "140px",
    padding: "5px 20px 5px 20px",
  },
  timeInput: {
    width: "80px",
    padding: "5px 20px 5px 20px",
  },
  IconButton: {
    padding: 5,
    color: theme.palette.primary.main,
    verticalAlign: "middle",
  },
});

const RoutineSchedules = ({ classes, scheduleData, handleUpdate }) => {
  const { weeklyRepeat, daysOfWeek, startDate, startTimes } = scheduleData;

  function setStartTimes(value) {
    handleUpdate({
      ...scheduleData,
      startTimes: value,
    });
  }

  const handleChangeTime = (type, index) => (time) => {
    if (type === "add") {
      setStartTimes(startTimes.concat([null]));
    } else if (type === "delete") {
      const newTimes = startTimes.filter((_, i) => index !== i);
      setStartTimes(newTimes.length === 0 ? [null] : newTimes);
    } else if (type === "update") {
      startTimes[index] = time;
      setStartTimes(startTimes.map((_, i) => (index === i ? time : _)));
    }
  };

  return (
    <>
      <Grid container item>
        <Grid className={classes.label}>
          {getText("routine_schedule_repeat_weekly")}
        </Grid>
        <Grid>
          <FormControlLabel
            control={
              <Switch
                size="small"
                color="primary"
                checked={weeklyRepeat}
                onChange={(e) =>
                  handleUpdate({
                    ...scheduleData,
                    weeklyRepeat: e.target.checked,
                  })
                }
              />
            }
            label=""
          />
        </Grid>
      </Grid>
      <Grid container item>
        <Grid className={classes.label}>{getText("date")}</Grid>
        <Grid>
          {weeklyRepeat ? (
            <Select
              variant="outlined"
              multiple
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{ input: classes.selectorInput }}
                />
              }
              value={daysOfWeek}
              onChange={(e) => {
                handleUpdate({ ...scheduleData, daysOfWeek: e.target.value });
              }}
            >
              {weekdays.map((day, index) => (
                <MenuItem
                  key={day}
                  value={index}
                  classes={{ selected: classes.selected }}
                >
                  {getText(day)}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale={getDateLocale()}
            >
              <DatePicker
                value={startDate}
                onChange={(v) => {
                  handleUpdate({ ...scheduleData, startDate: v });
                }}
                showTodayButton
                format="yyyy/MM/DD"
                cancelLabel={getText("cancel")}
                okLabel={getText("ok")}
                todayLabel={getText("today")}
                TextFieldComponent={(props) => (
                  <OutlinedInput
                    classes={{ input: classes.selectorInput }}
                    {...props}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          )}
        </Grid>
        <Grid container item style={{ marginTop: 8 }}>
          <Grid className={classes.label} style={{ marginTop: 8 }}>
            {getText("time")}
          </Grid>
          <Grid>
            {startTimes.map((time, index) => (
              <div key={index}>
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  locale={getDateLocale()}
                >
                  <TimePicker
                    value={time}
                    onChange={handleChangeTime("update", index)}
                    cancelLabel={getText("cancel")}
                    okLabel={getText("ok")}
                    format="HH:mm"
                    TextFieldComponent={(props) => (
                      <OutlinedInput
                        classes={{ input: classes.timeInput }}
                        {...props}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
                {index < startTimes.length - 1 ? (
                  <IconButton
                    className={classes.IconButton}
                    onClick={handleChangeTime("delete", index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  <>
                    {index > 0 && (
                      <IconButton
                        className={classes.IconButton}
                        onClick={handleChangeTime("delete", index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}

                    <IconButton
                      className={classes.IconButton}
                      onClick={handleChangeTime("add")}
                    >
                      <AddIcon />
                    </IconButton>
                  </>
                )}
              </div>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(RoutineSchedules);
