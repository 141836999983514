import React from "react";

import { Grid, withStyles, Tabs, Tab } from "@material-ui/core";

const styles = (theme) => ({
  tabs: {
    borderBottom: "1px solid #bbb",
    marginTop: -theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

class TabPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabId: 0,
    };
  }

  handleSwitchTab = (e, tabId) => {
    this.setState({ tabId });
  };

  parseTabList(children) {
    return React.Children.toArray(children)
      .map((node) => (React.isValidElement(node) ? node.props.title : null))
      .filter((el) => Boolean(el));
  }

  render() {
    const { classes, children } = this.props;
    const { tabId } = this.state;

    const tabs = this.parseTabList(children);

    return (
      <Grid style={{ width: "100%" }}>
        <Tabs
          value={tabId}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          variant="scrollable"
          onChange={this.handleSwitchTab}
          className={classes.tabs}
        >
          {tabs.map((item, index) => (
            <Tab label={item} key={index}></Tab>
          ))}
        </Tabs>
        <Grid container>
          {children.map((item, index) => (
            <Grid
              key={index}
              container
              style={{ display: index === tabId ? "flex" : "none" }}
            >
              {item}
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TabPage);
