import React, {useState, useMemo, useEffect} from "react"
import {connect} from "react-redux"
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  withStyles
} from "@material-ui/core"

import DropDownSelectorHorizontal from "src/components/Selectors/DropDownSelectorHorizontal"
import Modal from "src/components/Modal"
import {getText} from "src/utils/MultilingualLoader"
import {messageActions} from "src/redux/actions"

const styles = (theme) => ({
  tableCell: {
    borderStyle: "none",
    padding: "10px 16px"
  }
})

const mapStateToProps = (state, props) => {
  const {robotMapLists} = state.robot
  const {selected} = props
  const robotMapList = selected && robotMapLists[selected.id]
  return {robotMapList}
}

const mapDispatchToProps = (dispatch) => ({
  showNotification: (msg, type = "success") =>
    dispatch(messageActions.showNotification(msg, type))
})

const MapRouteModal = ({
                         robotMapList,
                         action,
                         onCancel,
                         onConfirm,
                         classes,
                         showNotification
                       }) => {
  const [mapId, setMapId] = useState("")
  const [routeId, setRouteId] = useState("")
  
  useEffect(() => {
    setMapId(action.arg.map_id)
    setRouteId(action.arg.route_id)
  }, [])
  
  const {maps, mapsMapper} = useMemo(() => {
    if (!robotMapList || robotMapList.loading) {
      return {
        maps: [],
        mapsMapper: {}
      }
    }
    
    const ms = robotMapList.maps.map((map) => ({
      label: `${map.name} ${map.id}`,
      value: map.id,
      map
    }))
    return {
      maps: ms,
      mapsMapper: ms.reduce((result, map) => ({
        ...result,
        [map.value]: map
      }), {})
    }
  }, [robotMapList && robotMapList.maps])
  
  const {routes, routesMapper} = useMemo(() => {
    if (!mapId || !robotMapList || robotMapList.loading) {
      return {
        routes: [],
        routesMapper: {}
      }
    }
    const curMap = robotMapList.maps.find((item) => item.id === mapId)
    const rs = (curMap ? curMap.mapRoutes : []).map((route) => ({
      label: `${route.name} ${route.id}`,
      value: route.id,
      route
    }))
    
    return {
      routes: rs,
      routesMapper: rs.reduce((result, route) => ({
        ...result,
        [route.value]: route
      }), {})
    }
  }, [robotMapList, mapId])
  
  
  function handleSubmit() {
    if (!mapId) {
      showNotification(getText("input_error_map_id"), "warning")
      return
    }
    if (!routeId) {
      showNotification(getText("input_error_route_id"), "warning")
      return
    }
    onConfirm({
      act: action.act, mapId, routeId, id: action.id,
      map: mapsMapper[mapId] && mapsMapper[mapId].map,
      route: routesMapper[routeId] && routesMapper[routeId].route
    })
  }
  
  return (
    <Modal
      title={getText("map_actions")}
      onCancel={onCancel}
      onConfirm={handleSubmit}
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableCell} padding="default">
              <Typography variant="body2">{getText("map_id")}</Typography>
            </TableCell>
            <TableCell className={classes.tableCell} padding="default">
              <DropDownSelectorHorizontal
                title={""}
                options={maps}
                value={mapId}
                onChange={(e) => {
                  if (e.target.value !== mapId) {
                    setMapId(e.target.value)
                    setRouteId(null)
                  }
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell} padding="default">
              <Typography variant="body2">{getText("route_id")}</Typography>
            </TableCell>
            <TableCell className={classes.tableCell} padding="default">
              <DropDownSelectorHorizontal
                title={""}
                options={routes}
                value={routeId}
                onChange={(e) => setRouteId(e.target.value)}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Modal>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MapRouteModal))
