import * as _userServices from './user';
import * as _caseServices from './case';
import * as _guardServices from './guard';
import * as _cameraServices from './camera';
import * as _robotServices from './robot';
import * as _manageServices from './manage';
import * as _siteServices from './site';
import * as _inspectionServices from './inspection';
import * as _sensorServices from './sensor';
import * as _targetServices from './target';
import * as _taskServices from './task';
import * as _referServices from './reference';
import * as _routineHistoryService from './routineHistory';

export const userServices = _userServices;
export const caseServices = _caseServices;
export const guardServices = _guardServices;
export const cameraServices = _cameraServices;
export const robotServices = _robotServices;
export const manageServices = _manageServices;
export const siteServices = _siteServices;
export const inspectionServices = _inspectionServices;
export const sensorServices = _sensorServices;
export const targetServices = _targetServices;
export const taskServices = _taskServices;
export const referenceServices = _referServices;
export const routineHistoryService = _routineHistoryService

