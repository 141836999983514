import React from 'react';
import { connect } from 'react-redux';

import { routineHistoryActions } from 'src/redux/actions';
import RoutineHistoryTimePagination from './RoutineHistoryTimePagination';

 const mapStateToProps = state => {
  const { filters,robotRoutineHistoryPage} = state.routineHistory;
     return {filters,robotRoutineHistoryPage}
  };
  
  const mapDispatchToProps = dispatch => {
      return {
        setRoutineHistoryFilter: filters => {
          dispatch(routineHistoryActions.setRoutineHistoryFilter(filters));
        },
        getRoutineHistoryPage: (filters) => {
          dispatch(routineHistoryActions.getRoutineHistoryPage(filters));
        },
      };
  };
  

class RoutineHistoryTimePaginationContainer extends React.Component {
  constructor(props) {
    super(props);
    const { filters } = props;
    const { direction,firstKey,lastKey,limit } = filters;
    this.state = {
      direction,
      firstKey,
      lastKey,
      limit
    };
  }
  handleChange = event => {
    const limit = event.target.value;
    this.setState({limit})
    const { filters,setRoutineHistoryFilter,getRoutineHistoryPage } = this.props;
    const routineHistoryFilters = Object.assign({}, filters, {...this.state, limit: limit});
    setRoutineHistoryFilter(routineHistoryFilters);
    getRoutineHistoryPage(routineHistoryFilters);
  }
  handlePrevPageClick = () => {
    const { filters,setRoutineHistoryFilter,robotRoutineHistoryPage,getRoutineHistoryPage } = this.props;
    const routineHistoryFilters = Object.assign({}, filters,
      {...this.state, firstKey:robotRoutineHistoryPage.first_key, lastKey: robotRoutineHistoryPage.last_key,direction:'back'});
    setRoutineHistoryFilter(routineHistoryFilters);
    getRoutineHistoryPage(routineHistoryFilters)
  }
  handleNextPageClick = () => {
    const { filters,setRoutineHistoryFilter,robotRoutineHistoryPage,getRoutineHistoryPage } = this.props;
    const routineHistoryFilters = Object.assign({}, filters,
       {...this.state, firstKey:robotRoutineHistoryPage.first_key, lastKey: robotRoutineHistoryPage.last_key,direction:'next'});
    setRoutineHistoryFilter(routineHistoryFilters);
    getRoutineHistoryPage(routineHistoryFilters)
  }
  
  render() {
    const {limit} = this.state
    const {robotRoutineHistoryPage} = this.props
    let noPrev = true;
    let noNext = true;
    if(robotRoutineHistoryPage){
       noPrev = !robotRoutineHistoryPage.has_back
       noNext = !robotRoutineHistoryPage.has_next
    }
    return (
      <>
        <RoutineHistoryTimePagination 
          limit = {limit}
          noPrev = {noPrev}
          noNext = {noNext}
          handlePrevPageClick = {this.handlePrevPageClick}
          handleNextPageClick = {this.handleNextPageClick}
          handleChange = {this.handleChange}/>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutineHistoryTimePaginationContainer);