import { caseConstants } from 'src/redux/constants';
import { caseServices, cameraServices } from 'src/services';
import { notificationActions, siteActions } from '.';
import moment from 'moment';

import { mapActionToActionName } from 'src/utils/constantMapper';
import { getText } from 'src/utils/MultilingualLoader';
import incidentNotificationContent from 'src/pages/Main/Cases/utils/incidentNotificationContent';

export const getCases = (filters) => {
  const request = () => {
    return {
      type: caseConstants.GET_CASES_REQUEST
    };
  };

  const success = result => {
    return {
      type: caseConstants.GET_CASES_SUCCESS,
      cases: result.cases,
      caseStats: result.case_stats
    };
  };

  const failure = () => {
    return {
      type: caseConstants.GET_CASES_FAILURE
    };
  };

  return (dispatch, getState) => {
    dispatch(request());
    filters = siteActions.addSiteToFilter(filters ? filters : getState().case.filters, getState());
    caseServices.getCases({}, filters)
      .then(result => {
        dispatch(success(result));
      })
      .catch(error => {
        dispatch(failure());
      });
  };
};

export const getMoreCases = (filters) => {
  const request = () => {
    return {
      type: caseConstants.GET_MORE_CASES_REQUEST
    };
  };

  const success = result => {
    return {
      type: caseConstants.GET_MORE_CASES_SUCCESS,
      cases: result.cases,
      caseStats: result.case_stats
    };
  };

  const failure = () => {
    return {
      type: caseConstants.GET_MORE_CASES_FAILURE
    };
  };

  return (dispatch, getState) => {
    const cases = getState().case.cases;
    const last_case_id = cases.length > 0 ? cases[cases.length - 1].id : Number.MAX_SAFE_INTEGER;
    dispatch(request());
    filters = Object.assign({}, getState().case.filters, filters);
    filters.lastId = last_case_id;
    filters = siteActions.addSiteToFilter(filters, getState());
    caseServices.getCases({}, filters)
      .then(result => {
        dispatch(success(result));
      })
      .catch(error => {
        dispatch(failure());
      });
  };
};

export const setPage = page => {
  return {
    type: caseConstants.SET_PAGE,
    page
  };
}

export const updateCase = (data) => {
  const request = () => {
    return {
      type: caseConstants.UPDATE_CASE_REQUEST
    };
  };

  const success = result => {
    return {
      type: caseConstants.UPDATE_CASE_SUCCESS,
      case: result.case,
      caseStats: result.case_stats
    };
  };

  const failure = () => {
    return {
      type: caseConstants.UPDATE_CASE_FAILURE
    };
  };

  const actionName = mapActionToActionName[data.action];

  return (dispatch, getState) => {
    const filters = siteActions.addSiteToFilter(getState().case.filters, getState());
    dispatch(request());
    caseServices.updateCase(data, filters)
      .then(result => {
        dispatch(success(result));
        if (actionName !== caseConstants.VIEW_CASE) {
          let extraMsg = '';
          if (actionName === caseConstants.FALSE_DETECTION || actionName === caseConstants.NO_ACTION_NEEDED)
            extraMsg = ', ' + getText('case_marked_as_resolved');
          if (actionName === caseConstants.CLOSE_CASE)
            extraMsg = ', ' + getText('case_marked_as_closed');
          dispatch(notificationActions.addNotification(`${getText(actionName)}${getText('words_space')}${getText('succeeded')}${extraMsg}`));
        }
      })
      .catch(error => {
        dispatch(failure());
        if (actionName !== caseConstants.VIEW_CASE)
          dispatch(notificationActions.addNotification(`${getText(actionName)}${getText('words_space')}${getText('failed')}`));
      });
  };
};

export const selectCase = _case => {
  return {
    type: caseConstants.SELECT_CASE,
    case: _case
  }
};

export const setFilter = filters => {
  return dispatch => {
    dispatch({ type: caseConstants.SET_FILTER, filters });
  }
};

export const addNewCase = (newCase) => {
  const request = () => {
    return {
      type: caseConstants.GET_NEW_CASES_REQUEST
    };
  };

  const success = result => {
    return {
      type: caseConstants.GET_NEW_CASES_SUCCESS,
      cases: result.cases,
      caseStats: result.case_stats
    };
  };

  const failure = () => {
    return {
      type: caseConstants.GET_NEW_CASES_FAILURE
    };
  };

  

  return (dispatch, getState) => {
    const { filters, fetching } = getState().case;

    // if new case type is incident, notify
    if (newCase.type === 'incident' || newCase.incident) {
      dispatch(notificationActions.addNotificationAhead(incidentNotificationContent(newCase, dispatch, getState), null));
    }

    // If the new case does not satisfy current filters, or If new cases are already fetching, no update
    if (!satisfyFilter(newCase, filters) || fetching) {
      return;
    }

    dispatch(request());

    //Get 10 newest cases to find the new cases
    let addNewFilters = Object.assign({}, filters, {
      timeType: 'all',
      startTime: null,
      endTime: null,
      unread: false,
      unclosed: false,
      pageSize: 10,
    });
    addNewFilters = siteActions.addSiteToFilter(addNewFilters, getState());
    caseServices.getCases({}, addNewFilters)
      .then(result => {
        dispatch(success(result));
      })
      .catch(error => {
        dispatch(failure());
      });
  };
};

export const setNewCaseNotification = shouldNotify => {
  return {
    type: caseConstants.SET_NEW_CASE_NOTIFICATION,
    shouldNotify
  }
}

export const getCameras = () => {
  const request = () => {
    return {
      type: caseConstants.GET_CAMERAS_REQUEST
    }
  };

  const success = cameras => {
    return {
      type: caseConstants.GET_CAMERAS_SUCCESS,
      cameras: cameras
    };
  };

  const failure = () => {
    return {
      type: caseConstants.GET_CAMERAS_FAILURE
    };
  };

  return (dispatch, getState) => {
    let filters = siteActions.addSiteToFilter({ type: 'all' }, getState());
    dispatch(request());
    cameraServices.getAllCameras({}, filters)
      .then(cameras => {
        dispatch(success(cameras));
      })
      .catch(error => {
        dispatch(failure());
      });
  };
};

export const selectCamera = (camera, checked) => {
  return dispatch => {
    dispatch({
      type: caseConstants.SELECT_CAMERA,
      id: camera.id,
      checked: checked
    }
    );
  };
};

export const updCamerasFromWS = source => {
  const parsedCameras = cameraServices.getAllCamerasAdapter.toClient({
    ret: source
  }).result || [];
  return {
    type: caseConstants.UPDATE_CAMERAS_FROM_WS,
    cameras: parsedCameras
  }
};

export const satisfyFilter = (_case, filters) => {
  try {
    if (filters.status !== 'all' && filters.status !== _case.status.toLowerCase()) return false;
    if (filters.priority !== 'all' && filters.priority !== _case.priority.toLowerCase()) return false;
    if (filters.startTime && filters.startTime.isAfter(moment(_case.created_at))) return false;
    if (filters.endTime && filters.endTime.isBefore(moment(_case.created_at))) return false;
    if (filters.unread && _case.viewed) return false;
    if (filters.unclosed && _case.status.toLowerCase() === 'closed') return false;
  } catch (e) {
    return false;
  }
  return true;
}

export const deleteCase = caseId => {
  const request = () => {
    return {
      type: caseConstants.UPDATE_CASE_REQUEST
    };
  };

  const success = () => {
    return {
      type: caseConstants.DELETE_CASE_SUCCESS,
      caseId
    };
  };

  const failure = () => {
    return {
      type: caseConstants.UPDATE_CASE_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    caseServices.deleteCase(caseId)
      .then(result => {
        dispatch(success());
        dispatch(notificationActions.addNotification(`${getText('delete_case')}${getText('words_space')}${getText('succeeded')}`));
      })
      .catch(error => {
        dispatch(failure());
        dispatch(notificationActions.addNotification(`${getText('delete_case')}${getText('words_space')}${getText('failed')}`));
      })
  };
}

export const getCaseTypes = deviceId => {
  const request = () => {
    return {
      deviceId,
      type: caseConstants.GET_TYPES_REQUEST
    }
  }

  const success = (result) => {
    return {
      type: caseConstants.GET_TYPES_SUCCESS,
      deviceId,
      types: result
    }
  }

  const failure = () => {
    return {
      deviceId,
      type: caseConstants.GET_TYPES_FAILURE
    }
  }

  return dispatch => {
    dispatch(request());
    caseServices.getCaseTypes(deviceId)
      .then(result => {
        dispatch(success(result));
      })
      .catch(error => {
        dispatch(failure());
      });
  }
}