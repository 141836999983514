import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ExitFullscreenIcon from '@material-ui/icons/FullscreenExit';

import CSSModuleStyles from './EventPlayer.module.css';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    height: '100%'
  },
  title: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  banner: {
    zIndex: 1,
    backgroundColor: '#FFFFFF'
  },
  video_visible: {
    width: '100%'
  },
  video_hidden: {
    display: 'none'
  },
  buttonBar: {
    display: 'flex',
    alignContent: 'flex-end',
    height: '36px',
    width: '100%',
    padding: '0 16px 0 16px'
  },
  button: {
    border: 0,
    padding: 0,
    backgroundColor: 'transparent'
  },
  spacer: {
    flex: 1
  },
  playtime: {
    color: '#FFFFFF',
    fontSize: '0.8rem',
    border: 0,
    padding: 0,
    backgroundColor: 'transparent',
    width: '30px',
  },
  seekBar: {
    height: '36px',
    width: '100%',
    padding: '0 16px 20px 16px',
  },
  timeLine: {
    width: '100%'
  }
});

const sortById = (a, b) => {
  if (a.id < b.id) return -1;
  else if (a.id > b.id) return 1;
  else return 0;
}

class EventPlayer extends React.Component {
  constructor(props){
    super(props);
    const { totalDuration, videoClips } = this.mapPlayListToVideoClips(props.playList, props.event);
    this.state = {
      playing: false,
      seeking: false,
      activeVideoClip: 0,
      bufferedVideoClip: 1 % videoClips.length,
      activeVideoContainer: null,
      bufferedVideoContainer: null,
      isFullScreen: false,
      totalDuration, videoClips
    };
  }

  mapPlayListToVideoClips = (playList, event) => {
    let totalDuration = 0;
    let videoClips = [];
    //sort playList by id(time)
    playList.sort(sortById);

    for (let idx = 0; idx < playList.length; idx++){
      let clipDuration = playList[idx].endTime.diff(playList[idx].startTime, 'seconds');
      let clip = {
        timeSlot: [],
        url: playList[idx].url,
        startSec: 0
      };
      if (idx === 0 && event.startTime.isAfter(playList[idx].startTime)){
        let startSec = event.startTime.diff(playList[idx].startTime, 'seconds');
        //keep 5 more secs before event's time as a context
        if (startSec > 5){
          startSec -= 5;
          clip.url += `#t=${startSec}`;
          clip.startSec = startSec;
          clipDuration -= startSec;
        }
      }
      if (idx === playList.length - 1 && event.endTime.isBefore(playList[idx].endTime)){
        let endSec = event.endTime.diff(playList[idx].startTime, 'seconds');
        const endDiff = playList[idx].endTime.diff(event.endTime, 'seconds');
        //keep 5 more secs after event's time as a context
        if (endDiff > 5){
          endSec += 5;
          clip.url += clip.url !== playList[idx].url ? `,${endSec}` : `#t=,${endSec}`;
          clipDuration -= playList[idx].endTime.diff(event.endTime, 'seconds') - 5;
        }
      }
      clip.timeSlot = [totalDuration, totalDuration + clipDuration];
      totalDuration += clipDuration;
      videoClips.push(clip);
    }
    return { totalDuration, videoClips };
  }

  videoContainerRef = videoContainer => {
    this.videoContainer = videoContainer;
  }

  video1Ref = video => {
    this.video1 = video;
  }

  video2Ref = video => {
    this.video2 = video;
  }

  videoControlsRef = videoControls => {
    this.videoControls = videoControls;
  }

  seekBarRef = seekBar => {
    this.seekBar = seekBar;
  }

  curPlayTimeRef = curPlayTime => {
    this.curPlayTime = curPlayTime;
  }
  

  //Event handler for play/pause button click
  playButtonOnClickHandler = () => {
    const { totalDuration, videoClips, activeVideoContainer, bufferedVideoClip, bufferedVideoContainer } = this.state;
    
    if (activeVideoContainer.paused){
      //If start at the end of the last clip: restart at the first clip
      if (parseFloat(this.seekBar.value) === parseFloat(totalDuration)){
        this.setState({
          activeVideoClip: bufferedVideoClip,
          bufferedVideoClip: (bufferedVideoClip + 1) % videoClips.length,
          activeVideoContainer: bufferedVideoContainer,
          bufferedVideoContainer: activeVideoContainer,
          playing: true
        }, () => {
          const { activeVideoContainer, bufferedVideoContainer, bufferedVideoClip } = this.state;
          activeVideoContainer.play();
          //let the previous active container buffer be a buffered container for the next clip
          bufferedVideoContainer.src = videoClips[bufferedVideoClip].url;
        });
      }
      else {
        activeVideoContainer.play();
        this.setState({
          playing: true
        });
      }
    }
    else {
      activeVideoContainer.pause();
      this.setState({
        playing: false
      });
    }
  }

  //Event handler for seekBar mouse down
  seekBarMouseDownHandler = () => {
    if (!this.state.activeVideoContainer.paused){
      this.state.activeVideoContainer.pause();
    }
    this.setState({
      seeking: true
    });
  }

  //Event handler for seekBar mouse up
  seekBarMouseUpHandler = () => {
    const { playing, activeVideoContainer, totalDuration } = this.state;
    const time = parseFloat(this.seekBar.value);
    //If the end is reached after seeking: stop playing
    if (playing){
      if (time < parseFloat(totalDuration)){
        activeVideoContainer.play();
      }
      else {
        this.setState({
          playing: false
        });
      }
    }
    this.setState({
      seeking: false
    });
  }

  //Event handler for seekBar input
  seekBarInputHandler = () => {
    const time = parseFloat(this.seekBar.value);
    const { activeVideoClip, bufferedVideoClip, videoClips, activeVideoContainer, bufferedVideoContainer } = this.state;

    //update the curPlayTime
    this.curPlayTime.value = this.formatTime(time);

    //find the video clip contains the new time point
    let selectedClip = activeVideoClip;
    for (let idx = 0; idx < videoClips.length; idx++){
      if (videoClips[idx].timeSlot[0] <= time && 
        (videoClips[idx].timeSlot[1] > time || (idx === videoClips.length - 1 && videoClips[idx].timeSlot[1] >= time))){
          selectedClip = idx;
          break;
      }
    }

    //If the new time point is still in the current active clip: current active container move to the new time point
    if (selectedClip === activeVideoClip){
      activeVideoContainer.currentTime = time - videoClips[activeVideoClip].timeSlot[0] + videoClips[activeVideoClip].startSec;
    }

    //If the new time point is in the next buffered clip: activate the buffered container to replace the current active container
    else if (selectedClip === bufferedVideoClip){
      this.setState({
        activeVideoClip: selectedClip,
        bufferedVideoClip: (selectedClip + 1) % videoClips.length,
        activeVideoContainer: bufferedVideoContainer,
        bufferedVideoContainer: activeVideoContainer
      }, () => {
        const { activeVideoContainer, bufferedVideoContainer } = this.state;
        activeVideoContainer.currentTime = time - videoClips[selectedClip].timeSlot[0] + videoClips[selectedClip].startSec;
        //let the previous active container buffer be a buffered container for the next clip
        bufferedVideoContainer.src = videoClips[(selectedClip + 1) % videoClips.length].url;
      });
    }

    //If the new time point is neither in an unloaded clip: reset the active container and the buffered container
    else {
      //move the current active container to the selected clip
      activeVideoContainer.src = videoClips[selectedClip].url;
      activeVideoContainer.currentTime = time - videoClips[selectedClip].timeSlot[0] + videoClips[selectedClip].startSec;
      //move the buffered container to the clip behind the selected clip
      bufferedVideoContainer.src = videoClips[(selectedClip + 1) % videoClips.length].url;
      this.setState({
        activeVideoClip: selectedClip,
        bufferedVideoClip: (selectedClip + 1) % videoClips.length
      });
    }
  }

  //Event handler for video ended
  videoEndedHandler = () => {
    const { activeVideoClip, bufferedVideoClip, videoClips, bufferedVideoContainer, activeVideoContainer } = this.state;

    //If the ended clip is the last clip: do not shift to the next clip
    if (activeVideoClip === videoClips.length - 1){
      this.setState({
        playing: false
      });
      return;
    }

    this.setState({
      activeVideoClip: bufferedVideoClip,
      bufferedVideoClip: (bufferedVideoClip + 1) % videoClips.length,
      activeVideoContainer: bufferedVideoContainer,
      bufferedVideoContainer: activeVideoContainer
    }, () => {
      const { activeVideoContainer, bufferedVideoContainer, bufferedVideoClip, playing } = this.state;
      if (playing){
        activeVideoContainer.play();
      }
      //let the previous active container buffer be a buffered container for the next clip
      bufferedVideoContainer.src = videoClips[bufferedVideoClip].url;
    });
  }

  //Event handler for video time update
  videoTimeUpdateHandler = () => {
    const { videoClips, activeVideoClip, activeVideoContainer, totalDuration, playing, seeking } = this.state;
    const videoClip = videoClips[activeVideoClip];
    let value = videoClip.timeSlot[0] + activeVideoContainer.currentTime - videoClip.startSec;
    if (!seeking && playing){
      this.seekBar.value = value;
      this.curPlayTime.value = this.formatTime(value);
    }
    
    //stop when the end is reached
    if (parseFloat(value) >= parseFloat(totalDuration) && !seeking && playing){
      activeVideoContainer.pause();
      this.setState({
        playing: false
      });
    }
  }

  fullscreenOnClickHandler = () => {
    if (this.videoContainer.requestFullscreen){
      this.videoContainer.requestFullscreen();
    } 
    else if (this.videoContainer.mozRequestFullScreen) { /* Firefox */
      this.videoContainer.mozRequestFullScreen();
    } 
    else if (this.videoContainer.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      this.videoContainer.webkitRequestFullscreen();
    } 
    else if (this.videoContainer.msRequestFullscreen) { /* IE/Edge */
      this.videoContainer.msRequestFullscreen();
    }
  }

  exitFullScreenOnClickHandler = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    }
    else if (document.webkitCancelFullScreen) { /* Chrome, Safari and Opera */
      document.webkitCancelFullScreen();
    }
    else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
  }

  fullScreenChangeHandler = () => {
    if (document.fullscreenElement || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement) {
      this.setState({isFullScreen: true});
    }
    else {
      this.setState({isFullScreen: false});
    }
  }

  addListeners = () => {
    document.addEventListener('fullscreenchange', this.fullScreenChangeHandler);
    document.addEventListener('webkitfullscreenchange', this.fullScreenChangeHandler);
    document.addEventListener('mozfullscreenchange', this.fullScreenChangeHandler);
    document.addEventListener('MSFullscreenChange', this.fullScreenChangeHandler);
  }

  removeListeners = () => {
    this.seekBar.removeEventListener('mousedown', this.seekBarMouseDownHandler);
    this.seekBar.removeEventListener('mouseup', this.seekBarMouseUpHandler);
    this.seekBar.removeEventListener('input', this.seekBarInputHandler);
    this.seekBar.removeEventListener('change', this.seekBarChangeHandler);
    this.video1.removeEventListener('ended', this.videoEndedHandler);
    this.video2.removeEventListener('ended', this.videoEndedHandler);
    this.video1.removeEventListener('timeupdate', this.videoTimeUpdateHandler);
    this.video2.removeEventListener('timeupdate', this.videoTimeUpdateHandler);

    document.removeEventListener('fullscreenchange', this.fullScreenChangeHandler);
    document.removeEventListener('webkitfullscreenchange', this.fullScreenChangeHandler);
    document.removeEventListener('mozfullscreenchange', this.fullScreenChangeHandler);
    document.removeEventListener('MSFullscreenChange', this.fullScreenChangeHandler);
  }

  formatTime = time => {
    let formatedTime = '';
    let hours = Math.floor(time / 3600);
    let minutes = Math.floor((time - 3600 * hours) / 60);
    let seconds = Math.floor(time - 3600 * hours - 60 * minutes);
    let hasHour = hours > 0;
    if (hasHour) {
      formatedTime += hours < 10 ? '0' + hours : hours;
      formatedTime += ':';
    }
    formatedTime += hasHour && minutes < 10 ? '0' + minutes : minutes;
    formatedTime += ':';
    formatedTime += seconds < 10 ? '0' + seconds : seconds;

    //increase the length of the input for curPlayTime if necessary
    if (this.curPlayTime){
      if (hours > 0){
        this.curPlayTime.style.width = '60px';
      }
      else if (minutes > 10){
        this.curPlayTime.style.width = '40px';
      }
    }
    return formatedTime;
  }

  componentDidMount(){
    const { videoClips } = this.state;
    this.video1.src = videoClips[0] ? videoClips[0].url : '';
    this.video2.src = videoClips[1 % videoClips.length] ? videoClips[1 % videoClips.length].url : '';
    this.seekBar.value = 0;
    this.curPlayTime.value = '0:00';
    this.video1.style.minHeight = this.props.minHeight;
    this.video2.style.minHeight = this.props.minHeight;
    this.setState({
      activeVideoContainer: this.video1,
      bufferedVideoContainer: this.video2
    }, this.addListeners());
  }

  componentWillUnmount () {
    this.removeListeners();
  }

  componentDidUpdate(prevProps){
    if (this.props.caseId !== prevProps.caseId){
      this.state.activeVideoContainer.pause();
      const { totalDuration, videoClips } = this.mapPlayListToVideoClips(this.props.playList, this.props.event);
      this.setState({
        playing: false,
        seeking: false,
        activeVideoClip: 0,
        bufferedVideoClip: 1  % videoClips.length,
        activeVideoContainer: this.video1,
        bufferedVideoContainer: this.video2,
        isFullScreen: false,
        totalDuration, videoClips
      }, () => {
        this.video1.src = videoClips[0] ? videoClips[0].url : '';
        this.video2.src = videoClips[1 % videoClips.length] ? videoClips[1 % videoClips.length].url : '';
        this.seekBar.value = 0;
        this.curPlayTime.value = '0:00';
        this.curPlayTime.style.width = '30px'
      });
    }

    if (this.props.minHeight !== prevProps.minHeight){
      this.video1.style.minHeight = this.props.minHeight;
      this.video2.style.minHeight = this.props.minHeight;
    }
  }

  render(){
    const {
      classes,
      caseId,
      minHeight
    } = this.props;

    const { playing, seeking, totalDuration, activeVideoContainer, isFullScreen } = this.state;

    const title = `${getText('case')}: #${caseId}`;
  
    return (
      <Card
        square={true}
        className={classes.root}
        >
        <Grid
          direction="column"
          container={true}
          justify="flex-start"
          spacing={0}
        >
          <Grid item={true} style={{ zIndex: 0, minHeight: minHeight }}>
            <CardMedia  src="video" >
            <div className={CSSModuleStyles.video_container} ref={this.videoContainerRef}>
              <video 
                ref={this.video1Ref} 
                className={activeVideoContainer === this.video1 ? classes.video_visible : classes.video_hidden}
                preload='auto'
                controls={false}
                onEnded={this.videoEndedHandler}
                onTimeUpdate={this.videoTimeUpdateHandler}
                />
              <video 
                ref={this.video2Ref} 
                className={activeVideoContainer === this.video2 ? classes.video_visible : classes.video_hidden} 
                preload='auto'
                controls={false}
                onEnded={this.videoEndedHandler}
                onTimeUpdate={this.videoTimeUpdateHandler}
                />
              <div className={CSSModuleStyles.video_controls} ref={this.videoControlsRef}>
                <div className={classes.buttonBar}>
                  <IconButton className={classes.button} onClick={this.playButtonOnClickHandler}>
                  {playing && !seeking ? 
                    <PauseIcon htmlColor='#FFFFFF' /> : 
                    <PlayIcon htmlColor='#FFFFFF' />}
                  </IconButton>
                  <input ref={this.curPlayTimeRef} className={classes.playtime} style={{marginLeft: '12px'}} disabled />
                  <input className={classes.playtime} style={{width: '5px', margin: '0 5px 0 5px'}} value='/' disabled />
                  <input className={classes.playtime} style={{width: '80px'}} value={this.formatTime(totalDuration)} disabled />
                  {/* Spacer */}
                  <span className={classes.spacer}/>
                  {isFullScreen ? 
                    <IconButton className={classes.button} onClick={this.exitFullScreenOnClickHandler}>
                      <ExitFullscreenIcon htmlColor='#FFFFFF' />
                    </IconButton>
                    : <IconButton className={classes.button} onClick={this.fullscreenOnClickHandler}>
                        <FullscreenIcon htmlColor='#FFFFFF' />
                      </IconButton>
                  }
                </div>
                <div className={classes.seekBar}>
                  <input 
                    type="range" 
                    ref={this.seekBarRef} 
                    className={classes.timeLine} 
                    min="0" 
                    max={totalDuration} 
                    step="any"
                    onMouseDown={this.seekBarMouseDownHandler}
                    onMouseUp={this.seekBarMouseUpHandler}
                    onInput={this.seekBarInputHandler}
                    />
                </div>
              </div>
            </div>
            </CardMedia>
          </Grid>
          <Grid item={true} className={classes.banner}>
            <Typography variant="body1" className={classes.title}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    );
  }
};

EventPlayer.propTypes = {
  classes: PropTypes.object.isRequired,
  playList: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
};

EventPlayer.defaultProps = {
  playList: [],
  event: {},
};

export default withStyles(styles)(EventPlayer);
