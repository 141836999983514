import React, {useEffect, useState, useMemo} from "react"
import {connect} from "react-redux"
import {
  Grid,
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Switch,
  FormControlLabel
} from "@material-ui/core"

import {getText} from "src/utils/MultilingualLoader"
import ButtonGroup from "src/components/ButtonGroup"
import MapRouteModal from "./MapRouteModal"
import TargetLoopModal from "./TargetLoopModal"
import RoutineSchedules from "./RoutineSchedules"
import {messageActions, robotActions} from "src/redux/actions"
import {scheduleAdapter} from "./adapter"
import Modal from '../../../../../components/Modal'

const styles = (theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(2)
  },
  label: {
    width: 80,
    marginRight: theme.spacing(1)
  },
  table: {
    background: "#f1f2f7",
    width: "100%"
  },
  buttons: {
    marginTop: theme.spacing(2)
  },
  actions: {
    textAlign: "center"
  },
  name: {
    border: "none",
    outline: "none",
    padding: "4px 11px",
    lineHeight: 1.57
  }
})

const mapDispatchToProps = (dispatch) => ({
  updateRobotConfigs: (robotId, updConfigs) =>
    dispatch(robotActions.updateRobotConfigs(robotId, updConfigs)),
  showNotification: (msg, type = "success") =>
    dispatch(messageActions.showNotification(msg, type))
})

const actionTypes = [
  "load_map_route",
  "one_loop",
  "target_loop",
  "goto_switch_pose",
  'vr_record'
]

const actionTextMapper = {
  'one_loop.vr_record': getText('vr_record')
}

function getActionText(action) {
  return actionTextMapper[`${action.act}.${action.mode}`] || getText(action.act) || action.act
}

const Routine = ({
                   classes,
                   routines,
                   schedules,
                   routine,
                   robotMapList,
                   targets,
                   selected,
                   updateRobotConfigs,
                   showNotification,
                   onCancel
                 }) => {
  const [actions, setActions] = useState([])
  const [editAction, setEditAction] = useState()
  const [unreachalbeTargets, setUnreachableTargets] = useState([])
  const [name, setName] = useState("")
  const [isSchedule, setIsSchedule] = useState(false)
  const [scheduleData, setScheduleData] = useState(
    scheduleAdapter.toClient(null)
  )
  const [loadedMap, setLoadedMap] = useState({})
  const [loadedRoute, setLoadedRoute] = useState({})
  
  const targetsMap = useMemo(() => {
    if (targets) {
      return new Map(
        targets.map((target) => [String(target.id), target.label])
      )
    }
    return null
  }, [targets])
  
  useEffect(() => {
    setActions(routine.actions || [])
    const targetsMap = new Map(
      targets.map((target) => [String(target.id), target])
    )
    setUnreachableTargets(
      ((routine.target && routine.target.all_target_ids) || []).filter(
        (targetId) => {
          const target = targetsMap.get(targetId)
          if (target && target.reachable === false) {
            return true
          }
          return false
        }
      )
    )
    setName(routine.name)
    const scheduleItem = (schedules || []).find(
      (item) => item.routine_id === routine.id
    )
    setIsSchedule(!!scheduleItem)
    setScheduleData(scheduleAdapter.toClient(scheduleItem || null))
  }, [])
  
  function getMapRouteName(mapId, routeId) {
    const map = robotMapList.maps.find((item) => item.id === mapId)
    if (!map) {
      return `${getText("map_name")}: --, ${getText("route_name")}: --`
    }
    const route = (map.mapRoutes || []).find((item) => item.id === routeId)
    if (!route) {
      return `${getText("map_name")}: ${map.name}, ${getText(
        "route_name"
      )}: --`
    }
    return `${getText("map_name")}: ${map.name}, ${getText("route_name")}: ${
      route.name
    }`
  }
  
  function getTargetList(list) {
    return (list || [])
    .map((targetId) => targetsMap.get(targetId) || targetId)
    .join(", ")
  }
  
  function getActionDetails(act, arg) {
    if (act === "load_map_route") {
      return !robotMapList || robotMapList.loading
        ? getText("loading")
        : getMapRouteName(arg.map_id, arg.route_id)
    } else if (act === "target_loop") {
      return !targets ? getText("loading") : getTargetList(arg.target_list)
    } else if (act === "one_loop" || act === "goto_switch_pose") {
      return ""
    } else {
      return `${getText("cannot_resolve")}(${act})`
    }
  }
  
  function handleEditAction(action, index) {
    if (
      action.type === "target_loop" &&
      (index === 0 || actions[index - 1].act !== "load_map_route")
    ) {
      showNotification(getText("must_be_load_map_and_route"), "warning")
      return
    }
    setEditAction({...action, id: index})
  }
  
  function handleUpdateAction(newAction) {
    if (newAction.act === "load_map_route") {
      // 如果是加载地图与路径，需要将地图与路径的完整数据保存下来，用于之后新增其他动作时会用到
      const {map, route, ...others} = newAction
      newAction = others
      
      setLoadedMap((prev) => ({
        ...prev,
        [newAction.mapId]: map
      }))
      setLoadedRoute((prev) => ({
        ...prev,
        [newAction.routeId]: route
      }))
    }
    
    setEditAction(null)
    setActions(
      actions
      .map((item, index) =>
        index === newAction.id
          ? {
            ...item,
            arg: {
              ...item.arg,
              route_id: newAction.routeId,
              map_id: newAction.mapId,
              reset_slam:
                newAction.act === "load_map_route" ? false : undefined,
              target_list: newAction.targetList
            }
          }
          : item
      )
      .concat(
        actions.length === newAction.id
          ? [
            {
              act: newAction.act,
              arg: {
                route_id: newAction.routeId,
                map_id: newAction.mapId,
                target_list: newAction.targetList,
                reset_slam:
                  newAction.act === "load_map_route" ? false : undefined
              }
            }
          ]
          : []
      )
    )
    if (newAction.act === "target_loop") {
      setUnreachableTargets(newAction.unReachableSelected)
    }
  }
  
  function handleAddAction(actionType) {
    let preNotLoadMapRoute = actions.length === 0 || actions[actions.length - 1].act !== "load_map_route"
    
    if (actionType === "one_loop" || actionType === "goto_switch_pose") {
      setActions(actions.concat({act: actionType, arg: {}}))
    } else if (actionType === "target_loop") {
      if (preNotLoadMapRoute) {
        showNotification(getText("must_be_load_map_and_route"), "warning")
        return
      }
      setEditAction({
        act: actionType,
        id: actions.length,
        arg: {target_list: []}
      })
    } else if (actionType === "load_map_route") {
      setEditAction({
        act: actionType,
        id: actions.length,
        arg: {map_id: null, route_id: null}
      })
    } else if (actionType === 'vr_record') {
      if (preNotLoadMapRoute) {
        showNotification(getText("must_be_load_map_and_route_vr_record"), "warning")
        return
      }
      
      const {arg: {map_id, route_id}} = actions[actions.length - 1]
      const mapInfo = loadedMap[map_id]
      const routeInfo = loadedRoute[route_id]
      
      if (!mapInfo || !routeInfo) {
        showNotification(getText("load_map_route_error"), "warning")
        return
      }
      
      // 地图必须绑定楼层才可以添加VR录制动作，因为VR视频保存的时候，需要楼层信息
      if (!mapInfo.floorId) {
        showNotification(getText("load_map_without_floor_error"), "warning")
        return
      }
      
      const {way_points} = routeInfo
      
      /**
       * way_points的部分数据结构是这样的：
       {
          "way_points": [{
            "actions": [{
              "act": "vr_start_record"
            }]
          }, {
            "actions": [{
              "act": "vr_stop_record"
            }]
          }]
        }
       要添加VR录制动作，必须满足way_points中同时存在act为"vr_start_record"和"vr_stop_record"的action，这是因为VR录制需要有个录制的起始位置
       */
      if (way_points && way_points.length > 0) {
        let hasStartRecord = false
        let hasStopRecord = false
        let routeActions
        
        for (let i = 0; i < way_points.length; i++) {
          routeActions = way_points[i].actions
          
          if (!routeActions) {
            continue
          }
          
          if (!hasStartRecord) {
            hasStartRecord = routeActions.findIndex((action) => action.act === 'vr_start_record') !== -1
          }
          
          if (!hasStopRecord) {
            hasStopRecord = routeActions.findIndex((action) => action.act === 'vr_stop_record') !== -1
          }
          
          if (hasStartRecord && hasStopRecord) {
            break
          }
        }
        
        if (hasStartRecord && hasStopRecord) {
          setActions(actions.concat({
            act: 'one_loop', mode: 'vr_record', arg: {atomic: true}
          }))
          return
        }
      }
      
      showNotification(getText("load_route_start_stop_action_error"), "warning")
    }
  }
  
  function getAllTargets(actions) {
    return Array.from(
      new Set(
        actions
        .filter((item) => item.act === "target_loop")
        .map((item) => item.arg.target_list || [])
        .flat()
      )
    )
  }
  
  function handleSubmit() {
    if (!name) {
      showNotification(getText("input_error_name"), "warning")
      return
    }
    if (isSchedule) {
      if (scheduleData.weeklyRepeat && scheduleData.daysOfWeek.length === 0) {
        showNotification(getText("input_error_date"), "warning")
        return
      }
      if (!scheduleData.weeklyRepeat && !scheduleData.startDate) {
        showNotification(getText("input_error_date"), "warning")
        return
      }
      if (scheduleData.startTimes.filter((item) => !!item).length === 0) {
        showNotification(getText("input_error_datetime"), "warning")
        return
      }
    }
    let newRoutines = []
    if (routine.id) {
      newRoutines = routines.map((item) =>
        item.id === routine.id
          ? {
            ...routine,
            name,
            schedule: null,
            startDate: undefined,
            startTime: undefined,
            actions,
            target: {
              all_target_ids:
                getAllTargets(actions).concat(unreachalbeTargets)
            }
          }
          : item
      )
    } else {
      newRoutines = [
        {
          id: String(+new Date()),
          name,
          actions,
          target: {
            all_target_ids: getAllTargets(actions).concat(unreachalbeTargets)
          }
        }
      ].concat(routines)
    }
    
    let newSchedules = []
    if (newRoutines.length > 0) {
      const routineId = routine.id || newRoutines[0].id
      const existedSchedule = (schedules || []).find(
        (item) => item.routine_id === routineId
      )
      if (!isSchedule) {
        newSchedules = (schedules || []).filter(
          (item) => item.routine_id !== routineId
        )
      } else if (!existedSchedule) {
        newSchedules = (schedules || []).concat([
          scheduleAdapter.toServer(scheduleData, routineId)
        ])
      } else {
        newSchedules = (schedules || []).map((item) =>
          item.routine_id === routineId
            ? scheduleAdapter.toServer(scheduleData, routineId)
            : item
        )
      }
    }
    
    updateRobotConfigs(selected.id, {
      routines: newRoutines,
      routine_schedules: newSchedules
    })
  }
  
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {routine.id && (
          <Grid container item>
            <Grid className={classes.label}>{getText("id")}</Grid>
            <Grid>{routine.id}</Grid>
          </Grid>
        )}
        <Grid container item>
          <Grid className={classes.label}>{getText("name")}</Grid>
          <Grid>
            <input
              spellCheck={false}
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={classes.name}
            />
          </Grid>
        </Grid>
        <Grid container item>
          <Grid className={classes.label}>{getText("actions")}</Grid>
          <Grid item xs>
            <Table className={classes.table}>
              {actions.length > 0 && (
                <TableHead>
                  <TableRow>
                    <TableCell>{getText("id")}</TableCell>
                    <TableCell>{getText("action")}</TableCell>
                    <TableCell>{getText("details")}</TableCell>
                    <TableCell className={classes.actions}>
                      {getText("actions")}
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {actions.map((action, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{getActionText(action)}</TableCell>
                    <TableCell>
                      {getActionDetails(action.act, action.arg)}
                    </TableCell>
                    <TableCell className={classes.actions}>
                      <Button
                        color="primary"
                        disabled={
                          action.act === "one_loop" ||
                          action.act === "goto_switch_pose"
                        }
                        onClick={() => handleEditAction(action, index)}
                      >
                        {getText("edit")}
                      </Button>
                      <Button
                        onClick={() =>
                          setActions(actions.filter((_, i) => i !== index))
                        }
                        color="primary"
                      >
                        {getText("delete")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className={classes.buttons}
            >
              <ButtonGroup
                options={[
                  {label: getText("append_action")},
                  ...actionTypes.map((type) => ({label: getText(type)}))
                ]}
                onClick={(index) => handleAddAction(actionTypes[index])}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid className={classes.label}>{getText("schedule_routine")}</Grid>
          <Grid>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  color="primary"
                  checked={isSchedule}
                  onChange={(e) => {
                    setIsSchedule(e.target.checked)
                    if (!e.target.checked) {
                      setScheduleData(scheduleAdapter.toClient(null))
                    }
                  }}
                />
              }
              label=""
            />
          </Grid>
        </Grid>
        {isSchedule && (
          <RoutineSchedules
            scheduleData={scheduleData}
            handleUpdate={(v) => setScheduleData(v)}
          />
        )}
        <Grid
          container
          alignItems="center"
          justify="flex-end"
          className={classes.buttons}
        >
          <Button
            color="primary"
            onClick={onCancel}
            style={{marginRight: 12}}
          >
            {getText("cancel")}
          </Button>
          <Button onClick={handleSubmit} variant="outlined" color="primary">
            {getText("ok")}
          </Button>
        </Grid>
      </Grid>
      {editAction && editAction.act === "load_map_route" && (
        <MapRouteModal
          selected={selected}
          action={editAction}
          onCancel={() => setEditAction(null)}
          onConfirm={handleUpdateAction}
        />
      )}
      {editAction && editAction.act === "target_loop" && (
        <TargetLoopModal
          selected={selected}
          actions={actions}
          targets={targets}
          unreachalbeTargets={unreachalbeTargets}
          action={editAction}
          onCancel={() => setEditAction(null)}
          onConfirm={handleUpdateAction}
        />
      )}
    </div>
  )
}

export default connect(
  undefined,
  mapDispatchToProps
)(withStyles(styles)(Routine))
