import React from 'react';
import { withStyles, Grid, Card, GridList, Typography, Dialog, DialogTitle, DialogContent, Zoom, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import OkIcon from '@material-ui/icons/Check';
import PokIcon from '@material-ui/icons/Clear';
import TaskIcon from '@material-ui/icons/LocalGroceryStore';
import CaseIcon from '@material-ui/icons/BugReport';
import { Pannellum } from "pannellum-react";

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  card: {
    height: '100%'
  },
  verticalGrid: {
    height: '100%'
  },
  content: {
    paddingTop: theme.spacing(1),
  },
  mediaList: {
    margin: '0 !important',
    padding: '0 !important',
    flexWrap: 'nowrap',
    width: '100%',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  imgTile: {
    // width: '100%',
    // To fit Safari
    // height: 'auto',
    // height: '100% !important',
    padding: '0 !important'
  },
  videoTile: {
    // width: '100%',
    objectFit: 'fill',
    // To fit Safari
    // height: 'auto',
    // height: '100% !important',
    padding: '0 !important'
  },
  pointName: {
    padding: theme.spacing(1),
    flex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  space: {
    padding: theme.spacing(1),
    paddingTop: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    '&:hover': {
      cursor: 'pointer'
    }
  },
  diaTitle: {
    textAlign: 'center',
    paddingBottom: 0
  },
  diaContent: {
    textAlign: 'center'
  },
  zoomedImg: {
    margin: 'auto',
    height: '90%',
    overflow: 'auto',
  },
  moreIcon: {
    position: 'absolute',
    top: 8,
    right: 8
  },
  title: {
    color: 'grey'
  },
  targetName: {
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
});

class TaskMedium extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      imgSrc: null,
      isPanoramic: false,
      mediumHeight: 'auto'
    };
  }

  componentDidMount() {
    this.resizeMedium();
    window.addEventListener('resize', this.resizeMedium);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeMedium);
  }

  resizeMedium = () => {
    const mediumHeight = (this.card && this.card.offsetWidth * 9 / 16) || 'auto';
    this.setState({ mediumHeight });
  }

  onImageClick = snapshot => {
    this.setState({
      isOpen: true,
      imgSrc: snapshot,
    });
  }

  cardRef = card => {
    this.card = card;
  }

  render() {
    const { classes, task, handleReview, handleCase } = this.props;
    const { isOpen, imgSrc, isPanoramic, mediumHeight } = this.state;

    return (
        <Card
          className={classes.card}
          square={true}
          ref={this.cardRef}
        >
          <Grid
            className={classes.verticalGrid}
            container={true}
            direction="column"
            justify="flex-start"
            spacing={0}
          >
            <Grid container direction="column" className={classes.content} spacing={0}>
              <Grid container item justify="space-between" className={classes.space}>
                <Typography variant="body1" className={classes.targetName} title={task.target_name}>{task.target_name}</Typography>
                <Typography>{task.end_time}</Typography>
              </Grid>
              <Grid container item justify="space-between" className={classes.space}>
                <Typography variant="body1">{task.status}</Typography>
                <Typography variant="body1">{task.algoType}</Typography>
              </Grid>
              <Grid container item justify="space-between" className={classes.space}>
                <Typography variant="caption" className={classes.title}>{getText('moderate_type')}</Typography>
                <Typography variant="body1">{task.moderation}</Typography>
              </Grid>
              <Grid container item justify="space-between" className={classes.space}>
                <Typography variant="caption" className={classes.title}>{getText('case_type')}</Typography>
                <Typography variant="body1">{task.event}</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <GridList cols={1} cellHeight={mediumHeight} className={classes.mediaList}>
                <img
                  className={classes.imgTile}
                  src={task.snapshot}
                  alt={getText('snapshot')}
                  onClick={() => this.onImageClick(task.snapshot)}
                  crossOrigin="anonymous"
                />
              </GridList>
            </Grid>
            <Grid container className={classes.content} spacing={0}>
              <Grid container item justify="space-between" alignItems="center" className={classes.space}>
                {task.result === true ? <OkIcon /> : (task.result === false ? <PokIcon /> : <div></div>) }
                <Grid>
                  <Button disabled={task.status === getText('review_pending')} variant="outlined" onClick={handleReview(task, task.results)} style={{marginRight: 8}}>{getText('moderate')}</Button>
                  <Button disabled={task.result === null} variant="outlined" onClick={handleCase(task, task.results)}>{getText('create_case')}</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Dialog
            TransitionComponent={Zoom}
            fullScreen
            open={isOpen}
            onClose={() => {
              this.setState({
                isOpen: false,
                imgSrc: null,
                isPanoramic: false
              });
            }}
          >
            <DialogTitle className={classes.diaTitle}>
              <Typography variant="body1">{task.target_name} {task.end_time}</Typography>
              {isPanoramic && <Typography variant="body1">{getText('360_image_description')}</Typography>}
              <CloseIcon
                className={classes.closeButton}
                onClick={() => {
                  this.setState({
                    isOpen: false,
                    imgSrc: null,
                    isPanoramic: false
                  });
                }}
              />
            </DialogTitle>
            <DialogContent className={classes.diaContent}>
              {isPanoramic ?
                <Pannellum
                  image={imgSrc}
                  width="100%"
                  height="100%"
                  autoLoad
                /> 
                : <img className={classes.zoomedImg} src={imgSrc} alt={getText('snapshot')} />}
            </DialogContent>
          </Dialog>
        </Card>
      
    );
  }
}

export default withStyles(styles)(TaskMedium);