import React from 'react';
import history from 'src/utils/history';
import { Button } from '@material-ui/core';
import { notificationActions, siteActions, caseActions } from 'src/redux/actions';
import { getRobot } from 'src/services/robot';
import { getText } from 'src/utils/MultilingualLoader';

const incidentNotificationContent = (newCase, dispatch, getState) => {
  const handleGotIt = () => {
    dispatch(notificationActions.notificationShowed());
  }

  const handleView = async () => {
    try {
      const robot = await getRobot(newCase.incident.system_id);
      const siteId = robot.site_id;
      if (siteId) {
        dispatch(siteActions.setCurSite(siteId));
        const filters = {
          siteId,
          caseType: 'incident',
          incidentType: newCase.incident.incident_type,
          action: 'all',
          status: 'all',
          priority: 'all',
          searchQuery: '',
          timeType: 'all',
          startTime: null,
          endTime: null,
          unread: false,
          unclosed: false,
          pageSize: 100,
        };
        dispatch(caseActions.setFilter(filters));
        dispatch(caseActions.getCases(filters));
        history.push('/cases/new');
      }
    } catch (e) {
      // do nothing
    }

    dispatch(notificationActions.notificationShowed());
  }

  return (
    <div>
      <div style={{ padding: '2px 8px' }}>{getText('incident')} {newCase.id}</div>
      <div style={{ padding: '2px 8px', fontSize: 12 }}>{getText('incident_type')} {newCase.incident.incident_type}</div>
      <div>
        <Button color="primary" variant="text" onClick={handleGotIt}>{getText('got_it')}</Button>
        <Button color="primary" variant="text" onClick={handleView}>{getText('view')}</Button>
      </div>
    </div>
  )
}

export default incidentNotificationContent;