import React, { useState } from 'react';
import {
  withStyles, Grid, Menu, MenuItem, Dialog, DialogTitle, DialogContent, FormControlLabel, Checkbox,
  Typography, Button
} from '@material-ui/core';

import InspectionMedium from './InspectionMedium/InspectionMedium';
import InspectionPagination from './InspectionPagination';
import Loading from 'src/components/LoadingText';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  button: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  label: {
    color: 'white',
    textTransform: 'initial'
  },
});

const InspectionList = props => {
  const { classes, inspections, lineSize, curPage, lastPage, counts, inspectionLoading,
    caseTypes, updateInspectionAlgos, createCase, curSite, eventTypes, updateInspectionResults } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedInspection, setSelectedInspection] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [moderateOpen, setModerateOpen] = useState(false);
  const open = Boolean(anchorEl);

  return (
    <Grid
      container
      direction='column'
      justify='flex-start'
      alignItems='stretch'
    >
      <Grid
        item
        container={true}
        direction='row'
        justify='flex-start'
        alignItems='stretch'
        spacing={3}
      >
        {inspections.map(inspection => (
          <Grid 
            item 
            xs={12 / lineSize}
            key={inspection.id}
          >
            <InspectionMedium
              mediums={inspection.mediums || []}
              wayPointName={(inspection.target && inspection.target.name) || (inspection.way_point && inspection.way_point.name) || ''}
              ended_at={inspection.ended_at}
              openMenu={event => {
                let algos = {};
                let results = {};
                inspection.algos && inspection.algos.forEach(algo => algos[algo] = true);
                inspection.results && inspection.results.forEach(item => results[item] = true);
                setAnchorEl(event.currentTarget);
                setSelectedInspection({
                  id: inspection.id,
                  algos,
                  results,
                  case: inspection.case
                });
              }}
            />
          </Grid>
        ))}
        {selectedInspection &&
          <Menu
            id="menu-inspection"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={() => {
              setAnchorEl(null);
              setSelectedInspection(null);
            }}
          >
            <MenuItem
              disabled
            >
              {selectedInspection.case ? `${getText('case_id')}: ${selectedInspection.case}` : getText('case_not_created')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setDialogOpen(true);
              }}
            >
              {getText('inspection_type')}
            </MenuItem>
            <MenuItem
              disabled={selectedInspection.case ? true : false}
              onClick={() => {
                createCase(selectedInspection && selectedInspection.id);
                setAnchorEl(null);
                setSelectedInspection(null);
              }}
            >
              {getText('create_case')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setModerateOpen(true);
              }}
            >
              {getText('moderate')}
            </MenuItem>
          </Menu>
        }
      </Grid>
      <Grid item>
        <InspectionPagination
          curPage={curPage}
          lastPage={lastPage}
          counts={counts}
          lineSize={lineSize}
          inspectionLoading={inspectionLoading}
        />
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>
          <Typography variant="body1">
            {getText('inspection_type')}
          </Typography>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              selectedInspection && updateInspectionAlgos(selectedInspection.id, selectedInspection.algos);
              setDialogOpen(false)
            }}
          >
            <Typography variant="body2" className={classes.label}>
              {getText('submit')}
            </Typography>
          </Button>
        </DialogTitle>
        <DialogContent>
          {caseTypes && selectedInspection ?
            <Grid container className={classes.algoOptions}>
              {caseTypes.map((type, index) => (
                <Grid item key={index} xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedInspection.algos[type.name] || false}
                        onChange={event => {
                          selectedInspection.algos[type.name] = event.target.checked
                          setSelectedInspection({
                            id: selectedInspection.id,
                            algos: selectedInspection.algos,
                            case: selectedInspection.case,
                            results: selectedInspection.results,
                          })
                        }}
                        color="primary"
                      />
                    }
                    label={getText(type.label)}
                  />
                </Grid>
              ))}
            </Grid> : <Loading />
          }
        </DialogContent>
      </Dialog>
      <Dialog open={moderateOpen} onClose={() => {setModerateOpen(false)}}>
        <DialogTitle>
          <Typography variant="body1">
            {getText('moderate')}
          </Typography>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              selectedInspection && updateInspectionResults(selectedInspection.id, selectedInspection.results);
              setModerateOpen(false)
            }}
          >
            <Typography variant="body2" className={classes.label}>
              {getText('submit')}
            </Typography>
          </Button>
        </DialogTitle>
        <DialogContent>
          {eventTypes && eventTypes[curSite] && selectedInspection ?
            <Grid container className={classes.algoOptions}>
              {(eventTypes[curSite] || []).map((type, index) => (
                <Grid item key={index} xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedInspection.results[type.value] || false}
                        onChange={event => {
                          selectedInspection.results[type.value] = event.target.checked
                          setSelectedInspection({
                            id: selectedInspection.id,
                            algos: selectedInspection.algos,
                            case: selectedInspection.case,
                            results: selectedInspection.results,
                          })
                        }}
                        color="primary"
                      />
                    }
                    label={getText(type.label)}
                  />
                </Grid>
              ))}
            </Grid> : <Loading />
          }
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default withStyles(styles)(InspectionList);
