import React from 'react';
import { withStyles, Toolbar, InputAdornment, TextField, IconButton, Select, MenuItem, Typography } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    flexGrow: 0,
    height: 30,
    padding: "0 10px",
  },
  textField: {
    padding: "0 10px",
    height: 30,
  },
  menuItem: {
    padding: 8,
    height: 30
  }
});

const SearchBar = props => {
  const { classes, queryId, setQuery, submitQuery, mapOptions, mapIndex, setMapIdx } = props;

  return (
    <Toolbar disableGutters className={classes.root} variant="dense">
      <Typography className={classes.title}>
        {getText('map_name')}
      </Typography>
      <Select
        className={classes.textField}
        inputProps={{style: {padding: 8}}}
        variant="outlined"
        value={mapIndex}
      >
        {
          mapOptions.map((map, index) => (
            <MenuItem
              className={classes.menuItem}
              key={index}
              value={index+1}
              onClick={() => setMapIdx(index+1)}
            >
              {`${map.name} ${map.id}`}
            </MenuItem>
          ))
        }
      </Select>
      <TextField
        className={classes.textField}
        variant="outlined"
        onChange={event => {
          setQuery(event.target.value)
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            submitQuery();
          }
        }}
        value={queryId}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
            >
              <IconButton
                onClick={submitQuery}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        //TODO: use style override in classes
        inputProps={{style: {padding: 6}}}
        placeholder={getText('map_id')}
      />
    </Toolbar>
  );
}

export default withStyles(styles)(SearchBar);