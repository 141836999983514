import React, {PureComponent} from 'react';
import {render} from 'react-dom';
import {makeStyles, createStyles, Snackbar, ThemeProvider} from '@material-ui/core';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import {theme} from 'src/App';

const useStyles = makeStyles(() =>
  createStyles({
    alert: {
      marginBottom: 10,
      paddingTop: 3,
      paddingBottom: 3,

      '&.MuiAlert-filledSuccess': {
        backgroundColor: '#323232',
      },
    },
  })
);

function Alert(props) {
  const classes = useStyles();
  return <MuiAlert elevation={6} variant="filled" className={classes.alert} {...props} />;
}



class MessageComponent extends PureComponent {
  container;
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      msgs: [],
    };
  }

  _removeMsg(id) {
    const {msgs} = this.state;
    const filtered = msgs.filter(v => v.id !== id);
    this.setState({
      open: filtered.length > 0,
      msgs: filtered,
    });
  }

  _addMsg(msg) {
    // this._init();
    const {msgs} = this.state;
    this.setState({
      open: true,
      msgs: [...msgs, msg],
    });

    const close = this._removeMsg.bind(this, msg.id);

    const hideTimeout = setTimeout(() => {
      close();
    }, msg.duration || 5000);

    return function closeManually() {
      clearTimeout(hideTimeout);
      close();
    };
  }

  success(content) {
    const id = `${Math.random()}`;
    const msg = {
      id,
      type: 'success',
      content,
    };
    return this._addMsg(msg);
  }

  error(content) {
    const id = `${Math.random()}`;
    const msg = {
      id,
      type: 'error',
      content,
    };
    return this._addMsg(msg);
  }

  warning(content) {
    const id = `${Math.random()}`;
    const msg = {
      id,
      type: 'warning',
      content,
    };
    return this._addMsg(msg);
  }

  info(content) {
    const id = `${Math.random()}`;
    const msg = {
      id,
      type: 'info',
      content,
    };
    return this._addMsg(msg);
  }

  render() {
    const {open, msgs} = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Snackbar open={open}>
          <div>
            {msgs.map(msg => (
              <Alert key={msg.id} severity={msg.type}>
                {msg.content}
              </Alert>
            ))}
          </div>
        </Snackbar>
      </ThemeProvider>
    );
  }
}

let messageInstance;

function init() {
  if (messageInstance) {
    return messageInstance;
  }
  const component = <MessageComponent />;

  const container = document.createElement('div');
  document.body.appendChild(container);
  const newMessageInstance = render(component, container);
  messageInstance = newMessageInstance;
  return messageInstance;
}

export default {
  success: function(content) {
    const message = init();
    return message.success(content);
  },
  error: function(content) {
    const message = init();
    return message.error(content);
  },
  warning: function(content) {
    const message = init();
    return message.warning(content);
  },
  info: function(content) {
    const message = init();
    return message.info(content);
  },
};
