import React from 'react';
import { connect } from 'react-redux';

import RoutineHistoryTime from './RoutineHistoryTime';

const mapStateToProps = state => {
  return {
    curSite: state.site.curSite,
  };
};

const mapDispatchToProps = dispatch => {
  return { };
};

class RoutineHistoryTimeContainer extends React.Component {
  render() {
    return (
      <>
        <RoutineHistoryTime/>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutineHistoryTimeContainer);