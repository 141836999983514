import { siteConstants } from "src/redux/constants";
import { filter } from "lodash";

const initialState = {
  sites: null,
  curSite: null,
  managers: [],
  managerPagination: {
    curPage: 1,
    count: 200,
  },
  robots: [],
  boxes: [],
  siteLoading: false,
  managerLoading: false,
  robotLoading: false,
  siteUpdating: false,
  managerUpdating: false,
  robotUpdating: false,
  boxLoading: false,
  boxUpdating: false,

  buildingLoading: false,
  buildingFilter: {
    page: 0,
    limit: 10,
  },
  buildingTotal: 0,
  buildings: [],
  floorLoading: {}, // Map<buildingId, boolean>
  floors: {}, // Map<buildingId, Floors>
  // 每个站点对应的所有楼层的信息，其中键为站点id，值为对应的所有楼层的列表
  siteAllFloors: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case siteConstants.GET_SITE_REQUEST:
      return {
        ...state,
        sites: null,
        siteLoading: true
      };
    case siteConstants.GET_SITE_SUCCESS:
      return {
        ...state,
        sites: action.list,
        siteLoading: false
      };
    case siteConstants.GET_SITE_FAILURE:
      return {
        ...state,
        siteLoading: false
      };
    case siteConstants.CREATE_SITE_REQUEST:
      return {
        ...state,
        siteUpdating: true
      };
    case siteConstants.CREATE_SITE_SUCCESS:
      return {
        ...state,
        siteUpdating: false
      };
    case siteConstants.CREATE_SITE_FAILURE:
      return {
        ...state,
        siteUpdating: false
      };
    case siteConstants.DELETE_SITE_REQUEST:
      return {
        ...state,
        siteUpdating: true
      };
    case siteConstants.DELETE_SITE_SUCCESS:
      return {
        ...state,
        siteUpdating: false
      };
    case siteConstants.DELETE_SITE_FAILURE:
      return {
        ...state,
        siteUpdating: false
      };
    case siteConstants.UPDATE_SITE_REQUEST:
      return {
        ...state,
        siteUpdating: true
      };
    case siteConstants.UPDATE_SITE_SUCCESS:
      return {
        ...state,
        siteUpdating: false
      };
    case siteConstants.UPDATE_SITE_FAILURE:
      return {
        ...state,
        siteUpdating: false
      };
    case siteConstants.ASSIGN_MANAGERS_REQUEST:
      return {
        ...state,
        siteUpdating: true
      };
    case siteConstants.ASSIGN_MANAGERS_SUCCESS:
      return {
        ...state,
        siteUpdating: false
      };
    case siteConstants.ASSIGN_MANAGERS_FAILURE:
      return {
        ...state,
        siteUpdating: false
      };
    case siteConstants.GET_MANAGER_REQUEST:
      return {
        ...state,
        managers: [],
        managerLoading: true
      };
    case siteConstants.GET_MANAGER_SUCCESS:
      return {
        ...state,
        managers: action.list,
        managerPagination: {
          curPage: action.curPage,
          count: action.count,
        },
        managerLoading: false
      };
    case siteConstants.GET_MANAGER_FAILURE:
      return {
        ...state,
        managerLoading: false
      };
    case siteConstants.CREATE_MANAGER_REQUEST:
      return {
        ...state,
        managerUpdating: true
      };
    case siteConstants.CREATE_MANAGER_SUCCESS:
      return {
        ...state,
        managerUpdating: false
      };
    case siteConstants.CREATE_MANAGER_FAILURE:
      return {
        ...state,
        managerUpdating: false
      };
    case siteConstants.DELETE_MANAGER_REQUEST:
      return {
        ...state,
        managerUpdating: true
      };
    case siteConstants.DELETE_MANAGER_SUCCESS:
      return {
        ...state,
        managerUpdating: false
      };
    case siteConstants.DELETE_MANAGER_FAILURE:
      return {
        ...state,
        managerUpdating: false
      };
    case siteConstants.GET_ROBOT_REQUEST:
      return {
        ...state,
        robotLoading: true
      };
    case siteConstants.GET_ROBOT_SUCCESS:
      return {
        ...state,
        robotLoading: false,
        robots: action.robots.sort(sortById)
      }
    case siteConstants.GET_ROBOT_FAILURE:
      return {
        ...state,
        robotLoading: false
      }
    case siteConstants.UPDATE_ROBOT_REQUEST:
      return {
        ...state,
        robotUpdating: true
      };
    case siteConstants.UPDATE_ROBOT_SUCCESS:
      return {
        ...state,
        robotUpdating: false
      };
    case siteConstants.UPDATE_ROBOT_FAILURE:
      return {
        ...state,
        robotUpdating: false
      };
    case siteConstants.GET_BOX_REQUEST:
      return {
        ...state,
        boxLoading: true
      };
    case siteConstants.GET_BOX_SUCCESS:
      return {
        ...state,
        boxLoading: false,
        boxes: action.list.sort(sortById)
      }
    case siteConstants.GET_BOX_FAILURE:
      return {
        ...state,
        boxLoading: false
      }
    case siteConstants.UPDATE_BOX_REQUEST:
      return {
        ...state,
        boxUpdating: true
      };
    case siteConstants.UPDATE_BOX_SUCCESS:
      return {
        ...state,
        boxUpdating: false
      };
    case siteConstants.UPDATE_BOX_FAILURE:
      return {
        ...state,
        boxUpdating: false
      };
    case siteConstants.SET_CUR_SITE:
      return {
        ...state,
        curSite: action.siteId
      };
    case siteConstants.GET_SITE_BUILDING_REQUEST:
      return {
        ...state,
        buildingLoading: true,
      };
    case siteConstants.GET_SITE_BUILDING_SUCCESS:
      return {
        ...state,
        buildingLoading: false,
        buildingFilter: action.filter,
        buildingTotal: action.total,
        buildings: action.list,
      };
    case siteConstants.GET_SITE_BUILDING_FAILURE:
      return {
        ...state,
        buildingLoading: false,
      };
    case siteConstants.GET_SITE_FLOOR_REQUEST:
      return {
        ...state,
        floorLoading: {
          ...state.floorLoading,
          [action.buildingId]: true,
        },
      };
    case siteConstants.GET_SITE_FLOOR_SUCCESS:
      return {
        ...state,
        floorLoading: {
          ...state.floorLoading,
          [action.buildingId]: false,
        },
        floors: {
          ...state.floors,
          [action.buildingId]: action.list,
        }
      };
    case siteConstants.GET_SITE_FLOOR_FAILURE:
      return {
        ...state,
        floorLoading: {
          ...state.floorLoading,
          [action.buildingId]: false,
        },
      };
    case siteConstants.SAVE_SITE_FLOORS:
      return {
        ...state,
        siteAllFloors: {
          ...state.siteAllFloors,
          [action.siteId]: action.floors
        }
      };
    default:
      return state;
  }
};

const sortById = (a, b) => {
  if (!a.id) return 1;
  if (!b.id) return -1;
  return a.id < b.id ? -1 : 1;
}
