import { messageConstants } from "src/redux/constants";

export const showNotification = (text, level) => {
  return (dispatch) => {
    dispatch({
      type: messageConstants.SHOW_NOTIFICATION,
      payload: {
        message: text,
        level,
      },
    });
  };
};

export const hideNotification = () => {
  return (dispatch) => {
    dispatch({
      type: messageConstants.HIDE_NOTIFICATION,
    });
  };
};
