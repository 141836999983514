import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import DropDownSelectorVertical from 'src/components/Selectors/DropDownSelectorVertical';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({});

const sizes = [
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 200, label: '200' },
];

const PageSizeSelector = props => {
  const { value, onChange } = props;
  return (
    <DropDownSelectorVertical
      title={getText('page_size')}
      options={sizes}
      value={value}
      onChange={onChange} />
  );
};

PageSizeSelector.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

PageSizeSelector.defaultProps = {
  value: 100,
  onChange: (event, value) => {}
};

export default withStyles(styles)(PageSizeSelector);
