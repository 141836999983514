import React from 'react';
import * as d3 from 'd3';
import * as d3_hexbin from 'd3-hexbin';
import { withStyles } from '@material-ui/core';

import { getText } from 'src/utils/MultilingualLoader';
import { reserveDecimal, getUnit } from 'src/utils/number';

const styles = theme => ({
  heatMap: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  tooltip: {
    position: 'absolute',
    textAlign: 'center',
    opacity: 0,
    backgroundColor: theme.palette.grey[200],
    pointerEvents: 'none'	
  },
  legend: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  legendRotated: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100px',
    transformOrigin: '0% 0%',
    transform: 'translateX(100px) rotate(90deg)'
  }
})

class SensorHeatMap extends React.Component {
  componentDidMount() {
    this.drawHeatMap();
  }

  componentDidUpdate() {
    d3.select('#sensor_heat_map').select('svg').remove();
    d3.select('#legend').select('svg').remove();
    this.drawHeatMap();
  }

  drawHeatMap() {
    const { width, height, radius, sensorType, sensorClusters, imageRotate, getImagePosition, getImageScale, zoomScale } = this.props;
    let points = [];
    let clusterData = [];

    sensorClusters.forEach(cluster => {
      const position = getImagePosition(cluster.position.x, cluster.position.y);
      points.push({x: position.x, y: position.y});
      const data = cluster.data && !isNaN(cluster.data[sensorType]) ? reserveDecimal(cluster.data[sensorType], 2) : null;
      clusterData.push(data);
    })
    
    let colors = sensorClusters.map(cluster => 'RGB(0, 0, 0, 0.4)');
    if (clusterData.length > 0) {
      let minValue = clusterData[0] || 9999;
      let maxValue = clusterData[0] || 0;
      clusterData.forEach(data => {
        if (data !== null) {
          minValue = Math.min(minValue, data);
          maxValue = Math.max(maxValue, data);
        }
      });
      const gap = maxValue - minValue;

      const legend = d3.select('#legend').append('svg');
      switch(sensorType) {
        case 'localization_score':
          colors = clusterData.map(data => {
            if (data === null) return 'RGB(0, 0, 0, 0.4)';
            if (data < 0.5) return 'RGB(209, 25, 25, 0.4)';
            if (data < 0.65) return 'RGB(209, 86, 25, 0.4)';
            if (data < 0.8) return 'RGB(209, 209, 25, 0.4)';
            else return 'RGB(148, 209, 25, 0.4)';
          });
          legend.append('rect').attr('x', 5).attr('y', 5).attr('width', 20).attr('height', 10).style('fill', 'RGB(209, 25, 25, 0.4)');
          legend.append('text').attr('x', 30).attr('y', 5).attr('alignment-baseline', 'hanging')
            .text('< 0.5');
          legend.append('rect').attr('x', 5).attr('y', 20).attr('width', 20).attr('height', 10).style('fill', 'RGB(209, 86, 25, 0.4)');
          legend.append('text').attr('x', 30).attr('y', 20).attr('alignment-baseline', 'hanging')
            .text('0.5 - 0.65');
          legend.append('rect').attr('x', 5).attr('y', 35).attr('width', 20).attr('height', 10).style('fill', 'RGB(209, 209, 25, 0.4)');
          legend.append('text').attr('x', 30).attr('y', 35).attr('alignment-baseline', 'hanging')
            .text('0.65 - 0.8');
          legend.append('rect').attr('x', 5).attr('y', 50).attr('width', 20).attr('height', 10).style('fill', 'RGB(148, 209, 25, 0.4)');
          legend.append('text').attr('x', 30).attr('y', 50).attr('alignment-baseline', 'hanging')
            .text('> 0.8');
          break;
        default: 
          colors = clusterData.map(data => {
            if (data === null) return 'RGB(0, 0, 0, 0.4)';
            let rank = gap === 0 ? maxValue : (data - minValue) / gap;
            if (rank <= 1 / 4) return 'RGB(148, 209, 25, 0.4)';
            if (rank <= 1 / 2) return 'RGB(209, 209, 25, 0.4)';
            if (rank <= 3 / 4) return 'RGB(209, 86, 25, 0.4)';
            else return 'RGB(209, 25, 25, 0.4)';
          });
          legend.append('rect').attr('x', 5).attr('y', 5).attr('width', 20).attr('height', 10).style('fill', 'RGB(148, 209, 25, 0.4)');
          legend.append('text').attr('x', 30).attr('y', 5).attr('alignment-baseline', 'hanging')
            .text(minValue.toFixed(2) + ' - ' + (minValue + gap / 4).toFixed(2) + ' ' + getUnit(sensorType));
          legend.append('rect').attr('x', 5).attr('y', 20).attr('width', 20).attr('height', 10).style('fill', 'RGB(209, 209, 25, 0.4)');
          legend.append('text').attr('x', 30).attr('y', 20).attr('alignment-baseline', 'hanging')
            .text((minValue + gap / 4).toFixed(2) + ' - ' + (minValue + gap * 2 / 4).toFixed(2) + ' ' + getUnit(sensorType));
          legend.append('rect').attr('x', 5).attr('y', 35).attr('width', 20).attr('height', 10).style('fill', 'RGB(209, 86, 25, 0.4)');
          legend.append('text').attr('x', 30).attr('y', 35).attr('alignment-baseline', 'hanging')
            .text((minValue + gap * 2 / 4).toFixed(2) + ' - ' + (minValue + gap * 3 / 4).toFixed(2) + ' ' + getUnit(sensorType));
          legend.append('rect').attr('x', 5).attr('y', 50).attr('width', 20).attr('height', 10).style('fill', 'RGB(209, 25, 25, 0.4)');
          legend.append('text').attr('x', 30).attr('y', 50).attr('alignment-baseline', 'hanging')
            .text((minValue + gap * 3 / 4).toFixed(2) + ' - ' + maxValue.toFixed(2) + ' ' + getUnit(sensorType));
        }
    }
    
    // convert inscribed radius to circumradius
    const hexgonRadius = getImageScale(radius/Math.sin(Math.PI/3));
    const hexbin = d3_hexbin.hexbin().radius(hexgonRadius);
    const svg = d3.select('#sensor_heat_map')
      .append('svg')
      .attr('width', width)
      .attr('height', height);
    const tooltip = d3.select('#tooltip');
    try {
      svg.append('g')
        .selectAll('.hexagon')
        .data(points)
        .enter().append('path')
        .attr('class', 'hexagon')
        .attr('d', function (d) {
          return 'M' + d.x + ',' + d.y + hexbin.hexagon();
        })
        .style('fill', function (d, i) { return colors[i]; })
        .on('mouseover', function (d, i) {
          tooltip.transition()
            .duration(200)
            .style('opacity', .9);
          tooltip.html(getText(sensorType) + ': ' + (clusterData[i] !== null ? clusterData[i] + ' ' + getUnit(sensorType) : getText('unknown')))
            .style('left', d.x+'px')
            .style('top', d.y+'px')
            .style('padding', 16/zoomScale+'px')
            .style('border-radius', 5/zoomScale+'px')
            .style('font-size', 14/zoomScale+'px')
            .style('transform', imageRotate ? 'rotate(90deg)' : '')
            .style('transform-origin', '0% 0%');
        })
        .on('mouseout', function (d, i) {
          tooltip.transition()
            .duration(500)
            .style('opacity', 0);
        });
      } catch (err) {}
  }

  render() {
    const { classes, imageRotate } = this.props;
    return (
      <>
        <div
          className={!imageRotate ? classes.legend : classes.legendRotated}
          id='legend'
        />
        <div
          className={classes.heatMap}
          id='sensor_heat_map'
        >
          <div 
            className={classes.tooltip}
            id='tooltip'
          />
        </div>
      </>
    );
  }
}

export default withStyles(styles)(SensorHeatMap);