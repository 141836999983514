import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';

import { webRTCWhiteList } from 'src/utils/webRTCWhiteList';
import WebRTCContainer from 'src/pages/Main/Player/WebRTCPlayer/WebRTCContainer';
import NearbyCameraPlayerContainer from './NearbyCameraPlayerContainer';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  card: {
    position: 'relative',
    height: '100%'
  },
  list: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    overflowY: 'scroll',
    overflowX: 'scroll'
  },
  menuItem: {
    paddingTop: 0,
    minHeight: 20,
    width: 'max-content'
  },
  checkbox: {
    width: '24',
    padding: '0 12px 0 12px'
  },
  itemText: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 0,
  },
});

const cameraContent = camera => {
  const name = camera.name;
  const status = camera.robotStatus || camera.cameraState;
  return `${name}  (${getText(status)})`;
};

const NearbyCameras = props => {
  const { classes, cameras, selectedCameras, handleCameraSelect, nearbyCameraHeight } = props;
  const [downGrade, setDownGrade] = useState({});
  const selected = cameras.filter(
    (camera) => selectedCameras[camera.id]
  );
  // camera height plus label height
  const cameraListHeight = nearbyCameraHeight + 41;
  return (
    <Grid
      container={true}
      justify="flex-start"
      direction="row"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item={true} xs={6} lg={3}>
        <Card
          style={{minHeight: cameraListHeight}}
          square={true}
          className={classes.card}
        >
          <List className={classes.list}>
            {
              cameras.map((camera, index) => (
                <MenuItem
                  disableGutters
                  dense={true}
                  className={classes.menuItem}
                  key={camera.id}
                  button={false}
                >
                  <Checkbox
                    className={classes.checkbox}
                    disabled={selected.length === 3 && selected.filter(item => item.id === camera.id).length === 0}
                    checked={selectedCameras[camera.id] ? selectedCameras[camera.id] : false}
                    color="primary"
                    onChange={(e, checked) => {
                      handleCameraSelect(camera, checked);
                    }} />
                  <ListItemText
                    disableTypography={true}
                    className={classes.itemText}
                    primary={
                      <div>
                        <Typography variant="body2">
                          {cameraContent(camera)}
                        </Typography>
                      </div>
                    }
                  />
                </MenuItem>
              ))
            }
          </List>
        </Card>
      </Grid>
      {
        selected.map((camera, index) => (
          <Grid item={true} xs={6} lg={3} key={camera.id}>
            {
            camera.type === 'robot' && !downGrade[camera.id] && webRTCWhiteList 
            && (webRTCWhiteList.length === 0 || webRTCWhiteList.includes(camera.id)) ?
              <WebRTCContainer
              camera={camera} 
              cameraHeight={nearbyCameraHeight} 
              downGrade={() => setDownGrade({
                ...downGrade,
                [camera.id]: true
              })} 
            />
            : <NearbyCameraPlayerContainer camera={camera} nearbyCameraHeight={nearbyCameraHeight} />
          }
          </Grid>
        ))
      }
    </Grid>
  );
};

NearbyCameras.propTypes = {
  classes: PropTypes.object.isRequired,
};

NearbyCameras.defaultProps = {
};

export default withStyles(styles)(NearbyCameras);
