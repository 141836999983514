import {stringify} from 'qs';
import {event_api} from 'src/services/Vision/api';
// import event2Instance from '../axios/api/event2';
// import {S3Token, S3Object} from '../../models/s3';
// import {isUsingEvent2} from 'src/redux/actions/org';
// import {listAdapter, trackingObjectOptionsAdapter} from './adapter';
// import {trackingObjectApiDataForEvent2Adapter, trackingObjectEvent2Adapter} from './event2Adapter';


export const listAdapter = {
  toServer: (filter) => {
    const site_id = filter.siteId === 'all' ? undefined : filter.siteId;
    return {
      search: filter.search,
      identifiers: filter.identifiers ? filter.identifiers.toString() : undefined,
      site: site_id,
      camera_id: filter.cameraId,
      has_name: typeof filter.unnamed === 'boolean' ? !filter.unnamed : undefined,
      person_role: filter.person_role || undefined,
      offset: (filter.page - 1) * filter.limit,
      limit: filter.limit,
      ordering: filter.ordering || '-latest_index_face_time',
      is_contact: filter.isContact ? true : undefined,
      is_flag: filter.isFlag ? true : undefined,
      latest_index_face_time__gt: filter.gt
        ? filter.gt
            .clone()
            .utc()
            .format()
        : undefined,
      latest_index_face_time__lt: filter.lt
        ? filter.lt
            .clone()
            .utc()
            .format()
        : undefined,
    };
  },
  toClient: (res, identifiers) => ({
    ...res,
    results:
      (res.results ? (res.results.map((employee) => ({
        employeeId: (identifiers ? identifiers.includes('person_id') : undefined) ? (employee.data ? employee.data.person_id : undefined) : employee.rfid,
        ...employee,
      }))) : undefined) || [],
  }),
};

export const trackingObjectOptionsAdapter = {
  toClient: (res, identifiers) =>
    (res.results ? (res.results.map((employee) => {
      const employeeId = identifiers ? identifiers.includes('person_id') : undefined
        ? (employee.data ? employee.data.person_id : undefined)
        : employee.rfid;
      return {
        tracking_object_name: employee.data ? employee.data.person_name : undefined,
        tracking_object_id: employeeId,
        employeeId,
        ...employee,
      };
    })) : undefined) || [],
};

export async function getTrackingTargets(params) {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL ||
      ''}/api/v1/face/tracking_objects?${stringify(listAdapter.toServer(params))}`;
    const response = await event_api.get(url);
    return listAdapter.toClient(response, params.identifiers || []);
  } catch (e) {
    throw e;
  }
}

export async function getTrackingTargetById(id) {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL || ''}/api/v1/face/tracking_objects/${id}`;
    const response = await event_api.get(url);
    return listAdapter.toClient(response, []);
  } catch (e) {
    throw e;
  }
}

export async function updateTrackingTarget(data) {
  const {id, ...otherData} = data;
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL ||
      ''}/api/v1/face/tracking_objects/${id ? id.replace(/-/g, '') : undefined}`;
    const response = await event_api.put(url, otherData);
    return response;
  } catch (e) {
    throw e;
  }
}

export async function getS3Token() {
  try {
    const url = `/api/v1/face/tracking_objects/get_token`;
    const response = await event_api.get(url);
    return response;
  } catch (e) {
    throw e;
  }
}


export async function getFaces(params){
  try {
    const url = `/api/v1/face/tracking_objects/${params.tracking_target_id}/list_faces`;
    const response = await event_api.get(url);
    return response;
  } catch (e) {
    throw e;
  }
}



export async function getTrackingObjectOptions(
  keyword,
  identifiers = [],
  params,
) {
  try {
    const url = `/api/v1/face/tracking_objects?has_name=true&search=${keyword}&identifiers=${identifiers}${
      params ? `&${stringify(params || {})}` : ''
    }`;
    const response = await event_api.get(url);
    return trackingObjectOptionsAdapter.toClient(response.data.ret, identifiers);
  } catch (e) {
    throw e;
  }
}

// export async function deleteTrackingTarget(id){
//   try {
//     const useEvent2 = isUsingEvent2();
//     const url = useEvent2
//       ? `/bw/v1/tracking_object/${id.replace(/-/g, '')}`
//       : `/api/v1/face/tracking_objects/${id.replace(/-/g, '')}`;
//     const response = await (useEvent2 ? event2Instance : apiInstance).delete(url);
//     return response;
//   } catch (e) {
//     throw e;
//   }
// }



export async function searchFace(params){
  try {
    const url = `/api/v1/face/tracking_objects/search_face`;
    const response = await event_api.post(url, params);
    return response;
  } catch (e) {
    throw e;
  }
}

// formdata key "file"
export async function importTrackingUsers(data) {
  try {
    const url = `/api/v1/face/tracking_objects/import_users`;
    const response = await event_api.post(url, data);
    return response;
  } catch (e) {
    throw e;
  }
}
