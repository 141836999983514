import React from "react";
import { connect } from "react-redux";
import {
  Grid,
  withStyles,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";

import { robotActions, notificationActions } from "src/redux/actions";
import { getText } from "src/utils/MultilingualLoader";
import { updateFullRoute } from "src/services/robot";
import styles from "./styles";

const mapStateToProps = (state, props) => {
  const { robotMapLists } = state.robot;
  const { selected } = props;
  const robotMapList = selected && robotMapLists[selected.id];
  return { robotMapList };
};

const mapDispatchToProps = {
  notify: (msg_text) => notificationActions.addNotification(msg_text),
  getRobotMapList: (robotId) =>
    robotActions.getRobotConfigMapsAndRoutes(robotId),
};

class RobotFullRouteContainer extends React.Component {
  state = {
    routes: [],
    floorMaps: [],
  };

  componentDidUpdate(prevProps) {
    const { robotMapList, selected, getRobotMapList } = this.props;

    if (
      robotMapList &&
      !robotMapList.loading &&
      selected &&
      selected.id &&
      (!prevProps.selected || prevProps.selected.id !== selected.id)
    ) {
      getRobotMapList(selected.id);
    }

    if (
      robotMapList !== prevProps.robotMapList &&
      robotMapList &&
      !robotMapList.loading
    ) {
      this.updateFloors();
    }
  }

  updateFloors = () => {
    const { robotMapList } = this.props;
    let maps = (robotMapList && robotMapList.maps || []).filter(({name}) => name && /^(\d{1,3}F|B\d)/.test(name));
    // if (configs.connecting_floors && configs.connecting_floors.length > 0) {
    //   const zones = Array.from(
    //     new Set(
    //       (configs.connecting_floors || [])
    //         .map((item) => item.parts || [])
    //         .flat()
    //         .map((item) => item.zone_id)
    //     )
    //   );
    //   maps =
    //     robotMapList && robotMapList.maps
    //       ? robotMapList.maps.filter((map) => zones.includes(map.zoneId))
    //       : [];
    // } else {
    //   maps =
    //     robotMapList && robotMapList.maps
    //       ? robotMapList.maps.filter((map) => /^(\dF|B\d)/.test(map.name))
    //       : [];
    // }
    maps = maps.map((map) => ({
      name: map.name,
      id: map.id,
      zoneId: map.zoneId,
      floor: map.floor,
      fullRouteLength: map.mapRoutes.filter((route) => route.type === "all")
        .length,
      routes: map.mapRoutes.map((route) => ({
        id: route.id,
        name: route.name,
        type: route.type,
      })),
    }));
    maps.sort((prev, next) => {
      if (prev.zoneId < next.zoneId) {
        return -1;
      }
      if (prev.zoneId > next.zoneId) {
        return 1;
      }
      if (prev.floor < next.floor) {
        return -1;
      }
      if (prev.floor > next.floor) {
        return 1;
      }
      return 0;
    });
    this.setState({
      routes: maps.map((map) => {
        const route = map.routes.find((route) => route.type === "all");
        return route && route.id;
      }),
      floorMaps: maps,
    });
  };

  handleChange = (index) => (e) => {
    this.setState({
      routes: this.state.routes.map((routeId, i) =>
        i === index ? e.target.value : routeId
      ),
    });
  };

  handleRefresh = () => {
    const { selected, getRobotMapList } = this.props;
    if (selected && selected.id) {
      getRobotMapList(selected.id);
    }
  };

  handleConfirm = () => {
    const { selected, getRobotMapList, notify } = this.props;
    const { floorMaps, routes } = this.state;
    if (selected && selected.id) {
      updateFullRoute(selected.id, {
        full_routes: floorMaps
          .map((map, index) => ({
            map_id: map.id,
            route_id: routes[index],
          }))
          .filter((item) => Boolean(item.route_id)),
      }).then(() => {
        notify(
          `${selected && selected.name}${getText("words_space")}${getText(
            "set_success"
          )}`
        );
        getRobotMapList(selected.id);
      });
    }
  };

  render() {
    const { classes, robotMapList } = this.props;
    const { floorMaps, routes } = this.state;

    return (
      <Grid>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell} padding="default">
                <Typography variant="body2">{getText("floor")}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} padding="default">
                <Typography variant="body2">{getText("route_id")}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} padding="default">
                <Typography variant="body2">{getText("full_route")}</Typography>
              </TableCell>
            </TableRow>
            {floorMaps.map((map, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableCell} padding="default">
                  <Typography variant="body2">
                    {map.zoneId}
                    {map.zoneId ? ", " : ""}
                    {map.name}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} padding="default">
                  <Select
                    style={{ width: 200 }}
                    value={routes[index] ? routes[index] : ""}
                    onChange={this.handleChange(index)}
                  >
                    {map.routes.map((route, i) => (
                      <MenuItem
                        key={i}
                        className={classes.menuItem}
                        value={route.id}
                      >{`${route.name} ${route.id}`}</MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell className={classes.tableCell} padding="default">
                  <Typography
                    variant="body2"
                    color={map.fullRouteLength === 1 ? "initial" : "secondary"}
                  >
                    {map.fullRouteLength}
                    {getText("comma")}
                    {map.fullRouteLength !== 1
                      ? getText("abnormal")
                      : getText("normal")}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell
                className={classes.tableCell}
                padding="default"
              ></TableCell>
              <TableCell className={classes.tableCell} padding="default">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleRefresh}
                  disabled={robotMapList && robotMapList.loading}
                >
                  <Typography variant="body2" className={classes.buttonText}>
                    {getText("refresh")}
                  </Typography>
                </Button>
              </TableCell>
              <TableCell className={classes.tableCell} padding="default">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleConfirm}
                >
                  <Typography variant="body2" className={classes.buttonText}>
                    {getText("ok")}
                  </Typography>
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RobotFullRouteContainer));
