import { getText } from 'src/utils/MultilingualLoader';

export const algoConfig = {
  '店铺': {
    types: ['region', 'door_regions', 'logo_regions'],
    region: {
      type: 'whole_store',
      key: 'region',
      multiple: false,
      status: [{
        label: 'light_on',
        value: 'light_on',
      }]
    },
    door_regions: {
      type: 'door',
      key: 'door_regions',
      multiple: true,
      status: [{
        label: 'open',
        value: 'open',
      }]
    },
    logo_regions: {
      type: 'logo',
      key: 'logo_regions',
      multiple: true,
      status: [{
        label: 'light_on',
        value: 'light_on',
      }]
    }
  },
  '多经': {
    types: ['region', 'highlight_regions'],
    region: {
      type: 'whole_store',
      multiple: false,
      key: 'region',
      status: [{
        label: 'light_on',
        value: 'light_on',
      }]
    },
    highlight_regions: {
      type: 'highlight',
      multiple: true,
      key: 'highlight_regions',
      status: [{
        label: 'light_on',
        value: 'light_on',
      }]
    },
  },
  '吊幔': {
    types: ['regions'],
    regions: {
      type: 'hanging_poster',
      multiple: true,
      key: 'regions',
      status: [{
        label: 'normal',
        value: 'normal',
      }, {
        label: 'not_exist',
        value: 'not_exist',
      }]
    },
  },
  '灯箱': {
    types: ['regions'],
    regions: {
      type: 'lightbox',
      multiple: true,
      key: 'regions',
      status: [{
        label: 'light_on',
        value: 'light_on',
      }, {
        label: 'light_off',
        value: 'light_off',
      }]
    },
  },
  '电子元件': {
    types: ['regions'],
    regions: {
      type: 'electrical',
      multiple: true,
      key: 'regions',
      status: [{
        label: '开关',
        value: 'switch',
      }, {
        label: '指示灯',
        value: 'light',
      }, {
        label: '数字面板',
        value: 'digit',
      }]
    },
    hideType: true,
    label1: getText('name'),
    label2: getText('detection_targets'),
    tooltip: '同一排电子元件以英文逗号分隔，非同一排元件使用回车键分隔',
  },
}

export function convert(type, data) {
  if (!algoConfig[type]) {
    throw new Error('Type Not Exist');
  }

  return data.map(ref => {
    const { url, inspection_id, regions } = ref;
    const params = {
      url,
      inspection_id,
    };
    if (type === '店铺' || type === '多经') {
      params.region = regions.find(item => item.type === 'whole_store');
    };
    if (type === '店铺') {
      params.door_regions = regions.filter(item => item.type === 'door');
      params.logo_regions = regions.filter(item => item.type === 'logo');
    } else if (type === '多经') {
      params.highlight_regions = regions.filter(item => item.type === 'highlight');
    } else if (type === '电子元件') {
      params.regions = regions.map(v => ({
        type: v.state,
        points: v.points,
        labels: (v.id || '').split('\n').map(line => line.split(',')),
      }));
    } else {
      params.regions = regions;
    }
    return params;
  });
}
