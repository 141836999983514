import React from 'react';
import {makeStyles} from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Check as CheckIcon,
  FiberManualRecord as CircleIcon,
} from '@material-ui/icons';
import clsx from 'clsx';

import {ReactComponent as CarIcon} from 'src/assets/Vision/unknown-car.svg';
import {ReactComponent as HeavyTruckIcon} from 'src/assets/Vision/heavy-truck.svg';
import {ReactComponent as VanIcon} from 'src/assets/Vision/van.svg';
import {ReactComponent as MPVIcon} from 'src/assets/Vision/mpv.svg';
import {ReactComponent as SUVIcon} from 'src/assets/Vision/suv.svg';
import {ReactComponent as PickupTruckIcon} from 'src/assets/Vision/pickup-truck.svg';
import {ReactComponent as SedanIcon} from 'src/assets/Vision/sedan.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    marginRight: theme.spacing(1.25),
    // marginLeft: theme.spacing(0.5),
    fontSize: 10,
    cursor: 'pointer',
    color: (theme.palette).other.secondary,
  },
  tip: {
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.5),
    width: theme.spacing(2.75),
    height: theme.spacing(2.75),
  },
  tipCircle: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    marginTop: theme.spacing(0.5),
  },
  tipWhiteBorder: {
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    border: '1px solid #d0d0d0',
    borderRadius: '50%',
    marginBottom: theme.spacing(0.75),
  },
  whiteBorder: {
    '& circle': {
      stroke: '#d0d0d0',
    },
  },
  hoverIcon: {
    position: 'absolute',
    boxSizing: 'border-box',
    padding: 2,
    width: theme.spacing(2.75),
    height: theme.spacing(2.75),
    marginTop: theme.spacing(-0.875),
  },
  typeColor: {
    color: '#bdbdbd',
    marginTop: theme.spacing(1),

    '&$active': {
      color: '#00001e',
    },
  },
  active: {
    color: (theme.palette).other.primary,
  },
}));

const hoverKeys = ['silver', 'white', 'yellow'];

const TypeIcon = ({type, className}) => {
  switch (type) {
    case 'Van / Campervan':
      return <VanIcon className={className} />;
    case 'Heavy truck':
      return <HeavyTruckIcon className={className} />;
    case 'MPV':
      return <MPVIcon className={className} />;
    case 'SUV':
      return <SUVIcon className={className} />;
    case 'Sedan / Hatchback':
      return <SedanIcon className={className} />;
    case 'Pickup truck':
      return <PickupTruckIcon className={className} />;
    default:
      return <CarIcon className={className} />;
  }
};

const Label = ({text, color, selected, onClick}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, selected && classes.active)} onClick={onClick}>
      {color &&
        (selected ? (
          <>
            <CheckCircleIcon
              style={{color}}
              className={clsx(classes.tip, text === 'White' && classes.tipWhiteBorder)}
            />
            {hoverKeys.includes(text.toLowerCase()) && <CheckIcon className={classes.hoverIcon} />}
          </>
        ) : (
          <CircleIcon
            style={{color}}
            className={clsx(classes.tipCircle, text === 'White' && classes.whiteBorder)}
          />
        ))}
      {!color && (
        <TypeIcon type={text} className={clsx(classes.typeColor, selected && classes.active)} />
      )}
      {text}
    </div>
  );
};

export default Label;
