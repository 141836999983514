import React from 'react';

import { connect } from 'react-redux';
import { cameraActions } from 'src/redux/actions';
import CameraFilter from './CameraFilter';


const mapStateToProps = state => {
  const { filters, loading } = state.camera;
  const { role } = state.user;
  return { filters, loading, role };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilter: filters => {
      dispatch(cameraActions.setFilter(filters));
    },
    getCameras: filters => {
      dispatch(cameraActions.getCameras(filters));
    }
  };
};

class CameraFilterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      robotStatuses: 'all',
      cameraStates: 'all'
    };
  }

  handleDialogOpen = () => {
    this.setState({
      openDialog: true,
      robotStatuses: this.props.filters.robotStatuses,
      cameraStates: this.props.filters.cameraStates,
    });
  };

  handleDialogClose = event => {
    this.setState({ openDialog: false });
  };

  handleRobotStatusChange = (e) => {
    this.setState({ robotStatuses: e.target.value});
  };

  handleCameraStateChange = (e) => {
    this.setState({ cameraStates: e.target.value});
  };

  handleFilterSave = event => {
    const { openDialog, ...filters } = this.state;
    const globalFilters = this.props.filters;
    this.props.setFilter(filters);
    if (!this.props.loading) this.props.getCameras(Object.assign({}, globalFilters, filters));
    this.setState({ openDialog: false });
  };

  render() {
    return (
      <CameraFilter
        robotStatuses={this.state.robotStatuses}
        cameraStates={this.state.cameraStates}
        role={this.props.role}
        openDialog={this.state.openDialog}
        onOpenDialog={this.handleDialogOpen}
        onCloseDialog={this.handleDialogClose}
        onSaveFilter={this.handleFilterSave}
        onChangeRobotStatus={this.handleRobotStatusChange}
        onChangeCameraState={this.handleCameraStateChange}
      />
    );
  }
}

CameraFilterContainer.propTypes = {
};

CameraFilterContainer.defaultProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CameraFilterContainer);
