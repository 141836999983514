import React from 'react';
import { connect } from 'react-redux';

import { siteActions } from 'src/redux/actions';
import AddSite from './AddSite';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    createSite: data => {
      dispatch(siteActions.createSite(data));
    }
  };
};

class AddSiteContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      name: '',
      location: '',
      note: '',
      polygons: [],
      error: {
        name: false
      },
      mapOpen: false
    };
  }

  handleSubmit = event => {
    const { name, location, note, polygons } = this.state;
    if (name === ''){
      this.setState({
        error: {
          name: true
        }
      });
      alert(getText('input_error_name'));
      return;
    }
    this.props.createSite({
      name,
      location,
      note,
      polygons
    });
  }

  handleChange = prop => event => {
    this.setState({
      [prop]: event.target.value,
      error: {
        [prop]: false
      }
    });
  }

  setPolygons = polygons => {
    this.setState({
      polygons
    });
  }

  handleMapOpen = event => {
    this.setState({
      mapOpen: true
    })
  }

  handleMapClose = event => {
    this.setState({
      mapOpen: false
    })
  }

  render() {
    const { name, location, note, error, mapOpen, polygons } = this.state;
    return (
      <AddSite
        name={name}
        location={location}
        note={note}
        error={error}
        mapOpen={mapOpen}
        polygons={polygons}
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
        onMapOpen={this.handleMapOpen}
        onMapClose={this.handleMapClose}
        setPolygons={this.setPolygons}
      />
    );
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSiteContainer);
