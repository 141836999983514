import React from "react";

import {
  Grid,
  CircularProgress,
  withStyles,
  Button,
  Tooltip,
  ListItemIcon,
  Typography,
  Backdrop,
} from "@material-ui/core";
import {
  PlayArrow as StartIcon,
  Pause as StopIcon,
  PhoneInTalk as TwoWayAudioIcon,
  NotificationsActive as FireAlarmIcon,
  ThreeSixty as ThreeSixtyDegreeTurnIcon,
  Gamepad as MicroControlIcon,
  Home as HomeIcon,
  Navigation as LeaveHomeIcon,
  ViewArray as ElevatorIcon,
  PlaylistAddCheck as RoutineStatusIcon,
  Stop as StopIcon2,
  Loop as RestartIcon,
} from "@material-ui/icons";
import RobotIcon from "src/components/icons/RobotIcon";
import MapOperationDialog from "./MapOperationDialog";
import MicroControlDialog from "./MicroControlDialog";
import TakeElevatorDialog from "./TakeElevatorDialog";
import RobotPatrolQueue from "../RobotPatrolQueue";
import ExpansionPanel from "../ExpansionPanel";
import Tabs from "../Tabs";
import { getText } from "src/utils/MultilingualLoader";

const styles = (theme) => ({
  buttonContainer: {
    height: "100%",
  },
  button: {
    background: "rgba(76,127,114,0.40)",
    borderRadius: "6px",
    border: "1px solid",
    minWidth: 172,
  },
  button_disabled: {
    background: "#CCCCCC",
    borderRadius: "6px",
    border: "1px solid",
    minWidth: 172,
  },
  label: {
    fontSize: "0.875rem",
    textTransform: "initial",
  },
  buttonIcon: {
    minWidth: 0,
    marginRight: 10,
  },
  tooltip: {
    fontSize: "0.875rem",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  notifyBackdrop: {
    background: "transparent",
    zIndex: theme.zIndex.drawer + 1,
  },
  notifyMsg: {
    color: "white",
    width: 400,
    padding: 30,
    backgroundColor: "grey",
    borderRadius: 8,
  },
});

class Actions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openMapOperationDialog: false,
      openMicroControlDialog: false,
      openTakeElevatorDialog: false,
    };
  }

  handleActionToggle = (name) => (event) => {
    const prev_state = this.state[name] || false;
    this.setState({ [name]: !prev_state });
  };

  handleActionClose = (name) => (event) => {
    this.setState({ [name]: false });
  };

  getPatrolLabel = (mode) => {
    switch (mode) {
      case "idle":
        return getText("start_patrol");
      case "patrol":
        return getText("stop_patrol");
      case "reaction":
        return getText("stop_action");
      default:
        return getText("start_patrol");
    }
  };

  render() {
    const {
      classes,
      setPanelContent,
      mode,
      selected,
      loading,
      notifyMsg,
      disableElevatorCommand,
      disableTakeElevator,
      onPlaySound,
      onRobotScan,
      onStartMicroControl,
      onStartPatrol,
      onStopAction,
      onGoHome,
      onLeaveHome,
      onTakeElevator,
      onElevatorCommand,
      onResumeRoutine,
      onHandleNimbo,
    } = this.props;

    const actionList = [
      // {
      //   label: getText("patrol_queue"),
      //   content: <RobotPatrolQueue selected={selected} />,
      //   actions: [
      //     // {
      //     //   label: "Call Police",
      //     //   propName: "openPoliceDialog",
      //     //   icon: <CallPoliceIcon />,
      //     //   disabled: true,
      //     //   tooltip: 'under development'
      //     // },
      //     // {
      //     //   label: getText("resume_routine"),
      //     //   propName: "resume_routine",
      //     //   onClick: onResumeRoutine,
      //     //   icon: <StartIcon />,
      //     // },
      //     // {
      //     //   label: getText("routine_status"),
      //     //   propName: "routine_status",
      //     //   onClick: () => setPanelContent("routine_status"),
      //     //   icon: <RoutineStatusIcon />,
      //     // },
      //   ],
      // },
      {
        label: getText("patrol_actions"),
        actions: [
          {
            label: this.getPatrolLabel(mode),
            propName: "openPatrolDialog",
            onClick: mode === "patrol" ? onStopAction : onStartPatrol,
            icon: mode === "patrol" ? <StopIcon /> : <StartIcon />,
          },
          {
            label: getText("idle"),
            propName: "",
            onClick: onStopAction,
            icon: <StopIcon2 />,
          },
          {
            label: getText("go_home"),
            propName: "go_home",
            onClick: onGoHome,
            icon: <HomeIcon />,
          },
          {
            label: getText("leave_home"),
            propName: "leave_home",
            onClick: onLeaveHome,
            icon: <LeaveHomeIcon />,
          },
        ],
      },
      {
        label: getText("nimbo_actions"),
        actions: [
          {
            label: getText("start_nimbo"),
            propName: "start_nimbo",
            onClick: () => onHandleNimbo("robot.systemctl_start"),
            icon: <StartIcon />,
          },
          {
            label: getText("stop_nimbo"),
            propName: "stop_nimbo",
            onClick: () => onHandleNimbo("robot.systemctl_stop"),
            icon: <StopIcon />,
          },
          {
            label: getText("restart_nimbo"),
            propName: "restart_nimbo",
            onClick: () => onHandleNimbo("robot.systemctl_restart"),
            icon: <RestartIcon />,
          },
        ],
      },
      {
        label: getText("elevator_actions"),
        actions: [
          {
            label: getText("take_elevator"),
            propName: "openTakeElevatorDialog",
            icon: <ElevatorIcon />,
            disabled: disableTakeElevator,
            tooltip: disableTakeElevator && getText("check_route"),
          },
          {
            label: getText("recall_elevator"),
            propName: "recall_elevator",
            onClick: onElevatorCommand("ReCallLift"),
            // disabled: disableElevatorCommand,
            icon: <ElevatorIcon />,
          },
          {
            label: getText("cancel_elevator"),
            propName: "cancel_elevator",
            onClick: onElevatorCommand("CancelLift"),
            // disabled: disableElevatorCommand,
            icon: <ElevatorIcon />,
          },
        ],
      },
      {
        label: getText("robot_actions"),
        actions: [
          {
            label: getText("micro_control"),
            propName: "openMicroControlDialog",
            icon: <MicroControlIcon />,
          },
          {
            label: getText("360_degree_turn"),
            propName: "openTurnDialog",
            onClick: onRobotScan,
            icon: <ThreeSixtyDegreeTurnIcon />,
          },
          {
            label: getText("two_way_audio"),
            propName: "openTwowayDialog",
            icon: <TwoWayAudioIcon />,
            disabled: true,
            tooltip: "under development",
          },
          {
            label: getText("fire_alarm"),
            propName: "openAlarmDialog",
            onClick: onPlaySound,
            icon: <FireAlarmIcon />,
          },
        ],
      },
      {
        label: getText("map_actions"),
        actions: [
          {
            label: getText("map_operation"),
            propName: "openMapOperationDialog",
            icon: <RobotIcon />,
          },
        ],
      },
    ];

    return (
      <ExpansionPanel title={getText("actions")}>
        <Grid style={{ width: "100%" }}>
          <Tabs>
            {actionList.map((item, index) => (
              <Grid
                key={index}
                container
                justify="flex-start"
                direction="row"
                alignItems="stretch"
                spacing={3}
                title={item.label}
              >
                {item.content
                  ? item.content
                  : item.actions.map((action, key) => (
                      <Grid item key={key}>
                        <Tooltip
                          placement="top"
                          enterDelay={200}
                          disableHoverListener={action.tooltip === undefined}
                          disableFocusListener={action.tooltip === undefined}
                          disableTouchListener={action.tooltip === undefined}
                          title={action.tooltip || ""}
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <div className={classes.buttonContainer}>
                            <Button
                              className={
                                !action.disabled
                                  ? classes.button
                                  : classes.button_disabled
                              }
                              onClick={
                                action.onClick ||
                                this.handleActionToggle(action.propName)
                              }
                              disabled={action.disabled || false}
                            >
                              <ListItemIcon className={classes.buttonIcon}>
                                {action.icon}
                              </ListItemIcon>
                              <Typography className={classes.label}>
                                {action.label}
                              </Typography>
                            </Button>
                          </div>
                        </Tooltip>
                      </Grid>
                    ))}
              </Grid>
            ))}
          </Tabs>
        </Grid>

        <MapOperationDialog
          open={this.state.openMapOperationDialog}
          onClose={this.handleActionClose("openMapOperationDialog")}
        />

        <MicroControlDialog
          open={this.state.openMicroControlDialog}
          mode={mode}
          onStartMicroControl={onStartMicroControl}
          onStopAction={onStopAction}
          onClose={this.handleActionClose("openMicroControlDialog")}
        />

        <TakeElevatorDialog
          open={this.state.openTakeElevatorDialog}
          onClose={this.handleActionClose("openTakeElevatorDialog")}
          onTakeElevator={onTakeElevator}
        />

        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Backdrop className={classes.notifyBackdrop} open={Boolean(notifyMsg)}>
          <Grid className={classes.notifyMsg}>{notifyMsg}</Grid>
        </Backdrop>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(Actions);
