import { cameraConstants } from "src/redux/constants";
import { cameraServices } from "src/services";
import { siteActions } from ".";

export const getCameras = (filters) => {
  const request = () => {
    return {
      type: cameraConstants.GET_CAMERAS_REQUEST,
    };
  };

  const success = (cameras) => {
    return {
      type: cameraConstants.GET_CAMERAS_SUCCESS,
      cameras: cameras,
    };
  };

  const failure = () => {
    return {
      type: cameraConstants.GET_CAMERAS_FAILURE,
    };
  };

  return (dispatch, getState) => {
    filters = siteActions.addSiteToFilter(
      filters ? filters : getState().camera.filters,
      getState()
    );
    let serviceMethod = "getAllCameras";
    switch (filters.type) {
      case "camera":
        serviceMethod = "getCameraCameras";
        break;
      case "robot":
        serviceMethod = "getRobotCameras";
        break;
      default:
        break;
    }
    dispatch(request());
    cameraServices[serviceMethod]({}, filters)
      .then((cameras) => {
        dispatch(success(cameras));
      })
      .catch((error) => {
        dispatch(failure());
      });
  };
};

export const deviceRequest = (deviceID) => {
  return (dispatch) => {
    dispatch({
      type: cameraConstants.DEVICE_REQUEST,
      device: deviceID,
    });
  };
};

export const selectCamera = (camera, checked) => {
  return {
    type: cameraConstants.SELECT_CAMERA,
    id: camera.id,
    checked: checked,
  };
};

export const selectAllCameras = (checked) => {
  return {
    type: cameraConstants.SELECT_ALL_CAMERAS,
    checked: checked,
  };
};

export const sortCameras = (sortColumn) => {
  return {
    type: cameraConstants.SORT_CAMERAS,
    sortColumn: sortColumn,
  };
};

export const getHLSURL = (deviceID, rtspResponse) => {
  const request = () => {
    return {
      type: cameraConstants.GET_CAMERA_HLS_URL_REQUEST,
      id: deviceID,
    };
  };

  const error = (err) => {
    return {
      type: cameraConstants.GET_CAMERA_HLS_URL,
      id: deviceID,
      err: err,
    };
  };

  const success = (result) => {
    return {
      type: cameraConstants.GET_CAMERA_HLS_URL,
      id: deviceID,
      hls: result.hls_url,
    };
  };

  return (dispatch) => {
    if (!deviceID) return;
    if (rtspResponse.err && rtspResponse.err.ec !== 0) {
      dispatch(error(rtspResponse.err.em));
      return;
    }
    if (rtspResponse.ret.hls_url_v2) {
      dispatch(success({ hls_url: rtspResponse.ret.hls_url_v2 }));
      return;
    }
    dispatch(request());
    cameraServices
      .getCameraHLSURL(rtspResponse.ret)
      .then((result) => {
        dispatch(success(result));
      })
      .catch((err) => {
        dispatch(error(err.message));
      });
  };
};

export const getHistory = (data) => {
  const request = () => {
    return {
      type: cameraConstants.GET_HISTORY_REQUEST,
      id: data.id,
      updating: data.updating,
    };
  };

  const success = (videos) => {
    return {
      type: cameraConstants.GET_HISTORY_SUCCESS,
      id: data.id,
      videos,
      updating: false,
    };
  };

  const failure = () => {
    return {
      type: cameraConstants.GET_HISTORY_FAILURE,
      updating: false,
    };
  };

  return (dispatch) => {
    dispatch(request());
    let serviceMethod = "getCameraHistory";
    switch (data.type) {
      case "camera":
        serviceMethod = "getCameraHistory";
        break;
      case "robot":
        serviceMethod = "getRobotHistory";
        break;
      default:
        break;
    }
    cameraServices[serviceMethod](data)
      .then((videos) => {
        dispatch(success(videos));
      })
      .catch((error) => {
        dispatch(failure());
      });
  };
};

export const setFilter = (filters) => {
  return {
    type: cameraConstants.SET_FILTER,
    filters,
  };
};

export const updCamerasFromWS = (source) => {
  const parsedCameras =
    cameraServices.getAllCamerasAdapter.toClient({
      ret: source,
    }).result || [];
  return {
    type: cameraConstants.UPDATE_CAMERAS_FROM_WS,
    cameras: parsedCameras,
  };
};

export const setLineSize = (size) => {
  return {
    type: cameraConstants.SET_LINE_SIZE,
    size,
  };
};

export const getCameraAlgos = (cameraId, boxId) => {
  const request = () => {
    return {
      type: cameraConstants.CAMERA_GET_ALGOS_REQUEST,
      camera: cameraId,
    };
  };

  const success = (algos) => {
    return {
      type: cameraConstants.CAMERA_GET_ALGOS_SUCCESS,
      camera: cameraId,
      algos,
    };
  };

  const failure = () => {
    return {
      type: cameraConstants.CAMERA_GET_ALGOS_FAILURE,
      robot: cameraId,
    };
  };

  return (dispatch) => {
    dispatch(request());
    cameraServices
      .getCameraAlgos(boxId)
      .then((algos) => {
        dispatch(success(algos));
      })
      .catch((err) => {
        dispatch(failure());
      });
  };
};
