import React from 'react';
import { connect } from 'react-redux';

import { siteActions } from 'src/redux/actions';
import SiteRobotList from './SiteRobotList';
import LoadingText from 'src/components/LoadingText';

const mapStateToProps = state => {
  return {
    robots: state.site.robots,
    robotLoading: state.site.robotLoading,
    robotUpdating: state.site.robotUpdating,
    sites: state.site.sites,
    siteLoading: state.site.siteLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRobots: () => {
      dispatch(siteActions.getSiteRobots());
    },
    associateRobot: data => {
      dispatch(siteActions.updateSiteRobot(data));
    },
    getSites: () => {
      dispatch(siteActions.getSites());
    }
  };
};

class SiteRobotListContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      editId: -1,
      editValue: {},
    }
  }

  componentDidMount(){
    if (!this.props.robotLoading) this.props.getRobots();
    if (!this.props.siteLoading) this.props.getSites();
  }

  onClickEdit = id => event => {
    if (this.state.editId !== -1){
      return;
    }
    const { robots } = this.props;
    this.setState({
      editId: id,
      editValue: robots.find(robot => robot.id === id)
    });
  }

  onClickSubmit = robotId => event => {
    if (!this.props.robotUpdating){
      this.props.associateRobot({
        id: robotId,
        site_id: this.state.editValue.site_id
      });
      this.setState({
        editId: -1,
        editValue: {}
      });
    }
  }

  handleChange = prop => event => {
    this.setState({
      editValue: {
        ...this.state.editValue,
        [prop]: event.target.value
      }
    });
  }

  render() {
    const { robots, sites, robotLoading, siteLoading } = this.props;
    const { editId, editValue } = this.state;
    return (
      !robotLoading && !siteLoading ?
        <SiteRobotList
          robots={robots}
          sites={sites || []}
          editId={editId}
          editValue={editValue}
          onClickEdit={this.onClickEdit}
          onClickSubmit={this.onClickSubmit}
          handleChange={this.handleChange}
          />
        : <LoadingText />
    );
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SiteRobotListContainer);
