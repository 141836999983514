import React from 'react';

import DropDownSelectorHorizontal from 'src/components/Selectors/DropDownSelectorHorizontal';
import { getText } from 'src/utils/MultilingualLoader';

const SensorType = props => {
  const { sensorType, sensorTypes, onSensorTypeChange } = props;
  const options = sensorTypes.map(type => ({value: type, label: getText(type)}));

  return (
    <DropDownSelectorHorizontal
      title={getText('sensor_types')}
      options={options}
      value={sensorType || ' '}
      onChange={onSensorTypeChange}
    />
  );
}

export default SensorType;