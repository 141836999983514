export const GET_TASK_REQUEST = 'TASK_GET_TASK_REQUEST';
export const GET_TASK_SUCCESS = 'TASK_GET_TASK_SUCCESS';
export const GET_TASK_FAILURE = 'TASK_GET_TASK_FAILURE';

export const SET_FILTER = 'TASK_SET_FILTER';
export const SET_EVENT_OPTIONS = 'TASK_SET_EVENT_OPTIONS';
export const SET_ALGO_OPTIONS = 'TASK_SET_ALGO_OPTIONS';
export const SET_TARGET_OPTIONS = 'TASK_SET_TARGET_OPTIONS';

export const UPDATE_TASK_STATUS = 'TASK_UPDATE_TASK_STATUS';
