import {event_api} from 'src/services/Vision/api';
// import {isUsingEvent2} from 'src/redux/actions/org';

export async function getEventTypes(){
  try {
    let url = `/api/v1/web/meta/all_event_types`;
    url += '?include_event_index=yes';
    const response = await event_api.get(url);
    return response;
  } catch (e) {
    throw e;
  }
}
