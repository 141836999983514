import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  root: {
    margin: '20px'
  },
  selectorLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  formLabel: {
    width: '50px',
    textTransform: 'capitalize'
  },
  input: {
    width: '100px',
    padding: '5px 20px 5px 20px',
    textTransform: 'capitalize'
  },
  menuItem: {
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem',
    textTransform: 'capitalize'
  },
});

const OutlinedSelector = props => {
  const { classes, title, options, value, onChange, disabled, children, noMargin } = props;
  return (
    <FormControl component="fieldset" className={noMargin ? '' : classes.root}>
      <div className={classes.selectorLine}>
        {title &&
          <FormLabel
            className={classes.formLabel}
            component="legend"
          >
            {title}
          </FormLabel>
        }
        <Select
          variant="outlined"
          input={<OutlinedInput labelWidth={0} classes={{ input: classes.input }} />}
          value={value}
          onChange={onChange}
          disabled={disabled}
        >
          {options.map((option, index) =>
            <MenuItem key={index} className={classes.menuItem} value={option.value}>{option.label}</MenuItem>
          )}
        </Select>
      </div>
      {children}
    </FormControl>
  );
};

export default withStyles(styles)(OutlinedSelector);
