import {IconButton, TableCell, CircularProgress} from '@material-ui/core'
import {getText} from 'src/utils/MultilingualLoader'
import DropDownSelectorHorizontal from 'src/components/Selectors/DropDownSelectorHorizontal'
import {Check as SubmitIcon, Close as CancelIcon, Edit as EditIcon} from '@material-ui/icons'
import React, {useEffect, useMemo, useState} from 'react'
import {connect} from 'react-redux'
import {robotActions} from 'src/redux/actions'

const mapStateToProps = (state) => {
  const { selected } = state.robot;
  const { curSite, siteAllFloors } = state.site;
  const currSiteFloors = (siteAllFloors[curSite] || []).map((floor) => {
    const buildingName = floor.building && floor.building.name
    const {id, name: floorName, zone_id} = floor
    
    return {
      ...floor,
      value: id,
      label: `${buildingName ? buildingName + ' - ' : ''}${zone_id ? zone_id + ' - ' : ''}${floorName}`
    }
  })
  return {
    robot: selected,
    // 当前站点的所有楼层信息
    currSiteFloors,
    currSiteFloorsMapper: currSiteFloors.reduce((result, floor) => ({
      ...result,
      [floor.id]: floor
    }), {})
  };
};

const mapDispatchToProps = {
  updateMap: (robotId, mapId, data) =>
    robotActions.updateMap(robotId, mapId, data),
}

function SelectMapFloor({classes, robot, curMap, currSiteFloors, currSiteFloorsMapper, updateMap}) {
  const [isUpdating, setIsUpdating] = useState(false)
  const [isEditingFloor, setIsEditingFloor] = useState(false)
  // 当前选中地图的楼层信息
  const selectedFloor = useMemo(() => curMap && currSiteFloors.find((floorObj) => {
    const {floorId, floor, zoneId} = curMap
    // 地图上的floor_id是后加的一个属性，表示地图绑定的楼层id，对于添加该属性之前已存在的地图来说，其值为null，
    // 此时，需要用地图上的floor和zone_id来将地图与楼层进行匹配
    // 另外，floorObj.id是数字类型，但是map里的floor_id是字符串类型，所以需要做一下转换，造成不一致的原因是，
    // 后端原话：map里面的floor_id改成string主要是考虑机器人的couchbase数据不好迁移升级，就用个通用的类型
    return floorId ? (floorObj.id + '') === floorId : (floorObj.floor === floor && floorObj.zone_id === zoneId)
  }), [currSiteFloors, curMap])
  // 临时选择的楼层的id
  const [tempSelectedFloorId, setTempSelectedFloorId] = useState(null)
  
  useEffect(() => {
    if(selectedFloor) {
      setTempSelectedFloorId(selectedFloor.value)
    }
  }, [selectedFloor])
  
  return (
    <>
      <TableCell className={classes.tableCell}
                 width={200}>
        {getText('bind_floor')}:
      </TableCell>
      <TableCell className={classes.tableCell}>
        {
          isEditingFloor ? (
            <>
              <DropDownSelectorHorizontal
                title={""}
                options={currSiteFloors}
                value={tempSelectedFloorId}
                onChange={(event) => {
                  setTempSelectedFloorId(event.target.value)
                }}
              />
              {
                isUpdating ? (
                  <CircularProgress size={25}/>
                ) :(<>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      const {id, floor, zone_id} = currSiteFloorsMapper[tempSelectedFloorId]
                      setIsUpdating(true)
                      updateMap(robot.id, curMap.id, { ...curMap.rawData, floor_id: id + '', floor, zone_id}).then(() => setIsEditingFloor(false)).finally(() => setIsUpdating(false))
                    }}
                  >
                    <SubmitIcon />
                  </IconButton>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      setIsEditingFloor(false);
                      setTempSelectedFloorId(selectedFloor && selectedFloor.value)
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </>)
              }
            </>
          ) : (
            <>
              {selectedFloor && selectedFloor.label}
              <IconButton
                className={classes.iconButton}
                onClick={() => setIsEditingFloor(true)}
              >
                <EditIcon />
              </IconButton>
            </>
          )
        }
      </TableCell>
    </>
  )
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectMapFloor);
