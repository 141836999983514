import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';

import { cameraActions } from 'src/redux/actions';
import HlsPlayer from 'src/pages/Main/Player/HlsPlayer';
import PlayerCard from 'src/pages/Main/Player/PlayerCard';
import { getText } from 'src/utils/MultilingualLoader'

const mapStateToProps = state => {
  const { hlsCache } = state.camera;
  return { hlsCache };
};

const mapDispatchToProps = dispatch => {
  return {
    getHLSURL: (deviceID, msg, options) => {
      dispatch(
        cameraActions.deviceRequest(deviceID)
      );
      if (window.wsAPP) window.wsAPP.sendMsg(msg, options);
    }
  };
};

class EventCameraPlayerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshDelay: 800,
    }
  }

  getDevice = (selected) => {
    let cameraID = null;
    let cameraType = '';
    let cameraName = '';
    if (selected) {
      const source = selected.inspection ? 'robot' : selected.event.source;
      if (source === 'robot') {
        cameraID = selected.inspection ? selected.inspection.robot && selected.inspection.robot.id :
          selected.event.robot && selected.event.robot.id;
        cameraName = selected.inspection ? selected.inspection.robot && selected.inspection.robot.name :
          selected.event.robot && selected.event.robot.name;
        cameraType = 'robot';
      }
      else if (source === 'camera') {
        cameraID = selected.event.camera.id;
        cameraName = selected.event.camera.name;
        cameraType = 'camera';
      }
    }
    return { cameraID, cameraName, cameraType };
  }

  loadCamera = (cameraID, cameraType, force = true) => {
    const { hlsCache } = this.props;
    const hlsURL = hlsCache[cameraID];
    if (!force && hlsURL) {
      return;
    }

    const device = cameraID;
    var act = "";
    var args = {};
    if (cameraType === "robot") {
      act = "robot.start_stream";
      args.robot_id = device;
      args.camera = "front";
    } else {
      act = "box.camera.start_stream";
      args.camera_id = device;
    }

    const requestID = uuid();

    this.props.getHLSURL(device, {
      id: requestID,
      act: act,
      arg: args
    }, {
        hasRes: true,
        device
      })
  }

  refreshCamera = (refreshDelay) => {
    const { selected } = this.props;
    const { cameraID, cameraType } = this.getDevice(selected);
    this.loadCamera(cameraID, cameraType, true);
    this.setState({
      refreshDelay: refreshDelay < 12800 ? refreshDelay * 2 : 12800
    });
  }



  componentDidMount() {
    const { selected } = this.props;
    const { cameraID, cameraType } = this.getDevice(selected);
    //No need to reload If the camera already has a valid streaming url
    const hlsURL = this.props.hlsCache[cameraID];
    if (hlsURL && hlsURL.hls) {
      return;
    }
    this.loadCamera(cameraID, cameraType, true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selected && this.props.selected && prevProps.selected.id !== this.props.selected.id) {
      const { selected } = this.props;
      const { cameraID, cameraType } = this.getDevice(selected);
      //No need to reload If the camera already has a valid streaming url
      const hlsURL = this.props.hlsCache[cameraID];
      if (hlsURL && hlsURL.hls) {
        return;
      }
      this.loadCamera(cameraID, cameraType, true);
    }
  }

  render() {
    const { selected, hlsCache, eventCameraHeight } = this.props;
    const { cameraID, cameraName, cameraType } = this.getDevice(selected);
    const hlsURL = cameraID ? hlsCache[cameraID] : null;
    let streamLoading = hlsURL ? hlsURL.loading : getText('preparing');
    let streamURL = hlsURL ? hlsURL.hls : null;
    let streamError = hlsURL ? hlsURL.err : null;
    let id = hlsURL ? hlsURL.id : null;

    return (
      <PlayerCard
        player={
          <HlsPlayer
            streamURL={streamURL}
            streamLoading={streamLoading}
            streamError={streamError}
            streamTitle={cameraName}
            id={id}
            minHeight={eventCameraHeight}
            refreshCamera={this.refreshCamera}
            refreshDelay={this.state.refreshDelay}
          />
        }
        camera={cameraType === 'robot' ? (selected.inspection ? selected.inspection.robot : selected.event.robot) : selected.event.camera}
        showHistory={false}
        minHeight={eventCameraHeight}
        refresh={() => this.refreshCamera(400)}
      />
    );
  }
}

EventCameraPlayerContainer.propTypes = {
  selected: PropTypes.object
};

EventCameraPlayerContainer.defaultProps = {
  selected: null
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventCameraPlayerContainer);
