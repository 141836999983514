import React from 'react';
import { connect } from 'react-redux';

import { manageActions } from 'src/redux/actions';
import { mapManageTypeToConstant } from 'src/utils/constantMapper';
import AddGuardAdminPanel from './AddGuardAdminPanel';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    createAccount: data => {
      dispatch(manageActions.createAccount(data));
    }
  };
};

class AddGuardAdminPanelContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      username: '',
      last_name: '',
      first_name: '',
      organization: -1,
      phone_number: '',
      selectedType: mapManageTypeToConstant(props.match.path.substr(props.match.path.lastIndexOf('/') + 1)),
      organizations: []
    };
  }

  async componentDidMount(){
    let organizations = await manageActions.getOrganizations();
    organizations = organizations ? organizations.list : [];
    this.setState({
      organizations: organizations
    })
  }

  handleSubmit = event => {
    const { selectedType } = this.state;
    const { username, last_name, first_name, organization, phone_number } = this.state;
    const reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const rePhone = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    if (!reEmail.test(String(username).toLowerCase())){
      alert(getText('input_error_email'));
      return;
    }
    if (!rePhone.test(String(phone_number))){
      alert(getText('input_error_phone'));
      return;
    }
    this.props.createAccount({
      selectedType,
      username,
      last_name,
      first_name,
      organization,
      phone_number
    });
  }

  handleChange = prop => event => {
    this.setState({
      ...this.state,
      [prop]: event.target.value
    });
  }

  render() {
    return (
      <AddGuardAdminPanel
        username={this.state.username}
        last_name={this.state.last_name}
        first_name={this.state.first_name}
        organization={this.state.organization}
        phone_number={this.state.phone_number}
        organizations={this.state.organizations}
        submitting={false}
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
        />
    );
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddGuardAdminPanelContainer);
