import { messageConstants } from "src/redux/constants";

const initialState = {
  message: "",
  level: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case messageConstants.SHOW_NOTIFICATION:
      return {
        ...state,
        ...action.payload,
      };
    case messageConstants.HIDE_NOTIFICATION:
      return {
        ...state,
        message: "",
        level: "",
      };
    default:
      return state;
  }
};
