import React from 'react';
import PropTypes from 'prop-types';
import { Layer, Image } from 'react-konva';
import useImage from 'use-image';

import robotImg from 'public/robot_yellow.svg';

const imageWidth = 24;
const imageHeight = 36;

const RobotImage = props => {
  const { position, scale } = props;
  const [ image ] = useImage(robotImg);
  return <Image image={image} width={imageWidth/Math.sqrt(scale)} height={imageHeight/Math.sqrt(scale)} 
    x={Math.max(position.x, 0)} y={Math.max(position.y, 0)} offsetX={imageWidth/2/Math.sqrt(scale)} offsetY={imageHeight/Math.sqrt(scale)} />;
};

const RobotLocation = props => {
  const location = props.robotLocation;
  if (location) {
    const { getPosition, scale } = props;
    const position = getPosition(location.x, location.y);
    if (position.x && position.y && !isNaN(position.x) && !isNaN(position.y)) {
      return (
          <Layer>
            <RobotImage position={position} scale={scale} />
          </Layer>
      );
    }
  }
  return (
      <Layer/>
  );
};

RobotLocation.propTypes = {
  robotLocation: PropTypes.any,
  getPosition: PropTypes.func.isRequired,
};

RobotLocation.defaultProps = {
  robotLocation: {}
};

export default RobotLocation
