import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DateSelector from 'src/components/Selectors/DateSelector';
import DropDownSelectorHorizontal from 'src/components/Selectors/DropDownSelectorHorizontal';
import { getText } from 'src/utils/MultilingualLoader';
import InspectionSwitch from '../InspectionSwitch';

const styles = theme => ({
  textField: {
    margin: 0
  },
  submit: {
    textAlign: 'right'
  },
  submitButton: {
    maxWidth: 100,
  },
  label: {
    color: 'white',
    textTransform: 'initial'
  },
  selectorLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  formLabel: {
    width: '100px'
  },
});

const lineSizeOptions = [
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 6, label: '6' },
];

const sortByOptions = [
  { value: 'target__name', label: getText('name') },
  { value: 'ended_at', label: getText('time') }
];

const mediumTypeOptions = [
  { value: 'all', label: getText('all') },
  { value: 'snapshot', label: getText('image') },
  { value: 'video', label: getText('video') }
]

const matchMapOptions = [
  { value: true, label: getText('yes') },
  { value: false, label: getText('no') },
];

const matchRouteOptions = [
  { value: true, label: getText('yes') },
  { value: false, label: getText('no') },
];

const inspectionTypeOptions = [
  { value: 'check', label: getText('check') },
  { value: 'manual', label: getText('manual') }
]

const InspectionFilter = props => {
  const { classes, lineSize, sortBy, mediumType, matchMap, matchRoute, startDate, endDate, inspectionType, hasMap, hasRoute, targetOptions, target,
    handleChange, handleSubmit, handleDateChange, handleAutoComplete } = props;
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-end"
      spacing={1}
    >
      <Grid item xl={1} lg={2}>
        <Autocomplete
          options={targetOptions}
          getOptionLabel={option => option.label || ''}
          disableListWrap
          onChange={handleAutoComplete}
          value={target} 
          renderInput={params => (
            <TextField 
              {...params} 
              className={classes.textField} 
              label={getText('name')} 
              margin="normal" 
            />
          )}
        />
      </Grid>
      <Grid item xl={1} lg={2}>
        <DateSelector
          value={startDate}
          onChange={handleDateChange('startDate')}
          title={getText('start_date')}
        />
      </Grid>
      <Grid item xl={1} lg={2}>
        <DateSelector
          value={endDate}
          onChange={handleDateChange('endDate')}
          title={getText('end_date')}
        />
      </Grid>
      <Grid item xl={1} lg={2}>
        <DropDownSelectorHorizontal
          title={getText('inspection_type')}
          options={inspectionTypeOptions}
          value={inspectionType}
          onChange={handleChange('inspectionType')}
        />
      </Grid>
      <Grid item xl={1} lg={2}>
        <DropDownSelectorHorizontal
          title={getText('line_size')}
          options={lineSizeOptions}
          value={lineSize}
          onChange={handleChange('lineSize')}
        />
      </Grid>
      <Grid item xl={1} lg={2}>
        <DropDownSelectorHorizontal
          title={getText('sort_by')}
          options={sortByOptions}
          value={sortBy}
          onChange={handleChange('sortBy')}
        />
      </Grid>
      <Grid item xl={1} lg={2}>
        <DropDownSelectorHorizontal
          title={getText('media_type')}
          options={mediumTypeOptions}
          value={mediumType}
          onChange={handleChange('mediumType')}
        />
      </Grid>
      <Grid item xl={1} lg={2}>
        <DropDownSelectorHorizontal
          title={getText('match_map')}
          options={matchMapOptions}
          value={matchMap}
          onChange={handleChange('matchMap')}
          disabled={!hasMap}
        />
      </Grid>
      <Grid item xl={1} lg={2}>
        <DropDownSelectorHorizontal
          title={getText('match_route')}
          options={matchRouteOptions}
          value={matchRoute}
          onChange={handleChange('matchRoute')}
          disabled={!hasRoute}
        />
      </Grid>
      {/* <Grid item lg={2}>
        <InspectionSwitch />
      </Grid> */}
      <Grid item xl={1} lg={2} className={classes.submit}>
        <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          className={classes.submitButton}
          onClick={handleSubmit}
        >
          <Typography variant="body2" className={classes.label}>
            {getText('submit')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(InspectionFilter);