import React, {useEffect, useState} from 'react';
import {Grid, CircularProgress} from '@material-ui/core';


export function ImageLoader({url, alt}) {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
  }, [url]);
  const loaded = () => setIsLoading(false);
  return (
    <>
      {isLoading && (
        <Grid item container alignItems="center" justify="center" style={{position: 'absolute'}}>
          <CircularProgress style={{color: 'white'}} />
        </Grid>
      )}
      <img src={url} alt={alt} onLoad={loaded} onError={loaded} crossOrigin="anonymous" />
    </>
  );
}
