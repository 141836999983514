import React from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';

import MapStyle from './MapStyle.json';

function mapLoaded(mapProps, map) {
  map.setOptions({
     styles: MapStyle
  })
}


const MapContainer = ({ initialCenter, google }) => {
  return (
    <Map
      google={google}
      initialCenter={initialCenter}
      zoom={20}
      onReady={(mapProps, map) => mapLoaded(mapProps, map)}
      zoomControl={false}
      mapTypeControl={false}
      fullscreenControl={false}
      streetViewControl={false}
    >
    </Map>
  );
}

export default GoogleApiWrapper({ apiKey: (process.env.REACT_APP_GOOGLE_MAP_API_KEY) })(MapContainer)