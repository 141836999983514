import React from 'react';
import { connect } from 'react-redux';
import { manageActions } from 'src/redux/actions';
import { mapManageTypeToConstant } from 'src/utils/constantMapper';

import AddOperatorPanel from './AddOperatorPanel';
import { getUserInfo } from 'src/utils/auth';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    createAccount: data => {
      dispatch(manageActions.createAccount(data));
    }
  };
};

class AddOperatorPanelContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      username: '',
      last_name: '',
      first_name: '',
      phone_number: '',
      organization: -1,
      selectedType: mapManageTypeToConstant(props.match.path.substr(props.match.path.lastIndexOf('/') + 1))
    };
  }

  async componentDidMount(){
    const uid = getUserInfo().userId;
    const guardAdminProfile = await manageActions.getGuardAdminProfile(uid);
    this.setState({
      organization: (guardAdminProfile.organization && guardAdminProfile.organization.id) || -1
    })
  }

  handleSubmit = event => {
    const { selectedType } = this.state;
    const { username, last_name, first_name, organization, phone_number } = this.state;
    const reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const rePhone = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    if (!reEmail.test(String(username).toLowerCase())){
      alert(getText('invalid_email'));
      return;
    }
    if (!rePhone.test(String(phone_number))){
      alert(getText('invalid_phone'));
      return;
    }
    if (this.state.organization === -1){
      alert(getText('organization_loading'));
      return;
    }
    this.props.createAccount({
      selectedType,
      username,
      last_name,
      first_name,
      organization,
      phone_number
    });
  }

  handleChange = prop => event => {
    this.setState({
      ...this.state,
      [prop]: event.target.value
    });
  }

  render() {
    return (
      <AddOperatorPanel
        username={this.state.username}
        last_name={this.state.last_name}
        first_name={this.state.first_name}
        phone_number={this.state.phone_number}
        submitting={false}
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
        />
    );
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddOperatorPanelContainer);
