import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import { mapActions } from 'src/redux/actions';
import MapList from './MapList';
import Map from './Map';
import MapActions from './MapActions';

const mapStateToProps = state => {
  const { selectedRobotId, maps } = state.map;
  const curRobotMaps = (selectedRobotId && maps[selectedRobotId] && maps[selectedRobotId].maps) || [];

  return { selectedRobotId, curRobotMaps };
};

const mapDispatchToProps = {
};

const initState = {
  curAction: null,
  curMap: null,
  transformData: null,
}

class MapsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedRobotId !== this.props.selectedRobotId) {
      this.setState(initState);
    }
    if (prevState.curMap && this.state.curMap && prevState.curMap.id !== this.state.curMap.id) {
      this.setState({
        ...initState,
        curMap: this.state.curMap
      });
    }
  }

  setMapsPanelState = state => {
    this.setState({
      ...state
    });
  }

  render() {
    const { curMap, curAction } = this.state;

    return (
      <Grid
        container
        direction="column"
        spacing={3}
      >
        <MapList
          curMap={curMap}
          setCurMap={map => this.setMapsPanelState({ curMap: map })}
        />
        {curMap && <>
          <Grid item>
            <Map
              mapsPanelState={this.state}
              setMapsPanelState={this.setMapsPanelState}
            />
          </Grid>
          <MapActions
            mapsPanelState={this.state}
            setMapsPanelState={this.setMapsPanelState}
          />
        </>}
      </Grid>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapsPanel);