import React, {useEffect, useMemo, useState} from 'react'
import {getText} from "src/utils/MultilingualLoader"
import {Button, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import {connect} from 'react-redux'
import {Add as AddIcon} from '@material-ui/icons'
import DropDownSelectorHorizontal from 'src/components/Selectors/DropDownSelectorHorizontal'
import {robotActions} from '../../../../../redux/actions'

function NoConnectingFloors({onSet}) {
  return (
    <div style={{
      textAlign: 'center'
    }}>
      <div style={{
        fontSize: '16px',
        fontWeight: 'bold',
        margin: '40px 0'
      }}>{getText('connecting_floors_empty_msg')}</div>
      <Button style={{
        marginBottom: '50px'
      }} color="primary" variant="contained" onClick={onSet}>{getText('connecting_floors_btn_text')}</Button>
    </div>
  )
}

function DisplayMapRouteCell({info, mapsMapper}) {
  let mapId
  let mapName = '-'
  let routeId
  let routeName = '-'
  if (info) {
    mapId = info.map_id
    routeId = info.route_id
    const targetMap = mapsMapper[mapId]
    
    if (targetMap) {
      const {mapRoutes} = targetMap
      const targetMapRoute = (mapRoutes || []).find(({id}) => id === routeId)
      
      mapName = targetMap.name
      
      if (targetMapRoute) {
        routeName = targetMapRoute.name
      }
    }
  }
  
  return (
    <>
      <TableCell>{mapName}</TableCell>
      <TableCell>{routeName}</TableCell>
    </>
  )
}

function EditMapRouteCell({info, maps, mapsMapper, onModify}) {
  let mapId = info && info.map_id || ''
  let routeId = info && info.route_id || ''
  const mapsOptions = useMemo(() => (maps || []).map(({id, name}) => ({
    label: name,
    value: id
  })), [maps])
  const mapRoutes = useMemo(() => {
    let routes = []
    
    if (mapId) {
      const targetMap = mapsMapper[mapId]
      if (targetMap) {
        routes = (targetMap.mapRoutes || []).map(({id, name}) => ({
          label: name,
          value: id
        }))
      }
    }
    
    return routes
  }, [info, maps])
  return (
    <>
      <TableCell>
        <DropDownSelectorHorizontal
          title={''}
          options={mapsOptions}
          value={mapId}
          onChange={event => onModify({
            map_id: event.target.value,
            route_id: ''
          })}
        />
      </TableCell>
      <TableCell>
        <DropDownSelectorHorizontal
          title={''}
          options={mapRoutes}
          value={routeId}
          onChange={event => onModify({
            map_id: mapId,
            route_id: event.target.value
          })}
        />
      </TableCell>
    </>
  )
}

function ConnectingFloorsRow({rowIndex, editing, connectingFloor, maps, mapsMapper, onModify, onRemove}) {
  const [aInfo, bInfo] = connectingFloor
  
  return (
    <TableRow>
      <TableCell>{rowIndex + 1}</TableCell>
      {
        editing ? (
          <>
            <EditMapRouteCell info={aInfo} maps={maps} mapsMapper={mapsMapper}
                              onModify={(value) => onModify(rowIndex, 0, value)}/>
            <EditMapRouteCell info={bInfo} maps={maps} mapsMapper={mapsMapper}
                              onModify={(value) => onModify(rowIndex, 1, value)}/>
            <TableCell><Button color="secondary"
                               variant="contained"
                               size="small"
                               onClick={() => onRemove(rowIndex)}>{getText('delete')}</Button></TableCell>
          </>
        ) : (
          <>
            <DisplayMapRouteCell info={aInfo} mapsMapper={mapsMapper}/>
            <DisplayMapRouteCell info={bInfo} mapsMapper={mapsMapper}/>
          </>
        )
      }
    </TableRow>
  )
}

function SetConnectingFloors({editing, setEditing, connectingFloors, maps, onAdd, onRemove, onModify, onReset, onSave}) {
  const mapsMapper = useMemo(() => maps.reduce((result, map) => ({
    ...result,
    [map.id]: map
  }), {}), [maps])
  const rowText = [
    getText('connecting_floors_area_a_map_title'),
    getText('connecting_floors_area_a_route_title'),
    getText('connecting_floors_area_b_map_title'),
    getText('connecting_floors_area_b_route_title')
  ]
  
  if (editing) {
    rowText.push(getText('action'))
  }
  
  return (
    <div>
      <Table style={{
        marginBottom: '15px'
      }}>
        <TableHead>
          <TableRow>
            <TableCell style={{
              width: '10%'
            }}>{getText('id')}</TableCell>
            {
              rowText.map((text, index) => <TableCell style={{
                width: '20%'
              }} key={index}>{text}</TableCell>)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            connectingFloors.length === 0
              ? (
                <TableRow>
                  <TableCell colSpan={5} style={{
                    textAlign: 'center'
                  }}>{getText('empty')}</TableCell>
                </TableRow>
              )
              : connectingFloors.map((connectingFloor, rowIndex) => <ConnectingFloorsRow editing={editing}
                                                                                         connectingFloor={connectingFloor}
                                                                                         maps={maps}
                                                                                         mapsMapper={mapsMapper}
                                                                                         onRemove={onRemove}
                                                                                         onModify={onModify}
                                                                                         rowIndex={rowIndex}
                                                                                         key={rowIndex}/>)
          }
          {editing && (
            <TableRow>
              <TableCell colSpan={5} style={{
                padding: '8px 16px'
              }}>
                <Button color="primary" variant="outlined" size="small" startIcon={<AddIcon/>} onClick={onAdd}>
                  {getText('add_more')}
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div style={{
        textAlign: 'right'
      }}>
        {
          editing ? (
            <>
              <Button color="secondary" variant="contained" size="small" onClick={() => {
                setEditing(false)
                onReset()
              }}>{getText('cancel')}</Button>
              <Button style={{
                marginLeft: '10px'
              }} color="primary" variant="contained" size="small" onClick={onSave}>{getText('save')}</Button>
            </>
          ) : <Button color="primary" variant="contained" size="small"
                      onClick={() => setEditing(true)}>{getText('edit')}</Button>
        }
      </div>
    </div>
  )
}

// 双楼栋
function ConnectingFloorsConfig({selected, robotMapList, robotConfig, updateRobotConfigs}) {
  const [editing, setEditing] = useState(false)
  const {connecting_floors, updating} = robotConfig || {}
  /**
   * 是这种形式的值：
   * [
   *   [
   {
           "map_id": "a111",
           "route_id": "a222",
         },
   {
           "map_id": "a333",
           "route_id": "a444",
         }
   ],
   [
   {
           "map_id": "b555",
           "route_id": "b666",
         },
   {
           "map_id": "b777",
           "route_id": "b888",
         }
   ]
   * ]
   */
  const [localConnectingFloors, setLocalConnectingFloors] = useState([])
  const maps = robotMapList.maps || []
  const setConnectingFloorsFromRemote = () => {
    setLocalConnectingFloors((connecting_floors || []).map(({parts}) => parts))
  }
  const addConnectingFloor = () => setLocalConnectingFloors(prevState => [...prevState, []])
  const removeConnectingFloor = (rowIndex) => {
    setLocalConnectingFloors(prevState => {
      const duplicateState = [...prevState]
      duplicateState.splice(rowIndex, 1)
      return duplicateState
    })
  }
  const modifyConnectingFloor = (rowIndex, zoneIndex, value) => {
    setLocalConnectingFloors(prevState => {
      const duplicateState = [...prevState]
      const targetRowInfo = [...prevState[rowIndex]]
      
      targetRowInfo[zoneIndex] = value
      duplicateState[rowIndex] = targetRowInfo
      
      return duplicateState
    })
  }
  const saveConnectingFloor = () => {
    if (updating) {
      return
    }
    
    if(selected) {
      updateRobotConfigs(selected.id, {
        ...(robotConfig || {}),
        connecting_floors: localConnectingFloors.filter(([aInfo, bInfo]) => aInfo && aInfo.map_id && aInfo.route_id && bInfo && bInfo.map_id && bInfo.route_id).map(([aInfo, bInfo]) => ({
          parts: [aInfo, bInfo]
        }))
      })
    }
  }
  
  useEffect(setConnectingFloorsFromRemote, [connecting_floors])
  useEffect(() => {
    setEditing(false)
  }, [updating])
  
  return (
    <div>
      {
        (editing || localConnectingFloors.length > 0)
          ? <SetConnectingFloors connectingFloors={localConnectingFloors}
                                 maps={maps}
                                 editing={editing}
                                 setEditing={setEditing}
                                 updating={updating}
                                 onAdd={addConnectingFloor}
                                 onRemove={removeConnectingFloor}
                                 onModify={modifyConnectingFloor}
                                 onReset={setConnectingFloorsFromRemote}
                                 onSave={saveConnectingFloor}/>
          : <NoConnectingFloors
            onSet={() => {
              setEditing(true)
              addConnectingFloor()
            }}
          />
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  const {robotMapLists, robotConfigs, selected} = state.robot
  return {
    selected,
    robotMapList: (selected && robotMapLists[selected.id]) || {},
    robotConfig: (selected && robotConfigs[selected.id]) || {}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateRobotConfigs: (robot_id, updConfigs) => {
      dispatch(robotActions.updateRobotConfigs(robot_id, updConfigs))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectingFloorsConfig)
