import React, { useState } from 'react';
import {
  Grid,
  Button,
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Card,
  CardContent,
  withStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';

import { getText } from 'src/utils/MultilingualLoader';
import RegionEditor from './RegionEditor';

const styles = theme => ({
  container: {
    padding: theme.spacing(1)
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    width: 80,
  },
  inspection: {
    objectFix: 'contain',
    width: '50%'
  },
  deleteContainer: {
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    right: 0,
    top: 0,
    cursor: 'pointer',
  },
  type: {
    fontWeight: 'bold',
    fontSize: 18
  }
});

const RegionCard = (props) => {
  const {
    classes,
    points,
    status,
    snapshot,
    options,
    error,
    type,
    multiple,
    regionConfig,
    handleChangeRef,
    handleDeleteRegion,
    no,
    validate,
  } = props;
  const [showEditor, setShowEditor] = useState(false);

  return (
    <Card style={{ background: '#f0f0f0' }}>
      <CardContent>
        <Grid container spacing={1}>
          {multiple && <Grid item container className={classes.deleteContainer}>
            <DeleteIcon color={error ? "secondary" : "primary"} onClick={handleDeleteRegion} className={classes.icon} />
          </Grid>}
          {!Boolean(regionConfig.hideType) && <Grid item container>
            <FormLabel component="label" className={classes.label}>{getText('type')}</FormLabel>
            <Grid className={classes.type}>{type}</Grid>
          </Grid>}
          <Grid item container>
            <FormLabel component="label" className={classes.label}>{getText('region')}</FormLabel>
            <Grid item xs>
              <Button color="primary" onClick={() => {
                setShowEditor(true)
              }}>{getText('edit_region')}</Button>
            </Grid>
          </Grid>
          <Grid item container>
            <FormLabel component="label" className={classes.label}></FormLabel>
            <Grid item xs>
              <TextField error={Boolean(validate.region)} helperText={validate.region} fullWidth placeholder="click above button to edit" value={points} />
            </Grid>
          </Grid>
          {multiple && <Grid item container alignItems="flex-start">
            <FormLabel component="label" className={classes.label}>{regionConfig.label1 ? regionConfig.label1 : getText('id')}</FormLabel>
            <Grid>
              <TextField
                error={Boolean(validate.no)}
                helperText={validate.no}
                fullWidth
                placeholder={regionConfig.tooltip ? regionConfig.tooltip : "region id"}
                value={no}
                multiline={Boolean(regionConfig.label1)}
                rows={Boolean(regionConfig.label1) ? 3 : 1}
                onChange={e => {
                  handleChangeRef('no', e.target.value);
                }}
              />
            </Grid>
          </Grid>}
          <Grid item container>
            <FormLabel component="label" className={classes.label}>{regionConfig.label2 ? regionConfig.label2 : getText('state')}</FormLabel>
            <RadioGroup aria-label="action" name="action" value={status} onChange={(e) => {
              handleChangeRef('status', e.target.value);
            }}>
              <Grid container>
                {options.map((item, index) => (
                  <FormControlLabel key={index} value={item.value} control={<Radio color="primary" />} label={item.label} />
                ))}
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
      </CardContent>
      {showEditor && <RegionEditor snapshot={snapshot} region={points} onClose={(value) => {
        setShowEditor(false);
        if (value !== undefined) {
          handleChangeRef('points', value);
        }
      }} />}
    </Card>
  );
};

export default withStyles(styles)(RegionCard);
