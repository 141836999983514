import React from 'react';
import { Stage, Layer, Image } from 'react-konva';

import Waypoints from './Waypoints';

class InspectionMapStage extends React.Component {
  render() {
    const {
      mapWidth,
      mapHeight,
      mapImage,
      waypoints,
      getImagePosition,
    } = this.props;

    return (
      <Stage width={mapWidth} height={mapHeight} x={0} y={0}>
        <Layer>
          <Image x={0} y={0} image={mapImage} width={mapWidth} height={mapHeight} />
        </Layer>
        {mapImage &&
          <Layer>
            <Waypoints waypoints={waypoints} getImagePosition={getImagePosition} />
          </Layer>
        }
      </Stage>
    )
  }
};

export default InspectionMapStage;