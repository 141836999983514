export function multiplyTransformMatrices(matrixA, matrixB) {
  if (matrixA.length !== 4 || matrixB.length !== 4) return [1, 0, 0, 1];
  return [
    matrixA[0] * matrixB[0] + matrixA[2] * matrixB[1],
    matrixA[1] * matrixB[0] + matrixA[3] * matrixB[1],
    matrixA[0] * matrixB[2] + matrixA[2] * matrixB[3],
    matrixA[1] * matrixB[2] + matrixA[3] * matrixB[3],
  ];
}

export function getTransformData(transformData) {
  const { xScale, yScale, transform, mapImageScale, biasTransform } = transformData;
  const resizeMatrix = [xScale, 0, 0, yScale];
  const reMatrix = /matrix\((-?\d*.?\d*e?-?\d*), (-?\d*.?\d*e?-?\d*), (-?\d*.?\d*e?-?\d*), (-?\d*.?\d*e?-?\d*), (-?\d*.?\d*e?-?\d*), (-?\d*.?\d*e?-?\d*)\)/;
  const maMatrix = transform.match(reMatrix);
  let rotateMatrix = maMatrix ? 
    [parseFloat(maMatrix[1]), parseFloat(maMatrix[2]), parseFloat(maMatrix[3]), parseFloat(maMatrix[4])]
    : [1, 0, 0, 1];
  const translateMatrix = maMatrix ? [parseFloat(maMatrix[5]), parseFloat(maMatrix[6])] : [0, 0];
  const reDegree = /(-?\d*.?\d*e?-?\d*)deg/;
  const maDegree = transform.match(reDegree) || 0;
  if (maDegree && maDegree[1] && maDegree[1].length > 3) {
    const degree = (parseFloat(maDegree[1].slice(0, maDegree[1].length - 3)) % 180) / 180 * Math.PI;
    if (degree) {
      rotateMatrix = multiplyTransformMatrices([Math.cos(degree), Math.sin(degree), -Math.sin(degree), Math.cos(degree)], rotateMatrix);
    }
  }
  const theta = rotateMatrix[0] < 0 ? rotateMatrix[1] < 0 ? -Math.PI - Math.asin(rotateMatrix[1]) : Math.PI - Math.asin(rotateMatrix[1]) : Math.asin(rotateMatrix[1]);
  const reTranslate = /translate\((-?\d*.?\d*e?-?\d*)px, (-?\d*.?\d*e?-?\d*)px\)/;
  const maTranslate = transform.match(reTranslate);
  if (maTranslate && maTranslate[1] && maTranslate[2]) {
    translateMatrix[0] += parseFloat(maTranslate[1]) * rotateMatrix[0] + parseFloat(maTranslate[2]) * rotateMatrix[2];
    translateMatrix[1] += parseFloat(maTranslate[1]) * rotateMatrix[1] + parseFloat(maTranslate[2]) * rotateMatrix[0];
  }
  translateMatrix[0] *= mapImageScale;
  translateMatrix[1] *= mapImageScale;
  let transformMatrix = multiplyTransformMatrices(rotateMatrix, resizeMatrix);
  if (biasTransform && biasTransform.translateWidth) {
    transformMatrix = multiplyTransformMatrices([0, 1, -1, 0], transformMatrix);
    const tmp = translateMatrix[0];
    translateMatrix[0] = biasTransform.translateWidth * mapImageScale - translateMatrix[1];
    translateMatrix[1] = tmp;
  }
  transformMatrix.push(...translateMatrix);
  return { transformMatrix, theta };
}