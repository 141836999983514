import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import { sensorActions } from 'src/redux/actions';
import SensorFilter from './SensorFilter';

const mapStateToProps = (state, props) => {
  return {
    filters: state.sensor.filters,
    robotMapList: state.robot.robotMapLists[props.selectedRobot.id],
  };
};

const mapDispatchToProps = {
    setFilter: filters => sensorActions.setFilter(filters),
    setShowType: showType => sensorActions.setShowType(showType),
    getSensorClusters: filters => sensorActions.getSensorClusters(filters),
    getSensorPoints: filters => sensorActions.getSensorPoints(filters)
};

class SensorFilterContainer extends React.Component {
  constructor(props) {
    super(props);
    const { filters } = props;
    const { radius, startTime, endTime, mapId, routeId, showType } = filters;
    this.state = {
      radius,
      startTime,
      endTime,
      mapId,
      routeId,
      showType
    };
  }

  componentDidUpdate(prevProps) {
    if (
        // reset filter when the same robot reselected 
        (!_.isEqual(prevProps.filters, this.props.filters) 
          && !_.isEqual(this.props.filters, {...this.state, robotId: this.props.filters.robotId}))
        // reset filter when another robot selected
        || ((prevProps.selectedRobot && this.props.selectedRobot && prevProps.selectedRobot.id !== this.props.selectedRobot.id))
      ) {
      const { radius, startTime, endTime, mapId, routId } = this.props.filters;
      this.setState({
        radius,
        startTime,
        endTime,
        mapId,
        routId
      });
    }
  }

  handleChange = prop => event => {
    this.setState({
      [prop]: event.target.value
    });
  }

  handleTimeChange = type => time => {
    this.setState({
      [type]: time
    });
  }

  handleMapIdChange = event => {
    const mapId = event.target.value;
    this.setState({ mapId }, this.handleSubmit);
  }

  handleRouteIdChange = event => {
    const routeId = event.target.value;
    this.setState({ routeId }, this.handleSubmit);
  }

  handleShowTypeSwitch = event => {
    const { setShowType } = this.props;
    const showType = event.target.checked ? 'route' : 'cluster';
    this.setState({ showType }, () => setShowType(showType));
  }

  handleSubmit = event => {
    const { filters, setFilter, getSensorClusters, getSensorPoints } = this.props;
    const sensorFilters = Object.assign({}, filters, this.state);
    setFilter(sensorFilters);
    getSensorClusters(sensorFilters);
    getSensorPoints(sensorFilters);
  };

  render() {
    const { radius, startTime, endTime, mapId, routeId, showType } = this.state;
    const { robotMapList } = this.props;
    let mapOptions = (robotMapList && robotMapList.maps && robotMapList.maps
      .filter(map => map.uploadStatus === 'finished' && map.id).map(map => ({value: map.id, label: `${map.name} ${map.id}`}))) || [];
    const selectedMap = mapId && robotMapList && robotMapList.maps && robotMapList.maps.find(map => map.id === mapId);
    const routes = selectedMap && selectedMap.mapRoutes;
    const routeOptions = (routes && routes.map(route => ({ label: `${route.name} ${route.id}`, value: route.id }))) || [];

    return (
       <SensorFilter
        radius={radius}
        startTime={startTime}
        endTime={endTime}
        mapId={mapId}
        routeId={routeId}
        showType={showType}
        mapOptions={mapOptions}
        routeOptions={routeOptions}
        handleChange={this.handleChange}
        handleTimeChange={this.handleTimeChange}
        handleMapIdChange={this.handleMapIdChange}
        handleRouteIdChange={this.handleRouteIdChange}
        handleSubmit={this.handleSubmit}
        handleShowTypeSwitch={this.handleShowTypeSwitch}
       />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SensorFilterContainer);
