import React from "react";

import RobotMap from "../RobotMap";

class RobotMapPanel extends React.Component {
  constructor(props) {
    super(props);
    const width = (window.innerWidth - 200 - 350 - 16 - 24) / 2;
    const height = (width * 3) / 4;
    this.state = {
      mapWidth: window.innerWidth < 1280 ? width * 2 : width,
      mapHeight: window.innerWidth < 1280 ? height * 2 : height,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeMap);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeMap);
  }

  resizeMap = () => {
    const width = (window.innerWidth - 200 - 350 - 16 - 24) / 2;
    const height = (width * 3) / 4;
    this.setState({
      mapWidth: window.innerWidth < 1280 ? width * 2 : width,
      mapHeight: window.innerWidth < 1280 ? height * 2 : height,
    });
  };

  render() {
    const { mapWidth, mapHeight } = this.state;
    return <RobotMap mapWidth={mapWidth} mapHeight={mapHeight} />;
  }
}

export default RobotMapPanel;
