import React from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  List,
  ListItem,
  IconButton,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import {
  AddCircle as AddIcon,
  ThreeSixty as ThreeSixtyIcon,
} from "@material-ui/icons";
import * as _ from "lodash";

import { robotActions } from "src/redux/actions";
import { getText } from "src/utils/MultilingualLoader";
import DropDownSelectorHorizontal from "src/components/Selectors/DropDownSelectorHorizontal";
import RoutineAction from "./RoutineAction";

const mapStateToProps = (state, props) => {
  const { robotMapLists } = state.robot;
  const { selected } = props;
  const robotMapList = selected && robotMapLists[selected.id];
  return { robotMapLists, robotMapList };
};

const mapDispatchToProps = (dispatch) => ({
  updateRobotConfigs: (robot_id, updConfigs) =>
    dispatch(robotActions.updateRobotConfigs(robot_id, updConfigs)),
  getRobotMapList: (robotId) =>
    dispatch(robotActions.getRobotConfigMapsAndRoutes(robotId)),
});

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    margin: "12px 0",
  },
  form: {
    width: "100%",
  },
  tableRowTop: {
    verticalAlign: "top",
  },
  tableCell: {
    borderStyle: "none",
  },
  submitButtons: {
    marginTop: theme.spacing(2),
    float: "right",
  },
  button: {
    margin: 6,
  },
  buttonText: {
    color: "white",
    textTransform: "initial",
  },
  list: {
    display: "inline-block",
    borderRadius: 5,
    border: "2px solid rgb(216, 215, 215)",
    padding: theme.spacing(1),
  },
  selectedItem: {
    //!important to override :hover
    backgroundColor: "rgba(76,127,114,0.25) !important",
  },
  listItem: {
    borderBottom: "2px solid rgba(0,0,0,0.07)",
    "&:last-child": {
      borderBottom: 0,
    },
  },
  selectorInput: {
    width: "100px",
    padding: "5px 20px 5px 20px",
  },
  IconButton: {
    padding: 5,
    color: theme.palette.primary.main,
  },
});

const actionOptions = [
  { label: "load_map_route", value: "load_map_route" },
  { label: "take_elevator", value: "take_elevator" },
  { label: "one_loop", value: "one_loop" },
  { label: "target_loop", value: "target_loop" },
  { label: "patrol", value: "patrol" },
  { label: "go_home", value: "go_home" },
  { label: "leave_home", value: "leave_home" },
];

class RobotConfigContainer extends React.Component {
  constructor(props) {
    super(props);
    const { configs } = props;
    this.state = {
      routines: (configs && configs.routines) || [],
      selectedRoutineIdx: null,
      selectedActionIdx: null,
      newRoutine: null,
      newAction: null,
      diaOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.configs) {
      const robotChanged =
        this.props.selected &&
        (!prevProps.selected ||
          this.props.selected.id !== prevProps.selected.id);
      // update routines if robot config routines change
      if (
        robotChanged ||
        !prevProps.configs ||
        !_.isEqual(this.props.configs.routines, prevProps.configs.routines)
      ) {
        this.setState({
          routines: this.props.configs.routines || [],
          selectedRoutineIdx: null,
          selectedActionIdx: null,
          newRoutine: null,
          newAction: null,
          diaOpen: false,
        });
      }
    }

    const { selected, getRobotMapList, robotMapLists } = this.props;
    if (
      selected &&
      selected.id &&
      (!prevProps.selected || selected.id !== prevProps.selected.id) &&
      !robotMapLists[selected.id]
    ) {
      getRobotMapList(selected.id);
    }
  }

  cancelCreateRoutine = () => {
    this.setState({
      selectedRoutineIdx: null,
      selectedActionIdx: null,
      newRoutine: null,
      newAction: null,
    });
  };

  deleteRoutine = (idx) => () => {
    const { selected, updateRobotConfigs } = this.props;
    const { routines } = this.state;
    const routineId = routines[idx] && routines[idx].id;
    if (!window.confirm(`${getText("delete_routine")} ${routineId}`)) return;
    const updRoutines = Object.assign([], routines);
    updRoutines.splice(idx, 1);
    updateRobotConfigs(selected.id, {
      routines: updRoutines,
    });
    this.setState({
      selectedRoutineIdx: null,
      selectedActionIdx: null,
      newRoutine: null,
      newAction: null,
    });
  };

  createRoutine = () => {
    this.setState({
      selectedRoutineIdx: -1,
      newRoutine: {
        id: new Date().getTime().toString(),
        name: "",
        actions: [],
      },
    });
  };

  cancelCreateAction = () => {
    this.setState({
      selectedActionIdx: null,
      newAction: null,
      diaOpen: false,
    });
  };

  deleteAction = (routineIdx, actionIdx) => () => {
    if (routineIdx === -1) {
      const newNewRoutine = Object.assign({}, this.state.newRoutine);
      newNewRoutine.actions && newNewRoutine.actions.splice(actionIdx, 1);
      this.setState({
        selectedActionIdx: null,
        newRoutine: newNewRoutine,
        diaOpen: false,
      });
    } else {
      const routines = Object.assign([], this.state.routines);
      routines[routineIdx] &&
        routines[routineIdx].actions &&
        routines[routineIdx].actions.splice(actionIdx, 1);
      this.setState({
        selectedActionIdx: null,
        routines,
        diaOpen: false,
      });
    }
  };

  createAction = () => {
    this.setState({
      selectedActionIdx: -1,
      newAction: {
        act: "",
        arg: {},
      },
    });
  };

  initialCreateAction = (event) => {
    const act = event.target.value;

    const { routines, selectedRoutineIdx, newRoutine } = this.state;
    const selectedRoutine =
      selectedRoutineIdx === -1
        ? newRoutine
        : routines && routines[selectedRoutineIdx];
    const actions = (selectedRoutine && selectedRoutine.actions) || [];
    if (
      act === "target_loop" &&
      (actions.length === 0 ||
        actions[actions.length - 1].act !== "load_map_route")
    ) {
      alert(getText("must_be_load_map_and_route"));
      return;
    }

    let arg = {};
    switch (act) {
      case "load_map_route":
        arg = {
          delay_secs: 0,
          map_id: "",
          route_id: "",
          reset_slam: false,
          x: null,
          y: null,
          theta: null,
        };
        break;
      case "take_elevator":
        arg = {
          from_floor: 0,
          to_floor: 0,
        };
        break;
      case "target_loop":
        arg = {
          target_list: [],
        };
        break;
      default:
        break;
    }
    this.setState({
      newAction: {
        act,
        arg,
      },
      diaOpen: true,
    });
  };

  isNoValue = (value) => {
    return value === undefined || value === null;
  };

  actionFieldCheck = (action) => {
    if (!action.act) {
      alert(getText("input_error_action"));
      return false;
    }
    if (action.act === "load_map_route" && action.arg) {
      if (!action.arg.map_id) {
        alert(getText("input_error_map_id"));
        return false;
      }
      if (!action.arg.route_id) {
        alert(getText("input_error_route_id"));
        return false;
      }
      if (action.arg.reset_slam) {
        if (
          this.isNoValue(action.arg.x) ||
          this.isNoValue(action.arg.y) ||
          this.isNoValue(action.arg.theta)
        ) {
          alert(getText("input_error_location"));
          return false;
        }
        if (
          this.isNoValue(action.arg.delay_secs) ||
          action.arg.delay_secs < 0
        ) {
          alert(getText("input_error_delay_sec"));
          return false;
        }
      }
    }
    if (
      action.act === "target_loop" &&
      action.arg &&
      action.arg.target_list.length === 0
    ) {
      alert(getText("input_error_targets"));
      return false;
    }
    if (action.act === "take_elevator" && action.arg) {
      if (this.isNoValue(action.arg.from_floor)) {
        alert(getText("input_error_from_floor"));
        return false;
      }
      if (this.isNoValue(action.arg.to_floor)) {
        alert(getText("input_error_to_floor"));
        return false;
      }
    }
    return true;
  };

  confirmCreateAction = () => {
    const { newAction, routines, selectedRoutineIdx, newRoutine } = this.state;
    if (!newAction || !this.actionFieldCheck(newAction)) return;
    if (selectedRoutineIdx === -1) {
      const newNewRoutine = Object.assign({}, newRoutine);
      newNewRoutine.actions && newNewRoutine.actions.push(newAction);
      this.setState({
        newRoutine: newNewRoutine,
        selectedActionIdx: null,
        newAction: null,
        diaOpen: false,
      });
    } else {
      const newRoutines = Object.assign([], routines);
      newAction &&
        newAction.act &&
        newRoutines[selectedRoutineIdx] &&
        newRoutines[selectedRoutineIdx].actions &&
        newRoutines[selectedRoutineIdx].actions.push(newAction);
      this.setState({
        routine: newRoutines,
        selectedActionIdx: null,
        newAction: null,
        diaOpen: false,
      });
    }
  };

  confirmEditAction = (routineIdx, actionIdx) => () => {
    let action = null;
    const { routines, newRoutine } = this.state;
    if (routineIdx === -1) {
      action =
        newRoutine && newRoutine.actions && newRoutine.actions[actionIdx];
    } else {
      action =
        routines[routineIdx] &&
        routines[routineIdx].actions &&
        routines[routineIdx].actions[actionIdx];
    }
    if (!action || !this.actionFieldCheck(action)) return;
    this.setState({
      diaOpen: false,
    });
  };

  handleRoutineChange = (prop, index) => (event) => {
    // add new routine
    if (index === -1) {
      this.setState({
        newRoutine: {
          ...this.state.newRoutine,
          [prop]: event.target.value,
        },
      });
    }
    // modify existed routine
    else {
      const { routines } = this.state;
      const newRoutines = Object.assign([], routines);
      if (newRoutines[index]) {
        newRoutines[index] = {
          ...newRoutines[index],
          [prop]: event.target.value,
        };
      }
      this.setState({
        routines: newRoutines,
      });
    }
  };

  getChangedActionArg = (arg, prop, type, event) => {
    let changedArg = Object.assign({}, arg);
    let value = event && event.target && event.target.value;
    if (type === "Integer") value = parseInt(value);
    if (type === "float") value = parseFloat(value);
    // reset route, x, y, theta when map changes
    if (prop === "map_id") {
      changedArg = {
        ...changedArg,
        map_id: value,
        route_id: null,
        reset_slam: false,
        x: null,
        y: null,
        theta: null,
      };
    }
    // reset x, y, theta when reset_slam changes
    else if (prop === "reset_slam") {
      value = event && event.target && event.target.checked;
      changedArg = {
        ...changedArg,
        reset_slam: value,
        x: null,
        y: null,
        theta: null,
      };
    } else if (prop === "target_loop") {
      const newTargets = event.target.checked
        ? [...arg.target_list, event.target.value]
        : arg.target_list.filter((id) => id !== event.target.value);
      changedArg = {
        ...changedArg,
        target_list: newTargets,
      };
    } else {
      changedArg = {
        ...changedArg,
        [prop]: value,
      };
    }
    return changedArg;
  };

  handleActionChange = (prop, type, routineIdx, actionIdx) => (event) => {
    // add new action
    if (actionIdx === -1) {
      let arg = this.getChangedActionArg(
        this.state.newAction && this.state.newAction.arg,
        prop,
        type,
        event
      );
      this.setState({
        newAction: {
          ...this.state.newAction,
          arg,
        },
      });
    }
    // edit current action
    else {
      const { routines, newRoutine } = this.state;
      if (routineIdx === -1) {
        const newNewRoutine = Object.assign({}, newRoutine);
        const action =
          newNewRoutine.actions && newNewRoutine.actions[actionIdx];
        if (action)
          action.arg = this.getChangedActionArg(action.arg, prop, type, event);
        if (prop === "map_id" || prop === "route_id") {
          const nextAction =
            newNewRoutine.actions &&
            newNewRoutine.actions.length > actionIdx + 1 &&
            newNewRoutine.actions[actionIdx + 1];
          if (nextAction && nextAction.act === "target_loop") {
            newNewRoutine.actions[actionIdx + 1].arg.target_list = [];
          }
        }
        this.setState({
          newRoutine: newNewRoutine,
        });
      } else {
        const newRoutines = Object.assign([], routines);
        const action =
          newRoutines[routineIdx] &&
          newRoutines[routineIdx].actions &&
          newRoutines[routineIdx].actions[actionIdx];
        if (action)
          action.arg = this.getChangedActionArg(action.arg, prop, type, event);
        if (prop === "map_id" || prop === "route_id") {
          const nextAction =
            newRoutines[routineIdx].actions &&
            newRoutines[routineIdx].actions.length > actionIdx + 1 &&
            newRoutines[routineIdx].actions[actionIdx + 1];
          if (nextAction && nextAction.act === "target_loop") {
            newRoutines[routineIdx].actions[actionIdx + 1].arg.target_list = [];
          }
        }
        this.setState({
          routines: newRoutines,
        });
      }
    }
  };

  onRelocalize = (routineIdx, actionIdx) => (x, y, theta) => {
    x = parseFloat(x);
    y = parseFloat(y);
    theta = parseFloat(theta);
    // add new action
    if (actionIdx === -1) {
      this.setState({
        newAction: {
          ...this.state.newAction,
          arg: {
            ...(this.state.newAction && this.state.newAction.arg),
            x,
            y,
            theta,
          },
        },
      });
    } else {
      const { routines, newRoutine } = this.state;
      if (routineIdx === -1) {
        const newNewRoutine = Object.assign({}, newRoutine);
        let action = newNewRoutine.actions && newNewRoutine.actions[actionIdx];
        action &&
          action.arg &&
          (action.arg.x = x) &&
          (action.arg.y = y) &&
          (action.arg.theta = theta);
        this.setState({
          newRoutine: newNewRoutine,
        });
      } else {
        const newRoutines = Object.assign([], routines);
        let action =
          newRoutines[routineIdx] &&
          newRoutines[routineIdx].actions &&
          newRoutines[routineIdx].actions[actionIdx];
        action &&
          action.arg &&
          (action.arg.x = x) &&
          (action.arg.y = y) &&
          (action.arg.theta = theta);
        this.setState({
          routines: newRoutines,
        });
      }
    }
  };

  submitRoutines = () => {
    const { selected, updateRobotConfigs } = this.props;
    const { routines, selectedRoutineIdx, newRoutine } = this.state;
    const updRoutines = Object.assign([], routines);
    if (selectedRoutineIdx === -1) updRoutines.push(newRoutine);
    updateRobotConfigs(selected.id, {
      routines: updRoutines,
    });
    this.setState({
      selectedRoutineIdx: null,
      selectedActionIdx: null,
      newRoutine: null,
      newAction: null,
    });
  };

  onRevertOneLoop = (actionIndex) => (e) => {
    e.stopPropagation();
    const { selectedRoutineIdx, routines, newRoutine } = this.state;
    const selectedRoutine =
      selectedRoutineIdx === -1
        ? newRoutine
        : routines && routines[selectedRoutineIdx];
    if (window.confirm(getText("confirm_revert_one_loop"))) {
      if (
        actionIndex === 0 ||
        selectedRoutine.actions[actionIndex - 1].act !== "load_map_route"
      ) {
        alert(getText("must_be_load_map_and_route"));
        return;
      }
      const { actions } = selectedRoutine;
      const loadMapAction =
        actionIndex === -1
          ? actions[actions.length - 1]
          : actions[actionIndex - 1];
      const mapId = loadMapAction.arg.map_id;
      const routeId = loadMapAction.arg.route_id;
      const { robotMapList } = this.props;
      const map = robotMapList.maps.find((map) => map.id === mapId);
      let targetIds = [];
      if (map) {
        const route = map.mapRoutes.find((route) => route.id === routeId);
        if (route) {
          const wayPoints = route.way_points;
          targetIds = wayPoints
            .map((wayPoint) =>
              (wayPoint.actions || []).map((action) =>
                action.act === "snapshot" && action.arg.target_id
                  ? action.arg.target_id
                  : ""
              )
            )
            .flat()
            .filter((targetId) => Boolean(targetId));
        }
      }
      if (selectedRoutineIdx === -1) {
        this.setState({
          newRoutine: {
            ...newRoutine,
            actions: newRoutine.actions.map((action, index) =>
              index === actionIndex
                ? {
                    act: "target_loop",
                    arg: {
                      target_list: targetIds,
                    },
                  }
                : action
            ),
          },
        });
      } else {
        this.setState({
          routines: routines.map((routine, routineIndex) =>
            routineIndex === selectedRoutineIdx
              ? {
                  ...routine,
                  actions: routine.actions.map((action, actionIdx) =>
                    actionIndex === actionIdx
                      ? {
                          act: "target_loop",
                          arg: {
                            target_list: targetIds,
                          },
                        }
                      : action
                  ),
                }
              : routine
          ),
        });
      }
    }
  };

  render() {
    const { classes, robotMapList, targets } = this.props;
    const {
      selectedRoutineIdx,
      routines,
      newRoutine,
      selectedActionIdx,
      newAction,
      diaOpen,
    } = this.state;
    const selectedRoutine =
      selectedRoutineIdx === -1
        ? newRoutine
        : routines && routines[selectedRoutineIdx];
    const actions = (selectedRoutine && selectedRoutine.actions) || [];
    const action =
      selectedActionIdx === -1
        ? newAction
        : actions && actions[selectedActionIdx];

    return (
      <>
        <form className={classes.form}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCell} padding="default">
                  <Typography variant="body2">{getText("routine")}</Typography>
                </TableCell>
                <TableCell className={classes.tableCell} padding="default">
                  <Select
                    variant="outlined"
                    input={
                      <OutlinedInput
                        labelWidth={0}
                        classes={{ input: classes.selectorInput }}
                      />
                    }
                    value={
                      selectedRoutineIdx !== null ? selectedRoutineIdx : ""
                    }
                    onChange={(event) =>
                      this.setState({ selectedRoutineIdx: event.target.value })
                    }
                    disabled={selectedRoutineIdx === -1}
                  >
                    {routines
                      .map((routine, index) => ({
                        label: `${routine.id} ${routine.name}`,
                        value: index,
                      }))
                      .map((option, index) => (
                        <MenuItem
                          key={index}
                          className={classes.menuItem}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                  </Select>
                  {selectedRoutineIdx !== -1 && (
                    <IconButton
                      className={classes.IconButton}
                      onClick={this.createRoutine}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
              {selectedRoutineIdx !== null && (
                <>
                  {selectedRoutineIdx === -1 && (
                    <TableRow>
                      <TableCell
                        className={classes.tableCell}
                        padding="default"
                      >
                        <Typography variant="body2">{getText("id")}</Typography>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        padding="default"
                      >
                        <input
                          type="text"
                          style={{ fontSize: "1rem" }}
                          value={selectedRoutine && selectedRoutine.id}
                          onChange={this.handleRoutineChange(
                            "id",
                            selectedRoutineIdx
                          )}
                          disabled
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell className={classes.tableCell} padding="default">
                      <Typography variant="body2">{getText("name")}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} padding="default">
                      <input
                        type="text"
                        style={{ fontSize: "1rem" }}
                        value={selectedRoutine && selectedRoutine.name}
                        onChange={this.handleRoutineChange(
                          "name",
                          selectedRoutineIdx
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                      <TableCell className={classes.tableCell} padding="default">
                        <Typography variant="body2">
                          {getText('parent_routine')}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell} padding="default">
                        <Select
                          variant="outlined"
                          input={<OutlinedInput labelWidth={0} classes={{ input: classes.selectorInput }} />}
                          value={(selectedRoutine && selectedRoutine.parent_routine_id) || null}
                          onChange={this.handleRoutineChange('parent_routine_id', selectedRoutineIdx)}
                        >
                          {routines.map((routine, index) => ({ label: `${routine.id} ${routine.name}`, value: routine.id })).map((option, index) =>
                            <MenuItem key={index} className={classes.menuItem} value={option.value}>{option.label}</MenuItem>
                          )}
                          <MenuItem key={-1} className={classes.menuItem} value={null}>{getText('others')}</MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow> */}
                  <TableRow className={classes.tableRowTop}>
                    <TableCell className={classes.tableCell} padding="default">
                      <Typography variant="body2">
                        {getText("action")}
                      </Typography>
                    </TableCell>

                    <TableCell className={classes.tableCell} padding="default">
                      <Grid container direction="row" alignItems="flex-start">
                        {actions.length > 0 && (
                          <Grid>
                            <List className={classes.list}>
                              {actions.map((action, index) => (
                                <ListItem
                                  key={index}
                                  className={classes.listItem}
                                  button={true}
                                  classes={{
                                    selected: classes.selectedItem,
                                  }}
                                  alignItems="center"
                                  selected={selectedActionIdx === index}
                                  onClick={() =>
                                    this.setState({
                                      selectedActionIdx: index,
                                      diaOpen: true,
                                    })
                                  }
                                >
                                  {action.act}
                                  {action.act === "one_loop" && (
                                    <ThreeSixtyIcon
                                      onClick={this.onRevertOneLoop(index)}
                                      style={{ marginLeft: 10 }}
                                    />
                                  )}
                                </ListItem>
                              ))}
                            </List>
                          </Grid>
                        )}
                        <Grid>
                          {selectedActionIdx !== -1 && (
                            <IconButton
                              className={classes.IconButton}
                              onClick={this.createAction}
                            >
                              <AddIcon />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  {selectedActionIdx === -1 && (
                    <TableRow>
                      <TableCell
                        className={classes.tableCell}
                        padding="default"
                      >
                        <Typography variant="body2">
                          {getText("create_action")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        padding="default"
                      >
                        <DropDownSelectorHorizontal
                          title={""}
                          options={actionOptions}
                          value={action.act || ""}
                          onChange={this.initialCreateAction}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
          <div className={classes.submitButtons}>
            {selectedRoutineIdx !== null && selectedRoutineIdx !== -1 && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.deleteRoutine(selectedRoutineIdx)}
                className={classes.button}
              >
                <Typography variant="body2" className={classes.buttonText}>
                  {selectedRoutineIdx === -1
                    ? getText("cancel")
                    : getText("delete_routine")}
                </Typography>
              </Button>
            )}
            {selectedRoutineIdx === -1 && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.cancelCreateRoutine}
                className={classes.button}
              >
                <Typography variant="body2" className={classes.buttonText}>
                  {getText("cancel")}
                </Typography>
              </Button>
            )}
            {selectedRoutineIdx !== null && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.submitRoutines}
              >
                <Typography variant="body2" className={classes.buttonText}>
                  {getText("save")}
                </Typography>
              </Button>
            )}
          </div>
        </form>
        <RoutineAction
          open={diaOpen}
          action={action}
          targets={targets}
          selectedRoutineIdx={selectedRoutineIdx}
          selectedActionIdx={selectedActionIdx}
          actions={actions}
          robotMapList={robotMapList}
          handleActionChange={this.handleActionChange}
          onRelocalize={this.onRelocalize}
          deleteAction={this.deleteAction}
          cancelCreateAction={this.cancelCreateAction}
          confirmCreateAction={this.confirmCreateAction}
          confirmEditAction={this.confirmEditAction}
        />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RobotConfigContainer));
