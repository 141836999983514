import React from 'react';
import { withStyles, Grid, FormControlLabel, Checkbox, Typography } from '@material-ui/core';

const styles = theme => ({
  typeOptions: {
    maxHeight: 300,
    overflow: 'auto'
  }
});

const EventTypes = props => {
  const { classes, types, typeOptions, onChange } = props;
  return (
    <Grid container className={classes.typeOptions}>
    {
      typeOptions.map(type => (
        <Grid item key={type.name} xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={types[type.name] ? types[type.name] : false}
                onChange={onChange(type.name)}
                color="primary"
              />
            }
            label={<Typography variant='body2'>{type.label}</Typography>}
          />
        </Grid>
      ))
    }
    </Grid>
  )
}

export default withStyles(styles)(EventTypes);