import React from 'react';
import { withStyles, Grid, Button, Typography, InputLabel, Input } from '@material-ui/core';

import { getText } from 'src/utils/MultilingualLoader';
import GoogleMap from './GoogleMap';
import GaodeMap from './GaodeMap';

const styles = theme => ({
  button: {
    maxWidth: 400,
    background: 'rgba(76,127,114,0.40)',
    borderRadius: '6px',
    border: '1px solid',
    width: '100%',
    height: '100%'
  },
  label: {
    fontSize: '1rem',
    textTransform: 'initial'
  },
});

const DrawSiteMap = props => {
  const { classes, defaultZoom, defaultCenter, newPolygon, polygons, polygonName, curAction, setAction,
    onNameChange, onGoogleMapClick, onGaodeMapClick, onRepaint, onConfirm, onCancel, onPolygonEdit } = props;

  return (
    <Grid
      container={true}
      justify="flex-start"
      direction="row"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={12}>
        {
          process.env.REACT_APP_DOMAIN === '.turingvideo.cn' ?
            <GaodeMap
              defaultZoom={defaultZoom}
              defaultCenter={defaultCenter}
              newPolygon={newPolygon}
              polygons={polygons}
              curAction={curAction}
              onMapClick={onGaodeMapClick}
              onPolygonEdit={onPolygonEdit}
            /> :
            <GoogleMap
              googleMapURL={`${process.env.REACT_APP_GOOGLE_MAP_API_URL}/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                &v=3.exp&libraries=places`}
              loadingElement={<div style={{ height: '85vh' }} />}
              containerElement={<div style={{
                paddingBottom: 16,
                paddingLeft: 16,
                paddingRight: 16,
                flexGrow: 1,
                position: 'relative'
              }} />}
              mapElement={<div style={{ height: '85vh' }} />}
              defaultZoom={defaultZoom}
              defaultCenter={defaultCenter}
              newPolygon={newPolygon}
              polygons={polygons}
              curAction={curAction}
              onMapClick={onGoogleMapClick}
              onPolygonEdit={onPolygonEdit}
            />
        }
      </Grid>
      {curAction !== 'add' && <>
        <Grid item xs={6} lg={2} >
          <Button
            className={classes.button}
            onClick={setAction('add')}
          >
            <Typography className={classes.label}>
              {getText('append_polygon')}
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={6} lg={2} >
          <Button
            className={classes.button}
            onClick={setAction('delete')}
          >
            <Typography className={classes.label}>
              {getText('delete_polygon')}
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={6} lg={2} >
          <Button
            className={classes.button}
            onClick={onRepaint}
          >
            <Typography className={classes.label}>
              {getText('repaint')}
            </Typography>
          </Button>
        </Grid>
      </>}
      {curAction === 'add' &&
        <Grid item xs={6} lg={3}>
          <InputLabel htmlFor="name">{getText('name')}</InputLabel>
          <Input
            id="name"
            name="name"
            autoFocus={true}
            value={polygonName}
            onChange={onNameChange}
          />
        </Grid>
      }
      <Grid item xs={6} lg={2}>
        <Button
          className={classes.button}
          onClick={onConfirm}
        >
          <Typography className={classes.label}>
            {getText('confirm')}
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={6} lg={2} >
        <Button
          className={classes.button}
          onClick={onCancel}
        >
          <Typography className={classes.label}>
            {getText('cancel')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(DrawSiteMap);
