import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import RoutineHistoryTime from "./RoutineHistoryTime";
import RoutineHistoryContent from "./RoutineHistoryContent";

export const sidePanelWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto'
  },
  sidePanel: {
    paddingBottom: 0,
    marginBottom: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: sidePanelWidth,
    height: '100%',
    overflow: 'auto',
  },
  mainPanel: {
    width: `calc(100% - ${sidePanelWidth}px)`,
    paddingBottom: 0,
    paddingRight: theme.spacing(2),
    marginBottom: 0,
    flex: 1,
    height: '100%',
    overflow: 'auto'
  },
  toolbar: theme.mixins.toolbar,
});

const RoutineHistory = props => {
  const { classes } = props;
  return (
    <Grid
    className={classes.root}
    direction="row"
    container={true}
    justify="flex-start"
    alignItems="flex-start"
    spacing={0}
    >
    <Grid className={classes.sidePanel} item={true}>
      <RoutineHistoryTime />
    </Grid>
    <Grid className={classes.mainPanel} item={true}>
      <RoutineHistoryContent />
    </Grid>
  </Grid>
  );
};  

export default withStyles(styles)(RoutineHistory);

