import React from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';

const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

const CircularLoading = props => {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <CircularProgress
        color="primary"
      />
    </div>
  );
}

export default withStyles(styles)(CircularLoading);