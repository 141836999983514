import { manageConstants } from 'src/redux/constants';
import { manageServices } from 'src/services';
import { notificationActions } from '.';
import history from 'src/utils/history';
import { getText } from 'src/utils/MultilingualLoader';

export const changeType = selectedType => {
  return {
    type: manageConstants.CHANGE_TYPE,
    selectedType
  }
}

export const getList = listType => {
  const request = () => {
    return {
      type: manageConstants.GET_LIST_REQUEST
    };
  };

  const success = (result) => {
    return {
      type: manageConstants.GET_LIST_SUCCESS,
      list: result.list,
      next: result.next
    };
  };

  const failure = () => {
    return {
      type: manageConstants.GET_LIST_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    switch (listType) {
      case manageConstants.COMPANY:
        manageServices.getCompanies()
          .then(result => {
            dispatch(success(result));
          })
          .catch(error => {
            dispatch(failure());
          });
        return;
      case manageConstants.GUARD_ADMIN:
        manageServices.getGuardAdmins()
          .then(result => {
            dispatch(success(result));
          })
          .catch(error => {
            dispatch(failure());
          });
        return;
      case manageConstants.CUSTOMER:
        manageServices.getCustomers()
          .then(result => {
            dispatch(success(result));
          })
          .catch(error => {
            dispatch(failure());
          });
        return;
      case manageConstants.GUARD:
        manageServices.getGuards()
          .then(result => {
            dispatch(success(result));
          })
          .catch(error => {
            dispatch(failure());
          });
        return;
      case manageConstants.OPERATOR:
        manageServices.getOperators()
          .then(result => {
            dispatch(success(result));
          })
          .catch(error => {
            dispatch(failure());
          });
        return;
      default:
        dispatch(failure());
        return;
    }
  };
};

export const getMoreAccounts = (listType, nextUrl) => {
  const request = () => {
    return {
      type: manageConstants.GET_MORE_ACCOUNTS_REQUEST
    };
  };

  const success = result => {
    return {
      type: manageConstants.GET_MORE_ACCOUNTS_SUCCESS,
      list: result.list,
      next: result.next
    };
  };

  const failure = () => {
    return {
      type: manageConstants.GET_LIST_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    switch (listType) {
      case manageConstants.CUSTOMER:
        manageServices.getMoreCustomers(nextUrl)
          .then(result => {
            dispatch(success(result));
          })
          .catch(error => {
            dispatch(failure());
          });
        return;
      default:
        dispatch(failure());
        return;
    };
  }
}

export const createAccount = data => {
  const request = () => {
    return {
      type: manageConstants.UPDATE_ACCOUNT_REQUEST
    };
  };

  const success = () => {
    return {
      type: manageConstants.UPDATE_ACCOUNT_SUCCESS
    };
  };

  const failure = () => {
    return {
      type: manageConstants.UPDATE_ACCOUNT_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    switch (data.selectedType) {
      case manageConstants.COMPANY:
        manageServices.addCompany(data)
          .then(result => {
            dispatch(success(result));
            dispatch(notificationActions.addNotification(`${getText('add')}${getText('words_space')}${getText('guard_company')}${getText('words_space')}${getText('succeeded')}`));
            history.push('/adminmanage/company');
          })
          .catch(error => {
            dispatch(failure());
            dispatch(notificationActions.addNotification(`${getText('add')}${getText('words_space')}${getText('guard_company')}${getText('words_space')}${getText('failed')}`));
            history.push('/adminmanage/company');
          });
        return;
      case manageConstants.GUARD_ADMIN:
        manageServices.addGuardAdmin(data)
          .then(result => {
            dispatch(success());
            dispatch(notificationActions.addNotification(`${getText('add')}${getText('words_space')}${getText('guard_admin')}${getText('words_space')}${getText('succeeded')}`));
            history.push('/adminmanage/guardadmin');
          })
          .catch(error => {
            dispatch(failure());
            dispatch(notificationActions.addNotification(`${getText('add')}${getText('words_space')}${getText('guard_admin')}${getText('words_space')}${getText('failed')}`));
            history.push('/adminmanage/guardadmin');
          });
        return;
      case manageConstants.CUSTOMER:
        manageServices.addCustomer(data)
          .then(result => {
            dispatch(success());
            dispatch(notificationActions.addNotification(`${getText('add')}${getText('words_space')}${getText('customer')}${getText('words_space')}${getText('succeeded')}`));
            history.push('/adminmanage/customer');
          })
          .catch(error => {
            dispatch(failure());
            dispatch(notificationActions.addNotification(`${getText('add')}${getText('words_space')}${getText('customer')}${getText('words_space')}${getText('failed')}`));
            history.push('/adminmanage/customer');
          });
        return;
      case manageConstants.GUARD:
        manageServices.addGuard(data)
          .then(result => {
            dispatch(success());
            dispatch(notificationActions.addNotification(`${getText('add')}${getText('words_space')}${getText('guard')}${getText('words_space')}${getText('succeeded')}`));
            history.push('/guardmanage/guard');
          })
          .catch(error => {
            dispatch(failure());
            dispatch(notificationActions.addNotification(`${getText('add')}${getText('words_space')}${getText('guard_admin')}${getText('words_space')}${getText('failed')}`));
            history.push('/guardmanage/guard');
          });
        return;
      case manageConstants.OPERATOR:
        manageServices.addOperator(data)
          .then(result => {
            dispatch(success());
            dispatch(notificationActions.addNotification(`${getText('add')}${getText('words_space')}${getText('monitoring_operator')}${getText('words_space')}${getText('succeeded')}`));
            history.push('/guardmanage/operator');
          })
          .catch(error => {
            dispatch(failure());
            dispatch(notificationActions.addNotification(`${getText('add')}${getText('words_space')}${getText('monitoring_operator')}${getText('words_space')}${getText('failed')}`));
            history.push('/guardmanagee/operator');
          });
        return;
      default:
        dispatch(failure());
        return;
    }
  };
}

export const updateAccount = data => {
  const request = () => {
    return {
      type: manageConstants.UPDATE_ACCOUNT_REQUEST
    };
  };

  const success = () => {
    return {
      type: manageConstants.UPDATE_ACCOUNT_SUCCESS
    };
  };

  const failure = () => {
    return {
      type: manageConstants.UPDATE_ACCOUNT_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    switch (data.selectedType) {
      case manageConstants.COMPANY:
        manageServices.updateCompany(data)
          .then(result => {
            dispatch(success(result));
            dispatch(notificationActions.addNotification(`${getText('update')}${getText('words_space')}${getText('company')}${getText('words_space')}${getText('succeeded')}`));
            dispatch(getList(data.selectedType));
            history.push('/adminmanage/company');
          })
          .catch(error => {
            dispatch(failure());
            dispatch(notificationActions.addNotification(`${getText('update')}${getText('words_space')}${getText('company')}${getText('words_space')}${getText('failed')}`));
            history.push('/adminmanage/company');
          });
        return;
      case manageConstants.CUSTOMER:
        manageServices.updateCustomer(data)
          .then(result => {
            dispatch(success(result));
            dispatch(notificationActions.addNotification(`${getText('update')}${getText('words_space')}${getText('customer')}${getText('words_space')}${getText('succeeded')}`));
            dispatch(getList(data.selectedType));
            history.push('/adminmanage/customer');
          })
          .catch(error => {
            dispatch(failure());
            dispatch(notificationActions.addNotification(`${getText('update')}${getText('words_space')}${getText('customer')}${getText('words_space')}${getText('failed')}`));
            history.push('/adminmanage/customer');
          });
        return;
      default:
        dispatch(failure());
        return;
    }
  };
}

export const deleteAccount = data => {
  const request = () => {
    return {
      type: manageConstants.UPDATE_ACCOUNT_REQUEST
    };
  };

  const success = () => {
    return {
      type: manageConstants.UPDATE_ACCOUNT_SUCCESS
    };
  };

  const failure = () => {
    return {
      type: manageConstants.UPDATE_ACCOUNT_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    switch (data.selectedType) {
      case manageConstants.COMPANY:
        manageServices.deleteCompany(data)
          .then(result => {
            dispatch(success(result));
            dispatch(notificationActions.addNotification(`${getText('delete')}${getText('words_space')}${getText('company')}${getText('words_space')}${getText('succeeded')}`));
            dispatch(getList(data.selectedType));
            history.push('/adminmanage/company');
          })
          .catch(error => {
            dispatch(failure());
            dispatch(notificationActions.addNotification(`${getText('delete')}${getText('words_space')}${getText('company')}${getText('words_space')}${getText('failed')}`));
            history.push('/adminmanage/company');
          });
        return;
      case manageConstants.GUARD_ADMIN:
        manageServices.deleteGuardAdmin(data)
          .then(result => {
            dispatch(success(result));
            dispatch(notificationActions.addNotification(`${getText('delete')}${getText('words_space')}${getText('guard_admin')}${getText('words_space')}${getText('succeeded')}`));
            dispatch(getList(data.selectedType));
            history.push('/adminmanage/guardadmin');
          })
          .catch(error => {
            dispatch(failure());
            dispatch(notificationActions.addNotification(`${getText('delete')}${getText('words_space')}${getText('guard_admin')}${getText('words_space')}${getText('failed')}`));
            history.push('/adminmanage/guardadmin');
          });
        return;
      case manageConstants.OPERATOR:
        manageServices.deleteOperator(data)
          .then(result => {
            dispatch(success(result));
            dispatch(notificationActions.addNotification(`${getText('delete')}${getText('words_space')}${getText('monitoring_operator')}${getText('words_space')}${getText('succeeded')}`));
            dispatch(getList(data.selectedType));
            history.push('/guardmanage/operator');
          })
          .catch(error => {
            dispatch(failure());
            dispatch(notificationActions.addNotification(`${getText('delete')}${getText('words_space')}${getText('monitoring_operator')}${getText('words_space')}${getText('failed')}`));
            history.push('/guardmanage/operator');
          });
        return;
      default:
        dispatch(failure());
        return;
    }
  };
}

export const setPage = page => {
  return {
    type: manageConstants.SET_PAGE,
    page
  };
}

export const getOrganizations = () => {
  return manageServices.getCompanies();
}

export const getGuardAdminProfile = uid => {
  return manageServices.getGuardAdminProfile(uid);
}
