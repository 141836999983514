import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {ProAutocomplete} from 'turing-ui';

// import {TrackingTarget} from 'src/models/employee';
import TrackingObjectOptionRender from '../TrackingObjectOptionRender';
import {useTrackingObjectSearch} from './hook';
// import {useIntl} from 'react-intl';

export function PeopleSearchComponent({
  onSelect,
  defaultValue,
}) {
  // const {formatMessage} = useIntl();
  const {trackingObjectOptions: options, search} = useTrackingObjectSearch();
  return (
    <ProAutocomplete
      placeholder={'Search People'}
      value={defaultValue ? defaultValue.id : undefined}
      options={options.map(option => {
        return {...option, label: option.tracking_object_name || '', value: option.id};
      })}
      renderOption={v => <TrackingObjectOptionRender trackingObjectOption={v} />}
      request={search}
      onChange={(id, trackObject) => onSelect(trackObject)}
      startAdornment={<SearchIcon style={{width: 20, height: 20, fillOpacity: 0.54}} />}
    />
  );
}
