import React from 'react';
import { withStyles, Grid } from '@material-ui/core';

import TaskList from './TaskList';
import TaskFilter from './TaskFilter';

const styles = theme => ({
  root: {
    minHeight: 300
  }
})

const TaskPanel = (props) => {
  const {classes, inspectionId} = props;

  return (
    <Grid className={classes.root}>
      <Grid>
        <TaskFilter inspectionId={inspectionId} />
      </Grid>
      <Grid>
        <TaskList inspectionId={inspectionId} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(TaskPanel);