/* eslint-disable @typescript-eslint/naming-convention */
// import {MinCameras, CameraListItem} from 'src/models/camera';

export const SET_FILTER = 'DEVICE_SET_FILTER';
export const CLEAR_FILTER = 'DEVICE_CLEAR_FILTER';
export const SET_CAMERA_LOADING = 'DEVICE_SET_CAMERA_LOADING';
export const SET_CAMERA_LIST = 'DEVICE_SET_CAMERA_LIST';
export const UPDATE_CAMERA_LIST = 'DEVICE_UPDATE_CAMERA_LIST';
export const SET_EVENT_TYPES = 'DEVICE_SET_EVENT_TYPES';
export const SET_ALL_SERVERS = 'DEVICE_SET_ALL_SERVERS';
export const SET_MIN_CAMERAS_MAPPING = 'SET_MIN_CAMERAS_MAPPING';
export const CLEAR_CAMERA_LIST = 'DEVICE_CLEAR_CAMERA_LIST';
