import React from 'react';
import {
  withStyles, Grid, Table, TableHead, TableBody, TableRow, TableCell, Input, Button, Card, CardContent, Typography,
  Dialog, DialogTitle, DialogContent
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import { getText } from 'src/utils/MultilingualLoader';
import DrawSiteMap from '../AddSite/DrawSiteMap';

const styles = theme => ({
  button: {
    margin: 24,
  },
  label: {
    color: 'white',
    textTransform: 'initial'
  },
  tableGrid: {
    height: '100%',
  },
  tableCard: {
    overflow: 'auto'
  },
  table: {
    width: '100%',
  },
  header: {
    fontSize: '1rem'
  },
  row: {
    fontSize: '1rem'
  },
  iconButton: {
    marginRight: '0.5rem',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  dialog: {
    width: window.innerWidth * 0.8,
    margin: 'auto',
  },
});

const tableHeaders = [
  'id',
  'name',
  'location',
  'note',
  'site_manager',
  'devices',
  ' '
];

const SiteList = props => {
  const {
    classes,
    sites,
    editId,
    editValue,
    mapOpen,
    onClickAdd,
    onClickAssign,
    onClickDelete,
    onClickEdit,
    onClickSubmit,
    handleChange,
    onMapOpen,
    onMapClose,
    setPolygons
  } = props;

  return (
    <Grid
      container={true}
      justify="flex-start"
      direction="row"
      alignItems="stretch"
      spacing={0}
    >
      <Grid item={true} xs={12}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onClickAdd}
        >
          <Typography variant="body2" className={classes.label}>
            {getText('add_site')}
          </Typography>
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onClickAssign}
        >
          <Typography variant="body2" className={classes.label}>
            {getText('assign_managers')}
          </Typography>
        </Button>
      </Grid>
      <Grid item={true} xs={12} className={classes.tableGrid}>
        <Card className={classes.tableCard}>
          <CardContent>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => (<TableCell key={index} className={classes.header}>{getText(header)}</TableCell>))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sites.map(site => {
                  const editing = site.id === editId;
                  return (
                    <TableRow key={site.id}>
                      <TableCell className={classes.row}>
                        {site.id}
                      </TableCell>
                      <TableCell className={classes.row}>{editing ?
                        <Input
                          id="name"
                          name="name"
                          autoFocus={true}
                          value={editValue.name}
                          onChange={handleChange('name')}
                        /> : site.name}
                      </TableCell>
                      <TableCell className={classes.row}>{editing ?
                        <>
                          <Input
                            id="location"
                            name="location"
                            autoFocus={true}
                            value={editValue.location}
                            onChange={handleChange('location')}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={onMapOpen}
                          >
                            <Typography variant="body2" className={classes.label}>
                              {getText('draw_location_polygons')}
                            </Typography>
                          </Button>
                        </> : site.location}
                      </TableCell>
                      <TableCell className={classes.row}>{editing ?
                        <Input
                          id="note"
                          name="note"
                          autoFocus={true}
                          value={editValue.note}
                          onChange={handleChange('note')}
                        /> : site.note}
                      </TableCell>
                      <TableCell className={classes.row}>{site.managements ? site.managements.map(management => {
                        if (management && management.site_manager && management.site_manager.user)
                          return `${management.site_manager.user.first_name} ${management.site_manager.user.last_name}`;
                        else return '';
                      }).join(', ') : ''}
                      </TableCell>
                      <TableCell className={classes.row}>
                        {getText('robots')}: {site.robots ? site.robots.length : 0}
                        {' '}
                        {getText('cameras')}: {site.boxes && site.boxes.length > 0 ? site.boxes.map(
                          box => box.camera_cnt ? box.camera_cnt : 0
                        ).reduce(
                          (totalcount, camera_cnt) => totalcount + camera_cnt
                        )
                          : 0}
                      </TableCell>
                      <TableCell className={classes.row}>
                        {editing ?
                          <CheckIcon className={classes.iconButton} onClick={onClickSubmit(site.id)} />
                          : <EditIcon className={classes.iconButton} onClick={onClickEdit(site.id)} />
                        }
                        <DeleteIcon className={classes.iconButton} onClick={onClickDelete(site.id)} />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            {editId !== -1 &&
              <Dialog
                fullScreen
                className={classes.dialog}
                open={mapOpen}
                onClose={onMapClose}
              >
                <DialogTitle>
                  <CloseIcon
                    className={classes.closeButton}
                    onClick={onMapClose}
                  />
                </DialogTitle>
                <DialogContent>
                  <DrawSiteMap 
                    polygons={sites.find(site => site.id === editId) ? sites.find(site => site.id === editId).polygons : []} 
                    setPolygons={setPolygons} 
                    onMapClose={onMapClose}
                  />
                </DialogContent>
              </Dialog>
            }
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(SiteList);
