import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import RadioSelector from 'src/components/Selectors/RadioSelector';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({});

const allStates = [
  { value: 'all', label: getText('all') },
  { value: 'online', label: getText('online') },
  { value: 'offline', label: getText('offline') }
];

const RobotStatusSelector = props => {
  const { value, onChange: handleChange } = props;
  return (
    <RadioSelector
      title={getText('robot_status')}
      options={allStates}
      onChange={handleChange}
      value={value} />
  );
};

RobotStatusSelector.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

RobotStatusSelector.defaultProps = {
  value: 'all',
  onChange: (event, value) => {}
};

export default withStyles(styles)(RobotStatusSelector);
