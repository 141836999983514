import React from 'react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';
import {
  Grid,
  Divider,
  Typography,
  Breadcrumbs,
  Link,
  makeStyles,
  Button,
  Tooltip,
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  DirectionsCar as DirectionsCarIcon,
  DirectionsWalk as DirectionsWalkIcon,
  DirectionsBike as MotorcycleIcon,
} from '@material-ui/icons';

// import {ClipFilter} from 'src/redux/types/clip';
import {useDetailContext, useIsPeople, useIsVechicle} from '../../hook';
import {EllipseComponent} from '../ellipse';

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    width: 'auto',
  },
  divider: {
    margin: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: '60%',
    background: theme.palette.action.disabledBackground,
  },
  pointer: {
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'underline',
  },
  typeText: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.primary.main,
    borderBottomStyle: 'solid',
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
  },
  time: {
    color: (theme.palette).other.primary,
  },
  back: {
    marginTop: 3,
  },
  disableLink: {
    textTransform: 'capitalize',
    padding: 0,
  },
  vehicle: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export function DetailNavigateInfoComponent() {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      alignItems="center"
      wrap="nowrap"
      spacing={2}
      classes={{item: classes.flex}}
    >
      <BackNavigateComponent />
      <Grid item container classes={{item: classes.flex}}>
        <DetailTypeComponent />
        <Grid item container classes={{item: classes.flex}}>
          <Divider orientation="vertical" flexItem className={classes.divider} />
        </Grid>
        <DetailRelatedComponent />
      </Grid>
    </Grid>
  );
}

function BackNavigateComponent() {
  const classes = useStyles();
  const history = useHistory();
  const back = () => {
    history.push(history.location.pathname.replace(/(.*)\/.*/, '$1')); // '/alert/...'=>'/alert'
  };
  return (
    <Grid item onClick={back} classes={{item: clsx(classes.pointer, classes.back)}}>
      <ArrowBackIcon />
    </Grid>
  );
}

function DetailTypeComponent() {
  const classes = useStyles();
  return (
    <Grid item container alignItems="center" classes={{item: classes.flex}}>
      <DetailTypeIconComponent />
      <DetailTypeTextComponent />
    </Grid>
  );
}

function DetailTypeIconComponent() {
  const isPeopleType = useIsPeople();
  const isVehicleType = useIsVechicle();

  return isPeopleType ? (
    <DirectionsWalkIcon />
  ) : isVehicleType ? (
    <DirectionsCarIcon />
  ) : (
    <MotorcycleIcon />
  );
}

function DetailTypeTextComponent() {
  const classes = useStyles();
  const isPeopleType = useIsPeople();
  const isVehicleType = useIsVechicle();

  const message = isPeopleType ? 'People' : isVehicleType ? 'Vehicles' : 'Motorcycle';
  // const linkToEvent = useLinkToEvent();
  return (
    <Tooltip
      arrow
      placement="bottom"
      title={`Search for all ${
        isPeopleType ? 'people' : isVehicleType ? 'Vehicles' : 'Motorcycle'
      } events`}
    >
      <Grid
        item
        classes={{item: `${classes.typeText} ${classes.vehicle}`}}
        // onClick={() => linkToEvent({})}
      >
        <Typography variant="h6">{message}</Typography>
      </Grid>
    </Tooltip>
  );
}

function DetailRelatedComponent() {
  const classes = useStyles();
  const {detail} = useDetailContext();
  const time = moment(detail.event.started_at);
  return (
    <Grid item container alignItems="center" classes={{item: classes.flex}}>
      <Breadcrumbs separator={<EllipseComponent />}>
        <Typography className={classes.time}>
          {moment().to(time) + ' at ' + time.format('HH:mm a')}
        </Typography>
        {detail.event.carmera ? (detail.event.camera.name && <CameraNavigateComponent />) : null}
        {detail.event.site ? (detail.event.site.name && <SiteNavigateComponent />) : null}
      </Breadcrumbs>
    </Grid>
  );
}

function CameraNavigateComponent() {
  const classes = useStyles();
  const {detail, cameras} = useDetailContext();
  // const linkToEvent = useLinkToEvent();
  const disable =
    !detail.event.camera || (cameras ? cameras.every(camera => camera.id !== detail.event.camera.id) : null);

  return (
    <>
      {disable ? (
        <Tooltip arrow placement="bottom" title="the camera no longer exists">
          <div>
            <Button disabled className={classes.disableLink}>
              <Typography>{detail.event.camera.name}</Typography>
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Tooltip arrow placement="bottom" title="Search for all events on this camera">
          <Link
            // onClick={() => linkToEvent({camera: detail.event.camera.id})}
            className={clsx(classes.pointer, classes.link)}
          >
            {detail.event.camera.name}
          </Link>
        </Tooltip>
      )}
    </>
  );
}

function SiteNavigateComponent() {
  const classes = useStyles();
  const {detail, sites} = useDetailContext();
  // const linkToEvent = useLinkToEvent();
  const disable = !detail.event.site || (sites ? sites.every(site => site.id !== detail.event.site.id) : null);
  return (
    <>
      {disable ? (
        <Tooltip arrow placement="bottom" title="the site no longer exists">
          <div>
            <Button disabled className={classes.disableLink}>
              <Typography>{detail.event.site.name}</Typography>
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Tooltip arrow placement="bottom" title="Search for all events at this site">
          <Link
            // onClick={() => linkToEvent({site: detail.event.site.id})}
            className={clsx(classes.pointer, classes.link)}
          >
            {detail.event.site.name}
          </Link>
        </Tooltip>
      )}
    </>
  );
}
