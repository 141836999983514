import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import SideNavList from './SideNavList';

const drawerWidth = 200;

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    border: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.grey[200],
    border: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar,
});

const SideNav = props => {
    const { classes, handleOnClick, role, pagePath, num_new, num_active } = props;
    return (
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
        anchor="left"
      >
        {/* Toolbar Spacer */}
        <div className={classes.toolbar}/>
        <div>
          <SideNavList
            handleOnClick={handleOnClick}
            role={role}
            pagePath={pagePath}
            num_new={num_new}
            num_active={num_active}
            />
        </div>
      </Drawer>
    );
};

SideNav.propTypes = {
  classes: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  pagePath: PropTypes.string.isRequired,
  num_new: PropTypes.number.isRequired,
  num_active: PropTypes.number.isRequired,
}

SideNav.defaultProps = {
  num_new: 0,
  num_active: 0,
};

export default withStyles(styles)(SideNav);
