import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Typography from '@material-ui/core/Typography';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    display: 'flex',
    width: '80%'
  },
  buttonLeft: {
    flex: 1,
    height: '2rem',
    backgroundColor: '#FFFFFF',
    //important to override :firstChild
    borderRadius: '15px 0 0 15px !important',
  },
  buttonMid: {
    flex: 1,
    height: '2rem',
    backgroundColor: '#FFFFFF',
    borderRadius: 0,
    //important to override not:firstChild
    margin: '0 2px 0 2px !important'
  },
  buttonRight: {
    flex: 1,
    height: '2rem',
    backgroundColor: '#FFFFFF',
    //important to override :lastChild
    borderRadius: '0 15px 15px 0 !important'
  },
  label: {
    textTransform: 'initial',
    '@media (min-width: 1280px) and (max-width: 1600px)': {
      fontSize: '0.9rem'
    }
  },
});

const PriorityButton = props => {
    const { classes, priority, onChangePriority, disabled } = props;
    return (
        <span className={classes.root}>
          <ToggleButton
            value='low'
            className={classes.buttonLeft}
            onClick={onChangePriority('low')}
            selected={priority === 'low'}
            disabled={disabled}
            >
            <Typography variant="body2" className={classes.label}>
              {getText('low')}
            </Typography>
          </ToggleButton>
          <ToggleButton
            value='medium'
            className={classes.buttonMid}
            onClick={onChangePriority('medium')}
            selected={priority === 'medium'}
            disabled={disabled}
            >
            <Typography variant="body2" className={classes.label}>
              {getText('medium')}
            </Typography>
          </ToggleButton>
          <ToggleButton
            value='high'
            className={classes.buttonRight}
            onClick={onChangePriority('high')}
            selected={priority === 'high'}
            disabled={disabled}
            >
            <Typography variant="body2" className={classes.label}>
              {getText('high')}
            </Typography>
          </ToggleButton>
        </span>
    );
};

PriorityButton.propTypes = {
  priority: PropTypes.string.isRequired,
  onChangePriority: PropTypes.func.isRequired,
};

PriorityButton.defaultProps = {
  priority: '',
  onChangePriority: () => () => {},
};

export default withStyles(styles)(PriorityButton);
