import React from 'react';
import { withStyles } from '@material-ui/core';
import * as _ from 'lodash';

import { injectScript } from 'src/utils/injectScript';


const styles = theme => ({
  mapContainer: {
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    flexGrow: 1,
    position: 'relative'
  },
  map: {
    height: '80vh'
  },
});

class GaodeMap extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.map = null;
  }

  componentDidMount() {
    if (!window.AMap) {
      injectScript(`${process.env.REACT_APP_GAODE_MAP_API_URL}/maps?v=1.4.15&key=${process.env.REACT_APP_GAODE_MAP_API_KEY}`)
        .then(() => {
          injectScript(`${process.env.REACT_APP_GAODE_MAP_API_URL}/ui/1.0/main.js?v=1.0.11`)
            .then(() => {
              window.AMap && window.AMap.plugin(['AMap.IndoorMap'], () => {
                this.loadGaodeMap();
              });
            });
        });
    } else {
      this.loadGaodeMap();
    }
  }

  componentWillUnmount() {
    this.destroyGaodeMap();
  }

  componentDidUpdate(prevProps, prevState) {
    const { polygons, newPolygon, curAction } = this.props;
    const { polygons: prevPolygons, newPolygon: prevNewPolygon } = prevProps;
    if ((!_.isEqual(polygons, prevPolygons) || !_.isEqual(newPolygon, prevNewPolygon)) && this.map) {
      this.map.clearMap();
      const polygonsPath = polygons.map(polygon => polygon.geo_points ? polygon.geo_points.map(
        point => new window.AMap.LngLat(point.lng, point.lat, true)
      ) : []);
      polygonsPath.forEach((polygonPath, index) => {
        const gaodePolygon = new window.AMap.Polygon({
          path: polygonPath,
          strokeColor: '#4C7F72',
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: '#4C7F72',
          zIndex: 50,
          draggable: true
        })
        this.map.add(gaodePolygon)
      });

      if (curAction === 'add') {
        const path = newPolygon.geo_points && newPolygon.geo_points.map(point => new window.AMap.LngLat(point.lng, point.lat, true));
        const gaodeNewPolygon = new window.AMap.Polygon({
          path: path,
          strokeColor: '#4C7F72',
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: '#4C7F72',
          zIndex: 50,
          draggable: true
        })
        this.map.add(gaodeNewPolygon)
      }
    }
  }

  destroyGaodeMap = () => {
    this.map && this.map.destroy();
    this.map = null;
  };

  loadGaodeMap = () => {
    if (window.AMap && window.AMapUI && !this.map) {
      const { defaultZoom, defaultCenter, newPolygon, polygons, curAction, onMapClick, onPolygonEdit } = this.props;
      const centerPoint = new window.AMap.LngLat(defaultCenter.lng, defaultCenter.lat, true);
      this.map = new window.AMap.Map(this.mapRef.current, {
        features: ['bg', 'road', 'building'],
        showLabel: false,
        resizeEnable: true,
        showIndoorMap: true,
        center: centerPoint,
        zoom: defaultZoom
      });
      if (this.map) {
        const polygonsPath = polygons.map(polygon => polygon.geo_points ? polygon.geo_points.map(
          point => new window.AMap.LngLat(point.lng, point.lat, true)
        ) : []);
        polygonsPath.forEach((polygonPath, index) => {
          const gaodePolygon = new window.AMap.Polygon({
            path: polygonPath,
            strokeColor: '#4C7F72',
            strokeWeight: 6,
            strokeOpacity: 0.2,
            fillOpacity: 0.4,
            fillColor: '#4C7F72',
            zIndex: 50,
            draggable: true
          })
          this.map.add(gaodePolygon)
        });

        if (curAction === 'add') {
          const path = newPolygon.geo_points && newPolygon.geo_points.map(point => new window.AMap.LngLat(point.lng, point.lat, true));
          const gaodeNewPolygon = new window.AMap.Polygon({
            path: path,
            strokeColor: '#4C7F72',
            strokeWeight: 6,
            strokeOpacity: 0.2,
            fillOpacity: 0.4,
            fillColor: '#4C7F72',
            zIndex: 50,
            draggable: true
          })
          this.map.add(gaodeNewPolygon)
        }

        window.AMap.event.addListener(this.map, 'click', onMapClick);
        // var polyEditor = new window.AMap.PolyEditor(this.map, polygon)
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.mapContainer}>
        <div ref={this.mapRef} className={classes.map} />
      </div>
    );
  }
}

export default withStyles(styles)(GaodeMap);
