import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Loader from "react-loader-advanced";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import ExitFullscreenIcon from "@material-ui/icons/FullscreenExit";
import uuid from "uuid";
import kurentoUtils from "kurento-utils";

import { wsActions } from "src/redux/actions";
import assignID from "src/components/IdAssigner";
import { getText } from "src/utils/MultilingualLoader";
import CSSModuleStyles from "./WebRTCViewer.module.css";

const mapStateToProps = (state, props) => {
  const { componentId } = props;
  const appStatus = state.websocket.APP.status;
  const wispMsg = state.websocket.WISP.messages[componentId];
  const wispStatus = state.websocket.WISP.status;
  return { appStatus, wispMsg, wispStatus };
};

const mapDispatchToProps = (dispatch) => {
  return {
    emptyMsgQue: (wsName, componentId) =>
      dispatch(wsActions.emptyMsgQue(wsName, componentId)),
    unregComponent: (wsName, componentId) =>
      dispatch(wsActions.unregComponent(wsName, componentId)),
  };
};

const styles = (theme) => ({
  fullScreenButton: {
    border: 0,
    backgroundColor: "transparent",
    float: "right",
  },
});

class Viewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      room_id: "",
      viewer_token: "",
      room_member_id: null,
      room_state: {},
      loading: getText("preparing"),
      error: null,
      webRtcPeer: null,
      candidateQue: [],
      resNumRqd: 2,
      shouldLeave: false,
    };
    this.onAppMsg = this.onAppMsg.bind(this);
  }

  componentDidUpdate(prevProps) {
    //handle websocket messages from wisp server
    if (this.props.wispMsg && this.props.wispMsg.length !== 0) {
      this.props.wispMsg.forEach(this.onWispMsg);
      this.props.emptyMsgQue("WISP", this.props.componentId);
    }

    //stop stream when websocket disconnect
    if (
      (prevProps.appStatus !== "closed" && this.props.appStatus === "closed") ||
      (prevProps.wispStatus !== "closed" && this.props.wispStatus === "closed")
    ) {
      this.handleLeaveRoom();
    }

    //start view if WISP connected
    if (
      prevProps.wispStatus === "closed" &&
      this.props.wispStatus === "open" &&
      this.state.viewer_token !== ""
    )
      this.startView();
  }

  componentDidMount() {
    this.getAccessToken();
    this.addListeners();
  }

  componentWillUnmount() {
    //only send message, no need to wait for the response
    this.stopView()();
    this.props.unregComponent("WISP", this.props.componentId);
    this.removeListeners();
  }

  videoRef = (video) => {
    this.video = video;
  };

  videoContainerRef = (videoContainer) => {
    this.videoContainer = videoContainer;
  };

  videoControlsRef = (videoControls) => {
    this.videoControls = videoControls;
  };

  startView = () => {
    if (!this.state.webRtcPeer) {
      const options = {
        remoteVideo: this.video,
        onicecandidate: this.onIceCandidate,
        configuration: {
          iceServers: [
            {
              urls: "turn:54.222.199.169:3478",
              username: "&wK8sWr|hzNTd|4T",
              credential: "qv}=d7LOQ}l2NhgH",
            },
          ],
        },
      };
      const self = this;
      const webRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(
        options,
        function (error) {
          if (error) {
            return console.log(error);
          }
          this.generateOffer(self.onOfferViewer);
        }
      );

      this.setState({
        webRtcPeer,
      });
    }
  };

  stopView = (error) => () => {
    if (!this.state.room_member_id) return;

    let message = {
      id: uuid(),
      act: "leave_room",
      arg: {
        room_member_id: this.state.room_member_id,
      },
    };
    if (error) message.arg["error_message"] = error;
    const options = {
      hasRes: true,
      componentId: this.props.componentId,
      timeout: 15000,
    };

    window.wsWISP
      .sendMsg(message, options)
      .catch((error) => console.log(error));
  };

  onOfferViewer = (error, offerSdp) => {
    if (error) {
      return console.log("Error generating the offer");
    }
    const message = {
      id: uuid(),
      act: "view",
      arg: {
        room_id: this.state.room_id,
        token: this.state.viewer_token,
        sdp: offerSdp,
      },
    };
    const options = {
      hasRes: true,
      componentId: this.props.componentId,
      timeout: 15000,
    };

    window.wsWISP
      .sendMsg(message, options)
      .catch((error) => console.log(error));
  };

  onIceCandidate = (candidate) => {
    if (!this.state.room_member_id) {
      this.state.candidateQue.push(candidate);
    } else {
      const ice_candidates = [
        {
          candidate: candidate.candidate,
          sdp_mid: candidate.sdpMid,
          sdp_m_line_index: parseInt(candidate.sdpMLineIndex),
        },
      ];
      const message = {
        id: uuid(),
        act: "add_client_ice_candidates",
        arg: {
          room_member_id: this.state.room_member_id,
          ice_candidates: ice_candidates,
        },
      };

      window.wsWISP.sendMsg(message).catch((error) => console.log(error));
    }
  };

  handleView = (msg) => {
    if (!msg.ret || !msg.ret.room_member_id) return;
    this.setState(
      {
        room_member_id: msg.ret.room_member_id,
        room_state: msg.ret.room_state,
        loading: getText("connecting"),
      },
      () => {
        if (this.state.candidateQue.length !== 0) {
          const candidates = this.state.candidateQue;
          const ice_candidates = candidates.map((candidate) => {
            return {
              candidate: candidate.candidate,
              sdp_mid: candidate.sdpMid,
              sdp_m_line_index: candidate.sdpMLineIndex,
            };
          });
          const message = {
            id: uuid(),
            act: "add_client_ice_candidates",
            arg: {
              room_member_id: this.state.room_member_id,
              ice_candidates: ice_candidates,
            },
          };

          window.wsWISP.sendMsg(message).catch((error) => console.log(error));

          this.setState({
            candidateQue: [],
          });
        }
      }
    );
  };

  handleLeaveRoom = () => {
    if (this.state.webRtcPeer) {
      this.state.webRtcPeer.dispose();
      this.setState({
        room_id: "",
        viewer_token: "",
        room_member_id: null,
        room_state: {},
        loading: getText("preparing"),
        error: null,
        webRtcPeer: null,
        candidateQue: [],
        resNumRqd: 2,
        shouldLeave: false,
      });
    }
  };

  handleStop = (msg) => {
    const { error_message } = msg.arg;
    if (error_message) console.log(error_message);
    this.handleLeaveRoom();
  };

  handleSdpAnswer = (msg) => {
    this.state.webRtcPeer.processAnswer(msg.arg.sdp, function (error) {
      if (error) return console.log(error);
    });
  };

  handleAddIceCandidate = (msg) => {
    msg.arg.ice_candidates.forEach((ice_candidate) => {
      const candidate = {
        candidate: ice_candidate.candidate,
        sdpMid: ice_candidate.sdp_mid,
        sdpMLineIndex: ice_candidate.sdp_m_line_index,
      };
      this.state.webRtcPeer.addIceCandidate(candidate, function (error) {
        if (error) return console.log("Error adding candidate: " + error);
      });
    });
  };

  handleAccessToken = async (msg) => {
    console.log("handle access token");
    if (window.wsWISP && !window.wsWISP.isConnected()) {
      console.log("connect wisp");
      const url =
        process.env.REACT_APP_WEBRTC_WEBSOCKET_URL +
        "?token=" +
        msg.ret.login_token;
      await window.wsWISP
        .connectWS(url, this.props.reconnect)
        .catch((error) => console.log(error));
    }

    this.setState({
      room_id: msg.ret.room_id,
      viewer_token: msg.ret.viewer_token,
    });

    if (window.wsWISP && window.wsWISP.isConnected()) this.startView();
    else console.log("websocket not connected");
  };

  getAccessToken = () => {
    const robot_id = this.props.camera.id;
    const message = {
      id: uuid(),
      act: "wisp.view_robot",
      arg: {
        robot_id: robot_id,
        camera: "front",
      },
    };
    const options = {
      hasRes: true,
      timeout: 15000,
      callback: this.onAppMsg,
      resNumRqd: 2,
    };
    if (!window.wsAPP || !window.wsAPP.isConnected()) {
      return;
    }
    window.wsAPP.sendMsg(message, options).catch((error) => console.log(error));
  };

  onAppMsg(msg, error) {
    if (error) {
      this.setState({
        error: error.message,
      });
      setTimeout(this.props.refresh, 2000);
      return;
    }
    if (msg.err && msg.err.ec !== 0) {
      this.setState({
        error: msg.err.em,
      });
      //downgrade to hls if webRTC is not supported
      if (msg.err.dm === "invalid_action")
        setTimeout(this.props.downGrade, 1000);
      return;
    }

    switch (msg.act) {
      case "wisp.view_robot!":
        // TODO: responses order cannot be ensured now, temporary judge by content
        // if (this.state.resNumRqd === 2) this.handleAccessToken(msg);
        // if (this.state.resNumRqd === 1 && !msg.ret.confirm) this.stopView()();
        if (msg.ret && msg.ret.login_token) {
          this.handleAccessToken(msg);
          if (this.state.shouldLeave) this.stopView()();
        } else if (!msg.ret.confirm) {
          this.setState({ shouldLeave: true });
          this.stopView()();
        }
        this.setState({ resNumRqd: this.state.resNumRqd - 1 });
        break;
      default:
        break;
    }
  }

  onWispMsg = (msg) => {
    if (msg.err && msg.err.ec !== 0) {
      this.setState({
        error: msg.err.dm,
      });
      this.handleLeaveRoom();
      if (msg.act === "timeout") setTimeout(this.props.refresh, 2000);
      return;
    }

    switch (msg.act) {
      case "view!":
        this.handleView(msg);
        break;
      case "answer_sdp":
        this.handleSdpAnswer(msg);
        break;
      case "update_viewer_room_state":
        this.setState({
          room_state: msg.arg.room_state,
        });
        break;
      case "add_server_ice_candidates":
        this.handleAddIceCandidate(msg);
        break;
      case "stop":
        this.handleStop(msg);
        break;
      default:
        break;
    }
  };

  showControls = () => {
    this.videoControls.style.display = "block";
  };

  hideControls = () => {
    this.videoControls.style.display = "none";
  };

  fullscreenOnClickHandler = () => {
    if (this.videoContainer.requestFullscreen) {
      this.videoContainer.requestFullscreen();
    } else if (this.videoContainer.mozRequestFullScreen) {
      /* Firefox */
      this.videoContainer.mozRequestFullScreen();
    } else if (this.videoContainer.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.videoContainer.webkitRequestFullscreen();
    } else if (this.videoContainer.msRequestFullscreen) {
      /* IE/Edge */
      this.videoContainer.msRequestFullscreen();
    }
  };

  exitFullScreenOnClickHandler = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      /* Chrome, Safari and Opera */
      document.webkitCancelFullScreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  };

  fullScreenChangeHandler = () => {
    if (
      document.fullscreenElement ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement
    ) {
      this.setState({ isFullScreen: true });
    } else {
      this.setState({ isFullScreen: false });
    }
  };

  addListeners = () => {
    document.addEventListener("fullscreenchange", this.fullScreenChangeHandler);
    document.addEventListener(
      "webkitfullscreenchange",
      this.fullScreenChangeHandler
    );
    document.addEventListener(
      "mozfullscreenchange",
      this.fullScreenChangeHandler
    );
    document.addEventListener(
      "MSFullscreenChange",
      this.fullScreenChangeHandler
    );
  };

  removeListeners = () => {
    document.removeEventListener(
      "fullscreenchange",
      this.fullScreenChangeHandler
    );
    document.removeEventListener(
      "webkitfullscreenchange",
      this.fullScreenChangeHandler
    );
    document.removeEventListener(
      "mozfullscreenchange",
      this.fullScreenChangeHandler
    );
    document.removeEventListener(
      "MSFullscreenChange",
      this.fullScreenChangeHandler
    );
  };

  render() {
    const { classes, minHeight } = this.props;
    const { room_state, loading, error } = this.state;
    const fullControls = process.env.REACT_APP_ENV === "development";
    return (
      <Loader
        style={{ zIndex: 0, minHeight: minHeight, height: "100%" }}
        show={loading || error ? true : false}
        message={error ? error : loading}
        hideContentOnLoad
      >
        <CardMedia src="video">
          <div
            className={
              !fullControls
                ? CSSModuleStyles.video_container
                : CSSModuleStyles.video_container_full_controls
            }
            ref={this.videoContainerRef}
            onMouseOver={!fullControls ? this.showControls : () => {}}
            onMouseOut={!fullControls ? this.hideControls : () => {}}
          >
            <video
              id="video"
              ref={this.videoRef}
              src=""
              autoPlay
              // muted={true}
              controls={fullControls}
              width="100%"
              onEnded={this.stopView("streaming ended")}
              onError={this.stopView("streaming error")}
              onCanPlay={() => this.setState({ loading: null })}
            />
            {!fullControls && (
              <div
                className={CSSModuleStyles.video_controls}
                ref={this.videoControlsRef}
              >
                {this.state.isFullScreen ? (
                  <IconButton
                    className={classes.fullScreenButton}
                    onClick={this.exitFullScreenOnClickHandler}
                  >
                    <ExitFullscreenIcon htmlColor="#FFFFFF" />
                  </IconButton>
                ) : (
                  <IconButton
                    className={classes.fullScreenButton}
                    onClick={this.fullscreenOnClickHandler}
                  >
                    <FullscreenIcon htmlColor="#FFFFFF" />
                  </IconButton>
                )}
              </div>
            )}
          </div>
          <div style={{ display: "none" }}>
            {Object.keys(room_state).map(
              (key) => ` ${key}: ${room_state[key]} `
            )}
          </div>
        </CardMedia>
      </Loader>
    );
  }
}

export default assignID(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Viewer))
);
