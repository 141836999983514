import qs from 'qs';
import { event_api } from 'src/services/Vision/api';

export const siteAdapter = {
  toServer: (site) => {
    return {
      name: site.name || '',
      province: site.province || '',
      city: site.city || '',
      county: site.county || '',
      location: site.location || '',
      polygons: site.polygons || [],
      label: site.label || '',
      note: site.note || '',
    };
  },
  toClient: (data) => {
    return {
      id: data.id,
      name: data.name,
      label: data.label,
      status: data.status,
      boxes: (data.boxes || []).map((box) => ({
        id: box.id,
        cameras: box.cameras || [],
        state: box.state,
      })),
      province: data.province,
      city: data.city,
      county: data.county,
      location: data.location,
      polygons: data.polygons,
      note: data.note,
      managements: data.managements || [],
      online_camera_count: data.online_camera_count,
      total_camera_count: data.total_camera_count,
    };
  },
};

export const siteListAdapter = {
  toServer: (params) => {
    return {
      offset: ((params.page || 1) - 1) * (params.limit || 10),
      limit: params.limit || 10,
      ordering: params.ordering,
      name: params.name && params.name !== 'All' ? params.name.trim() : undefined,
      site_name:
        params.site_name && params.site_name !== 'All' ? params.site_name.trim() : undefined,
      province: params.province && params.province !== 'All' ? params.province : undefined,
      managements: params.managements ? params.managements : undefined,
    };
  },
  toClient: (data) => {
    return {
      count: data.count,
      list: data.results.map(siteAdapter.toClient),
    };
  },
};


export async function getSimpleSites(params) {
  try {
    let url = `${process.env.REACT_APP_API_BASE_URL || ''}/face/sites?simple=true`;
    if (params) {
      url += `&${qs.stringify(siteListAdapter.toServer(params))}`;
    }
    const response = await event_api.get(url);
    return response;
  } catch (e) {
    throw e;
  }
}