import React from 'react';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';

import { referenceActions, notificationActions } from 'src/redux/actions';
import AlgoReference from '../AlgoReference';
import { referenceServices } from 'src/services';
import { getText } from 'src/utils/MultilingualLoader';
import { algoConfig } from './config';

const mapStateToProps = state => {
  const { algoOptions, maps } = state.target;
  return {
    algoOptions,
    maps: maps.map(map => ({
      value: map.id,
      label: `${map.name}-${map.id}`,
      routes: map.mapRoutes.map(route => ({
        value: route.id,
        label: `${route.name}-${route.id}`,
      })),
    })),
    siteId: state.site.curSite
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addAlgoReference: (params) => {
      dispatch(referenceActions.addAlgoReference(params));
    },
    deleteAlgoReference: (id) => {
      dispatch(referenceActions.deleteAlgoReference(id));
    },
    addNotification: (msg) => {
      dispatch(notificationActions.addNotification(msg));
    }
  };
};

const initialState = {
  id: '',
  mapOption: null,
  routeOption: null,
  refs: [],
  validate: {
    refs: [],
  },
}

class AlgoReferenceListContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      needLoad: true,
      list: [],
      newList: [],
    };

    this.region = algoConfig[props.type];
  }

  findRegionConfig(type, itemType) {
    let isError = false;
    const regionConfig = algoConfig[type];
    if (!regionConfig) {
      throw new TypeError('Config type error: ' + type);
    }
    let region = Object.keys(regionConfig).map(key => regionConfig[key]).find(config => config.type === itemType);
    if (!region) {
      // search for global
      isError = true;
      const totalRegions = Object.keys(algoConfig).map(key => algoConfig[key]).map(config => config.types.map(type => config[type])).flat();
      region = totalRegions.find(config => config.type === itemType);
      if (!region) {
        throw new TypeError('Config type error conflict type: ' + itemType);
      }
    }
    return { region, isError };
  }

  async componentDidUpdate() {
    const { algoConfigId, type, show } = this.props;
    const { needLoad } = this.state;
    if (show && needLoad) {
      this.setState({
        needLoad: false,
      });

      const result = await referenceServices.getAlgoReference({
        algo_config_id: algoConfigId,
        ordering: 'id'
      });
      const array = result.map(item => {
        const { refs, id } = item;
        const region = algoConfig[type];
        return {
          id,
          type,
          multiple: region.types.filter(type => region[type].multiple).map(type => region[type]),
          refs: refs.map(ref => ({
            ...ref,
            regions: ref.regions.map(item => {
              const { region, isError } = this.findRegionConfig(type, type === '电子元件' ? 'electrical' : item.type);
              return {
                ...item,
                type: type === '电子元件' ? 'electrical' : item.type,
                no: type === '电子元件' ? item.labels.map(v => v.join(',')).join('\n') : item.no,
                status: type === '电子元件' ? item.type : item.status,
                multiple: region.multiple,
                options: region.status,
                error: isError,
              };
            }),
          })),
          validate: {
            refs: refs.map(item => {
              return {
                regions: item.regions.map(() => ({})),
              };
            }),
          }
        };
      });
      this.setState({
        list: array,
        loaded: true,
      });
      if (array.length === 0) {
        this.addReference();
      }
    }
  }

  addReference = () => {
    const { newList } = this.state;
    const { type } = this.props;
    const region = algoConfig[type];
    this.setState({
      newList: newList.concat({
        ...initialState,
        type,
        multiple: region.types.filter(type => region[type].multiple).map(type => region[type]),
        refs: [],
        validate: {
          routeId: '',
          refs: [],
        }
      }),
    });
  }

  render() {
    const { loaded, list, newList } = this.state;
    const { target, show, algoConfigId } = this.props;

    return (loaded ? (
      <Grid>
        {
          list.map((item, index) => (
            <AlgoReference
              key={index}
              data={item}
              target={target}
              region={this.region}
              show={show}
              algoConfigId={algoConfigId}
            />
          ))
        }
        {
          newList.map((item, index) => (
            <AlgoReference
              key={index + 1000}
              data={item}
              target={target}
              region={this.region}
              show={show}
              algoConfigId={algoConfigId}
            />
          ))
        }
        {show ? <Grid container justify="center">
          <Button color="primary" style={{ marginTop: 10 }} variant="outlined" onClick={this.addReference}>{getText('add_reference')}</Button>
        </Grid> : null}
      </Grid>
    ) : (show ? <Grid>{getText('loading')}</Grid> : null));
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlgoReferenceListContainer);