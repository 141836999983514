import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'

const RobotIcon = props => {
  return (
    <SvgIcon>
      <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="页面1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon_robot_修改" transform="translate(-49.000000, -29.000000)">
            <g id="icon_黑" transform="translate(48.000000, 28.000000)">
                <g id="编组" transform="translate(1.000000, 1.000000)">
                    <g id="上" transform="translate(2.100000, 0.000000)" stroke="RGB(109, 109, 109)" strokeWidth="1.5">
                        <rect id="矩形" fill="none" x="0.75" y="0.75" width="16.5" height="8.5" rx="4"></rect>
                        <path d="M3.65,5.25 L14.15,5.25 L14.15,4 C14.15,3.30964406 13.5903559,2.75 12.9,2.75 L4.9,2.75 C4.20964406,2.75 3.65,3.30964406 3.65,4 L3.65,5.25 Z" id="矩形"></path>
                    </g>
                    <g id="左右" transform="translate(0.000000, 12.125052)" fill="none" stroke="RGB(109, 109, 109)" strokeWidth="1.5">
                        <path d="M1.29068323,2.95973085 C0.324184918,2.95973085 -0.45931677,3.74323254 -0.45931677,4.70973085 C-0.45931677,5.67622916 0.324184918,6.45973085 1.29068323,6.45973085 L5.30931677,6.45973085 C6.27581508,6.45973085 7.05931677,5.67622916 7.05931677,4.70973085 C7.05931677,3.74323254 6.27581508,2.95973085 5.30931677,2.95973085 L1.29068323,2.95973085 Z" id="矩形" transform="translate(3.300000, 4.709731) rotate(-90.000000) translate(-3.300000, -4.709731) "></path>
                        <path d="M16.7906832,2.95973085 C15.8241849,2.95973085 15.0406832,3.74323254 15.0406832,4.70973085 C15.0406832,5.67622916 15.8241849,6.45973085 16.7906832,6.45973085 L20.8093168,6.45973085 C21.7758151,6.45973085 22.5593168,5.67622916 22.5593168,4.70973085 C22.5593168,3.74323254 21.7758151,2.95973085 20.8093168,2.95973085 L16.7906832,2.95973085 Z" id="矩形" transform="translate(18.800000, 4.709731) rotate(-90.000000) translate(-18.800000, -4.709731) "></path>
                    </g>
                    <g id="横" transform="translate(5.500000, 15.064458)" fill="RGB(109, 109, 109)">
                        <polygon id="矩形" transform="translate(5.500000, 0.750598) rotate(-90.000000) translate(-5.500000, -0.750598) " points="4.74940156 -4.74940156 6.25059844 -4.74940156 6.25059844 6.25059844 4.74940156 6.25059844"></polygon>
                        <polygon id="矩形" transform="translate(5.500000, 3.435542) rotate(-90.000000) translate(-5.500000, -3.435542) " points="4.74940156 -2.06445825 6.25059844 -2.06445825 6.25059844 8.93554175 4.74940156 8.93554175"></polygon>
                    </g>
                    <g id="竖" transform="translate(8.800000, 8.388761)" stroke="RGB(109, 109, 109)" strokeWidth="1.5">
                        <rect id="矩形" x="0.95" y="1.37987282" width="1" height="5.5"></rect>
                        <rect id="矩形" fill="RGB(109, 109, 109)" x="4.25" y="1.37987282" width="1" height="5.5"></rect>
                    </g>
                </g>
            </g>
        </g>
    </g>
      </svg>
    </SvgIcon >
  );
}

export default RobotIcon;