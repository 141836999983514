import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    margin: "12px 0",
  },
  tableCell: {
    borderStyle: "none",
  },
});

const RobotLiftStatus = (props) => {
  const { classes, details } = props;
  return (
    <Table>
      <TableBody>
        {details.map((detail) => (
          <TableRow key={detail.title}>
            <TableCell className={classes.tableCell} padding="default">
              <Typography variant="body2">{detail.title}</Typography>
            </TableCell>
            <TableCell className={classes.tableCell} padding="default">
              <Typography variant="body2">{detail.content}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

RobotLiftStatus.propTypes = {
  details: PropTypes.array.isRequired,
};

RobotLiftStatus.defaultProps = {
  details: [],
};

export default withStyles(styles)(RobotLiftStatus);
