import React, {useMemo} from 'react';
import clsx from 'clsx';
import {Grid, Typography, Tooltip, makeStyles} from '@material-ui/core';
import {NavigateNext as NextIcon, Check, Flag} from '@material-ui/icons';

// import {LicensePlate} from 'src/models/event';
import {useDetailContext} from '../../../hook';
import {
  vehicleColorOptions,
  vehicleTypeOptions,
  vehicleMakeOptions,
} from '../../../../Filter/staticOption';

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    width: 'auto',
  },
  wrap: {
    marginTop: theme.spacing(3),
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  license: {
    fontSize: 13,
    color: (theme.palette).other.secondary,
    marginRight: theme.spacing(1),
  },
  divider: {
    margin: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: 13,
    background: theme.palette.action.disabledBackground,
  },
  link: {
    fontSize: 13,
  },
  pointer: {
    cursor: 'pointer',
  },
  disablePointer: {
    cursor: 'default !important',
  },
  zoomin: {
    padding: theme.spacing(1),

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  licensePlate: {
    height: 30,
    cursor: 'pointer',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    '&:hover': {
      background: '#eeeeee',
    },
    '& svg': {
      color: (theme.palette).other.primary,
      marginLeft: 2,
    },
  },
  vehicleColor: {
    borderRadius: '50%',
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    marginRight: theme.spacing(0.5),
  },
  vehicleButton: {
    borderRadius: theme.spacing(2),
    background: '#eeeeee',
    color: (theme.palette).other.primary,
    textTransform: 'capitalize',
    cursor: 'pointer',
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonIcon: {
    color: (theme.palette).other.secondary,
    marginTop: 1,
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
  },
  vehicleEntityIcon: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(1),
  },
}));

export function VehicleInfoComponent() {
  const classes = useStyles();

  return (
    <Grid item container classes={{item: clsx(classes.flex, classes.wrap)}}>
      <LicensePlatesComponent />
      <VehicleTypesComponent />
    </Grid>
  );
}

function LicensePlatesComponent() {
  const {detail} = useDetailContext();
  const licensePlates = (detail.event.metadata ? (detail.event.metadata.license_plates || []) : null).filter(
    vehicleInfo => !!vehicleInfo.text
  );

  if (!licensePlates.length) return null;

  return (
    <>
      {licensePlates.map(licensePlate => {
        return (
          <LicensePlateComponent key={licensePlate.text} licensePlate={licensePlate} />
        );
      })}
    </>
  );
}

function LicensePlateComponent({licensePlate}) {
  const classes = useStyles();
  // const linkToEvent = useLinkToEvent();

  return (
    <VehicleButtonComponent
      title="Search for license"
      // onClick={() => linkToEvent({lpr: licensePlate.text})}
    >
      {licensePlate.is_register && <Check className={classes.vehicleEntityIcon} />}
      {licensePlate.is_flag && <Flag className={classes.vehicleEntityIcon} color="error" />}
      <Typography variant="subtitle2">{licensePlate.text}</Typography>
    </VehicleButtonComponent>
  );
}

function VehicleTypesComponent() {
  const {detail} = useDetailContext();

  const licensePlates = detail.event.metadata ? (detail.event.metadata.license_plates || []) : null;
  const vehicleTypes = useMemo(() => {
    return licensePlates.filter(
      item => (item.color ? item.color.color : null) || (item.model_make ? item.model_make.model : null) || (item.model_make ? item.model_make.make : null)
    );
  }, [licensePlates]);

  return (
    <>
      {vehicleTypes.map((vehicleType, inedx) => (
        <VehicleTypeComponent vehicleType={vehicleType} key={inedx} />
      ))}
    </>
  );
}

function VehicleTypeComponent({vehicleType}) {
  const classes = useStyles();
  // const linkToEvent = useLinkToEvent();

  const color = vehicleColorOptions.find(
    v => vehicleType.color && v.value.includes(vehicleType.color ? vehicleType.color.color : null)
  );
  const type = vehicleTypeOptions.find(
    v => vehicleType.vehicle && v.value.includes(vehicleType.vehicle ? vehicleType.vehicle.type : null)
  );
  const make = vehicleMakeOptions.find(v => v.value === vehicleType.model_make ? vehicleType.model_make.make : null);

  if (!color && !type && !make) {
    return null;
  }

  return (
    <VehicleButtonComponent
      title="Search for vehicle"
      // onClick={() =>
      //   linkToEvent({
      //     vehicle_color: color?.value,
      //     vehicle_type: type?.value,
      //     vehicle_make: make?.value,
      //   })
      // }
    >
      {color && (
        <div className={classes.vehicleColor} style={{background: color.color.toLowerCase()}} />
      )}
      {color && <Typography variant="body2">{color.label}</Typography>}
      {type && (
        <Typography style={{marginLeft: '4px'}} variant="body2">
          {type.label}
        </Typography>
      )}
      {make && (
        <Typography style={{marginLeft: '4px'}} variant="body2">
          {make.label}
        </Typography>
      )}
    </VehicleButtonComponent>
  );
}



function VehicleButtonComponent({onClick, children, title}) {
  const classes = useStyles();
  const {isSharing} = useDetailContext();

  return (
    <Tooltip arrow placement="bottom" title={isSharing ? '' : title}>
      <Grid
        item
        container
        alignItems="center"
        justify="center"
        classes={{
          item: clsx(classes.flex, classes.vehicleButton, isSharing && classes.disablePointer),
        }}
        onClick={onClick}
      >
        {children}
        <NextIcon className={classes.buttonIcon} />
      </Grid>
    </Tooltip>
  );
}
