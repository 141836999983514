import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Grid, Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';

import { robotActions } from 'src/redux/actions';
import { getText } from 'src/utils/MultilingualLoader';
import OutlinedSelector from 'src/components/Selectors/OutlinedSelector';

const mapStateToProps = state => {
  return {
    robotMapLists: state.robot.robotMapLists
  }
}

const mapDispatchToProps = {
  getRobotMaps: robotId => robotActions.getRobotConfigMapsAndRoutes(robotId),
}

const styles = theme => ({
  buttons: {
    textAlign: 'end'
  },
  button: {
    display: 'inline-block',
    margin: theme.spacing(1),
    marginLeft: 0,
    color: 'white',
    textTransform: 'initial'
  }
});

class CopyRoute extends React.Component {
  state = {
    robotId: '',
    mapId: ''
  }

  componentDidUpdate(prevProps, prevState) {
    const { robotMapLists, getRobotMaps } = this.props;
    const { robotId } = this.state;
    if (robotId && robotId !== prevState.robotId && (!robotMapLists[robotId] || !robotMapLists[robotId].maps)) {
      getRobotMaps(robotId);
    }
  }

  render() {
    const { classes, robots, robotMapLists, onClose, onConfirm } = this.props;
    const { robotId, mapId } = this.state;
    const robotMapList = (robotId && robotMapLists[robotId] && robotMapLists[robotId].maps) || [];

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={true}
        onClose={onClose}
      >
        <DialogTitle>
          {getText('copy_route_to')}
        </DialogTitle>
        <DialogContent>
          <Grid container justify="flex-end">
            <Grid item xs={12} sm={6}>
              <OutlinedSelector
                title={getText('robot')}
                options={robots.map(robot => ({ value: robot.id, label: robot.name }))}
                value={robotId}
                onChange={event => this.setState({ robotId: event.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <OutlinedSelector
                title={getText('map')}
                options={robotMapList.map(map => ({ value: map.id, label: `${map.id} ${map.name}` }))}
                value={mapId}
                onChange={event => this.setState({ mapId: event.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onClose}
              >
                {getText('cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onConfirm(robotId, mapId)}
              >
                {getText('confirm')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CopyRoute));