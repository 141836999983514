import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SearchBar from './SearchBar';
import CaseList from './CaseList';
import Pagination from './Pagination';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto'
  },
  caseList: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  searchBar: {
  },
});

const SidePanel = props => {
  const { classes } = props;
  return (
    <Grid
      className={classes.root}
      container={true}
      justify="flex-start"
      direction="column"
      alignItems="stretch"
      spacing={0}
    >
      <Grid item={true}>
        <Grid
          container={true}
          justify="flex-start"
          direction="row"
          alignItems="stretch"
          spacing={0}
        >
          <Grid item={true} xs={9}>
            <SearchBar />
          </Grid>
          <Grid item={true} xs={3}>
            <Pagination />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} className={classes.caseList}>
        <CaseList />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(SidePanel);
