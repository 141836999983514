import React from 'react';
import { withStyles, Button } from '@material-ui/core';

const styles = theme => ({
  button: {
    color: 'white',
    textTransform: 'initial'
  }
});

const ContainedButton = props => {
  const { classes, children, onClick, disabled } = props;
  return (
    <Button
      className={classes.button}
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export default withStyles(styles)(ContainedButton);