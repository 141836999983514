import React, { useEffect } from "react";
import uuid from "uuid";
import {
  Grid,
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
const styles = (theme) => ({
  root: {
    margin: theme.spacing(2),
  },
});

const RobotPatrolQueue = ({ classes, selected }) => {
  useEffect(() => {
    const message = {
      id: uuid(),
      act: "robot.get_routine_execution_queue",
      arg: {
        robot_id: selected.id,
      },
    };
    if (window.wsAPP) {
      window.wsAPP.sendMsg(message, {
        hasRes: true,
        timeout: 15000,
        callback: (res, err) => {
          console.log(res);
        },
      });
    }
  }, []);

  return (
    <Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>开发中</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>敬请期待</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};

export default withStyles(styles)(RobotPatrolQueue);
