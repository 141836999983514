import React from 'react';
import { connect } from 'react-redux';
import { userActions, caseActions } from 'src/redux/actions';
import { getUserInfo } from 'src/utils/auth';
import Header from './Header';

const mapStateToProps = state => {
  return {
    submitting: state.user.submitting,
    case_notify: state.case.case_notify,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNewCaseNotification: shouldNotify => dispatch(caseActions.setNewCaseNotification(shouldNotify)),
    signout: () => dispatch(userActions.signout()),
  };
};

class HeaderContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      anchorEl: null,
      shouldNotify: props.case_notify
    }
  }

  handleSignOutButtonClick = event => {
    if (!this.props.submitting) this.props.signout();
  }

  handleOpenMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleNotificationSwitch = event => {
    const shouldNotify = event.target.checked;
    this.setState({ shouldNotify }, () => this.props.setNewCaseNotification(shouldNotify));
  }

  render() {
    const { anchorEl, shouldNotify } = this.state;
    return (
      <Header
        onClickSignOutButton={this.handleSignOutButtonClick}
        shouldNotify={shouldNotify}
        handleNotificationSwitch={this.handleNotificationSwitch}
        handleOpenMenu={this.handleOpenMenu}
        handleCloseMenu={this.handleCloseMenu}
        anchorEl={anchorEl}
        username={getUserInfo().username || ''}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
