import React from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  Button,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  EditLocation as RelocalizationIcon,
  Forward as GoToIcon,
  RemoveCircle as VirtualWallIcon,
  Map as MapICon,
  ClearAll as RepaintIcon,
  Check as SubmitIcon,
  Add as AppendIcon,
  Remove as DeleteIcon,
} from "@material-ui/icons";
import uuid from "uuid/v4";

import { getText } from "src/utils/MultilingualLoader";
import RobotMap from "../../RobotMap";
import GotoOptions from "./GotoOptions";
import RobotMapList from "../../RobotMapList";
import BackupMapList from "../../BackupMapList";
import RelocalizeOptions from "./RelocalizeOptions";
import {
  robotActions,
  notificationActions,
  targetActions,
} from "src/redux/actions";
import GotoModeSelection from './GotoModeSelection'

const mapStateToProps = (state) => {
  const { selected, robotCurMaps, robotConfigs } = state.robot;
  const configMap = selected && robotCurMaps[selected.id];
  const robotConfig = selected && robotConfigs[selected.id];
  return { robot: selected, configMap, robotConfig };
};

const mapDispatchToProps = {
  setVirtualWall: (robotId, mapId, data, routeId) =>
    robotActions.updateMap(robotId, mapId, data, routeId),
  notify: (msg_text) => notificationActions.addNotification(msg_text),
  getTargetOptions: () => targetActions.getTargetOptions(),
};

const styles = (theme) => ({
  button: {
    maxWidth: 400,
    background: "rgba(76,127,114,0.40)",
    borderRadius: "6px",
    border: "1px solid",
    width: "100%",
    height: "100%",
  },
  button_disabled: {
    maxWidth: 400,
    background: "#CCCCCC",
    borderRadius: "6px",
    border: "1px solid",
    width: "100%",
    height: "100%",
  },
  label: {
    fontSize: "1rem",
    textTransform: "initial",
  },
  tooltip: {
    fontSize: "0.875rem",
  },
  description: {
    fontWeight: "bold",
    padding: "5px",
    border: "3px solid black",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    "&:hover": {
      cursor: "pointer",
    },
  },
});

class MapOperationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curAction: null,
      mapImageReady: false,
      showRelocalization: false,
      showReactionModeDialog: false,
      gotoPosition: {},
      newVirtualWallPolygons:
        props.configMap &&
        props.configMap.mapConfig &&
        props.configMap.mapConfig.virtual_wall
          ? props.configMap.mapConfig.virtual_wall
          : [],
    };
  }

  componentDidMount() {
    this.props.getTargetOptions();
  }

  sendWSMessage = (message, options) => {
    if (window.wsAPP) window.wsAPP.sendMsg(message, options);
  };

  onGoTo = (x, y) => {
    this.setState({
      gotoPosition: {
        x,
        y
      },
      showReactionModeDialog: true
    })
  };
  
  onGoToConfirm = (mode) => {
    const {gotoPosition: {x, y}} = this.state;
    const { notify, robot, robotConfig } = this.props;
    notify(`${robot.name}${getText("words_space")}${getText("is_moving")}`);
    const mapId = robotConfig && robotConfig.current_map_id;
    this.sendWSMessage({
      id: uuid(),
      act: "robot.move",
      arg: {
        robot_id: robot.id,
        map_id: mapId,
        x,
        y,
        path_mode: mode
      },
    });
    this.setState({
      curAction: null
    })
    this.onGoToClose()
  }
  
  onGoToClose = () => {
    this.setState({
      showReactionModeDialog: false,
      gotoPosition: {}
    });
  }

  onRelocalize = (x, y, theta) => {
    const { notify, robot } = this.props;
    notify(
      `${robot.name}${getText("words_space")}${getText("is_relocalizing")}`
    );
    this.sendWSMessage({
      id: uuid(),
      act: "robot.relocalize",
      arg: {
        robot_id: robot.id,
        pose: {
          x,
          y,
          theta,
        },
      },
    });
    this.setState({ curAction: null });
  };

  onRelocalizeConfirm = ({ reselect }) => {
    const { notify, robot } = this.props;
    if (!reselect) {
      notify(
        `${robot.name}${getText("words_space")}${getText("is_relocalizing")}`
      );
      this.sendWSMessage({
        id: uuid(),
        act: "robot.relocalize",
        arg: {
          robot_id: robot.id,
          pose: null,
        },
      });
      this.setState({
        curAction: null,
        showRelocalization: false,
      });
    } else {
      this.setState({
        showRelocalization: false,
      });
    }
  };

  onClose = () => {
    this.setState({
      curAction: null,
      mapImageReady: false,
      newVirtualWallPolygons: [],
    });
    this.props.onClose();
  };

  onRelocalizeClose = () => {
    this.setState({
      showRelocalization: false,
      curAction: null,
    });
  };

  initializeNewVirtuallWall = () => {
    const { configMap } = this.props;
    const newVirtualWallPolygons =
      configMap.mapConfig && configMap.mapConfig.virtual_wall
        ? configMap.mapConfig.virtual_wall
        : [];
    this.setState({ newVirtualWallPolygons });
  };

  initializeRelocalize = () => {
    const { notify, configMap } = this.props;
    if(configMap.lidarType === '2d') {
      this.setState({ showRelocalization: true });
    } else {
      notify(getText("handle_relocalizing"));
    }
  };

  onVirtualWallSubmit = () => {
    const { robot, configMap, robotConfig, setVirtualWall } = this.props;
    const { newVirtualWallPolygons } = this.state;
    const robotId = robot && robot.id;
    const mapId = robotConfig && robotConfig.current_map_id;
    const routeId = robotConfig && robotConfig.current_route_id;
    if (
      robotId &&
      mapId &&
      window.confirm(
        getText("set") +
          getText("words_space") +
          newVirtualWallPolygons.length +
          getText("words_space") +
          getText("virtual_walls")
      )
    ) {
      const data = {
        ...configMap.rawData,
        map_config: {
          ...configMap.mapConfig,
          virtual_wall: newVirtualWallPolygons,
        },
      };
      setVirtualWall(robotId, mapId, data, routeId);
      this.setState({ curAction: null, newVirtualWallPolygons: [] });
    }
  };

  setNewVirtualWallPolygons = (newVirtualWallPolygons) => {
    this.setState({ newVirtualWallPolygons });
  };

  setMapImageReady = (value) => {
    this.setState({ mapImageReady: value });
  };

  render() {
    const { classes, open, robot } = this.props;
    const {
      curAction,
      newVirtualWallPolygons,
      mapImageReady,
      showRelocalization,
      showReactionModeDialog,
      gotoPosition
    } = this.state;
    let rootActions = [];

    if (
      !curAction ||
      ["go_to", "relocalize", "go_home", "leave_home"].includes(curAction)
    ) {
      rootActions = [
        {
          label: getText("go_to"),
          value: "go_to",
          icon: <GoToIcon />,
          disabled: !mapImageReady || robot.status !== "online",
        },
        {
          label: getText("relocalization"),
          value: "relocalize",
          icon: <RelocalizationIcon />,
          description: getText("relocalization_description"),
          // disabled: !mapImageReady || robot.status !== "online",
          disabled: !mapImageReady,
          onClick: this.initializeRelocalize,
        },
        {
          label: getText("virtual_wall"),
          value: "virtual_wall",
          icon: <VirtualWallIcon />,
          description: getText("virtual_wall_description"),
          disabled: !mapImageReady,
          onClick: this.initializeNewVirtuallWall,
        },
        {
          label: getText("map_list"),
          value: "map_list",
          icon: <MapICon />,
        },
        {
          label: getText("backup_map_list"),
          value: "backup_map_list",
          icon: <MapICon />,
        },
      ];
    }

    if (
      ["virtual_wall", "virtual_wall_append", "virtual_wall_delete"].includes(
        curAction
      )
    ) {
      rootActions = [
        {
          label: getText("repaint"),
          icon: <RepaintIcon />,
          onClick: () => this.setState({ newVirtualWallPolygons: [] }),
        },
        {
          label: getText("append"),
          icon: <AppendIcon />,
          value: "virtual_wall_append",
        },
        {
          label: getText("delete"),
          icon: <DeleteIcon />,
          value: "virtual_wall_delete",
        },
        {
          label: getText("submit"),
          icon: <SubmitIcon />,
          onClick: this.onVirtualWallSubmit,
        },
        {
          label: getText("cancel"),
          icon: <CloseIcon />,
          onClick: () =>
            this.setState({ curAction: null, newVirtualWallPolygons: [] }),
        },
      ];
    }

    let description = "";
    switch (curAction) {
      case "go_to":
        description = getText("dispatch_robot_description");
        break;
      case "relocalize":
        description = getText("relocalization_description");
        break;
      case "virtual_wall":
        description = getText("virtual_wall_description");
        break;
      default:
        break;
    }

    const maxMapWidth = window.innerWidth * 0.8 - 48;
    const maxMapHeight = window.innerHeight - 300;

    return (
      <>
        <Dialog fullScreen open={open} onClose={this.onClose}>
          <DialogTitle>
            <Typography variant="body2">{description}</Typography>
            <CloseIcon className={classes.closeButton} onClick={this.onClose} />
          </DialogTitle>
          <DialogContent>
            {!["map_list", "backup_map_list"].includes(curAction) && (
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
                spacing={3}
              >
                <Grid item>
                  <RobotMap
                    maxMapWidth={maxMapWidth}
                    maxMapHeight={maxMapHeight}
                    action={curAction}
                    onGoTo={this.onGoTo}
                    onRelocalize={this.onRelocalize}
                    setMapImageReady={this.setMapImageReady}
                    newVirtualWallPolygons={newVirtualWallPolygons}
                    setNewVirtualWallPolygons={this.setNewVirtualWallPolygons}
                  />
                </Grid>
                <Grid
                  item
                  container={true}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                  spacing={3}
                >
                  {curAction !== "go_to" &&
                    rootActions.map((action, index) => (
                      <Grid item={true} xs={6} lg={3} key={index}>
                        <Tooltip
                          placement="top"
                          enterDelay={200}
                          disableHoverListener={action.tooltip === undefined}
                          disableFocusListener={action.tooltip === undefined}
                          disableTouchListener={action.tooltip === undefined}
                          title={action.tooltip || ""}
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <div>
                            <Button
                              className={classes.button}
                              onClick={() => {
                                action.onClick && action.onClick();
                                action.value &&
                                  this.setState({ curAction: action.value });
                              }}
                              disabled={action.disabled}
                            >
                              <ListItemIcon>{action.icon}</ListItemIcon>
                              <Typography className={classes.label}>
                                {action.label}
                              </Typography>
                            </Button>
                          </div>
                        </Tooltip>
                      </Grid>
                    ))}
                  {curAction === "go_to" && (
                    <GotoOptions
                      onGoTo={this.onGoTo}
                      onCancel={() => this.setState({ curAction: null })}
                    />
                  )}
                </Grid>
              </Grid>
            )}
            {curAction === "map_list" && <RobotMapList />}
            {curAction === "backup_map_list" && <BackupMapList />}
          </DialogContent>
        </Dialog>
        {showRelocalization && (
          <RelocalizeOptions
            onClose={this.onRelocalizeClose}
            onConfirm={this.onRelocalizeConfirm}
          />
        )}
        <GotoModeSelection open={showReactionModeDialog} onConfirm={this.onGoToConfirm} onClose={this.onGoToClose} position={gotoPosition}/>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MapOperationDialog));
