import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import PrevPageIcon from '@material-ui/icons/NavigateBefore';
import NextPageIcon from '@material-ui/icons/NavigateNext';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    flex: 1,
    overflow: 'auto'
  },
  table: {
  },
  header: {
    fontSize: '1rem'
  },
  row: {
    fontSize: '1rem'
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)'
  },
  iconButton: {
    '&:hover': {
      cursor: 'pointer',
    }
  },
});

const tableHeaders = [
  'id',
  'username',
  'enable_service',
  'guard_company',
];

const CustomerList = props => {
  const {
    classes,
    data,
    editId,
    editValue,
    organizations,
    onClickAdd,
    onClickEdit,
    handleChange,
    onClickSubmit,
    selectedType,
    handlePrevPageClick,
    handleNextPageClick,
    noPrev,
    noNext
  } = props;
  return (
    <Grid
      className={classes.root}
      container={true}
      justify="flex-start"
      direction="row"
      alignItems="stretch"
      spacing={0}
    >
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onClickAdd(selectedType)}
      >
        {getText('add_customer')}
      </Button>
      <Grid item={true} xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (<TableCell key={index} className={classes.header}>{getText(header)}</TableCell>))}
              <TableCell className={classes.header}>
                <IconButton
                  className={classes.iconButton}
                  onClick={handlePrevPageClick}
                  disabled={noPrev}
                >
                  <PrevPageIcon />
                </IconButton>
                <span>{getText('prev')}</span><span style={{ paddingLeft: '1rem' }}>{getText('next')}</span>
                <IconButton
                  className={classes.iconButton}
                  onClick={handleNextPageClick}
                  disabled={noNext}
                >
                  <NextPageIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data ? data.map((customer, index) => {
              const editing = editId === customer.id;
              return (
                <TableRow key={customer.id}>
                  <TableCell className={classes.row}>{customer.id}</TableCell>
                  <TableCell className={classes.row}>{customer.username}</TableCell>
                  <TableCell className={classes.row}>{editing && editValue ?
                    <Select
                      id="enable_monitoring_service"
                      name="enable_monitoring_service"
                      value={editValue.enable_monitoring_service}
                      onChange={handleChange('enable_monitoring_service')}
                    >
                      <MenuItem key='true' value={true}>{getText('true')}</MenuItem>
                      <MenuItem key='false' value={false}>{getText('false')}</MenuItem>
                    </Select>
                    : customer.enable_monitoring_service ? 'Yes' : 'No'}
                  </TableCell>
                  <TableCell className={classes.row}>{editing && editValue ?
                    <Select
                      id="guard_company_id"
                      name="guard_company_id"
                      value={editValue.guard_company_id ? editValue.guard_company_id : ''}
                      onChange={handleChange('guard_company_id')}
                    >
                      {organizations.map((company, index) =>
                        <MenuItem key={company.id} value={company.id}>{company.title}</MenuItem>
                      )}
                    </Select>
                    : organizations.filter(company => company.id === customer.guard_company_id).length > 0 ?
                      organizations.filter(company => company.id === customer.guard_company_id)[0].title
                      : ''}
                  </TableCell>
                  <TableCell align="center" className={classes.row}>{editing && editValue ?
                    <CheckIcon className={classes.iconButton} onClick={onClickSubmit(selectedType)} />
                    : <EditIcon className={classes.iconButton} onClick={onClickEdit(customer.id)} />
                  }</TableCell>
                </TableRow>
              )
            }) : null}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CustomerList);
