import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  tableBody: {
    overflow: 'auto',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  tableCellRoot: {
    padding: '12px 12px 12px 12px',
    '&:last-child':{
      paddingRight: 12
    }
  },
  selected: {
    //!important to override :hover
    backgroundColor: 'rgba(76,127,114,0.25) !important'
  },
  statusCell: {
    width: 90
  },
  statusOn: {
    padding: theme.spacing(0.5),
    border: '1px solid #4C7F72',
    borderRadius: '4px',
    color: '#4C7F72',
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: '0.9rem'
  },
  statusOff: {
    padding: theme.spacing(0.5),
    border: '1px solid #93052D',
    borderRadius: '4px',
    color: '#93052D',
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: '0.9rem'
  },
  sortLabel: {
    color: '#919191',
    fontSize: '1rem'
  },
});

const trimName = name => {
  if (name.length <= 25){
    return name;
  }
  else {
    return name.substr(0, 22) + '...';
  }
}

const RobotList = props => {
  const { classes, robots, selected, handleRobotSelect, sortOrder, sortRobots } = props;
  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              classes={{root: classes.tableCellRoot}}
            >
              <TableSortLabel
                active={true}
                direction={sortOrder.name}
                onClick={() => {sortRobots('name')}}
              >
                <Typography className={classes.sortLabel}>
                  {getText('robot_name')}
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell
              classes={{root: classes.tableCellRoot}}
              className={classes.statusCell}
            >
              <TableSortLabel
                active={true}
                direction={sortOrder.status}
                onClick={() => {sortRobots('status')}}
              >
                <Typography className={classes.sortLabel}>
                  {getText('status')}
                </Typography>
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <div className={classes.tableBody}>
      <Table>
        <TableBody>
        {
          robots.map((robot, index) => {
            const online = robot.status === 'online';
            return (
              <TableRow
                key={robot.id}
                classes={{
                  selected: classes.selected
                }}
                selected={selected && selected.id === robot.id}
                hover={true}
                onClick={handleRobotSelect(robot)}
              >
                <TableCell
                  classes={{root: classes.tableCellRoot}}
                  >
                  <Tooltip
                    placement="top"
                    enterDelay={200}
                    title={`${robot.name}${robot.site ? ` - ${robot.site.name}` : ''}`}
                    >
                    <Typography variant="body1">
                      {trimName(robot.name)}
                    </Typography>
                  </Tooltip>
                  <span style={{display:'none'}}>{robot.id}</span>
                </TableCell>
                <TableCell
                  classes={{root: classes.tableCellRoot}}
                  className={classes.statusCell}
                  >
                  <Typography variant="body1" className={online ? classes.statusOn : classes.statusOff}>
                    {getText(robot.status)}
                  </Typography>
                </TableCell>
              </TableRow>
            )})
          }
          </TableBody>
        </Table>
        </div>
      </React.Fragment>
  );
};

RobotList.propTypes = {

};

RobotList.defaultProps = {

};

export default withStyles(styles)(RobotList);
