import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import moment from 'moment';

import { routineHistoryActions } from 'src/redux/actions';
import RoutineHistoryFilter from './RoutineHistoryFilter';

const mapStateToProps = state => {
  const { filters, selectedRobot, showType } = state.routineHistory;
  const { curSite: siteId } = state.site;
  const { targetOptions } = state.target;
  const { robotConfigs } = state.robot;
  const configs = selectedRobot&&robotConfigs[selectedRobot.id];
  return { filters, selectedRobot, configs, showType, targetOptions, siteId };
};

const mapDispatchToProps = dispatch => {
  return {
    setRoutineHistoryFilter: filters => {
      dispatch(routineHistoryActions.setRoutineHistoryFilter(filters));
    },
    getRoutineHistoryPage: (filters, loadAll) => {
      dispatch(routineHistoryActions.getRoutineHistoryPage(filters, loadAll));
    },
    selectRoutineHistoryTime: history_time => {
      dispatch(routineHistoryActions.selectRoutineHistoryTime(history_time))
    }
  };
};
const getStartAndEndTime = (timeType, filters) => {
  let startTime = filters.startTime;
  let endTime = filters.endTime;
  switch (timeType) {
    case 'today':
      startTime = moment().startOf('day');
      endTime = moment();
      break;
    case 'oneWeek':
      startTime = moment().subtract(1, 'week').startOf('day');
      endTime = moment();
      break;
    case 'oneMonth':
      startTime = moment().subtract(1, 'month').startOf('day');
      endTime = moment();
      break;
    case 'customized':
      startTime = startTime ? startTime : moment().startOf('day');
      endTime = endTime ? endTime : moment();
      break;
    // case 'all':
    //   startTime = moment().subtract(1, 'year').startOf('day');
    //   endTime = moment();
    //   break;
    default:
      break;
  }
  return { startTime, endTime};
};

class RoutineHistoryFilterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeType: 'today',
      startTime: moment().startOf('day'),
      endTime: moment(),
    };
  }

  handleTimeTypeChange = event => {
    const timeType = event.target.value;
    const { startTime, endTime } = getStartAndEndTime(timeType, this.props.filters)
    this.setState({ 
      timeType,
      startTime,
      endTime,
      firstKey:'',
      lastKey:'',
      direction:'next',
    });
  }

  handleStartTimeChange = time => {
    this.setState({ startTime: time });
  };

  handleEndTimeChange = time => {
    this.setState({ endTime: time });
  };

  handleSubmit = event => {
    const { filters, setRoutineHistoryFilter, getRoutineHistoryPage, selectRoutineHistoryTime } = this.props;
    const {startTime,endTime} = this.state;
    const inspectionFilters = Object.assign({}, filters, {...this.state, startTime:startTime,endTime:endTime});
    setRoutineHistoryFilter(inspectionFilters);
    getRoutineHistoryPage(inspectionFilters);
    selectRoutineHistoryTime(null)
  };

  render() {
    const { timeType, startTime, endTime } = this.state;

    return (
       <RoutineHistoryFilter
        timeType={timeType}
        startTime={startTime}
        endTime={endTime}
        handleTimeTypeChange={this.handleTimeTypeChange}
        handleStartTimeChange={this.handleStartTimeChange}
        handleEndTimeChange={this.handleEndTimeChange}
        handleFilterSave={this.handleSubmit}
       />
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RoutineHistoryFilterContainer);
