import { stringify } from "qs";
import { GeneralAdapter } from "./adapter";
import { api } from "./api";
import { getText } from "src/utils/MultilingualLoader";

const storeDataAdapter = {
  toServer: (data) => {
    return data;
  },
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    const { results, count } = result;
    return {
      count,
      list: results.map((item) => ({
        id: item.id,
        label: item.name,
        number: item["serial_no"],
        floor: item.floor,
        note: item.desc,
        type: item.category,
      })),
    };
  },
};

const algoDataAdapter = {
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    const { results, count } = result;
    return {
      count,
      list: results.map((item) => ({
        id: item.id,
        targetId: item["target_id"],
        mode: item.enabled,
        thresholds: JSON.stringify(item.thresholds, null, 4),
        action: item["waypoint_action"],
        schedule: item.schedules.map((cell) => {
          const date1 = new Date();
          date1.setHours(Math.floor(cell[0] / 60));
          date1.setMinutes(cell[0] % 60);
          const date2 = new Date();
          date2.setHours(Math.floor(cell[1] / 60));
          date2.setMinutes(cell[1] % 60);
          return [date1, date2];
        }),
        algo: item.algo,
        algoId: item["algo_id"],
        rules: JSON.stringify(item.params, null, 4),
      })),
    };
  },
};

const algoUpdateAdapter = {
  toServer: (data) => {
    return {
      params: JSON.parse(data.rules),
      thresholds: data.thresholds ? JSON.parse(data.thresholds) : {},
      algo_id: data.algoId,
      waypoint_action: data.action,
      enabled: data.mode,
      schedules: data.schedule.map((item) => {
        return [
          item[0].getHours() * 60 + item[0].getMinutes(),
          item[1].getHours() * 60 + item[1].getMinutes(),
        ];
      }),
    };
  },
};

const algoAddAdapter = {
  toServer: (targetId, algoId, data) => {
    return {
      target_id: targetId,
      algo_id: algoId,
      params: JSON.parse(data.rules),
      thresholds: data.thresholds ? JSON.parse(data.thresholds) : {},
      algo: data.algo,
      waypoint_action: data.action,
      enabled: data.mode,
      schedules: data.schedule.map((item) => {
        return [
          item[0].getHours() * 60 + item[0].getMinutes(),
          item[1].getHours() * 60 + item[1].getMinutes(),
        ];
      }),
    };
  },
};

const algoOptionsAdapter = {
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    return result.map((item) => ({
      id: item.id,
      label: item.name,
      value: item.code_name,
    }));
  },
};

const targetOptionsAdapter = {
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    return result.map((item) => ({
      id: item.id,
      label: item.name,
      value: item.id,
      floor: item.floor,
      category: item.category,
      serial_no: item.serial_no,
      reachable: item.robot_reachable,
    }));
  },
};

const floorOptionsAdapter = {
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return [];
    return [{ id: "all", label: getText("all"), value: "all" }].concat(
      result
        .filter((item) => !!item)
        .map((item) => ({
          id: item,
          label: item,
          value: item,
        }))
    );
  },
};

const targetAdapter = {
  toServer: (data) => {
    const params = {};
    if (data["name"] && typeof data["name"] === "object") {
      params.name = data["name"].label || "";
    } else {
      params.name = data.name;
    }
    params.visible = data.visible;
    params.limit = data.limit;
    params.offset = data.offset;
    params.site_id = data.site_id;
    if (data.floor) {
      params.floor = data.floor;
    }
    if (data.category) {
      params.category = data.category;
    }
    if (data.algo_config !== "all") {
      params.algo_configs_exist = data.algo_config;
    }
    if (data.algo_reference !== "all") {
      params.algo_references_exist = data.algo_reference;
    }
    return params;
  },
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    const { results, count } = result;
    return {
      count,
      list: results.map((item) => ({
        id: item.id,
        label: item.name,
        number: item["serial_no"],
        floor: item.floor,
        note: item.desc,
        type: item.category,
        reference_image: item.reference_image,
        algo_configs_exist: item.algo_configs_exist,
        algo_references_exist: item.algo_references_exist,
      })),
    };
  },
};

export const getStoreList = (
  (adapter) =>
  async (filters = {}) => {
    const params = targetAdapter.toServer(filters);
    try {
      const url = `/target/targets?visible=all&${stringify(params)}`;
      let response = await api.get(url);
      return targetAdapter.toClient(response.data);
    } catch (e) {
      throw e;
    }
  }
)(storeDataAdapter);

export const getAlgoOptions = async (filters) => {
  try {
    const url = `/site/sites/${filters.siteId}/algos`;
    const response = await api.get(url);
    return algoOptionsAdapter.toClient(response.data);
  } catch (e) {
    throw e;
  }
};

export const getAlgoConfigs = ((adapter) => async (targetId) => {
  try {
    const url = `/algo_config/algo_configs?target_id=${targetId}`;
    let response = await api.get(url);
    return adapter.toClient(response.data);
  } catch (e) {
    throw e;
  }
})(algoDataAdapter);

export const deleteAlgoConfig = async (algoId) => {
  try {
    const url = `/algo_config/algo_configs/${algoId}`;
    let response = await api.delete(url);
    return GeneralAdapter.toClient(response.data);
  } catch (e) {
    throw e;
  }
};

export const updateAlgoConfig = async (algoId, algoConfig) => {
  try {
    const url = `/algo_config/algo_configs/${algoId}`;
    const params = algoUpdateAdapter.toServer(algoConfig);
    let response = await api.patch(url, params);
    return GeneralAdapter.toClient(response.data);
  } catch (e) {
    throw e;
  }
};

export const addAlgoConfig = async (targetId, algoId, algoConfig) => {
  try {
    const url = `/algo_config/algo_configs`;
    const params = algoAddAdapter.toServer(targetId, algoId, algoConfig);
    let response = await api.post(url, params);
    return GeneralAdapter.toClient(response.data);
  } catch (e) {
    throw e;
  }
};

export const disableAlgoConfig = async (targetId) => {
  try {
    const url = `/algo_config/algo_configs/disable?target_id=${targetId}`;
    let response = await api.post(url);
    return GeneralAdapter.toClient(response.data);
  } catch (e) {
    throw e;
  }
};

export const enableAlgoConfig = async (targetId) => {
  try {
    const url = `/algo_config/algo_configs/enable?target_id=${targetId}`;
    const response = await api.post(url);
    return GeneralAdapter.toClient(response.data);
  } catch (e) {
    throw e;
  }
};

export const getTargetOptions = async (filters) => {
  try {
    const url = `/target/targets/all?site_id=${filters.siteId}&visible=all`;
    const response = await api.get(url);
    return targetOptionsAdapter.toClient(response.data);
  } catch (e) {
    throw e;
  }
};

export const getFloorOptions = async (filters) => {
  try {
    const url = `/target/targets/list_floors?site_id=${filters.siteId}`;
    const response = await api.get(url);
    return floorOptionsAdapter.toClient(response.data);
  } catch (e) {
    throw e;
  }
};

export const getCategoryOptions = async (filters) => {
  try {
    const url = `/target/targets/list_categories?site_id=${filters.siteId}`;
    const response = await api.get(url);
    return floorOptionsAdapter.toClient(response.data);
  } catch (e) {
    throw e;
  }
};

export const copyRoutes = async (params) => {
  try {
    const url = `/algo_reference/algo_references/copy`;
    const response = await api.post(url, params);
    return GeneralAdapter.toClient(response.data);
  } catch (e) {
    throw e;
  }
};

export const updateReferenceImage = async (targetId, inspectionId) => {
  try {
    const url = `/target/targets/${targetId}`;
    const response = await api.patch(url, {
      reference_inspection_id: inspectionId,
    });
    return GeneralAdapter.toClient(response.data);
  } catch (e) {
    throw e;
  }
};

export const getReferenceImage = async (targetId) => {
  try {
    const url = `/target/targets/${targetId}/reference_image`;
    const response = await api.get(url);
    return GeneralAdapter.toClient(response.data);
  } catch (e) {
    throw e;
  }
};
