import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Grid, Table, TableBody, TableRow, TableCell, Typography } from '@material-ui/core';

import { mapActions } from 'src/redux/actions';
import CircularLoading from 'src/components/circular-loading';
import OperationButton from 'src/components/Buttons/operation-button';
import OutlinedSelector from 'src/components/Selectors/OutlinedSelector';
import { getText } from 'src/utils/MultilingualLoader';
import { getTransformData } from '../utils';

const mapStateToProps = state => {
  const { selectedRobotId, robots, maps } = state.map;
  return { selectedRobotId, robots, robotMaps: maps };
};

const mapDispatchToProps = {
  getRobotMaps: robotId => mapActions.getMaps(robotId),
  updateMap: (robotId, mapId, data) => mapActions.updateMap(robotId, mapId, data),
};

const styles = theme => ({
  tableCell: {
    padding: 2,
    borderStyle: 'none'
  },
  buttonGrid: {
    padding: 0
  }
});

class MapActions extends React.Component {
  state = {
    transformRobotId: '',
    transformTargetMap: null,
    sourceRouteId: ''
  }

  componentDidUpdate(prevProps, prevState) {
    const { transformRobotId: robotId } = this.state;
    const { selectedRobotId, robotMaps, getRobotMaps, mapsPanelState } = this.props;
    // state robot change
    if (robotId && prevState.transformRobotId !== robotId && selectedRobotId !== robotId && (!robotMaps[robotId] || !robotMaps[robotId].loading)) {
      getRobotMaps(robotId);
    }
    // prop curMap change
    if ((prevProps.mapsPanelState.curMap && mapsPanelState.curMap && prevProps.mapsPanelState.curMap.id !== mapsPanelState.curMap.id) ||
      (prevProps.mapsPanelState.transformData && !this.props.mapsPanelState.transformData)) {
      this.setState({
        transformRobotId: '',
        transformTargetMap: null,
        sourceRouteId: ''
      });
    }
  }

  onRobotChange = event => {
    const { mapsPanelState, setMapsPanelState } = this.props;
    this.setState({ 
      transformRobotId: event.target.value,
      transformTargetMap: null,
    });
    setMapsPanelState({ transformData: {
      ...mapsPanelState.transformData,
      transformRobotId: event.target.value,
      transformTargetMap: null
    } });
  }

  onMapChange = event => {
    const mapId = event.target.value;
    const { robotMaps, mapsPanelState, setMapsPanelState } = this.props;
    const { transformRobotId } = this.state;
    const maps = (robotMaps[transformRobotId] && robotMaps[transformRobotId].maps) || [];
    const map = maps.find(map => map.id === mapId);
    this.setState({ transformTargetMap: map });
    setMapsPanelState({transformData: {
      ...mapsPanelState.transformData,
      transformTargetMap: map,
      xScale: 1,
      yScale: 1,
      transform: '',
      mapImageScale: 0,
      biasTransform: null
    }});
  }

  onRouteChange = event => {
    const { mapsPanelState, setMapsPanelState } = this.props;
    const routeId = event.target.value;
    const { curMap } = mapsPanelState;
    const route = curMap && curMap.mapRoutes && curMap.mapRoutes.find(route => route.id === routeId);
    this.setState({ 
      sourceRouteId: routeId
    });
    setMapsPanelState({ transformData: {
      ...mapsPanelState.transformData,
      sourceRoute: route,
    } });
  }

  onCancel = () => {
    this.setState({
      transformRobotId: '',
      transformTargetMap: null,
      sourceRoute: null
    });
    this.props.setMapsPanelState({ curAction: null, transformData: null });
  }

  onSubmitTransform = () => {
    const { selectedRobotId, updateMap } = this.props;
    const { transformData, curMap } = this.props.mapsPanelState;
    if (!transformData) return;
    const { transformRobotId, transformTargetMap } = transformData;
    if (!transformRobotId || !(transformTargetMap && transformTargetMap.id)) return;
    const transforms = curMap.transforms;
    const transformIdx = transforms.findIndex(map => map.map_id === transformTargetMap.id);
    const { transformMatrix, theta } = getTransformData(transformData);
    if (transformIdx !== -1 && transforms[transformIdx]) {
      transforms[transformIdx].matrix = transformMatrix;
      transforms[transformIdx].theta = theta;
    }
    else {
      transforms.push({
        map_id: transformTargetMap.id,
        matrix: transformMatrix,
        theta
      });
    }
    updateMap(selectedRobotId, curMap.id, {...curMap.rawData, transforms});
    this.onCancel();
  }

  render() {
    const { classes, mapsPanelState, setMapsPanelState, robots, robotMaps } = this.props;
    const { curAction, curMap } = mapsPanelState;
    const { transformRobotId, transformTargetMap, sourceRouteId } = this.state;
    const mapOptionsLoading = (transformRobotId && robotMaps[transformRobotId] && robotMaps[transformRobotId].loading) || false;
    const mapOptions = (transformRobotId && robotMaps[transformRobotId] && robotMaps[transformRobotId].maps) || [];
    const routes = (curMap && curMap.mapRoutes) || [];
    
    switch (curAction) {
      case 'transform':
        return (
          <Grid
            item
            container
            direction="row"
            spacing={3}
          >
            <Grid item>
              <Typography variant="body1">
                {getText('target_robot')}
              </Typography>
            </Grid>
            <Grid item>
              <OutlinedSelector
                noMargin={true}
                options={robots.map(robot => ({ value: robot.id, label: robot.name }))}
                value={transformRobotId}
                onChange={this.onRobotChange}
              />
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {getText('target_map')}
              </Typography>
            </Grid>
            <Grid item>
              {!mapOptionsLoading ?
                <OutlinedSelector
                  noMargin={true}
                  options={mapOptions.map(map => ({ value: map.id, label: `${map.id} ${map.name}` }))}
                  value={(transformTargetMap && transformTargetMap.id) || ''}
                  onChange={this.onMapChange}
                />
                : <CircularLoading />
              }
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {getText('source_route')}
              </Typography>
            </Grid>
            <Grid item>
              <OutlinedSelector
                noMargin={true}
                options={routes.map(route => ({ value: route.id, label: `${route.id} ${route.name}` }))}
                value={sourceRouteId}
                onChange={this.onRouteChange}
              />
            </Grid>
            <Grid item className={classes.buttonGrid}>
              <OperationButton onClick={this.onSubmitTransform}>
                {getText('submit')}
              </OperationButton>
            </Grid>
            <Grid item className={classes.buttonGrid}>
              <OperationButton onClick={this.onCancel}>
                {getText('cancel')}
              </OperationButton>
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid
            item
            container
            dirction="column"
          >
            <Grid item>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.tableCell} width={200}>{getText('map_id')}:</TableCell>
                    <TableCell className={classes.tableCell}>{curMap.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} width={200}>{getText('map_name')}:</TableCell>
                    <TableCell className={classes.tableCell}>{curMap.name}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid
              item
              container
              direction="row"
            >
              <Grid item xs={2}>
                <OperationButton onClick={() => setMapsPanelState({ curAction: 'transform', transformData: null })}>
                  {getText('transform')}
                </OperationButton>
              </Grid>
            </Grid>
          </Grid >
        );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapActions));