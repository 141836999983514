import { combineReducers } from "redux";

import { userConstants } from "src/redux/constants";
import user from "./user";
import _case from "./case";
import guard from "./guard";
import camera from "./camera";
import robot from "./robot";
import notification from "./notification";
import manage from "./manage";
import websocket from "./websocket";
import site from "./site";
import inspection from "./inspection";
import sensor from "./sensor";
import target from "./target";
import task from "./task";
import reference from "./reference";
import map from "./map";
import routineHistory from "./routineHistory";
import message from "./message";
import clip from './Vision/clip';
import licensePlateBlacklist from "./Vision/licensePlateBlacklist";
import cameravision from './Vision/cameravision';
import org from './Vision/org';
import sitevision from './Vision/sitevision';
import eventType from './Vision/eventType';
import trackingSetting from './Vision/trackingSetting';
import orgvision from './Vision/orgvision';

const rootReducer = (state, action) => {
  if (action.type === userConstants.LOGOUT_SUCCESS) {
    state = undefined;
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  orgvision,
  user,
  case: _case,
  guard,
  camera,
  robot,
  notification,
  manage,
  websocket,
  site,
  inspection,
  sensor,
  target,
  task,
  reference,
  map,
  routineHistory,
  message,
  clip,
  licensePlateBlacklist,
  cameravision,
  org,
  sitevision,
  eventType,
  trackingSetting,
});

export default rootReducer;
