import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  close: {
    padding: theme.spacing(0.5)
  }
});

const Notifications = props => {
  const {
    classes, msg, open, onClose
  } = props;
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={msg && msg.duration !== undefined ? msg.duration : 3000}
        onClose={onClose}
        message={<span id="message-id">{msg && msg.text}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose} >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
};

Notifications.propTypes = {
  classes: PropTypes.object.isRequired,
  msg: PropTypes.any,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

Notifications.defaultProps = {
  open: false
};

export default withStyles(styles)(Notifications);
