import { wsConstants } from 'src/redux/constants';

export const statusChange = (wsName, status) => {
  return {
    type: wsConstants.STATUS_CHANGE,
    wsName,
    status
  };
};

export const receiveMsg = (wsName, componentId, message) => {
  return {
    type: wsConstants.RECEIVE_MSG,
    wsName,
    componentId,
    message
  };
};

export const emptyMsgQue = (wsName, componentId) => {
  return {
    type: wsConstants.EMPTY_MSG_QUEUE,
    wsName,
    componentId
  };
};

export const unregComponent = (wsName, componentId) => {
  return {
    type: wsConstants.UNREG_COMPONENT,
    wsName,
    componentId
  };
};