import React from 'react';
import { connect } from 'react-redux';

import { referenceActions } from 'src/redux/actions';
import InspectionFilter from './InspectionFilter';

const mapStateToProps = state => {
  const { filters } = state.reference;
  return { filters };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilter: filters => {
      dispatch(referenceActions.setFilter(filters));
    },
    getInspections: (filters) => {
      dispatch(referenceActions.getInspections(filters));
    }
  };
};

class InspectionFilterContainer extends React.Component {
  constructor(props) {
    super(props);
    // const { algoConfigs } = props;
    this.state = {
      reviewStatus: 'all',
      statusOptions: [
        {
          value: 'all',
          label: 'all'
        },
        {
          value: 'ready',
          label: 'ready'
        },
        {
          value: 'finished',
          label: 'finished'
        },
        {
          value: 'failed',
          label: 'failed'
        }
      ]
    };
  }

  handleChange = (key) => (e) => {
    this.setState({
      [key]: e.target.value
    })
  }

  handleSubmit = () => {
    const { filters, getInspections, setFilter, targetId } = this.props;
    setFilter({
      ...filters,
      reviewStatus: this.state.reviewStatus,
    });
    getInspections({
      ...filters,
      reviewStatus: this.state.reviewStatus,
      targetId
    });
  }

  render() {
    const {reviewStatus, statusOptions} = this.state;

    return (
       <InspectionFilter
          reviewStatus={reviewStatus}
          statusOptions={statusOptions}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
       />
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InspectionFilterContainer);