import React from 'react';
import { connect } from 'react-redux';

import history from 'src/utils/history';
import { manageActions } from 'src/redux/actions';
import { manageConstants } from 'src/redux/constants';
import { mapManageTypeToConstant } from 'src/utils/constantMapper';
import CompanyList from './CompanyList';
import GuardAdminList from './GuardAdminList';
import GuardList from './GuardList';
import OperatorList from './OperatorList';
import CustomerList from './CustomerList';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  const { list, loading, selectedType, next, curPage, lastPage } = state.manage;
  return { list , loading, selectedType, next, curPage, lastPage };
};

const mapDispatchToProps = dispatch => {
  return {
    getList: listType => {
      dispatch(manageActions.getList(listType));
    },
    setPage: curPage => {
      dispatch(manageActions.setPage(curPage));
    },
    getMoreAccounts: (listType, next) => {
      dispatch(manageActions.getMoreAccounts(listType, next));
    },
    changeType: selectedType => {
      dispatch(manageActions.changeType(selectedType));
    },
    updateAccount: data => {
      dispatch(manageActions.updateAccount(data));
    },
    deleteAccount: data => {
      dispatch(manageActions.deleteAccount(data));
    }
  };
};

class AccountListContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      editId: -1,
      editValue: {},
      organizations: []
    };
  }

  async componentDidMount(){
    const selectedType = mapManageTypeToConstant(this.props.match.path.substr(this.props.match.path.lastIndexOf('/') + 1));
    if (selectedType && !this.props.loading){
      if (this.props.selectedType !== selectedType){
        this.props.changeType(selectedType);
      }
      this.props.getList(selectedType);
    }

    if (selectedType === manageConstants.CUSTOMER){
      let organizations = await manageActions.getOrganizations();
      organizations = organizations ? organizations.list : [];
      this.setState({
        organizations: organizations
      })
    }
  }

  async componentDidUpdate(prevProps, prevState){
    const selectedType = mapManageTypeToConstant(this.props.match.path.substr(this.props.match.path.lastIndexOf('/') + 1));
    const selectedTypePrev = mapManageTypeToConstant(prevProps.match.path.substr(prevProps.match.path.lastIndexOf('/') + 1));
    if (selectedType !== selectedTypePrev && !this.props.loading){
      if (this.props.selectedType !== selectedType){
        this.props.changeType(selectedType);
      }   
      this.props.getList(selectedType);
      if (selectedType === manageConstants.CUSTOMER){
        let organizations = await manageActions.getOrganizations();
        organizations = organizations ? organizations.list : [];
        this.setState({
          editId: -1,
          editValue: {},
          organizations: organizations
        });
      }
      else {
        this.setState({
          editId: -1,
          editValue: {},
        });
      }
    }
  }

  onClickAdd = selectedType => event => {
    switch(selectedType){
      case manageConstants.COMPANY:
        return history.push('/adminmanage/addcompany');
      case manageConstants.GUARD_ADMIN:
        return history.push('/adminmanage/addguardadmin');
      case manageConstants.CUSTOMER:
        return history.push('/adminmanage/addcustomer');
      case manageConstants.OPERATOR:
        return history.push('/guardmanage/addoperator');
      case manageConstants.GUARD:
        return history.push('/guardmanage/addguard');
      default:
        return;
    }
  }

  onClickDelete = (selectedType, id) => event => {
    if (window.confirm(getText('confirm_delete_account'))){
      this.props.deleteAccount({selectedType, id});
    }
  }

  onClickEdit = id => event => {
    if (this.state.editId !== -1){
      return;
    }
    const { list } = this.props;
    this.setState({
      editId: id,
      editValue: list.find(account => account.id === id)
    });
  }

  onClickSubmit = selectedType => event => {
    this.props.updateAccount({
      selectedType,
      ...this.state.editValue
    });
    this.setState({
      editId: -1,
      editValue: {}
    });
  }

  UpdateList = (selectedType, id) => {
    this.props.getList(selectedType);
  }

  handleChange = prop => event => {
    this.setState({
      editValue: {
        ...this.state.editValue,
        [prop]: event.target.value
      }
    });
  }

  handlePrevPageClick = event => {
    this.props.setPage(this.props.curPage - 1);
  }

  handleNextPageClick = event => {
    const { curPage, lastPage, loading, selectedType, next } = this.props;
    if (curPage < lastPage){
      this.props.setPage(curPage + 1);
    }
    else if (!loading && next){
      this.props.getMoreAccounts(selectedType, next);
    }
  }

  render() {
    const { list, next, curPage, lastPage } = this.props;
    const selectedType = mapManageTypeToConstant(this.props.match.path.substr(this.props.match.path.lastIndexOf('/') + 1));
    switch(selectedType){
      case manageConstants.COMPANY:
        return (
          <CompanyList
            data={list}
            editId={this.state.editId}
            editValue={this.state.editValue}
            selectedType={selectedType}
            onClickAdd={this.onClickAdd}
            onClickDelete={this.onClickDelete}
            onClickEdit={this.onClickEdit}
            onClickSubmit={this.onClickSubmit}
            handleChange={this.handleChange}
            />
        );
      case manageConstants.GUARD_ADMIN:
        return(
          <GuardAdminList
            data={list}
            selectedType={selectedType}
            onClickAdd={this.onClickAdd}
            onClickDelete={this.onClickDelete}
            />
        );
      case manageConstants.OPERATOR:
        return(
          <OperatorList
            data={list}
            selectedType={selectedType}
            onClickAdd={this.onClickAdd}
            onClickDelete={this.onClickDelete}
            onClickOperaterEdit = {this.onClickOperaterEdit}
            UpdateList = {this.UpdateList}
            />
        );
      case manageConstants.GUARD:
        return(
          <GuardList
            data={list}
            selectedType={selectedType}
            onClickAdd={this.onClickAdd}
            />
        );
      case manageConstants.CUSTOMER:
        return(
          <CustomerList
            data={list.slice((curPage - 1) * 20, curPage * 20)}
            editId={this.state.editId}
            editValue={this.state.editValue}
            organizations={this.state.organizations}
            selectedType={selectedType}
            onClickAdd={this.onClickAdd}
            onClickEdit={this.onClickEdit}
            onClickSubmit={this.onClickSubmit}
            handleChange={this.handleChange}
            handlePrevPageClick={this.handlePrevPageClick}
            handleNextPageClick={this.handleNextPageClick}
            noPrev={curPage === 1}
            noNext={!next && curPage === lastPage}
            />
        );
      default:
        return null;
    }
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountListContainer);
