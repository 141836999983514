import React, { useState } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@material-ui/core";

import DropDownSelectorHorizontal from "src/components/Selectors/DropDownSelectorHorizontal";
import { getText } from "src/utils/MultilingualLoader";

const nextActionOptions = [
  {
    label: getText("stay"),
    value: "stay",
  },
  {
    label: getText("go_home"),
    value: "go_home",
  },
  {
    label: getText("recover"),
    value: "recover",
  },
];

const RoutineList = ({ routines, onConfirm, onCancel }) => {
  const [routineId, setRoutineId] = useState("all");
  const [nextAction, setNextAction] = useState("go_home");

  return (
    <Dialog onClose={onCancel} open={true} maxWidth="xs" fullWidth={true}>
      <DialogTitle>
        <Typography>{getText("set_next_action")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="column">
          <Grid item xs>
            <DropDownSelectorHorizontal
              title={getText("routines")}
              options={routines}
              value={routineId}
              onChange={(e) => setRoutineId(e.target.value)}
            />
          </Grid>
          <Grid item xs>
            <DropDownSelectorHorizontal
              title={getText("routine_next_action")}
              options={nextActionOptions}
              value={nextAction}
              onChange={(e) => setNextAction(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          {getText("cancel")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!routineId || routineId === "all"}
          onClick={() => onConfirm(routineId, nextAction)}
        >
          {getText("ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoutineList;
