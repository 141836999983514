import {
    SET_VISION_ORG,
  } from 'src/redux/types/Vision/orgvision';

import React, {useMemo, useRef, useState, useEffect} from 'react';

import {SET_SITE_OPTIONS} from "src/redux/types/Vision/site";
import store from 'src/redux/store';
import * as orgServices from 'src/services/Vision/org';
import {getCameraOptions} from 'src/redux/actions/Vision/camera';
import {useSelector} from 'react-redux';


const {dispatch, getState} = store;

export function manualSetOrg() {
  const currentOrg = getState().trackingSetting.trackingSettings.organization;
  dispatch({
    type: SET_VISION_ORG,
    payload: {
      org: [{
        id: currentOrg,
        name:"_NOT_ORG_ACCOUNT",
        sites: getState().sitevision.siteOptions.map(site => site.id),
      }],
    },
  });
}

export async function getOrgs() {
  try {
    const res = await orgServices.getOrgsinfo()
    dispatch({
        type: SET_VISION_ORG,
        payload: {
          org: res,
        },
    });
    if (!getState().sitevision.siteOptions.length){
      const currentorg = getState().orgvision.org;
      let ans = []
      let posi = {}
      for (let i = 0; i < currentorg.length; i++){
        for (let j = 0; j < currentorg[i].sites.length; j++){
          if (!posi.hasOwnProperty(currentorg[i].sites[j])){
            posi[currentorg[i].sites[j]] = ans.length;
            ans = ans.concat([{
              _actions: "destroy,create,add_map,add_target,update,managements,create_building,retrieve,remove_map,configure_email_sms",
              city: "",
              county: "",
              customer_id: 0,
              id: currentorg[i].sites[j],
              label: currentorg[i].sites[j].toString(),
              location: "",
              name: currentorg[i].sites[j].toString(),
              note: "",
              polygons: [],
              province: "",
            }])
          }
        }
      }
      dispatch({
        type: SET_SITE_OPTIONS,
        payload: {
          siteOptions: ans,
        },
      });
      getCameraOptions(ans.map(v => v.id));
    }
  } catch (error) {
      console.log(error)
  }
}