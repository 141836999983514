import React from 'react';
import { withStyles, Grid, Card, GridList, Typography, Dialog, DialogTitle, DialogContent, Zoom } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import { Pannellum } from "pannellum-react";

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  card: {
    height: '100%'
  },
  verticalGrid: {
    height: '100%'
  },
  mediaList: {
    margin: '0 !important',
    padding: '0 !important',
    flexWrap: 'nowrap',
    width: '100%',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  imgTile: {
    // width: '100%',
    // To fit Safari
    // height: 'auto',
    // height: '100% !important',
    padding: '0 !important'
  },
  videoTile: {
    // width: '100%',
    objectFit: 'fill',
    // To fit Safari
    // height: 'auto',
    // height: '100% !important',
    padding: '0 !important'
  },
  title: {
    position: 'relative',
    flex: 1 // stretch title height to align between cards
  },
  pointName: {
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    '&:hover': {
      cursor: 'pointer'
    }
  },
  diaTitle: {
    textAlign: 'center',
    paddingBottom: 0
  },
  diaContent: {
    textAlign: 'center'
  },
  zoomedImg: {
    margin: 'auto',
    height: '90%',
    overflow: 'auto',
  },
  moreIcon: {
    position: 'absolute',
    top: 8,
    right: 8
  },

});

class InspectionMedium extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      imgSrc: null,
      isPanoramic: false,
      mediumHeight: 'auto'
    };
  }

  componentDidMount() {
    this.resizeMedium();
    window.addEventListener('resize', this.resizeMedium);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeMedium);
  }

  resizeMedium = () => {
    const mediumHeight = (this.card && this.card.offsetWidth * 9 / 16) || 'auto';
    this.setState({ mediumHeight });
  }

  onImageClick = medium => {
    const isPanoramic = medium.file ? medium.file.panoramic : false;
    const imgSrc = medium.file ? medium.file.url : null;
    this.setState({
      isOpen: true,
      imgSrc,
      isPanoramic
    });
  }

  cardRef = card => {
    this.card = card;
  }

  render() {
    const { classes, mediums, wayPointName, ended_at, openMenu } = this.props;
    const { isOpen, imgSrc, isPanoramic, mediumHeight } = this.state;
    const endTime = ended_at ? ended_at.format('YYYY-MM-DD HH:mm:ss') : '';


    return (
      <Card
        className={classes.card}
        square={true}
        ref={this.cardRef}
      >
        <Grid
          className={classes.verticalGrid}
          container={true}
          direction="column"
          justify="flex-start"
          spacing={0}
        >
          <Grid
            className={classes.title}
            item
            container 
            direction="row"
            justify="flex-end"
            alignItems="stretch"
          >
            <Grid item xs={11}>
              <Typography variant="body1" className={classes.pointName}>
                {wayPointName + ' ' + endTime}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <MoreIcon className={classes.moreIcon} onClick={openMenu} />
            </Grid>
          </Grid>
          <Grid item>
            <GridList cols={1} cellHeight={mediumHeight} className={classes.mediaList}>
              {
                mediums.map(medium => (
                  medium.name === 'snapshot' ?
                    <img
                      key={`${medium.id}_snapshot`}
                      className={classes.imgTile}
                      src={medium.file ? medium.file.url : null}
                      alt={getText('snapshot')}
                      onClick={() => this.onImageClick(medium)}
                      crossOrigin="anonymous"
                    />
                    : medium.name === 'video' ?
                      <video
                        key={`${medium.id}_video`}
                        className={classes.videoTile}
                        src={medium.file ? medium.file.url : null}
                        controls
                      />
                      : null
                ))
              }
            </GridList>
          </Grid>
        </Grid>
        <Dialog
          TransitionComponent={Zoom}
          fullScreen
          open={isOpen}
          onClose={() => {
            this.setState({
              isOpen: false,
              imgSrc: null,
              isPanoramic: false
            });
          }}
        >
          <DialogTitle className={classes.diaTitle}>
            <Typography variant="body1">{wayPointName} {endTime}</Typography>
            {isPanoramic && <Typography variant="body1">{getText('360_image_description')}</Typography>}
            <CloseIcon
              className={classes.closeButton}
              onClick={() => {
                this.setState({
                  isOpen: false,
                  imgSrc: null,
                  isPanoramic: false
                });
              }}
            />
          </DialogTitle>
          <DialogContent className={classes.diaContent}>
            {isPanoramic ?
              <Pannellum
                image={imgSrc}
                width="100%"
                height="100%"
                autoLoad
              />
              : <img className={classes.zoomedImg} src={imgSrc} alt={getText('snapshot')} />}
          </DialogContent>
        </Dialog>
      </Card>
    );
  }
}

export default withStyles(styles)(InspectionMedium);