import uuid from "uuid/v4";

import { cameraConstants } from 'src/redux/constants';
import { getText } from 'src/utils/MultilingualLoader'

const initialState = {
  loading: false,
  cameras: [],
  selectedCameras: {},
  lineSize: 2,
  hlsCache: {
    /*
      camera: {
      loading:
      id: <device id>
      hls: <hls url>
      err: <err>
      }
    */
  },
  history: {
    /*
      camera: { 
        loading:
        videos: [
          {
            started_at:
            ended_at:
            video:
          }
        ]
      }
    */
  },
  cameraAlgos: {},
  filters: {
    cameraStates: 'all',
    robotStatuses: 'all',
    searchQuery: '',
    type: 'all',
  },
  sortOrder: {
    sortColumn: 'status',
    checked: 'desc',
    name: 'asc',
    status: 'desc'
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case cameraConstants.DEVICE_REQUEST:
      return {
        ...state,
        hlsCache: {
          ...state.hlsCache,
          [action.device]: {
            loading: getText('preparing')
          }
        }
      };

    case cameraConstants.GET_CAMERAS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case cameraConstants.GET_CAMERAS_SUCCESS:
      let cameras = action.cameras.map(camera => ({
        ...camera,
        checked: state.selectedCameras[camera.id] ? 1 : 0
      }));
      sortCameras(state.sortOrder.sortColumn, state.sortOrder[state.sortOrder.sortColumn], cameras);
      return {
        ...state,
        loading: false,
        cameras
      };

    case cameraConstants.GET_CAMERAS_FAILURE:
      return {
        ...state,
        loading: false
      }

    case cameraConstants.GET_CAMERA_HLS_URL_REQUEST:
      return {
        ...state,
        hlsCache: {
          ...state.hlsCache,
          [action.id]: {
            loading: getText('connecting'),
          }
        }
      }

    case cameraConstants.GET_CAMERA_HLS_URL:
      return {
        ...state,
        hlsCache: {
          ...state.hlsCache,
          [action.id]: {
            loading: false,
            hls: action.hls,
            err: action.err,
            id: uuid(),
          }
        }
      };

    case cameraConstants.GET_HISTORY_REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          [action.id]: {
            loading: true,
            updating: action.updating
          }
        }
      };

    case cameraConstants.GET_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          [action.id]: {
            loading: false,
            updating: false,
            videos: action.videos
          }
        }
      };

    case cameraConstants.GET_HISTORY_FAILURE:
      return {
        ...state,
        history: {
          ...state.history,
          [action.id]: {
            loading: false,
            updating: false
          }
        }
      };

    case cameraConstants.SELECT_CAMERA:
      return {
        ...state,
        selectedCameras: {
          ...state.selectedCameras,
          [action.id]: action.checked
        }
      };

    case cameraConstants.SELECT_ALL_CAMERAS:
      const selectedCameras = Object.assign({}, state.selectedCameras);
      for (let idx in state.cameras) {
        selectedCameras[state.cameras[idx].id] = action.checked;
      }
      return {
        ...state,
        selectedCameras
      };

    case cameraConstants.SET_FILTER:
      return {
        ...state,
        filters: Object.assign({}, state.filters, action.filters)
      };

    case cameraConstants.SORT_CAMERAS:
      let newOrder = state.sortOrder[action.sortColumn] === 'desc' ? 'asc' : 'desc';
      cameras = state.cameras.map(camera => ({
        ...camera,
        checked: state.selectedCameras[camera.id] ? 1 : 0
      }));
      sortCameras(action.sortColumn, newOrder, cameras);
      return {
        ...state,
        cameras,
        sortOrder: {
          ...state.sortOrder,
          sortColumn: action.sortColumn,
          [action.sortColumn]: newOrder
        }
      };

    case cameraConstants.SET_LINE_SIZE:
      return {
        ...state,
        lineSize: action.size
      };

    case cameraConstants.CAMERA_GET_ALGOS_REQUEST:
      return {
        ...state,
        cameraAlgos: {
          ...state.cameraAlgos,
          [action.camera]: {
            ...state.cameraAlgos[action.camera],
            loading: true
          }
        }
      };

    case cameraConstants.CAMERA_GET_ALGOS_SUCCESS:
      return {
        ...state,
        cameraAlgos: {
          ...state.cameraAlgos,
          [action.camera]: {
            algos: action.algos,
            loading: false
          }
        }
      };

    case cameraConstants.CAMERA_GET_ALGOS_FAILURE:
      return {
        ...state,
        cameraAlgos: {
          ...state.cameraAlgos,
          [action.camera]: {
            ...state.cameraAlgos[action.camera],
            loading: false
          }
        }
      };

    case cameraConstants.UPDATE_CAMERAS_FROM_WS:
      let updCameras = Object.assign([], state.cameras);
      action.cameras.forEach(camera => {
        let updCameraIdx = updCameras.findIndex(_camera => _camera.id === camera.id);
        if (updCameraIdx !== -1) {
          updCameras[updCameraIdx] = {
            ...camera,
            checked: state.selectedCameras[camera.id] ? 1 : 0
          };
        }
      }) 
      return {
        ...state,
        cameras: updCameras
      }

    default:
      return state;
  }
}

export const sortCameras = (column, order, cameras) => {
  switch (column) {
    case 'name':
      return order === 'asc' ? cameras.sort(sortByName) : cameras.sort(sortByName).reverse();
    case 'status':
      return order === 'desc' ? cameras.sort(sortByStatus) : cameras.sort(sortByStatus).reverse();
    case 'checked':
      return order === 'desc' ? cameras.sort(sortByChecked) : cameras.sort(sortByChecked).reverse();
    default:
      return cameras;
  }
}

const sortByName = (a, b) => {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();
  if (nameA < nameB) {
    return -1;
  }
  else if (nameA > nameB) {
    return 1;
  }
  else {
    return 0;
  }
}

const sortByStatus = (a, b) => {
  const statusA = a.robotStatus || a.cameraState;
  const statusB = b.robotStatus || b.cameraState;
  if (statusA === statusB) {
    return sortByName(a, b);
  }
  return statusA === 'online' ? -1 : 1;
}

const sortByChecked = (a, b) => {
  const checkedA = a.checked;
  const checkedB = b.checked;
  if (checkedA > checkedB) {
    return -1;
  }
  else if (checkedA < checkedB) {
    return 1;
  }
  else {
    return 0;
  }
}
