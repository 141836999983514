export const reserveDecimal = (number, digit) => {
  return Math.round(number * Math.pow(10, digit)) / Math.pow(10, digit);
}

export const getUnit = type => {
  switch(type) {
    case 'temperature': case 'infrared':
      return '°C';
    case 'light':
      return 'lx'
    case 'humidity':
        return '%'
    case 'hcho':
      return 'mg/m3';
    case 'pm25':
      return 'ug/m3';
    case 'noise':
      return 'dB';
    default:
      return '';
  }
}