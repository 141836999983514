import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';

import RobotStatusSelector from "src/pages/Main/Cameras/SidePanel/CameraFilter/RobotStatusSelector";
import FilterIcon from "src/components/icons/FilterIcon";
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {},
  dialog: {
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: 500
  }
});

const RobotFilter = props => {
  const {
    classes, openDialog, robotStatuses,
    onOpenDialog: handleDialogOpen,
    onCloseDialog: handleDialogClose,
    onSaveFilter: handleFilterSave,
    onChangeRobotStatus: handleRobotStatusChange,
  } = props;
  return (
      <React.Fragment>
        <IconButton onClick={handleDialogOpen}>
          <FilterIcon />
        </IconButton>
        <Dialog
            open={openDialog}
            maxWidth="md"
            onClose={handleDialogClose}
            className={classes.dialog}
        >
          <DialogTitle id="filter-dialog-title">{getText('robot_filter')}</DialogTitle>
          <DialogContent
              className={classes.dialogContent}
          >
            <RobotStatusSelector
                value={robotStatuses}
                onChange={handleRobotStatusChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              {getText('cancel')}
            </Button>
            <Button onClick={handleFilterSave} color="primary" autoFocus>
              {getText('save')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
  );
};

RobotFilter.propTypes = {
  classes: PropTypes.object.isRequired
};

RobotFilter.defaultProps = {
};

export default withStyles(styles)(RobotFilter);
