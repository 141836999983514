import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import CameraList from './CameraList';
import SearchBar from "./SearchBar";
import LineSizeButton from './LineSizeButton';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto'
  },
  cameraList: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  }
});

const SidePanel = props => {
  const {classes} = props;
  return (
      <Grid
          className={classes.root}
          container={true}
          justify="flex-start"
          direction="column"
          alignItems="stretch"
          spacing={0}
      >
        <Grid item={true}>
          <Grid
            container={true}
            justify="flex-start"
            direction="row"
            alignItems="stretch"
            spacing={0}
          >
            <Grid item={true} xs={10}>
              <SearchBar />
            </Grid>
            <Grid item={true} xs={2}>
              <LineSizeButton />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} className={classes.cameraList}>
          <CameraList/>
        </Grid>
      </Grid>
  );
};

export default withStyles(styles)(SidePanel);
