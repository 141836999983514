import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, TextField, MenuItem } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { getText } from 'src/utils/MultilingualLoader';
import DateSelector from 'src/components/Selectors/DateSelector';

const styles = theme => ({
  root: {
    paddingLeft: 20,
  },
  submitButton: {
    maxWidth: 100,
  },
  label: {
    color: 'white',
    textTransform: 'initial'
  },
  textField: {
    margin: 0,
    width: 150
  },
});

const TaskFilter = (props) => {
  const {
    classes,
    name,
    startDate,
    endDate,
    reviewStatus,
    moderateStatus,
    orderStatus,
    handleChange, 
    handleSubmit,
    handleAutoComplete,
    handleDateChange,
    statusOptions,
    targetOptions,
    resultOptions,
    orderOptions,
  } = props;

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-end"
      className={classes.root}
      spacing={2}
    >
      <Grid item>
        <Autocomplete
          options={targetOptions}
          getOptionLabel={option => option && option.label ? option.label : ''}
          disableListWrap
          onChange={handleAutoComplete}
          value={name} 
          renderInput={params => (
            <TextField 
              {...params} 
              className={classes.textField}
              style={{width: 180}}
              label={getText('target_name')} 
              margin="normal" 
            />
          )}
        />
      </Grid>
      <Grid item>
        <DateSelector
          value={startDate}
          onChange={handleDateChange('startDate')}
          title={getText('start_date')}
        />
      </Grid>
      <Grid item>
        <DateSelector
          value={endDate}
          onChange={handleDateChange('endDate')}
          title={getText('end_date')}
        />
      </Grid>
      <Grid item>
        <TextField 
          select 
          fullWidth 
          label={getText('review_status')}
          className={classes.textField}
          value={reviewStatus}
          onChange={handleChange('reviewStatus')}
        >
          {statusOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item>
        <TextField 
          select 
          fullWidth 
          label={getText('moderate_status')}
          className={classes.textField}
          value={moderateStatus}
          onChange={handleChange('moderateStatus')}
        >
          {resultOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item>
        <TextField 
          select 
          fullWidth 
          label={getText('sort_by')}
          className={classes.textField}
          value={orderStatus}
          onChange={handleChange('orderStatus')}
        >
          {orderOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          className={classes.submitButton}
          onClick={handleSubmit}
        >
          <Typography variant="body2" className={classes.label}>
            {getText('submit')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(TaskFilter);