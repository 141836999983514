import store from 'src/redux/store';
import * as services from 'src/services/Vision/eventType';
import {SET_EVENT_TYPES} from 'src/redux/types/Vision/eventType';
// import {getIsSuperBox} from 'src/utils/featureFlag';

// const SET_EVENT_TYPES = 'SET_EVENT_TYPES';

export function isFacialRecognition(typeName){
    return typeof typeName === 'string' && typeName.includes('face_tracking');
}

export function isLPR(typeName){
    return typeof typeName === 'string' && typeName.includes('license_plate');
}

export function isPeople(typeName) {
    return (
      typeof typeName === 'string' &&
      (typeName.includes('face_tracking') || typeName.includes('intrude:110'))
    );
}

export function isPeopleIntrusion(typeName) {
    return typeof typeName === 'string' && typeName.includes('intrude') && !typeName.includes('car');
}

export function isVehicle(typeName) {
    return (
      typeof typeName === 'string' && (typeName.includes('car') || typeName.includes('license_plate'))
    );
}

export function isMotorcycle(typeName) {
  return (
    typeof typeName === 'string' &&
    (typeName.includes('motorcycle_enter') || typeName.includes('motorcycle_intrude'))
  );
}


export function isVehicleIntrusion(typeName) {
    return typeof typeName === 'string' && typeName.includes('car') && !typeName.includes('intrude');
}




export async function getEventTypes(){
  const {dispatch, getState} = store;
  const currentEventTypes = getState().eventType.eventTypes;
  if (currentEventTypes.length > 0) {
    return currentEventTypes;
  }

  try {
    let res = await services.getEventTypes();
    res = res.data.ret;
    const facialRecognition = {
      name: (res.find(v => v.name.includes('face_tracking'))? res.find(v => v.name.includes('face_tracking')).name : undefined) || 'face_tracking',
      event_label: 'People',
      alert_label: 'alertsView.alertsDropdown.peopleAlertType',
      event: true,
      alert: true,
    };

    const peopleIntrusion = {
      name: (res.find(v => v.name.includes('intrude:110')) ? res.find(v => v.name.includes('intrude:110')).name : undefined) || 'intrude:110',
      event_label: 'Intrusion: People',
      alert_label: 'alertsView.alertsDropdown.peopleIntrusionAlertType',
      event: true,
      alert: true,
    };

    const vehicleIntrusion = {
      name:
        (res.find(v => v.name.includes('car') && !v.name.includes('car_blocking')) ? res.find(v => v.name.includes('car') && !v.name.includes('car_blocking')).name : undefined)|| 'car',
      event_label: 'Intrusion: Vehicle',
      alert_label: 'alertsView.alertsDropdown.vehicleIntrusionAlertType',
      event: true,
      alert: true,
    };

    const lpr = {
      name: (res.find(v => v.name.includes('license_plate')) ? res.find(v => v.name.includes('license_plate')).name : undefined) || 'license_plate',
      event_label: 'Vehicle',
      alert_label: 'alertsView.alertsDropdown.licenseAlertType',
      event: true,
      alert: true,
    };


    const motorcycleIntrude = {
      name: (res.find(v => v.name.includes('motorcycle_intrude')) ? res.find(v => v.name.includes('motorcycle_intrude')).name : undefined) || 'motorcycle_intrude',
      event_label: 'Motorcycle',
      alert_label: 'alertsView.alertsDropdown.motorcycle',
      event: true,
      alert: true,
    };

    const motorcycleEnter = {
      name: (res.find(v => v.name.includes('motorcycle_enter')) ? res.find(v => v.name.includes('motorcycle_enter')).name : undefined) || 'motorcycle_enter',
      event_label: 'Motorcycle',
      alert_label: 'alertsView.alertsDropdown.motorcycle',
      event: true,
      alert: true,
    };

    // TODO: Temporarily hide other event types in sprint 6
    const eventTypes = [
          facialRecognition,
          peopleIntrusion,
          vehicleIntrusion,
          lpr,
          motorcycleEnter,
          motorcycleIntrude,
        ];

    dispatch({
      type: SET_EVENT_TYPES,
      payload: {
        eventTypes,
      },
    });
    return eventTypes;
  } catch (error) {
    throw error;
  }
}


  