import React, { useState } from 'react';
import { withStyles, GridList, GridListTile, Dialog, DialogTitle, DialogContent, Typography, Zoom } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LazyLoad from 'react-lazy-load';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  mediaList: {
    // paddingLeft: theme.spacing(1),
    overflowY: 'auto',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  listTile: {
    margin: '0 !important',
  },
  title: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  diaContent: {
    textAlign: 'center'
  },
  zoomedImg: {
    margin: 'auto',
    height: '90%',
    overflow: 'auto',
  },
});

const InspectionDetails = props => {
  const { classes, inspections, cardWidth } = props;
  const listHeight = window.innerWidth < 1280 ? cardWidth / 2 : cardWidth * 3 / 4 / 2;
  const listCols = window.innerWidth < 1280 ? 2 : 1;
  const mediumHeight = window.innerWidth < 1280 ? cardWidth / 2 * 9 / 16 : cardWidth * 1 / 4 * 9 / 16;
  const [zoomImg, setZoomImg] = useState({
    isOpen: false,
    imgSrc: null,
    imgTime: ''
  });

  if (!inspections || !inspections[0]) return null;

  let mediums = [];
  inspections.forEach(inspection => {
    mediums = mediums.concat(inspection.mediums ? inspection.mediums.map(medium => ({
      ...medium,
      endTime: inspection.ended_at ? inspection.ended_at.format('YYYY-MM-DD HH:mm:ss') : ''
    })) : []);
  });
  const wayPointName = inspections[0].way_point ? inspections[0].way_point.name : '';

  return (
    <>
      <Typography variant="body1" className={classes.title}>{wayPointName}</Typography>
      <GridList style={{ height: listHeight }} cellHeight={mediumHeight} cols={listCols} className={classes.mediaList}>
        {
          mediums.map(medium => (
            medium.name === 'snapshot' ?
              <GridListTile key={`${medium.id}_snapshot`} className={classes.listTile}>
                <LazyLoad height={mediumHeight} offsetVertical={mediumHeight}>
                  <img width='100%' height='100%' src={medium.file ? medium.file.url : null}
                    alt={getText('snapshot')}
                    onClick={() => {
                      setZoomImg({
                        isOpen: true,
                        imgSrc: medium.file ? medium.file.url : null,
                        imgTime: medium.endTime
                      });
                    }}
                  />
                </LazyLoad>
              </GridListTile>
              : medium.name === 'video' ?
                <GridListTile key={`${medium.id}_video`} className={classes.listTile}>
                  <LazyLoad height={mediumHeight} offsetVertical={3*mediumHeight}>
                    <video width='100%' height='100%' controls src={medium.file ? medium.file.url : null} />
                  </LazyLoad>
                </GridListTile>
                : null
          ))
        }
      </GridList>
      <Dialog
        TransitionComponent={Zoom}
        fullScreen
        open={zoomImg.isOpen}
        onClose={() => {
          setZoomImg({
            isOpen: false,
            imgSrc: null,
            imgTime: ''
          });
        }}
      >
        <DialogTitle>
          <CloseIcon
            className={classes.closeButton}
            onClick={() => {
              setZoomImg({
                isOpen: false,
                imgSrc: null,
                imgTime: ''
              });
            }}
          />
        </DialogTitle>
        <DialogContent className={classes.diaContent}>
          <Typography variant="body1" className={classes.title}>{wayPointName} {zoomImg.imgTime}</Typography>
          <img className={classes.zoomedImg} src={zoomImg.imgSrc} alt={getText('snapshot')} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withStyles(styles)(InspectionDetails);

