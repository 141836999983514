export const GeneralAdapter = {
  toServer: data => {
    return data;
  },
  toClient: data => {
    const { err, ret } = data;
    const result = ret;
    const error = !err || err.ec === 0 ? null : {
      code: err.ec,
      develop: err.dm,
      message: err.em,
    };
    return { error, result };
  },
  mapRobotStatuses: (queries, robotStatuses) => {
    if (robotStatuses && robotStatuses !== 'all'){
      let statuses = {};
      statuses[robotStatuses] = true;
      if (robotStatuses === 'offline'){
        statuses['robot_offline'] = true;
      }
      const query = Object.keys(statuses).join('|');
      if (query !== '') {
        queries.push(`robot.status__match=(${encodeURIComponent(query)})`);
      }
    }
  },
  mapCameraStates: (queries, cameraStates) => {
    if (cameraStates && cameraStates !== 'all'){
      queries.push(`${encodeURIComponent('camera.status')}=${encodeURIComponent(cameraStates)}`);
    }
  },
  getRobotStatus: (robot) => {
    // Online when both robox and robot are online.
    if (robot.status === 'online' && robot.state.status === 'online') {
      return 'online'
    }
    return 'offline';
  },
  getCameraState: (state) => {
    if (state === 'running' || state === 'ready') {
      return 'online'
    }
    return 'offline'
  }
};
