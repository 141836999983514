// import moment from 'moment';

import { inspectionConstants } from "src/redux/constants";

const initialState = {
  robots: [],
  allInspections: {},
  /*
    [robot_id]: {
      loading: false,
      inspections: [],
      waypoints: {
        [waypoint_id]: [
          inspection1,
          inspection2
        ]
      },
      curPage,
      lastPage,
      counts,
    }
  */
  selectedRobot: null,
  selectedWaypoint: null,
  showType: 'list',
  eventTypes: {},
  filters: {
    lineSize: 4,
    sortBy: 'ended_at',
    mediumType: 'all',
    matchMap: false,
    matchRoute: false,
    startDate: null,
    endDate: null,
    inspectionType: 'check',
    target: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case inspectionConstants.GET_ROBOT_REQUEST:
      return {
        ...state,
        robots: [],
        robotLoading: true
      };
    case inspectionConstants.GET_ROBOT_SUCCESS:
      return {
        ...state,
        robots: action.robots,
        robotLoading: false
      };
    case inspectionConstants.GET_ROBOT_FAILURE:
      return {
        ...state,
        robotLoading: false
      };
    case inspectionConstants.ROBOT_SELECTED:
      return {
        ...state,
        selectedRobot: action.robot
      };
    case inspectionConstants.WAYPOINT_SELECTED:
      return {
        ...state,
        selectedWaypoint: {
          id: action.waypointId,
          inspections: action.inspections
        }
      };
    case inspectionConstants.SET_FILTER:
      return {
        ...state,
        filters: Object.assign({}, state.filters, action.filters)
      };
    case inspectionConstants.SET_SHOW_TYPE:
      return {
        ...state,
        showType: action.showType
      }
    case inspectionConstants.GET_INSPECTION_REQUEST:
      return {
        ...state,
        allInspections: {
          ...state.allInspections,
          [action.robotId]: {
            ...state.allInspections[action.robotId],
            loading: true
          }
        }
      };
    case inspectionConstants.GET_INSPECTION_SUCCESS:
        return {
          ...state,
          allInspections: {
            ...state.allInspections,
            [action.robotId]: {
              ...state.allInspections[action.robotId],
              loading: false,
              inspections: action.inspections,
              waypoints: action.waypoints,
              curPage: 1,
              lastPage: action.loadAll && action.inspections ? 
                Math.ceil(action.inspections.length / state.filters.lineSize / 3) : 1,
              counts: action.counts
            }
          },
          selectedWaypoint: null
        }; 
    case inspectionConstants.GET_INSPECTION_FAILURE:
        return {
          ...state,
          allInspections: {
            ...state.allInspections,
            [action.robotId]: {
              ...state.allInspections[action.robotId],
              loading: false
            }
          }
        }; 
    case inspectionConstants.GET_MORE_INSPECTION_SUCCESS:
        let inspections = state.allInspections[action.robotId] && state.allInspections[action.robotId].inspections
          ? Object.assign([], state.allInspections[action.robotId].inspections) : [];
        let waypoints = state.allInspections[action.robotId] && state.allInspections[action.robotId].waypoints
          ? Object.assign({}, state.allInspections[action.robotId].waypoints) : {};
        let selectedWaypoint = state.selectedWaypoint ? Object.assign({}, state.selectedWaypoint) : null;

        inspections = inspections.concat(action.inspections);
        Object.keys(action.waypoints).forEach(waypointId => {
          if (!waypoints[waypointId]) waypoints[waypointId] = action.waypoints[waypointId];
          else waypoints[waypointId] = waypoints[waypointId].concat(action.waypoints[waypointId]);
        });
        if (selectedWaypoint) selectedWaypoint.inspections = waypoints[selectedWaypoint.id];
        
        let lastPage = state.allInspections[action.robotId] && state.allInspections[action.robotId].lastPage ? 
          state.allInspections[action.robotId].lastPage + action.morePages : 1;
        
        return {
          ...state,
          allInspections: {
            ...state.allInspections,
            [action.robotId]: {
              ...state.allInspections[action.robotId],
              loading: false,
              inspections,
              waypoints,
              curPage: lastPage,
              lastPage,
              counts: action.counts
            }
          },
          selectedWaypoint
        }; 
    case inspectionConstants.SET_PAGE:
        return {
          ...state,
          allInspections: {
            ...state.allInspections,
            [action.robotId]: {
              ...state.allInspections[action.robotId],
              curPage: action.page
            }
          }
        }; 
    case inspectionConstants.UPDATE_INSPECTION_SUCCESS:
      inspections = state.allInspections[action.robotId] && state.allInspections[action.robotId].inspections
        ? Object.assign([], state.allInspections[action.robotId].inspections) : [];
      let inspection = inspections.find(_inspection => _inspection.id === action.inspectionId);

      if (action.updData) {
        if ( Object.keys(action.updData).includes('algos')) {
          inspection.algos = action.updData.algos
        }
        if ( Object.keys(action.updData).includes('results')) {
          inspection.results = action.updData.results
        }
      }
      return {
        ...state,
        allInspections: {
          ...state.allInspections,
          [action.robotId]: {
            ...state.allInspections[action.robotId],
            inspections: inspections
          }
        }
      }
    case inspectionConstants.CREATE_CASE_FROM_INSPECTION_SUCCESS:
      inspections = state.allInspections[action.robotId] && state.allInspections[action.robotId].inspections
        ? Object.assign([], state.allInspections[action.robotId].inspections) : [];
      inspection = inspections.find(_inspection => _inspection.id === action.inspectionId);
      // only update inspection case
      inspection.case = action.updData && action.updData.caseId
      return {
        ...state,
        allInspections: {
          ...state.allInspections,
          [action.robotId]: {
            ...state.allInspections[action.robotId],
            inspections: inspections
          }
        }
      }
    case inspectionConstants.GET_EVENT_TYPES:
      return {
        ...state,
        eventTypes: action.eventTypes,
      };
    default:
      return state;
  }
};
