import { guardConstants } from 'src/redux/constants';
import { guardServices } from 'src/services';

export const getGuards = () => {
  const request = () => {
    return {
      type: guardConstants.GET_GUARDS_REQUEST
    }
  };

  const success = guards => {
    return {
      type: guardConstants.GET_GUARDS_SUCCESS,
      guards
    };
  };

  const failure = () => {
    return {
      type: guardConstants.GET_GUARDS_FAILURE
    };
  };

  return dispatch => {
    dispatch(request());
    guardServices.getGuards()
      .then(result => {
        dispatch(success(result));
      })
      .catch(error => {
        dispatch(failure());
      });
  };
};
