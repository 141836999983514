import axios from "axios";
import store from "src/redux/store";
import { userActions, notificationActions } from "src/redux/actions";

const defaultConfig = {
  baseURL: `${process.env.REACT_APP_BASE_HOST}/api/v1`,
  withCredentials: true,
  // xsrfCookieName: 'csrftoken',
  // xsrfHeaderName: 'X-CSRFToken',
  headers: { "X-APP": "TuringGuard" },
};

class API {
  static create(config = defaultConfig) {
    let api = axios.create(config);

    //handle response errors
    api.interceptors.response.use(
      (response) => {
        if (response.data && response.data.err && response.data.err.ec !== 0) {
          //authorization error
          if (response.data.err.ec === 401 || response.data.err.ec === 104)
            store.dispatch(userActions.tokenExpired());
          //other errors
          else
            store.dispatch(
              notificationActions.addNotification(response.data.err.em)
            );
        }
        return response;
      },
      (error) => {
        if (!error.response) throw error;
        //authorization error
        if (error.response.status === 401)
          store.dispatch(userActions.tokenExpired());
        //other errors
        else
          store.dispatch(
            notificationActions.addNotification(error.response.data.err.em)
          );
        return error.response;
      }
    );
    return api;
  }
}

export const api = API.create();
