import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  tableGrid: {
    height: '100%',
    overflow: 'auto'
  },
  table: {
  },
  header: {
    fontSize: '1rem'
  },
  row: {
    fontSize: '1rem'
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)'
  }
});

const tableHeaders = [
  "id",
  "username",
  "last_name",
  "first_name",
  "organization_id",
  "phone_number"
];

const GuardList = props => {
  const { classes, data, onClickAdd, selectedType } = props;
  return (
    <Grid
      container={true}
      justify="flex-start"
      direction="row"
      alignItems="stretch"
      spacing={0}
    >
      <Grid item={true} xs={12}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onClickAdd(selectedType)}
        >
          {getText('add_guard')}
        </Button>
      </Grid>
      <Grid item={true} xs={12} className={classes.tableGrid}>
        <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header, index) => (<TableCell key={index} className={classes.header}>{getText(header)}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data ? data.map((guard, index) => (
              <TableRow key={guard.id}>
                <TableCell className={classes.row}>{guard.id}</TableCell>
                <TableCell className={classes.row}>{guard.username}</TableCell>
                <TableCell className={classes.row}>{guard.last_name}</TableCell>
                <TableCell className={classes.row}>{guard.first_name}</TableCell>
                <TableCell className={classes.row}>{guard.organization}</TableCell>
                <TableCell className={classes.row}>{guard.phone_number}</TableCell>
              </TableRow>
          )) : null}
        </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(GuardList);
