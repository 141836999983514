import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';

// import ActionSelector from './ActionSelector';
import StatusSelector from './StatusSelector';
import TimeRangeSelector from './TimeRangeSelector';
import EventSourceSelector from './EventSourceSelector';
import EventTypeSelector from './EventTypeSelector';
import IncidentTypeSelector from './IncidentTypeSelector';
import CaseTypeSelector from './CaseTypeSelector';
import PrioritySelector from "./PrioritySelector";
import PageSizeSelector from './PageSizeSelector';
import FilterIcon from "src/components/icons/FilterIcon"
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  dialogContent: {
    width: 600
  }
});

const CaseFilter = props => {
  const {
    classes, 
    openDialog, 
    // action, 
    eventType,
    incidentType,
    status, timeType, startTime, endTime, eventSource,
    priority, pageSize, caseType,
    handleDialogOpen,
    handleDialogClose,
    handleFilterSave,
    handleTimeTypeChange,
    handleStartTimeChange,
    handleEndTimeChange,
    handleFilterChange
  } = props;
  return (
    <React.Fragment>
      <IconButton onClick={handleDialogOpen}>
        <FilterIcon />
      </IconButton>
      <Dialog
        open={openDialog}
        maxWidth="md"
        onClose={handleDialogClose}
        className={classes.dialog}
      >
        <DialogTitle id="filter-dialog-title">{getText('case_filter')}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid 
            container={true}
            justify="flex-start"
            direction="row"
            alignItems="stretch"
            spacing={3}
            >
            <Grid item={true} xs={timeType === 'customized' ? 12 : 6}>
              <TimeRangeSelector
                timeType={timeType}
                startTime={startTime}
                endTime={endTime}
                onChangeTimeType={handleTimeTypeChange}
                onChangeStartTime={handleStartTimeChange}
                onChangeEndTime={handleEndTimeChange}
              />
            </Grid>
            {/* <Grid item={true} xs={6}>
              <ActionSelector
                value={action}
                onChange={handleActionChange}
              />
            </Grid> */}
            {caseType === 'event' && 
              <Grid item={true} xs={6}>
                <StatusSelector
                  value={status}
                  onChange={handleFilterChange('status')}
                />
              </Grid>
            }
            <Grid item={true} xs={6}>
              <CaseTypeSelector
                value={caseType}
                onChange={handleFilterChange('caseType')}
              />
            </Grid>
            {caseType === 'event' && 
              <Grid item={true} xs={6}>
                <EventTypeSelector
                  value={eventType}
                  onChange={handleFilterChange('eventType')}
                />
              </Grid>
            }
            {caseType === 'incident' && 
              <Grid item={true} xs={6}>
                <IncidentTypeSelector
                  value={incidentType}
                  onChange={handleFilterChange('incidentType')}
                />
              </Grid>
            }
            {caseType === 'event' && 
              <Grid item={true} xs={6}>
                <EventSourceSelector
                  value={eventSource}
                  onChange={handleFilterChange('eventSource')}
                />
              </Grid>
            }
            <Grid item={true} xs={6}>
              <PrioritySelector
                value={priority}
                onChange={handleFilterChange('priority')}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <PageSizeSelector
                value={pageSize}
                onChange={handleFilterChange('pageSize')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {getText('cancel')}
          </Button>
          <Button onClick={handleFilterSave} color="primary" autoFocus>
            {getText('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

CaseFilter.propTypes = {
  classes: PropTypes.object.isRequired
};

CaseFilter.defaultProps = {
};

export default withStyles(styles)(CaseFilter);
