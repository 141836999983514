import React from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {RouteChildrenProps} from 'react-router';

import {DetailComponent} from './component';

function Detail(
  props
) {
  // console.log(props.detailId);
  const params = useParams();
  const history = useHistory();
  // console.log(props);
  // console.log(history);
  const list = history.location.state ? history.location.state.list : [];
  const from = history.location.state ? history.location.state.from : 'events';
  const index = list.findIndex(id => id === props.detailId);
  const redirect = (nextIndex) => {
    history.push({
      pathname: history.location.pathname.replace(/(.*)\/.*/, '$1') + '/' + list[nextIndex],
      state: {list, from},
    });
  };
  const prevAble = index !== -1 && index > 0;
  const nextAble = index !== -1 && index < list.length - 1;
  return (
    <DetailComponent
      id={props.detailId}
      from={from}
      prev={prevAble ? () => redirect(index - 1) : undefined}
      next={nextAble ? () => redirect(index + 1) : undefined}
    />
  );
}

export default Detail
