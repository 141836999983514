import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import { sensorActions } from 'src/redux/actions';
import SensorType from './SensorType';

const mapStateToProps = state => {
  const { selectedRobot } = state.inspection;
  const { sensors } = state.sensor;
  const sensorData = sensors[selectedRobot.id];
  return {
    sensorType: state.sensor.sensorType,
    sensorData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSensorType: type => dispatch(sensorActions.setSensorType(type))
  }
}

class SensorTypeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sensorType: props.sensorType
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // auto select the first sensor type for new sensor data
    if (this.props.sensorData && (!prevProps.sensorData || !_.isEqual(prevProps.sensorData, this.props.sensorData))) {
      const clusters = this.props.sensorData && this.props.sensorData.clusters;
      const sensorTypes = (clusters && clusters[0] && clusters[0].data && Object.keys(clusters[0].data)) || [];
      if (sensorTypes[0]) this.props.setSensorType(sensorTypes[0]);
    }

    // update showed sensor type when it changed by auto select
    if (prevProps.sensorType !== this.props.sensorType && this.props.sensorType !== this.state.sensorType) {
      this.setState({sensorType: this.props.sensorType});
    }
  }
  

  handleSensorTypeChange = event => {
    const type = event.target.value;
    this.setState({sensorType: type});
    this.props.setSensorType(type);
  }

  render() {
    const { sensorData } = this.props;
    const { sensorType } = this.state;
    const clusters = sensorData && sensorData.clusters;
    let sensorTypes = new Set();
    clusters && clusters.forEach(cluster => {
      cluster.data && Object.keys(cluster.data).forEach(type => sensorTypes.add(type))
    });
    sensorTypes = Array.from(sensorTypes)
    
    return (
      <SensorType
        sensorType={sensorType}
        sensorTypes={sensorTypes}
        onSensorTypeChange={this.handleSensorTypeChange}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SensorTypeContainer);