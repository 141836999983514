import { stringify } from "qs";
import { api } from "./api";
import { service } from "./monitoring";
import { GeneralAdapter } from "./adapter";

const generalAdapter = {
  toServer: (data) => {
    return data;
  },
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    return { result };
  },
};

const generalListAdapter = {
  toServer: (data) => {
    const { page, limit, offset, ...others } = data;
    const params = {
      ...others,
      offset:
        typeof offset === "number"
          ? offset
          : typeof page === "number" && typeof limit === "number"
          ? page * limit
          : undefined,
      limit,
    };
    return Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== null)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
  },
  toClient: (data) => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    let { results } = result;
    results = results ? results : [];
    return { count: result.count, next: result.next, results };
  },
};

export const getSites = ((adapter) => async () => {
  try {
    const url = "/site/sites/simple?limit=1000";
    let response = await api.get(url);
    response = adapter.toClient(response.data);
    const { error, results } = response;
    if (error) throw error;
    return { list: results };
  } catch (e) {
    throw e;
  }
})(generalListAdapter);

export const createSite = ((adapter) => async (data = {}) => {
  try {
    const url = `/site/sites`;
    data = adapter.toServer(data);
    let response = await api.post(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const deleteSite = ((adapter) => async (id, data = {}) => {
  try {
    const url = `/site/sites/${id}`;
    data = adapter.toServer(data);
    let response = await api.delete(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const updateSite = ((adapter) => async (data = {}) => {
  try {
    const url = `/site/sites/${data.id}`;
    data = adapter.toServer(data);
    let response = await api.put(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const getManagers = ((adapter) => async (data = {}) => {
  try {
    const url = `/site_manager/site_managers?${stringify(data)}`;
    data = adapter.toServer(data);
    let response = await api.get(url, data);
    response = adapter.toClient(response.data);
    const { error, results, count } = response;
    if (error) throw error;
    return { list: results, count };
  } catch (e) {
    throw e;
  }
})(generalListAdapter);

export const createManager = ((adapter) => async (data = {}) => {
  try {
    const url = `/site_manager/site_managers`;
    data = adapter.toServer(data);
    let response = await api.post(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const deleteManager = ((adapter) => async (id, data = {}) => {
  try {
    const url = `/site_manager/site_managers/${id}`;
    data = adapter.toServer(data);
    let response = await api.delete(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const assignManagers = ((adapter) => async (data = {}) => {
  try {
    const url = `/site/sites/${data.site_id}/managements`;
    data = adapter.toServer(data);
    let response = await api.post(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const updateSiteRobot = ((adapter) => async (data) => {
  try {
    const url = `/robot/`;
    let response = await service.put(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const getSiteBoxes = ((adapter) => async (data) => {
  try {
    const url = `/box/`;
    let response = await service.get(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return { list: result };
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const updateSiteBox = ((adapter) => async (data) => {
  try {
    const url = `/box/`;
    let response = await service.put(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const getSiteBuildings = ((adapter) => async (data) => {
  try {
    const url = `/building/buildings?${adapter.toServer(data)}`;
    let response = await api.get(url);
    response = adapter.toClient(response.data);
    const { error, results, count } = response;
    if (error) throw error;
    return { count, list: results };
  } catch (e) {
    throw e;
  }
})(generalListAdapter);

export const createSiteBuilding = ((adapter) => async (data) => {
  try {
    const url = `/building/buildings`;
    let response = await api.post(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return { list: result };
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const updateSiteBuilding = ((adapter) => async (buildingId, data) => {
  try {
    const url = `/building/buildings/${buildingId}`;
    let response = await api.put(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return { list: result };
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const deleteSiteBuilding = ((adapter) => async (buildingId) => {
  try {
    const url = `/building/buildings/${buildingId}`;
    let response = await api.delete(url);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return { list: result };
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const getSiteFloors = ((adapter) => async (data) => {
  try {
    const url = `/floor/floors?${adapter.toServer(data)}`;
    let response = await api.get(url);
    response = adapter.toClient(response.data);
    const { error, count, results, next } = response;
    if (error) throw error;
    return { count, next, list: results };
  } catch (e) {
    throw e;
  }
})(generalListAdapter);

export const createSiteFloor = ((adapter) => async (data) => {
  try {
    const url = `/floor/floors`;
    let response = await api.post(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return { list: result };
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const updateSiteFloor = ((adapter) => async (floorId, data) => {
  try {
    const url = `/floor/floors/${floorId}`;
    let response = await api.put(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return { list: result };
  } catch (e) {
    throw e;
  }
})(generalAdapter);

export const deleteSiteFloor = ((adapter) => async (floorId) => {
  try {
    const url = `/floor/floors/${floorId}`;
    let response = await api.delete(url);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return { list: result };
  } catch (e) {
    throw e;
  }
})(generalAdapter);
