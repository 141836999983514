import React from "react";
import { connect } from "react-redux";

import WebRTCContainer from "src/pages/Main/Player/WebRTCPlayer/WebRTCContainer";

const mapStateToProps = (state) => {
  const { selected } = state.robot;
  return { selected };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

class RobotCamera extends React.Component {
  constructor(props) {
    super(props);
    const height = (((window.innerWidth - 200 - 350 - 16 - 24) / 2) * 3) / 4;
    this.state = {
      needDownGrade: false,
      robotCameraHeight: window.innerWidth < 1280 ? height * 2 : height,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeVideo);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeVideo);
  }

  resizeVideo = () => {
    const height = (((window.innerWidth - 200 - 350 - 16 - 24) / 2) * 9) / 16;
    this.setState({
      robotCameraHeight: window.innerWidth < 1280 ? height * 2 : height,
    });
  };

  downGrade = () => {
    this.setState({
      needDownGrade: true,
    });
  };

  render() {
    const { selected } = this.props;
    const { robotCameraHeight } = this.state;

    return (
      <WebRTCContainer
        camera={selected}
        cameraHeight={robotCameraHeight}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RobotCamera);
