import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 16,
    cursor: 'pointer',
  },
  snapshot: {
    width: '100%',
    height: '100%',
    border: 'none',
    outline: 'none',
    objectFit: 'contain',
  },
  snapshotFF: {
    width: '100%',
    height: '100%',
    border: 'none',
    outline: 'none',
    fontSize: '50px',
    '& vjs-slider-bar': {
      backgroundColor: '#BDBDBD',
    },
  },
  noMedia: {
    height: 120,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(0,0,0,0.54)',
  },
  description: {
    marginTop: 8,
    display: 'flex',
  },
  text: {
    flex: 1,
  },
  label: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '1.57em',
  },
  lprLabel: {
    fontWeight: 300,
    color: theme.palette.text.secondary,
  },
  location: {
    fontSize: 14,
    lineHeight: '1.43em',
    color: 'rgba(0, 0, 0, 0.54)',
    wordBreak: 'break-all',
  },
  dot: {
    display: 'inline-block',
    backgroundColor: 'rgba(0, 0, 0, 0.23)',
    width: 4,
    height: 4,
    borderRadius: '50%',
    marginLeft: 4,
    marginRight: 4,
    verticalAlign: 'middle',
  },
  time: {
    fontSize: 14,
    lineHeight: '1.43em',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  action: {
    paddingRight: 2,
  },
  actionIcon: {
    color: 'rgba(0,0,0,0.54)',
    fontSize: '1.2em',
  },
  menuItemIcon: {
    color: 'rgba(0,0,0,0.54)',
    fontSize: '1.2em',
    marginRight: 10,
  },
  danger: {
    color: '#F44336',
  },
  media: {
    position: 'relative',
    height: 0,
    width: '100%',
    paddingTop: (163 / 287) * 100 + '%',
  },
  mediaWrap: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeTxt: {
    position: 'absolute',
    top: '5px',
    left: '10px',
    color: 'rgba(255, 255, 255, 0.87)',
    display: 'inline-block',
    padding: '0 10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '5px',
    fontSize: '12px',
  },
  icon: {
    fontSize: theme.spacing(2),
    marginRight: theme.spacing(0.5),
    display: 'inline-block',
    verticalAlign: 'middle',
    marginTop: theme.spacing(-0.375),
  },
  vehicle: {
    textTransform: 'capitalize',
  },
  hideTxt: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    letterSpacing: '0.1px',
  },
  breakWord: {
    wordBreak: 'break-all',
  },
}));

export default useStyles;
