import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Card, Dialog, IconButton, Zoom, Grid } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Moveable from 'react-moveable';
import { Stage, Layer, Circle, Line } from 'react-konva';

import { mapActions } from 'src/redux/actions';
import { getText } from 'src/utils/MultilingualLoader';
import OperationButton from 'src/components/Buttons/operation-button';
import { getTransformData } from '../../utils';
import { getImagePosition } from 'src/pages/Main/Robots/RobotPanel/RobotMap';

const styles = theme => ({
  card: {
    position: 'relative',
  },
  curMapDiv: {
    position: 'absolute',
    transformOrigin: 'left top',
    top: 50,
    left: 50,
    opacity: 0.5,
  },
  curMapImg: {
    width: '100%',
    height: '100%',
    objectFit: 'fill'
  },
  targetMap: {
    position: 'absolute',
    top: 50,
    left: 50,
    opacity: 0.5,
    filter: 'sepia(100%)',
  },
  fullScreenButton: {
    position: 'absolute',
    right: 10,
    bottom: 10
  },
  diaActions: {
    padding: '20px 50px',
    height: 100
  },
  buttonGrid: {
    padding: 0
  },
  stage: {
    position: 'absolute'
  }
});

const mapAreaWidthCard = window.innerWidth - 400 - 12;
const mapAreaHeightCard = window.innerHeight * 0.5;
const mapImagePadding = 50;

const mapAreaWidthFullScreen = window.innerWidth;
const mapAreaHeightFullScreen = window.innerHeight - 100;

const mapStateToProps = state => {
  const { selectedRobotId } = state.map;
  return { selectedRobotId };
};

const mapDispatchToProps = {
  updateMap: (robotId, mapId, data) => mapActions.updateMap(robotId, mapId, data)
};

class MapTransform extends React.Component {
  state = {
    imgUpdId: 1,
    fullScreen: false,
    stageWidth: 0,
    stageHeight: 0
  };

  componentDidUpdate(prevProps) {
    const { mapsPanelState, setMapsPanelState } = this.props;
    const { fullScreen } = this.state
    const { transformData: curTransformData } = mapsPanelState;
    const curTransformTargetMap = curTransformData && curTransformData.transformTargetMap;
    const { transformData: prevTransformData } = prevProps.mapsPanelState;
    const prevTransformTargetMap = prevTransformData && prevTransformData.transformTargetMap;
    // calculate and set the transformTargetMapScale when target map selected
    if (curTransformTargetMap && (!prevTransformTargetMap || curTransformTargetMap.id !== prevTransformTargetMap.id)) {
      const { transformTargetMapWidth, transformTargetMapScale, rotate } = this.getTransformTargetMapSize(curTransformTargetMap, fullScreen);
      setMapsPanelState({
        transformData: {
          ...mapsPanelState.transformData,
          mapImageScale: transformTargetMapScale,
          biasTransform: rotate ? {
            translateWidth: transformTargetMapWidth
          } : null
        }
      });
      this.setState({
        stageWidth: 0,
        stageHeight: 0,
        imgUpdId: this.state.imgUpdId + 1
      });
    }
  }

  // both source map and target map have the same scale determined by target map size
  getTransformTargetMapSize = (transformTargetMap, fullScreen) => {
    const transformTargetMapImageSize = transformTargetMap && transformTargetMap.mapSize;
    if (!transformTargetMapImageSize || !transformTargetMapImageSize[0] || !transformTargetMapImageSize[1]) {
      return {transformTargetMapWidth: 0, transformTargetMapHeight: 0, transformTargetMapScale: 0};
    }
    const transformTargetMapRadio = transformTargetMapImageSize[0] / transformTargetMapImageSize[1];
    const mapAreaWidth = fullScreen ? mapAreaWidthFullScreen : mapAreaWidthCard;
    const mapAreaHeight = fullScreen ? mapAreaHeightFullScreen : mapAreaHeightCard;
    let transformTargetMapWidth, transformTargetMapHeight = 0;
    if (transformTargetMapRadio > 0.5) {
      transformTargetMapWidth = mapAreaWidth - 2 * mapImagePadding;
      transformTargetMapHeight = transformTargetMapWidth / transformTargetMapRadio;
      if (transformTargetMapHeight > mapAreaHeight - 2 * mapImagePadding) {
        transformTargetMapHeight = mapAreaHeight - 2 * mapImagePadding;
        transformTargetMapWidth = transformTargetMapHeight * transformTargetMapRadio;
      }
    }
    else {
      transformTargetMapHeight = mapAreaWidth - 2 * mapImagePadding;
      transformTargetMapWidth = transformTargetMapHeight * transformTargetMapRadio;
      if (transformTargetMapWidth > mapAreaHeight - 2 * mapImagePadding) {
        transformTargetMapWidth = mapAreaHeight - 2 * mapImagePadding;
        transformTargetMapHeight = transformTargetMapWidth / transformTargetMapRadio;
      }
    }
    const transformTargetMapScale = transformTargetMapImageSize[0] / transformTargetMapWidth;
    const rotate = transformTargetMapRadio > 0.5 ? false : true;
    return { transformTargetMapWidth, transformTargetMapHeight, transformTargetMapScale, rotate};
  }

  curMapRef = image => {
    this.curMapImage = image;
  }

  curMapRef2 = image => {
    this.curMapImage2 = image;
    this.setState({ imgUpdId: this.state.imgUpdId + 1 })
  }

  setResize = (xScale, yScale) => {
    const { mapsPanelState, setMapsPanelState } = this.props;
    setMapsPanelState({
      transformData: {
        ...mapsPanelState.transformData,
        xScale,
        yScale,
      },
    });
  }

  setTransform = transform => {
    const { mapsPanelState, setMapsPanelState } = this.props;
    setMapsPanelState({
      transformData: {
        ...mapsPanelState.transformData,
        transform
      },
    });
  }

  resizeStage = (width, height) => {
    this.setState({ stageWidth: width, stageHeight: height })
  }

  enterFullScreen = () => {
    const { mapsPanelState, setMapsPanelState } = this.props;
    const { imgUpdId } = this.state;
    const { transformData: curTransformData } = mapsPanelState;
    const curTransformTargetMap = curTransformData && curTransformData.transformTargetMap;
    const { transformTargetMapWidth, transformTargetMapScale, rotate } = this.getTransformTargetMapSize(curTransformTargetMap, true);
    setMapsPanelState({
      transformData: {
        ...mapsPanelState.transformData,
        mapImageScale: transformTargetMapScale,
        biasTransform: rotate ? {
          translateWidth: transformTargetMapWidth
        } : null
      }
    });
    this.setState({ fullScreen: true, imgUpdId: imgUpdId + 1 });
  }

  exitFullScreen = () => {
    const { mapsPanelState, setMapsPanelState } = this.props;
    const { imgUpdId } = this.state;
    const { transformData: curTransformData } = mapsPanelState;
    const curTransformTargetMap = curTransformData && curTransformData.transformTargetMap;
    const { transformTargetMapWidth, transformTargetMapScale, rotate } = this.getTransformTargetMapSize(curTransformTargetMap, false);
    setMapsPanelState({
      transformData: {
        ...mapsPanelState.transformData,
        mapImageScale: transformTargetMapScale,
        biasTransform: rotate ? {
          translateWidth: transformTargetMapWidth
        } : null
      }
    });
    this.setState({ fullScreen: false, imgUpdId: imgUpdId + 1 });
  }

  onCancel = () => {
    this.props.setMapsPanelState({ curAction: null, transformData: null });
  }

  onSubmitTransform = () => {
    const { selectedRobotId, updateMap } = this.props;
    const { transformData, curMap } = this.props.mapsPanelState;
    if (!transformData) return;
    const { transformRobotId, transformTargetMap } = transformData;
    if (!transformRobotId || !(transformTargetMap && transformTargetMap.id)) return;
    const transforms = curMap.transforms;
    const transformIdx = transforms.findIndex(map => map.map_id === transformTargetMap.id);
    const { transformMatrix, theta } = getTransformData(transformData);
    if (transformIdx !== -1 && transforms[transformIdx]) {
      transforms[transformIdx].matrix = transformMatrix;
      transforms[transformIdx].theta = theta;
    }
    else {
      transforms.push({
        map_id: transformTargetMap.id,
        matrix: transformMatrix,
        theta
      });
    }
    updateMap(selectedRobotId, curMap.id, { ...curMap.rawData, transforms });
    this.onCancel();
  }

  render() {
    const { classes, mapsPanelState } = this.props;
    const { imgUpdId, fullScreen, stageWidth, stageHeight } = this.state;
    const { curMap, transformData } = mapsPanelState;
    const transformTargetMap = transformData && transformData.transformTargetMap;
    const sourceRoute = transformData && transformData.sourceRoute;

    const curMapImageSize = curMap.mapSize;
    const transformTargetMapImageSize = transformTargetMap && transformTargetMap.mapSize;
    const mapAreaWidth = fullScreen ? mapAreaWidthFullScreen : mapAreaWidthCard;
    const mapAreaHeight = fullScreen ? mapAreaHeightFullScreen : mapAreaHeightCard;
    let curMapWidth, curMapHeight = 0;
    if (!curMapImageSize || !curMapImageSize[0] || !curMapImageSize[1]) {
      return (<div>Invalid map</div>);
    }
    const curMapRadio = curMapImageSize[0] / curMapImageSize[1];
    curMapWidth = mapAreaWidth - 2 * mapImagePadding;
    curMapHeight = curMapWidth / curMapRadio;
    if (curMapHeight > mapAreaHeight - 2 * mapImagePadding) {
      curMapHeight = mapAreaHeight - 2 * mapImagePadding;
      curMapWidth = curMapHeight * curMapRadio;
    }
    
    let transformTargetMapWidth, transformTargetMapHeight, transformTargetMapScale = 0;
    let rotate = false;
    if (transformTargetMap) {
      if (!transformTargetMapImageSize || !transformTargetMapImageSize[0] || !transformTargetMapImageSize[1]) {
        return (<div>Invalid map</div>);
      }
      const transformTargetMapSize = this.getTransformTargetMapSize(transformTargetMap, fullScreen);
      transformTargetMapWidth = transformTargetMapSize.transformTargetMapWidth;
      transformTargetMapHeight = transformTargetMapSize.transformTargetMapHeight;
      transformTargetMapScale = transformTargetMapSize.transformTargetMapScale;
      rotate = transformTargetMapSize.rotate;
      curMapWidth = curMapImageSize[0] / transformTargetMapScale;
      curMapHeight = curMapImageSize[1] / transformTargetMapScale;
    }

    // get route
    let pathLines = [];
    let waypoints = [];
    let prev = null;
    if (curMap && sourceRoute && sourceRoute.way_points) {
      const getPosition = getImagePosition(
        curMap.mapConfig,
        { width: curMap.mapSize && curMap.mapSize[0], height: curMap.mapSize && curMap.mapSize[1] },
        { width: stageWidth || curMapWidth, height: stageHeight || curMapHeight }
      );
      sourceRoute.way_points.forEach((wayPoint, idx) => {
        const position = wayPoint.point && getPosition(wayPoint.point.x, wayPoint.point.y);
        if (position && position.x && position.y && !isNaN(position.x) && !isNaN(position.y)) {
          waypoints.push(
            <Circle key={'dot' + idx} radius={1}
              x={position.x} y={position.y} fill="blue" />
          );
          if (prev) {
            pathLines.push(
              <Line
                key={'line' + idx}
                points={[position.x, position.y, prev.x, prev.y]}
                stroke="green"
                strokeWidth={1} />
            )
          }
          prev = position;
        }
      });
    }

    if (fullScreen) {
      return (
        <Dialog
          fullScreen
          TransitionComponent={Zoom}
          open={fullScreen}
          onClose={this.exitFullScreen}
        >
          <Card
            className={classes.card}
            style={{ width: mapAreaWidth, height: mapAreaHeight }}
          >
            <img
              className={classes.targetMap}
              src={transformTargetMap.mapUrl}
              alt={transformTargetMap.name}
              width={transformTargetMapWidth}
              height={transformTargetMapHeight}
              style={{transformOrigin: '0 0', transform: rotate ? `translateY(${transformTargetMapWidth}px) rotate(-90deg)` : ''}}
            />
            <div
              className={classes.curMapDiv}
              ref={this.curMapRef2}
              style={{ width: curMapWidth, height: curMapHeight }}
            >
              <Stage
                className={classes.stage}
                width={stageWidth || curMapWidth}
                height={stageHeight || curMapHeight}
                x={0}
                y={0}
              >
                <Layer>
                  {pathLines}
                </Layer>
              </Stage>
              <img
                id={imgUpdId}
                className={classes.curMapImg}
                src={curMap.mapUrl}
                alt={curMap.name}
              />
            </div>
          </Card>
          <Grid container className={classes.diaActions} spacing={3}>
            <Grid item className={classes.buttonGrid}>
              <OperationButton onClick={this.onSubmitTransform}>
                {getText('submit')}
              </OperationButton>
            </Grid>
            <Grid item className={classes.buttonGrid}>
              <OperationButton onClick={this.onCancel}>
                {getText('cancel')}
              </OperationButton>
            </Grid>
          </Grid>
          <Moveable
            id={imgUpdId}
            target={this.curMapImage2}
            // snappable={true}
            // bounds={{
            //   left: -curMapWidth + 50,
            //   top: -curMapHeight + 50,
            //   right: mapAreaWidth + curMapWidth - 50,
            //   bottom: mapAreaHeight + curMapHeight - 50
            // }}
            draggable={true}
            onDrag={({
              target,
              transform,
            }) => {
              target.style.transform = transform;
            }}
            onDragEnd={({ target }) => {
              this.setTransform(target.style.transform);
            }}
            resizable={true}
            onResize={({ target, width, height, drag }) => {
              target.style.width = `${width}px`;
              target.style.height = `${height}px`;
              this.resizeStage(width, height);
            }}
            onResizeEnd={({ target }) => {
              const width = parseFloat(target.style.width.slice(0, target.style.width.length - 2));
              const height = parseFloat(target.style.height.slice(0, target.style.width.length - 2));
              const xScale = width / curMapWidth;
              const yScale = height / curMapHeight;
              this.setResize(xScale, yScale);
            }}
            rotatable={true}
            rotationPosition={"right"}
            onRotate={({
              target,
              transform,
            }) => {
              target.style.transform = transform;
            }}
            onRotateEnd={({ target }) => {
              this.setTransform(target.style.transform);
            }}
          />
        </Dialog>
      );
    }

    return (
      <Card
        className={classes.card}
        style={{ width: mapAreaWidth, height: mapAreaHeight }}
      >
        {transformTargetMap &&
          <>
            <img
              className={classes.targetMap}
              src={transformTargetMap.mapUrl}
              alt={transformTargetMap.name}
              width={transformTargetMapWidth}
              height={transformTargetMapHeight}
              style={{transformOrigin: '0 0', transform: rotate ? `translateY(${transformTargetMapWidth}px) rotate(-90deg)` : ''}}
            />
            <div className={classes.fullScreenButton}>
              <IconButton onClick={this.enterFullScreen}>
                <FullscreenIcon />
              </IconButton>
            </div>
          </>
        }
        <div
          className={classes.curMapDiv}
          ref={this.curMapRef}
          style={{ width: curMapWidth, height: curMapHeight }}
        >
          <Stage
            className={classes.stage}
            width={stageWidth || curMapWidth}
            height={stageHeight || curMapHeight}
            x={0}
            y={0}
          >
            <Layer>
              {pathLines}
            </Layer>
          </Stage>
          <img
            id={imgUpdId}
            className={classes.curMapImg}
            src={curMap.mapUrl}
            alt={curMap.name}
          />
        </div>
        {transformTargetMap &&
          <Moveable
            id={imgUpdId}
            target={this.curMapImage}
            // snappable={true}
            // bounds={{
            //   left: -curMapWidth + 50,
            //   top: -curMapHeight + 50,
            //   right: mapAreaWidth + curMapWidth - 50,
            //   bottom: mapAreaHeight + curMapHeight - 50
            // }}
            draggable={true}
            onDrag={({
              target,
              transform,
            }) => {
              target.style.transform = transform;
            }}
            onDragEnd={({ target }) => {
              this.setTransform(target.style.transform);
            }}
            resizable={true}
            onResize={({ target, width, height, drag }) => {
              target.style.width = `${width}px`;
              target.style.height = `${height}px`;
              this.resizeStage(width, height);
            }}
            onResizeEnd={({ target }) => {
              const width = parseFloat(target.style.width.slice(0, target.style.width.length - 2));
              const height = parseFloat(target.style.height.slice(0, target.style.height.length - 2));
              const xScale = width / curMapWidth;
              const yScale = height / curMapHeight;
              this.setResize(xScale, yScale);
            }}
            rotatable={true}
            rotationPosition={"right"}
            onRotate={({
              target,
              transform,
            }) => {
              target.style.transform = transform;
            }}
            onRotateEnd={({ target }) => {
              this.setTransform(target.style.transform);
            }}
          />
        }
      </Card>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapTransform));