import React from 'react';
import { Layer, Line } from 'react-konva';

const VirtualWall = props => {
  const { position, newVirtualWallPolygons, appendingVirtualWallPolygon, getImagePosition, scale } = props;
  const curPolygon = appendingVirtualWallPolygon.concat([position.x, position.y]);

  let imagePolygons = [];
  newVirtualWallPolygons.forEach((polygon, index) => {
    let polygonPoints = [];
    polygon && polygon.forEach(point => {
      const position = point && getImagePosition(point[0], point[1]);
      if (position && position.x && position.y && !isNaN(position.x) && !isNaN(position.y)) polygonPoints.push(position.x, position.y);
    });

    if (polygonPoints.length >= 3) {
      imagePolygons.push(
        <Line 
          key={index}
          points={polygonPoints}
          closed={true}
          fill='gray'
          stroke="black"
          strokeWidth={1/Math.sqrt(scale)}
        />
      )
    }
  });

  return (
    <React.Fragment>
      <Layer>
        <Line points={curPolygon} tension={0} stroke='black' strokeWidth={1/Math.sqrt(scale)} />
        {imagePolygons}
      </Layer> 
    </React.Fragment>);
};

export default VirtualWall;
