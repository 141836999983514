import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, TextField, MenuItem } from '@material-ui/core';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    paddingLeft: 20,
  },
  submit: {
    textAlign: 'right'
  },
  submitButton: {
    maxWidth: 100,
  },
  label: {
    color: 'white',
    textTransform: 'initial'
  },
  textField: {
    width: 200
  }
});

const InspectionFilter = (props) => {
  const {
    classes,
    reviewStatus,
    statusOptions,
    handleChange,
    handleSubmit,
  } = props;

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-end"
      className={classes.root}
      spacing={1}
    >
      <Grid item>
        <TextField 
          select 
          fullWidth 
          label={'Review Status'}
          className={classes.textField}
          value={reviewStatus}
          onChange={handleChange('reviewStatus')}
        >
          {statusOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xl={1} lg={2} className={classes.submit}>
        <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          className={classes.submitButton}
          onClick={handleSubmit}
        >
          <Typography variant="body2" className={classes.label}>
            {getText('submit')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(InspectionFilter);