import React from "react";
import { connect } from "react-redux";

import Messages from "./Messages";
import { messageActions } from "src/redux/actions";

const mapStateToProps = (state) => {
  const { message, level } = state.message;
  return {
    message,
    level,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideNotification: () => {
      dispatch(messageActions.hideNotification());
    },
  };
};

const MessagesContainer = ({ message, level, hideNotification }) => {
  return (
    <Messages message={message} level={level} handleClose={hideNotification} />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesContainer);
