import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Switch, Typography } from '@material-ui/core';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({

});

const OnOffSwitch = props => {
  const { value, onChange } = props;
  return (
    <div>
      <Typography>
        {getText('off')}
      <Switch
        checked={value}
        onChange={onChange}
        color="primary"
      />
        {getText('on')}
      </Typography>
    </div>
  );
}

OnOffSwitch.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

OnOffSwitch.defaultProps = {
  value: false,
  onChange: () => {}
}

export default withStyles(styles)(OnOffSwitch);