import React from 'react';
import {useSelector} from 'react-redux';
import {Typography, IconButton} from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as RemoveIcon,
  Add as AddIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  CheckCircle as CheckCircleIcon,
  ArrowForward as ArrowForwardIcon,
  Flag as FlagIcon,
} from '@material-ui/icons';
import clsx from 'clsx';

// import {RootState} from 'src/redux/reducers';
import useStyles from './index.style';
import CroppedImage from 'src/components/Vision/CroppedImage';
import {ReactComponent as ContactPage} from 'src/assets/Vision/contact_page.svg';


const TableCard = ({
  row,
  onView,
  onDelete,
  selectable = false,
  selected = false,
  onSelect,
  onLinkToClips,
  disableHover = false,
  onFlag,
  isContact,
}) => {
  const classes = useStyles();
  // const orgSettings = useSelector((state) => state.org.org);
  return (
    <Typography
      component="div"
      onClick={() => {
        if (disableHover) return;
        if (selectable) {
          onSelect && onSelect(row);
        } else {
          onView(row);
        }
      }}
      className={
        disableHover
          ? `${classes.simpleCard}`
          : `${classes.card} ${selectable ? classes.selectable : ''} ${
              selected ? classes.selected : ''
            }`
      }
    >
      <Typography component="div" className={classes.content}>
        {selectable && (
          <div className={classes.checkboxContainer}>
            {selected ? (
              <CheckCircleIcon className={classes.checkboxIcon} />
            ) : (
              <RadioButtonUncheckedIcon className={classes.checkboxIcon} />
            )}
          </div>
        )}
        <Typography
          component="div"
          className={
            disableHover
              ? `${classes.photoBox} ${classes.selectable}`
              : `${classes.photoBox} ${selectable ? classes.selectable : ''}`
          }
        >
          <IconButton
            className={classes.action}
            // onClick={e => {
            //   e.stopPropagation();
            //   onLinkToClips(row);
            // }}
          >
            <ArrowForwardIcon />
          </IconButton>
          {row.img_url || row.thumbnail_url ? (
            <CroppedImage target={row} className={classes.photo} />
          ) : (
            <Typography className={classes.nameAbbr}>
              {((row.first_name && row.first_name[0]) || '').toUpperCase()}
              {((row.last_name && row.last_name[0]) || '').toUpperCase()}
            </Typography>
          )}
          {(row.is_flag || row.is_contact) && (
            <Typography component="span" className={classes.contact}>
              {(!row.is_flag || isContact) && row.is_contact && (
                <ContactPage className={classes.contactIcon} />
              )}
              {!isContact && row.is_flag && (
                <FlagIcon className={clsx(classes.contactIcon, classes.flagIcon)} />
              )}
            </Typography>
          )}
        </Typography>

        <Typography component="div" className={classes.bottom}>
          <Typography className={classes.name}>{row.data.person_name}</Typography>
          <Typography component="div" className={classes.buttons}>
            {onDelete && (
              <IconButton
                color="primary"
                className={classes.button}
                // onClick={e => {
                //   e.stopPropagation();
                //   onDelete(row);
                // }}
              >
                <RemoveIcon className={classes.icon} />
              </IconButton>
            )}
            {/* {(orgSettings ? orgSettings.enable_tracking : null) && (
              <IconButton
                color="primary"
                className={classes.button}
                onClick={e => {
                  e.stopPropagation();
                  onFlag && onFlag(row);
                }}
              >
                <FlagIcon className={classes.icon} />
              </IconButton>
            )} */}
            <IconButton color="primary" className={classes.button}>
              {row.data.person_name ? (
                <EditIcon className={classes.icon} />
              ) : (
                <AddIcon className={classes.icon} />
              )}
            </IconButton>
          </Typography>
        </Typography>
      </Typography>
    </Typography>
  );
};

export default TableCard;
