import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

import { robotActions } from 'src/redux/actions';
import RobotPanel from './RobotPanel';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  const { selected, robotConfigs, robotDetectionConfigs, robotAlgos } = state.robot;
  const { targetOptions } = state.target;
  const { curSite } = state.site;
  const targets = targetOptions[curSite] || [];
  return { selected, robotConfigs, robotDetectionConfigs, robotAlgos, targets };
};

const mapDispatchToProps = dispatch => {
  return {
    getRobotConfigs: robot_id => {
      dispatch(robotActions.getRobotConfigs(robot_id));
    },
    getRobotDetectionConfigs: robot_id => {
      dispatch(robotActions.getRobotDetectionConfigs(robot_id));
    },
    getRobotAlgos: robot_id => {
      dispatch(robotActions.getRobotAlgos(robot_id));
    },
  };
};

class RobotPanelContainer extends React.Component {
  state = {
    panelContent: 'main'
  };

  componentDidMount() {
    const { selected, getRobotConfigs, getRobotDetectionConfigs, getRobotAlgos } = this.props;
    if (selected) {
      getRobotConfigs(selected.id);
      getRobotDetectionConfigs(selected.id);
      getRobotAlgos(selected.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { selected, getRobotConfigs, getRobotDetectionConfigs, getRobotAlgos } = this.props;
    if (selected && (!prevProps.selected || selected.id !== prevProps.selected.id)) {
      getRobotConfigs(selected.id);
      getRobotDetectionConfigs(selected.id);
      getRobotAlgos(selected.id);
      this.setState({panelContent: 'main'});
    }
  }

  render() {
    const { selected, robotConfigs, robotDetectionConfigs, robotAlgos, targets } = this.props;
    const { panelContent } = this.state;
    if (!selected) return (<Typography variant='body1'>{getText('no_robot_selected')}</Typography>);
    const id = selected.id;
    const configs = robotConfigs[id];
    const detectionConfigs = robotDetectionConfigs[id];
    const algos = robotAlgos[id] && robotAlgos[id].algos;
    
    return (
      <RobotPanel
        panelContent={panelContent}
        setPanelContent={contentName => this.setState({panelContent: contentName})}
        selected={selected} 
        configs={configs} 
        targets={targets}
        detectionConfigs={detectionConfigs}
        algos={algos || []}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RobotPanelContainer);
