import React from 'react';
import { withStyles, Typography } from '@material-ui/core';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  text: {
    margin: 'auto'
  }
});

const LoadingText = props => {
  const { classes } = props;
  
  return (<Typography variant='body1' className={classes.text} >{getText('loading')}...</Typography>);
}

export default withStyles(styles)(LoadingText);