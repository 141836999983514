import React, {useContext, useReducer, useState, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import * as api from 'src/services/Vision/event';
// import {Case} from 'src/models/case';
// import {ClipFilter} from 'src/redux/types/clip';
// import {setClipsFilter} from 'src/redux/actions/clip';
// import {defaultFilter} from 'src/redux/reducers/clip';
// import {RootState} from 'src/redux/reducers';
import {isPeople, isVehicle, isMotorcycle} from 'src/redux/actions/Vision/eventType';
import {getEventTypes} from 'src/redux/actions/Vision/eventType';
// import {MinCameras} from 'src/models/camera';
// import {SimpleSites} from 'src/models/site';
// import {TrackingTarget} from 'src/models/employee';
// import {getSiteOptions} from 'src/redux/actions/site';
// import {getCameraOptions} from 'src/redux/actions/camera';
// import {ACTION_VIEW} from 'src/redux/types/alert';
// import {getTrackingTargetById} from 'src/services/employee';
import {DetailContext} from './context';
import {DetailType, DetailFrom} from './definition';
// import {getSharedEvent} from 'src/services/share';


const ACTION_VIEW = 'viewed';
export function useDetailContext(){
  const ans = useContext(DetailContext);
  return ans;
}

export function useIsPeople() {
  return useDetailContext().detailType === DetailType.People;
}

export function useIsVechicle(){
  return useDetailContext().detailType === DetailType.Vehicle;
}

export function useIsAlert(){
  return useDetailContext().detailFrom === DetailFrom.Alert;
}

export function useForceUpdate() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignore, forceUpdate] = useReducer(x => x + 1, 0);
  return forceUpdate;
}

export function useDetail(detailId) {
  const [detail, setDetail] = useState(null);
  const history = useHistory();
  useEffect(() => {
    api.updateCase2({
      id: detailId,
      action: ACTION_VIEW,
    });
    // Use response of update to set detail when backend is ready
    api
      .getCaseDetail(detailId)
      .then(setDetail)
      .catch(e => {
        history.push('/404');
        console.log('Error', e);
      });
  }, [detailId]);
  return {
    detail,
    refresh: () =>
      api
        .getCaseDetail(detailId)
        .then(setDetail)
        .catch(e => {
          history.push('/404');
          console.log('Error', e);
        }),
  };
}


export function useClipFilter() {
  const isPeople = useIsPeople();
  const isVehicle = useIsVechicle();

  const {eventTypeOption} = useDetailContext();
  const clipFilter = useSelector((state) => state.clip.filter);
  const newFilter = Object.assign({}, clipFilter);
  if (!eventTypeOption) return newFilter;
  newFilter.type = isPeople
    ? eventTypeOption.people
    : isVehicle
    ? eventTypeOption.vehicle
    : eventTypeOption.motorcycle;
  return newFilter;
}

export function useEventType() {
  const eventTypes = useSelector((state) => state.eventType.eventTypes);
  useEffect(() => {
    if (eventTypes.length === 0) getEventTypes();
  }, []);
  const option = {people: '', vehicle: '', motorcycle: ''};
  const people = [];
  const vehicle = [];
  const motorcycle = [];
  eventTypes.forEach(eventType => {
    if (isPeople(eventType.name)) people.push(eventType.name);
    if (isVehicle(eventType.name)) vehicle.push(eventType.name);
    if (isMotorcycle(eventType.name)) motorcycle.push(eventType.name);
  });
  option.people = people.join(',');
  option.vehicle = vehicle.join(',');
  option.motorcycle = motorcycle.join(',');

  return option;
}

// export function useAllSites() {
//   const sites = useSelector((state) => state.site.siteOptions);
//   useEffect(() => {
//     if (sites.length === 0) getSiteOptions();
//   }, [sites]);
//   return sites;
// }

// export function useLastTrackingObject(obj) {
//   const [trackingObject, setTrackingObject] = useState(null);
//   useEffect(() => {
//     getTrackingTargetById(obj.id)
//       .then(res => {
//         if (res) {
//           setTrackingObject(res);
//         } else {
//           setTrackingObject(null);
//         }
//       })
//       .catch(() => {
//         setTrackingObject(null);
//       });
//   }, [obj]);
//   return trackingObject;
// }
