import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MomentUtils from "@date-io/moment";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { getText, getDateLocale } from 'src/utils/MultilingualLoader'

const styles = theme => ({
  root: {

  },

});

const TimeSelector = props => {
  const { value, onChange, title } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={getDateLocale()}>
      <TimePicker
        value={value}
        onChange={onChange}
        label={title}
        disableFuture
        showTodayButton
        cancelLabel={getText('cancel')}
        okLabel={getText('ok')}
      />
    </MuiPickersUtilsProvider>
  );
};

TimeSelector.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string
};

TimeSelector.defaultProps = {
  value: null,
  title: '',
  onChange: () => {}
};

export default withStyles(styles)(TimeSelector);
