import { userConstants } from 'src/redux/constants';
import { getUserInfo } from 'src/utils/auth';

const initialState = {
  submitting: false,
  role: getUserInfo().role,
  signedIn: getUserInfo().userId ? true : false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        submitting: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        signedIn: true,
        submitting: false,
        role: action.result.role
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        signedIn: false,
        submitting: false
      };
    case userConstants.LOGOUT_REQUEST:
      return {
        ...state,
        submitting: true,
      };
    case userConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        signedIn: false,
        submitting: false,
        role: null,
      };
    case userConstants.LOGOUT_FAILURE:
      return {
        ...state,
        submitting: false,
      };
    case userConstants.REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        submitting: true
      };
    case userConstants.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        submitting: false
      };
    case userConstants.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        submitting: false
      };
    default:
      return state
  }
};
