import React from "react";

import { connect } from "react-redux";
import CameraPanel from "./CameraPanel";

const mapStateToProps = (state) => {
  return {
    cameras: state.camera.cameras,
    selectedCameras: state.camera.selectedCameras,
    lineSize: state.camera.lineSize,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

class CameraPanelContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // video_height = (window_width - sideNav_width - sidePanel_width - mainPanel_padding - grid_gap) / num_videos_per_line * ratio
      cameraHeight:
        (((window.innerWidth - 200 - 350 - 16 - 24 * (props.lineSize - 1)) /
          props.lineSize) *
          9) /
        16,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeVideo);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeVideo);
  }

  componentDidUpdate(prevProps) {
    if (this.props.lineSize !== prevProps.lineSize) this.resizeVideo();
  }

  resizeVideo = () => {
    this.setState({
      cameraHeight:
        (((window.innerWidth -
          200 -
          350 -
          16 -
          24 * (this.props.lineSize - 1)) /
          this.props.lineSize) *
          9) /
        16,
    });
  };

  render() {
    const { cameraHeight } = this.state;
    const { cameras, selectedCameras, lineSize } = this.props;
    const selectedCamerasList = cameras.filter(
      (camera) => selectedCameras[camera.id]
    );

    return (
      <CameraPanel
        selectedCameras={selectedCamerasList}
        lineSize={lineSize}
        cameraHeight={cameraHeight}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CameraPanelContainer);
