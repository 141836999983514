import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';

import { cameraActions } from 'src/redux/actions';
import HlsPlayer from 'src/pages/Main/Player/HlsPlayer';
import PlayerCard from 'src/pages/Main/Player/PlayerCard';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  const { hlsCache } = state.camera;
  const { role } = state.user;
  return { hlsCache, role };
};

const mapDispatchToProps = dispatch => {
  return {
    getHLSURL: (deviceID, msg, options) => {
      dispatch(
        cameraActions.deviceRequest(deviceID)
      );
      if (window.wsAPP) window.wsAPP.sendMsg(msg, options);
    }
  };
};

class CameraPlayerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshDelay: 800
    }
  }

  loadCamera = (cameraID, cameraType, force = true) => {
    const { hlsCache } = this.props;
    const hlsURL = hlsCache[cameraID];
    if (!force && hlsURL) {
      return;
    }

    const device = cameraID;
    var act = "";
    var args = {};
    if (cameraType === "robot") {
      act = "robot.start_stream";
      args.robot_id = device;
      args.camera = "front";
    } else {
      act = "box.camera.start_stream";
      args.camera_id = device;
    }

    const requestID = uuid();

    this.props.getHLSURL(device, {
      id: requestID,
      act: act,
      arg: args
    }, {
        hasRes: true,
        device
      })
  }

  refreshCamera = (refreshDelay) => {
    this.loadCamera(this.props.camera.id, this.props.camera.type, true);
    this.setState({
      refreshDelay: refreshDelay < 12800 ? refreshDelay * 2 : 12800
    });
  }

  componentDidMount() {
    //No need to reload If the camera already has a valid streaming url
    const hlsURL = this.props.hlsCache[this.props.camera.id];
    if (hlsURL && hlsURL.hls) {
      return;
    }
    this.loadCamera(this.props.camera.id, this.props.camera.type, true);
  }

  render() {
    const { camera, hlsCache, cameraHeight, role } = this.props;
    const hlsURL = hlsCache[camera.id];
    let streamLoading = hlsURL ? hlsURL.loading : getText('preparing');
    let streamURL = hlsURL && hlsURL.hls;
    let streamError = hlsURL && hlsURL.err;
    let id = hlsURL && hlsURL.id;

    return (
      <PlayerCard
        player={
          <HlsPlayer
            streamURL={streamURL}
            streamLoading={streamLoading}
            streamError={streamError}
            streamTitle={camera.name}
            id={id}
            minHeight={cameraHeight}
            refreshCamera={this.refreshCamera}
            refreshDelay={this.state.refreshDelay}
          />
        }
        camera={camera}
        showHistory={['monitoring_operator', 'customer', 'site_manager'].includes(role)}
        minHeight={cameraHeight}
        refresh={() => this.refreshCamera(400)}
      />
    );
  }
}

CameraPlayerContainer.propTypes = {
  camera: PropTypes.object,
};

CameraPlayerContainer.defaultProps = {
  camera: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CameraPlayerContainer);
