/* eslint-disable @typescript-eslint/naming-convention */

export const SET_SITES = 'SET_SITES';
export const SET_SIMPLE_SITES = 'SET_SIMPLE_SITES';
export const SET_REALTIME_SITES_LOADING = 'SET_REALTIME_SITES_LOADING';
export const SET_REALTIME_SITES_FILTER = 'SET_REALTIME_SITES_FILTER';
export const SET_REALTIME_SITES = 'SET_REALTIME_SITES';
export const SET_SITE_OPTIONS = 'SET_SIMPLE_SITES';

export const SET_SITES_WITH_CAMERAS_LOADING = 'SITE_SET_SITES_WITH_CAMERAS_LOADING';
export const SET_SITES_WITH_CAMERAS_FILTER = 'SITE_SET_SITES_WITH_CAMERAS_FILTER';
export const SET_SITES_WITH_CAMERAS = 'SITE_SET_SITES_WITH_CAMERAS';
