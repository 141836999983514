/* eslint-disable @typescript-eslint/naming-convention */
import React, {useMemo, useRef, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {
  Grid,
  Button,
  TextField,
  IconButton,
  Divider,
  InputAdornment,
  Chip,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Person as PersonIcon,
  Flag as FlagIcon,
} from '@material-ui/icons';

import { ProSelect, DateRange} from 'turing-ui';
import moment from 'moment';

import {getClips, setClipsFaceSelect} from 'src/redux/actions/Vision/clip';
import {isFacialRecognition, isLPR} from 'src/redux/actions/Vision/eventType';
// import EditTrackingTargetModal from 'src/pages/employees/EditModal';
import useStyles from './index.style';
// import {VehicleTypeFilterComponent} from './VehicleTypeFilter';
import FacialSelect from './FacialSelect';
// import {FacialSelectComponent} from 'src/pages/recordings/detail/facialFilter';
import CroppedImage from 'src/components/Vision/CroppedImage';
import {HorizontalScrollingComponent} from './HorizontalScrolling';
import {CancelIconComponent} from 'src/components/Vision/CancelIcon';
// import {getIsPeopleSearchEnabled} from 'src/utils/featureFlag';
import {defaultFilter} from 'src/redux/reducers/Vision/clip';
import {PeopleSearchComponent} from './PeopleSearch';
import {ReactComponent as SearchFaceIcon} from 'src/assets/Vision/search_face.svg';
// import {ReactComponent as ContactPage} from 'src/assets/contact_page.svg';

import renderCameraOption from './CameraOption';
// import renderOrganizationOption from './OrganizationOption';
import VehicleFilterLine from './VehicleFilterLine';

import {SET_VISION_ORG} from 'src/redux/types/Vision/orgvision';
import {getOrgs} from 'src/redux/actions/Vision/orgvision';
import store from 'src/redux/store';
import { setClipsFilter } from '../../../../redux/actions/Vision/clip';

const {dispatch, getState} = store;

// const tabOptions = [
//   {label: 'All', value: 'event'},
//   {label: 'Alerts', value: 'case'},
// ];
// const role = getState().user.role;

const Filter = ({type}) => {
  const classes = useStyles();
  const siteOptions = useSelector((state) => state.sitevision.siteOptions);
  const trackingSettings = useSelector((state) => state.trackingSetting.trackingSettings);
  const OrgsOptions = useSelector((state) => state.orgvision.org);
  const minCamerasMapping = useSelector((state) => state.cameravision.minCamerasMapping);
  const {filter, showFaceSelect} = useSelector((state) => state.clip);
  const [state, setState] = useState(filter);
  const timeContainerRef = useRef(null);
  const {role} = useSelector((state) => state.user);
  // const [editTrackingTarget, setEditTrackingTarget] = useState(null);
  const [isSubmitLPR, setIsSubmitLPR] = useState(false);
  // const intl = useIntl();

  const resetFilter = useMemo(() => {
    const tmp = JSON.parse(JSON.stringify(defaultFilter));
    if (trackingSettings && role !== 'monitoring_operator'){
      tmp.org = trackingSettings.organization;
    }
    return tmp
  }, [trackingSettings]);

  useEffect(() => {
    if (type) {
      let newState = {
        ...state,
        api_type: 'event',
        face: type && type === filter.type && isFacialRecognition(type) ? filter.facse : null,
        type,
        // type && type !== filter.type && type.includes(filter.type as string) ? filter.type : type,
        lpr: type === filter.type ? filter.lpr : '',
      };
      if (role !== 'monitoring_operator') {
        newState.org = trackingSettings.organization;
      }

      if (filter.lpr && type === filter.type) {
        setIsSubmitLPR(true);
      } else {
        setIsSubmitLPR(false);
      }
      if (!isLPR(newState.type)) {
        newState.vehicle_color = '';
        newState.vehicle_type = '';
        newState.vehicle_make = '';
      }
      setState(newState);
      getClips({...newState, page: 0}, true);
    }
  }, [type]);

  useEffect(() => {
    const newState = {...filter};
    setState(newState);
  }, [filter]);

  const validOrgsOptions = useMemo(() => {
    const options = [];
    return options.concat(
      OrgsOptions.map(v => ({
        value: v.id,
        label: v.name,
      }))
    );
  }, [OrgsOptions]);

  const validSiteOptions = useMemo(() => {
    if (state.org){
      const orgIDs = state.org ? [state.org] : OrgsOptions.map(v => v.id);

      let orgsposi = {}
      OrgsOptions.map((currElement, index) => {orgsposi[currElement.id] = index});

      const validposi = orgIDs.map(p => {return OrgsOptions[orgsposi[p]].sites}).flat()

      let siteposi = {}
      siteOptions.map((currElement, index) => {siteposi[currElement.id] = index});

      const ans = siteOptions.length > 0 ? validposi.map(currElement => {return {
        value: siteOptions[siteposi[currElement]].id,
        label: siteOptions[siteposi[currElement]].name,
      }}) : [];
      return ans;
    }else{
      return []
    }
  }, [state.org, siteOptions, validOrgsOptions]);

  const validCameraOptions = useMemo(() => {
    if (state.org){
      let siteIds = state.site ? [state.site] : (state.org ? validSiteOptions.map(v => v.value) : siteOptions.map(v => v.id));
      const options = [];
      const ans = options.concat(
        siteIds
          .reduce((ret, siteId) => {return ret.concat(minCamerasMapping[siteId] || [])}, [])
          .map(v => ({
            value: v.id,
            label: v.name,
            tooltip: !v.isTuring
              ? "thrid party camera"
              : '',
          }))
          .sort(function(a, b) {
            return !a.tooltip && b.tooltip ? -1 : 1;
          })
      );
      return ans;
    }else{
      return []
    }
  }, [state.site, siteOptions, minCamerasMapping, state.org,]);

  const handleChange = (key) => (e) => {
    const value = e && e.target ? e.target.value : e;
    let newState = {
      ...state,
      [key]: value,
    };
    if (key === 'org'){
      if (!value){
        newState.site = '';
        newState.camera = '';
      }
    }
    if (key === 'site') {
      const index = value
        ? minCamerasMapping[value].findIndex(camera => camera.id === state.camera)
        : -1;
      if (index === -1) {
        // clean previous camera selection if new site does not contain it.
        newState.camera = '';
      }
    }
    if (!isLPR(newState.type)) {
      newState.vehicle_color = '';
      newState.vehicle_type = '';
      newState.vehicle_make = '';
    }
    if (key === 'lpr' && value === '' && isSubmitLPR) {
      setIsSubmitLPR(false);
    }
    setState(newState);
    if (key !== 'lpr') {
      getClips({...newState, page: 0}, true);
    }
  };

  function handleClearLpr() {
    const newState = {
      ...state,
      lpr: '',
    };
    setState(newState);
    setIsSubmitLPR(false);
    getClips({...newState, page: 0}, true);
  }

  const handleChangeTime = (data) => {
    const newState = {
      ...state,
      range: data,
    };
    setState(newState);
    getClips({...newState, page: 0}, true);
  };

  const handleSearch = () => {
    getClips({...state, page: 0}, true);
  };

  const is_facial_recognition = isFacialRecognition(state.type);
  const is_lpr = isLPR(state.type);
  if (!state.type) {
    return null;
  }

  function handleClear() {
    const newState = Object.assign({}, resetFilter, {type});
    setState(newState);
    setIsSubmitLPR(false);
    getClips({...newState, page: 0}, true);
  }

  function handleDropdown({type, color, make}) {
    const newState = {...state, vehicle_color: color, vehicle_type: type, vehicle_make: make};
    setState(newState);
    getClips({...newState, page: 0}, true);
  }

  function handleSelectFace(face) {
    setClipsFaceSelect(false);
    const newState = {...state, face};
    setState(newState);
    getClips({...newState, page: 0}, true);
  }

  function handleClearFace() {
    const newState = {...state, face: null};
    setState(newState);
    getClips({...newState, page: 0}, true);
  }

  // function handleCloseEditTrackingTarget(changed, target) {
  //   setEditTrackingTarget(null);
  //   if (changed && target) {
  //     const newState = {...state, face: target};
  //     setState(newState);
  //     getClips({...newState, page: 0}, true);
  //   }
  // }

  const keyEnter = (event) => {
    const theEvent = event || window.event;
    const code = theEvent.keyCode || theEvent.which || theEvent.charCode;
    if (code === 13) {
      if (state.lpr) {
        setIsSubmitLPR(true);
        handleSearch();
      }
    }
  };
  return (
    <div className={classes.root}>
      {state.org !== getState().trackingSetting.trackingSettings.organization && (<>
        <Grid container direction="row" spacing={0}  wrap="nowrap">
        <p style={{fontSize: '24pt'}}>Current Organization is: {state.org || '(Not selected)'}</p>
        </Grid>
        <Grid container direction="row" spacing={1} style={{margin: '0px 0 20px 0px'}} wrap="nowrap">
          <HorizontalScrollingComponent spacing={2}>
            <Grid item>
              <ProSelect
                preLabel="Organization"
                options={validOrgsOptions}
                value={state.org || ''}
                onChange={(handleChange('org'))}
              />
            </Grid>
            {!state.org &&(<Grid item>
              <p>Select organization to view accessible sites and cameras</p>
            </Grid>)}
          </HorizontalScrollingComponent>
        </Grid>
      </>)}
      
      
      {state.org && (<div>
        <Grid container direction="row" spacing={1} style={{margin: '8px 0 8px 0px'}} wrap="nowrap">
          <HorizontalScrollingComponent spacing={2}>
          {is_facial_recognition && !state.face && (
              <>
                <Grid item style={{width: 270}}>
                  <PeopleSearchComponent onSelect={handleChange('face')} defaultValue={state.face} />
                </Grid>
                {/* <Grid item>
                  <Chip
                    onClick={() => setClipsFaceSelect(true)}
                    onDelete={() => setClipsFaceSelect(true)}
                    style={{backgroundColor: '#EEE'}}
                    deleteIcon={<SearchFaceIcon />}
                    label="Search by Photo"
                  ></Chip>
                </Grid> */}
                <Divider orientation="vertical" variant="middle" style={{height: 40}} />
              </>
            )}
            {is_facial_recognition && state.face && state.face.img_url && (
              <Grid item className={classes.faceContainer}>
                <CroppedImage target={state.face} className={classes.face} />
                {state.face.data.person_name && (
                  <div className={classes.employeeGroup}>
                    <PersonIcon className={`${classes.iconFont} ${classes.mr1}`} />
                    {state.face.data.person_name}
                  </div>
                )}
                {/* {(state.face.is_flag || state.face.is_contact) && (
                  <div className={classes.contact}>
                    {!state.face.is_flag && state.face.is_contact && (
                      <ContactPage className={classes.contactIcon} />
                    )}
                    {state.face.is_flag && <FlagIcon className={classes.flag} />}
                  </div>
                )} */}
                <div className={classes.clearFace} onClick={handleClearFace}>
                  <ClearIcon color="primary" />
                </div>
                {/* <div
                  className={classes.editFace}
                  onClick={() => {
                    setEditTrackingTarget(state.face);
                  }}
                >
                  {state.face.data.person_name ? (
                    <EditIcon color="primary" />
                  ) : (
                    <AddIcon color="primary" />
                  )}
                </div> */}
              </Grid>
            )}
            {is_lpr && (
              <>
                <Grid item style={{paddingRight: 0}}>
                  <div style={{width: 200}}>
                    <TextField
                      className={`${classes.input} ${isSubmitLPR ? classes.lprAction : ''}`}
                      placeholder="Search license plate number"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={state.lpr}
                      onChange={handleChange('lpr')}
                      onKeyUp={keyEnter}
                      InputProps={{
                        startAdornment: isSubmitLPR ? (
                          <InputAdornment position="start" className={classes.licenseStart}>
                            License:{' '}
                          </InputAdornment>
                        ) : null,
                        endAdornment: state.lpr ? (
                          <IconButton
                            onClick={handleClearLpr}
                            size="small"
                            className={`${classes.endAdornment} ${
                              isSubmitLPR ? classes.actionBtn : ''
                            }`}
                            style={{marginRight: 8}}
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        ) : null,
                      }}
                    />
                  </div>
                </Grid>
                <Grid item style={{paddingLeft: 0}}>
                  <Button
                    size="small"
                    onClick={() => {
                      if (state.lpr) {
                        setIsSubmitLPR(true);
                        handleSearch();
                      }
                    }}
                    variant="contained"
                    color="primary"
                    className={classes.searchButtonLPR}
                  >
                    <SearchIcon />
                  </Button>
                </Grid>
                {/* <Grid item>
                  <VehicleTypeFilterComponent
                    color={state.vehicle_color || ''}
                    type={state.vehicle_type || ''}
                    make={state.vehicle_make || ''}
                    onChange={handleDropdown}
                  />
                </Grid> */}
                <Divider orientation="vertical" variant="middle" style={{height: 40}} />
                {/* <Grid item className={classes.switch}>
                  <SwitchButtons
                    value={state.api_type}
                    options={tabOptions}
                    onChange={value => handleChange('api_type')(value)}
                  />
                </Grid> */}
              </>
            )}
            
            <Grid item>
              <ProSelect
                preLabel="Site"
                options={validSiteOptions}
                value={state.site || ''}
                onChange={handleChange('site')}
              />
            </Grid>
            <Grid item>
              <ProSelect
                preLabel="Camera"
                options={validCameraOptions}
                value={state.camera || ''}
                onChange={handleChange('camera')}
                renderOption={renderCameraOption}
              />
            </Grid>
            <Grid item>
              <DateRange
                preLabel="Time"
                hasNoYear
                rangeValid={[moment().subtract(29, 'days'), moment()]}
                value={state.range}
                onChange={handleChangeTime}
              />
            </Grid>
          </HorizontalScrollingComponent>

          {(state.sitevision ||
            state.face ||
            state.camera ||
            (state.range && state.range.range && state.range.range.join(',') !== ',') ||
            state.vehicle_color ||
            state.vehicle_type ||
            state.lpr ||
            state.vehicle_make) && <CancelIconComponent onClick={handleClear} />}
        </Grid>
        <div ref={timeContainerRef} />
        {showFaceSelect && (
          <FacialSelect
            cameraId={state.camera}
            onSelect={handleSelectFace}
            tab={'upload'}
            onClose={() => setClipsFaceSelect(false)}
          />
        )}
        {/* {getIsPeopleSearchEnabled() && is_facial_recognition && !state.face && (
          <FacialSelectComponent
            range={filter.range.range}
            cameraId={state.camera}
            onSelect={handleSelectFace}
          />
        )} */}
        {/* {editTrackingTarget && (
          <EditTrackingTargetModal
            employee={editTrackingTarget}
            onClose={handleCloseEditTrackingTarget}
          />
        )} */}
        {is_lpr && (
          <VehicleFilterLine
            color={state.vehicle_color || ''}
            type={state.vehicle_type || ''}
            make={state.vehicle_make || ''}
            onChange={handleDropdown}
          />
        )}
      </div>)}
    </div>
  );
};

export default Filter;
