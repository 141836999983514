import React from 'react';
import {Grid, makeStyles} from '@material-ui/core';

// import {getIsPeopleSearchEnabled} from 'src/utils/featureFlag';
// import {ViewInCameraComponent} from './viewInCamera';
import {TrackingObjectsComponent} from './trackingObject';
import {VehicleInfoComponent} from './vehicleInfo';
import {DownloadVideoComponent} from './download';
import {ZoomComponent} from './zoom';
// import {ArchiveButton} from './archive';
import {useDetailContext, useIsPeople} from '../../../hook';
// import {SharingButton} from './sharing';

const useStyles = makeStyles(theme => ({
  extra: {
    marginTop: theme.spacing(2),
  },
}));

export function DetailAnalyzeComponent() {
  const classes = useStyles();
  const isPeople = useIsPeople();
  const isPeopleSearchEnabled = true;
  const {isSharing} = false;

  return (
    <Grid container classes={{container: classes.extra}}>
      <Grid container item alignItems="center">
        <ZoomComponent />
        <DownloadVideoComponent />
        {/* {!isSharing && <SharingButton />}
        {!isSharing && <ArchiveButton />} */}
        {/* {!isSharing && <ViewInCameraComponent />} */}
      </Grid>
      {isPeople ? (
        (isPeopleSearchEnabled || isSharing) && <TrackingObjectsComponent />
      ) : (
        <VehicleInfoComponent />
      )}
    </Grid>
  );
}
