import React from "react";
import { connect } from "react-redux";

import RobotDetail from "./RobotDetail";
import { getText } from "src/utils/MultilingualLoader";

const mapStateToProps = (state, props) => {
  const { selected } = props;
  const { robotCurMaps } = state.robot;
  const configMap = selected && robotCurMaps[selected.id];
  return {
    robot: selected,
    configMap,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

function getFloorName(floorId) {
  return Boolean(floorId) ? (floorId > 0 ? floorId + "F" : "B" + -floorId) : "";
}

class RobotDetailContainer extends React.Component {
  render() {
    const { selected, configMap } = this.props;
    if (!selected) return null;

    let mode = "unknown";
    let power_level = 25;
    let status = selected.status;
    if (selected.state_data) {
      if (status !== "offline") status = selected.state_data.status || status;
      mode = selected.state_data.mode || mode;
      power_level = selected.state_data.power_percentage || power_level;
      if (!isNaN(power_level)) {
        power_level = power_level + "%";
      }
    }
    let details = [
      {
        title: getText("robot_name"),
        content: selected.name,
      },
      {
        title: getText("robot_id"),
        content: selected.id,
      },
      {
        title: getText("status"),
        content: getText(status),
      },
      {
        title: getText("mode"),
        content: mode !== "unknown" ? getText(mode) : getText("unknown"),
      },
      {
        title: getText("power_level"),
        content: power_level,
      },
      {
        title: getText("signal_strength"),
        content: Number.isFinite(selected.signalStrength)
          ? `${selected.signalStrength}%`
          : "-",
      },
      {
        title: getText("map"),
        content: configMap && configMap.name,
      },
      {
        title: getText("floor"),
        content: configMap && getFloorName(configMap.floor),
      },
      {
        title: getText("route_name"),
        content:
          (configMap && configMap.mapRoute && configMap.mapRoute.name) || "-",
      },
    ];
    return <RobotDetail details={details} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RobotDetailContainer);
