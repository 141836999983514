import React from 'react';
import { Text, Circle } from 'react-konva';

export const waypointRadius = 5;

const Waypoints = props => {
  const { getImagePosition, waypoints, selectedWaypoint, onClick } = props;
  let points = [];
  Object.keys(waypoints).forEach(waypointId => {
    const inspection = waypoints[waypointId] ? waypoints[waypointId][0] : null;
    if (inspection && inspection.way_point) {
      const position = getImagePosition(inspection.way_point.x, inspection.way_point.y)
      points.push(
        <React.Fragment key={waypointId}>
          <Text
            x={position.x - 10}
            y={position.y - 20}
            text={inspection.way_point.name}
            fill="blue"
          />
          <Circle
            x={position.x}
            y={position.y}
            radius={waypointRadius}
            fill={selectedWaypoint && waypointId === selectedWaypoint.id ? 'red' : 'green'}
            onClick={onClick(waypointId, waypoints[waypointId])}
          />
        </React.Fragment>
      )
    }
  })

  return (
    <>{points}</>
  );
};

export default Waypoints;


