import moment from 'moment';

import EnglishText from 'src/utils/locale/english.json';
import ChineseText from 'src/utils/locale/chinese.json';

let dictionary = {};
switch (window.navigator.language) {
  case 'zh': case 'zh-CN':
    dictionary = ChineseText;
    break;
  default:
    dictionary = EnglishText;
    break;
}

moment.updateLocale('zh-cn', {
  months : '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_'),
  monthsShort : '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
  weekdays : '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
  weekdaysShort : '周日_周一_周二_周三_周四_周五_周六'.split('_'),
  weekdaysMin : '日_一_二_三_四_五_六'.split('_'),
  longDateFormat : {
      LT : 'HH:mm',
      LTS : 'HH:mm:ss',
      L : 'YYYY/MM/DD',
      LL : 'YYYY年M月D日',
      LLL : 'YYYY年M月D日Ah点mm分',
      LLLL : 'YYYY年M月D日ddddAh点mm分',
      l : 'YYYY/M/D',
      // ll : 'YYYY年M月D日',
      ll: 'M月D日',
      // lll : 'YYYY年M月D日 HH:mm',
      lll: 'M月D日 HH:mm',
      // llll : 'YYYY年M月D日dddd HH:mm',
      llll: 'M月D日 HH:mm:ss'
  },
  meridiemParse: /凌晨|早上|上午|中午|下午|晚上/,
  meridiemHour: function (hour, meridiem) {
      if (hour === 12) {
          hour = 0;
      }
      if (meridiem === '凌晨' || meridiem === '早上' ||
              meridiem === '上午') {
          return hour;
      } else if (meridiem === '下午' || meridiem === '晚上') {
          return hour + 12;
      } else {
          // '中午'
          return hour >= 11 ? hour : hour + 12;
      }
  },
  meridiem : function (hour, minute, isLower) {
      var hm = hour * 100 + minute;
      if (hm < 600) {
          return '凌晨';
      } else if (hm < 900) {
          return '早上';
      } else if (hm < 1130) {
          return '上午';
      } else if (hm < 1230) {
          return '中午';
      } else if (hm < 1800) {
          return '下午';
      } else {
          return '晚上';
      }
  },
  calendar : {
      sameDay : '[今天]LT',
      nextDay : '[明天]LT',
      nextWeek : '[下]ddddLT',
      lastDay : '[昨天]LT',
      lastWeek : '[上]ddddLT',
      sameElse : 'L'
  },
  dayOfMonthOrdinalParse: /\d{1,2}(日|月|周)/,
  ordinal : function (number, period) {
      switch (period) {
          case 'd':
          case 'D':
          case 'DD':
          case 'DDD':
              return number + '日';
          case 'M':
              return number + '月';
          case 'w':
          case 'W':
              return number + '周';
          default:
              return number;
      }
  },
  relativeTime : {
      future : '%s内',
      past : '%s前',
      s : '几秒',
      ss : '%d 秒',
      m : '1 分钟',
      mm : '%d 分钟',
      h : '1 小时',
      hh : '%d 小时',
      d : '1 天',
      dd : '%d 天',
      M : '1 个月',
      MM : '%d 个月',
      y : '1 年',
      yy : '%d 年'
  },
  week : {
      // GB/T 7408-1994《数据元和交换格式·信息交换·日期和时间表示法》与ISO 8601:1988等效
      dow : 1, // Monday is the first day of the week.
      doy : 4  // The week that contains Jan 4th is the first week of the year.
  }
});

moment.updateLocale('en', {
  longDateFormat : {
    ll: 'MMM DD',
    lll: 'MMM DD, h:mm a',
    llll: 'MMM DD HH:mm:ss'
  }
});

export const getText = key => {
  return dictionary[key] !== undefined ? dictionary[key] : key;
};

export const getTimeText = (time, format) => {
  switch (window.navigator.language) {
    case 'zh': case 'zh-CN':
      return time.locale('zh-cn').format(format);
    default:
      return time.locale('en').format(format);
  };
}

export const getEventContent = (types, source, name) => {
  switch (window.navigator.language) {
    case 'zh': case 'zh-CN':
      return `${getText(source)} ${name} ${getText('detected_by')}${types}`;
    default:
      return `${types} ${getText('detected_by')} ${getText(source)} ${name}`;
  };
}

export const getDateLocale = () => {
  switch (window.navigator.language) {
    case 'zh': case 'zh-CN':
      return 'zh-cn';
    default:
      return 'en';
  };
}

export const getDefaultMapCenter = () => {
  switch(process.env.REACT_APP_DOMAIN) {
    case '.turingvideo.com':
      return { lat: 37.0902, lng: -95.7129 };
    case '.turingvideo.cn':
      return { lat: 35.8617, lng: 104.1954 };
    default:
      return { lat: 37.0902, lng: -95.7129 };
  }
}

export const getLangName = lang => {
  switch(lang) {
    case 'en':
      return getText('english');
    case 'zh-hans':
      return getText('chinese_simplified');
    default:
      return lang;
  }
}