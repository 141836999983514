 import React from 'react';
 import { connect } from 'react-redux';

import EventCameraPlayerContainer from './EventCameraPlayerContainer';
import WebRTCContainer from 'src/pages/Main/Player/WebRTCPlayer/WebRTCContainer';
import { webRTCWhiteList } from 'src/utils/webRTCWhiteList';

const mapStateToProps = state => {
  const { selected } = state.case;
  return { selected };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

class EventCamera extends React.Component {
  constructor(props){
    super(props);
    const height = (window.innerWidth - 200 - 350 - 16 - 24) / 2 * 9 / 16;
    this.state = {
      needDownGrade: false,
      eventCameraHeight: window.innerWidth < 1280 ? height * 2 : height
    }
  }

  componentDidMount(){
    window.addEventListener('resize', this.resizeVideo);
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.resizeVideo);
  }

  resizeVideo = () => {
    const height = (window.innerWidth - 200 - 350 - 16 - 24) / 2 * 9 / 16;
    this.setState({
      eventCameraHeight: window.innerWidth < 1280 ? height * 2 : height
    });
  }

  downGrade = () => {
    this.setState({
      needDownGrade: true,
    });
  }

  render() {
    const { selected } = this.props;
    const { eventCameraHeight } = this.state;
    
    if (selected.event && selected.event.source === 'robot' && selected.event.robot 
      && !this.state.needDownGrade && webRTCWhiteList 
      && (webRTCWhiteList.length === 0 || webRTCWhiteList.includes(selected.event.robot.id))) return (
        <WebRTCContainer
          camera={selected.event.robot}
          cameraHeight={eventCameraHeight} 
          downGrade={this.downGrade}
        />
    );
    else return (
      <EventCameraPlayerContainer selected={selected} eventCameraHeight={eventCameraHeight} />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventCamera);
