const styles = theme => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    margin: '12px 0'
  },
  form: {
    width: '100%',
  },
  tableRowTop: {
    verticalAlign: 'top'
  },
  tableCell: {
    borderStyle: 'none',
    padding: '10px 16px',
  },
  label: {
    lineHeight: 2.2,
  },
  submitButtons: {
    marginTop: theme.spacing(2),
    textAlign: 'right'
  },
  button: {
    margin: 6
  },
  buttonText: {
    color: 'white',
    textTransform: 'initial'
  },

  selectorInput: {
    width: '140px',
    padding: '5px 20px 5px 20px',
  },

  middle: {
    verticalAlign: 'middle',
  },
  IconButton: {
    padding: 5,
    color: theme.palette.primary.main,
    verticalAlign: 'middle',
  },
  radioGroup: {
    flexDirection: 'row'
  },
  timeInput: {
    width: '80px',
    padding: '5px 20px 5px 20px',
  },
  helpText: {
    color: '#f44336'
  },
  selected: {
    backgroundColor: 'rgba(76,127,114,0.25) !important'
  }
});

export default styles;