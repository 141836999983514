import { mapConstants } from '../constants';

const initState = {
  robots: [],
  selectedRobotId: null,
  maps: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case mapConstants.GET_ROBOT_SUCCESS:
      return {
        ...state,
        robots: action.robots
      };
    case mapConstants.SELECT_ROBOT_ID:
      return {
        ...state,
        selectedRobotId: action.robotId
      };
    case mapConstants.GET_MAPS_REQUEST:
      return {
        ...state,
        maps: {
          ...state.maps,
          [action.robotId]: {
            loading: true
          }
        }
      }
    case mapConstants.GET_MAPS_SUCCESS:
      let maps = (action.maps && action.maps.sort(sortByMapIdTime)) || [];
      return {
        ...state,
        maps: {
          ...state.maps,
          [action.robotId]: {
            loading: false,
            maps
          }
        }
      }
    case mapConstants.GET_MAPS_FAILURE:
      return {
        ...state,
        maps: {
          ...state.maps,
          [action.robotId]: {
            loading: false
          }
        }
      }
    default:
      return state;
  }
}

const sortByMapIdTime = (a, b) => {
  if (!a.id) {
    return 1;
  }
  else if (!b.id) {
    return -1;
  }
  // get unix timestamp from mapId
  const timeA = a.id.slice(-13);
  const timeB = b.id.slice(-13);
  return timeA > timeB ? -1 : 1;
}