import React, {useState} from 'react';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';

import {ButtonComponent} from './button';
import LPRModal from '../../lprModal';
import {useDetailContext, useIsPeople} from '../../../hook';

export function ZoomComponent(){
  const {detail} = useDetailContext();
  const [open, setOpen] = useState(false);
  const isPeople = useIsPeople();

  if (!detail.snapshotUrl || isPeople) return null;

  return (
    <>
      <ButtonComponent title="View snapshot" onClick={() => setOpen(true)}>
        <ImageSearchIcon />
      </ButtonComponent>
      {open && <LPRModal setOpen={setOpen} />}
    </>
  );
}
