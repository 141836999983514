import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Switch, Typography } from '@material-ui/core';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  switchLabel: {
    display: 'inline',
    color: 'gray'
  },
  switch: {
    top: 6
  }
});

const InspectionSwitch = props => {
  const { classes, showType, onSwitch } = props;

  return (
    <Grid 
      container={true} 
      direction="row"
      alignItems="flex-end" 
    >
      <Typography variant='body1' className={classes.switchLabel}>{getText('list')}</Typography>
      <Switch
        className={classes.switch}
        checked={showType === 'map'}
        onChange={onSwitch}
        value={showType}
        color="default"
      />
      <Typography variant='body1' className={classes.switchLabel}>{getText('map')}</Typography>
    </Grid>
  );
}

export default withStyles(styles)(InspectionSwitch);
