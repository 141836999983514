import React from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
} from "@material-ui/core";

import { robotActions } from "src/redux/actions";
import { getText } from "src/utils/MultilingualLoader";
import DropDownSelectorHorizontal from "src/components/Selectors/DropDownSelectorHorizontal";
import styles from "./styles";

const mapStateToProps = (state, props) => {
  const { robotMapLists } = state.robot;
  const { selected } = props;
  const robotMapList = selected && robotMapLists[selected.id];
  return { robotMapLists, robotMapList };
};

const mapDispatchToProps = {
  updateRobotConfigs: (robot_id, newConfigs) =>
    robotActions.updateRobotConfigs(robot_id, newConfigs),
  getRobotMapList: (robotId) =>
    robotActions.getRobotConfigMapsAndRoutes(robotId),
};

class RobotHomeMapContainer extends React.Component {
  constructor(props) {
    super(props);
    const { configs } = props;
    this.state = {
      selectedMapId:
        configs &&
        configs.home_map_route_ids &&
        configs.home_map_route_ids.map_id,
      selectedRouteId:
        configs &&
        configs.home_map_route_ids &&
        configs.home_map_route_ids.route_id,
    };
  }

  componentDidMount() {
    const { robotMapList, selected, getRobotMapList } = this.props;
    if ((!robotMapList || !robotMapList.loading) && selected && selected.id) {
      // getRobotMapList(selected.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.configs && this.props.configs.home_map_route_ids) {
      if (
        !prevProps.configs ||
        !prevProps.configs.home_map_route_ids ||
        prevProps.configs.home_map_route_ids.map_id !==
          this.props.configs.home_map_route_ids.map_id ||
        prevProps.configs.home_map_route_ids.route_id !==
          this.props.configs.home_map_route_ids.route_id
      ) {
        this.setState({
          selectedMapId: this.props.configs.home_map_route_ids.map_id,
          selectedRouteId: this.props.configs.home_map_route_ids.route_id,
        });
      }
    }
  }

  handleChange = (prop) => (e) => {
    if (prop === "selectedMapId") {
      this.setState({
        [prop]: e.target.value,
        selectedRouteId: "",
      });
    } else {
      this.setState({
        [prop]: e.target.value,
      });
    }
  };

  handleConfirm = () => {
    const { updateRobotConfigs, selected } = this.props;
    if (selected && selected.id) {
      const { selectedRouteId, selectedMapId } = this.state;
      updateRobotConfigs(selected.id, {
        home_map_route_ids: {
          map_id: selectedMapId,
          route_id: selectedRouteId,
        },
      });
    }
  };

  render() {
    const { classes, robotMapList } = this.props;
    const { selectedMapId, selectedRouteId } = this.state;

    const selectedMap =
      selectedMapId &&
      robotMapList &&
      robotMapList.maps &&
      robotMapList.maps.find((map) => map.id === selectedMapId);
    const routes = selectedMap && selectedMap.mapRoutes;

    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableCell} padding="default">
              <Typography variant="body2">{getText("map_id")}</Typography>
            </TableCell>
            <TableCell className={classes.tableCell} padding="default">
              <DropDownSelectorHorizontal
                title={""}
                options={
                  (robotMapList &&
                    robotMapList.maps &&
                    robotMapList.maps.map((map) => ({
                      label: `${map.name} ${map.id}`,
                      value: map.id,
                    }))) ||
                  []
                }
                value={selectedMapId || ""}
                onChange={this.handleChange("selectedMapId")}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell} padding="default">
              <Typography variant="body2">{getText("route_id")}</Typography>
            </TableCell>
            <TableCell className={classes.tableCell} padding="default">
              <DropDownSelectorHorizontal
                title={""}
                options={
                  (routes &&
                    routes.map((route) => ({
                      label: `${route.name} ${route.id}`,
                      value: route.id,
                    }))) ||
                  []
                }
                value={selectedRouteId || ""}
                onChange={this.handleChange("selectedRouteId")}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              className={classes.tableCell}
              padding="default"
            ></TableCell>
            <TableCell className={classes.tableCell} padding="default">
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handleConfirm}
              >
                <Typography variant="body2" className={classes.buttonText}>
                  {getText("ok")}
                </Typography>
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RobotHomeMapContainer));
