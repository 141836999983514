import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

import SensorPanel from './SensorPanel';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  const { selectedRobot } = state.inspection;
  return { selectedRobot };
};

const mapDispatchToProps = dispatch => {
  return { };
};

class SensorPanelContainer extends React.Component {
  render() {
    const { selectedRobot } = this.props;

    return (
        <>{
          selectedRobot ? 
            <SensorPanel selectedRobot={selectedRobot} /> 
            : <Typography>{getText('no_robot_selected')}</Typography>
        }</>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SensorPanelContainer);
