import moment from 'moment';
import { GeneralAdapter } from './adapter';
import { api } from './api';

const taskDataAdapter = {
  toServer: data => {
    return data;
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    const {results, count} = result;
    return { 
      count,
      list: results.map(item => ({
        id: item.id,
        inspection_id: item.inspection && item.inspection.id,
        snapshot: item.inspection && item.inspection.mediums && item.inspection.mediums[0] && item.inspection.mediums[0].url,
        algo: item.algo_config.algo,
        status: item.review_status,
        target_id: item.target_id,
        result: item.moderation_result,
        moderation: item.moderation_types,
        event: item.inspection.algo_types,
        results: item.analysis_results ? Object.keys(item.analysis_results).map(key => ({
          algo: key,
          score: item.analysis_results[key],
        })) : [],
        end_time: item.inspection && item.inspection.ended_at && moment(new Date(item.inspection.ended_at)).format('YYYY-MM-DD HH:mm'),
      }))
    };
  }
}

const taskAdapter = {
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    return {
      id: result.id,
      name: result.inspection && result.inspection.target && result.inspection.target.name,
      status: result.review_status,
      results: result.analysis_results ? Object.keys(result.analysis_results).map(key => ({
        algo: key,
        score: result.analysis_results[key],
      })) : [],
    };
  }
}

const eventOptionsAdapter = {
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    return result.map(item => ({
      id: item.id,
      label: item.name,
      value: item.code_name
    }))
  }
}

function queryParams(filters) {
  const {limit, offset, name, reviewStatus, moderateStatus, orderStatus, startDate, endDate, site_id} = filters;
  const params = [];
  params.push(`limit=${limit}`);
  params.push(`offset=${offset}`);
  if (name && name.id) {
    params.push(`target_id=${name.id}`)
  }
  // Start time
  if (startDate) {
    const startTimeUTC = moment.utc(startDate).toISOString();
    params.push(`${encodeURIComponent('inspection__started_at__gte')}=${(startTimeUTC)}`);
  }
  
  // End time
  if (endDate) {
    const endTimeUTC = moment.utc(endDate).toISOString();
    params.push(`${encodeURIComponent('inspection__started_at__lt')}=${(endTimeUTC)}`);
  }
  if (site_id) {
    params.push(`target__site_id=${site_id}`)
  }
  if (reviewStatus && reviewStatus !== 'all') {
    params.push(`review_status=${reviewStatus}`);
  }
  if ((moderateStatus || moderateStatus === false) && moderateStatus !== 'all') {
    if (moderateStatus === 'null') {
      params.push(`moderation_result=${null}`);
    } else {
      params.push(`moderation_result=${moderateStatus}`);
    }
  }
  if (orderStatus) {
    params.push(`ordering=${orderStatus}`)
  }
  return params.join('&');
}

export const getTasks = async (filters) => {
  try {
    const params = queryParams(filters);
    const url = `/detection_task/detection_tasks?${params}`;
    const response = await api.get(url);
    return taskDataAdapter.toClient(response.data);
  } catch(e) {
    throw e;
  }
}

export const getTask = async (taskId) => {
  try {
    const url = `/detection_task/detection_tasks/${taskId}`;
    const response = await api.get(url);
    return taskAdapter.toClient(response.data);
  } catch(e) {
    throw e;
  }
}

export const review = async (taskId, algoType) => {
  try {
    const params = {
      moderation_result: true,
      moderation_types: algoType
    };
    const url = `/detection_task/detection_tasks/${taskId}/moderate`;
    const response = await api.post(url, params);
    return GeneralAdapter.toClient(response.data)
  } catch(e) {
    throw e;
  }
}

export const getEventOptions = async (siteId) => {
  try {
    const url = `/site/sites/${siteId}/event_types`;
    const response = await api.get(url);
    return eventOptionsAdapter.toClient(response.data);
  } catch(e) {
    throw e;
  }
}
