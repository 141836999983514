/* eslint-disable @typescript-eslint/camelcase */
import {SET_TRACKING_SETTINGS} from 'src/redux/types/Vision/trackingSetting';

const initialState = {
  trackingSettings: null,
};

export default function trackingSettingReducer(
  state = initialState,
  action,
) {
  const {type, payload} = action;
  switch (type) {
    case SET_TRACKING_SETTINGS:
      return {
        ...state,
        trackingSettings: payload.trackingSettings,
      };
    default:
      return state;
  }
}
