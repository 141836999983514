import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import EventMedia from './EventMedia';
import CaseInspectionMedia from './CaseInspectionMedia';
import EventCamera from './EventCamera';
import NearbyCameras from './NearbyCameras';
import Actions from './Actions';
import CaseContent from './CaseContent';

const styles = theme => ({
  root: {
  },
});

const CasePanel = props => {
  const { classes, role, selected } = props;
  return (
    <Grid
      className={classes.root}
      container={true}
      justify="flex-start"
      direction="row"
      spacing={3}
    >
      <Grid item={true} xs={12}>
        <Grid
          container={true}
          justify="flex-start"
          direction="row"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item={true} xs={12} lg={6}>
            {
              selected.event ? <EventMedia selected={selected} /> :
                selected.inspection ? <CaseInspectionMedia selected={selected} /> :
                  null
            }
          </Grid>
          <Grid item={true} xs={12} lg={6}>
            { (selected.event || selected.inspection) && <EventCamera />}
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12}>
        <NearbyCameras />
      </Grid>
      <Grid item={true} xs={12}>
        <Actions />
      </Grid>
      <Grid item={true} xs={12}>
        <CaseContent role={role}/>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CasePanel);
