import React from 'react';
// import {MinCameras} from 'src/models/camera';

// import {Case} from 'src/models/case';
// import {SimpleSites} from 'src/models/site';
// import {DetailType, DetailFrom, MoreDataLoader, EventTypeOption} from './definition';



export const DetailContext = React.createContext(
  null
);
