import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import RobotList from './RobotList';

const styles = theme => ({

});

const SidePanel = props => {
    return <RobotList />;
};

export default withStyles(styles)(SidePanel);
