import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Divider,
  Typography,
  Breadcrumbs,
  Link,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  Close as CloseIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from '@material-ui/icons';

// import {ClipFilter} from 'src/redux/types/clip';
import {useDetailContext} from '../../hook';
import {EllipseComponent} from '../ellipse';
import {drawLPRBox} from 'src/utils/Vision/boundingBox';

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    width: 'auto',
  },
  divider: {
    margin: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: '60%',
    background: theme.palette.action.disabledBackground,
  },
  pointer: {
    cursor: 'pointer',
    fontSize: '0.75rem',
  },
  disablePointer: {
    cursor: 'default !important',
    fontSize: '0.75rem',
  },
  typeText: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.primary.main,
    borderBottomStyle: 'solid',
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
  },
  time: {
    color: (theme.palette).other.primary,
    fontSize: '0.75rem',
  },
  back: {
    marginTop: 3,
  },
  disableLink: {
    textTransform: 'capitalize',
    padding: 0,

    '& .MuiTypography-body1': {
      fontSize: '0.75rem',
    },
  },
  vehicle: {
    fontSize: '1.25rem',
    color: (theme.palette).other.primary,
    fontWeight: 500,

    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  imgGroup: {
    width: 900,
    height: 600,
    margin: '0 auto',
    position: 'relative',
    overflow: 'hidden',
  },
  img: {
    width: '100%',
    height: '100%',
    cursor: 'move',
    position: 'absolute',
  },
  dialogTitle: {
    borderBottomWidth: 1,
    borderBottomColor: (theme.palette).other.border,
    borderBottomStyle: 'solid',
  },
  dialogContent: {
    paddingTop: 0,
  },
  actionBg: {
    position: 'absolute',
    display: 'inline-block',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    width: theme.spacing(5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    backgroundColor: (theme.palette).other.secondary,
  },
  actionBtn: {
    color: 'rgba(255, 255, 255)',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
  actionLine: {
    backgroundColor: 'rgba(255, 255, 255)',
  },
}));

const LPRModal= ({setOpen}) => {
  const classes = useStyles();
  return (
    <Dialog fullWidth maxWidth="md" scroll="body" open={true} onClose={() => setOpen(false)}>
      <DialogTitle className={classes.dialogTitle}>
        <Grid container justify="space-between" alignItems="center">
          <DetailNavigateInfoComponent setOpen={setOpen} />
          <IconButton aria-label="close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <VehicleSnapshotComponent />
      </DialogContent>
    </Dialog>
  );
};

const DetailNavigateInfoComponent = ({setOpen}) => {
  const classes = useStyles();
  return (
    <Grid item container alignItems="center" spacing={2} classes={{item: classes.flex}}>
      <BackNavigateComponent setOpen={setOpen} />
      <Grid item container classes={{item: classes.flex}}>
        <LicensePlatesComponent setOpen={setOpen} />
        <Grid item container classes={{item: classes.flex}}>
          <Divider orientation="vertical" flexItem className={classes.divider} />
        </Grid>
        <DetailRelatedComponent setOpen={setOpen} />
      </Grid>
    </Grid>
  );
};

const LicensePlatesComponent = ({setOpen}) => {
  const classes = useStyles();
  const {detail, isSharing} = useDetailContext();
  // const linkToEvent = useLinkToEvent();
  const licensePlates = ((detail.event.metadata ? detail.event.metadata.license_plates : null) || [])
    .map(vehicleInfo => vehicleInfo.text)
    .filter(licensePlate => !!licensePlate);
  if (!licensePlates.length) return null;
  return (
    <>
      <Grid item alignItems="center" container classes={{item: classes.flex}}>
        <Grid item>License:</Grid>
        <Breadcrumbs separator={' '}>
          {licensePlates.map(licensePlate => {
            return (
              <Tooltip
                key={licensePlate}
                arrow
                placement="bottom"
                title={isSharing ? '' : 'Search for license'}
              >
                <Link
                  // onClick={() => {
                  //   if (isSharing) return;
                  //   setOpen(false);
                  //   linkToEvent({lpr: licensePlate});
                  // }}
                  className={clsx(classes.typeText, classes.vehicle)}
                  key={licensePlate}
                >
                  {licensePlate}
                </Link>
              </Tooltip>
            );
          })}
        </Breadcrumbs>
      </Grid>
    </>
  );
};

const BackNavigateComponent = ({setOpen}) => {
  const classes = useStyles();
  const history = useHistory();
  const {isSharing} = useDetailContext();
  const back = () => {
    if (isSharing) return;
    setOpen(false);
    history.push(history.location.pathname.replace(/(.*)\/.*/, '$1')); // '/alert/...'=>'/alert'
  };
  return (
    <Grid
      item
      onClick={back}
      classes={{item: clsx(classes.pointer, classes.back, isSharing && classes.disablePointer)}}
    >
      <ArrowBackIcon />
    </Grid>
  );
};

const DetailRelatedComponent = ({setOpen}) => {
  const classes = useStyles();
  const {detail} = useDetailContext();
  const time = moment(detail.event.started_at);
  return (
    <Grid item container alignItems="center" classes={{item: classes.flex}}>
      <Breadcrumbs separator={<EllipseComponent />}>
        <Typography className={classes.time}>
          {moment().to(time) + ' at ' + time.format('HH:mm a')}
        </Typography>
        {(detail.event.camera ? detail.event.camera.name : null) && <CameraNavigateComponent setOpen={setOpen} />}
        {(detail.event.site ? detail.event.site.name :null) && <SiteNavigateComponent setOpen={setOpen} />}
      </Breadcrumbs>
    </Grid>
  );
};

const CameraNavigateComponent = ({setOpen}) => {
  const classes = useStyles();
  const {detail, cameras, isSharing} = useDetailContext();
  // const linkToEvent = useLinkToEvent();
  const disable = isSharing
    ? true
    : !detail.event.camera || (cameras ? cameras.every(camera => camera.id !== detail.event.camera.id) : null);

  return (
    <>
      {disable ? (
        <Tooltip arrow placement="bottom" title={isSharing ? '' : 'the camera no longer exists'}>
          <div>
            <Button disabled className={classes.disableLink}>
              <Typography>{detail.event.camera.name}</Typography>
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Tooltip arrow placement="bottom" title="Search for all events on this camera">
          <Link
            // onClick={() => {
            //   setOpen(false);
            //   linkToEvent({camera: detail.event.camera.id} );
            // }}
            className={classes.pointer}
          >
            {detail.event.camera.name}
          </Link>
        </Tooltip>
      )}
    </>
  );
};

const SiteNavigateComponent = ({setOpen}) => {
  const classes = useStyles();
  const {detail, sites, isSharing} = useDetailContext();
  // const linkToEvent = useLinkToEvent();
  const disable = isSharing
    ? true
    : !detail.event.site || (sites ? sites.every(site => site.id !== detail.event.site.id) : null);
  return (
    <>
      {disable ? (
        <Tooltip arrow placement="bottom" title={isSharing ? '' : 'the site no longer exists'}>
          <div>
            <Button disabled className={classes.disableLink}>
              <Typography>{detail.event.site.name}</Typography>
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Tooltip arrow placement="bottom" title="Search for all events at this site">
          <Link
            // onClick={() => {
            //   setOpen(false);
            //   linkToEvent({site: detail.event.site.id});
            // }}
            className={classes.pointer}
          >
            {detail.event.site.name}
          </Link>
        </Tooltip>
      )}
    </>
  );
};

const VehicleSnapshotComponent = () => {
  const classes = useStyles();
  const {detail} = useDetailContext();
  const [url, setUrl] = useState();
  const [size, setSize] = useState(1);
  const [pos, setPos] = useState({x: 0, y: 0});
  const licensePlates = ((detail.event.metadata ? detail.event.metadata.license_plates : null) || [])
    .map(vehicleInfo => {
      if (vehicleInfo.text) {
        return vehicleInfo;
      }
      return null;
    })
    .filter(licensePlate => !!licensePlate);
  useEffect(() => {
    if (detail.snapshotUrl) {
      drawLPRBox(detail.snapshotUrl, licensePlates)
        .then(url => {
          setUrl(url);
        })
        .catch(e => {
          console.log('Load Image failed!', e);
        });
    }
  }, []);
  const zoomin = () => {
    if (size < 3) setSize(size + 0.5);
  };
  const zoomout = () => {
    if (size > 1) setSize(size - 0.5);
    if (size - 0.5 === 1) setPos({x: 0, y: 0});
  };
  const moveImg = (event) => {
    event.preventDefault();
    if (size === 1) return;
    const disX = event.pageX - pos.x;
    const disY = event.pageY - pos.y;
    const parent = document.getElementById('imgGroup');
    if (parent) {
      parent.onmousemove = (ev) => {
        setPos({
          x: ev.pageX - disX,
          y: ev.pageY - disY,
        });
      };
      parent.onmouseup = () => {
        parent.onmousemove = null;
        parent.onmousedown = null;
      };
    }
  };
  return (
    <div id="imgGroup" className={classes.imgGroup}>
      <img
        className={classes.img}
        src={url}
        style={{transform: `scale(${size})`, left: pos.x, top: pos.y}}
        draggable
        onMouseDown={moveImg}
        alt=""
      />
      <Grid item container direction="column" className={classes.actionBg}>
        <IconButton className={classes.actionBtn} onClick={zoomin}>
          <AddIcon />
        </IconButton>
        <Divider orientation="horizontal" className={classes.actionLine} />
        <IconButton className={classes.actionBtn} onClick={zoomout}>
          <RemoveIcon />
        </IconButton>
      </Grid>
    </div>
  );
};

export default LPRModal;
