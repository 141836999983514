import { referenceConstants } from 'src/redux/constants';
import { referenceServices } from 'src/services';
import {notificationActions} from './index';
import { getText } from 'src/utils/MultilingualLoader';

export function getInspections(filters) {
  const request = () => {
    return {
      type: referenceConstants.GET_REFERENCE_REQUEST,
    };
  };
  
  const success = (result) => {
    return {
      type: referenceConstants.GET_REFERENCE_SUCCESS,
			list: result.list,
			count: result.count,
			curPage: 1,
    };
  };

  const failure = (result) => {
    return {
      type: referenceConstants.GET_REFERENCE_FAILURE,
    };
  };
  
  return (dispatch, getState) => {
		dispatch(request());
		const offset = 0;
    referenceServices.getInspections({...filters, offset})
      .then(result => {
        dispatch(success(result));
      })
      .catch(error => {
        dispatch(failure());
      });
  };
}

export const getMoreInspections = (filters, curPage) => {
	const request = () => {
		return {
			type: referenceConstants.GET_REFERENCE_REQUEST,
		};
	};

	const success = results => {
		return {
      type: referenceConstants.GET_REFERENCE_SUCCESS,
			list: results.list,
			count: results.count,
			curPage: curPage,
		};
	};

	const failure = () => {
		return {
			type: referenceConstants.GET_REFERENCE_FAILURE
		};
	};

	return (dispatch, getState) => {
		const offset = (curPage - 1) * filters.limit;

		dispatch(request());
		referenceServices.getInspections({...filters, offset})
			.then(results => {
				dispatch(success(results));
			})
			.catch(error => {
				dispatch(failure());
			});
	};
};

export const setFilter = filters => {
	return {
		type: referenceConstants.SET_FILTER,
		filters
	};
};

export const addAlgoReference = (params, cb) => {
	return (dispatch, getState) => {
		referenceServices.addAlgoReference(params, cb).then((res) => {
			if (res.err.ec === 0) {
				dispatch(notificationActions.addNotification(getText('operate_success')));
				cb(res.ret.id);
			}
		});
	};
}

export const updateAlgoReference = (params, id) => {
	return (dispatch, getState) => {
		referenceServices.updateAlgoReference(params, id).then((res) => {
			if (res.err.ec === 0) {
				dispatch(notificationActions.addNotification(getText('operate_success')));
			}
		});
	};
}

export const deleteAlgoReference = (id) => {
	return (dispatch, getState) => {
		referenceServices.deleteAlgoReference(id).then((res) => {
			if (res.err.ec === 0) {
				dispatch(notificationActions.addNotification(getText('operate_success')));
			}
		});
	};
}
