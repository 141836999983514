import React from "react";
import { connect } from "react-redux";
import { robotActions } from "src/redux/actions";
import RobotList from "./RobotList";

const mapStateToProps = (state) => {
  const { robots, loading, filters, selected, sortOrder, robotMapLists } =
    state.robot;
  const { curSite } = state.site;
  const robotMapList = selected && robotMapLists[selected.id];
  return {
    robots,
    loading,
    filters,
    selected,
    sortOrder,
    curSite,
    robotMapList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRobotMaps: (robotId) =>
      dispatch(robotActions.getRobotConfigMapsAndRoutes(robotId)),
    getRobots: (filters) => dispatch(robotActions.getRobots(filters)),
    selectRobot: (robot) => dispatch(robotActions.selectRobot(robot)),
    unselectRobot: () => dispatch(robotActions.selectRobot(null)),
    sortRobots: (sortColumn) => dispatch(robotActions.sortRobots(sortColumn)),
    setFilter: (filters) => dispatch(robotActions.setFilter(filters)),
  };
};

class RobotListContainer extends React.Component {
  componentDidMount() {
    const { loading, getRobots } = this.props;
    if (!loading) {
      getRobots();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSite,
      loading,
      getRobots,
      unselectRobot,
      selected,
      getRobotMaps,
      robotMapList,
    } = this.props;
    if (
      curSite &&
      (!prevProps.curSite || curSite !== prevProps.curSite) &&
      !loading
    ) {
      getRobots();
      unselectRobot();
    }

    if (
      !robotMapList &&
      selected &&
      selected.id &&
      (!prevProps.selected || prevProps.selected.id !== selected.id)
    ) {
      getRobotMaps(selected.id);
    }
  }

  componentWillUnmount() {
    this.props.unselectRobot();
  }

  handleRobotSelect = (robot) => (event) => {
    this.props.selectRobot(robot);
  };

  sortRobots = (sortColumn) => {
    this.props.sortRobots(sortColumn);
  };

  render() {
    let robots = this.props.robots || [];

    return (
      <RobotList
        selected={this.props.selected}
        robots={robots}
        handleRobotSelect={this.handleRobotSelect}
        sortOrder={this.props.sortOrder}
        sortRobots={this.sortRobots}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RobotListContainer);
