import React from 'react';

import { connect } from 'react-redux';
import { caseActions } from 'src/redux/actions';
import CasePanel from './CasePanel';
import { Typography } from '@material-ui/core';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  const { cases, selected, camerasLoading } = state.case;
  const { role } = state.user;
  return { cases, selected, role, camerasLoading };
};

const mapDispatchToProps = dispatch => {
  return {
    getCameras: () => {
      const filters = {
        robotStatuses: 'all',
        cameraStates: 'all'
      };
      dispatch(caseActions.getCameras(filters));
    },
  };
};

class CasePanelContainer extends React.Component {
  componentDidMount(){
    if (!this.props.camerasLoading) this.props.getCameras();
  };

  render() {
    const { cases, selected, role } = this.props;
    if (cases.length === 0) {
      return (<Typography variant='body1'>{getText('no_case_available')}</Typography>);
    }
    if (!selected){
      return (<Typography variant='body1'>{getText('no_case_selected')}</Typography>);
    }
    return (
      <CasePanel role={role} selected={selected} />
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CasePanelContainer);
