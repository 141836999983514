import React, {useState, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Grid, Typography, CircularProgress} from '@material-ui/core';
import {Modal, SwitchButtons} from 'turing-ui';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Person as PersonIcon, Flag as FlagIcon} from '@material-ui/icons';
import moment from 'moment';

// import {RootState} from 'src/redux/reducers';
import useStyles from './index.style';
import {getTrackingTargets} from 'src/services/Vision/employee';
// import {TrackingTarget} from 'src/models/employee';
// import Upload from './Upload';
import CroppedImage from 'src/components/Vision/CroppedImage';
import {ReactComponent as ContactPage} from 'src/assets/Vision/contact_page.svg';

const tabOptions = [
  {label: 'Select a Face', value: 'select'},
  {label: 'Upload a Photo', value: 'upload'},
];

const FacialSelect = ({
  cameraId,
  tab,
  onlyOne,
  noFilter,
  onSelect,
  onClose,
}) => {
  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [tabKey, setTabKey] = useState(tab || 'select');
  const [list, setList] = useState([]);
  const filter = useSelector((state) => state.employee.filter);
  const trackingSettings = useSelector(
    (state) => state.trackingSetting.trackingSettings
  );

  function listAdapter(array, list = []) {
    const map = new Map(list.map(item => [item.date, item]));
    array.forEach(item => {
      if (map.get(item.date)) {
        map.get(item.date).list.push(item);
      } else {
        map.set(item.date, {date: item.date, list: [item]});
      }
    });
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return Array.from(map, ([_, value]) => value);
  }

  function timeAdapter(dateTime) {
    if (!dateTime) return '';
    const now = moment();
    const m = moment(dateTime);
    if (now.diff(m, 'minutes') <= 59) {
      return `${now.diff(m, 'minutes')}m ago`;
    } else if (now.diff(m, 'hours') <= 2) {
      return `${now.diff(m, 'hours')}hr ago`;
    } else {
      return m.format('H:mm a');
    }
  }

  function getFacesByNoFilter(page) {
    getTrackingTargets({
      ...filter,
      page,
      limit: 50,
      cameraId: cameraId || undefined,
      identifiers: trackingSettings ? trackingSettings.identify_by : undefined,
      unnamed: undefined,
    }).then(res => {
      setTotal(res.count);
      setList(
        listAdapter(
          res.results.map((item) => ({
            ...item,
            date: item.latest_index_face_time
              ? moment(item.latest_index_face_time).format('dddd, MMMM D')
              : '',
            time: timeAdapter(item.latest_index_face_time),
          })),
          page === 1 ? [] : list
        )
      );
    });
  }

  function getFaces(page) {
    getTrackingTargets({
      page,
      limit: 50,
      ordering: '-latest_index_face_time',
      cameraId: cameraId || undefined,
      gt: !cameraId ? moment().subtract(29, 'days') : undefined,
    }).then(res => {
      setTotal(res.count);
      setList(
        listAdapter(
          res.results.map((item) => ({
            ...item,
            date: item.latest_index_face_time
              ? moment(item.latest_index_face_time).format('dddd, MMMM D')
              : '',
            time: timeAdapter(item.latest_index_face_time),
          })),
          page === 1 ? [] : list
        )
      );
    });
  }

  useEffect(() => {
    setPage(1);
    setList([]);
    setTotal(0);
    if (tabKey === 'select') {
      if (noFilter) {
        getFacesByNoFilter(1);
      } else {
        getFaces(1);
      }
    }
  }, [tabKey]);

  function getMoreFaces() {
    const newPage = page + 1;
    setPage(newPage);
    if (noFilter) {
      getFacesByNoFilter(newPage);
    } else {
      getFaces(newPage);
    }
  }

  const length = useMemo(() => {
    return list.reduce((prev, cur) => prev + cur.list.length, 0);
  }, [list]);

  function handleSelect(faceObj) {
    onSelect(faceObj);
  }

  return (
    <Modal title="People Search" maxWidth="md" dividers={false} hideActions onClose={onClose}>
      {!onlyOne && (
        <Grid className={classes.buttons}>
          <SwitchButtons value={tabKey} onChange={v => setTabKey(v)} options={tabOptions} />
        </Grid>
      )}
      {tabKey === 'select' && (
        <Grid style={{minHeight: 500, maxHeight: 500, overflowY: 'auto'}} id="scrollableDiv-facial">
          <InfiniteScroll
            next={getMoreFaces}
            hasMore={length < total}
            loader={
              <div style={{textAlign: 'center', minHeight: '50px', color: '#3C5BFF'}}>
                <CircularProgress color="inherit" />
              </div>
            }
            dataLength={length}
            scrollThreshold={0.95}
            scrollableTarget="scrollableDiv-facial"
          >
            <Grid className={classes.container}>
              {list.map((item, index) => (
                <Grid key={index} className={classes.item}>
                  <Typography variant="subtitle1" style={{color: 'black'}} gutterBottom>
                    {item.date}
                  </Typography>
                  <Grid container spacing={2}>
                    {item.list.map((cell, i) => (
                      <Grid item container direction="column" alignItems="center" xs={2} key={i}>
                        <Grid container className={classes.cell} onClick={() => handleSelect(cell)}>
                          <CroppedImage target={cell} className={classes.avatar} />
                          {(cell.data ? cell.data.person_name : undefined) && (
                            <Grid container className={classes.backdrop}>
                              <Grid container wrap="nowrap" className={classes.namewrap}>
                                <Grid item className={classes.iconwrap}>
                                  <PersonIcon className={classes.employee} />
                                </Grid>
                                <Grid item className={classes.name}>
                                  {(cell.data ? cell.data.person_name : undefined)}
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          {(cell.is_flag || cell.is_contact) && (
                            <Grid item className={classes.contact}>
                              {!cell.is_flag && cell.is_contact && (
                                <ContactPage className={classes.contactIcon} />
                              )}
                              {cell.is_flag && <FlagIcon className={classes.flag} />}
                            </Grid>
                          )}
                        </Grid>
                        <Grid container>
                          <Typography
                            variant="caption"
                            style={{paddingLeft: '8px'}}
                            color="textSecondary"
                          >
                            {cell.time}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        </Grid>
      )}
      {/* {tabKey === 'upload' && <Upload onSelect={handleSelect} />} */}
    </Modal>
  );
};

export default FacialSelect;
