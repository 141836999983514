import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { getText } from 'src/utils/MultilingualLoader';
import Pagination from './Pagination';

const styles = theme => ({
  button: {
    margin: 24
  },
  label: {
    color: 'white',
    textTransform: 'initial'
  },
  tableGrid: {
    height: '100%',
    overflow: 'auto'
  },
  tableCard: {
    overflow: 'auto'
  },
  table: {
    width: '100%'
  },
  header: {
    fontSize: '1rem'
  },
  row: {
    fontSize: '1rem'
  },
  iconButton: {
    marginRight: '0.5rem',
    '&:hover': {
      cursor: 'pointer',
    }
  },
});

const tableHeaders = [
  'id',
  'first_name',
  'last_name',
  'username',
  'level',
  'activated',
  ' '
];

const SiteList = props => {
  const {
    classes,
    managers,
    pagination,
    onClickAdd,
    onClickDelete
  } = props;
  
  return (
    <Grid
      container={true}
      justify="flex-start"
      direction="row"
      alignItems="stretch"
      spacing={0}
    >
      <Grid item={true} xs={12}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onClickAdd}
        >
          <Typography variant="body2" className={classes.label}>
            {getText('add_site_manager')}
          </Typography>
        </Button>
      </Grid>
      <Grid item={true} xs={12} className={classes.tableGrid}>
        <Card className={classes.tableCard}>
          <CardContent>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => (<TableCell key={index} className={classes.header}>{getText(header)}</TableCell>))}
                </TableRow>
              </TableHead>
              <TableBody>
                {managers.map(manager => {
                  if (!manager.user) return null;

                  return (
                    <TableRow key={manager.user.id}>
                      <TableCell className={classes.row}>{manager.user.id}</TableCell>
                      <TableCell className={classes.row}>{manager.user.first_name}</TableCell>
                      <TableCell className={classes.row}>{manager.user.last_name}</TableCell>
                      <TableCell className={classes.row}>{manager.user.username}</TableCell>
                      <TableCell className={classes.row}>{manager.level}</TableCell>
                      <TableCell className={classes.row}>{manager.user.activated ? getText('yes') : getText('no')}</TableCell>
                      <TableCell className={classes.row}>
                        <DeleteIcon className={classes.iconButton} onClick={onClickDelete(manager.user.id)} />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
      <Pagination 
        curPage={pagination.curPage}
        count={pagination.count}
      />
    </Grid>
  );
};

export default withStyles(styles)(SiteList);
