import React, { useState }  from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import TextField from '@material-ui/core/TextField';
import Zoom from '@material-ui/core/Zoom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    margin: '12px 0'
  },
  card: {
    marginBottom: 12,
    boxShadow: 'none'
  },
  reportText: {
    width: '100%'
  },
  mediaGrid: {
    width: '100%'
  },
  mediaList: {
    paddingTop: '1rem',
    flexWrap: 'nowrap',
    width: '100%',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  diaContent: {
    textAlign: 'center'
  },
  zoomedImg: {
    margin: 'auto',
    height: '100%',
    overflow: 'auto',
  },
});

const CaseReport = props => {
  const { classes, report, media } = props;
  //React State Hook
  const [zoomImg, setZoomImg] = useState({
    isOpen: false,
    imgSrc: null
  });
  return (
    <ExpansionPanel className={classes.root}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
        <Typography variant="body1">
          {getText('officer_report')}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container direction="column">
          <Grid item>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="body1">
                  {getText('report')}
                </Typography>
                <TextField 
                  className={classes.reportText}
                  InputProps={{style: {color: 'green'}}}
                  value={report}
                  multiline
                  disabled />
              </CardContent>
            </Card>
          </Grid>
          <Grid item className={classes.mediaGrid}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="body1">
                  {getText('medium_recorded')}
                </Typography>
                <GridList cols={2.5} cellHeight={300} className={classes.mediaList}>
                  {
                    media.snapshots && media.snapshots.map((snapshot, index) => ( 
                      <GridListTile key={snapshot.url}>
                        <img src={snapshot.url}
                            alt={getText('snapshot')}
                            onClick={() => {setZoomImg({
                              isOpen: true,
                              imgSrc: snapshot.url
                            });}}
                            />
                        <GridListTileBar
                          title={getText('picture at scene')} titlePosition="top"/>
                      </GridListTile>
                    ))
                  }
                  {
                    media.videos && media.videos.map((video, index) => (
                      <GridListTile key={video.url}>
                        <video height="100%" controls src={video.url} />
                      </GridListTile>
                    ))
                  }
                </GridList>
                <Dialog
                  TransitionComponent={Zoom}
                  fullScreen
                  open={zoomImg.isOpen}
                  onClose={() => {setZoomImg({
                    isOpen: false,
                    imgSrc: null
                  });}}
                  >
                  <DialogTitle>
                    <CloseIcon
                      className={classes.closeButton}
                      onClick={() => {setZoomImg({
                        isOpen: false,
                        imgSrc: null
                      });}}
                      />
                  </DialogTitle>
                  <DialogContent className={classes.diaContent}>
                    <img className={classes.zoomedImg} src={zoomImg.imgSrc} alt="scene" />
                  </DialogContent>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

CaseReport.propTypes = {
  report: PropTypes.string.isRequired,
  media: PropTypes.object.isRequired,
};

CaseReport.defaultProps = {
  report: '',
  media: {}
};

export default withStyles(styles)(CaseReport);
