import React from 'react';
import { connect } from 'react-redux';

import RoutineHistoryList from './RoutineHistoryList';

const mapStateToProps = state => {
  return {
    curSite: state.site.curSite,
  };
};

const mapDispatchToProps = dispatch => {
  return { };
};

class RoutineHistoryListContainer extends React.Component {
  render() {
    return (
      <>
        <RoutineHistoryList/>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutineHistoryListContainer);