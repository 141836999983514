import { getText } from './MultilingualLoader';

export function validatePassword(password) {
  const length = /.{10,}/;
  const numeric = /.*[0-9]/;
  const lowerCase = /.*[a-z]/;
  const upperCase = /.*[A-Z]/;
  const punctuation = /.*[!@#$%^&*,.]/;
  if (!length.test(password)){
    return getText('input_error_password_short');
  }
  if (!numeric.test(password)){
    return getText('input_error_password_numeric');
  }
  if (!lowerCase.test(password)){
    return getText('input_error_password_lower_case');
  }
  if (!upperCase.test(password)){
    return getText('input_error_password_upper_case');
  }
  if (!punctuation.test(password)){
    return getText('input_error_password_punctuation');
  }
  return '';
}