import * as _userActions from "./user";
import * as _caseActions from "./case";
import * as _guardActions from "./guard";
import * as _cameraActions from "./camera";
import * as _wsActions from "./websocket";
import * as _robotActions from "./robot";
import * as _notificationActions from "./notification";
import * as _manageActions from "./manage";
import * as _siteActions from "./site";
import * as _inspectionActions from "./inspection";
import * as _sensorActions from "./sensor";
import * as _targetActions from "./target";
import * as _taskActions from "./task";
import * as _referenceActions from "./reference";
import * as _mapActions from "./map";
import * as _routineHistoryActions from "./routineHistory";
import * as _messageActions from "./message";

export const userActions = _userActions;
export const caseActions = _caseActions;
export const guardActions = _guardActions;
export const cameraActions = _cameraActions;
export const wsActions = _wsActions;
export const robotActions = _robotActions;
export const notificationActions = _notificationActions;
export const manageActions = _manageActions;
export const siteActions = _siteActions;
export const inspectionActions = _inspectionActions;
export const sensorActions = _sensorActions;
export const targetActions = _targetActions;
export const taskActions = _taskActions;
export const referenceActions = _referenceActions;
export const mapActions = _mapActions;
export const routineHistoryActions = _routineHistoryActions;
export const messageActions = _messageActions;
