import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import RobotDetail from "./RobotDetail";
import RobotCamera from "./RobotCamera";
import RobotMapPanel from "./RobotMapPanel";
import Actions from "./Actions";
import RoutineStatus from "./RoutineStatus";
import RobotPatrol from "./RobotPatrol";
import Configs from "./Configs";

const styles = (theme) => ({
  root: {
    // flexGrow: 1,
    // height: '100%'
  },
});

const RobotPanel = (props) => {
  const {
    classes,
    panelContent,
    setPanelContent,
    selected,
    configs,
    detectionConfigs,
    algos,
    targets,
  } = props;
  return (
    <Grid
      className={classes.root}
      container={true}
      justify="flex-start"
      direction="row"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <RobotDetail selected={selected} />
      </Grid>
      <Grid item={true} xs={12}>
        <Grid
          container={true}
          justify="flex-start"
          direction="row"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item={true} xs={12} lg={6}>
            <RobotCamera />
          </Grid>
          <Grid item={true} xs={12} lg={6}>
            <RobotMapPanel />
          </Grid>
        </Grid>
      </Grid>
      {panelContent === "routine_status" ? (
        <Grid item={true} xs={12}>
          <RoutineStatus robot={selected} setPanelContent={setPanelContent} />
        </Grid>
      ) : (
        <>
          <Grid item={true} xs={12}>
            <Actions selected={selected} setPanelContent={setPanelContent} />
          </Grid>
          <Grid item xs={12}>
            <RobotPatrol
              selected={selected}
              configs={configs}
              targets={targets}
            />
          </Grid>
          <Grid item xs={12}>
            <Configs
              selected={selected}
              configs={configs}
              detectionConfigs={detectionConfigs}
              algos={algos}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default withStyles(styles)(RobotPanel);
