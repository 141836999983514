/* eslint-disable no-undef */
import React, { Component, createRef } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import { getText } from 'src/utils/MultilingualLoader';

class RegionEditor extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = createRef();
    this.state = {};
  }

  componentDidMount() {
    this.getMeta();
  }

  getMeta() {
    const {snapshot, region} = this.props;
    const img = new Image();
    img.src = snapshot;
    img.onload = () => {
      const imageWidth = img.width;
      const imageHeight = img.height;
      let boxWidth;
      let boxHeight;
      if (imageWidth > imageHeight) {
        boxWidth = 800;
        boxHeight = (imageHeight / imageWidth) * 800;
      } else {
        boxHeight = 800;
        boxWidth = (imageWidth / imageHeight) * 800;
      }

      const dimensions = [boxWidth, boxHeight];
      this.dimensions = dimensions;
      const arr = region
        ? region
            .split(',')
            .map((elem, index) => {
              if (index % 2 === 0) {
                return Math.round(Number(elem) * dimensions[0]);
              } else {
                return Math.round(Number(elem) * dimensions[1]);
              }
            })
            .join(',')
        : '';

      $(this.canvasRef.current)
        .parent()
        .css({width: boxWidth + 'px', height: boxHeight + 100 + 'px', margin: '0 auto'})
        .end()
        .val(arr)
        .canvasAreaDraw({
          imageUrl: snapshot,
          graphType: 'polygon',
          width: boxWidth,
          height: boxHeight,
        });
    };
  }

  handleOk() {
    const {onClose} = this.props;
    if (onClose) {
      const dimensions = this.dimensions;
      let region = $(this.canvasRef.current).val();
      if (region) {
        region = region
          .split(',')
          .map((elem, index) => {
            if (index % 2 === 0) {
              return (Number(elem) / dimensions[0]).toFixed(4);
            } else {
              return (Number(elem) / dimensions[1]).toFixed(4);
            }
          })
          .join(',');
      }
      onClose(region);
    }
  }

  handleCancel() {
    const {onClose} = this.props;
    if (onClose) {
      onClose();
    }
  }

  render() {
    return (
      <Dialog open={true} fullWidth={true} maxWidth={'md'} onClose={this.handleCancel.bind(this)}>
        <DialogContent>
          <div id="main" className="container">
            <div>
              <textarea
                ref={this.canvasRef}
                name="coords2"
                rows={2}
                className="canvas-area input-xxlarge"
                readOnly
                placeholder="Interact with snapshot to see coordinates"
                style={{width: '100%'}}
              ></textarea>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid>
            <Button color="primary" onClick={this.handleCancel.bind(this)}>{getText('cancel')}</Button>
            <Button color="primary" onClick={this.handleOk.bind(this)}>{getText('ok')}</Button>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
};

export default RegionEditor;
