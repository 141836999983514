export const DetailType = {
  People: 0,
  Vehicle: 1,
  Motorcycle: 2,
}

export const DetailFrom = {
  Alert: 0,
  Event: 1,
}
