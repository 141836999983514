import {Grid, GridProps} from '@material-ui/core';
import React, {useRef, useEffect, useState, useCallback} from 'react';
import {makeStyles} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(theme => ({
  wrap: {
    position: 'relative',
    overflow: 'hidden',
    width: 'auto',
  },
  left: {
    position: 'absolute',
    left: -8,
    top: 0,
    height: '100%',
    width: 32,
    background: '#fff',
    cursor: 'pointer',
    zIndex: 10,
  },
  right: {
    position: 'absolute',
    right: -8,
    top: 0,
    height: '100%',
    width: 32,
    background: '#fff',
    cursor: 'pointer',
    zIndex: 10,
  },
  children: {
    overflow: 'hidden',
    '&>div': {
      flexShrink: 0,
    },
  },
}));


export function HorizontalScrollingComponent({children, ...props}) {
  const classes = useStyles();
  const wrapRef = useRef();
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(false);

  const setArrowState = useCallback(() => {
    const wrapElement = wrapRef.current;
    const {scrollWidth, clientWidth, scrollLeft} = wrapElement;
    setLeftArrow(!!scrollLeft);
    setRightArrow(scrollLeft < scrollWidth - clientWidth);
  }, []);

  const scrollView = useCallback(isLeft => {
    const wrapElement = wrapRef.current;
    const option = {
      left: isLeft ? wrapElement.scrollLeft - 120 : wrapElement.scrollLeft + 120,
      behavior: 'smooth',
    };
    wrapElement.scrollTo(option);
  }, []);

  useEffect(() => {
    setArrowState();
  });

  return (
    <Grid container wrap="nowrap" onScroll={() => setArrowState()} className={classes.wrap}>
      {leftArrow && (
        <Grid
          className={classes.left}
          alignItems="center"
          container
          onClick={() => scrollView(true)}
        >
          <NavigateBeforeIcon />
        </Grid>
      )}
      <Grid
        {...props}
        item
        container
        wrap="nowrap"
        className={classes.children}
        ref={wrapRef}
      >
        {children}
      </Grid>
      {rightArrow && (
        <Grid
          className={classes.right}
          alignItems="center"
          container
          onClick={() => scrollView(false)}
        >
          <NavigateNextIcon />
        </Grid>
      )}
    </Grid>
  );
}
