import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 4,
  },
  container: {
    minHeight: 200,
    overflow: 'hidden',
  },
  item: {
    margin: theme.spacing(1, 0, 1.5),
  },
  cell: {
    position: 'relative',
    width: '120px',
    height: '120px',
    marginBottom: 4,
  },
  avatar: {
    position: 'absolute',
    width: '100%',
    borderRadius: 6,
    top: 0,
    left: 0,
    cursor: 'pointer',
  },
  button: {
    minWidth: 'auto',
    paddingTop: 7,
    paddingBottom: 7,
  },
  buttons: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  },
  dragZone: {
    overflow: 'hidden',
    background: '#fafafa',
    border: 'dashed 2px #e0e0e0',
    padding: 32,
    marginTop: 16,
    minHeight: 400,
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    width: 80,
    height: 80,
    marginBottom: 24,
  },
  choose: {
    position: 'relative',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'inline-block',
  },
  imageInput: {
    position: 'absolute',
    top: 0,
    boxSizing: 'border-box',
    paddingTop: '2px',
    overflow: 'hidden',
    color: 'transparent',
    height: '1px',
  },
  namewrap: {
    position: 'absolute',
    bottom: 0,
    height: '24px',
    borderRadius: '6px',
    fontSize: '16px',
    color: theme.palette.primary.contrastText,
  },
  iconwrap: {
    bottom: 0,
    height: 24,
    width: 24,
    backgroundColor: 'rgba(0,0,0,.7)',
    borderRadius: '0 6px 0 6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  employee: {
    fontSize: '16px',
  },
  name: {
    fontSize: '13px',
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  backdrop: {
    overflow: 'hidden',
    position: 'absolute',
    width: '120px',
    height: '120px',
    borderRadius: '6px',
    background: 'linear-gradient(to top, rgba(0,0,0, .7) 15% , rgba(0,0,0,.1) 50%)',
  },
  contact: {
    position: 'absolute',
    bottom: 0,
    height: 24,
    minWidth: 24,
    backgroundColor: '#fff',
    borderRadius: '0 6px 0 6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contactIcon: {
    width: 16,
    height: 16,
  },
  flag: {
    color: theme.palette.error.dark,
    fontSize: 16,
  },
}));

export default useStyles;
