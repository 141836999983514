import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon } from "react-google-maps";
import SearchBox from "react-google-maps/lib/components/places/SearchBox";

import MapStyle from './MapStyle.json';

class DrawSiteGoogleMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marker: null
    };
    this.polygonsOnMap = [];
    props.polygons.forEach((polygon, index) => {
      this.polygonsOnMap.push(React.createRef());
    })
  }

  mapRef = map => this.map = map;
  newPolygonOnMapRef = newPolygonOnMap => this.newPolygonOnMap = newPolygonOnMap;
  searchBoxRef = searchBox => this.searchBox = searchBox;

  componentDidMount() {
    try {
      if (this.props.polygons.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        this.props.polygons.forEach(polygon => polygon.geo_points.forEach(point => bounds.extend(point)));
        // Adjust bounds not to zoom in too much
        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
          const extendPoint1 = new window.google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
          const extendPoint2 = new window.google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
          bounds.extend(extendPoint1);
          bounds.extend(extendPoint2);
        }
        this.map.fitBounds(bounds);
      }
    } catch (e) { }
  }

  render() {
    const { defaultZoom, defaultCenter, newPolygon, polygons, curAction,
      onMapClick, onPolygonEdit } = this.props;

    const { marker } = this.state;

    return (
      <GoogleMap
        ref={this.mapRef}
        defaultZoom={defaultZoom}
        defaultCenter={defaultCenter}
        onClick={onMapClick}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          styles: MapStyle
        }}
      >
        {polygons.map((polygon, index) => (
          <Polygon
            key={polygon.name}
            ref={this.polygonsOnMap[index]}
            draggable
            editable
            path={polygon.geo_points}
            options={{ strokeColor: '#4C7F72', fillColor: '#4C7F72' }}
            onMouseUp={() => {
              try {
                onPolygonEdit(index, this.polygonsOnMap[index].current.getPath().g);
              } catch (e) { }
            }}
          />
        ))}
        {curAction === 'add' &&
          <Polygon
            ref={this.newPolygonOnMapRef}
            draggable
            editable
            path={newPolygon.geo_points}
            options={{ strokeColor: '#4C7F72', fillColor: '#4C7F72' }}
            onMouseUp={() => {
              try {
                onPolygonEdit(-1, this.newPolygonOnMap.getPath().g);
              } catch (e) { }
            }}
          />
        }
        <SearchBox
          ref={this.searchBoxRef}
          controlPosition={1}
          onPlacesChanged={() => {
            try {
              const place = this.searchBox.getPlaces()[0];
              if (place) {
                const position = place.geometry.location.toJSON();
                if (this.map && window.google) {
                  const bounds = new window.google.maps.LatLngBounds();
                  bounds.extend(position);
                  // Adjust bounds not to zoom in too much
                  if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
                    const extendPoint1 = new window.google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
                    const extendPoint2 = new window.google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
                    bounds.extend(extendPoint1);
                    bounds.extend(extendPoint2);
                  }
                  this.map.fitBounds(bounds);
                  this.setState({
                    marker: <Marker
                      position={position}
                    />
                  });
                }
              }
            } catch (e) { }
          }}
        >
          <input
            type="text"
            placeholder="Enter address"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              marginTop: `27px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
            }}
          />
        </SearchBox>
        {marker}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(DrawSiteGoogleMap));
