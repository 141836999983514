import React from 'react';
import { withStyles, Paper, Typography, Breadcrumbs, Select, OutlinedInput, MenuItem, Button } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  input: {
    minWidth: '100px',
    maxWidth: '200px',
    padding: '5px 20px 5px 10px',
  },
  menuItem: {
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem',
  },
  label: {
    color: 'white',
    textTransform: 'initial'
  },
});

const SiteBar = props => {
  const { classes, siteOptions, curSite, onSiteChange, onSiteReset } = props;
  return (
    <Paper className={classes.root}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <Button
            variant="contained"
            color="primary"
            onClick={onSiteReset}
          >
          <Typography variant="body2" className={classes.label}>
            {getText('site_map')}
          </Typography>
        </Button>
        <Select
          variant="outlined"
          input={<OutlinedInput classes={{ input: classes.input }} />}
          value={curSite}
          onChange={onSiteChange}
          >
          {siteOptions.map((option, index) =>
            <MenuItem key={index} className={classes.menuItem} value={option.value}>{option.label}</MenuItem>
          )}
        </Select>
      </Breadcrumbs>
    </Paper>
  );
};

export default withStyles(styles)(SiteBar);
