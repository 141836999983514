import {
  notificationConstants
} from 'src/redux/constants';

export const addNotification = (text) => {
  return dispatch => {
    dispatch({
      type: notificationConstants.ADD_NOTIFICATION,
      msg: {
        text: text
      }
    });
  };
};

export const addNotificationAhead = (text, duration) => {
  return dispatch => {
    dispatch({
      type: notificationConstants.ADD_NOTIFICATION_AHEAD,
      msg: {
        text: text,
        duration,
      }
    });
  };
};

export const notificationShowed = () => {
  return dispatch => {
    dispatch({
      type: notificationConstants.SHOWED_NOTIFICATION
    });
  };
};
