import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography, Tooltip } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingText from 'src/components/LoadingText';
import { getText } from 'src/utils/MultilingualLoader';


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  mapId: {
    wordBreak: 'break-all'
  },
  floorParams: {
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  arrow: {
    color: '#000',
  },
  tooltip: {
    backgroundColor: '#000',
    fontSize: '1rem'
  },
});


function SiteBuildingRow(props) {
  const {
    row,
    floorLoading,
    floors,
    sites,
    handleEditBuilding,
    handleDeleteBuilding,
    handleAddFloor,
    handleEditFloor,
    handleDeleteFloor,
    getSiteFloors,
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const site = (sites || []).find(v => v.id === row.site_id);
  const siteName = site ? site.label || site.name : '';

  const handleClickCollapse = () => {
    if (!open) {
      getSiteFloors(row.id);
    }
    setOpen(!open)
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell width={50}>
          <IconButton aria-label="expand row" size="small" onClick={handleClickCollapse}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{siteName}</TableCell>
        <TableCell className={classes.row}>
          <IconButton onClick={() => { handleEditBuilding(row) }}><EditIcon /></IconButton>
          <IconButton onClick={() => { handleAddFloor(row) }} title={getText('add_floor')}><AddIcon /></IconButton>
          <IconButton onClick={() => { handleDeleteBuilding(row.id) }}><DeleteIcon /></IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} />
        <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{getText('floor_name')}</TableCell>
                    <TableCell>{getText('floor')}</TableCell>
                    <TableCell>{getText('map')}</TableCell>
                    <TableCell>{getText('floor_params')}</TableCell>
                    <TableCell>{getText('actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {floorLoading ?
                    <LoadingText /> :
                    (floors || []).map((floor) => (
                      <TableRow key={floor.id}>
                        <TableCell width={120}>{floor.name}</TableCell>
                        <TableCell width={100}>{floor.floor}</TableCell>
                        <TableCell width={150}><div className={classes.mapId}>{floor.map_id}</div></TableCell>
                        <TableCell width={200}>
                          <Tooltip
                            title={<pre>{JSON.stringify(floor.params, null, 2)}</pre>}
                            classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                            placement="top"
                          >
                            <div className={classes.floorParams}>{JSON.stringify(floor.params)}</div>
                          </Tooltip>
                        </TableCell>
                        <TableCell width={100}>
                          <IconButton onClick={() => { handleEditFloor(row, floor) }}><EditIcon /></IconButton>
                          <IconButton onClick={() => { handleDeleteFloor(row.id, floor.id) }}><DeleteIcon /></IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default SiteBuildingRow;