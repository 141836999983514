import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'

const FilterIcon = props => {
  return (
    <SvgIcon>
      <polygon fill="none" stroke="RGB(114, 114, 114)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
        points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
    </SvgIcon>
  );
}

export default FilterIcon;