import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  form: {
    width: '50%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
    margin: 'auto'
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

const AddGuardPanel = ({
  classes,
  username,
  last_name,
  first_name,
  phone_number,
  onSubmit,
  onChange,
  submitting
}) => (
  <form className={classes.form}>
    <FormControl margin="normal" required fullWidth>
      <InputLabel htmlFor="username">{getText('email')}</InputLabel>
      <Input
        id="username"
        name="username"
        autoFocus={true}
        value={username}
        onChange={onChange('username')}
      />
    </FormControl>
    <FormControl margin="normal" fullWidth>
      <InputLabel htmlFor="last_name">{getText('last_name')}</InputLabel>
      <Input
        id="last_name"
        name="last_name"
        value={last_name}
        onChange={onChange('last_name')}
      />
    </FormControl>
    <FormControl margin="normal" fullWidth>
      <InputLabel htmlFor="first_name">{getText('first_name')}</InputLabel>
      <Input
        id="first_name"
        name="first_name"
        value={first_name}
        onChange={onChange('first_name')}
      />
    </FormControl>
    <FormControl margin="normal" required fullWidth>
      <InputLabel htmlFor="phone_number">{getText('phone_number')}</InputLabel>
      <Input
        id="phone_number"
        name="phone_number"
        value={phone_number}
        onChange={onChange('phone_number')}
      />
    </FormControl>

    <Button
      variant="contained"
      color="primary"
      fullWidth={true}
      className={classes.submit}
      onClick={onSubmit}
    >
      {getText('submit')}
    </Button>
  </form>
);

AddGuardPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  phone_number: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default withStyles(styles)(AddGuardPanel);
