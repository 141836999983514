import { caseConstants } from 'src/redux/constants';
import { sortCameras } from './camera';
import notificationIcon from 'public/favicon.ico';

const initialState = {
  cases: [],
  loading: false,  //loading case list
  fetching: false, //fetching new cases
  updating: false, //updating selected case
  camerasLoading: false, //loading nearby cameras
  selected: null,  //selected case
  hasMore: false,
  case_notify: process.env.REACT_APP_ENV !== 'development', //browser notification for new cases
  curPage: 0,
  lastPage: 0,
  num_new: 0,
  num_active: 0,
  cameras: [], //nearby cameras
  selectedCameras: {},
  caseTypes: {},
  search: {
    query: ''
  },
  filters: {
    action: 'all',
    status: 'all',
    eventType: 'all',
    eventSource: 'all',
    priority: 'all',
    searchQuery: '',
    timeType: 'all',
    startTime: null,
    endTime: null,
    pageSize: 100,
    unread: false,
    unclosed: false,
    caseType: 'event',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case caseConstants.GET_CASES_REQUEST:
      return {
        ...state,
        loading: true
      };

    case caseConstants.GET_CASES_SUCCESS:
      let { cases, caseStats } = action;
      // let selected = Object.assign({}, state.selected);
      // // Check if selected is empty
      // if (!selected) {
      //   selected = cases[0];
      // } else {
      //   // Check if selected is in current list
      //   let foundSelected = cases.find(_case => _case.id === selected.id);
      //   selected = foundSelected ? foundSelected : cases[0];
      // }

      let hasMore = cases.length === state.filters.pageSize;

      return {
        ...state,
        cases,
        // selected,
        loading: false,
        hasMore,
        curPage: 1,
        lastPage: 1,
        num_new: caseStats.unviewed,
        num_active: caseStats.unclosed
      };

    case caseConstants.GET_CASES_FAILURE:
      return {
        ...state,
        cases: [],
        selected: null,
        loading: false
      };

    case caseConstants.GET_MORE_CASES_REQUEST:
      return {
        ...state,
        loading: true,
        curPage: state.curPage + 1,
        lastPage: state.lastPage + 1
      };

    case caseConstants.GET_MORE_CASES_SUCCESS:
      const { pageSize } = state.filters;
      let lastPage = state.lastPage;
      let more_cases = state.cases.concat(action.cases).slice(0, lastPage * pageSize);
      hasMore = action.cases.length === pageSize;
      if (hasMore) lastPage = Math.floor(more_cases.length / pageSize);
      else lastPage = Math.ceil(more_cases.length / pageSize);
      let curPage = state.curPage > lastPage ? lastPage : state.curPage;
      return {
        ...state,
        cases: more_cases,
        loading: false,
        hasMore,
        curPage,
        lastPage,
        num_new: action.caseStats.unviewed,
        num_active: action.caseStats.unclosed
      };

    case caseConstants.GET_MORE_CASES_FAILURE:
      lastPage = Math.ceil(state.cases.length / state.filters.pageSize);
      curPage = state.curPage > lastPage ? lastPage : state.curPage;
      return {
        ...state,
        loading: false,
        hasMore: false,
        curPage,
        lastPage,
      };

    case caseConstants.GET_NEW_CASES_REQUEST:
      return {
        ...state,
        fetching: true,
      }

    case caseConstants.GET_NEW_CASES_SUCCESS:
      let new_cases = [];
      if (state.cases.length === 0) {
        new_cases = action.cases;
        cases = new_cases;
      }
      //add new cases from the 10 newest cases that the local does not have
      else {
        let newest_local_id = state.cases[0].id;
        new_cases = action.cases.filter(_case => _case.id > newest_local_id);
        cases = new_cases.concat(state.cases);
      }
      // browser notification for new cases
      if (new_cases.length > 0 && state.case_notify && "Notification" in window) {
        if (Notification.permission === "granted") {
          new Notification('New Case', { icon: notificationIcon, requireInteraction: true });
        }
        else if (Notification.permission !== 'denied') {
          Notification.requestPermission(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
              new Notification("New Case", { icon: notificationIcon, requireInteraction: true });
            }
          });
        }
      }

      return {
        ...state,
        fetching: false,
        cases,
        num_new: action.caseStats.unviewed,
        num_active: action.caseStats.unclosed,
      };

    case caseConstants.GET_NEW_CASES_FAILURE:
      return {
        ...state,
        fetching: false,
      }

    case caseConstants.SET_PAGE:
      return {
        ...state,
        curPage: action.page
      }

    case caseConstants.UPDATE_CASE_REQUEST:
      return {
        ...state,
        updating: true
      };

    case caseConstants.UPDATE_CASE_SUCCESS:
      let updCases = Object.assign([], state.cases);
      let selected = state.selected ? Object.assign(state.selected) : {};
      //update the local case
      if (selected.id === action.case.id) {
        Object.assign(selected, action.case);
      }
      let updIdx = updCases.findIndex(_case => _case.id === action.case.id);
      if (updIdx !== -1) {
        let updCase = updCases[updIdx];
        Object.assign(updCase, action.case);
        //Check if the updated case still satisfies the filters. If not remove it from the case list.
        // if (!caseActions.satisfyFilter(updCase, state.filters)) {
        //   updCases.splice(updIdx, 1);
        // }
      }

      return {
        ...state,
        updating: false,
        selected,
        cases: updCases,
        num_new: action.caseStats ? action.caseStats.unviewed : state.num_new,
        num_active: action.caseStats ? action.caseStats.unclosed : state.num_active
      };

    case caseConstants.UPDATE_CASE_FAILURE:
      return {
        ...state,
        updating: false
      };

    case caseConstants.DELETE_CASE_SUCCESS:
      // TODO: For deleting a case on the last page, need to load more data to fill the page or leave the page unfilled?
      let delCases = Object.assign([], state.cases);
      delCases.splice(delCases.findIndex(_case => _case.id === action.caseId), 1)
      return {
        ...state,
        selected: null,
        cases: delCases,
        num_new: state.num_new - 1,
        num_active: state.num_active - 1
      }

    case caseConstants.SELECT_CASE:
      return {
        ...state,
        selected: action.case,
      };

    case caseConstants.SET_FILTER:
      return {
        ...state,
        //only selecting new or active tab will set unread or unclosed
        filters: Object.assign({}, state.filters, action.filters)
      };

    case caseConstants.GET_CAMERAS_REQUEST:
      return {
        ...state,
        camerasLoading: true
      }

    case caseConstants.GET_CAMERAS_SUCCESS:
      sortCameras('status', 'desc', action.cameras);
      return {
        ...state,
        camerasLoading: false,
        cameras: action.cameras
      };

    case caseConstants.GET_CAMERAS_FAILURE:
      return {
        ...state,
        camerasLoading: false
      }

    case caseConstants.SELECT_CAMERA:
      return {
        ...state,
        selectedCameras: {
          ...state.selectedCameras,
          [action.id]: action.checked
        }
      };

    case caseConstants.SET_NEW_CASE_NOTIFICATION:
      return {
        ...state,
        case_notify: action.shouldNotify
      }

    case caseConstants.GET_TYPES_REQUEST:
      return {
        ...state,
        caseTypes: {
          ...state.caseTypes,
          [action.deviceId]: {
            loading: true
          }
        }
      };
        
    case caseConstants.GET_TYPES_SUCCESS:
      return {
        ...state,
        caseTypes: {
          ...state.caseTypes,
          [action.deviceId]: {
            loading: false,
            types: action.types
          }
        }
      };

    case caseConstants.GET_TYPES_FAILURE:
      return {
        ...state,
        caseTypes: {
          ...state.caseTypes,
          [action.deviceId]: {
            loading: false
          }
        }
      };

    case caseConstants.UPDATE_CAMERAS_FROM_WS:
      let updCameras = Object.assign([], state.cameras);
      action.cameras.forEach(camera => {
        let updCameraIdx = updCameras.findIndex(_camera => _camera.id === camera.id);
        if (updCameraIdx !== -1) {
          updCameras[updCameraIdx] = camera;
        }
      }) 
      return {
        ...state,
        cameras: updCameras
      }

    default:
      return state;
  }
};
