import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GuardIcon from '@material-ui/icons/Security';
import PoliceIcon from '@material-ui/icons/SupervisorAccount';
import CallIcon from '@material-ui/icons/Call';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

import RobotIcon from 'src/components/icons/RobotIcon';
import Actions from './Actions';
import ActionDialog from './ActionDialog';
import { guardActions } from 'src/redux/actions';
import { caseActions } from 'src/redux/actions';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  const { guards } = state.guard;
  const { selected: _case } = state.case;
  return { guards, case: _case };
};

const mapDispatchToProps = dispatch => {
  return {
    getGuards: () => {
      dispatch(guardActions.getGuards());
    },
    saveAction: (data) => {
      dispatch(caseActions.updateCase(data));
    },
  };
};

const actions = [
  { name: 'call_police', label: getText('call_police'), icon: <PoliceIcon />, disabled: true, tooltip: 'under development' },
  // { name: 'dispatch_guard', label: getText('dispatch_guard'), icon: <GuardIcon /> },
  { name: 'dispatch_robot', label: getText('dispatch_robot'), icon: <RobotIcon />, disabled: true, tooltip: 'under development' },
  // { name: '', label: getText('building_manager'), icon: <CallIcon />, disabled: true, tooltip: 'under development' },
  { name: 'notify_customer', label: getText('notify_customer'), icon: <CallIcon /> },
  { name: 'no_harm', label: getText('no_harm'), icon: <ThumbUpIcon /> },
  { name: 'false_alarm', label: getText('false_alarm'), icon: <ThumbDownIcon /> },
];

class ActionBoxContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedAction: 0,
      selectedGuard: 0,
      showGuards: false,
      phones: [],
      phonesInput: '',
      emails: [],
      emailsInput: ''
    };
  }

  componentDidMount() {
    // TODO: temporally do not get guards to assign
    // this.props.getGuards();
  }

  handleActionDialogOpen = index => event => {
    this.setState({
      open: true,
      selectedAction: index,
      selectedGuard: 0,
      showGuards: index === 1,
      showNotifyCustomer: index === 3
    });
  };

  handleGuardChange = (event, value) => {
    this.setState({ selectedGuard: parseInt(value) });
  };

  handleActionSubmit = event => {
    const { selectedAction } = this.state;
    const action = actions[selectedAction].name;
    const { id: caseId } = this.props.case;
    if (selectedAction === 1) {
      // TODO: temporally do not assign any guard online
      // const guardId = this.props.guards[selectedGuard].id;
      // this.props.saveAction({caseId, action, guardId});
      this.props.saveAction({ caseId, action });
    }
    else if (selectedAction === 3) {
      let phones = Object.assign([], this.state.phones);
      if (this.state.phonesInput !== '') phones.push(this.state.phonesInput);
      const notify_phones = phones.join(',');

      let emails = Object.assign([], this.state.emails);
      if (this.state.emailsInput !== '') emails.push(this.state.emailsInput);
      const notify_emails = emails.join(',');

      this.props.saveAction({ caseId, action, notify_phones, notify_emails });
    }
    else {
      this.props.saveAction({ caseId, action });
    }
    this.handleActionDialogClose();
  };

  handleActionDialogClose = event => {
    this.setState({
      open: false,
      phones: [],
      phonesInput: '',
      emails: [],
      emailsInput: ''
    });
  };

  handleTextChange = field => event => {
    if (event.target.value.includes(',')) {
      let newValue = Object.assign([], this.state[field]);
      newValue.push(...event.target.value.split(',').filter(value => value !== ''))
      this.setState({
        [field]: newValue,
        [field + 'Input']: ''
      });
    }
    else {
      this.setState({
        [field + 'Input']: event.target.value
      });
    }
  }

  handleChipDelete = (field, index) => event => {
    let newValue = Object.assign([], this.state[field]);
    newValue.splice(index, 1);
    this.setState({
      [field]: newValue
    });
  }

  render() {
    const action = actions[this.state.selectedAction];
    const { phones, phonesInput, emails, emailsInput } = this.state;
    return (
      <React.Fragment>
        <Actions
          actions={actions}
          onClick={this.handleActionDialogOpen}
        />
        <ActionDialog
          title={action.label}
          open={this.state.open}
          guards={this.props.guards}
          showGuards={this.state.showGuards}
          showNotifyCustomer={this.state.showNotifyCustomer}
          value={this.state.selectedGuard}
          phones={phones}
          phonesInput={phonesInput}
          emails={emails}
          emailsInput={emailsInput}
          onChange={this.handleGuardChange}
          onTextChange={this.handleTextChange}
          onClose={this.handleActionDialogClose}
          onSubmit={this.handleActionSubmit}
          onKeyDown={this.handleKeyDown}
          onDelete={this.handleChipDelete}
        />
      </React.Fragment>
    );
  }
}

ActionBoxContainer.propTypes = {
  guards: PropTypes.array,
  case: PropTypes.object,
  getGuards: PropTypes.func.isRequired,
  saveAction: PropTypes.func.isRequired,
};

ActionBoxContainer.defaultProps = {
  guards: [],
  case: {},
  getGuards: () => { },
  saveAction: () => { }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionBoxContainer);
