import axios from 'axios';
import moment from 'moment';

import { GeneralAdapter } from './adapter';
import { service } from './monitoring';
import { api } from './api';
import store from 'src/redux/store';
import { userActions, notificationActions } from 'src/redux/actions';

// Get all cameras
export const getAllCamerasAdapter = {
  toServer: data => {
    return data;
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    let { cameras, robots } = result;
    cameras = cameras ? cameras : [];
    cameras = cameras.map(camera => {
      return {
        id: camera.id,
        type: 'camera',
        name: camera.name,
        mode: camera.mode,
        user_id: camera.user_id,
        cameraState: GeneralAdapter.getCameraState(camera.state),
        box: camera.box
      };
    });
    robots = robots ? robots : [];
    robots = robots.map(robot => {
      return {
        id: robot.id,
        type: 'robot',
        name: robot.name,
        user_id: robot.user_id,
        details: robot.cameras,
        robotStatus: GeneralAdapter.getRobotStatus(robot),
        site: robot.site
      };
    });

    result = [...cameras, ...robots];

    return { error, result };
  }
};

export const getAllCameras = (adapter => async (data = {}, filters = {}) => {
  try {
    const query = mapFiltersToQuery(filters);
    const url = `/device/camera/?${query}`;
    data = adapter.toServer(data);
    let response = await service.get(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(getAllCamerasAdapter);

const getCameraCamerasAdapter = {
  toServer: data => {
    return data;
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = result ? result : [];
    result = result.map(camera => {
      return {
        id: camera.id,
        type: 'camera',
        name: camera.name,
        mode: camera.mode,
        user_id: camera.user_id,
        cameraState: GeneralAdapter.getCameraState(camera.state),
        box: camera.box
      };
    });

    return { error, result };
  }
}

export const getCameraCameras = (adapter => async (data = {}, filters = {}) => {
  try {
    const query = mapFiltersToQuery(filters);
    const url = `/camera/?${query}`;
    data = adapter.toServer(data);
    let response = await service.get(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(getCameraCamerasAdapter);

const getRobotCamerasAdapter = {
  toServer: data => {
    return data;
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = result ? result : [];
    result = result.map(robot => {
      return {
        id: robot.id,
        type: 'robot',
        name: robot.name,
        user_id: robot.user_id,
        details: robot.cameras,
        robotStatus: GeneralAdapter.getRobotStatus(robot),
        site: robot.site
      };
    });

    return { error, result };
  }
}

export const getRobotCameras = (adapter => async (data = {}, filters = {}) => {
  try {
    const query = mapFiltersToQuery(filters);
    const url = `/robot/?${query}`;
    data = adapter.toServer(data);
    let response = await service.get(url, data);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(getRobotCamerasAdapter);

const mapFiltersToQuery = filters => {
  const queries = [];

  // Robot Status
  if (filters.type === 'all' || filters.type === 'robot') {
    const { robotStatuses } = filters;
    GeneralAdapter.mapRobotStatuses(queries, robotStatuses);
  }

  // Camera State
  if (filters.type === 'all' || filters.type === 'camera') {
    const { cameraStates } = filters;
    GeneralAdapter.mapCameraStates(queries, cameraStates);
  }

  const { searchQuery } = filters;
  if (searchQuery)
    queries.push(`${encodeURIComponent('search')}=${encodeURIComponent(searchQuery)}`);

  // SiteId
  if (filters.siteId) {
    if (filters.type === 'all' || filters.type === 'camera') queries.push(`${encodeURIComponent('box__site_id')}=${encodeURIComponent(filters.siteId !== -1 ? filters.siteId : null)}`);
    if (filters.type === 'all' || filters.type === 'robot') queries.push(`${encodeURIComponent('site_id')}=${encodeURIComponent(filters.siteId !== -1 ? filters.siteId : null)}`);
  }

  return queries.join('&');
};

const getHLSAdapter = {
  toServer: data => {
    return {
      id: data.hls_id,
      rtsp_url: data.uri
    };
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    let { hls_url, hls_path } = result;
    hls_url = hls_path ? process.env.REACT_APP_BASE_HOST + '/' + hls_path : hls_url;
    return { error, hls_url };
  }
};

/*
  data: {
    hls_url: <string>
    hls_id: <id>
    uri: <rtsps uri>
  }
  */
const defaultHLSConfig = {
  withCredentials: true,
  headers: {
    'X-APP': 'TuringGuard'
  }
};

export const getCameraHLSURL = (adapter => async (data) => {
  const url = data.hls_url;
  const req_data = adapter.toServer(data);
  let response = await axios.post(
    url, req_data, defaultHLSConfig
  ).catch(error => {
    if (!error.response) throw error;
    //authorization error
    if (error.response.status === 401) store.dispatch(userActions.tokenExpired());
    //other errors
    else store.dispatch(notificationActions.addNotification(error.response.data.err.em));
  });
  response = adapter.toClient(response.data);
  const { error, hls_url } = response;
  if (error) throw error;
  return { hls_url };
})(getHLSAdapter);

const getHistoryQuery = data => {
  const queries = [];
  const { id, type, startTime, endTime } = data;
  if (type === 'camera') queries.push(`${encodeURIComponent('camera_id')}=${encodeURIComponent(id)}`);
  else queries.push(`${encodeURIComponent('robot_id')}=${encodeURIComponent(id)}`);
  if (startTime) {
    let startTimeUTC = moment.utc(startTime).format('YYYY-MM-DDTHH:mm:ss');
    queries.push(`${encodeURIComponent('started_at__gte')}=${encodeURIComponent(startTimeUTC)}`)
  }
  if (endTime) {
    let endTimeUTC = moment.utc(endTime).format('YYYY-MM-DDTHH:mm:ss');
    queries.push(`${encodeURIComponent('started_at__lt')}=${encodeURIComponent(endTimeUTC)}`)
  }
  return queries.join('&');
}

const getHistoryAdapter = {
  toServer: data => {
    return data;
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = result ? result : [];
    result = result.map(video => {
      return {
        startTime: moment(video.started_at),
        endTime: moment(video.ended_at),
        video: video.video,
      };
    });
    return { error, result };
  }
}

export const getCameraHistory = (adapter => async (data = {}, filters = {}) => {
  try {
    const query = getHistoryQuery(data);
    const url = `/camera_video/camera_videos/slot?${query}`;
    let response = await api.get(url);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(getHistoryAdapter);

export const getRobotHistory = (adapter => async (data = {}, filters = {}) => {
  try {
    const query = getHistoryQuery(data);
    const url = `/robot_video/robot_videos/slot?${query}`;
    let response = await api.get(url);
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(getHistoryAdapter);

const getCameraAlgosAdapter = {
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    let { error, result } = data;
    if (error) return { error };
    result = result && result.algos ? result.algos : [];
    if (typeof result === 'object') result = Object.values(result);
    return { error, result };
  }
};

export const getCameraAlgos = (adapter => async (boxId) => {
  try {
    const url = '/box/boxes/';
    let response = await api.get(url + boxId + '/get_meta');
    response = adapter.toClient(response.data);
    const { error, result } = response;
    if (error) throw error;
    return result;
  } catch (e) {
    throw e;
  }
})(getCameraAlgosAdapter);
