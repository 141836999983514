import qs from "qs";
import {api} from "src/services/api";

export async function getOrgsinfo() {
    try{
        const url = '/site_operation/site_operations';
        let ans = [];
        const response = await api.get(url);
        let posi = {};
        for (let i = 0; i < response.data.ret.results.length; i++){
            let orgid = response.data.ret.results[i].organization_id
            if (!posi.hasOwnProperty(orgid)){
                let tmpp = ans.length;
                posi[orgid] = tmpp
                ans = ans.concat([{id: orgid, sites:[], name: tmpp.toString()}])
            }
            ans[posi[orgid]].sites = ans[posi[orgid]].sites.concat([response.data.ret.results[i].site_id])
        }
        return ans
    }catch(e){
        console.log(e)
    }
  }

  export async function getOrgsinfo2() {
    const url = '/site_operation/site_operations';
    try{
        let ans = [];
        const response = await api.get(url);
        let posi = {};
        for (let i = 0; i < response.data.ret.results.length; i++){
            let orgid = response.data.ret.results[i].organization_id
            if (!posi.hasOwnProperty(orgid)){
                let tmpp = ans.length;
                posi[orgid] = tmpp
                ans = ans.concat([{id: orgid, sites:[], name: tmpp.toString()}])
            }
            ans[posi[orgid]].sites = ans[posi[orgid]].sites.concat([response.data.ret.results[i].site_id])
        }
        return ans
    }catch(e){
        console.log(e)
    }
  }