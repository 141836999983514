import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Hls from 'hls.js';
import CSSModuleStyles from './HlsPlayer.module.css';
import IconButton from '@material-ui/core/IconButton';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ExitFullscreenIcon from '@material-ui/icons/FullscreenExit';
import VolumeOnIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';

const styles = theme => ({
  video: {
    width: '100%'
  },
  button: {
    border: 0,
    backgroundColor: 'transparent',
    float: 'right',
  },
});

class HlsPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.player = null;
    this.hls = null;
    this.state = {
      isFullScreen: false,
      muted: false
    };
  }

  playerRef = player => {
    this.player = player;
  }

  videoContainerRef = videoContainer => {
    this.videoContainer = videoContainer;
  }

  videoControlsRef = videoControls => {
    this.videoControls = videoControls;
  }

  load = url => {
    if (Hls.isSupported()) {
      let that = this;
      this.hls = new Hls(this.props.hlsOptions);
      this.hls.on(Hls.Events.ERROR, (e, data) => {
        that.props.onError(e, data, this.hls, Hls)
      });
      this.hls.loadSource(url);
      this.hls.attachMedia(this.player);
      this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
        that.player.play();
      });
    }
    else if (this.player.canPlayType('application/vnd.apple.mpegurl')) {
      this.player.src = url;
      this.player.addEventListener('loadedmetadata', function () {
        this.player.play();
      });
    }
  }

  showControls = () => {
    this.videoControls.style.display = 'block';
  }

  hideControls = () => {
    this.videoControls.style.display = 'none';
  }

  fullscreenOnClickHandler = () => {
    if (this.videoContainer.requestFullscreen) {
      this.videoContainer.requestFullscreen();
    }
    else if (this.videoContainer.mozRequestFullScreen) { /* Firefox */
      this.videoContainer.mozRequestFullScreen();
    }
    else if (this.videoContainer.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      this.videoContainer.webkitRequestFullscreen();
    }
    else if (this.videoContainer.msRequestFullscreen) { /* IE/Edge */
      this.videoContainer.msRequestFullscreen();
    }
  }

  exitFullScreenOnClickHandler = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    }
    else if (document.webkitCancelFullScreen) { /* Chrome, Safari and Opera */
      document.webkitCancelFullScreen();
    }
    else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
  }

  fullScreenChangeHandler = () => {
    if (document.fullscreenElement || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement) {
      this.setState({ isFullScreen: true });
    }
    else {
      this.setState({ isFullScreen: false });
    }
  }

  addListeners = () => {
    document.addEventListener('fullscreenchange', this.fullScreenChangeHandler);
    document.addEventListener('webkitfullscreenchange', this.fullScreenChangeHandler);
    document.addEventListener('mozfullscreenchange', this.fullScreenChangeHandler);
    document.addEventListener('MSFullscreenChange', this.fullScreenChangeHandler);
  }

  removeListeners = () => {
    document.removeEventListener('fullscreenchange', this.fullScreenChangeHandler);
    document.removeEventListener('webkitfullscreenchange', this.fullScreenChangeHandler);
    document.removeEventListener('mozfullscreenchange', this.fullScreenChangeHandler);
    document.removeEventListener('MSFullscreenChange', this.fullScreenChangeHandler);
  }

  componentDidMount() {
    this.load(this.props.url);
    this.addListeners();
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.pause();
    }
    if (this.hls) {
      this.hls.destroy();
    }
    this.removeListeners();
  }

  render() {
    const { classes, url, onEnded, onError, onCanPlay } = this.props;
    const { muted, isFullScreen } = this.state;
    // const fullControls = process.env.REACT_APP_ENV === 'development';
    let fullControls = false

    return (
      <div
        className={!fullControls ? CSSModuleStyles.video_container : CSSModuleStyles.video_container_full_controls}
        ref={this.videoContainerRef}
        onMouseOver={!fullControls ? this.showControls : () => { }}
        onMouseOut={!fullControls ? this.hideControls : () => { }}
      >
        <video
          ref={this.playerRef}
          className={classes.video}
          src={url}
          preload='auto'
          muted={muted}
          controls={fullControls}
          autoPlay={true}
          onEnded={onEnded}
          onError={onError}
          onCanPlay={onCanPlay}
        >
        </video>
        {!fullControls &&
          <div className={CSSModuleStyles.video_controls} ref={this.videoControlsRef}>
            {isFullScreen ?
              <IconButton className={classes.button} onClick={this.exitFullScreenOnClickHandler}>
                <ExitFullscreenIcon htmlColor='#FFFFFF' />
              </IconButton>
              : <IconButton className={classes.button} onClick={this.fullscreenOnClickHandler}>
                <FullscreenIcon htmlColor='#FFFFFF' />
              </IconButton>
            }
            {muted ?
              <IconButton className={classes.button} onClick={() => this.setState({ muted: false })} >
                <VolumeOffIcon htmlColor='#FFFFFF' />
              </IconButton>
              : <IconButton className={classes.button} onClick={() => this.setState({ muted: true })} >
                <VolumeOnIcon htmlColor='#FFFFFF' />
              </IconButton>
            }
          </div>
        }
      </div>
    );
  }
}

export default withStyles(styles)(HlsPlayer);