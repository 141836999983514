import React from 'react';
import { withStyles, Grid, Button, TextField, MenuItem } from '@material-ui/core';

import { getText, getLangName } from 'src/utils/MultilingualLoader';
import { timezoneList } from 'src/utils/locale/timezone';
import { langList } from 'src/utils/locale/lang';

const styles = theme => ({
  row: {
    margin: '10px 0',
    width: '50%'
  },
  textfield: {
    width: '100%'
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

const AddCompanyPanel = ({
  classes,
  firstName,
  lastName,
  username,
  password,
  password2,
  lang,
  timezone,
  referral,
  inputErrors,
  onChange,
  onSubmit
}) => (
    <Grid
      container
      item
      direction="column"
      alignItems="center"
    >
      <Grid item className={classes.row}>
        <TextField
          className={classes.textfield}
          label={getText('first_name')}
          type="text"
          error={inputErrors.firstName ? true : false}
          helperText={inputErrors.firstName}
          name={'firstName'}
          value={firstName}
          onChange={onChange}
        />
      </Grid>
      <Grid item className={classes.row}>
        <TextField
          className={classes.textfield}
          label={getText('last_name')}
          type="text"
          error={inputErrors.lastName ? true : false}
          helperText={inputErrors.lastName}
          name={'lastName'}
          value={lastName}
          onChange={onChange}
        />
      </Grid>
      <Grid item className={classes.row}>
        <TextField
          className={classes.textfield}
          label={getText('email')}
          type="text"
          error={inputErrors.username ? true : false}
          helperText={inputErrors.username}
          name={'username'}
          value={username}
          onChange={onChange}
        />
      </Grid>
      <Grid item className={classes.row}>
        <TextField
          className={classes.textfield}
          label={getText('password')}
          type="password"
          error={inputErrors.password ? true : false}
          helperText={inputErrors.password}
          name={'password'}
          value={password}
          onChange={onChange}
        />
      </Grid>
      <Grid item className={classes.row}>
        <TextField
          className={classes.textfield}
          label={getText('confirm_password')}
          type="password"
          error={inputErrors.password2 ? true : false}
          helperText={inputErrors.password2}
          name={'password2'}
          value={password2}
          onChange={onChange}
        />
      </Grid>
      <Grid item className={classes.row}>
        <TextField
          className={classes.textfield}
          select={true}
          label={getText('lang')}
          type="text"
          error={inputErrors.lang ? true : false}
          helperText={inputErrors.lang}
          name={'lang'}
          value={lang}
          onChange={onChange}
        >
        {langList.map(lang =>
            <MenuItem key={lang} value={lang}>{getLangName(lang)}</MenuItem>
          )}
        </TextField>
      </Grid>
      <Grid item className={classes.row}>
        <TextField
          className={classes.textfield}
          select={true}
          label={getText('timezone')}
          type="text"
          error={inputErrors.timezone ? true : false}
          helperText={inputErrors.timezone}
          name={'timezone'}
          value={timezone}
          onChange={onChange}
        >
          {timezoneList.map(timezone =>
            <MenuItem key={timezone} value={timezone}>{timezone}</MenuItem>
          )}
        </TextField>
      </Grid>
      <Grid item className={classes.row}>
        <TextField
          className={classes.textfield}
          label={getText('referral')}
          type="text"
          error={inputErrors.referral ? true : false}
          helperText={inputErrors.referral}
          name={'referral'}
          value={referral}
          onChange={onChange}
        />
      </Grid>
      <Grid item className={classes.row}>
        <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          className={classes.submit}
          onClick={onSubmit}
        >
          {getText('submit')}
        </Button>
      </Grid>
    </Grid>
  );

export default withStyles(styles)(AddCompanyPanel);
