import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    margin: '12px 0'
  },
  tableCell: {
    borderStyle: 'none'
  },
});

class CaseDetail extends React.Component {
  render() {
    const { classes, details } = this.props;
    return (
      <ExpansionPanel
        defaultExpanded={true}
        className={classes.root}
        >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography variant="body1">
            {getText('details')}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Table>
            <TableBody>
              {
                details.map(detail => (
                  !detail.hide &&
                    <TableRow key={detail.title}>
                      <TableCell className={classes.tableCell} padding="default">
                        <Typography variant="body2">
                          {detail.title}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell} padding="default">
                        { 
                          detail.title !== ''
                          ? <Typography variant="body2">
                              {detail.content}
                            </Typography>
                          : detail.content
                        }
                      </TableCell>
                    </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

CaseDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  details: PropTypes.array.isRequired,
  onChangePriority: PropTypes.func.isRequired,
};


CaseDetail.defaultProps = {
  details: [],
  onChangePriority: () => () => {}
};

export default withStyles(styles)(CaseDetail);
