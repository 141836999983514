import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, DialogTitle, DialogContent, DialogActions, Dialog, RadioGroup, Radio,
  FormControl, FormLabel, FormControlLabel, Button, Chip, Input
} from '@material-ui/core';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    maxHeight: 800,
  },
  radioGroup: {
    minWidth: 250
  },
  formControl: {
    margin: theme.spacing(0),
  },
  contact: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-end'
  },
  label: {
    marginRight: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5)
  }
});

const ActionDialog = props => {
  const {
    classes, open, title, guards, value, showGuards, showNotifyCustomer,
    phones, phonesInput, emails, emailsInput, onTextChange, onSubmit, onChange, onClose, onDelete
  } = props;
  return (
    <Dialog
      className={classes.root}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        {title}
      </DialogTitle>
      {showGuards ?
        <DialogContent>
          {guards.length > 0 ?
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">{getText('officers')}</FormLabel>
              <RadioGroup
                className={classes.radioGroup}
                name="guard"
                value={`${value}`}
                onChange={onChange}
              >
                {guards.map((guard, index) => (
                  <FormControlLabel
                    key={index}
                    value={`${index}`}
                    control={<Radio />}
                    label={`${guard.first_name} ${guard.last_name}`}
                  />
                ))}
              </RadioGroup>
            </FormControl> : ''}
        </DialogContent> : null
      }
      {
        showNotifyCustomer ?
          <DialogContent>
            <FormControl className={classes.contact}>
              <FormLabel className={classes.label}>{getText('phones')}:</FormLabel>
              {
                phones.map((phone, index) =>
                  <Chip
                    className={classes.chip}
                    key={index}
                    label={phone}
                    onDelete={onDelete('phones', index)}
                    color="primary"
                    size="small"
                  />)
              }
              <Input
                id="phones"
                name="phones"
                value={phonesInput}
                onChange={onTextChange('phones')}
                inputProps={{style: {padding: 0}}}
              />
            </FormControl>
            <FormControl className={classes.contact}>
              <FormLabel className={classes.label}>{getText('emails')}:</FormLabel>
              {
                emails.map((email, index) =>
                  <Chip
                    className={classes.chip}
                    key={index}
                    label={email}
                    onDelete={onDelete('emails', index)}
                    color="primary"
                    size="small"
                  />)
              }
              <Input
                id="emails"
                name="emails"
                value={emailsInput}
                onChange={onTextChange('emails')}
                inputProps={{style: {padding: 0}}}
              />
            </FormControl>
          </DialogContent> : null
      }
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {getText('cancel')}
        </Button>
        <Button onClick={onSubmit} color="primary">
          {getText('submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ActionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  guards: PropTypes.array.isRequired,
  value: PropTypes.number.isRequired,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func
};

ActionDialog.defaultProps = {
  open: false,
  title: '',
  guards: [],
  value: 0,
  onSubmit: event => { },
  onClose: event => { },
  onChange: (event, value) => { },
};

export default withStyles(styles)(ActionDialog);
