import { manageConstants, caseConstants } from 'src/redux/constants'

export const mapManageTypeToConstant = type => {
  switch (type) {
    case 'company':
    case 'addcompany':
      return manageConstants.COMPANY;
    case 'guardadmin':
    case 'addguardadmin':
      return manageConstants.GUARD_ADMIN;
    case 'customer':
    case 'updtcustomer':
    case 'addcustomer':
      return manageConstants.CUSTOMER;
    case 'guard':
    case 'addguard':
      return manageConstants.GUARD;
    case 'operator':
    case 'addoperator':
      return manageConstants.OPERATOR;
    default:
      return;
  }
}

export const mapActionToActionName = {
  'false_alarm'    : caseConstants.FALSE_DETECTION,
  'no_harm'        : caseConstants.NO_ACTION_NEEDED,
  'call_police'    : caseConstants.CALL_POLICE,
  'dispatch_guard' : caseConstants.DISPATCH_OFFICER,
  'update_priority': caseConstants.UPDATE_PRIORITY,
  'dispatch_robot' : caseConstants.DISPATCH_ROBOT,
  'accept_case'    : caseConstants.ACCEPT_CASE,
  'start'          : caseConstants.START,
  'arrive'         : caseConstants.OFFICER_ARRIVED,
  'resolve_case'   : caseConstants.RESOLVE_CASE,
  'add_note'       : caseConstants.ADD_NOTE,
  'close'          : caseConstants.CLOSE_CASE,
  'viewed'         : caseConstants.VIEW_CASE,
  'new'            : caseConstants.CREATE_CASE,
  'update_types'   : caseConstants.UPDATE_TYPES,
  'notify_customer': caseConstants.NOTIFY_CUSTOMER
}
