import moment from 'moment';
import { getText } from 'src/utils/MultilingualLoader';

const ErrorDict = {
  RequiredError: {status: true, msg: getText('required')},
  JsonStringError: {status: true, msg: getText('not_json')},
  TimeSlotConflictError: {status: true, msg: getText('conflict_interval')},
  NotAllowSameTime: {status: true, msg: getText('same_interval')},
};

function CustomError(errorKey) {
  return ErrorDict[errorKey];
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
    if (JSON.parse(str) instanceof Array) {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
}

export function checkConfigs(algoConfig) {
  const validate = {};
  let errorCount = 0;
  ['rules'].forEach(key => {
    validate[key] = (algoConfig[key] || algoConfig[key] === false) ? null : CustomError('RequiredError');
    if(validate[key] !== null) {
      errorCount++;
    } 
    if (key === 'rules') {
      if (!isJsonString(algoConfig[key])) {
        validate[key] = CustomError('JsonStringError');
        errorCount++;
      }
    }
  });
  if (algoConfig['thresholds'] && !isJsonString(algoConfig['thresholds'])) {
    validate['thresholds'] = CustomError('JsonStringError');
    errorCount++;
  }
  validate['schedule'] = checkTimeslot(algoConfig['schedule']);
  validate['schedule'].forEach(item => {
    if (item[0] !== null || item[1] !== null) {
      errorCount++;
    }
  });
  return {errorCount, validateResult: validate};
}

export function checkValueChange(key, value) {
  if (!value && value !== false) {
    if (key !== 'thresholds') {
      return CustomError('RequiredError');
    }
  } else if (key === 'rules' || key === 'thresholds') {
    if (!isJsonString(value) && value) {
      return CustomError('JsonStringError');
    }
  }
  return null;
}

export function checkTimeslot(timeslots) {
  return timeslots.map((item, index) => {
    if (!item[0] || !item[1]) {
      return [item[0] ? null : CustomError('RequiredError'), item[1] ? null : CustomError('RequiredError')];
    } else if(item[0].toString() === 'Invalid Date' || item[1].toString() === 'Invalid Date') {
      return [item[0].toString() === 'Invalid Date' ? {msg: 'Invalid Date'} : null, item[1].toString() === 'Invalid Date' ? {msg: 'Invalid Date'} : null]
    } else if (item[0].getHours() * 60 + item[0].getMinutes() === item[1].getHours() * 60 + item[1].getMinutes()) {
      return [CustomError('NotAllowSameTime'), CustomError('NotAllowSameTime')];
    } else if (checkConflict(item, timeslots, index)) {
      return [CustomError('TimeSlotConflictError'), CustomError('TimeSlotConflictError')];
    }
    return [null, null];
  });
}

function checkConflict(target, timeslots, index) {
  for (let i = 0; i < timeslots.length; i++) {
    if (i === index) {
      continue;
    }
    if (checkSingleConflict(target, timeslots[i])) {
      return true;
    }
  }
  return false;
}

const MinutesOfOneDay = 24 * 60;

function checkSingleConflict(a, b) {
  const minutesm = [
    a[0].getHours() * 60 + a[0].getMinutes(),
    a[1].getHours() * 60 + a[1].getMinutes(),
  ];
  const minutesn = [
    b[0].getHours() * 60 + b[0].getMinutes(),
    b[1].getHours() * 60 + b[1].getMinutes(),
  ];
  const timeSlots = [];
  if (minutesm[0] > minutesm[1]) {
    timeSlots.push([minutesm[0], MinutesOfOneDay]);
    timeSlots.push([0, minutesm[1]]);
  } else {
    timeSlots.push(minutesm);
  }
  if (minutesn[0] > minutesn[1]) {
    timeSlots.push([minutesn[0], MinutesOfOneDay]);
    timeSlots.push([0, minutesn[1]]);
  } else {
    timeSlots.push(minutesn);
  }

  for (let i = 0; i < timeSlots.length; i += 1) {
    for (let j = 0; j < i; j += 1) {
      if (timeSlots[i][1] > timeSlots[j][0] && timeSlots[i][0] < timeSlots[j][1]) {
        return true;
      }
    }
  }

  return false;
}

export function transformCameraFromApiToLocal(
  camera
) {
  const data = {
    name: camera.name,
    uri: camera.uri,
    mode: camera.mode,
    defaultParams: [],
    rules: [],
    snapshot: camera.snapshot,
  };

  const defaultParams = camera.default_params || {};
  const paramKeys = Object.keys(defaultParams);
  paramKeys.forEach(key => {
    data.defaultParams.push({
      pk: `${Math.random()}`,
      key,
      value: JSON.stringify(defaultParams[key] || {}, null, 2),
    });
  });

  const rules = camera.rules;
  rules.forEach(rule => {
    const r = {
      pk: `${Math.random()}`,
      ...rule,
      params: JSON.stringify(rule.params || {}, null, 2),
      schedule: {
        type: 'daily',
        time_slots: (rule.schedule.time_slots || []).map(slot => {
          const start = slot && slot[0];
          const end = slot && slot[1];
          return {
            pk: `${Math.random()}`,
            start:
              start || start === 0
                ? moment()
                    .startOf('day')
                    .add(start, 'minute')
                : undefined,
            end:
              end || end === 0
                ? moment()
                    .startOf('day')
                    .add(end, 'minute')
                : undefined,
          };
        }),
      },
    };

    data.rules.push(r);
  });

  return {
    camera: data,
    paramsOption: paramKeys,
  };
}

export function transformCameraFromLocalToApi(data) {
  const camera = {
    name: data.name,
    uri: data.uri,
    mode: data.mode,
    default_params: {},
    rules: [],
  };

  data.defaultParams.forEach(v => {
    camera.default_params[v.key] = v.value ? JSON.parse(v.value) : {};
  });

  data.rules.forEach(rule => {
    const time_slots = (rule.schedule && rule.schedule.time_slots) || [];
    const r = {
      ...rule,
      params: rule.params ? JSON.parse(rule.params) : {},
    };
    delete r.pk;
    if (r.mode !== 'schedule') {
      r.schedule = {};
    } else {
      r.schedule = {
        type: 'daily',
        time_slots: time_slots.map(v => {
          return [v.start.hour() * 60 + v.start.minute(), v.end.hour() * 60 + v.end.minute()];
        }),
      };
    }
    camera.rules.push(r);
  });

  return camera;
}
