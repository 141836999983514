import { routineHistoryConstants } from "src/redux/constants";
import { routineHistoryService } from 'src/services';

export const getRoutineHistoryTime = robot_id => {
    const request = () => {
      return {
        type: routineHistoryConstants.ROBOT_GET_ROUTINE_HISTORY_TIME_REQUEST,
        robot: robot_id,
      };
    };
  
    const success = robotRoutineHistoryTime => {
      return {
        type: routineHistoryConstants.ROBOT_GET_ROUTINE_HISTORY_TIME_SUCCESS,
        robot: robot_id,
        robotRoutineHistoryTime: robotRoutineHistoryTime
      };
    };
  
    const failure = () => {
      return {
        type: routineHistoryConstants.ROBOT_GET_ROUTINE_HISTORY_TIME_FAILURE,
        robot: robot_id,
      };
    };
  
    return dispatch => {
      dispatch(request());
      routineHistoryService.getRoutineHistoryTime(robot_id)
        .then((robotRoutineHistoryTime) => {
          dispatch(success(robotRoutineHistoryTime));
        })
        .catch(err => {
          dispatch(failure());
        });
    };
  };

  export const selectRoutineHistoryTime = selectedRoutineHistoryTime => {
    return {
      type: routineHistoryConstants.ROUTINE_SELECTED_HISTORY_TIME,
      selectedRoutineHistoryTime
    };
  };


  export const getRoutineHistoryPage = (filters) => {
    const request = () => {
      return {
        type: routineHistoryConstants.ROBOT_GET_ROUTINE_HISTORY_PAGE_REQUEST,
        robotId: filters.robotId
      };
    };
  
    const success = results => {
      return {
        type: routineHistoryConstants.ROBOT_GET_ROUTINE_HISTORY_PAGE_SUCCESS,
        robotId: filters.robotId,
        robotRoutineHistoryPage:results
      };
    };
  
    const failure = () => {
      return {
        type: routineHistoryConstants.ROBOT_GET_ROUTINE_HISTORY_PAGE_FAILURE,
        robotId: filters.robotId
      };
    };
  
    return (dispatch) => {
      dispatch(request());
      routineHistoryService.getRoutineHistoryPages({filters})
        .then(results => {
          dispatch(success(results));
        })
        .catch(error => {
          dispatch(failure());
        });
    };
  };

  export const setRoutineHistoryFilter = filters => {
    return {
      type: routineHistoryConstants.SET_ROUTINE_HISTORY_FILTER,
      filters
    };
  };

  export const setPageLimit = limit => {
    return {
      type: routineHistoryConstants.SET_PAGE_LIMIT,
      limit
    };
  };