import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import DropDownSelectorVertical from 'src/components/Selectors/DropDownSelectorVertical';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({});

const priorities = [
  { value: 'all', label: getText('all') },
  { value: 'unassigned', label: getText('unassigned') },
  { value: 'low', label: getText('low') },
  { value: 'medium', label: getText('medium') },
  { value: 'high', label: getText('high') },
];

const PrioritySelector = props => {
  const { value, onChange } = props;
  return (
    <DropDownSelectorVertical
      title={getText('priority')}
      options={priorities}
      value={value}
      onChange={onChange} />
  );
};

PrioritySelector.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

PrioritySelector.defaultProps = {
  value: 'all',
  onChange: (event, value) => {}
};

export default withStyles(styles)(PrioritySelector);
