/* eslint-disable @typescript-eslint/camelcase */
import * as services from 'src/services/Vision/trackingSetting';
import {SET_TRACKING_SETTINGS} from 'src/redux/types/Vision/trackingSetting';
import store from 'src/redux/store';

const {dispatch, getState} = store;

export async function getTrackingSettings(){
  try {
    const currentTrackingSettings = getState().trackingSetting.trackingSettings;
    if (currentTrackingSettings) {
      return;
    }
    const res = await services.getTrackingSettings();
    dispatch({
      type: SET_TRACKING_SETTINGS,
      payload: {
        trackingSettings: res,
      },
    });
  } catch (error) {
    return;
  }
}