import React from 'react';
import { connect } from 'react-redux';
import {
  Typography, 
  Zoom,
  Dialog,
  DialogTitle,
  DialogContent,
  withStyles,
} from '@material-ui/core';
import { Pannellum } from "pannellum-react";
import CloseIcon from '@material-ui/icons/Close';

import { getText } from 'src/utils/MultilingualLoader';
import TargetList from './TargetList';
import { targetActions, notificationActions } from 'src/redux/actions';
import { targetServices } from 'src/services';
import {algoConfig} from '../InspectionConfigs/AlgoReferenceList/config';
import Inspections from '../Inspections';

const mapStateToProps = state => {
  const {list, pagination, filters} = state.target;
  return {
    list,
    pagination,
    filters,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getStoreList: (filters) => {
      dispatch(targetActions.getStoreList(filters));
    },
    addNotification: (msg) => {
      dispatch(notificationActions.addNotification(msg));
    },
    updateReferenceImage: (targetId, inspectionId) => {
      dispatch(targetActions.updateReferenceImage(targetId, inspectionId));
    },
  };
};

const styles = theme => ({
  root: {
    paddingBottom: 20
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    '&:hover': {
      cursor: 'pointer'
    }
  },
  diaTitle: {
    textAlign: 'center',
    paddingBottom: 0,
  },
  diaContent: {
    textAlign: 'center',
  },
  zoomedImg: {
    margin: 'auto',
    height: '90%',
    overflow: 'auto',
  },
});

class StoreListContainer extends React.Component {
  state = {
    selectedInspectionTarget: null,
    selectedReferenceTarget: null,
    referenceImage: null,
  }

  componentDidMount() {
    this.props.getStoreList({
      limit: 10,
    });
  }

  handleDisable = (targetId) => async () => {
    const {error} = await targetServices.disableAlgoConfig(targetId);
    if (!error) {
      this.props.addNotification(getText('operate_success'));
    }
  }

  handleEnable = (targetId) => async () => {
    const {error} = await targetServices.enableAlgoConfig(targetId);
    if (!error) {
      this.props.addNotification(getText('operate_success'));
    }
  }

  onSelectSnapshot = (inspection) => {
    const { selectedInspectionTarget } = this.state;
    this.props.updateReferenceImage(selectedInspectionTarget.id, inspection.id);
  }

  render() {
    const { list, pagination, filters, setDialogOpen, setTarget, classes } = this.props;
    const { selectedInspectionTarget, selectedReferenceTarget, referenceImage } = this.state;
    const category = Object.keys(algoConfig);
    return (
      <>
        {
          list && list.length > 0 ? <TargetList
            targets={list}
            pagination={pagination}
            setTarget={setTarget}
            filters={filters}
            setDialogOpen={setDialogOpen}
            category={category}
            showInspectionModal={target => {
              this.setState({
                selectedInspectionTarget: target,
              });
            }}
            showReferenceTarget={target => {
              targetServices.getReferenceImage(target.id).then(res => {
                if (!res.error) {
                  const result = res.result;
                  this.setState({
                    referenceImage: {
                      isPanoramic: result.panoramic,
                      snapshot: result.url,
                    }
                  });
                }
              });
              this.setState({
                selectedReferenceTarget: target
              });
            }}
            handleDisable={this.handleDisable}
            handleEnable={this.handleEnable}
          />
          : <Typography variant='body1' style={{padding: 16}}>{getText('no_data')}</Typography>
        }
        { selectedInspectionTarget && (
          <Inspections
            targetId={selectedInspectionTarget.id}
            onSelectSnapshot={this.onSelectSnapshot}
            onClose={() => this.setState({selectedInspectionTarget: null})}
          />
        ) }
        <Dialog
          TransitionComponent={Zoom}
          fullScreen
          className={classes.dialog}
          open={Boolean(selectedReferenceTarget)}
          onClose={() => {
            this.setState({
              selectedReferenceTarget: null,
            });
          }}
        >
          <DialogTitle className={classes.diaTitle}>
            {referenceImage && referenceImage.isPanoramic && <Typography variant="body1">{getText('360_image_description')}</Typography>}
            <CloseIcon
              className={classes.closeButton}
              onClick={() => {
                this.setState({
                  selectedReferenceTarget: null,
                });
              }}
            />
          </DialogTitle>
          <DialogContent className={classes.diaContent}>
            {referenceImage && referenceImage.isPanoramic ?
              <Pannellum
                image={referenceImage && referenceImage.snapshot}
                width="100%"
                height="100%"
                autoLoad
              />
              : <img className={classes.zoomedImg} src={referenceImage && referenceImage.snapshot} alt={getText('snapshot')} />}
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(StoreListContainer));;