import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import InspectionMap from './InspectionMap';
import InspectionFilter from './InspectionFilter';
import InspectionList from './InspectionList';

const styles = theme => ({
  hide: {
    display: 'none'
  }
});

const InspectionPanel = props => {
  const { classes, selectedRobot, showType } = props;
  
  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item>
        <InspectionFilter showType={showType} />
      </Grid>
      <Grid item className={showType !== 'list' ? classes.hide : ''}>
        <InspectionList selectedRobot={selectedRobot} />
      </Grid>
      <Grid item className={showType !== 'map' ? classes.hide : ''}>
        {/* <InspectionMap selectedRobot={selectedRobot} /> */}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(InspectionPanel);
