/* eslint-disable @typescript-eslint/naming-convention */
import {
  SET_SITE_OPTIONS,
  SET_SITES_WITH_CAMERAS,
  SET_SITES_WITH_CAMERAS_LOADING,
} from 'src/redux/types/Vision/site';

const initialState = {
  loading: false,
  siteOptions: [], // this is usually used for select options
  sitesWithCameras: [],
};


export default function siteReducer(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case SET_SITE_OPTIONS:
      return {
        ...state,
        siteOptions: payload.siteOptions,
      };
    case SET_SITES_WITH_CAMERAS:
      return {
        ...state,
        sitesWithCameras: payload.sitesWithCameras,
        loading: payload.loading,
      };
    case SET_SITES_WITH_CAMERAS_LOADING:
      return {
        ...state,
        loading: payload.loading,
      };
    default:
      return state;
  }
}
