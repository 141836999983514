import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button,Typography } from '@material-ui/core';

import { getText } from 'src/utils/MultilingualLoader';
import TimeRangeSelector from './TimeRangeSelector';

const styles = theme => ({

  save: {
    marginTop: -20,
  },
  input: {
    minWidth: 120,
    maxWidth: 300,
  },
  time:{
    marginLeft:20,
  },
  submitButton: {
    maxWidth: 100,  
    marginLeft: 20,
  },
  submitButtonCus: {
    maxWidth: 100,
    marginTop: 40,
    marginLeft: 20,
  },
  label: {
    color: 'white',
    textTransform: 'initial'
  },
});


const InspectionFilter = props => {
  const { classes,timeType, startTime, endTime,handleTimeTypeChange, handleStartTimeChange,
    handleEndTimeChange, handleFilterSave, } = props;
//for possible version of routineid
//   const handleChangeRoutineID = (event) => {
//     if(event.target.value.length === routineIds.length+1){
//     setCheckedRoutineIdArr([]);
//     }else if(event.target.value.includes('all')){
//     setCheckedRoutineIdArr(routineIds);
//     }else{
//     setCheckedRoutineIdArr(event.target.value);
//     }
// };
// let checkedAll=false
// if(checkedRoutineIdArr&&routineIds){
//   let selectedStr = checkedRoutineIdArr.sort().join('')
//   let routineStr = routineIds.sort().join('')
//   if(selectedStr===routineStr){
//     checkedAll = true;
//   }
// }
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      spacing={1}
    >
      <Grid container item xs={12} md={6} className={classes.time}>
        <Grid item xs={timeType === 'customized' ? 12 : 9}>
          <TimeRangeSelector
            timeType={timeType}
            startTime={startTime}
            endTime={endTime}
            onChangeTimeType={handleTimeTypeChange}
            onChangeStartTime={handleStartTimeChange}
            onChangeEndTime={handleEndTimeChange}
          />
          </Grid>
      </Grid>
      {
      //for possible version of routineid
      /* <Grid item xs={3} lg={3}  className={classes.routines}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">Routines</InputLabel>
          <Select className={classes.input}
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={checkedRoutineIdArr}
            onChange={handleChangeRoutineID}
            input={<Input />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
              <MenuItem key={'all'} value={'all'}>
                <Checkbox checked={checkedAll} color="primary" />
                <ListItemText primary={'all'}/>
              </MenuItem>
              <Divider />
            {routineIds&&routineIds.map((routineId) => (
              <MenuItem key={routineId} value={routineId}>
                <Checkbox checked={checkedRoutineIdArr.indexOf(routineId) > -1} color="primary" />
                <ListItemText primary={routineId} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid> */}
      <Grid item xs={12} md={5}  className={classes.save} >
      <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          className={timeType === 'customized'?classes.submitButtonCus:classes.submitButton}
          onClick={handleFilterSave}
        >
          <Typography variant="body2" className={classes.label}>
            {getText('submit')}
          </Typography>
        </Button>
        {/* <Button item='true' onClick={handleFilterSave} color="primary" autoFocus>
          {getText('submit')}
        </Button> */}
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(InspectionFilter);