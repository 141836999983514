/* eslint-disable @typescript-eslint/naming-convention */
import React, {useState, useRef, useEffect} from 'react';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import {Typography} from '@material-ui/core';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import FlagOutlined from '@material-ui/icons/FlagOutlined';
// import PersonIcon from '@material-ui/icons/DirectionsWalk';
import CarIcon from '@material-ui/icons/DirectionsCar';
import {FormattedMessage} from 'react-intl';
import clsx from 'clsx';
import {escapeRegExp, escape} from 'lodash';

// import Popover from 'src/components/Popover';
import {
  isPeople,
  isPeopleIntrusion,
  isVehicleIntrusion,
  isVehicle,
} from 'src/redux/actions/Vision/eventType';
//import {getLicensePlateBlacklist} from 'src/redux/actions/licensePlateBlacklist';
//import {LicensePlateBlacklist} from 'src/models/licensePlateBlacklist';
//import SetBlacklist from '../SetBlacklist';
// import {isInBlacklist} from '../utils';
import useStyles from './EventItem.style';
//import {getIsPeopleSearchEnabled} from 'src/utils/featureFlag';
//import {escapeHtml} from 'src/utils/dom';
import {
  vehicleColorOptions,
  vehicleTypeOptions,
  vehicleMakeOptions,
} from 'src/pages/Vision/Events/Filter/staticOption';


const EventItem = ({
  event,
  licensePlateBlacklist,
  lpr,
  mediumVisible = true,
  linkEnabled = true,
  hideTypeName = true,
  isDetailPage = false,
  onRefresh,
  onClick,
}) => {
  vehicleColorOptions.find( () => {});
  const classes = useStyles();
  const history = useHistory();
  const [blacklistVisible, setBlacklistVisible] = useState(false);
  const [over, setOver] = useState(false);

  const [timestamp, setTimestamp] = useState(
    moment(event.started_at).format('ddd MMM DD, YYYY [at] hh:mm:ss a')
  );
  const videoRef = useRef(null);

  const isFF = navigator.userAgent.indexOf('Firefox') > -1;

  const is_people_search_enabled = true;

  useEffect(() => {
    if (isFF) {
      const handleFullscreenChange = function() {
        if (document.fullscreenElement) {
          setOver(true);
        }
      };
      const videoDOM = videoRef.current;
      if  (videoDOM !== null) videoDOM.addEventListener('fullscreenchange', handleFullscreenChange);
      const handleFullscreenError = function(error) {
        console.log(error);
      };
      if(videoDOM !== null)videoDOM.addEventListener('fullscreenerror', handleFullscreenError);
      return () => {
        if (isFF) {
          if(videoDOM !== null)videoDOM.removeEventListener('fullscreenchange', handleFullscreenChange);
          if(videoDOM !== null)videoDOM.removeEventListener('fullscreenerror', handleFullscreenError);
        }
      };
    }
  }, []);

  const handleMouseEnter = () => {
    if (snapshot && video) {
      setOver(true);
      if (isFF) {
        if(videoRef.current !== null)videoRef.current.fastSeek(0);
        if(videoRef.current !== null)videoRef.current.play();
      }
    } else if (video) {
        if(videoRef.current !== null)videoRef.current.fastSeek(0);
        if(videoRef.current !== null)videoRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    if (snapshot && video) {
      if (over) {
        setTimeout(() => {
          if (!document.fullscreenElement) {
            setOver(false);
          }
        }, 100);
      } else {
        setOver(false);
      }
      setTimestamp(moment(event.started_at).format('ddd MMM DD, YYYY [at] hh:mm:ss a'));
    } else if (video) {
      setTimestamp(moment(event.started_at).format('ddd MMM DD, YYYY [at] hh:mm:ss a'));
    }
  };

  const handleClose = (changed)  => {
    setBlacklistVisible(false);
    if (changed) {
      //getLicensePlateBlacklist({}, true);
      onRefresh && onRefresh();
    }
  };

  const handleTimeUpdate = () => {
    setTimestamp(
      moment(
        moment(event.started_at).valueOf() + Math.floor(((videoRef.current.currentTime === undefined) ? 0 : videoRef.current.currentTime) * 1000)
      ).format('ddd MMM DD, YYYY [at] hh:mm:ss a')
    );
  };

  // const content: React.FC = () => (
  //   <MenuList style={{outline: 'none'}}>
  //     <MenuItem
  //       onClick={(): any => {
  //         setBlacklistVisible(true);
  //       }}
  //     >
  //       <FlagOutlined className={classes.menuItemIcon} />
  //       <FormattedMessage id="clipsView.LPRthumbnail.actionsMenu.actionLabel" />
  //     </MenuItem>
  //   </MenuList>
  // );

    //const snapshot = event.mediums?.find(v => v.name === 'snapshot');
    const snapshot = (event.mediums === undefined) ? undefined : event.mediums.find(v => v.name === 'snapshot');
    //const video = event.mediums?.find(v => v.name === 'video');
    const video = (event.mediums === undefined) ? undefined : event.mediums.find(v => v.name === 'video');

    // const testTypes = (event.metadata?event.metadata.license_plates : [])
    // console.log("event:", event)
    // console.log("testTypes", testTypes)
    // console.log("video", video)
    // console.log("snapshot", snapshot)
    
    const vehicleTypes = ((event.metadata ? event.metadata.license_plates : undefined) || []).filter(
    lp =>
      vehicleColorOptions.find(v => v.value.includes(lp.color ? lp.color.color : undefined)) ||
      vehicleTypeOptions.find(v => v.value.includes(lp.vehicle ? lp.vehicle.type : undefined)) ||
      vehicleMakeOptions.find(v => v.value === lp.model_make ? lp.model_make.make : undefined)
    );

   
  const c1 = (item) => {
    const f = v => v.value.includes(item.color ? event.metadata.color:undefined);
    const answer = vehicleColorOptions.find(f);
    return (answer ? answer.label : undefined) || '';
}

  const c2 = (item) => {
    const f = v => v.value.includes(item.vehicle ? item.vehicle.type:undefined);
    const answer = vehicleTypeOptions.find(f);
    return (answer ? answer.label : undefined) || '';
  }

  const c3 = (item) => {
    const f = v => v.value === item.model_make.make;
    const answer = vehicleMakeOptions.find(f);
    return (answer ? answer.label : undefined) || '';
  }
  return (
    <>
      <Typography
        component="div"
        className={classes.root}
        style={{cursor: linkEnabled ? 'pointer' : 'default'}}
        onClick={
          linkEnabled
            ? onClick
              ? onClick
              : () => {
                  history.push(`/events/${event.id}`);
                }
            : undefined
        }
      >
        {mediumVisible &&
          (snapshot || video ? (
            <div
              className={classes.media}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className={classes.mediaWrap}>
                {snapshot ? (
                  isFF ? (
                    <img
                      style={{display: !over ? 'block' : 'none'}}
                      className={classes.snapshot}
                      //src={snapshot?.file.url}
                      src={snapshot?snapshot.file.url:""}
                      alt="event snapshot"
                    />
                  ) : (
                    !over && (
                      <img
                        className={classes.snapshot}
                        src={snapshot?snapshot.file.url:""}
                        alt="event snapshot"
                      />
                    )
                  )
                ) : null}
                {video ? (
                  snapshot ? (
                    over &&
                    (isFF ? (
                      <video
                        ref={videoRef}
                        onTimeUpdate={handleTimeUpdate}
                        className={classes.snapshotFF}
                        //src={video?.file.url}
                        src={video?video.file.url:""}
                        controls
                        autoPlay
                        muted
                        preload="auto"
                        disablePictureInPicture
                      />
                    ) : (
                      <video
                        ref={videoRef}
                        onTimeUpdate={handleTimeUpdate}
                        className={classes.snapshot}
                        //src={video?.file.url}
                        src={video?video.file.url:""}
                        controls
                        autoPlay
                        muted
                        preload="auto"
                      />
                    ))
                  ) : (
                    <video
                      ref={videoRef}
                      onTimeUpdate={handleTimeUpdate}
                      className={classes.snapshot}
                      //src={video?.file.url}
                      src={video?video.file.url:""}
                      controls
                      preload="auto"
                    />
                  )
                ) : null}
              </div>
              <span className={classes.timeTxt}>{timestamp}</span>
            </div>
          ) : (
            <Typography component="div" className={classes.noMedia}>
              <p>No Media</p>
            </Typography>
          ))}

        <div className={classes.description}>
          <div className={classes.text} style={{width: '100%'}}>
            {is_people_search_enabled && event.types.some(type => isPeople(type.name)) && (
              <div className={`${classes.label} ${classes.breakWord}`}>
                {event.tracking_objects && event.tracking_objects
                  .map(v => v.data.person_name || '')
                  .filter(v => Boolean(v))
                  .join(', ')}

                {event.tracking_objects &&
                  event.tracking_objects.some(v => !v.data.person_name) && (
                    <span style={{fontWeight: 300}}>{` Unnamed (${
                      event.tracking_objects.filter(v => !v.data.person_name).length
                    })`}</span>
                  )}
              </div>
            )}
            {(event.types ? event.types.some(type => isVehicle(type.name)) : undefined) && (
              <>


                <div
                  className={
                    lpr
                      ? clsx(classes.label, classes.hideTxt, classes.lprLabel)
                      : clsx(classes.label, classes.hideTxt)
                  }
                >
                  {(event.metadata ? event.metadata.license_plates || [] : undefined)
                    .filter(lp => lp.text)
                    .map((v, i) => (
                      <span key={v.text}>
                        {i > 0 && v.text ? ', ' : ''}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: lpr
                              ? (v.text)
                                  .toUpperCase()
                                  .replace(
                                    new RegExp(escapeRegExp(lpr.toUpperCase()), 'g'),
                                    `<span style="font-weight: 500; color: rgba(0, 0, 0, 0.87)">${escape(
                                      lpr.toUpperCase()
                                    )}</span>`
                                  )
                              : (v.text).toUpperCase(),
                          }}
                        >
                        </span>
                      </span>
                    ))}
                </div>


                {vehicleTypes.length > 0 && (
                  <div className={`${classes.label} ${classes.hideTxt}`}>
                    <CarIcon className={classes.icon} />
                    {vehicleTypes.map((item, i) => (
                      <span key={i} className={classes.vehicle}>
                        {`${c1(item)} ${c2(item)} ${c3(item)}${
                        (item.color.color || item.vehicle.type || item.model_make.make) &&
                        i < vehicleTypes.length - 1
                          ? ', '
                          : ''
                      }`}
                      </span>
                    ))}
                  </div>
                )}
              </>
            )}
            {event.types&&event.types.some(type => isPeopleIntrusion(type.name)) && !hideTypeName && (
              <div className={classes.label}>
                <FormattedMessage id="alertsView.alertsDropdown.peopleIntrusionAlertType" />
              </div>
            )}
            {event.types&&event.types.some(type => isVehicleIntrusion(type.name)) && !hideTypeName && (
              <div className={classes.label}>
                <FormattedMessage id="alertsView.alertsDropdown.vehicleIntrusionAlertType" />
              </div>
            )}
            <div className={classes.location}>
              {/* {event.types?.some(type => isPeopleIntrusion(type.name)) && (
                <PersonIcon className={classes.icon} />
              )}
              {event.types?.some(type => isVehicleIntrusion(type.name)) && (
                <CarIcon className={classes.icon} />
              )} */}
              {event.site && event.site.name}
              <i className={classes.dot} />
              {event.camera && event.camera.name}
            </div>
            {isDetailPage && (
              <div className={classes.location}>
                {moment(event.started_at).format('MMM DD, YYYY [at] hh:mm:ss a')}
              </div>
            )}
          </div>
          {/* {event.types?.some(type => isLPR(type.name)) && (
            <div className={classes.action}>
              <Popover
                trigger="click"
                content={content}
                transformOrigin={{vertical: 'top', horizontal: 20}}
              >
                <MoreVertIcon className={classes.actionIcon} />
              </Popover>
            </div>
          )} */}
        </div>
      </Typography>
      
    </>
  );
};

export default EventItem;
