import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

import InspectionDetails from './InspectionDetails';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = (state) => {
  return {
    selectedWaypoint: state.inspection.selectedWaypoint
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}

class InspectionDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardWidth: window.innerWidth - 200 - 200 - 24 - 16
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeCard);
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.resizeCard);
  }

  resizeCard = () => {
    const width = window.innerWidth - 200 - 200 - 24 - 16;
    this.setState({
      cardWidth: width
    });
  }

  render() {
    const { selectedWaypoint } = this.props;
    const { cardWidth } = this.state;
    return (
      selectedWaypoint && selectedWaypoint.inspections ? 
      <InspectionDetails
        inspections={selectedWaypoint.inspections}
        cardWidth={cardWidth}
      />
      : <Typography>{getText('no_waypoint_selected')}</Typography>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionDetailsContainer);
