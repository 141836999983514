import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    flex: 1,
    overflow: 'auto',
  },
  listItem: {
    paddingLeft: 5
  },
  selected: {
    //!important to override :hover
    backgroundColor: 'rgba(76,127,114,0.25) !important'
  },
  primary: {
    display: 'flex',
    flexDirection: 'row',
  },
});

const SiteManageList = props => {
  const { classes, listItems, pagePath, handleOnclick } = props;
  return (
    <List
      component="nav"
      className={classes.root}>
      {
        listItems.map((item, index) => (
            <ListItem
              key={index}
              className={classes.listItem}
              disableGutters
              button={true}
              classes={{
                selected: classes.selected
              }}
              selected={pagePath === item.path}
              onClick={handleOnclick(item)} >
              <ListItemText
                disableTypography={true}
                primary={<Typography variant='body1'>{item.label}</Typography>}
              />
            </ListItem>
        ))
      }
    </List>
  );
};

SiteManageList.propTypes = {
  listItems: PropTypes.array,
  pagePath: PropTypes.string,
  handleOnclick: PropTypes.func
};

SiteManageList.defaultProps = {
  listItems: [],
  pagePath: '',
  handleOnclick: () => {}
};

export default withStyles(styles)(SiteManageList);
