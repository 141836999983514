import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Loader from 'react-loader-advanced';

import InspectionDetails from './InspectionDetails';
import InspectionMapStage from './InspectionMapStage';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  title: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  banner: {
    zIndex: 1,
    backgroundColor: '#FFFFFF'
  },
  refreshButton: {
    padding: '0 12px',
  },
  loader: {
    zIndex: 0,
    minHeight: 200
  }
});

class InspectionMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      imageSize: {}
    };
  }

  loadImage = mapSrc => {
    const image = new window.Image();
    image.src = mapSrc;
    image.onload = () => {
      // setState will redraw layer
      // because "image" property is changed
      this.setState({
        image: image,
        imageSize: {
          width: image.width,
          height: image.height
        },
      });
    };
  };

  getImageLocation = (mapConfig, imageSize, displaySize) => (x, y) => {
    if (!mapConfig) return { x: 0, y: 0 };
    const { origin, resolution: r } = mapConfig;
    if (!origin || !r) return { x: 0, y: 0 };
    let imageX = ((x - origin[0]) / r) / imageSize.width * displaySize.width;
    let imageY = (imageSize.height - (y - origin[1]) / r) / imageSize.height * displaySize.height;
    if (isNaN(imageX) || isNaN(imageY)) return {x: null, y: null};
    return {
      x: imageX,
      y: imageY,
    };
  };

  componentDidMount() {
    if (this.props.mapSrc) {
      this.loadImage(this.props.mapSrc);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.mapSrc !== prevProps.mapSrc) {
      this.loadImage(this.props.mapSrc);
    }
  }

  render() {
    const { classes, waypoints, mapConfig, mapLoading, mapError, onRefresh, cardWidth } = this.props;
    const { image, imageSize } = this.state;
    const mapWidth = window.innerWidth < 1280 ? cardWidth : cardWidth * 3 / 4;
    const mapHeight = window.innerHeight - 64 - 48 - 160;

    return (
      <Card className={classes.root}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={0}
        >
          <Grid
            item
            xs={12}
            lg={9}
            direction="column"
            container={true}
            justify="flex-start"
            spacing={0}
          >
            <Grid item={true} className={classes.banner}>
              <Typography variant="body1" className={classes.title}>
                {getText('map')}
                <IconButton className={classes.refreshButton} onClick={onRefresh}><RefreshIcon /></IconButton>
              </Typography>
            </Grid>
            <Grid item={true}>
              <Loader
                className={classes.loader}
                show={mapLoading || mapError ? true : false}
                message={mapError || getText('loading')}
                hideContentOnLoad
              >
                {
                  mapLoading === false && !mapError &&
                  <CardMedia
                    src="img"
                  >
                    <InspectionMapStage
                      mapImage={image}
                      waypoints={waypoints}
                      mapWidth={mapWidth}
                      mapHeight={mapHeight}
                      getImagePosition={this.getImageLocation(mapConfig, imageSize, {
                        width: mapWidth,
                        height: mapHeight
                      })}
                    />
                  </CardMedia>
                }
              </Loader>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3}>
            <InspectionDetails />
          </Grid>
        </Grid>
      </Card>
    );
  }
};

InspectionMap.propTypes = {
  mapLoading: PropTypes.bool.isRequired,
  mapError: PropTypes.any,
  mapSrc: PropTypes.string,
  mapConfig: PropTypes.shape({
    origin: PropTypes.arrayOf(PropTypes.number),
    resolution: PropTypes.number,
  }).isRequired,
  onRefresh: PropTypes.func,
};

InspectionMap.defaultProps = {
  mapLoading: false,
  mapError: null,
  mapSrc: null,
  mapConfig: {},
  onRefresh: () => { },
};

export default withStyles(styles)(InspectionMap);
