import React from "react";

import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    margin: "12px 0",
  },
  tableCell: {
    borderStyle: "none",
  },
});

const ExpansionPage = (props) => {
  const { classes, title, children } = props;

  return (
    <ExpansionPanel className={classes.root} defaultExpanded={true}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          {title}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default withStyles(styles)(ExpansionPage);
