export const GET_CAMERAS_REQUEST = 'CAMERA_GET_CAMERAS_REQUEST';
export const GET_CAMERAS_SUCCESS = 'CAMERA_GET_CAMERAS_SUCCESS';
export const GET_CAMERAS_FAILURE = 'CAMERA_GET_CAMERAS_FAILURE';
export const DEVICE_REQUEST = 'CAMERA_DEVICE_REQUEST';
export const GET_CAMERA_HLS_URL = 'CAMERA_GET_HLS_URL';
export const GET_CAMERA_HLS_URL_REQUEST = 'CAMERA_GET_CAMERA_HLS_URL_REQUEST';
export const SELECT_CAMERA = 'CAMERA_SELECT_CAMERA';
export const SET_FILTER = 'CAMERA_SET_FILTER';
export const SELECT_ALL_CAMERAS = 'CAMERA_SELECT_ALL_CAMERAS';
export const SORT_CAMERAS = 'CAMERA_SORT_CAMERAS';
export const UPDATE_CAMERAS = 'CAMERA_UPDATE_CAMERAS';
export const SET_LINE_SIZE = 'CAMERA_SET_LINE_SIZE';
export const GET_HISTORY_REQUEST = 'CAMERA_GET_HISTORY_REQUEST';
export const GET_HISTORY_SUCCESS = 'CAMERA_GET_HISTORY_SUCCESS';
export const GET_HISTORY_FAILURE = 'CAMERA_GET_HISTORY_FAILURE';
export const CAMERA_GET_ALGOS_REQUEST = 'CAMERA_GET_ALGOS_REQUEST';
export const CAMERA_GET_ALGOS_SUCCESS = 'CAMERA_GET_ALGOS_SUCCESS';
export const CAMERA_GET_ALGOS_FAILURE = 'CAMERA_GET_ALGOS_FAILURE';
export const UPDATE_CAMERAS_FROM_WS = 'CAMERA_UPDATE_CAMERAS_FROM_WS';
