import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
// import {TrackingObjectOption} from 'src/models/employee';

export default function TrackingObjectOptionRender({
  trackingObjectOption,
}) {
  return (
    <Grid container>
      <Grid item>
        <PersonIcon color="action" />
      </Grid>
      <Grid item>
        <Typography style={{margin: '0 10px'}}>
          {trackingObjectOption.tracking_object_name}
        </Typography>
      </Grid>
      <Grid item style={{height: 18, width: 1, background: '#d0d0d0'}} />
      <Grid item>
        <Typography style={{margin: '0 10px'}} color="textSecondary">
          ID: {trackingObjectOption.tracking_object_id}
        </Typography>
      </Grid>
    </Grid>
  );
}
