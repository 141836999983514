import { getText } from 'src/utils/MultilingualLoader';

export const statusOptions = [
  {
    value: 'all',
    label: getText('all'),
  },
  {
    value: 'ready',
    label: getText('review_pending'),
  },
  {
    value: 'done',
    label: getText('review_finished'),
  },
  {
    value: 'finished',
    label: getText('review_ok'),
  },
  {
    value: 'failed',
    label: getText('review_nok'),
  }
];

export const statusObj = {
  ready: getText('review_pending'),
  finished: getText('review_ok'),
  failed: getText('review_nok'),
}