/**
 * Returns on an img element with cropped dataUrl.
 * @param {target} TrackingTarget An TrackingObject that contains bounding_box and img_url.
 * @param {className} string classes wants to add to image element.
 * @return {HTMLImageElement} an img element with cropped dataUrl.
 */

import React, {useEffect, useState, useRef} from 'react';
import {AccountBox as PersonIcon} from '@material-ui/icons';

// import {TrackingTarget} from 'src/models/employee';
import {cropImg} from 'src/utils/Vision/boundingBox';


function CroppedImage({target, className, alt}) {
  const [imgUrl, setImgUrl] = useState(target.thumbnail_url || '');
  const [retryCount, setRetryCount] = useState(0);

  const destroyed = useRef(false);

  useEffect(() => {
    if (!imgUrl) {
      cropFace();
    }
    return () => {
      destroyed.current = true;
    };
  }, []);

  function handleError() {
    if (retryCount < 3) {
      cropFace();
      setRetryCount(retryCount + 1);
    }
  }

  function cropFace() {
    if (!target.img_url) return;
    cropImg(target)
      .then(url => {
        !destroyed.current && setImgUrl(url);
      })
      .catch(e => {
        console.log('Load Image failed!', e);
      });
  }

  return imgUrl ? (
    <img className={className} src={imgUrl} alt={alt} onError={handleError} />
  ) : (
    <PersonIcon color="action" className={className} />
  );
}

export default CroppedImage;
