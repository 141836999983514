import qs from "qs";
import {event_api} from 'src/services/Vision/api';
import { isFacialRecognition, isLPR } from "src/redux/actions/Vision/eventType";
import store from 'src/redux/store';

const {dispatch, getState} = store;

export function trackingObjectApiDataForEvent2Adapter(data) {
  return {
    note: data.note,
    enabled_tracking: data.enabled_tracking,
    organization_id: data.organization,
    event_id: data.event_id,
    imgs: data.imgs,
    data: data.data,
    phone_number: data.data.phone_number || data.phone_number,
    person_id: data.data.person_id,
    first_name: data.first_name,
    middle_name: data.middle_name,
    last_name: data.last_name,
    rfid: data.rfid,
    site_id: data.site,
  };
}

export function trackingObjectEvent2Adapter(data) {
  return {
    ...data,
    site: data.site_id,
    img_url: data.img ? data.img.url : null,
    thumbnail_url: data.thumbnail ? data.thumbnail.url : null,
  };
}

export function event2Adapter(event) {
  const mediums = [];
  (event.mediums || []).forEach((medium) => {
    const name = medium.name;
    if (medium.files) {
      medium.files.forEach((file) => {
        mediums.push({
          id: file.Key,
          name,
          file: {
            content_type: file.file ? (file.file.meta ? file.file.meta.content_type : null) : null,
            file_size: file.file ? (file.file.meta ? file.file.meta.file_size : null) : null,
            url: file.file ? file.file.url : null,
            thumb_url: file.file ? file.file.thumb_url : null,
          },
        });
      });
    }
  });

  const parseSnapShotUrl = () => {
    const snapList = mediums.filter(item => item.name === 'snapshot');
    if (!snapList.length) return undefined;
    return snapList[0].file ? snapList[0].file.url : undefined;
  };

  const parseThumbUrl = () => {
    const snapList = mediums.filter(item => item.name === 'snapshot');
    if (!snapList.length) return undefined;
    return snapList[0].file ? snapList[0].file.thumb_url : undefined;
  };

  const caseItem = {
    id: event._id,
    event: {
      id: event._id,
      camera: event.camera,
      ended_at: event.ended_at,
      mediums,
      site: event.camera.box.site,
      source: event.source,
      started_at: event.started_at,
      ipc_time: event.ipc_time,
      tracking_objects: event.tracking_objects
        ? event.tracking_objects
            .filter((objects) => objects !== null)
            .map(trackingObjectEvent2Adapter)
        : undefined,
      types: event.type ? event.type.split(',').map((type) => ({name: type, label: type})) : null,
      metadata: {
        license_plates: event.license_plates,
        face_tracking: event.face_tracking,
      },
    },
    status: event.status,
    note: event.notes && event.notes.length > 0 ? event.notes[event.notes.length - 1].note : '',
    noteTime: (event.notes ? event.notes.length > 0 : null) ? event.notes[event.notes.length - 1].created_at : '',
    author:
      event.notes ? event.notes.length > 0 : null
        ? `${event.notes[event.notes.length - 1].user.first_name} ${
            event.notes[event.notes.length - 1].user.last_name
          }`.trim()
        : '',
    viewed: event.viewed,
    snapshotUrl: parseSnapShotUrl(),
    thumbUrl: parseThumbUrl(),
  };
  return caseItem;
}

export const event2ListAdapter = {
  toServer: (data, isAlert) => {
    const param = {
      type: !data.type ? undefined : data.type,
      org_id: !data.org ? undefined : data.org,
      site_id: !data.site ? undefined : data.site,
      camera_id: !data.camera ? undefined : data.camera,
      time_after:
        data.range && data.range.range && data.range.range[0]
          ? data.range.range[0].clone().utc().format()
          : undefined,
      time_before:
        data.range && data.range.range && data.range.range[1]
          ? data.range.range[1].clone().utc().format()
          : undefined,
      tracking_object_id: data.tracking_object_id || undefined,
      ordering: data.ordering || "-started_at",
      offset: (data.page || 0) * data.limit,
      limit: data.limit,
      source: "camera",
      vehicle_color:
        data.vehicle_color && data.vehicle_color !== "all"
          ? data.vehicle_color
          : undefined,
      vehicle_type:
        data.vehicle_type && data.vehicle_type !== "all"
          ? data.vehicle_type
          : undefined,
      vehicle_make:
        data.vehicle_make && data.vehicle_make !== "all"
          ? data.vehicle_make
          : undefined,
      alert: isAlert === undefined ? undefined : isAlert ? 1 : 0,
    };
    // only support single select
    switch (data.status) {
      case "new":
        param.status = "new";
        break;
      case "false_alarm":
        param.status = "false_alarm";
        break;
    }

    if (isFacialRecognition(data.type)) {
      if (data.face) {
        param.tracking_object_id = data.face.id || undefined;
      } else {
        // param.search = data.tracking_object_key || undefined;
        // param.identifiers = data.tracking_object_key ? 'name' : undefined;
        param.tracking_object_id = data.tracking_object_key || undefined;
      }
    }
    if (isLPR(data.type)) {
      param.search = data.lpr?data.lpr.toUpperCase() : undefined;
      param.identifiers = data.lpr ? "vehicle_number" : undefined;
    }

    return param;
  },
  toClient: (res, params) => {
    const list = (res.results || []).map(event2Adapter);
    return {
      total:
        typeof res.count === "number" && res.count
          ? res.count
          : res.next
          ? -1
          : (params.page || 0) * params.limit + list.length,
      hasNext: res.next,
      list,
    };
  },
};

export async function resetFalseAlarm(params) {
  const {dispatch, getState} = store;
  const {role} = getState().user;
  try {
    if (role === 'monitoring_operator' ) {
      params.user_org_id = 304;
      const url =  `/event/${params.id}`;
      const response = await event_api.put(url, params);
      return event2Adapter(response.data.ret);
    }else{
      const url = `/event/${params.id}`;
      const response = await event_api.put(url, params);
      return event2Adapter(response.data.ret);
    };
  } catch (e) {
    throw e;
  }
}

export async function markFalseAlert(params) {
  const {dispatch, getState} = store;
  const {role} = getState().user;
  try {
    if (role === 'monitoring_operator' ) {
      params.user_org_id = 304;
      // console.log(params)
      const url =  `/event/${params.id}`;
      const response = await event_api.put(url, params);
      return event2Adapter(response.data.ret);
    }else{
      const url = `/event/${params.id}`;
      const response = await event_api.put(url, params);
      return event2Adapter(response.data.ret);
    };
  } catch (e) {
    throw e;
  }
}

export async function addNote(params) {
  const {dispatch, getState} = store;
  const {role} = getState().user;
  try {
    if (role === 'monitoring_operator' ) {
      params.user_org_id = 304;
      const url =  `/event/${params.id}`;
      const response = await event_api.put(url, params);
      return event2Adapter(response.data.ret);
    }else{
      const url = `/event/${params.id}`;
      const response = await event_api.put(url, params);
      return event2Adapter(response.data.ret);
    };
  } catch (e) {
    throw e;
  }
}

export async function getCaseDetail(id) {
  const {dispatch, getState} = store;
  const {role} = getState().user;
  try {
    if (role === 'monitoring_operator' ) {
      const url = `/event/details/${id}?org_id=304`;
      const response = await event_api.get(url);
      const ans = event2Adapter(response.data.ret)
      return ans;
    }else{
      const url = `/event/details/${id}`;
      const response = await event_api.get(url);
      const ans = event2Adapter(response.data.ret)
      return ans;
    };
  } catch (e) {
    throw e;
  }
};

export async function updateCase2(params){
  const {dispatch, getState} = store;
  const {role} = getState().user;
  try {
    if (role === 'monitoring_operator' ) {
      const url = `/event/${params.id}`;
      params.action = 'false_alarm';
      params.user_org_id=304;
      const response = await event_api.put(url, params);
      return event2Adapter(response.data.ret);
    }else{
      const url =  `/event/${params.id}`;
      const response = await event_api.put(url, params);
      return event2Adapter(response.data.ret);
    };
  } catch (e) {
    throw e;
  }
}

export async function getEventList(params) {
  const {role} = getState().user;
  try {
    if (role === "monitoring_operator" && (params.org === "" || params.site === "")){
      return {
        total: 0,
        hasNext: false,
        results: [],
      };
    }else{
      const url = `/event/events?${qs.stringify(
        event2ListAdapter.toServer(params)
      )}`;
      const response = await event_api.get(url);
      const res = event2ListAdapter.toClient(response.data.ret, params);
      return {
        total: res.list.length,
        hasNext: res.hasNext,
        results: res.list.map((v) => v.event),
      };
    }
  } catch (e) {
    throw e;
  }
}
