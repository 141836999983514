import React from 'react';
import { connect } from 'react-redux';

import Tasks from './Tasks';
import SiteMap from '../SiteMap';

const mapStateToProps = state => {
  const { algoOptions } = state.target;
  const { curSite } = state.site;
  return { algoOptions, curSite };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};


class TaskPanelContainer extends React.Component {

  render() {
    const { curSite } = this.props;

    return (
        <>{curSite ? <Tasks /> : <SiteMap />}</>
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskPanelContainer);