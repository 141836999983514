import moment from 'moment';
import {
    routineHistoryConstants
  } from "src/redux/constants";
  
  const initialState = {
  robotRoutineHistoryTime: {},
  selectedRoutineHistoryTime:null,
  filters: {
    robotId:'robot_dog_sr_10057',
    startTime:moment().startOf('day'),
    endTime:moment(),
    direction:'next',
    firstKey:'',
    lastKey:'',
    limit:10,
    // firstKey:1590000000010,
    // lastKey:1590000000004,
    // limit:7,
  },
  robotRoutineHistoryPage: {},
  }

  export default (state = initialState, action) => {
    switch (action.type) {
        case routineHistoryConstants.ROBOT_GET_ROUTINE_HISTORY_TIME_REQUEST:
            return {
              ...state,
              robotRoutineHistoryTime: {
                ...state.robotRoutineHistoryTime,
                [action.robot]: {
                  ...state.robotRoutineHistoryTime,
                  updating: true
                }
              }
            };
      
        case routineHistoryConstants.ROBOT_GET_ROUTINE_HISTORY_TIME_SUCCESS:
          console.log('REDUCER！！！:'+Object.entries(action.robotRoutineHistoryTime))
          let retObj = {
            ...state,
            robotRoutineHistoryTime: {
              ...state.robotRoutineHistoryTime,
              [action.robot]: {
                ...action.robotRoutineHistoryTime,
                updating: false
              }
            }
          };
          return retObj;
      
        case routineHistoryConstants.ROBOT_GET_ROUTINE_HISTORY_TIME_FAILURE:
          return {
            ...state,
            robotRoutineHistoryTime: {
              ...state.robotRoutineHistoryTime,
              [action.robot]: {
                ...state.robotRoutineHistoryTime,
                updating: false
              }
            }
          };
        case routineHistoryConstants.ROUTINE_SELECTED_HISTORY_TIME:
          return {
            ...state,
            selectedRoutineHistoryTime: action.selectedRoutineHistoryTime
          };
        case routineHistoryConstants.SET_ROUTINE_HISTORY_FILTER:
          return {
            ...state,
            filters: Object.assign({}, state.filters, action.filters)
          };
        case routineHistoryConstants.ROBOT_GET_ROUTINE_HISTORY_PAGE_REQUEST:
            return {
              ...state,
              robotRoutineHistoryPage: {
                ...action.robotRoutineHistoryPage,
                  loading: true
                }
            };
      
        case routineHistoryConstants.ROBOT_GET_ROUTINE_HISTORY_PAGE_SUCCESS:
          return {
            ...state,   
            robotRoutineHistoryPage: {
              ...action.robotRoutineHistoryPage,
                loading: false
              }
            };
      
          case routineHistoryConstants.ROBOT_GET_ROUTINE_HISTORY_PAGE_FAILURE:
          return {
            ...state,
            robotRoutineHistoryPage: {
              ...action.robotRoutineHistoryPage,
                loading: false
              }
            };
        
        default:
            return state;
    }
};