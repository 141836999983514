import React from 'react';
import clsx from 'clsx';
import {Grid, Tooltip, makeStyles} from '@material-ui/core';
import {
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
} from '@material-ui/icons';

import {useDetailContext} from '../../hook';

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    width: 'auto',
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: '#9e9e9e',
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  disabled: {
    color: (theme.palette.action).disabledText + ' !important',
    background: 'transparent !important',
    cursor: 'initial',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

export function MoreDetailLoadComponent() {
  const classes = useStyles();

  return (
    <Grid item container alignItems="center" wrap="nowrap" classes={{item: classes.flex}}>
      <PrevComponent />
      <NextComponent />
    </Grid>
  );
}

function PrevComponent() {
  const classes = useStyles();
  const {prev} = useDetailContext();
  const disable = !prev;
  const content = (
    <Grid
      item
      container
      alignItems="center"
      justify="center"
      classes={{
        item: clsx(classes.icon, classes.pointer, !prev ? classes.disabled : null),
      }}
      onClick={prev}
    >
      <NavigateBeforeIcon fontSize="large" />
    </Grid>
  );
  return disable ? (
    content
  ) : (
    <Tooltip placement="bottom" title="Prev event" arrow>
      {content}
    </Tooltip>
  );
}

function NextComponent() {
  const classes = useStyles();
  const {next} = useDetailContext();
  const disable = !next;
  const content = (
    <Grid
      item
      container
      alignItems="center"
      justify="center"
      classes={{
        item: clsx(classes.icon, classes.pointer, !next ? classes.disabled : null),
      }}
      onClick={next}
    >
      <NavigateNextIcon fontSize="large" />
    </Grid>
  );
  return disable ? (
    content
  ) : (
    <Tooltip placement="bottom" title="Next event" arrow>
      {content}
    </Tooltip>
  );
}
