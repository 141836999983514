import React from 'react';
import clsx from 'clsx';
import {Grid, Button, makeStyles, Tooltip, ButtonProps} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrap: {
    borderRadius: theme.spacing(2),
    width: 54,
    height: theme.spacing(5),
    overflow: 'hidden',
    marginRight: theme.spacing(1),
    border: 'solid 1px #3c5bff',
  },
  disable: {
    borderColor: (theme.palette.action).disabledText,
  },
  button: {
    height: '100%',
    color: '#3c5bff',
  },
}));

export function ButtonComponent({
  onClick,
  children,
  title,
  ButtonProps,
}) {
  const classes = useStyles();

  return (
    <Tooltip placement="bottom" title={title} arrow>
      <Grid
        classes={{container: clsx(classes.wrap, (ButtonProps ? ButtonProps.disabled : null) ? classes.disable : '')}}
        container
        justify="center"
        alignItems="center"
      >
        <Button className={classes.button} onClick={onClick} {...ButtonProps}>
          {children}
        </Button>
      </Grid>
    </Tooltip>
  );
}
