export function injectScript(url) {
  const script = document.createElement('script');
  script.src = url;

  const promise = new Promise((resolve, reject) => {
    script.onload = () => {
      resolve();
    };
    script.onerror = () => {
      reject();
    };
  });

  document.head.appendChild(script);

  return promise;
}