import React from 'react';
import { connect } from 'react-redux';

import { siteActions } from 'src/redux/actions';
import AddSiteManager from './AddSiteManager';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    createManager: data => {
      dispatch(siteActions.createManager(data));
    }
  };
};

const levels = [
  0,
  1
]

class AddSiteManagerContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      username: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      level: 0,
      error: {
        username: false,
        phone_number: false
      }
    };
  }

  handleSubmit = event => {
    const { username, first_name, last_name, phone_number, level } = this.state;
    const reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const rePhone = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    if (!reEmail.test(String(username).toLowerCase())){
      alert(getText('input_error_email'));
      return;
    }
    if (!rePhone.test(String(phone_number))){
      alert(getText('input_error_phone'));
      return;
    }
    this.props.createManager({
      user: {
        username,
        first_name,
        last_name,
      },
      level: level,
      phone: phone_number
    });
  }

  handleChange = prop => event => {
    this.setState({
      ...this.state,
      [prop]: event.target.value,
      error: {
        [prop]: false
      }
    });
  }

  render() {
    const { username, first_name, last_name, phone_number, level, error } = this.state;
    return (
      <AddSiteManager
        username={username}
        first_name={first_name}
        last_name={last_name}
        phone_number={phone_number}
        levels={levels}
        level={level}
        error={error}
        submitting={false}
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
        />
    );
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSiteManagerContainer);
