import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  table: {
    width: '100%'
  },
  tableCard: {
    overflow: 'auto'
  },
  header: {
    fontSize: '1rem'
  },
  row: {
    fontSize: '1rem'
  },
  iconButton: {
    marginRight: '0.5rem',
    '&:hover': {
      cursor: 'pointer',
    }
  },
});

const tableHeaders = [
  'id',
  'site',
  ' '
];

const SiteList = props => {
  const {
    classes,
    boxes,
    sites,
    editId,
    editValue,
    onClickEdit,
    onClickSubmit,
    handleChange
  } = props;

  return (
    <Card className={classes.tableCard}>
      <CardContent>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (<TableCell key={index} className={classes.header}>{getText(header)}</TableCell>))}
            </TableRow>
          </TableHead>
          <TableBody>
            {boxes.map(box => {
              const editing = box.id === editId;
              return (
                <TableRow key={box.id}>
                  <TableCell className={classes.row}>{box.id}</TableCell>
                  <TableCell className={classes.row}>{editing ?
                    <Select
                      id="site_id"
                      name="site_id"
                      value={editValue.site_id ? editValue.site_id : ''}
                      onChange={handleChange('site_id')}
                    >
                      {sites.map(site => <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>)}
                    </Select> : sites.find(site => site.id === box.site_id) ? sites.find(site => site.id === box.site_id).name : ''}
                  </TableCell>
                  <TableCell className={classes.row}>
                    {editing ?
                      <CheckIcon className={classes.iconButton} onClick={onClickSubmit(box.id)} />
                      : <EditIcon className={classes.iconButton} onClick={onClickEdit(box.id)} />
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(SiteList);
