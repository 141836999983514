import React from "react";
import { connect } from "react-redux";

import { targetActions } from "src/redux/actions";
import Targets from "./Targets";
import SiteMap from "../SiteMap";

const mapStateToProps = (state) => {
  const { robots } = state.target;
  const { curSite } = state.site;
  return {
    robots,
    curSite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRobots: () => {
      dispatch(targetActions.getRobots());
    },
    getRobotMaps: (robotId) => {
      dispatch(targetActions.getRobotConfigMapsAndRoutes(robotId));
    },
  };
};

class StoreContainer extends React.Component {
  componentDidMount() {
    const { getRobots } = this.props;

    getRobots();
  }

  componentDidUpdate(prevProps) {
    const { curSite, getRobots, getRobotMaps, robots } = this.props;

    if (curSite && (!prevProps.curSite || curSite !== prevProps.curSite)) {
      getRobots();
    }
  }

  render() {
    const { curSite } = this.props;
    return <>{curSite ? <Targets /> : <SiteMap />}</>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreContainer);
