import React from 'react';
import {
  withStyles, Dialog, DialogTitle, DialogContent, DialogActions,
  Table, TableBody, TableRow, TableCell, TextField, Button
} from '@material-ui/core';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  label: {
    marginRight: theme.spacing(1),
  },
  tableCell: {
    borderStyle: 'none',
  },
})

class TakeElevatorDialog extends React.Component {
  state = {
    from: null,
    to: null
  };

  onClose = () => {
    this.setState({
      from: null,
      to: null,
    });
    this.props.onClose();
  }

  render() {
    const { classes, open, onTakeElevator } = this.props;
    const { from, to } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.onClose}
      >
        <DialogTitle>
          {getText('take_elevator')}
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  {getText('from')}:
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <TextField
                    id="take_elevator_from"
                    name="take_elevator_from"
                    type="number"
                    value={from}
                    onChange={event => this.setState({ from: event.target.value })}
                    inputProps={{ style: { padding: 0 } }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  {getText('to')}:
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <TextField
                    id="take_elevator_to"
                    name="take_elevator_to"
                    type="number"
                    value={to}
                    onChange={event => this.setState({ to: event.target.value })}
                    inputProps={{ style: { padding: 0 } }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose} color="primary">
            {getText('cancel')}
          </Button>
          <Button onClick={() => {
            onTakeElevator(from, to);
            this.onClose();
          }} color="primary">
            {getText('submit')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(TakeElevatorDialog);