/* eslint-disable @typescript-eslint/camelcase */
import qs from 'qs';
import { event_api } from 'src/services/Vision/api';
// import {LicensePlateBlacklist, LicensePlateBlacklistFilter} from 'src/models/licensePlateBlacklist';
// import {listAdapter} from './adapter';

export const listAdapter = {
  toServer: (filter) => {
    return {
      site_id: filter.site || undefined,
      offset: (filter.page || 0) * (filter.limit || 10),
      limit: filter.limit || undefined,
    };
  },
};

export async function getLicensePlateBlacklist(params) {
  try {
    const url = `/api/v1/license_plate/blacklist?${qs.stringify(listAdapter.toServer(params))}`;
    const response = await event_api.get(url);
    return response;
  } catch (e) {
    throw e;
  }
}

