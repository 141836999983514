import Grid from "@material-ui/core/Grid/Grid";
import SidePanel from "./SidePanel/SidePanel";
import {withStyles} from "@material-ui/core";
import React from "react";
import { Route, Switch } from 'react-router-dom';
import AccountListPanel from './AccountListPanel';
import AddOperatorPanel from './AddOperatorPanel';
import AddGuardPanel from './AddGuardPanel';

const sidePanelWidth = 200;

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  sidePanel: {
    padding: theme.spacing(2),
    width: sidePanelWidth
    // width: '100%',
    // maxWidth: sidePanelWidth,
  },
  mainPanel: {
    width: `calc(100% - ${sidePanelWidth}px)`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflow: 'auto',
    display: 'flex',
    flex: 1,
    // minHeight: '0px
    // display: 'flex',
    // flex: 1,
  },
  toolbar: theme.mixins.toolbar,
});

const GuardManage = props => {
  const { classes } = props;
  return (
    <Grid
        className={classes.root}
        direction="row"
        container={true}
        justify="flex-start"
        spacing={0}
    >
      <Grid className={classes.sidePanel} item={true}>
        <SidePanel/>
      </Grid>
      <Grid className={classes.mainPanel} item={true}>
        <Switch>
          <Route path='/guardmanage/operator' component={AccountListPanel} />
          <Route path='/guardmanage/guard' component={AccountListPanel} />
          <Route path='/guardmanage/addoperator' component={AddOperatorPanel} />
          <Route path='/guardmanage/addguard' component={AddGuardPanel} />
        </Switch>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(GuardManage);
