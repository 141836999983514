import Grid from "@material-ui/core/Grid/Grid";
import SidePanel from "./SidePanel/SidePanel";
import {withStyles} from "@material-ui/core";
import React from "react";
import { Route, Switch } from 'react-router-dom';
import AccountListPanel from './AccountListPanel';
import AddCompanyPanel from './AddCompanyPanel';
import AddGuardAdminPanel from './AddGuardAdminPanel';
import AddCustomer from './AddCustomerPanel';

const sidePanelWidth = 200;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto'
  },
  sidePanel: {
    padding: theme.spacing(2),
    width: sidePanelWidth,
    height: '100%',
    overflow: 'auto'
  },
  mainPanel: {
    width: `calc(100% - ${sidePanelWidth}px)`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // display: 'flex',
    flex: 1,
    height: '100%',
    overflow: 'auto'
  },
  toolbar: theme.mixins.toolbar,
});

const AdminManage = props => {
  const { classes } = props;
  return (
      <Grid
          className={classes.root}
          direction="row"
          container={true}
          justify="flex-start"
          spacing={0}
      >
        <Grid className={classes.sidePanel} item={true}>
          <SidePanel/>
        </Grid>
        <Grid className={classes.mainPanel} item={true}>
          <Switch>
            <Route path='/adminmanage/company' component={AccountListPanel} />
            <Route path='/adminmanage/guardadmin' component={AccountListPanel} />
            <Route path='/adminmanage/customer' component={AccountListPanel} />
            <Route path='/adminmanage/addcompany' component={AddCompanyPanel} />
            <Route path='/adminmanage/addguardadmin' component={AddGuardAdminPanel} />
            <Route path='/adminmanage/addcustomer' component={AddCustomer} />
          </Switch>
        </Grid>
      </Grid>
  );
};

export default withStyles(styles)(AdminManage);
