import React from 'react';
import { connect } from 'react-redux';

import { targetActions } from 'src/redux/actions';
import RouteConfigs from './RouteConfigs';
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  const {robots, maps, copyLoading} = state.target;
  const { curSite } = state.site;
  return {
    robots,
    curSite,
    copyLoading,
    maps: maps.map(map => ({
      value: map.id,
      label: `${map.name}-${map.id}`,
      routes: map.mapRoutes.map(route => ({
        value: route.id,
        label: `${route.name}-${route.id}`,
      })),
    })),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    copyRoutes: (params) => {
      dispatch(targetActions.copyRoutes(params));
    },
  };
};

const initialState = {
  map: null,
  oldRoute: null,
  newRoute: null,
};

class RouteContainer extends React.Component {

  state = {
    ...initialState,
  };

  handleAutoComplete = (key) => (e, value) => {
    if (key === 'map') {
      this.setState({
        ...initialState,
        map: value,
      });
    } else {
      this.setState({
        [key]: value,
      })
    }
  }

  handleSubmit = () => {
    const {copyRoutes} = this.props;
    const {map, oldRoute, newRoute} = this.state;
    if (!map || !oldRoute || !newRoute) {
      alert('Invalid map or route');
    } else if (oldRoute.value === newRoute.value) {
      alert(getText('conflict_route'));
    } else {
      copyRoutes({
        map_id: map.value,
        route_id: oldRoute.value,
        to_route_id: newRoute.value,
      });
    }
  }
  
  getRoutes(mapOption, maps) {
    const selected = maps.find(map => map.value === (mapOption && mapOption.value))
    return selected ? selected.routes : [];
  }

  render() {
    const { maps, hideMutator, copyLoading } = this.props;
    const { map, newRoute, oldRoute } = this.state;
    const routes = this.getRoutes(map, maps);
    return <RouteConfigs
              maps={maps}
              routes={routes}
              map={map}
              newRoute={newRoute}
              oldRoute={oldRoute}
              hideMutator={hideMutator}
              copyLoading={copyLoading}
              handleSubmit={this.handleSubmit}
              handleAutoComplete={this.handleAutoComplete}
            />
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteContainer);;