import React from 'react';
import { connect } from 'react-redux';

import history from 'src/utils/history';
import SiteManageList from "./SiteManageList";
import { getText } from 'src/utils/MultilingualLoader';

const mapStateToProps = state => {
  return { };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

const listItems = [
  { label: getText('site'), path: '/sitemanage/site' },
  { label: getText('site_manager'), path: '/sitemanage/manager' },
  { label: getText('robot'), path: '/sitemanage/robot' },
  { label: getText('box'), path: '/sitemanage/box' },
  { label: getText('floor'), path: '/sitemanage/building' }
];

class SiteManageListContainer extends React.Component {
  constructor(props) {
    super(props);
    const pagePath = window.location.pathname;
    this.state = {
      pagePath: pagePath
    };
  }

  handleOnclick = item => e => {
    this.setState({ pagePath: item.path });
    history.push(item.path);
  }

  render() {
    const { pagePath } = this.state;
    return (
      <SiteManageList
        listItems={listItems}
        handleOnclick={this.handleOnclick}
        pagePath={pagePath}
        />
    );
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteManageListContainer);
