import React from "react";
import { connect } from "react-redux";
import { withStyles, Button, Typography } from "@material-ui/core";

import { siteActions } from "src/redux/actions";
import { injectScript } from "src/utils/injectScript";
import LoadingText from "src/components/LoadingText";
import { getText, getDefaultMapCenter } from "src/utils/MultilingualLoader";

const styles = (theme) => ({
  mapContainer: {
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    flexGrow: 1,
    position: "relative",
  },
  map: {
    height: "85vh",
  },
  button: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(3),
    zIndex: 9999,
  },
  label: {
    color: "white",
    textTransform: "initial",
  },
});

const mapStateToProps = (state) => {
  return {
    sites: state.site.sites,
    siteLoading: state.site.siteLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurSite: (siteId) => dispatch(siteActions.setCurSite(siteId)),
  };
};

class GaodeMap extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.map = null;
  }

  componentDidMount() {
    if (!window.AMap || !this.mapRef.current) {
      injectScript(
        `${process.env.REACT_APP_GAODE_MAP_API_URL}/maps?v=1.4.15&key=${process.env.REACT_APP_GAODE_MAP_API_KEY}`
      ).then(() => {
        injectScript(
          `${process.env.REACT_APP_GAODE_MAP_API_URL}/ui/1.0/main.js?v=1.0.11`
        ).then(() => {
          window.AMap &&
            window.AMap.plugin(["AMap.IndoorMap"], () => {
              this.loadGaodeMap();
            });
        });
      });
    } else {
      this.loadGaodeMap();
    }
  }

  componentWillUnmount() {
    this.destroyGaodeMap();
  }

  destroyGaodeMap = () => {
    this.map && this.map.destroy();
    this.map = null;
  };

  loadGaodeMap = () => {
    if (window.AMap && window.AMapUI && !this.map) {
      const { sites } = this.props;
      const siteLocations = [];
      let defaultCenter = null;
      sites &&
        sites.forEach((site) => {
          let centerPosition = null;
          try {
            const num_points = site.polygons.reduce(
              (totalNum, polygon) => totalNum + polygon.geo_points.length,
              0
            );
            if (num_points > 0)
              centerPosition = {
                lat:
                  site.polygons.reduce(
                    (totalLat, polygon) =>
                      totalLat +
                      polygon.geo_points.reduce(
                        (subTotalLat, point) => subTotalLat + point.lat,
                        0
                      ),
                    0
                  ) / num_points,
                lng:
                  site.polygons.reduce(
                    (totalLng, polygon) =>
                      totalLng +
                      polygon.geo_points.reduce(
                        (subTotalLng, point) => subTotalLng + point.lng,
                        0
                      ),
                    0
                  ) / num_points,
              };
          } catch (err) {}
          if (centerPosition)
            siteLocations.push({
              label: site.name,
              value: site.id,
              position: centerPosition,
            });
        });
      const sitePositions = siteLocations.map((location) => location.position);
      if (sitePositions.length > 0)
        defaultCenter = {
          lat:
            sitePositions
              .map((position) => position.lat)
              .reduce((totalLat, lat) => totalLat + lat, 0) /
            sitePositions.length,
          lng:
            sitePositions
              .map((position) => position.lng)
              .reduce((totalLng, lng) => totalLng + lng, 0) /
            sitePositions.length,
        };
      else {
        defaultCenter = getDefaultMapCenter();
      }
      const centerPoint = new window.AMap.LngLat(
        defaultCenter.lng,
        defaultCenter.lat,
        true
      );

      // create map
      this.map = new window.AMap.Map(this.mapRef.current, {
        mapStyle: "amap://styles/whitesmoke",
        features: ["bg", "road", "building"],
        showLabel: false,
        resizeEnable: true,
        showIndoorMap: true,
        center: centerPoint,
      });
      if (this.map) {
        // load zoom control
        window.AMapUI.loadUI(["control/BasicControl"], (BasicControl) => {
          BasicControl &&
            this.map.addControl(
              new BasicControl.Zoom({
                position: "br",
              })
            );
        });
        // add site markers and bounds
        const siteMarkers = [];
        let west = defaultCenter.lng,
          south = defaultCenter.lat,
          east = defaultCenter.lng,
          north = defaultCenter.lat;
        siteLocations.forEach((site) => {
          const position =
            site.position &&
            new window.AMap.LngLat(site.position.lng, site.position.lat, true);
          const marker = new window.AMap.Marker({
            position,
            title: site.label,
            clickable: true,
          });
          window.AMap.event.addListener(
            marker,
            "click",
            this.onSiteClick(site.value)
          );
          siteMarkers.push(marker);
          west = position && position.lng < west ? position.lng : west;
          south = position && position.lat < south ? position.lat : south;
          east = position && position.lng > east ? position.lng : east;
          north = position && position.lat > north ? position.lat : north;
        });
        const southWest = new window.AMap.LngLat(west, south);
        const northEast = new window.AMap.LngLat(east, north);
        const bounds = new window.AMap.Bounds(southWest, northEast);
        this.map.add(siteMarkers);
        this.map.setBounds(bounds);
      }
    }
  };

  onSiteClick = (id) => (event) => {
    this.props.setCurSite(id);
  };

  render() {
    const { classes, siteLoading } = this.props;
    if (siteLoading) return <LoadingText />;
    return (
      <div className={classes.mapContainer}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={this.onSiteClick(-1)}
        >
          <Typography variant="body2" className={classes.label}>
            {getText("others")}
          </Typography>
        </Button>
        <div ref={this.mapRef} className={classes.map} />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GaodeMap));
