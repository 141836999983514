import React from 'react';
// import {useIntl} from 'react-intl';
import {CircularProgress} from '@material-ui/core';

import {ReactComponent as DownloadIcon} from 'src/assets/Vision/download.svg';
import {useDetailContext} from '../../../hook';
import {ButtonComponent} from './button';
import {useDownloadVideo} from 'src/utils/Vision/hooks/useDownloadVideo';

export function DownloadVideoComponent(){
  const {detail} = useDetailContext();
  const video = detail.event.mediums ? detail.event.mediums.find(v => v.name === 'video') : null;
  // const {formatMessage} = useIntl();
  return (
    <DownloadVideoButton url={video ? video.file.url : null} title={'Downloading'} />
  );
}

export function DownloadVideoButton({url, title}) {
  const {download, isDownloading} = useDownloadVideo();
  if (!url) return null;
  return (
    <ButtonComponent title={title} onClick={() => download(url)}>
      {isDownloading ? <CircularProgress size="1.25rem" /> : <DownloadIcon />}
    </ButtonComponent>
  );
}
