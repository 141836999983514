export const GET_SENSOR_CLUSTERS_REQUEST = 'SENSOR_GET_SENSOR_CLUSTERS_REQUEST';
export const GET_SENSOR_CLUSTERS_SUCCESS = 'SENSOR_GET_SENSOR_CLUSTERS_SUCCESS';
export const GET_SENSOR_CLUSTERS_FAILURE = 'SENSOR_GET_SENSOR_CLUSTERS_FAILURE';

export const GET_SENSOR_POINTS_REQUEST = 'SENSOR_GET_SENSOR_POINTS_REQUEST';
export const GET_SENSOR_POINTS_SUCCESS = 'SENSOR_GET_SENSOR_POINTS_SUCCESS';
export const GET_SENSOR_POINTS_FAILURE = 'SENSOR_GET_SENSOR_POINTS_FAILURE';

export const SET_FILTER = 'SENSOR_SET_FILTER';
export const SET_SENSOR_TYPE = 'SENSOR_SET_SENSOR_TYPE';
export const SET_SHOW_TYPE = 'SENSOR_SET_SHOW_TYPE';