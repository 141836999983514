import React from 'react';
import { connect } from "react-redux";
import Main from './Main';
import APP from './Websocket/APP'
import WISP from './Websocket/WISP';
import { robotActions } from 'src/redux/actions';

const mapStateToProps = state => {
  const { role } = state.user;
  return { role };
};

const mapDispatchToProps = {
  getAllRobots: () => robotActions.getAllRobots(),
};

class SignInContainer extends React.Component {
  interval = null;

  leaveConfirm = event => {
    event.preventDefault();
    event.returnValue = '';
  };

  componentDidMount(){
    console.log('Environment:', process.env.REACT_APP_ENV);
    window.wsAPP = new APP('APP');
    window.wsWISP = new WISP('WISP');
    window.wsAPP.connectWS(process.env.REACT_APP_API_WEBSOCKET_URL).catch(() => {});
    
    const {getAllRobots} = this.props;
    getAllRobots();
    this.interval = setInterval(() => {
      getAllRobots();
    }, 60000);

    if (process.env.REACT_APP_ENV === 'production'){
      window.addEventListener('beforeunload', this.leaveConfirm); 
    }
  }

  componentWillUnmount() {
    if (window.wsAPP) {
      window.wsAPP.close();
    }
    if (window.wsWISP) {
      window.wsWISP.close();
    }
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (process.env.REACT_APP_ENV === 'production') {
      window.removeEventListener('beforeunload', this.leaveConfirm);
    }
  }

  render() {
    const { role } = this.props;
    return (
      <Main role={role} />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInContainer);
