import React from 'react';
import { withStyles, Button } from '@material-ui/core';
import PrevIcon from '@material-ui/icons/ArrowLeft';
import NextIcon from '@material-ui/icons/ArrowRight';
import RefreshIcon from '@material-ui/icons/Autorenew';

import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
    textAlign: 'right'
  },
  button: {
    margin: theme.spacing(0.2),
    color: theme.palette.primary.main,
    minWidth: 36
  },
  buttonSelected: {
    margin: theme.spacing(0.2),
    border: 0,
    color: '#C70039',
    minWidth: 36
  }
});

const TaskPagination = props => {
  const { classes, noPrev, noNext, curPage, pageOptions, onPageClick, onRefresh } = props;

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        style={{marginRight: 10}}
        variant="outlined"
        size="small"
        onClick={onRefresh}
      >
        <RefreshIcon fontSize="small" />{getText('refresh')}
      </Button>
      <Button
        className={classes.button}
        style={{paddingleft: 0}}
        variant="outlined"
        size="small"
        disabled={noPrev}
        onClick={onPageClick(curPage - 1)}
      >
        <PrevIcon htmlColor={noPrev ? "#CCCCCC" : "#C70039"} />
        {getText('prev_page')}
      </Button>
      {
        pageOptions.map(page => (
          <Button
            className={page === curPage ? classes.buttonSelected : classes.button}
            key={page}
            variant="outlined"
            size="small"
            onClick={onPageClick(page)}
          >
            {page}
          </Button>
        ))
      }
      <Button
        className={classes.button}
        style={{paddingRight: 0}}
        variant="outlined"
        size="small"
        disabled={noNext}
        onClick={onPageClick(curPage + 1)}
      >
        {getText('next_page')}
        <NextIcon htmlColor={noNext ? "#CCCCCC" : "#C70039"} />
      </Button>
    </div>
  );
}

export default withStyles(styles)(TaskPagination);