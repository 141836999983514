import React from "react";

import ExecutionPagination from "./ExecutionPagination";

class ExecutionPaginationContainer extends React.Component {
  handlePageClick = (page) => (e) => {
    const { loading, onPageClick } = this.props;
    if (!loading) {
      onPageClick(page);
    }
  };

  render() {
    const { curPage, count, filters } = this.props;

    if (count <= 5) {
      return null;
    }

    const maxPage = Math.ceil(count / 5);
    let pageOptions = [curPage];
    const left = Math.max(curPage - 2, 1);
    for (let i = curPage - 1; i >= left; i--) pageOptions.unshift(i);
    const right = Math.min(
      curPage + Math.max(5 - pageOptions.length, 0),
      maxPage
    );
    for (let i = curPage + 1; i <= right; i++) pageOptions.push(i);
    for (let i = left - 1; pageOptions.length < 5 && i > 0; i--)
      pageOptions.unshift(i);

    return (
      <ExecutionPagination
        noPrev={curPage <= 1}
        noNext={curPage >= maxPage}
        curPage={curPage}
        pageOptions={pageOptions}
        onPageClick={this.handlePageClick}
      />
    );
  }
}

export default ExecutionPaginationContainer;
