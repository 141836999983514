import React from 'react';
import { Line, Circle } from 'react-konva';

const waypointRadius = 3;
const pathStrokeWidth = 1;

const RobotPatrolRoute = props => {
  const { route, zoomScale, getImagePosition, showWayPoints } = props;
  const routePoints = (route && route.way_points) || [];
  let pathLines = [];
  let waypoints = [];
  let prev = null;
  routePoints.forEach((wayPoint, idx) => {
    const position = wayPoint.point && getImagePosition(wayPoint.point.x, wayPoint.point.y);
    if (position && position.x && position.y && !isNaN(position.x) && !isNaN(position.y)) {
      waypoints.push(
        <Circle key={'dot' + idx} radius={waypointRadius / Math.sqrt(zoomScale)}
          x={position.x} y={position.y} fill="blue" />
      );
      if (prev) {
        pathLines.push(
          <Line key={'line' + idx}
            points={[position.x, position.y, prev.x, prev.y]}
            stroke="green"
            strokeWidth={pathStrokeWidth / Math.sqrt(zoomScale)} />
        )
      }
      prev = position;
    }
  });

  return (
    <>
      {pathLines}
      {showWayPoints && waypoints}
    </>
  );
}

export default RobotPatrolRoute;