import React from 'react';
import PropTypes from 'prop-types';
import { 
  withStyles, Button, FormControl, Input, InputLabel, Card, CardHeader, CardContent, Typography, 
  Dialog, DialogTitle, DialogContent
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { getText } from 'src/utils/MultilingualLoader';
import DrawSiteMap from './DrawSiteMap';

const styles = theme => ({
  card: {
    width: '50%',
    marginTop: theme.spacing(1),
    margin: 'auto'
  },
  submit: {
    marginTop: theme.spacing(3),
    maxWidth: 100,
    float: 'right',
    marginBottom: 24
  },
  label: {
    color: 'white',
    textTransform: 'initial'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialog: {
    width: window.innerWidth * 0.8,
    margin: 'auto',
  },
});

const AddSite = ({
  classes,
  name,
  location,
  note,
  error,
  mapOpen,
  polygons,
  onSubmit,
  onChange,
  onMapOpen,
  onMapClose,
  setPolygons
}) => (
    <Card className={classes.card}>
      <CardHeader 
        title={getText('add_site')}
        />
      <CardContent>
        <form>
          <FormControl margin="normal" required fullWidth
            error={error.title}
          >
            <InputLabel htmlFor="name">{getText('name')}</InputLabel>
            <Input
              id="name"
              name="name"
              autoFocus={true}
              value={name}
              onChange={onChange('name')}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="note">{getText('note')}</InputLabel>
            <Input
              id="note"
              name="note"
              value={note}
              onChange={onChange('note')}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="location">{getText('location')}</InputLabel>
            <Input
              id="location"
              name="location"
              value={location}
              onChange={onChange('location')}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="location_polygon">{getText('location_polygons')}</InputLabel>
            <Input
              id="location_polygon"
              name="location_polygon"
              value={polygons.map(polygon => polygon.name).join(',')}
              disabled
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={onMapOpen}
            >
              <Typography variant="body2" className={classes.label}>
                {getText('draw_location_polygons')}
              </Typography>
            </Button>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            fullWidth={true}
            className={classes.submit}
            onClick={onSubmit}
          >
            <Typography variant="body2" className={classes.label}>
              {getText('submit')}
            </Typography>
          </Button>
        </form>
        <Dialog
          fullScreen
          className={classes.dialog}
          open={mapOpen}
          onClose={onMapClose}
        >
          <DialogTitle>
            <CloseIcon
              className={classes.closeButton}
              onClick={onMapClose}
            />
          </DialogTitle>
          <DialogContent>
            <DrawSiteMap 
              polygons={polygons} 
              setPolygons={setPolygons}
              onMapClose={onMapClose}
            />
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );

  AddSite.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddSite);
