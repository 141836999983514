import React from 'react';
import { connect } from 'react-redux';

import { taskActions } from 'src/redux/actions';
import TaskFilter from './TaskFilter';
import { getText } from 'src/utils/MultilingualLoader';
import { statusOptions } from './config';

const mapStateToProps = state => {
  const { filters, targetOptions } = state.task;
  const { curSite } = state.site;

  return { 
    filters,
    curSite,
    targetOptions,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilter: filters => {
      dispatch(taskActions.setFilter(filters));
    },
    getTasks: (filters) => {
      dispatch(taskActions.getTasks(filters));
    },
  };
};

const resultOptions = [
  {
    value: 'all',
    label: getText('all'),
  },
  {
    value: 'null',
    label: getText('moderate_pending'),
  },
  {
    value: true,
    label: getText('moderate_ok'),
  },
  {
    value: false,
    label: getText('moderate_nok'),
  }
];

const orderOptions = [
  {
    value: '-id',
    label: getText('default'),
  },
  {
    value: 'created_at',
    label: getText('date_sort_1'),
  },
  {
    value: '-created_at',
    label: getText('date_sort_2'),
  },
]

class TaskFilterContainer extends React.Component {
  constructor(props) {
    super(props);
    const { filters } = props;
    const { reviewStatus, moderateStatus, orderStatus, name, startDate, endDate } = filters;
    this.state = {
      reviewStatus,
      moderateStatus,
      orderStatus,
      name,
      startDate,
      endDate,
    };
  }
  
  componentDidUpdate(prevProps) {
    const { curSite, setFilter, filters, getTasks } = this.props;

    if (curSite && (!prevProps.curSite || curSite !== prevProps.curSite)) {

      const newState = {
        name: {},
        reviewStatus: 'all',
        moderateStatus: 'all',
        orderStatus: '-id',
        startDate: null,
        endDate: null,
      };

      const targetFilters = Object.assign({}, filters, {...newState});
      setFilter(newState);
      this.setState(newState);
      getTasks(targetFilters);
    }
  }

  handleChange = (key) => (e) => {
    this.setState({
      [key]: e.target.value
    })
  }

  handleAutoComplete = (e, newValue) => {
    this.setState({
      name: newValue,
    })
  }

  handleDateChange = type => time => {
    if (type === 'startDate')
      this.setState({
        startDate: time.startOf('day')
      });
    else
      this.setState({
        endDate: time.endOf('day')
      });
  }

  handleSubmit = () => {
    const { filters, getTasks, setFilter } = this.props;
    const targetFilters = Object.assign({}, filters, {...this.state});
    setFilter(targetFilters);
    getTasks(targetFilters);
  }

  render() {
    const {reviewStatus, moderateStatus, orderStatus, name, startDate, endDate} = this.state;
    const {targetOptions, curSite} = this.props;

    return (
       <TaskFilter
          reviewStatus={reviewStatus}
          moderateStatus={moderateStatus}
          orderStatus={orderStatus}
          name={name}
          startDate={startDate}
          endDate={endDate}
          statusOptions={statusOptions}
          resultOptions={resultOptions}
          orderOptions={orderOptions}
          targetOptions={targetOptions[curSite] || []}
          handleDateChange={this.handleDateChange}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          handleAutoComplete={this.handleAutoComplete}
       />
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskFilterContainer);