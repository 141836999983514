import React from 'react';
import { Stage, Layer, Image, Circle, Line } from 'react-konva';

export default function RobotMapItem(props) {
  const { position, image, mapWidth, mapHeight, scale, getImagePosition, onMapClick, onMouseMove,
    route, newElevatorBase, updRouteWayPointData, customizedWaypoints, curAction } = props;
    const sizedPosition = {
      x: position && position.x / scale,
      y: position && position.y / scale
  }
  
  const mapRoute = (route && route.way_points) || [];
  let pathLines = [];
  let wayPoints = [];
  let prev = null;

  // show route
  if (image && mapRoute && !updRouteWayPointData) {
    mapRoute.forEach((wayPoint, idx) => {
      const position = wayPoint.point && getImagePosition(wayPoint.point.x, wayPoint.point.y);
      if (position && position.x && position.y && !isNaN(position.x) && !isNaN(position.y)) {
        // show customized waypoints
        curAction === 'customize_waypoints' && wayPoints.push(
          <Circle
            key={'dot' + idx}
            radius={3 / Math.sqrt(scale)}
            x={position.x}
            y={position.y}
            fill={customizedWaypoints.includes(idx) ? "red" : "blue"}
          />
        );
        if (prev) {
          pathLines.push(
            <Line key={'line' + idx}
              points={[position.x, position.y, prev.x, prev.y]}
              stroke="green"
              strokeWidth={1 / Math.sqrt(scale)} />
          )
        }
        prev = position;
      }
    });
  }

  // show editing route
  if (image && updRouteWayPointData) {
    const routeWayPoints = updRouteWayPointData.wayPoints || [];
    const { basePointIdx, selectedPointIdx, movingPointIdx } = updRouteWayPointData;
    routeWayPoints.forEach((wayPoint, idx) => {
      const position = wayPoint.point && getImagePosition(wayPoint.point.x, wayPoint.point.y);
      if (position && position.x && position.y && !isNaN(position.x) && !isNaN(position.y)) {
        wayPoints.push(
          <Circle
            key={'dot' + idx}
            radius={3 / Math.sqrt(scale)}
            x={position.x}
            y={position.y}
            fill={[basePointIdx, selectedPointIdx, movingPointIdx].includes(idx) ? "red" : "blue"}
          />
        );
        if (prev) {
          pathLines.push(
            <Line key={'newLine' + idx}
              points={[position.x, position.y, prev.x, prev.y]}
              stroke="green"
              strokeWidth={1 / Math.sqrt(scale)} />
          )
        }
        prev = position;
      }
    });
  }

  // show elevator base
  const basePosition = newElevatorBase && newElevatorBase.base &&
    getImagePosition(newElevatorBase.base.x, newElevatorBase.base.y);
  const enterPosition = newElevatorBase && newElevatorBase.enter &&
    getImagePosition(newElevatorBase.enter.x, newElevatorBase.enter.y);
  const exitPosition = newElevatorBase && newElevatorBase.exit &&
    getImagePosition(newElevatorBase.exit.x, newElevatorBase.exit.y);

  return (
    <Stage
      onClick={() => onMapClick(sizedPosition)}
      onMouseMove={() => onMouseMove(sizedPosition)}
      height={mapHeight} width={mapWidth} x={0} y={0}
    >
      <Layer>
        <Image x={0} y={0} image={image} height={mapHeight} width={mapWidth} />
      </Layer>
      <Layer>
        {basePosition && <Circle radius={3 / Math.sqrt(scale)} fill="green"
          x={basePosition.x} y={basePosition.y} />}
        {enterPosition && <Circle radius={3 / Math.sqrt(scale)} fill="blue"
          x={enterPosition.x} y={enterPosition.y} />}
        {exitPosition && <Circle radius={3 / Math.sqrt(scale)} fill="purple"
          x={exitPosition.x} y={exitPosition.y} />}
        {pathLines}
        {wayPoints}
      </Layer>
    </Stage>
  );
}
