export const GET_ROBOT_REQUEST = 'INSPECTION_GET_ROBOT_REQUEST';
export const GET_ROBOT_SUCCESS = 'INSPECTION_GET_ROBOT_SUCCESS';
export const GET_ROBOT_FAILURE = 'INSPECTION_GET_ROBOT_FAILURE';

export const ROBOT_SELECTED = 'INSPECTION_ROBOT_SELECTED';
export const WAYPOINT_SELECTED = 'INSPECTION_WAYPOINT_SELECTED'
export const SET_FILTER = 'INSPECTION_SET_FILTER';
export const SET_PAGE = 'INSPECTION_SET_PAGE';
export const SET_SHOW_TYPE = 'INSPECTION_SET_SHOW_TYPE';

export const GET_INSPECTION_REQUEST = 'INSPECTION_GET_INSPECTION_REQUEST';
export const GET_INSPECTION_SUCCESS = 'INSPECTION_GET_INSPECTION_SUCCESS';
export const GET_INSPECTION_FAILURE = 'INSPECTION_GET_INSPECTION_FAILURE';
export const GET_MORE_INSPECTION_SUCCESS = 'INSPECTION_GET_MORE_INSPECTION_SUCCESS';

export const UPDATE_INSPECTION_SUCCESS = 'INSPECTION_UPDATE_INSPECTION_SUCCESS';
export const CREATE_CASE_FROM_INSPECTION_SUCCESS = 'INSPECTION_CREATE_CASE_FROM_INSPECTION_SUCCESS';

export const GET_EVENT_TYPES = 'INSPECTION_GET_EVENT_TYPES';
