import React, {useState, useEffect, useMemo} from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
  DialogActions,
  TextField,
  Chip,
} from '@material-ui/core';
import {useSelector} from 'react-redux';
// import {FormattedMessage, useIntl} from 'react-intl';
// import {FormattedMessage} from 'react-intl';
import CloseIcon from '@material-ui/icons/Close';
import {Select, SelectOptionType} from 'turing-ui';
// import message from 'src/components/Message';
// import {SimpleSite, SimpleSites} from 'src/models/site';
import Spin from 'src/components/Vision/Spin';
// import {RootState} from 'src/redux/reducers';
// import {SiteManager, SiteManagerApiData} from 'src/models/siteManager';
// import {addSiteManager, editSiteManager} from 'src/services/siteManager';
// import {isValidEmail} from 'src/utils/validators';
import useStyles from './index.style';


const EditOperator = (props) => {
  // const intl = useIntl();
  // const language = intl.locale.toLowerCase().indexOf('cn') > -1 ? 'cn' : 'en';
  // const {siteManager, selectedSites, open, onClose} = props;
  const {onClose, data} = props;
  // const {open, onClose} = props;
  // const simpleSites = useSelector((state) => state.site.siteOptions);
  const [submitting, setSubmitting] = useState(false);
  const [changed] = useState(false);
  // const [, setUserId] = useState<number | undefined>(siteManager ? siteManager.user.id : undefined);

  const classes = useStyles();
  const [state, setState] = useState(JSON.parse(JSON.stringify(data)));
  console.log('edit')
  // const [validate, setValidate] = useState<any>({});
  // const [selectedManagers, setSelectedManagers] = useState(selectedSites || []);

  // const validSiteOptions = useMemo(() => {
  //   const options = [];
  //   return options.concat(
  //     simpleSites.map(v => {
  //       return {
  //         value: v.id,
  //         label: v.name,
  //       };
  //     })
  //   );
  // }, [simpleSites]);

  // useEffect(() => {
  //   if (open) {
  //     const uid = siteManager ? siteManager.user.id : undefined;
  //     setUserId(uid);
  //     setState({
  //       last_name: (siteManager && siteManager.user.last_name) || '',
  //       first_name: (siteManager && siteManager.user.first_name) || '',
  //       username: (siteManager && siteManager.user.username) || '',
  //       level: (siteManager && siteManager.level) || 0,
  //       phone_prefix: language === 'cn' ? '+86' : '+1',
  //     });
  //     setSelectedManagers(selectedSites || []);
  //   }
  //   // eslint-disable-next-line
  // }, [open]);

  function handleClose() {
    onClose(false);
  }

  // function validateForm() {
  //   let hasError = false;
  //   const valid = {};
  //   state.first_name = state.first_name.trim();
  //   state.last_name = state.last_name.trim();
  //   state.username = state.username.trim();
  //   if (!state.first_name) {
  //     valid.first_name = intl.formatMessage({id: 'common.required'});
  //     hasError = true;
  //   }
  //   if (!state.last_name) {
  //     valid.last_name = intl.formatMessage({id: 'common.required'});
  //     hasError = true;
  //   }
  //   if (!state.username) {
  //     valid.username = intl.formatMessage({id: 'common.required'});
  //     hasError = true;
  //   } else if (!isValidEmail(state.username)) {
  //     valid.username = intl.formatMessage({id: 'settings.user.validate-email'});
  //     hasError = true;
  //   }
  //   if (selectedManagers.length === 0) {
  //     valid.site = intl.formatMessage({id: 'common.required'});
  //     hasError = true;
  //   }
  //   setValidate(valid);
  //   return hasError;
  // }

  function handleSubmit() {
    
    

    handleClose();
    // const hasError = validateForm();
    // if (!hasError) {
    //   const siteIds = [];
    //   selectedManagers.forEach((item) => {
    //     siteIds.push(item.id);
    //   });
    //   setSubmitting(true);
    //   if (!siteManager) {
    //     const data = {
    //       user: {
    //         first_name: state.first_name,
    //         last_name: state.last_name,
    //         username: state.username,
    //       },
    //       level: state.level,
    //       site_ids: siteIds.length > 0 ? siteIds : undefined,
    //     };
    //     addSiteManager(data)
    //       .then(() => {
    //         message.success(intl.formatMessage({id: 'settings.user.message.add-site-manager'}));
    //         setSubmitting(false);
    //         onClose(true);
    //       })
    //       .catch(error => {
    //         setSubmitting(false);
    //         error && message.error(error.message);
    //       });
    //   } else {
    //     const data = {
    //       id: siteManager.user.id,
    //       data: {
    //         site_ids: siteIds.length > 0 ? siteIds : [],
    //       },
    //     };
    //     editSiteManager(data)
    //       .then(() => {
    //         message.success(intl.formatMessage({id: 'settings.user.message.edit-site-manager'}));
    //         setSubmitting(false);
    //         onClose(true);
    //       })
    //       .catch(error => {
    //         setSubmitting(false);
    //         error && message.error(error.message);
    //       });
    //   }
    // }
  }

  function handleChange(key) {
    return function(event) {
      // set label the same with name
      setState({
        ...state,
        [key]: event.target.value,
      });
    };
  }

  // function handleSiteChange(value) {
  //   const obj = simpleSites.find(v => v.id === value);
  //   if (obj) {
  //     handleManagerChange(obj, true);
  //   }
  // }

  // function handleManagerChange(obj, checked) {
  //   if (checked) {
  //     if (selectedManagers.find(v => v.id === obj.id)) {
  //       return;
  //     }
  //     setSelectedManagers([...selectedManagers, obj]);
  //   } else {
  //     setSelectedManagers(selectedManagers.filter(v => v.id !== obj.id));
  //   }
  // }

  return (
    <Dialog fullWidth maxWidth="sm" scroll="body" open={true}>
    {/* <Dialog fullWidth maxWidth="sm" scroll="body" open={open}> */}
      <DialogTitle className={classes.titleGroup}>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h6">
            Edit Operator Information
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div className={`${classes.rowManager} ${classes.flex}`}>
          <TextField
            label={
              <Typography>
                First Name
                <span className={classes.required}>*</span>
              </Typography>
            }
            // disabled={!!siteManager}
            variant="outlined"
            className={`${classes.input} ${classes.mr40}`}
            name="first_name"
            // error={!!validate.first_name}
            value={state.first_name}
            onChange={handleChange('first_name')}
          />
          <TextField
            label={
              <Typography>
                Last Name
                <span className={classes.required}>*</span>
              </Typography>
            }
            // disabled={!!siteManager}
            variant="outlined"
            className={classes.input}
            name="last_name"
            // error={!!validate.last_name}
            value={state.last_name}
            onChange={handleChange('last_name')}
          />
        </div>
        <div className={classes.row}>
          <TextField
            label={
              <Typography>
                {/* <FormattedMessage id="settings.user.emailAddress" /> */}
                Email Address
                <span className={classes.required}>*</span>
              </Typography>
            }
            // disabled={!!siteManager}
            variant="outlined"
            className={classes.input}
            name="username"
            // error={!!validate.username}
            value={state.username}
            onChange={handleChange('username')}
          />
        </div>
        {/* <div className={classes.row}>
          <Select
            size="small"
            className={`${classes.select} ${!!validate.site ? classes.selectRequired : ''}`}
            label={
              <Typography style={{marginTop: '-4px'}}>
                <FormattedMessage id="settings.site" />
                <span className={classes.required}>*</span>
              </Typography>
            }
            placeholder={intl.formatMessage({id: 'settings.user.select-site'})}
            options={validSiteOptions}
            onChange={handleSiteChange}
          />
          <Typography className={classes.tip}>
            <FormattedMessage id="settings.user.manager-tip" />
          </Typography>
          <div className={classes.result}>
            {selectedManagers.map(item => (
              <Chip
                key={item.id}
                label={item.name || ''}
                className={classes.chip}
                onDelete={() => {
                  handleManagerChange(item, false);
                }}
              />
            ))}
          </div>
        </div> */}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Typography className={classes.actionRequired}>*Required</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{textTransform: 'capitalize'}}
        >
          {/* <FormattedMessage id="common.done" /> */}
          Done
        </Button>
      </DialogActions>
      <Spin spinning={submitting} />
    </Dialog>
  );
};

export default EditOperator;
