import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PrevPageIcon from '@material-ui/icons/NavigateBefore';
import NextPageIcon from '@material-ui/icons/NavigateNext';

import { getText, getTimeText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  playerHide: {
    display: 'none'
  },
  video_visible: {
    width: '100%'
  },
  video_hidden: {
    display: 'none'
  },
  liveButton: {
    padding: 4,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  historyControl: {
    textAlign: 'center'
  },
  slider: {
    marginTop: theme.spacing(4),
    width: '90%',
  },
  valueLabel: {
    fontSize: '0.5rem'
  },
  dateText: {
    display: 'inline',
    verticalAlign: 'middle'
  }
});

function getTimeString(seconds) {
  return moment().startOf('day')
    .seconds(seconds)
    .format('HH:mm:ss');
}

function ValueLabelComponent(props) {
  const { children, value } = props;

  const popperRef = React.useRef(null);
  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  return (
    <Tooltip
      PopperProps={{
        popperRef,
      }}
      open={true}
      enterTouchDelay={0}
      placement="top"
      title={getTimeString(value)}
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

class HistoryPlayer extends React.Component {
  constructor(props) {
    super(props);
    const { playlist, noNext, curDate } = props;
    const { totalDuration, videoClips } = this.mapPlayListToVideoClips(playlist, noNext, curDate);
    this.state = {
      playing: false,
      seeking: false,
      activeClipIdx: -1,
      bufferedClipIdx: -1,
      activeVideoContainer: null,
      bufferedVideoContainer: null,
      isFullScreen: false,
      sliderValue: totalDuration,
      totalDuration, videoClips
    };
    this.sliderChangeHandler = this.sliderChangeHandler.bind(this);
    this.sliderChangeCommittedHandler = this.sliderChangeCommittedHandler.bind(this);
    this.sliderMouseDownHandler = this.sliderMouseDownHandler.bind(this);
    this.videoPlayHandler = this.videoPlayHandler.bind(this);
    this.videoPauseHandler = this.videoPauseHandler.bind(this);
    this.videoEndedHandler = this.videoEndedHandler.bind(this);
  }

  componentDidMount() {
    this.video1.style.minHeight = this.props.minHeight;
    this.video2.style.minHeight = this.props.minHeight;
    this.setState({
      activeVideoContainer: this.video1,
      bufferedVideoContainer: this.video2
    });
  }

  componentDidUpdate(prevProps) {
    if (!this.props.curDate.isSame(prevProps.curDate, 'day')) {
      const { playlist, noNext, curDate } = this.props;
      const { totalDuration, videoClips } = this.mapPlayListToVideoClips(playlist, noNext, curDate);
      this.setState({
        sliderValue: totalDuration,
        totalDuration,
        videoClips
      });
    }

    else if (this.props.playlist.length !== prevProps.playlist.length) {
      const { playlist, noNext, curDate } = this.props;
      const { totalDuration, videoClips } = this.mapPlayListToVideoClips(playlist, noNext, curDate);
      this.setState({
        totalDuration,
        videoClips
      });
    }

    if (this.props.minHeight !== prevProps.minHeight) {
      this.video1.style.minHeight = this.props.minHeight;
      this.video2.style.minHeight = this.props.minHeight;
    }
  }

  mapPlayListToVideoClips = (playlist, noNext, curDate) => {
    let totalDuration = noNext ? Math.ceil(moment().diff(moment().startOf('day'), 'seconds') / 3600) * 3600 : 24 * 3600;
    let videoClips = [];
    playlist.forEach(video => {
      let clip = {
        timeSlot: [video.startTime.diff(curDate, 'seconds'),
        video.endTime.diff(curDate, 'seconds')],
        url: video.video.url
      };
      // skip clips with wrong timestamp(longer than 2 mins)
      if (clip.timeSlot[0] && clip.timeSlot[1] && clip.timeSlot[1] - clip.timeSlot[0] < 120)
        videoClips.push(clip);
    });
    return { totalDuration, videoClips };
  }

  video1Ref = video => {
    this.video1 = video;
  }

  video2Ref = video => {
    this.video2 = video;
  }

  //Event handler for slider mouse down
  sliderMouseDownHandler() {
    if (!this.state.activeVideoContainer.paused) {
      this.state.activeVideoContainer.pause();
    }
    this.setState({
      seeking: true
    });
  }

  //Event handler for video play
  videoPlayHandler() {
    if (!this.state.playing) this.setState({ playing: true });
  }

  //Event handler for video pause
  videoPauseHandler() {
    if (this.state.playing) this.setState({ playing: false });
  }

  //Event handler for video ended
  videoEndedHandler() {
    const { activeClipIdx, bufferedClipIdx, videoClips, bufferedVideoContainer, activeVideoContainer } = this.state;

    //If the ended clip is the last clip: do not shift to the next clip
    if (activeClipIdx === videoClips.length - 1) {
      this.setState({
        playing: false
      });
      return;
    }

    this.setState({
      activeClipIdx: bufferedClipIdx,
      bufferedClipIdx: (bufferedClipIdx + 1) % videoClips.length,
      activeVideoContainer: bufferedVideoContainer,
      bufferedVideoContainer: activeVideoContainer
    }, () => {
      const { bufferedVideoContainer, bufferedClipIdx } = this.state;
      //let the previous active container buffer be a buffered container for the next clip
      bufferedVideoContainer.src = videoClips[bufferedClipIdx] ? videoClips[bufferedClipIdx].url : null;
    });
  }

  //Event handler for video time update
  videoTimeUpdateHandler = () => {
    const { videoClips, activeClipIdx, activeVideoContainer, totalDuration, playing, seeking } = this.state;
    if (activeClipIdx === -1 || videoClips.length === 0) return;
    const videoClip = videoClips[activeClipIdx];
    if (!videoClip) return;
    const value = videoClip.timeSlot[0] + activeVideoContainer.currentTime;
    if (!seeking && playing) {
      this.setState({
        sliderValue: value
      });
    }

    //stop when the end is reached
    if (value >= totalDuration && !seeking && playing) {
      activeVideoContainer.pause();
      this.setState({
        playing: false
      });
    }
  }

  sliderChangeCommittedHandler(event, newValue) {
    const { noNext } = this.props;
    const { videoClips, totalDuration } = this.state;
    if (noNext && videoClips.length > 0 && newValue >= videoClips[videoClips.length - 1].timeSlot[1])
      this.setState({
        sliderValue: totalDuration
      });

    this.setState({
      seeking: false
    });
  }

  sliderChangeHandler(event, newValue) {
    const { noNext, playerType, shiftPlayer } = this.props;
    const { activeClipIdx, bufferedClipIdx, videoClips, activeVideoContainer, bufferedVideoContainer } = this.state;

    if (videoClips.length === 0) {
      this.setState({
        sliderValue: newValue
      });
      return;
    };

    //switch to live streaming
    if ((noNext && newValue >= videoClips[videoClips.length - 1].timeSlot[1]) && playerType !== 'live') {
      shiftPlayer('live');
      return;
    }

    //switch to history
    if (newValue <= videoClips[videoClips.length - 1].timeSlot[1]
      && playerType !== 'history') shiftPlayer('history');

    //find the video clip contains the new time point
    let selectedClipIdx = -1;
    for (let idx = 0; idx < videoClips.length; idx++) {
      if (videoClips[idx].timeSlot[0] <= newValue &&
        (videoClips[idx].timeSlot[1] > newValue || (idx === videoClips.length - 1 && videoClips[idx].timeSlot[1] >= newValue))) {
        selectedClipIdx = idx;
        break;
      }
    }

    //If there is not video clip at the new time point
    if (selectedClipIdx === -1) {
      activeVideoContainer.src = null;
      this.setState({
        activeClipIdx: -1,
      });
    }

    //If the new time point is still in the current active clip: current active container move to the new time point
    else if (selectedClipIdx === activeClipIdx) {
      activeVideoContainer.currentTime = Math.max(newValue - videoClips[activeClipIdx].timeSlot[0], 0);
    }

    //If the new time point is in the next buffered clip: activate the buffered container to replace the current active container
    else if (selectedClipIdx === bufferedClipIdx) {
      this.setState({
        activeClipIdx: selectedClipIdx,
        bufferedClipIdx: (selectedClipIdx + 1) % videoClips.length,
        activeVideoContainer: bufferedVideoContainer,
        bufferedVideoContainer: activeVideoContainer
      }, () => {
        const { activeVideoContainer, bufferedVideoContainer } = this.state;
        activeVideoContainer.currentTime = Math.max(newValue - videoClips[activeClipIdx].timeSlot[0], 0);
        //let the previous active container buffer be a buffered container for the next clip
        bufferedVideoContainer.src = videoClips[bufferedClipIdx].url;
      });
    }

    // //If the new time point is in an unloaded clip: reset the active container and the buffered container
    else {
      //move the current active container to the selected clip
      activeVideoContainer.src = videoClips[selectedClipIdx].url;
      activeVideoContainer.currentTime = Math.max(newValue - videoClips[selectedClipIdx].timeSlot[0], 0);
      //move the buffered container to the clip behind the selected clip
      bufferedVideoContainer.src = videoClips[(selectedClipIdx + 1) % videoClips.length].url;
      this.setState({
        activeClipIdx: selectedClipIdx,
        bufferedClipIdx: (selectedClipIdx + 1) % videoClips.length,
      });
    }

    this.setState({
      sliderValue: newValue
    });
  }

  render() {
    const { classes, playerType, curDate, handlePrevPageClick, handleNextPageClick, handleGoLive, noPrev, noNext, lineSize } = this.props;
    const { sliderValue, totalDuration, activeVideoContainer } = this.state;
    let marks = [];
    if (!noNext) {
      for (let hour = 0; hour <= 24; hour++) marks.push({
        value: hour * 3600,
        label: `${hour}`,
      });
    }
    else {
      for (let hour = 0; hour < Math.ceil(moment().diff(moment().startOf('day'), 'seconds') / 3600); hour++) marks.push({
        value: hour * 3600,
        label: `${hour}`,
      });
      marks.push({
        value: totalDuration,
        label: getText('live')
      })
    }

    return (
      <Grid
        item
        container={true}
        direction="column"
        justify="flex-start"
        spacing={0}
      >
        <Grid item className={playerType !== 'history' ? classes.playerHide : ''}>
          <CardMedia src="video">
            <div>
              <video
                ref={this.video1Ref}
                className={activeVideoContainer === this.video1 ? classes.video_visible : classes.video_hidden}
                preload='auto'
                controls={true}
                style={{ minHeight: this.props.minHeight }}
                autoPlay={true}
                onPlay={this.videoPlayHandler}
                onPause={this.videoPauseHandler}
                onEnded={this.videoEndedHandler}
                onTimeUpdate={this.videoTimeUpdateHandler}
              />
              <video
                ref={this.video2Ref}
                className={activeVideoContainer === this.video2 ? classes.video_visible : classes.video_hidden}
                preload='auto'
                controls={true}
                style={{ minHeight: this.props.minHeight }}
                autoPlay={true}
                onPlay={this.videoPlayHandler}
                onPause={this.videoPauseHandler}
                onEnded={this.videoEndedHandler}
                onTimeUpdate={this.videoTimeUpdateHandler}
              />
            </div>
          </CardMedia>
        </Grid>
        <Grid
          item
          container={true}
          className={classes.historyControl}
          condirection="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={false} md={lineSize > 1 ? false : 3} lg={lineSize > 3 ? false : 3} xl={3}></Grid>
          <Grid item xs={12} md={lineSize > 1 ? 12 : 6} lg={lineSize > 3 ? 12 : 6} xl={6}>
            <IconButton
              onClick={handlePrevPageClick}
              disabled={noPrev}
            >
              <PrevPageIcon />
            </IconButton>
            <Typography variant="body1" className={classes.dateText}>{getTimeText(curDate, 'll')}</Typography>
            <IconButton
              onClick={handleNextPageClick}
              disabled={noNext}
            >
              <NextPageIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} md={lineSize > 1 ? 12 : 3} lg={lineSize > 3 ? 12 : 3} xl={3}>
            <Button
              className={classes.liveButton}
              onClick={handleGoLive}
            >
              <Typography variant='body2'>{getText('live')}</Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid item className={classes.historyControl}>
          <Slider
            className={classes.slider}
            classes={{ valueLabel: classes.valueLabel }}
            min={0}
            max={totalDuration}
            step={1}
            value={sliderValue}
            marks={marks}
            valueLabelDisplay='on'
            valueLabelFormat={this.getTimeString}
            ValueLabelComponent={ValueLabelComponent}
            onChange={this.sliderChangeHandler}
            onChangeCommitted={this.sliderChangeCommittedHandler}
            onMouseDown={this.sliderMouseDownHandler}
          />
        </Grid>
      </Grid>
    );
  }
};

HistoryPlayer.propTypes = {
  classes: PropTypes.object.isRequired,
  playlist: PropTypes.array.isRequired,
};

HistoryPlayer.defaultProps = {
  playlist: [],
};

export default withStyles(styles)(HistoryPlayer);
