import React from 'react';
import { Line } from 'react-konva';

const pathStrokeWidth = 3;

const getStrokeColor = (wayPoint, sensorType) => {
  return 'blue';
}

const SensorActualRoute = props => {
  const { sensorPoints, sensorType, zoomScale, getImagePosition } = props;
  const wayPoints = (sensorPoints && sensorPoints[sensorType]) || [];
  let pathLines = [];
  let prev = null;
  wayPoints.forEach((wayPoint, idx) => {
    const position = wayPoint.position && getImagePosition(wayPoint.position.x, wayPoint.position.y);
    if (position && position.x && position.y && !isNaN(position.x) && !isNaN(position.y)) {
      if (prev) {
        pathLines.push(
          <Line key={'line' + idx}
            points={[position.x, position.y, prev.x, prev.y]}
            stroke={getStrokeColor(wayPoint, sensorType)}
            strokeWidth={pathStrokeWidth / Math.sqrt(zoomScale)} />
        )
      }
      prev = position;
    }
  });

  return (
    <>
      {pathLines}
    </>
  );
}

export default SensorActualRoute;