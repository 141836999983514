import React from "react";
import { Grid } from "@material-ui/core";

import { getText } from "src/utils/MultilingualLoader";
import ExpansionPanel from "../ExpansionPanel";
import Tabs from "../Tabs";
import RobotConfig from "../RobotConfig";
import RobotPatrolConfig from "../RobotPatrolConfig";
import RobotHomeMap from "../RobotHomeMap";
import RobotFullRoute from "../RobotFullRoute";
import RobotLiftStatus from "../RobotLiftStatus";
import RobotDetectionConfig from "../RobotDetectionConfig";
import ConnectingFloorsConfig from './ConnectingFloorsConfig'

const RobotConfigs = ({ selected, configs, detectionConfigs, algos }) => {
  return (
    <ExpansionPanel title={getText("set")}>
      <Tabs>
        <Grid title={getText("patrol_config")}>
          <RobotPatrolConfig selected={selected} configs={configs} />
        </Grid>
        <Grid title={getText("robot_config")}>
          <RobotConfig selected={selected} configs={configs} />
        </Grid>
        <Grid title={getText("home_map_route_ids")}>
          <RobotHomeMap selected={selected} configs={configs} />
        </Grid>
        <Grid title={getText("full_route_map")}>
          <RobotFullRoute selected={selected} configs={configs} />
        </Grid>
        <Grid title={getText("lift_status")}>
          <RobotLiftStatus selected={selected} />
        </Grid>
        <Grid title={getText("detection_configs")}>
          <RobotDetectionConfig
            selected={selected}
            configs={detectionConfigs}
            algos={algos}
          />
        </Grid>
        <Grid title={getText("connecting_floors_title")} style={{
          width: '100%'
        }}>
          <ConnectingFloorsConfig />
        </Grid>
      </Tabs>
    </ExpansionPanel>
  );
};

export default RobotConfigs;
