import React, {useState} from 'react';
import {
  withStyles, 
  Grid,
  Card,
  CardContent,
  Typography,
  Radio,
  Button,
  Zoom,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { Pannellum } from "pannellum-react";
import CloseIcon from '@material-ui/icons/Close';
import { getText } from 'src/utils/MultilingualLoader';
import InspectionPagination from './InspectionPagination';

const styles = theme => ({
  root: {
    paddingBottom: 20
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    '&:hover': {
      cursor: 'pointer'
    }
  },
  diaTitle: {
    textAlign: 'center',
    paddingBottom: 0,
  },
  diaContent: {
    textAlign: 'center',
  },
  zoomedImg: {
    margin: 'auto',
    height: '90%',
    overflow: 'auto',
  },
});

const InspectionList = (props) => {
  const {classes, inspectionList, pagination, targetId, onClose, onSelectSnapshot, mapId, routeId} = props;
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedInspection, setSelectedInspection] = useState(null);

  console.log(selectedInspection);

  return (
    <Grid className={classes.root}>
      <Grid container spacing={1}>
        {inspectionList.map((inspection) => (
          <Grid item xs={12} sm={3} key={inspection.id}>
            <Card>
              <CardContent>
                <img onClick={() => setSelectedInspection(inspection)} alt="" src={inspection.snapshot} style={{width: '100%', cursor: 'pointer', objectFit: 'contain'}}/>
                <Grid container justify="space-between" alignItems="center">
                  <Typography>{getText('select')}</Typography>
                  <Radio
                    checked={selectedValue === inspection.id}
                    onChange={() => {
                      setSelectedValue(inspection.id)
                    }}
                    color="primary"
                    value="a"
                    name="radio-button-demo"
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <InspectionPagination
        mapId={mapId}
        routeId={routeId}
        targetId={targetId}
        curPage={pagination.curPage}
        count={pagination.count}
      />
      <Grid container justify="flex-end" style={{marginTop: 10}}>
        <Button color="primary" onClick={onClose}>{getText('cancel')}</Button>
        <Button color="primary" onClick={() => {
          if (selectedValue) {
            const inspection = inspectionList.find(item => item.id === selectedValue);
            onSelectSnapshot(inspection && {url: inspection.snapshot, id: inspection.id});
            onClose();
          }
        }}>{getText('ok')}</Button>
      </Grid>
      <Dialog
        TransitionComponent={Zoom}
        fullScreen
        className={classes.dialog}
        open={Boolean(selectedInspection)}
        onClose={() => {
          setSelectedInspection(null);
        }}
      >
        <DialogTitle className={classes.diaTitle}>
          {selectedInspection && selectedInspection.isPanoramic && <Typography variant="body1">{getText('360_image_description')}</Typography>}
          <CloseIcon
            className={classes.closeButton}
            onClick={() => setSelectedInspection(null)}
          />
        </DialogTitle>
        <DialogContent className={classes.diaContent}>
          {selectedInspection && selectedInspection.isPanoramic ?
            <Pannellum
              image={selectedInspection && selectedInspection.snapshot}
              width="100%"
              height="100%"
              autoLoad
            />
            : <img className={classes.zoomedImg} src={selectedInspection && selectedInspection.snapshot} alt={getText('snapshot')} />}
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default withStyles(styles)(InspectionList);