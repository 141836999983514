import React from "react";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { getText } from "src/utils/MultilingualLoader";
import ExpansionPanel from "../ExpansionPanel";

const RobotDetail = (props) => {
  const { details } = props;

  return (
    <ExpansionPanel title={getText("current_robot_detail")}>
      <Grid container spacing={2}>
        {details.map((detail) => (
          <Grid key={detail.title} container item xs={12} sm={6} md={4} xl={3}>
            <Typography
              variant="body2"
              style={{ minWidth: 80, marginRight: 10 }}
            >
              {detail.title}
            </Typography>
            <Typography variant="body2" style={{ flex: 1 }}>
              {detail.content}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </ExpansionPanel>
  );
};

RobotDetail.propTypes = {
  details: PropTypes.array.isRequired,
};

RobotDetail.defaultProps = {
  details: [],
};

export default RobotDetail;
