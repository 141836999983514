import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Loader from 'react-loader-advanced';
import { Stage, Layer, Image } from 'react-konva';
import { MapInteractionCSS } from 'react-map-interaction';
import { getImagePosition } from 'src/pages/Main/Robots/RobotPanel/RobotMap';

import { getText } from 'src/utils/MultilingualLoader';
import SensorHeatMap from './SensorHeatMap';
import SensorActualRoute from './SensorActualRoute';
import RobotPatrolRoute from 'src/components/react-konva-components/robot-patrol-route';

const styles = theme => ({
  card: {
    width: 'fit-content',
    height: 'fit-content',
  },
  loader: {
    zIndex: 0,
    minHeight: 200
  },
  cardMedia: {
    position: 'relative',
  },
});

class InspectionMap extends React.Component {
  state = {
    image: null,
    imageSize: {},
    imageRotate: false,
    zoomScale: 1,
    translation: { x: 0, y: 0 }
  }

  componentDidMount() {
    if (this.props.mapUrl) {
      this.loadImage(this.props.mapUrl);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.mapUrl !== prevProps.mapUrl) {
      this.loadImage(this.props.mapUrl);
      this.setState({
        image: null,
        imageSize: {},
        imageRotate: false
      })
    }
  }

  loadImage = mapUrl => {
    const image = new window.Image();
    image.src = mapUrl;
    image.onload = () => {
      this.setState({
        image: image,
        imageSize: {
          width: image.width,
          height: image.height
        },
        // rotate the image card for tall images
        imageRotate: image.height > image.width * 1.5
      });
    };
  };

  getImageScale = (mapConfig, imageSize, displaySize) => l => {
    if (!mapConfig) return 0;
    const { origin, resolution: r } = mapConfig;
    if (!origin || !r) return 0;
    const ratio = displaySize.width / imageSize.width;
    return l / r * ratio;
  };

  cardRef = card => {
    this.card = card;
  }

  render() {
    const { classes, sensorClusters, mapUrl, mapConfig, mapLoading, route, sensorPoints,
      sensorType, showType, radius, maxWidth, maxHeight } = this.props;
    const { image, imageSize, zoomScale, translation, imageRotate } = this.state;
    let mapWidth = 0;
    let mapHeight = 0;
    if (imageSize && imageSize.width && imageSize.height) {
      if (!imageRotate) {
        mapWidth = maxWidth;
        mapHeight = mapWidth / imageSize.width * imageSize.height;
        if (mapHeight > maxHeight) {
          mapHeight = maxHeight;
          mapWidth = mapHeight / imageSize.height * imageSize.width;
        }
        if (this.card) {
          this.card.style.transform = '';
        }
      }
      else {
          mapHeight = maxWidth;
          mapWidth = mapHeight / imageSize.height * imageSize.width;
        if (mapWidth > maxHeight) {
          mapWidth = maxHeight;
          mapHeight = mapWidth / imageSize.width * imageSize.height;
        }
        if (this.card) {
          this.card.style.transform = `translateY(${mapWidth}px) rotate(-90deg)`;
          this.card.style.transformOrigin = '0% 0%';
        }
      }
    }

    return (
      <Card
        className={classes.card}
        ref={this.cardRef}
      >
        <Loader
          className={classes.loader}
          show={mapLoading || !image ? true : false}
          message={(mapLoading && getText('loading')) || (mapUrl && getText('loading_image'))
          || (!mapLoading && !mapUrl && getText('please_upload_map'))}
          hideContentOnLoad
        >
          {
            mapUrl &&
            <CardMedia
              src="img"
              className={classes.cardMedia}
            >
              {image &&
                <MapInteractionCSS
                  scale={zoomScale}
                  translation={translation}
                  onChange={({ scale, translation }) => {
                    this.setState({
                      zoomScale: scale,
                      // convert translation if rotated
                      translation: scale === 1 ? { x: 0, y: 0 } : !imageRotate ? translation : {
                        x: -translation.y - (scale - 1) * mapWidth,
                        y: translation.x
                      }
                    })
                  }
                  }
                  minScale={1}
                  maxScale={10}
                >
                  <Stage width={mapWidth} height={mapHeight} x={0} y={0}>
                    <Layer>
                      <Image x={0} y={0} image={image} width={mapWidth} height={mapHeight} />
                    </Layer>
                    <Layer>
                      <RobotPatrolRoute
                        route={route}
                        getImagePosition={getImagePosition(mapConfig, imageSize, {
                          width: mapWidth,
                          height: mapHeight
                        })}
                        zoomScale={zoomScale}
                        showWayPoints={false}
                      />
                      {
                        showType === 'route' &&
                        <SensorActualRoute
                          sensorPoints={sensorPoints}
                          sensorType={sensorType}
                          getImagePosition={getImagePosition(mapConfig, imageSize, {
                            width: mapWidth,
                            height: mapHeight
                          })}
                          zoomScale={zoomScale}
                        />
                      }
                    </Layer>
                  </Stage>
                  {
                    showType === 'cluster' &&
                    <SensorHeatMap
                      width={mapWidth}
                      height={mapHeight}
                      zoomScale={zoomScale}
                      sensorClusters={sensorClusters}
                      imageRotate={imageRotate}
                      sensorType={sensorType}
                      radius={radius}
                      getImagePosition={getImagePosition(mapConfig, imageSize, {
                        width: mapWidth,
                        height: mapHeight
                      })}
                      getImageScale={this.getImageScale(mapConfig, imageSize, {
                        width: mapWidth,
                        height: mapHeight
                      })}
                    />
                  }
                </MapInteractionCSS>
              }
            </CardMedia>
          }
        </Loader>
      </Card>
    );
  }
};

export default withStyles(styles)(InspectionMap);
