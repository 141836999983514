export const GET_SITE_REQUEST = 'SITE_GET_SITE_REQUEST';
export const GET_SITE_SUCCESS = 'SITE_GET_SITE_SUCCESS';
export const GET_SITE_FAILURE = 'SITE_GET_SITE_FAILURE';
export const CREATE_SITE_REQUEST = 'SITE_CREATE_SITE_REQUEST';
export const CREATE_SITE_SUCCESS = 'SITE_CREATE_SITE_SUCCESS';
export const CREATE_SITE_FAILURE = 'SITE_CREATE_SITE_FAILURE';
export const DELETE_SITE_REQUEST = 'SITE_DELETE_SITE_REQUEST';
export const DELETE_SITE_SUCCESS = 'SITE_DELETE_SITE_SUCCESS';
export const DELETE_SITE_FAILURE = 'SITE_DELETE_SITE_FAILURE';
export const UPDATE_SITE_REQUEST = 'SITE_UPDATE_SITE_REQUEST';
export const UPDATE_SITE_SUCCESS = 'SITE_UPDATE_SITE_SUCCESS';
export const UPDATE_SITE_FAILURE = 'SITE_UPDATE_SITE_FAILURE';
export const ASSIGN_MANAGERS_REQUEST = 'SITE_ASSIGN_MANAGERS_REQUEST';
export const ASSIGN_MANAGERS_SUCCESS = 'SITE_ASSIGN_MANAGERS_SUCCESS';
export const ASSIGN_MANAGERS_FAILURE = 'SITE_ASSIGN_MANAGERS_FAILURE';

export const GET_MANAGER_REQUEST = 'SITE_GET_MANAGER_REQUEST';
export const GET_MANAGER_SUCCESS = 'SITE_GET_MANAGER_SUCCESS';
export const GET_MANAGER_FAILURE = 'SITE_GET_MANAGER_FAILURE';
export const CREATE_MANAGER_REQUEST = 'SITE_CREATE_MANAGER_REQUEST';
export const CREATE_MANAGER_SUCCESS = 'SITE_CREATE_MANAGER_SUCCESS';
export const CREATE_MANAGER_FAILURE = 'SITE_CREATE_MANAGER_FAILURE';
export const DELETE_MANAGER_REQUEST = 'SITE_DELETE_MANAGER_REQUEST';
export const DELETE_MANAGER_SUCCESS = 'SITE_DELETE_MANAGER_SUCCESS';
export const DELETE_MANAGER_FAILURE = 'SITE_DELETE_MANAGER_FAILURE';

export const GET_ROBOT_REQUEST = 'SITE_GET_ROBOT_REQUEST';
export const GET_ROBOT_SUCCESS = 'SITE_GET_ROBOT_SUCCESS';
export const GET_ROBOT_FAILURE = 'SITE_GET_ROBOT_FAILURE';
export const UPDATE_ROBOT_REQUEST = 'SITE_UPDATE_ROBOT_REQUEST';
export const UPDATE_ROBOT_SUCCESS = 'SITE_UPDATE_ROBOT_SUCCESS';
export const UPDATE_ROBOT_FAILURE = 'SITE_UPDATE_ROBOT_FAILURE';

export const GET_BOX_REQUEST = 'SITE_GET_BOX_REQUEST';
export const GET_BOX_SUCCESS = 'SITE_GET_BOX_SUCCESS';
export const GET_BOX_FAILURE = 'SITE_GET_BOX_FAILURE';
export const UPDATE_BOX_REQUEST = 'SITE_UPDATE_BOX_REQUEST';
export const UPDATE_BOX_SUCCESS = 'SITE_UPDATE_BOX_SUCCESS';
export const UPDATE_BOX_FAILURE = 'SITE_UPDATE_BOX_FAILURE';

export const SET_CUR_SITE = 'SITE_SET_CUR_SITE';

export const GET_SITE_BUILDING_REQUEST = 'GET_SITE_BUILDING_REQUEST';
export const GET_SITE_BUILDING_SUCCESS = 'GET_SITE_BUILDING_SUCCESS';
export const GET_SITE_BUILDING_FAILURE = 'GET_SITE_BUILDING_FAILURE';
export const GET_SITE_FLOOR_REQUEST = 'GET_SITE_FLOOR_REQUEST';
export const GET_SITE_FLOOR_SUCCESS = 'GET_SITE_FLOOR_SUCCESS';
export const GET_SITE_FLOOR_FAILURE = 'GET_SITE_FLOOR_FAILURE';

export const SAVE_SITE_FLOORS = 'SAVE_SITE_FLOORS'
