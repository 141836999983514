import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import RoutineHistoryFilter from './RoutineHistoryFilter';
import RoutineHistoryList from './RoutineHistoryList';

const styles = theme => ({

});

const InspectionPanel = props => {
  const { classes, showType } = props;
  
  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      spacing={1}
    >
      <Grid item >
        <RoutineHistoryFilter showType={showType} />
      </Grid>
      <Grid item>
        <RoutineHistoryList />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(InspectionPanel);
