import {makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 0 0 0',
  },
  complexBox: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    overflow: 'hidden',
    boxSizing: 'border-box',
    height: 40,
    padding: 1,
    borderWidth: '1px !important',
    borderRight: 'none',
    backgroundColor: '#fff',
    position: 'relative',
    '&:hover': {
      borderColor: theme.palette.grey[700],
    },
    '&.active': {
      padding: 0,
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
      display: 'none',
    },
    '& .MuiInputBase-input': {
      padding: 7,
    },
  },
  searchButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    minWidth: 48,
    paddingLeft: 0,
    paddingRight: 0,
  },
  timefilter: {
    '&.selection': {
      backgroundColor: 'red',
    },
    '&.active': {
      backgroundColor: 'red',
    },
    backgroundColor: 'red',
  },
  eventTypeSelect: {
    width: 330,
  },
  divider: {
    width: 0,
    height: 24,
    borderLeft: '1px solid #E0E0E0',
  },
  activeButton: {
    backgroundColor: '#fff',
    textTransform: 'none',
    width: 60,
    borderColor: 'rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  deactiveButton: {
    backgroundColor: 'rgba(0,0,0,0.06)',
    color: 'rgba(0, 0, 0, 0.38)',
    textTransform: 'none',
    width: 60,
    borderColor: 'rgba(0,0,0,0.06)',
    '&:hover': {
      backgroundColor: 'rgb(30,0,0,0.06)',
    },
  },
  modal: {
    minWidth: 'auto',
    height: 32,
    paddingTop: 7,
    paddingBottom: 7,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 4,
  },
  faceContainer: {
    position: 'relative',
    margin: '0 8px 12px 0',
    // padding: '0 !important',
    display: 'flex',
  },
  face: {
    width: 120,
    height: 120,
    borderRadius: 8,
  },
  clearFace: {
    position: 'absolute',
    top: 8,
    left: 8,
    background: '#c6cdf3',
    cursor: 'pointer',
    '& svg': {
      verticalAlign: 'top',
    },
  },
  editFace: {
    cursor: 'pointer',
    marginLeft: 8,
    padding: '2px 0',
  },
  switch: {
    '& p': {
      width: 60,
    },
  },
  input: {
    width: '100%',
    '& input': {
      paddingTop: 6,
      paddingBottom: 6,
      lineHeight: 16,
      height: 16,
      paddingLeft: 8,
      fontSize: 14,
    },
    '&>div': {
      paddingTop: '2px !important',
      paddingBottom: '2px !important',
    },
    '& fieldset': {
      borderRadius: 16,
      borderWidth: '1px !important',
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
  },
  lprAction: {
    '& input': {
      color: theme.palette.primary.main,
    },
    '&>div': {
      borderTopLeftRadius: 16,
      borderBottomLeftRadius: 16,
      backgroundColor: theme.palette.primary.light,
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  endAdornment: {
    top: 'calc(50% - 13px)',
    right: 0,
    position: 'absolute',
  },
  actionBtn: {
    color: theme.palette.primary.main,
  },
  searchButtonLPR: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    minWidth: 34,
    paddingLeft: 0,
    paddingRight: 0,
  },
  iconFont: {
    fontSize: '14px',
  },
  employeeGroup: {
    position: 'absolute',
    bottom: 0,
    color: 'white',
    backgroundImage: 'linear-gradient(to top, rgba(0, 0, 30, 1), transparent)',
    width: 120,
    height: 30,
    fontSize: 12,
    display: 'inline-block',
    overflow: 'hidden',
    boxSizing: 'border-box',
    padding: '12px 6px 3px 6px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  mr1: {
    marginRight: 5,
    verticalAlign: 'bottom',
  },
  licenseStart: {
    fontSize: theme.spacing(1.75),
    marginRight: theme.spacing(-0.75),
    color: theme.palette.primary.main,
  },
  contact: {
    position: 'absolute',
    bottom: 0,
    height: 24,
    minWidth: 24,
    backgroundColor: '#fff',
    borderRadius: '0 6px 0 6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  employee: {
    fontSize: 16,
  },
  contactIcon: {
    width: 16,
    height: 16,
  },
  flag: {
    color: theme.palette.error.dark,
    fontSize: 16,
  },
}));

export default useStyles;
