import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withStyles, Grid, Typography, TextField, MenuItem } from '@material-ui/core';
import { getText } from 'src/utils/MultilingualLoader';
import ContainedButton from 'src/components/Buttons/ContainedButton';

const styles = theme => ({
  textfield: {
    width: '150px'
  },
});

const GotoOptions = props => {
  const { classes, onGoTo, onCancel } = props;
  const [waypoint, setWaypoint] = useState({});
  const { selected, robotCurMaps } = useSelector(state => state.robot);
  const { curSite } = useSelector(state => state.site);
  const targetList = useSelector(state => state.target.targetOptions[curSite]);
  const curMap = selected && robotCurMaps && robotCurMaps[selected.id];
  const allWayPoints = (curMap && curMap.mapRoute && curMap.mapRoute.way_points) || [];
  // ignore the last waypoint since it is the same with the start waypoint
  const showWaypoints = (allWayPoints && allWayPoints.length > 2 && allWayPoints.slice(0, allWayPoints.length - 1)) || allWayPoints;
  const waypointName = getWaypointName(waypoint);

  function getWaypointName(waypoint) {
    let name = waypoint.name || '';
    waypoint.actions && waypoint.actions.some(action => {
      const targetId = action.arg && action.arg.target_id;
      const target = targetId && targetList && targetList.find(target => target.id === parseInt(targetId));
      const targetName = target && target.label;
      if (targetName || action.name) {
        name = targetName || action.name;
        return true;
      }
      return false;
    });
    return name;
  }

  function handleConfirm() {
    const point = (waypoint && waypoint.point) || {}
    onGoTo(point.x, point.y);
  }
  
  return (
    <>
      <Grid item>
        <Typography viriant="body2">
          {getText('way_point')}
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          className={classes.textfield}
          select={true}
          value={waypointName}
        >
          {showWaypoints.map((waypoint, index) => {
            const name = getWaypointName(waypoint);
            if (name) {
              return (
                <MenuItem key={index} value={name} onClick={() => setWaypoint(waypoint)}>
                  {name}
                </MenuItem>
              );
            }
            else {
              return null;
            }
          })}
        </TextField>
      </Grid>
      <Grid item>
        <ContainedButton
          onClick={handleConfirm}
        >
          {getText('confirm')}
        </ContainedButton>
      </Grid>
      <Grid item>
        <ContainedButton
          onClick={onCancel}
        >
          {getText('cancel')}
        </ContainedButton>
      </Grid>
    </>
  );
}

export default withStyles(styles)(GotoOptions);
