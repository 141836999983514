import moment from 'moment';
import { userActions } from 'src/redux/actions';

export function getUserInfo() {
  let userInfo = {};
  try {
    userInfo = JSON.parse(window.localStorage && window.localStorage.getItem('userInfo')) || {};
  } catch (e) {
    userActions.tokenExpired();
    return {};
  }
  if (userInfo.expire && moment.utc().isAfter(userInfo.expire)) {
    userActions.tokenExpired();
    return {};
  }
  return userInfo;
}
