import axios from 'axios';

const defaultConfig = {
    baseURL: process.env.REACT_APP_BASE_HOST,
    headers: { 'X-APP': 'TuringGuard' },
    withCredentials: true
};

class Auth {
    static create(config = defaultConfig) {
        return axios.create(config);
    }
}

export const auth = Auth.create();
