import React from 'react';

import { connect } from 'react-redux';
import { cameraActions } from 'src/redux/actions';
import LineSizeButton from './LineSizeButton';

const mapStateToProps = state => {
  const { filters } = state.case;
  return { filters };
};

const mapDispatchToProps = dispatch => {
  return {
    setLineSize: lineSize => {
      dispatch(cameraActions.setLineSize(lineSize));
    }
  }
};

const lineSizes = [
  1,
  2,
  3,
  4
];

class LineSizeButtonContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      anchorEl: null,
    }
  }

  handleOpenMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleOnClick = size => event => {
    this.props.setLineSize(size);
    this.handleCloseMenu();
  }

  render() {
    const { anchorEl } = this.state;
    return (
      <LineSizeButton
        lineSizes={lineSizes}
        handleOnClick={this.handleOnClick}
        handleOpenMenu={this.handleOpenMenu}
        handleCloseMenu={this.handleCloseMenu}
        anchorEl={anchorEl}
        />
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LineSizeButtonContainer);
