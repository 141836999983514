import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import RoutineHistoryTimePagination from "./RoutineHistoryTimePagination";
import RoutineHistoryTimeList from "./RoutineHistoryTimeList";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto'
  },
  timeList: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: '69vh',
  },
});

const RoutineHistoryTime = props => {
    const { classes } = props;
    return (
      <Grid
        container={true}
        justify="flex-start"
        direction="column"
        alignItems="stretch"
        spacing={0}
        className={classes.root}
       >
        <Grid item={true}  >
          <RoutineHistoryTimePagination />
        </Grid>
        <Grid item={true} className={classes.timeList}>
          <RoutineHistoryTimeList/>
        </Grid>
      </Grid>
    );
};

export default withStyles(styles)(RoutineHistoryTime);
