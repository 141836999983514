import React from 'react';
import { connect } from 'react-redux';

import { getDefaultMapCenter } from 'src/utils/MultilingualLoader';
import { siteActions } from 'src/redux/actions';
import SiteMap from './SiteMap';
import LoadingText from 'src/components/LoadingText';


const mapStateToProps = state => {
  return {
    sites: state.site.sites,
    siteLoading: state.site.siteLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurSite: siteId => dispatch(siteActions.setCurSite(siteId)),
  };
};

class SiteMapContainer extends React.Component {
  //If google API is not available
  componentDidCatch(error, info) {} 

  handleSiteClick = id => event => {
    this.props.setCurSite(id);
  }

  render() {
    const { sites, siteLoading } = this.props;
    if (siteLoading) return <LoadingText />
    let siteLocations = [];
    sites && sites.forEach(site => {
      let centerPosition = null;
      try {
        const num_points = site.polygons.reduce((totalNum, polygon) => totalNum + polygon.geo_points.length, 0);
        if (num_points > 0)
          centerPosition = {
            lat: site.polygons.reduce((totalLat, polygon) => totalLat + polygon.geo_points.reduce((subTotalLat, point) => subTotalLat + point.lat, 0), 0) / num_points,
            lng: site.polygons.reduce((totalLng, polygon) => totalLng + polygon.geo_points.reduce((subTotalLng, point) => subTotalLng + point.lng, 0), 0) / num_points
          }
      } catch(err) {}
      if (centerPosition) siteLocations.push({label: site.name, value: site.id, position: centerPosition})
    });
    const sitePositions = siteLocations.map(location => location.position);
    const defaultZoom = 5;
    let defaultCenter;
    if (sitePositions.length > 0) 
      defaultCenter = { 
        lat: sitePositions.map(position => position.lat).reduce((totalLat, lat) => totalLat + lat, 0) / sitePositions.length,
        lng: sitePositions.map(position => position.lng).reduce((totalLng, lng) => totalLng + lng, 0) / sitePositions.length,
      };
    else 
      defaultCenter = getDefaultMapCenter();

    return (
      <SiteMap
        // TODO: hide api key from component's property
        googleMapURL={`${process.env.REACT_APP_GOOGLE_MAP_API_URL}/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&v=3.exp`}
        loadingElement={<div style={{ height: '85vh' }} />}
        containerElement={<div style={{
          paddingBottom: 16,
          paddingLeft: 16,
          paddingRight: 16,
          flexGrow: 1,
          position: 'relative' 
        }} />}
        mapElement={<div style={{ height: '85vh' }} />}
        defaultZoom={defaultZoom}
        defaultCenter={defaultCenter}
        siteLocations={siteLocations}
        onSiteClick={this.handleSiteClick}
      /> 
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteMapContainer);
