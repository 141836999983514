import React from 'react';

// import {Case} from 'src/models/case';
import {MediaDisplay} from 'src/components/Vision/media';

export const DetailMediaComponent = React.memo(
  ({detail}) => {
    const snapshot = detail.event.mediums ? detail.event.mediums.find(v => v.name === 'snapshot') : undefined;
    const video = detail.event.mediums ? detail.event.mediums.find(v => v.name === 'video') : undefined;
    return <MediaDisplay video={video ? video.file.url : null} img={snapshot ? snapshot.file.url : null} />;
  },
  (prevProps, nextProps) => prevProps.detail.id === nextProps.detail.id
);
