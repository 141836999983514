import { robotConstants } from "src/redux/constants";
import { robotServices } from "src/services";
import { notificationActions, siteActions, messageActions } from ".";
import { getText } from "src/utils/MultilingualLoader";

export const deviceRequest = (robot_id) => {
  return {
    type: robotConstants.ROBOT_GET_MAP_REQUEST,
    robot: robot_id,
  };
};

export const updRobotNavFromServer = (updInfo) => {
  return {
    type: robotConstants.UPDATE_NAV,
    location: updInfo.location,
    robotId: updInfo.robot_id,
    signalStrength: updInfo.signal_strength,
    navState: {
      map_id: updInfo.map_id,
      route_id: updInfo.route_id,
      routine_id: updInfo.routine_id,
      routine_execution_id: updInfo.routine_execution_id,
    },
  };
};

export const clearRobotNav = (robotId) => {
  return {
    type: robotConstants.UPDATE_NAV,
    location: null,
    robotId,
  };
};

export const updRobotModeFromServer = (updInfo) => {
  return {
    type: robotConstants.UPDATE_MODE,
    mode: updInfo.targetMode,
    robotId: updInfo.robotId,
  };
};

export const getRobots = (filters) => {
  const request = () => {
    return {
      type: robotConstants.GET_ROBOT_REQUEST,
    };
  };

  const success = (robots) => {
    return {
      type: robotConstants.GET_ROBOT_SUCCESS,
      robots,
    };
  };

  const failure = () => {
    return {
      type: robotConstants.GET_ROBOT_FAILURE,
    };
  };

  return (dispatch, getState) => {
    dispatch(request());
    filters = siteActions.addSiteToFilter(
      filters ? filters : getState().robot.filters,
      getState()
    );
    robotServices
      .getRobots(filters)
      .then((robots) => {
        dispatch(success(robots));
      })
      .catch((error) => {
        dispatch(failure());
      });
  };
};

export const getAllRobots = () => {
  const success = (robots) => {
    return {
      type: robotConstants.GET_ALL_ROBOTS_SUCCESS,
      robots: (robots || []).map((robot) => ({
        ...robot,
        version: 0,
      })),
    };
  };

  return (dispatch) => {
    robotServices
      .getRobots()
      .then((robots) => {
        dispatch(success(robots));
      })
      .catch((error) => {});
  };
};

export const sortRobots = (sortColumn) => {
  return {
    type: robotConstants.SORT_ROBOTS,
    sortColumn: sortColumn,
  };
};

export const selectRobot = (robot) => {
  return {
    type: robotConstants.ROBOT_SELECTED,
    robot: robot,
  };
};

export const updateRobotStatus = (robot_id) => {
  const success = (robot) => {
    return {
      type: robotConstants.ROBOT_SELECTED_UPDATED,
      robot: robot_id,
      updated: robot,
    };
  };

  return (dispatch) => {
    robotServices
      .updateRobotStatus(robot_id)
      .then((robot) => {
        dispatch(success(robot));
      })
      .catch((err) => {});
  };
};

export const setFilter = (filters) => {
  return (dispatch) => {
    dispatch({ type: robotConstants.SET_FILTER, filters });
  };
};

export const handleVersionChanged = () => {
  return (dispatch, getState) => {
    const state = getState();
    const allRobots = state.robot.allRobots;
    if (allRobots.length > 0) {
      robotServices
        .getRobotDifferences({
          robots: allRobots.map((robot) => ({
            id: robot.id,
            version: robot.version,
          })),
        })
        .then((res) => {
          const versionMap = new Map(
            res.robots.map((robot) => [robot.id, robot.version])
          );
          dispatch({
            type: robotConstants.GET_ALL_ROBOTS_SUCCESS,
            robots: allRobots.map((robot) =>
              versionMap.has(robot.id)
                ? {
                    ...robot,
                    version: versionMap.get(robot.id),
                  }
                : robot
            ),
          });
          dispatch({
            type: robotConstants.UPDATE_ROBOTS_FROM_WS,
            robots: res.robots.map(robotServices.robotAdapter) || [],
          });
        })
        .catch(() => {});
    }
  };
};

export const getRobotConfigs = (robot_id) => {
  const request = () => {
    return {
      type: robotConstants.ROBOT_GET_CONFIGS_REQUEST,
      robot: robot_id,
    };
  };

  const success = (configs) => {
    return {
      type: robotConstants.ROBOT_GET_CONFIGS_SUCCESS,
      robot: robot_id,
      configs: configs,
    };
  };

  const failure = () => {
    return {
      type: robotConstants.ROBOT_GET_CONFIGS_FAILURE,
      robot: robot_id,
    };
  };

  return (dispatch) => {
    dispatch(request());
    robotServices
      .getRobotConfigs(robot_id)
      .then((configs) => {
        dispatch(success(configs));
      })
      .catch((err) => {
        dispatch(failure());
      });
  };
};

export const updateRobotConfigs = (robotId, updConfigs) => {
  const request = () => {
    return {
      type: robotConstants.ROBOT_UPDATE_CONFIGS_REQUEST,
      robotId,
    };
  };

  const success = (configs) => {
    return {
      type: robotConstants.ROBOT_UPDATE_CONFIGS_SUCCESS,
      robotId,
      configs,
    };
  };

  const failure = () => {
    return {
      type: robotConstants.ROBOT_UPDATE_CONFIGS_FAILURE,
      robotId,
    };
  };

  return (dispatch) => {
    dispatch(request());
    robotServices
      .updateRobotConfigs(robotId, updConfigs)
      .then((configs) => {
        dispatch(success(configs));
        dispatch(
          messageActions.showNotification(
            `${getText("robot_config")}${getText("words_space")}${getText(
              "succeeded"
            )}`,
            "success"
          )
        );
      })
      .catch((err) => {
        dispatch(failure());
        dispatch(
          messageActions.showNotification(
            `${getText("robot_config")}${getText("words_space")}${getText(
              "failed"
            )}`,
            "error"
          )
        );
      });
  };
};

export const getRobotDetectionConfigs = (robot_id) => {
  const success = (configs) => {
    return {
      type: robotConstants.ROBOT_GET_DETECTION_CONFIGS_SUCCESS,
      robot: robot_id,
      configs: configs,
    };
  };

  return (dispatch) => {
    robotServices
      .getRobotDetectionConfigs(robot_id)
      .then((configs) => {
        dispatch(success(configs));
      })
      .catch((err) => {});
  };
};

export const updateRobotDetectionConfigs = (robot_id, updConfigs) => {
  const request = () => {
    return {
      type: robotConstants.ROBOT_UPDATE_DETECTION_CONFIGS_REQUEST,
      robot: robot_id,
    };
  };

  const success = (configs) => {
    return {
      type: robotConstants.ROBOT_UPDATE_DETECTION_CONFIGS_SUCCESS,
      robot: robot_id,
      configs: configs,
    };
  };

  const failure = () => {
    return {
      type: robotConstants.ROBOT_UPDATE_DETECTION_CONFIGS_FAILURE,
      robot: robot_id,
    };
  };

  return (dispatch) => {
    dispatch(request());
    robotServices
      .updateRobotDetectionConfigs(robot_id, updConfigs)
      .then((configs) => {
        dispatch(success(configs));
        dispatch(
          notificationActions.addNotification(
            `${getText("robot_detection_config")}${getText(
              "words_space"
            )}${getText("succeeded")}`
          )
        );
      })
      .catch((err) => {
        dispatch(failure());
        dispatch(
          notificationActions.addNotification(
            `${getText("robot_detection_config")}${getText(
              "words_space"
            )}${getText("failed")}`
          )
        );
      });
  };
};

export const getRobotAlgos = (robot_id) => {
  const request = () => {
    return {
      type: robotConstants.ROBOT_GET_ALGOS_REQUEST,
      robot: robot_id,
    };
  };

  const success = (algos) => {
    return {
      type: robotConstants.ROBOT_GET_ALGOS_SUCCESS,
      robot: robot_id,
      algos,
    };
  };

  const failure = () => {
    return {
      type: robotConstants.ROBOT_GET_ALGOS_FAILURE,
      robot: robot_id,
    };
  };

  return (dispatch) => {
    dispatch(request());
    robotServices
      .getRobotAlgos(robot_id)
      .then((algos) => {
        dispatch(success(algos));
      })
      .catch((err) => {
        dispatch(failure());
      });
  };
};

export const getRobotConfigMapsAndRoutes = (robot_id) => {
  const request = () => {
    return {
      type: robotConstants.ROBOT_GET_MAPS_REQUEST,
      robot: robot_id,
    };
  };

  const success = (maps) => {
    return {
      type: robotConstants.ROBOT_GET_MAPS_SUCCESS,
      robot: robot_id,
      maps,
    };
  };

  const failure = () => {
    return {
      type: robotConstants.ROBOT_GET_MAPS_FAILURE,
      robot: robot_id,
    };
  };

  return (dispatch) => {
    dispatch(request());
    Promise.all([
      robotServices.getRobotMapsWithRoutes(robot_id),
      robotServices.getRobotMaps(robot_id),
    ])
      .then((maps) => {
        // combine maps with routes and maps without route
        maps[1].forEach((map) => {
          if (!maps[0].find((mapWithRoutes) => mapWithRoutes.id === map.id)) {
            delete map.mapRoute;
            map.mapRoutes = [];
            maps[0].push(map);
          }
        });
        dispatch(success(maps[0]));
      })
      .catch((err) => {
        dispatch(failure());
      });
  };
};

export const getRobotConfigMap = (robotId, mapId, routeId = null) => {
  const request = () => {
    return {
      type: robotConstants.ROBOT_GET_CONFIG_MAP_REQUEST,
      robotId,
    };
  };

  const success = (mapData) => {
    return {
      type: robotConstants.ROBOT_GET_CONFIG_MAP_SUCCESS,
      robotId,
      mapData,
    };
  };

  const failure = () => {
    return {
      type: robotConstants.ROBOT_GET_CONFIG_MAP_FAILURE,
      robotId,
    };
  };

  return (dispatch) => {
    dispatch(request());
    robotServices
      .getRobotConfigMap(robotId, mapId, routeId)
      .then((mapData) => {
        dispatch(success(mapData));
      })
      .catch((err) => {
        dispatch(failure());
      });
  };
};

export const updateMap = (robotId, mapId, data, routeId = null) => {
  const request = () => {
    return {
      type: robotConstants.ROBOT_UPDATE_CONFIG_MAP_REQUEST,
      robotId,
      mapId,
    };
  };

  const success = (mapData) => {
    return {
      type: robotConstants.ROBOT_UPDATE_CONFIG_MAP_SUCCESS,
      robotId,
      mapData,
    };
  };

  const failure = () => {
    return {
      type: robotConstants.ROBOT_UPDATE_CONFIG_MAP_FAILURE,
      robotId,
      mapId,
    };
  };

  return (dispatch) => {
    dispatch(request());
    return robotServices
      .updateMap(robotId, mapId, data, routeId)
      .then((mapData) => {
        dispatch(success(mapData));
      })
      .catch((err) => {
        dispatch(failure());
      });
  };
};

export const deleteMap = (robotId, mapId) => {
  const success = () => {
    return {
      type: robotConstants.DELETE_MAP_SUCCESS,
      robotId,
      mapId,
    };
  };

  return (dispatch) => {
    robotServices
      .deleteMap(robotId, mapId)
      .then(() => {
        dispatch(success());
      })
      .catch((err) => {
        dispatch(
          notificationActions.addNotification(
            `${getText("delete_map")}${getText("words_space")}${getText(
              "failed"
            )}`
          )
        );
      });
  };
};

export const backupMap = (robotId, mapId) => {
  return (dispatch) => {
    const data = {
      robot_id: robotId,
      map_id: mapId,
    };
    robotServices
      .backupMap(data)
      .then(() => {
        dispatch(
          notificationActions.addNotification(
            `${getText("backup_map")}${getText("words_space")}${getText(
              "succeeded"
            )}`
          )
        );
      })
      .catch((err) => {
        dispatch(
          notificationActions.addNotification(
            `${getText("backup_map")}${getText("words_space")}${getText(
              "failed"
            )}`
          )
        );
      });
  };
};

export const updateRoute = (robotId, mapId, routeId, data) => {
  const success = (route) => {
    return {
      type: robotConstants.UPDATE_ROUTE_SUCCESS,
      robotId,
      mapId,
      routeId,
      route,
    };
  };

  return (dispatch) => {
    robotServices
      .updateRoute(robotId, mapId, routeId, data)
      .then((route) => {
        dispatch(success(route));
        dispatch(
          notificationActions.addNotification(
            `${getText("update_route")}${getText("words_space")}${getText(
              "succeeded"
            )}`
          )
        );
      })
      .catch((err) => {
        dispatch(
          notificationActions.addNotification(
            `${getText("update_route")}${getText("words_space")}${getText(
              "failed"
            )}`
          )
        );
      });
  };
};

export const deleteRoute = (robotId, mapId, routeId) => {
  const success = () => {
    return {
      type: robotConstants.DELETE_ROUTE_SUCCESS,
      robotId,
      mapId,
      routeId,
    };
  };

  return (dispatch) => {
    robotServices
      .deleteRoute(robotId, mapId, routeId)
      .then(() => {
        dispatch(success());
      })
      .catch((err) => {
        dispatch(
          notificationActions.addNotification(
            `${getText("delete_route")}${getText("words_space")}${getText(
              "failed"
            )}`
          )
        );
      });
  };
};

export const backupRoute = (robotId, mapId, routeId) => {
  return (dispatch) => {
    const data = {
      robot_id: robotId,
      map_id: mapId,
      route_id: routeId,
    };
    robotServices
      .backupRoute(data)
      .then(() => {
        dispatch(
          notificationActions.addNotification(
            `${getText("backup_route")}${getText("words_space")}${getText(
              "succeeded"
            )}`
          )
        );
      })
      .catch((err) => {
        dispatch(
          notificationActions.addNotification(
            `${getText("backup_route")}${getText("words_space")}${getText(
              "failed"
            )}`
          )
        );
      });
  };
};

export const copyMap = (from, to, mapId) => {
  const success = (map) => {
    return {
      type: robotConstants.COPY_MAP_SUCCESS,
      robotId: to,
      map,
    };
  };

  return (dispatch) => {
    robotServices
      .copyMap({ from, to, map_id: mapId })
      .then((map) => {
        dispatch(success(map));
        dispatch(
          notificationActions.addNotification(
            `${getText("copy_map")}${getText("words_space")}${getText(
              "succeeded"
            )}`
          )
        );
      })
      .catch((err) => {
        dispatch(
          notificationActions.addNotification(
            `${getText("copy_map")}${getText("words_space")}${getText(
              "failed"
            )}`
          )
        );
      });
  };
};

export const copyRoute = (from, to, mapIdFrom, mapIdTo, routeId) => {
  const success = (route) => {
    return {
      type: robotConstants.COPY_ROUTE_SUCCESS,
      robotId: to,
      mapId: mapIdTo,
      route,
    };
  };

  return (dispatch) => {
    const data = {
      from,
      to,
      map_id_from: mapIdFrom,
      map_id_to: mapIdTo,
      route_id: routeId,
    };
    robotServices
      .copyRoute(data)
      .then((route) => {
        dispatch(success(route));
        dispatch(
          notificationActions.addNotification(
            `${getText("copy_route")}${getText("words_space")}${getText(
              "succeeded"
            )}`
          )
        );
      })
      .catch((err) => {
        dispatch(
          notificationActions.addNotification(
            `${getText("copy_route")}${getText("words_space")}${getText(
              "failed"
            )}`
          )
        );
      });
  };
};

export const getBackupMaps = (userId) => {
  const request = () => {
    return {
      type: robotConstants.GET_BACKUP_MAPS_REQUEST,
      userId,
    };
  };

  const success = (mapList) => {
    return {
      type: robotConstants.GET_BACKUP_MAPS_SUCCESS,
      userId,
      mapList,
    };
  };

  const failure = () => {
    return {
      type: robotConstants.GET_BACKUP_MAPS_FAILURE,
      userId,
    };
  };

  return (dispatch) => {
    dispatch(request());
    robotServices
      .getBackupMaps(userId)
      .then((mapList) => {
        dispatch(success(mapList));
      })
      .catch((err) => {
        dispatch(failure());
      });
  };
};

export const getBackupRoutes = (userId, mapId) => {
  const success = (routes) => {
    return {
      type: robotConstants.GET_BACKUP_ROUTES_SUCCESS,
      userId,
      mapId,
      routes,
    };
  };
  return (dispatch) => {
    robotServices
      .getBackupRoutes(userId, mapId)
      .then((routes) => {
        dispatch(success(routes));
      })
      .catch((err) => {});
  };
};

export const restoreMap = (robotId, mapId) => {
  const success = (map) => {
    return {
      type: robotConstants.RESTORE_MAP_SUCCESS,
      robotId,
      map,
    };
  };

  return (dispatch) => {
    const data = {
      robot_id: robotId,
      map_id: mapId,
    };
    robotServices
      .restoreMap(data)
      .then((map) => {
        dispatch(success(map));
        dispatch(
          notificationActions.addNotification(
            `${getText("restore_map")}${getText("words_space")}${getText(
              "succeeded"
            )}`
          )
        );
      })
      .catch((err) => {
        dispatch(
          notificationActions.addNotification(
            `${getText("restore_map")}${getText("words_space")}${getText(
              "failed"
            )}`
          )
        );
      });
  };
};

export const restoreRoute = (robotId, mapIdFrom, mapIdTo, routeId) => {
  return (dispatch) => {
    const data = {
      robot_id: robotId,
      map_id_from: mapIdFrom,
      map_id_to: mapIdTo,
      route_id: routeId,
    };
    robotServices
      .restoreRoute(data)
      .then(() => {
        dispatch(
          notificationActions.addNotification(
            `${getText("restore_route")}${getText("words_space")}${getText(
              "succeeded"
            )}`
          )
        );
      })
      .catch((err) => {
        dispatch(
          notificationActions.addNotification(
            `${getText("restore_route")}${getText("words_space")}${getText(
              "failed"
            )}`
          )
        );
      });
  };
};

export const deleteBackupMap = (userId, mapId) => {
  const success = () => {
    return {
      type: robotConstants.DELETE_BACKUP_MAP_SUCCESS,
      userId,
      mapId,
    };
  };

  return (dispatch) => {
    robotServices
      .deleteBackupMap(userId, mapId)
      .then(() => {
        dispatch(success());
        dispatch(
          notificationActions.addNotification(
            `${getText("robot.backup_map.delete_backup_map")}${getText(
              "words_space"
            )}${getText("succeeded")}`
          )
        );
      })
      .catch((err) => {
        dispatch(
          notificationActions.addNotification(
            `${getText("robot.backup_map.delete_backup_map")}${getText(
              "words_space"
            )}${getText("failed")}`
          )
        );
      });
  };
};

export const deleteBackupRoute = (userId, mapId, routeId) => {
  const success = () => {
    return {
      type: robotConstants.DELETE_BACKUP_ROUTE_SUCCESS,
      userId,
      mapId,
      routeId,
    };
  };

  return (dispatch) => {
    robotServices
      .deleteBackupRoute(userId, mapId, routeId)
      .then(() => {
        dispatch(success());
        dispatch(
          notificationActions.addNotification(
            `${getText("robot.backup_map.delete_backup_route")}${getText(
              "words_space"
            )}${getText("succeeded")}`
          )
        );
      })
      .catch((err) => {
        dispatch(
          notificationActions.addNotification(
            `${getText("robot.backup_map.delete_backup_route")}${getText(
              "words_space"
            )}${getText("failed")}`
          )
        );
      });
  };
};

export const updateBackupMap = (userId, mapId, data) => {
  const success = (map) => {
    return {
      type: robotConstants.UPDATE_BACKUP_MAP_SUCCESS,
      userId,
      map,
    };
  };

  return (dispatch) => {
    robotServices
      .updateBackupMap(userId, mapId, data)
      .then((map) => {
        dispatch(success(map));
        dispatch(
          notificationActions.addNotification(
            `${getText("robot.backup_map.update_backup_map")}${getText(
              "words_space"
            )}${getText("succeeded")}`
          )
        );
      })
      .catch((err) => {
        dispatch(
          notificationActions.addNotification(
            `${getText("robot.backup_map.update_backup_map")}${getText(
              "words_space"
            )}${getText("failed")}`
          )
        );
      });
  };
};

export const updateBackupRoute = (userId, mapId, routeId, data) => {
  const success = (route) => {
    return {
      type: robotConstants.UPDATE_BACKUP_ROUTE_SUCCESS,
      userId,
      mapId,
      routeId,
      route,
    };
  };

  return (dispatch) => {
    robotServices
      .updateBackupRoute(userId, mapId, routeId, data)
      .then((route) => {
        dispatch(success(route));
        dispatch(
          notificationActions.addNotification(
            `${getText("robot.backup_map.update_backup_route")}${getText(
              "words_space"
            )}${getText("succeeded")}`
          )
        );
      })
      .catch((err) => {
        dispatch(
          notificationActions.addNotification(
            `${getText("robot.backup_map.update_backup_route")}${getText(
              "words_space"
            )}${getText("failed")}`
          )
        );
      });
  };
};
