export const GET_ROBOT_REQUEST = "ROBOT_GET_ROBOT_REQUEST";
export const GET_ROBOT_SUCCESS = "ROBOT_GET_ROBOT_SUCCESS";
export const GET_ROBOT_FAILURE = "ROBOT_GET_ROBOT_FAILURE";
export const ROBOT_SELECTED = "ROBOT_SELECTED";
export const ROBOT_SELECTED_UPDATED = "ROBOT_SELECTED_UPDATED";
export const SET_FILTER = "ROBOT_SET_FILTER";
export const SORT_ROBOTS = "ROBOT_SORT_ROBOTS";
export const UPDATE_NAV = "ROBOT_UPDATE_NAV_FROM_SERVER";
export const UPDATE_MODE = "ROBOT_UPDATE_MODE_FROM_SERVER"
export const UPDATE_ROBOTS = "ROBOT_UPDATE_ROBOTS";
export const ROBOT_GET_CONFIG_MAP_REQUEST = "ROBOT_GET_CONFIG_MAP_REQUEST";
export const ROBOT_GET_CONFIG_MAP_SUCCESS = "ROBOT_GET_CONFIG_MAP_SUCCESS";
export const ROBOT_GET_CONFIG_MAP_FAILURE = "ROBOT_GET_CONFIG_MAP_FAILURE";
export const ROBOT_UPDATE_CONFIG_MAP_REQUEST = "ROBOT_UPDATE_CONFIG_MAP_REQUEST";
export const ROBOT_UPDATE_CONFIG_MAP_SUCCESS = "ROBOT_UPDATE_CONFIG_MAP_SUCCESS";
export const ROBOT_UPDATE_CONFIG_MAP_FAILURE = "ROBOT_UPDATE_CONFIG_MAP_FAILURE";
export const ROBOT_GET_CONFIGS_REQUEST = 'ROBOT_GET_CONFIGS_REQUEST';
export const ROBOT_GET_CONFIGS_SUCCESS = 'ROBOT_GET_CONFIGS_SUCCESS';
export const ROBOT_GET_CONFIGS_FAILURE = 'ROBOT_GET_CONFIGS_FAILURE';
export const ROBOT_UPDATE_CONFIGS_REQUEST = 'ROBOT_UPDATE_CONFIGS_REQUEST';
export const ROBOT_UPDATE_CONFIGS_SUCCESS = 'ROBOT_UPDATE_CONFIGS_SUCCESS';
export const ROBOT_UPDATE_CONFIGS_FAILURE = 'ROBOT_UPDATE_CONFIGS_FAILURE';
export const ROBOT_GET_DETECTION_CONFIGS_SUCCESS = 'ROBOT_GET_DETECTION_CONFIGS_SUCCESS';
export const ROBOT_UPDATE_DETECTION_CONFIGS_REQUEST = 'ROBOT_UPDATE_DETECTION_CONFIGS_REQUEST';
export const ROBOT_UPDATE_DETECTION_CONFIGS_SUCCESS = 'ROBOT_UPDATE_DETECTION_CONFIGS_SUCCESS';
export const ROBOT_UPDATE_DETECTION_CONFIGS_FAILURE = 'ROBOT_UPDATE_DETECTION_CONFIGS_FAILURE';
export const ROBOT_GET_ALGOS_REQUEST = 'ROBOT_GET_ALGOS_REQUEST';
export const ROBOT_GET_ALGOS_SUCCESS = 'ROBOT_GET_ALGOS_SUCCESS';
export const ROBOT_GET_ALGOS_FAILURE = 'ROBOT_GET_ALGOS_FAILURE';
export const ROBOT_GET_MAPS_REQUEST = 'ROBOT_GET_MAPS_REQUEST';
export const ROBOT_GET_MAPS_SUCCESS = 'ROBOT_GET_MAPS_SUCCESS';
export const ROBOT_GET_MAPS_FAILURE = 'ROBOT_GET_MAPS_FAILURE';
export const GET_ALL_ROBOTS_SUCCESS = 'ROBOT_GET_ALL_ROBOTS_SUCCESS';
export const UPDATE_ROUTE_SUCCESS = 'UPDATE_ROUTE_SUCCESS';
export const DELETE_MAP_SUCCESS = "ROBOT_DELETE_MAP_SUCCESS";
export const DELETE_ROUTE_SUCCESS = "ROBOT_DELETE_ROUTE_SUCCESS";
export const COPY_MAP_SUCCESS = "ROBOT_COPY_MAP_SUCCESS";
export const COPY_ROUTE_SUCCESS = "ROBOT_COPY_ROUTE_SUCCESS";
export const GET_BACKUP_MAPS_REQUEST = "ROBOT_GET_BACKUP_MAPS_REQUEST";
export const GET_BACKUP_MAPS_SUCCESS = "ROBOT_GET_BACKUP_MAPS_SUCCESS";
export const GET_BACKUP_MAPS_FAILURE = "ROBOT_GET_BACKUP_MAPS_FAILURE";
export const GET_BACKUP_ROUTES_SUCCESS = "ROBOT_GET_BACKUP_ROUTES_SUCCESS";
export const RESTORE_MAP_SUCCESS = 'ROBOT_RESTORE_MAP_SUCCESS';
export const UPDATE_ROBOTS_FROM_WS = 'ROBOT_UPDATE_ROBOTS_FROM_WS';
export const DELETE_BACKUP_MAP_SUCCESS = 'ROBOT_DELETE_BACKUP_MAP_SUCCESS';
export const DELETE_BACKUP_ROUTE_SUCCESS = 'ROBOT_DELETE_BACKUP_ROUTE_SUCCESS';
export const UPDATE_BACKUP_MAP_SUCCESS = 'ROBOT_UPDATE_BACKUP_MAP_SUCCESS';
export const UPDATE_BACKUP_ROUTE_SUCCESS = 'ROBOT_UPDATE_BACKUP_ROUTE_SUCCESS';

