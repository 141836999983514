import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Messages = ({ message, level, handleClose }) => {
  return (
    <Snackbar
      open={Boolean(message)}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      style={{ marginTop: 100 }}
    >
      <Alert onClose={handleClose} severity={level}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Messages;
