import React from 'react';
import { connect } from 'react-redux';

import Robots from './Robots';
import SiteMap from '../SiteMap';
import { targetActions } from 'src/redux/actions';

const mapStateToProps = state => {
  return {
    curSite: state.site.curSite,
  };
};

const mapDispatchToProps = dispatch => {
  return { 
    getTargetOptions: ()　=> {
      dispatch(targetActions.getTargetOptions());
    },
  };
};

class RobotsContainer extends React.Component {

  componentDidUpdate(prevProps) {
    const { curSite, getTargetOptions } = this.props;

    if (curSite && (!prevProps.curSite || curSite !== prevProps.curSite)) {
      getTargetOptions();
    }
  }

  render() {
    const { curSite } = this.props;
    return (
      <>
        {curSite ? <Robots /> : <SiteMap />}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RobotsContainer);