import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Grid, Card, CardMedia, Button, IconButton, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import {
  NavigateBefore as PrevIcon, NavigateNext as NextIcon, Edit as EditIcon, Check as SubmitIcon
} from '@material-ui/icons';
import Loader from 'react-loader-advanced';

import { robotActions } from 'src/redux/actions';
import { getText } from 'src/utils/MultilingualLoader';
import { getImagePosition } from '../RobotMap';
import DropDownSelectorHorizontal from 'src/components/Selectors/DropDownSelectorHorizontal';
import RobotMapItem from './RobotMapItem';
import SearchBar from './SearchBar';
import RestoreRoute from './RestoreRoute';

const mapStateToProps = state => {
  const { selected, backupMapLists } = state.robot;
  const backupMapList = selected && backupMapLists[selected.user_id];
  return { robot: selected, backupMapList };
}

const mapDispatchToProps = {
  getBackupMaps: userId => robotActions.getBackupMaps(userId),
  getBackupRoutes: (userId, mapId) => robotActions.getBackupRoutes(userId, mapId),
  restoreMap: (robotId, mapId) => robotActions.restoreMap(robotId, mapId),
  restoreRoute: (robotId, mapIdFrom, mapIdTo, routeId) => robotActions.restoreRoute(robotId, mapIdFrom, mapIdTo, routeId),
  deleteMap: (userId, mapId) => robotActions.deleteBackupMap(userId, mapId),
  deleteRoute: (userId, mapId, routeId) => robotActions.deleteBackupRoute(userId, mapId, routeId),
  updateMap: (userId, mapId, data) => robotActions.updateBackupMap(userId, mapId, data),
  updateRoute: (userId, mapId, routeId, data) => robotActions.updateBackupRoute(userId, mapId, routeId, data)
}

const styles = theme => ({
  searchBar: {
    height: (window.innerHeight - 48) * 0.05
  },
  map: {
    flex: 1,
    width: '100%',
    height: '100%'
  },
  iconButton: {
    '&:hover': {
      cursor: 'pointer',
    }
  },
  textAlignRight: {
    textAlign: 'right'
  },
  tableCell: {
    paddingTop: 4,
    paddingBottom: 4,
    borderStyle: 'none'
  },
  buttonMap: {
    margin: theme.spacing(1),
    marginLeft: 0,
    color: 'white',
    textTransform: 'initial'
  },
});

class BackupMapList extends React.Component {
  state = {
    curMapIndex: null,
    mapName: '',
    editingMapName: false,
    curRouteId: '',
    routeName: '',
    editingRouteName: false,
    image: null,
    imageSize: null,
    queryId: '',
    dialOpen: false
  }

  componentDidMount() {
    const { robot, backupMapList, getBackupMaps } = this.props;
    if (robot && robot.user_id && (!backupMapList || !backupMapList.loading)) {
      getBackupMaps(robot.user_id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { robot, backupMapList, getBackupRoutes } = this.props;
    const { curMapIndex } = this.state;

    if (backupMapList && backupMapList.maps && curMapIndex <= backupMapList.maps.length) {
      const mapId = backupMapList.maps[curMapIndex - 1] && backupMapList.maps[curMapIndex - 1].id;
      const prevMapId = prevProps.backupMapList && prevProps.backupMapList.maps &&
        prevProps.backupMapList.maps[prevState.curMapIndex - 1] && prevProps.backupMapList.maps[prevState.curMapIndex - 1].id;
      if (mapId !== prevMapId) {
        const mapUrl = backupMapList.maps[curMapIndex - 1] && backupMapList.maps[curMapIndex - 1].mapUrl;
        robot && robot.user_id && getBackupRoutes(robot.user_id, mapId);
        this.setState({
          curRouteId: '',
          image: null,
          imageSize: null
        }, () => mapUrl && this.loadImage(mapUrl));
      }
    }

    // show the first map when map list is loaded
    if (backupMapList && backupMapList.loading === false && backupMapList.maps && backupMapList.maps.length > 0 &&
      (!prevProps.backupMapList || prevProps.backupMapList.loading === true)) {
      this.setState({ curMapIndex: 1 });
    }

    // set current map to the new last when the previous last is deleted
    if (backupMapList && backupMapList.maps && curMapIndex > backupMapList.maps.length) {
      if (backupMapList.maps.length > 0) {
        this.setState({ curMapIndex: backupMapList.maps.length });
      } else {
        this.setState({ 
          curMapIndex: null,
          curRouteId: '',
          image: null,
          imageSize: null
         });
      }
    }
  }

  loadImage(mapSrc) {
    const image = new window.Image();
    this.setState({
      image: null,
      imageSize: null
    });
    image.onload = () => {
      this.setState({
        image,
        imageSize: {
          width: image.width,
          height: image.height
        },
      });
    };
    image.src = mapSrc;
  };

  switchMap = num => {
    this.setState((state, props) => ({
      curMapIndex: state.curMapIndex + num
    }));
  }

  submitQuery = () => {
    const { backupMapList } = this.props;
    const { queryId } = this.state;
    if (!queryId) return alert(getText('input_error_query_mapId'));
    if (backupMapList && backupMapList.maps) {
      const matchedIdx = backupMapList.maps.findIndex(map => map.id === queryId);
      if (matchedIdx !== -1) this.setState({ curMapIndex: matchedIdx + 1 });
      else return alert(getText('map_id_not_found'));
    }
  }

  restoreMap = mapId => () => {
    const { robot, restoreMap } = this.props;
    if (robot && robot.id && mapId) {
      restoreMap(robot.id, mapId)
    }
  }

  restoreRoute = (mapIdFrom, mapIdTo, routeId) => {
    const { robot, restoreRoute } = this.props;
    if (robot && robot.id && mapIdFrom && mapIdTo && routeId) {
      restoreRoute(robot.id, mapIdFrom, mapIdTo, routeId)
    }
  }

  deleteMap = mapId => {
    const { robot, deleteMap } = this.props;
    if (robot && robot.user_id && mapId) {
      deleteMap(robot.user_id, mapId);
    }
  }

  deleteRoute = (mapId, routeId) => {
    const { robot, deleteRoute } = this.props;
    if (robot && robot.user_id && mapId && routeId) {
      deleteRoute(robot.user_id, mapId, routeId);
    }
  }

  onMapNameSubmit = (map, name) => {
    const { robot, updateMap } = this.props;
    if (!robot || !map) return;
    updateMap(robot.user_id, map.id, { ...map.rawData, name });
  }

  onRouteNameSubmit = (map, route, name) => {
    const { robot, updateRoute } = this.props;
    if (!robot || !map || !route) return;
    updateRoute(robot.user_id, map.id, route.id, { ...route, name });
  }

  render() {
    const { classes, backupMapList } = this.props;
    const { curMapIndex, curRouteId, image, imageSize, queryId, dialOpen, mapName, editingMapName, routeName, editingRouteName } = this.state;
    const curMap = backupMapList && backupMapList.maps && curMapIndex && backupMapList.maps[curMapIndex - 1];
    const routeOptions = curMap && curMap.mapRoutes ? curMap.mapRoutes.map(route => ({
      value: route.id,
      label: `${route.name} ${route.id}`
    })) : [];
    const curRoute = curMap && curMap.mapRoutes && curMap.mapRoutes.find(route => route.id === curRouteId);

    const maxMapWidth = (window.innerWidth * 0.8 - 48) * 10 / 12;
    const maxMapHeight = (window.innerHeight - 48) * 0.7;
    let mapWidthWithRadio = null;
    let mapHeightWithRadio = null;
    if (imageSize) {
      const radio = imageSize.height !== 0 ? imageSize.width / imageSize.height : 0;
      mapWidthWithRadio = maxMapWidth;
      mapHeightWithRadio = radio !== 0 ? mapWidthWithRadio / radio : 0;
      if (mapHeightWithRadio > maxMapHeight) {
        mapHeightWithRadio = maxMapHeight;
        mapWidthWithRadio = mapHeightWithRadio * radio;
      }
    }

    return (
      <Grid
        container={true}
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={0}
      >
        <Grid item xs={1}>
          <IconButton
            className={classes.iconButton}
            onClick={() => this.switchMap(-1)}
            disabled={!curMapIndex || curMapIndex === 1}
          >
            <PrevIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={10}
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item className={classes.searchBar}>
            <SearchBar
              mapOptions={(backupMapList && backupMapList.maps) || []}
              mapIndex={curMapIndex}
              setMapIdx={idx => this.setState({ curMapIndex: idx })}
              queryId={queryId}
              setQuery={id => this.setState({ queryId: id })}
              submitQuery={this.submitQuery}
            />
          </Grid>
          <Grid item className={classes.map}>
            <Card>
              {/* Grid to centralize the map */}
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Loader
                  style={{ zIndex: 0, minHeight: maxMapHeight }}
                  show={!image ? true : false}
                  message={backupMapList && backupMapList.maps && backupMapList.maps.length === 0 ?
                    getText('no_map') : getText('loading_map')}
                  hideContentOnLoad
                >
                  {image &&
                    <CardMedia
                      className={classes.map}
                      src="img" >
                      <RobotMapItem
                        image={image}
                        mapWidth={mapWidthWithRadio}
                        mapHeight={mapHeightWithRadio}
                        route={curRoute}
                        getImagePosition={getImagePosition(curMap && curMap.mapConfig, imageSize, {
                          width: mapWidthWithRadio,
                          height: mapHeightWithRadio
                        })}
                      />
                    </CardMedia>
                  }
                </Loader>
              </Grid>
            </Card>
          </Grid>
          {curMap && <Grid item>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.tableCell} width={200}>{getText('map_id')}:</TableCell>
                  <TableCell className={classes.tableCell}>{curMap.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCell} width={200}>{getText('map_name')}:</TableCell>
                  <TableCell className={classes.tableCell}>
                    {editingMapName ?
                      <>
                        <input
                          type='text'
                          style={{ fontSize: '1rem' }}
                          value={mapName}
                          onChange={event => this.setState({ mapName: event.target.value })}
                        />
                        <IconButton className={classes.iconButton} onClick={() => {
                          this.onMapNameSubmit(curMap, mapName);
                          this.setState({ editingMapName: false })
                        }} >
                          <SubmitIcon />
                        </IconButton>
                      </>
                      :
                      <>
                        {curMap.name}
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => this.setState({
                            editingMapName: true,
                            mapName: curMap.name
                          })}
                        >
                          <EditIcon />
                        </IconButton>
                      </>
                    }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonMap}
              onClick={this.restoreMap(curMap.id)}
            >
              {getText('restore_map')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonMap}
              onClick={() => this.deleteMap(curMap.id)}
            >
              {getText('delete_map')}
            </Button>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.tableCell} width={200}>{getText('route')}:</TableCell>
                  <TableCell className={classes.tableCell}>
                    <DropDownSelectorHorizontal
                      title={''}
                      options={routeOptions}
                      value={curRouteId}
                      onChange={event => this.setState({ curRouteId: event.target.value })}
                    />
                  </TableCell>
                </TableRow>
                {curRoute && <>
                  <TableRow>
                    <TableCell className={classes.tableCell} width={200}>{getText('route_id')}:</TableCell>
                    <TableCell className={classes.tableCell}>{curRouteId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} width={200}>{getText('route_name')}:</TableCell>
                    <TableCell className={classes.tableCell}>
                      {editingRouteName ?
                        <>
                          <input
                            type='text'
                            style={{ fontSize: '1rem' }}
                            value={routeName}
                            onChange={event => this.setState({ routeName: event.target.value })}
                          />
                          <IconButton className={classes.iconButton} onClick={() => {
                            this.onRouteNameSubmit(curMap, curRoute, routeName);
                            this.setState({ editingRouteName: false })
                          }} >
                            <SubmitIcon />
                          </IconButton>
                        </>
                        :
                        <>
                          {curRoute.name}
                          <IconButton
                            className={classes.iconButton}
                            onClick={() => this.setState({
                              editingRouteName: true,
                              routeName: curRoute.name || ''
                            })}
                          >
                            <EditIcon />
                          </IconButton>
                        </>
                      }
                    </TableCell>
                  </TableRow>
                </>}
              </TableBody>
            </Table>
            {curRouteId && <>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonMap}
                onClick={() => this.setState({ dialOpen: true })}
              >
                {getText('restore_route_to')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonMap}
                onClick={() => this.deleteRoute(curMap.id, curRouteId)}
              >
                {getText('delete_route')}
              </Button>
            </>}
          </Grid>}
        </Grid>
        <Grid item xs={1} className={classes.textAlignRight}>
          <IconButton
            className={classes.iconButton}
            onClick={() => this.switchMap(1)}
            disabled={!curMapIndex || (backupMapList && backupMapList.maps && curMapIndex >= backupMapList.maps.length)}
          >
            <NextIcon />
          </IconButton>
        </Grid>
        <RestoreRoute
          dialOpen={dialOpen}
          onClose={() => this.setState({ dialOpen: false })}
          onConfirm={mapId => {
            this.restoreRoute(curMap.id, mapId, curRouteId);
            this.setState({ dialOpen: false });
          }}
        />
      </Grid >
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BackupMapList));
