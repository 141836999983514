import React from 'react';
import { withStyles, Button } from '@material-ui/core';

const styles = theme => ({
  button: {
    background: 'rgba(76,127,114,0.40)',
    borderRadius: '6px',
    textTransform: 'initial'
  },
});

const OperationButton = props => {
  const { classes, children, onClick, disabled } = props;
  return (
    <Button
      className={classes.button}
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export default withStyles(styles)(OperationButton);