import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';

import CameraStateSelector from './CameraStateSelector';
import RobotStatusSelector from './RobotStatusSelector';
import FilterIcon from "src/components/icons/FilterIcon";
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  root: {},
  dialog: {
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: 500
  }
});

const CameraFilter = props => {
  const {
    classes, openDialog, cameraStates, robotStatuses, role,
    onOpenDialog: handleDialogOpen,
    onCloseDialog: handleDialogClose,
    onSaveFilter: handleFilterSave,
    onChangeCameraState: handleCameraStateChange,
    onChangeRobotStatus: handleRobotStatusChange,
  } = props;
  const pagePath = window.location.pathname;
  let hasRobot;
  let hasCamera;
  switch(pagePath){
    case '/livestreaming':
      hasRobot = true;
      hasCamera = true;
      break;
    case '/livestreaming/robot':
      hasRobot = true;
      hasCamera = false;
      break;
    case '/livestreaming/camera':
      hasRobot = false;
      hasCamera = true;
      break;
    default:
      break;
  }

  return (
    <React.Fragment>
      <IconButton onClick={handleDialogOpen}>
        <FilterIcon />
      </IconButton>
      <Dialog
        open={openDialog}
        maxWidth="md"
        onClose={handleDialogClose}
        className={classes.dialog}
      >
        <DialogTitle id="filter-dialog-title">{getText('live_streaming_filter')}</DialogTitle>
        <DialogContent
          className={classes.dialogContent}
        >
          {
            hasRobot &&
            <RobotStatusSelector
              value={robotStatuses}
              role={role}
              onChange={handleRobotStatusChange}
            />
          }
          { 
            hasCamera &&
            <CameraStateSelector
              value={cameraStates}
              role={role}
              onChange={handleCameraStateChange}
            />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {getText('cancel')}
          </Button>
          <Button onClick={handleFilterSave} color="primary" autoFocus>
            {getText('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

CameraFilter.propTypes = {
  classes: PropTypes.object.isRequired
};

CameraFilter.defaultProps = {
};

export default withStyles(styles)(CameraFilter);
