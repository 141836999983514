import React, {useEffect, useState} from 'react'
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, RadioGroup, FormControlLabel, Radio} from '@material-ui/core'
import { getText } from "src/utils/MultilingualLoader";

function GotoModeSelection({open, onClose, onConfirm, position}) {
  const [mode, setMode] = useState('go_straight')
  
  useEffect(() => {
    if(!open) {
      setMode('go_straight')
    }
  }, [open])
  
  return (
    <Dialog onClose={onClose} open={open} disableEscapeKeyDown disableBackdropClick>
      <DialogTitle>{getText('reaction_mode_title')}</DialogTitle>
      <DialogContent>
        {getText('coordinate_label')}：({position.x}, {position.y})
        <RadioGroup name="mode" value={mode} onChange={(event, value) => setMode(value)}>
          <FormControlLabel value="go_straight" control={<Radio color="primary" />} label={getText('go_straight_label')} />
          <FormControlLabel value="along_route" control={<Radio color="primary" />} label={getText('along_route_label')} />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{getText('cancel')}</Button>
        <Button onClick={() => onConfirm(mode)}>{getText('ok')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default GotoModeSelection
