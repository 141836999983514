import moment from "moment";
import { getDateLocale } from "src/utils/MultilingualLoader";

moment.locale(getDateLocale());

export function routineAdapter(configs) {
  const { routines, routine_schedules } = configs;
  const schedules = new Map(
    (routine_schedules || []).map((item) => [item.routine_id, item])
  );
  return (routines || []).map((routine) => {
    const schedule = schedules.get(routine.id);
    return {
      id: routine.id,
      name: routine.name,
      actions: routine.actions,
      target: routine.target,
      schedule: schedule ? (schedule.repetitive ? "每周" : "执行一次") : "--",
      startDate: schedule
        ? schedule.repetitive
          ? (schedule.days_of_week || [])
              .map((weekday) => moment.weekdays()[weekday])
              .join(", ")
          : moment(new Date(schedule.date)).format("YYYY-MM-DD")
        : "--",
      startTime: schedule
        ? (schedule.start_times || [])
            .map((time) => `${Math.floor(time / 60)}:${time % 60}`)
            .join(", ")
        : "--",
      createTime: moment(new Date(parseInt(routine.id))).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    };
  });
}

export const scheduleAdapter = {
  toClient: (scheduleData) => {
    if (!scheduleData) {
      return {
        weeklyRepeat: true,
        daysOfWeek: [],
        startDate: null,
        startTimes: [null],
      };
    }
    return {
      weeklyRepeat: !!scheduleData.repetitive,
      daysOfWeek: scheduleData.days_of_week || [],
      startDate: (scheduleData.date && moment(scheduleData.date)) || null,
      startTimes:
        scheduleData.start_times && scheduleData.start_times.length > 0
          ? scheduleData.start_times.map((minutes) => {
              if (typeof minutes === "number") {
                const hour = Math.floor(minutes / 60);
                const minute = minutes % 60;
                return moment().hours(hour).minutes(minute);
              }
              return null;
            })
          : [null],
    };
  },
  toServer: (scheduleData, routineId) => {
    return {
      name: "",
      days_of_week: scheduleData.daysOfWeek,
      disabled: false,
      date:
        scheduleData.startDate &&
        scheduleData.startDate
          .clone()
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .format(),
      repetitive: scheduleData.weeklyRepeat,
      routine_id: routineId,
      start_times: scheduleData.startTimes
        .filter((v) => !!v)
        .map((v) => v.hours() * 60 + v.minutes()),
      timezone: "Asia/Shanghai",
    };
  },
};
