import React from 'react';
import { connect } from 'react-redux';
import uuid from "uuid/v4";

import { robotActions } from 'src/redux/actions';
import LoadingText from 'src/components/LoadingText';
import InspectionMap from './InspectionMap';

const mapStateToProps = (state, props) => {
  const { allInspections } = state.inspection;
  const robotInspections = allInspections[props.selectedRobot.id];
  const { mapCache, robotCurMaps } = state.robot;
  const mapData = mapCache[props.selectedRobot.id];
  const robotMap = robotCurMaps[props.selectedRobot.id]
  return { robotInspections, mapData, robotMap };
}

const mapDispatchToProps = dispatch => {
  return {
    getRobotMap: robot_id => {
      const requestID = uuid();
      const args = {
        robot_id: robot_id,
      };
      const msg = {
        id: requestID,
        act: "robot.map_upload",
        arg: args
      };
      const options = {
        hasRes: true,
        robot: robot_id
      }
      dispatch(robotActions.deviceRequest(robot_id));
      if (window.wsAPP) window.wsAPP.sendMsg(msg, options);
    }
  };
}

class InspectionMapContainer extends React.Component {
  constructor(props) {
    super(props);
    const width = window.innerWidth - 200 - 200 - 16;
    this.state = {
      cardWidth: width
    };
  }

  componentDidMount() {
    this.loadMap(this.props.selectedRobot, false);
    window.addEventListener('resize', this.resizeCard);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedRobot && (!prevProps.selectedRobot || prevProps.selectedRobot.id !== this.props.selectedRobot.id)) {
      this.loadMap(this.props.selectedRobot, true);
    }
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.resizeCard);
  }

  loadMap(robot, force = true) {
    const { mapData } = this.props;
    if (!force && mapData) {
      return;
    }
    this.props.getRobotMap(robot.id);
  }

  resizeCard = () => {
    const width = window.innerWidth - 200 - 200 - 16;
    this.setState({
      cardWidth: width
    });
  }
 
  handleRefresh = () => {
    this.loadMap(this.props.selectedRobot, true);
  }

  render() {
    const { mapData, robotMap, robotInspections } = this.props;
    const { cardWidth } = this.state;
    if (!robotInspections || robotInspections.loading) return (<LoadingText />);
    const mapLoading = mapData ? mapData.loading : true;
    const mapError = mapData && mapData.error;
    const mapUrl = mapData && mapData.map_url;
    const mapConfig = mapData && mapData.map_config;
    const mapUrl2 = robotMap && robotMap.mapUrl;
    const mapConfig2 = robotMap && robotMap.mapConfig;
    const mapLoading2 = robotMap ? robotMap.loading : true;
    const waypoints = robotInspections.waypoints? robotInspections.waypoints : {};
    
    return (
      <InspectionMap
         mapLoading={!mapUrl && !mapUrl2 && (mapLoading || mapLoading2)}
         mapError={mapError && !mapUrl2 ? mapError : null}
         mapSrc={mapUrl || mapUrl2}
         mapConfig={mapConfig || mapConfig2 || {}}
         waypoints={waypoints}
         onRefresh={this.handleRefresh}
         cardWidth={cardWidth}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionMapContainer);
