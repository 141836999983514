import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Button, Typography, Checkbox, FormControlLabel, Grid, Slider } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import moment from 'moment';
import * as _ from 'lodash';

import { robotActions } from 'src/redux/actions';
import RobotDetectionConfig from './RobotDetectionConfig';
import { getText } from 'src/utils/MultilingualLoader';
import OnOffSwitch from 'src/components/Switches/OnOffSwitch';
import DropDownSelectorVertical from 'src/components/Selectors/DropDownSelectorVertical';
import TimeSelector from 'src/components/Selectors/TimeSelector';

const styles = theme => ({
  buttonCreate: {
    marginLeft: 12
  },
  buttonCreateText: {
    color: 'white',
    textTransform: 'initial'
  },
  buttonGroup: {
    width: '100%',
    backgroundColor: 'inherit'
  },
  buttonLeft: {
    flex: 1,
    height: '2rem',
    //important to override :firstChild
    borderRadius: '15px 0 0 15px !important',
  },
  buttonMid: {
    flex: 1,
    height: '2rem',
    borderRadius: 0,
    //important to override not:firstChild
    margin: '0 2px 0 2px !important'
  },
  buttonRight: {
    flex: 1,
    height: '2rem',
    //important to override :lastChild
    borderRadius: '0 15px 15px 0 !important'
  },
  buttonText: {
    textTransform: 'initial'
  },
  hide: {
    display: 'none'
  },
  algoOptions: {
    maxHeight: 300,
    overflow: 'auto'
  }
})

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    updateRobotDetectionConfigs: (robot_id, updConfigs) => {
      dispatch(robotActions.updateRobotDetectionConfigs(robot_id, updConfigs));
    }
  };
};

class RobotDetectionConfigContainer extends React.Component {
  constructor(props) {
    super(props);
    const { configs } = props;
    let stateConfigs = Object.assign({}, configs);
    delete stateConfigs.cas;
    delete stateConfigs.updating;
    this.state = {
      selectedRuleIdx: null,
      newRule: {},
      configs: stateConfigs,
    };
  }

  componentDidUpdate(prevProps) {
    const configs = Object.assign({}, this.props.configs);
    const prevConfigs = Object.assign({}, prevProps.configs);
    delete configs.updating;
    delete prevConfigs.updating;
    if (!_.isEqual(configs, prevConfigs)) {
      delete configs.cas;
      this.setState({
        selectedRuleIdx: null,
        newRule: {},
        configs
      });
    }
  }

  minutesToString = totalMinutes => {
    let hours = Math.floor(totalMinutes / 60).toString();
    let minutes = (totalMinutes % 60).toString();
    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;
    return `${hours}:${minutes}`;
  }

  stringToMinutes = timeString => {
    let time = timeString.split(':');
    if (time.length < 2 || time[0] === '' || time[1] === '') {
      return null;
    }
    let minutes = parseInt(time[0]) * 60 + parseInt(time[1]);
    return minutes;
  }

  handleChange = field => event => {
    this.setState({
      configs: {
        ...this.state.configs,
        [field]: event.target.value
      }
    });
  }

  handleSensitivityChange = (event, newValue) => {
    this.setState({
      configs: {
        ...this.state.configs,
        detection_threshold: 1 - newValue / 100
      }
    })
  }

  handleSwitch = field => event => {
    this.setState({
      configs: {
        ...this.state.configs,
        [field]: event.target.checked
      }
    });
  }

  handleRuleNameChange = index => event => {
    // add new rule
    if (index === -1) {
      this.setState({
        newRule: {
          ...this.state.newRule,
          name: event.target.value
        }
      });
    }
    // modify existed rule
    else {
      const rules = this.state.configs.detection_rules ? this.state.configs.detection_rules : [];
      if (rules[index]) rules[index].name = event.target.value;
      this.setState({
        configs: {
          ...this.state.configs,
          'detection_rules': rules
        }
      });
    }
  }

  handleRuleModeChange = index => (event, newValue) => {
    if (!newValue) return;
    // add new rule
    if (index === -1) {
      this.setState({
        newRule: {
          ...this.state.newRule,
          mode: newValue
        }
      });
    }
    // modify existed rule
    else {
      const rules = this.state.configs.detection_rules ? this.state.configs.detection_rules : [];
      if (rules[index]) rules[index].mode = newValue;
      this.setState({
        configs: {
          ...this.state.configs,
          'detection_rules': rules
        }
      });
    }
  }

  handleAlgoChange = (index, algo) => event => {
    // add new rule
    if (index === -1) {
      this.setState({
        newRule: {
          ...this.state.newRule,
          algos: {
            ...this.state.newRule.algos,
            [algo]: event.target.checked
          }
        }
      });
    }
    // modify existed rule
    else {
      const rules = this.state.configs.detection_rules ? this.state.configs.detection_rules : [];
      if (rules[index]) rules[index].algos[algo] = event.target.checked;
      this.setState({
        configs: {
          ...this.state.configs,
          'detection_rules': rules
        }
      });
    }
  }

  handleRuleTimeChange = (index, time, field) => {
    // add new rule
    if (index === -1) {
      this.setState({
        newRule: {
          ...this.state.newRule,
          [field]: this.stringToMinutes(time)
        }
      });
    }
    // modify existed rule
    else {
      const rules = this.state.configs.detection_rules ? this.state.configs.detection_rules : [];
      const newTime = this.stringToMinutes(time);
      if (newTime === null) return;
      if (rules[index]) rules[index][field] = newTime;
      this.setState({
        configs: {
          ...this.state.configs,
          'detection_rules': rules
        }
      });
    }
  }

  handleSubmit = () => {
    if (this.props.configs && !this.props.configs.updating && this.props.selected) {
      let updConfigs = Object.assign({}, this.state.configs);
      //format check for numbers
      let valid = true;
      Object.keys(this.props.configs)
        .filter(configKey => configKey !== 'robot_ids' && configKey !== 'cas' && configKey !== 'updating')
        .forEach(configKey => {
          if (typeof this.props.configs[configKey] === 'number') {
            let number = parseFloat(updConfigs[configKey]);
            if (isNaN(number)) {
              alert(`${getText('incorrect')} ${configKey} ${getText('format')}`);
              valid = false;
            }
            updConfigs[configKey] = number;
          }
        });
      if (valid) this.props.updateRobotDetectionConfigs(this.props.selected.id, updConfigs);
    }
  }

  cancelCreate = event => {
    this.setState({
      selectedRuleIdx: null,
      newRule: {}
    });
  }

  deleteRule = index => event => {
    let rules = this.state.configs.detection_rules ? this.state.configs.detection_rules : [];
    rules.splice(index, 1);
    this.setState({
      selectedRuleIdx: null,
      configs: {
        ...this.state.configs,
        'detection_rules': rules
      }
    });
  }

  confirmCreateRule = event => {
    const { newRule } = this.state;
    if (!newRule.name || newRule.name === '') {
      alert(getText('input_error_rule_name'));
      return;
    }
    let rules = this.state.configs.detection_rules ? this.state.configs.detection_rules : [];
    rules.push(newRule);
    this.setState({
      selectedRuleIdx: null,
      newRule: {},
      configs: {
        ...this.state.configs,
        'detection_rules': rules
      }
    });
  }

  getConfigs = stateConfigs => {
    const { selectedRuleIdx, newRule } = this.state;
    const { classes, algos } = this.props;
    let selectedRule = {};
    if (selectedRuleIdx >= 0 && stateConfigs['detection_rules']) selectedRule = stateConfigs['detection_rules'][selectedRuleIdx];
    else if (selectedRuleIdx === -1) selectedRule = newRule;
    selectedRule = selectedRule ? selectedRule : {};
    let selectedName = selectedRule.name ? selectedRule.name : '';
    let selectedAlgos = selectedRule.algos ? selectedRule.algos : {};
    let selectedMode = selectedRule.mode ? selectedRule.mode : '';
    let selectedStartTime = selectedRule.start_at ? selectedRule.start_at : 0;
    let selectedEndTime = selectedRule.end_at ? selectedRule.end_at : 0;
    let sensitivity = Math.round((1 - stateConfigs['detection_threshold']) * 100);

    return [
      {
        title: getText('auto_speak_enabled'),
        content: (
          <OnOffSwitch
            value={stateConfigs['auto_speak_enabled'] ? stateConfigs['auto_speak_enabled'] : false}
            onChange={this.handleSwitch('auto_speak_enabled')}
          />
        )
      },
      {
        title: getText('detect_enabled'),
        content: (
          <OnOffSwitch
            value={stateConfigs['detect_enabled'] ? stateConfigs['detect_enabled'] : false}
            onChange={this.handleSwitch('detect_enabled')}
          />
        )
      },
      {
        title: getText('sensitivity'),
        content: (<><Typography variant='body2'>{sensitivity}%</Typography><Slider
          min={0}
          max={100}
          step={1}
          value={sensitivity}
          onChange={this.handleSensitivityChange}
        /></>)
      },
      {
        title: getText('detection_rules'),
        content: (<>
          <DropDownSelectorVertical
            options={stateConfigs['detection_rules'] ? stateConfigs['detection_rules'].map(
              (rule, index) => ({ label: rule.name, value: index })
            ) : []}
            value={selectedRuleIdx !== null ? selectedRuleIdx : ''}
            onChange={event => this.setState({ selectedRuleIdx: event.target.value })}
            disabled={selectedRuleIdx === -1}
          />
          <Button
            variant="contained"
            color="primary"
            // selectedRuleIdx -1 stands for adding new rule
            onClick={selectedRuleIdx === -1 ? this.cancelCreate
              : this.deleteRule(selectedRuleIdx)}
            className={selectedRuleIdx !== null ? classes.buttonCreate : classes.hide}
          >
            <Typography variant="body2" className={classes.buttonCreateText}>
              {selectedRuleIdx === -1 ? getText('cancel') : getText('delete_rule')}
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            // selectedRuleIdx -1 stands for adding new rule
            onClick={selectedRuleIdx === -1 ? this.confirmCreateRule
              : event => this.setState({ selectedRuleIdx: -1 })}
            className={classes.buttonCreate}
          >
            <Typography variant="body2" className={classes.buttonCreateText}>
              {selectedRuleIdx === -1 ? getText('confirm') : getText('create_rule')}
            </Typography>
          </Button>
        </>)
      },
      {
        title: getText('name'),
        hide: selectedRuleIdx === null,
        content: (<input
          type='text'
          style={{ fontSize: '1rem' }}
          value={selectedName}
          onChange={this.handleRuleNameChange(selectedRuleIdx)}
        />)
      },
      {
        title: getText('algorithm'),
        hide: selectedRuleIdx === null,
        content: (
          <Grid container className={classes.algoOptions}>
            {
              algos.map(algo => (
                <Grid item key={algo} xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedAlgos[algo] ? selectedAlgos[algo] : false}
                        onChange={this.handleAlgoChange(selectedRuleIdx, algo)}
                        color="primary"
                      />
                    }
                    label={algo}
                  />
                </Grid>
              ))
            }
          </Grid>
        )
      },
      {
        title: getText('mode'),
        hide: selectedRuleIdx === null,
        content: (
          <ToggleButtonGroup
            value={selectedMode}
            exclusive
            onChange={this.handleRuleModeChange(selectedRuleIdx)}
            className={classes.buttonGroup}
          >
            <ToggleButton
              value='on'
              className={classes.buttonLeft}
            >
              <Typography variant='body2' className={classes.buttonText}>{getText('on')}</Typography>
            </ToggleButton>
            <ToggleButton
              value='off'
              className={classes.buttonMid}
            >
              <Typography variant='body2' className={classes.buttonText}>{getText('off')}</Typography>
            </ToggleButton>
            <ToggleButton
              value='schedule'
              className={classes.buttonRight}
            >
              <Typography variant='body2' className={classes.buttonText}>{getText('schedule')}</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        )
      },
      {
        title: getText('start_time'),
        hide: selectedRuleIdx === null || selectedMode !== 'schedule',
        content: (
          <TimeSelector
            label={''}
            value={moment(this.minutesToString(selectedStartTime), 'h:mm')}
            onChange={time => this.handleRuleTimeChange(selectedRuleIdx,
              moment(time).format('HH:mm'), 'start_at')}
          />
        )
      },
      {
        title: getText('end_time'),
        hide: selectedRuleIdx === null || selectedMode !== 'schedule',
        content: (
          <TimeSelector
            label={''}
            value={moment(this.minutesToString(selectedEndTime), 'h:mm')}
            onChange={time => this.handleRuleTimeChange(selectedRuleIdx,
              moment(time).format('HH:mm'), 'end_at')}
          />
        )
      }
    ];
  }
 
  render() {
    const { configs: stateConfigs } = this.state;
    const { configs: propsConfigs } = this.props;
    let configs = [];
    if (propsConfigs && Object.keys(stateConfigs).length !== 0) {
      configs = this.getConfigs(stateConfigs);
    }
    return (<RobotDetectionConfig configs={configs} onSubmit={this.handleSubmit} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RobotDetectionConfigContainer));
