import {makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: 30,
  },
  rowManager: {
    marginTop: 0,
  },
  flex: {
    display: 'flex',
  },
  mr40: {
    marginRight: 40,
  },
  input: {
    width: '100%',
    height: '40px',

    '& .MuiOutlinedInput-root': {
      height: '40px',
    },
    '& .MuiInputLabel-formControl': {
      top: '-12px',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      top: 0,
    },
    '& .MuiInputLabel-shrink': {
      top: 0,
    },
  },
  // cityInput: {
  //   width: '60%',
  //   marginRight: '10px',
  //   height: '40px',

  //   '& .MuiOutlinedInput-root': {
  //     height: '40px',
  //   },
  //   '& .MuiInputLabel-formControl': {
  //     top: '-12px',
  //   },
  //   '& .MuiFormLabel-root.Mui-focused': {
  //     top: 0,
  //   },
  //   '& .MuiInputLabel-shrink': {
  //     top: 0,
  //   },
  // },
  select: {
    flex: 1,
    height: '56px',
  },
  required: {
    color: theme.palette.error.main,
  },
  title: {
    fontSize: 16,
    marginBottom: 20,
    color: 'rgba(0,0,0,0.87)',
  },
  tip: {
    fontSize: 12,
    marginBottom: 8,
    color: 'rgba(0,0,0,0.54)',
  },
  splitLine: {
    width: '100%',
    height: 1,
    backgroundColor: '#E0E0E0',
  },
  result: {
    marginTop: 12,
  },
  chip: {
    marginRight: 8,
    marginBottom: 4,
  },
  actions: {
    position: 'relative',
    borderTop: '1px solid #e0e0e0',
    padding: '12px 24px',
  },
  actionRequired: {
    position: 'absolute',
    left: '24px',
  },
  selectRequired: {
    '& .MuiFormLabel-root': {
      color: 'red',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
  },
  titleGroup: {
    borderBottom: '1px solid #e0e0e0',
    paddingTop: '8px',
    paddingBottom: '8px',
    marginBottom: '20px',
  },
}));

export default useStyles;
