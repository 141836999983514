import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import { inspectionActions, targetActions } from 'src/redux/actions';
import InspectionFilter from './InspectionFilter';

const mapStateToProps = state => {
  const { filters, selectedRobot, showType } = state.inspection;
  const { curSite: siteId } = state.site;
  const { targetOptions } = state.target;
  const { robotConfigs } = state.robot;
  const configs = selectedRobot&&robotConfigs[selectedRobot.id];
  return { filters, selectedRobot, configs, showType, targetOptions, siteId };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilter: filters => {
      dispatch(inspectionActions.setFilter(filters));
    },
    getInspections: (filters, loadAll) => {
      dispatch(inspectionActions.getInspections(filters, loadAll));
    },
    getTargetOptions: ()　=> {
      dispatch(targetActions.getTargetOptions());
    },
  };
};

class InspectionFilterContainer extends React.Component {
  constructor(props) {
    super(props);
    const { filters } = props;
    const { lineSize, sortBy, mediumType, matchMap, matchRoute, startDate, endDate, inspectionType, target } = filters;
    this.state = {
      lineSize,
      sortBy,
      mediumType,
      matchMap,
      matchRoute,
      startDate,
      endDate,
      inspectionType,
      target,
    };
  }

  componentDidMount() {
    const { getTargetOptions, siteId, targetOptions } = this.props;
    if (siteId && !targetOptions[siteId]) {
      getTargetOptions();
    }
  }

  componentDidUpdate(prevProps) {
    if (
        // reset filter when the same robot reselected 
        (!_.isEqual(prevProps.filters, this.props.filters) 
          && !_.isEqual(this.props.filters, {...this.state, robotId: this.props.filters.robotId}))
        // reset filter when another robot selected
        || ((prevProps.selectedRobot && this.props.selectedRobot && prevProps.selectedRobot.id !== this.props.selectedRobot.id))
      ) {
      const { lineSize, sortBy, mediumType, matchMap, matchRoute, startDate, endDate, inspectionType, target } = this.props.filters;
      this.setState({
        lineSize,
        sortBy,
        mediumType,
        matchMap,
        matchRoute,
        startDate,
        endDate,
        inspectionType,
        target
      })
    }
  }

  handleChange = prop => event => {
    this.setState({
      [prop]: event.target.value
    });
  }

  handleAutoComplete = (e, val) => {
    this.setState({
      target: val
    })
  }

  handleSubmit = event => {
    const { filters, selectedRobot, setFilter, getInspections, showType } = this.props;
    const inspectionFilters = Object.assign({}, filters, {...this.state, robotId: selectedRobot.id});
    setFilter(inspectionFilters);
    getInspections(inspectionFilters, showType === 'map');
  };

  handleDateChange = type => time => {
    if (type === 'startDate')
      this.setState({
        startDate: time.startOf('day')
      });
    else
      this.setState({
        endDate: time.endOf('day')
      });
  }

  render() {
    const { configs, targetOptions, siteId } = this.props;
    const { lineSize, sortBy, mediumType, matchMap, matchRoute, startDate, endDate, inspectionType, target } = this.state;
    const hasMap = configs && configs.current_map_id;
    const hasRoute = configs && configs.current_route_id;

    return (
       <InspectionFilter
        lineSize={lineSize}
        sortBy={sortBy}
        mediumType={mediumType}
        matchMap={matchMap}
        matchRoute={matchRoute}
        target={target}
        startDate={startDate}
        endDate={endDate}
        inspectionType={inspectionType}
        hasMap={hasMap}
        hasRoute={hasRoute}
        targetOptions={targetOptions[siteId] || []}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        handleDateChange={this.handleDateChange}
        handleAutoComplete={this.handleAutoComplete}
       />
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InspectionFilterContainer);
