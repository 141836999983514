import React from 'react';
import {Grid, Divider, IconButton, makeStyles} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
  clear: {
    textTransform: 'none',
    fontSize: 14,
    color: theme.palette.grey[400],
    padding: 0,
  },
  grid: {
    padding: '0 !important',
    height: theme.spacing(4),
    lineHeight: `${theme.spacing(3.5)}px`,
  },
  divider: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
}));

export function CancelIconComponent({onClick}) {
  const classes = useStyles();
  return (
    <>
      <Divider
        classes={{middle: classes.divider}}
        orientation="vertical"
        variant="middle"
        style={{height: 32}}
      />
      <Grid item classes={{item: classes.grid}}>
        <IconButton className={classes.clear} size="small" onClick={onClick}>
          <CancelIcon />
        </IconButton>
      </Grid>
    </>
  );
}
