import React from 'react';
import { connect } from 'react-redux';
import AdminManage from './AdminManage';
import GuardManage from './GuardManage';

class AccountManageContainer extends React.Component {

  render() {
    const roleType = this.props.match.path.substr(this.props.match.path.lastIndexOf('/') + 1);
    return (
      roleType === 'adminmanage' ? <AdminManage /> : <GuardManage />
    );
  }
};

export default connect(
)(AccountManageContainer);
