import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, Switch } from '@material-ui/core';

import DateTimeSelector from 'src/components/Selectors/DateTimeSelector';
import Selector from './Selector';
import SensorType from '../SensorType';
import { getText } from 'src/utils/MultilingualLoader';

const styles = theme => ({
  textField: {
    margin: 0
  },
  submit: {
    textAlign: 'right'
  },
  submitButton: {
    maxWidth: 100,
  },
  label: {
    color: 'white',
    textTransform: 'initial'
  },
  selectorLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  formLabel: {
    width: '100px'
  },
  switchLabel: {
    color: 'gray'
  },
});

const radiusOptions = [
  { value: 0.5, label: '0.5' },
  { value: 1, label: '1' },
  { value: 2, label: '2' }
];

const SensorFilter = props => {
  const { classes, radius, startTime, endTime, mapId, mapOptions, routeId, routeOptions, showType,
    handleChange, handleTimeChange, handleMapIdChange, handleSubmit, handleRouteIdChange, handleShowTypeSwitch } = props;
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-end"
      spacing={1}
    >
      <Grid item xs={6} lg={2}>
        <DateTimeSelector
          value={startTime}
          onChange={handleTimeChange('startTime')}
          title={getText('start_date')}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <DateTimeSelector
          value={endTime}
          onChange={handleTimeChange('endTime')}
          title={getText('end_date')}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <Selector
          title={getText('map')}
          options={mapOptions}
          value={mapId || ''}
          onChange={handleMapIdChange}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <Selector
          title={getText('route')}
          options={routeOptions}
          value={routeId || ''}
          onChange={handleRouteIdChange}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <Selector
          title={getText('radius')}
          options={radiusOptions}
          value={radius}
          onChange={handleChange('radius')}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          className={classes.submitButton}
          onClick={handleSubmit}
        >
          <Typography variant="body2" className={classes.label}>
            {getText('submit')}
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Grid
          container={true}
          direction="row"
          alignItems="flex-end"
        >
          <Grid item xs={6} lg={2}>
            <SensorType />
          </Grid>
          <Grid item xs={6} lg={2}>
            <Typography variant='body1' className={classes.switchLabel}>
              {getText('cluster')}
            <Switch
              checked={showType === 'route'}
              onChange={handleShowTypeSwitch}
              color="default"
            />
            {getText('route')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(SensorFilter);