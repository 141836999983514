import {
  notificationConstants
} from "src/redux/constants";

const initialState = {
  messages: []
    /*
      {
        text: "string"
      }
    */
};

export default (state = initialState, action) => {
  switch (action.type) {
    case notificationConstants.ADD_NOTIFICATION:
      /*
        action: {
          type: ...
          msg: {
            text: "string",
            duration?: "number" | null // set as null if don't want auto close
          }
        }
       */
      return {
        ...state,
        messages: [...state.messages, action.msg]
      };
    case notificationConstants.ADD_NOTIFICATION_AHEAD:
      return {
        ...state,
        messages: [action.msg, ...state.messages]
      };
    case notificationConstants.SHOWED_NOTIFICATION:
      return {
        ...state,
        messages: state.messages.slice(1)
      };
    default:
      return state;
  }
}
