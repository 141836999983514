import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import history from 'src/utils/history';
import PrivateRoute from 'src/components/private-route';
import Main from 'src/pages/Main';
import SignIn from 'src/pages/SignIn';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4C7F72',
      default: process.env.REACT_APP_THEME_COLOR,
      // main: '#3C5BFF',
      // dark: '#00001E',
      light: '#d8dcfd',
      background: '#E0E0E0',
      border: '#3C5BFF',
      contrastText: '#fff',
    },
    secondary: {
      main: '#5A96E0',
      contrastText: '#fff',
      border: '#5A96E0',
    },
    action: {
      main: '#E0E0E0',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabledText: 'rgba(0, 0, 0, 0.26)',
    },
    other: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      border: 'rgba(0, 0, 0, 0.23)',
      divider: '#E0E0E0',
      backdrop: 'rgba(0, 0, 0, 0.5)',
      snackbar: '#323232',
    },
    error: {
      main: '#F44336',
      dark: '#E31B0C',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: "\"Helvetica\", Roboto, Arial, sans-serif",
  },
  zIndex: {
    tooltip: 1000
  },
  overrides: {
    
  }
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Router history={history}>
          <Switch>
            <Route exact path="/signin" component={SignIn} />
            <PrivateRoute path="/" component={Main} />
          </Switch>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
