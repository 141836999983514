import {
  SET_LICENSE_PLATE_BLACKLIST,
} from 'src/redux/types/Vision/licensePlateBlacklist';

const initialState = {
  licensePlateBlacklist: {},
};

export default function licensePlateBlacklistReducer(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case SET_LICENSE_PLATE_BLACKLIST:
      return {
        ...state,
        licensePlateBlacklist: payload.licensePlateBlacklist,
      };
    default:
      return state;
  }
}
