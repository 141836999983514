import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, AppBar, Toolbar, IconButton, MenuItem, Menu } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircle from '@material-ui/icons/AccountCircle';

import logo from 'public/logo.png';
import { getText } from 'src/utils/MultilingualLoader';
import OnOffSwitch from 'src/components/Switches/OnOffSwitch';

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#2C2C40'
  },
  flex: {
    flex: 1,
  },
  title: {
    margin: theme.spacing(1),
  },
  logo: {
    height: '1.25rem'
  },
  menuItem: {
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem',
  },
  account: {
    marginLeft: theme.spacing(2)
  },
  menuText: {
    margin: '0 0.5em 0 0'
  },
});

const Header = props => {
  const { classes, onClickSignOutButton, handleOpenMenu, handleCloseMenu, anchorEl, username, 
    shouldNotify, handleNotificationSwitch } = props;
  const open = Boolean(anchorEl);
  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <img src={logo} alt='Turing' className={classes.logo} />
        {/* Spacer */}
        <Typography variant="h6" className={classes.flex} />
        <Typography variant="body1" className={classes.title}>{getText('notification')}: </Typography>
        <OnOffSwitch value={shouldNotify} onChange={handleNotificationSwitch} />
        <IconButton
          className={classes.account}
          onClick={handleOpenMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleCloseMenu}
        >
          <MenuItem disabled={true}>
            <Typography variant="subtitle1" color="inherit" className={classes.menuText}>
              {username}
            </Typography>
          </MenuItem>
          <MenuItem onClick={onClickSignOutButton}>
            <Typography variant="subtitle1" color="inherit" className={classes.menuText}>
              {getText('sign_out')}
            </Typography>
            <ExitToAppIcon />
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onClickSignOutButton: PropTypes.func.isRequired
};

Header.defaultProps = {
};

export default withStyles(styles)(Header);
