import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Grid, Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';

import { robotActions } from 'src/redux/actions';
import { getText } from 'src/utils/MultilingualLoader';
import OutlinedSelector from 'src/components/Selectors/OutlinedSelector';

const mapStateToProps = state => {
  const { selected, robotMapLists } = state.robot;
  const robotMapList = selected && robotMapLists[selected.id];
  const robotId = selected && selected.id;
  return { robotId, robotMapList }
}

const mapDispatchToProps = {
  getRobotMaps: robotId => robotActions.getRobotConfigMapsAndRoutes(robotId),
}

const styles = theme => ({
  buttons: {
    textAlign: 'end'
  },
  button: {
    display: 'inline-block',
    margin: theme.spacing(1),
    marginLeft: 0,
    color: 'white',
    textTransform: 'initial'
  }
});

class CopyRoute extends React.Component {
  state = {
    mapId: ''
  }

  componentDidMount() {
    const { getRobotMaps, robotId } = this.props;
    getRobotMaps(robotId);
  }

  render() {
    const { classes, dialOpen, robotMapList, onClose, onConfirm } = this.props;
    const { mapId } = this.state;
    const mapOptions = (robotMapList && robotMapList.maps) || [];

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={dialOpen}
        onClose={onClose}
      >
        <DialogTitle>
          {getText('restore_route_to')}
        </DialogTitle>
        <DialogContent>
          <Grid container justify="flex-end">
            <Grid item xs={12}>
              <OutlinedSelector
                title={getText('map')}
                options={mapOptions.map(map => ({ value: map.id, label: `${map.id} ${map.name}` }))}
                value={mapId}
                onChange={event => this.setState({ mapId: event.target.value })}
              />
            </Grid>
            <Grid item xs={12} className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onClose}
              >
                {getText('cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => onConfirm(mapId)}
              >
                {getText('confirm')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CopyRoute));