import React, {useMemo, useRef, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';
import {Grid, Typography, Button, makeStyles, Popover, List, ListItem} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import MotorcycleIcon from '@material-ui/icons/DirectionsBike';

import CommonHeader from 'src/components/Vision/CommonHeader';
// import {RootState} from 'src/redux/reducers';
import {isPeople, isVehicle, isMotorcycle} from 'src/redux/actions/Vision/eventType';
import {useEventType} from '../detail/hook';
// import {EventTypeOption} from '../detail/definition';

const useStyles = makeStyles(theme => ({
  item: {
    marginRight: 20,
    borderBottom: '2px solid transparent',
    paddingTop: 0,
    paddingBottom: 4,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 0,
    textTransform: 'capitalize',
    minWidth: 0,
    color: theme.palette.text.secondary,
    '&:hover': {
      background: 'white',
      color: theme.palette.text.primary,
    },
  },
  selected: {
    color: theme.palette.primary.main,
    borderBottomColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));


const Header = ({type, setType}) => {
  const history = useHistory();
  const classes = useStyles();
  const eventOption = useEventType();
  const labels = ['people', 'vehicle', 'motorcycle'];
  const {filter} = useSelector(state => state.clip);
  const textRef = useRef(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setType(filter.type);
  }, [filter]);

  const eventTypeOptions = labels.map(label => {
    return {
      value: eventOption[label],
      label,
    };
  });

  useEffect(() => {
    if (!type && !filter.type && eventTypeOptions[0]) {
      // Select first option if no type selected.
      const tmp = eventTypeOptions.find(
        v => v.label.toLowerCase() === history.location.pathname.split('/')[2]
      );
      setType(
        (tmp ? tmp.value : undefined) || eventTypeOptions[0].value
      );
    }
  }, [eventTypeOptions]);

  useEffect(() => {
    if (eventTypeOptions[0]) {
      const tmp = eventTypeOptions.find(
        v => v.label.toLowerCase() === history.location.pathname.split('/')[2]
      );
      setType(
        (tmp ? tmp.value : undefined) || eventTypeOptions[0].value
      );
    }
  }, [history.location.pathname]);

  const moreSelectedItem = useMemo(() => {
    return eventTypeOptions.slice(3).find(item => item.value === type);
  }, [eventTypeOptions, type]);

  const isSelected = (option) => {
    const typeArray = type.split(',').filter(type => type);
    return typeArray.some(type => option.includes(type));
  };

  return (
    <CommonHeader title="Search" position="bottom" fixed={false}>
      <Grid container>
        {eventTypeOptions.slice(0, 3).map(item => (
          <Button
            key={item.label}
            onClick={() => history.push(`/events/${item.label.toLowerCase()}`)}
            className={clsx(classes.item, type && isSelected(item.value) && classes.selected)}
            startIcon={
              isPeople(item.value) ? (
                <DirectionsWalkIcon />
              ) : isVehicle(item.value) ? (
                <DirectionsCarIcon />
              ) : isMotorcycle(item.value) ? (
                <MotorcycleIcon />
              ) : null
            }
          >
            <Typography variant="subtitle2" style={{textTransform: 'capitalize'}}>
              {item.label}
            </Typography>
          </Button>
        ))}
        {eventTypeOptions.length > 3 && (
          <>
            <Button
              className={clsx(classes.item, moreSelectedItem ? classes.selected : '')}
              ref={textRef}
              onClick={() => setOpen(true)}
            >
              {moreSelectedItem ? moreSelectedItem.label : 'More'}
              <ArrowDropDownIcon />
            </Button>
            {open && textRef && textRef.current && (
              <Popover
                open={true}
                anchorEl={textRef.current}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                onClose={() => setOpen(false)}
              >
                <List>
                  {eventTypeOptions.slice(3).map((item, idx) => (
                    <ListItem
                      key={idx}
                      onClick={() => {
                        setType(item.value);
                        setOpen(false);
                      }}
                      className={type === item.value ? classes.selected : ''}
                      button
                    >
                      {item.label}
                    </ListItem>
                  ))}
                </List>
              </Popover>
            )}
          </>
        )}
      </Grid>
    </CommonHeader>
  );
};

export default Header;
