import moment from 'moment';

import { config } from './config';
import { GeneralAdapter } from './adapter';

const mapFiltersToQuery = filters => {
  const queries = [];

  const { startTime, endTime, radius, robotId, mapId, routeId } = filters;

  // Robot Id
  queries.push(`${encodeURIComponent('robot_id')}=${encodeURIComponent(robotId)}`);

  // Map Id
  queries.push(`${encodeURIComponent('map_id')}=${encodeURIComponent(mapId)}`);

  // Route Id
  if (routeId) queries.push(`${encodeURIComponent('route_id')}=${encodeURIComponent(routeId)}`);

  // Start time
  if (startTime) {
    const startTimeUTC = moment.utc(startTime).format('YYYY-MM-DDTHH:mm:ss');
    // started_at means started_at__gte
    queries.push(`start_at=${encodeURIComponent(startTimeUTC)}Z`);
  }
  
  // End time
  if (endTime) {
    const endTimeUTC = moment.utc(endTime).format('YYYY-MM-DDTHH:mm:ss');
    // ended_at means ended_at__lte
    queries.push(`end_at=${encodeURIComponent(endTimeUTC)}Z`);
  }

  // Radius
  queries.push(`${encodeURIComponent('radius')}=${encodeURIComponent(radius)}`);

  return queries.join('&');
}

const getSensorClustersAdapter = {
  toServer: data => {
    return data;
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    let clusters = result.clusters || [];
    clusters = clusters.map(cluster => ({
      position: {
        x: cluster.location && cluster.location.x,
        y: cluster.location && cluster.location.y
      },
      data: cluster.average
    }));
    return { results: {
      mapId: result.map_id,
      clusters
     }};
  }
}

export const getSensorClusters = (adapter => async (filters = {}) => {
  try {
    const query = mapFiltersToQuery(filters);
    const url = `/config/sensor/clusters?${query}`;
    let response = await config.get(url);
    response = adapter.toClient(response.data);
    const { error, results } = response;
    if (error) throw error;
    return results;
  } catch(e) {
    throw e;
  }
})(getSensorClustersAdapter);

const getSensorPointsAdapter = {
  toServer: data => {
    return data;
  },
  toClient: data => {
    data = GeneralAdapter.toClient(data);
    const { error, result } = data;
    if (error) return { error };
    let g5st = result.g5st || [];
    g5st = g5st.map(point => ({
      position: {
        x: point.X,
        y: point.Y
      },
      temperature: point.Temperature,
      humidity: point.Humidity,
      pm25: point.PM25,
      hcho: point.Hcho,
      noise: point.Noise,
      infrared: point.Infrared
    }));
    let localization_score = result.patrol_performance || [];
    localization_score = localization_score.map(point => ({
      position: {
        x: point.X,
        y: point.Y
      },
      localizationScore: point.LocalizationScore
    }));
    return { results: {
      g5st,
      localization_score
     }};
  }
}

export const getSensorPoints = (adapter => async (filters = {}) => {
  try {
    const query = mapFiltersToQuery(filters);
    const url = `/config/sensor/points?${query}`;
    let response = await config.get(url);
    response = adapter.toClient(response.data);
    const { error, results } = response;
    if (error) throw error;
    return results;
  } catch(e) {
    throw e;
  }
})(getSensorPointsAdapter);