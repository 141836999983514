import React from 'react';
import { withStyles } from '@material-ui/core';

import { injectScript } from 'src/utils/injectScript';


const styles = theme => ({
  mapContainer: {
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    flexGrow: 1,
    position: 'relative'
  },
  map: {
    height: '80vh'
  },
});

class GaodeMap extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.map = null;
  }

  componentDidMount() {
    if (!window.AMap) {
      injectScript(`${process.env.REACT_APP_GAODE_MAP_API_URL}/maps?v=1.4.15&key=${process.env.REACT_APP_GAODE_MAP_API_KEY}`)
        .then(() => {
          injectScript(`${process.env.REACT_APP_GAODE_MAP_API_URL}/ui/1.0/main.js?v=1.0.11`)
            .then(() => {
              window.AMap && window.AMap.plugin(['AMap.IndoorMap'], () => {
                this.loadGaodeMap();
              });
            });
        });
    } else {
      this.loadGaodeMap();
    }
  }

  componentWillUnmount() {
    this.destroyGaodeMap();
  }

  destroyGaodeMap = () => {
    this.map && this.map.destroy();
    this.map = null;
  };

  loadGaodeMap = () => {
    if (window.AMap && window.AMapUI && !this.map) {
      const { initialCenter } = this.props;
      const centerPoint = new window.AMap.LngLat(initialCenter.lng, initialCenter.lat, true);
      this.map = new window.AMap.Map(this.mapRef.current, {
        features: ['bg', 'road', 'building'],
        showLabel: false,
        resizeEnable: true,
        showIndoorMap: true,
        center: centerPoint
      });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.mapContainer}>
        <div ref={this.mapRef} className={classes.map} />
      </div>
    );
  }
}

export default withStyles(styles)(GaodeMap);
