import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Loader from 'react-loader-advanced';

import HlsPlayer from './HlsPlayer';
import { getText } from 'src/utils/MultilingualLoader'

const styles = theme => ({

});

class HlsPlayerController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //timeout for loading a camera
      loadingTimeout: null,
      //timeout for a delayed camera refresh
      refreshTimeout: null,
      videoLoading: 'loading'
    };
  }

  componentDidMount() {
    this.refreshWhenLoadingTimeout(false);
  }

  componentWillUnmount() {
    if (this.state.loadingTimeout) {
      clearTimeout(this.state.loadingTimeout);
    }
    if (this.state.refreshTimeout) {
      clearTimeout(this.state.refreshTimeout);
    }
  }

  refreshWhenLoadingTimeout = (needRefresh) => {
    if (this.state.loadingTimeout) {
      clearTimeout(this.state.loadingTimeout)
    }
    const { streamLoading, refreshCamera } = this.props;
    if (streamLoading) {
      if (needRefresh) {
        refreshCamera(this.props.refreshDelay);
      }
      this.setState({
        loadingTimeout: setTimeout(() => this.refreshWhenLoadingTimeout(true), 15000)
      });
    }
  }

  handleEnd = () => {
    console.log('handleEnd: ', this.props.streamTitle);
    this.delayedRefresh();
  };

  handleError = (e, data, hls, Hls) => {
    console.log('handleError: ', this.props.streamTitle, e, data);
    if (data) {
      if (data.fatal) {
        this.delayedRefresh();
        return;
      }
      switch (data.details) {
        case Hls.ErrorDetails.LEVEL_LOAD_ERROR:
        case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
        case Hls.ErrorDetails.MANIFEST_LOAD_TIMEOUT:
        case Hls.ErrorDetails.MANIFEST_PARSING_ERROR:
        case Hls.ErrorDetails.FRAG_LOAD_ERROR:
        case Hls.ErrorDetails.FRAG_LOAD_TIMEOUT:
          this.delayedRefresh();
          break;
        default:
          console.log('non-refresh error: ', e, data)
          break;
      }
    }
  };

  delayedRefresh = () => {
    if (!this.state.refreshTimeout) {
      console.log('refresh after: ', this.props.refreshDelay)
      this.setState({
        refreshTimeout: setTimeout(() => {
          this.props.refreshCamera(this.props.refreshDelay);
          this.refreshWhenLoadingTimeout(false);
          this.setState({
            refreshTimeout: null
          });
        }, this.props.refreshDelay)
      });
    }
  }

  forceRefresh = () => {
    this.props.refreshCamera(400);
    this.refreshWhenLoadingTimeout(false);
  }

  render() {
    const {
      streamURL,
      streamLoading,
      streamError,
      id,
      minHeight,
    } = this.props;

    const { videoLoading } = this.state;

    return (
      <Loader
        style={{ zIndex: 0, minHeight: minHeight }}
        show={streamLoading || streamError || videoLoading ? true : false}
        message={streamError ? streamError : streamLoading ? streamLoading : videoLoading}
        hideContentOnLoad
      >
        {streamURL &&
          <CardMedia src="video">
            <HlsPlayer
              key={id}
              onEnded={() => this.handleEnd()}
              onError={(e, data, hls, Hls) => this.handleError(e, data, hls, Hls)}
              onCanPlay={() => this.setState({ videoLoading: null })}
              hlsOptions={{
                // debug: true,
                fragLoadingMaxRetry: 0,
                xhrSetup: function (xhr, url) {
                  xhr.withCredentials = true // send cookies
                }
              }}
              url={streamURL}
            />
          </CardMedia>
        }
      </Loader>
    );
  }
};

HlsPlayerController.propTypes = {
  classes: PropTypes.object.isRequired,
  streamURL: PropTypes.string,
  streamLoading: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]).isRequired,
  streamError: PropTypes.any,
};

HlsPlayerController.defaultProps = {
  streamLoading: getText('preparing'),
  streamError: false,
};

export default withStyles(styles)(HlsPlayerController);
