export const GET_CASES_REQUEST = 'CASE_GET_CASES_REQUEST';
export const GET_CASES_SUCCESS = 'CASE_GET_CASES_SUCCESS';
export const GET_CASES_FAILURE = 'CASE_GET_CASES_FAILURE';

export const GET_MORE_CASES_REQUEST = 'CASE_GET_MORE_CASES_REQUEST';
export const GET_MORE_CASES_SUCCESS = 'CASE_GET_MORE_CASES_SUCCESS';
export const GET_MORE_CASES_FAILURE = 'CASE_GET_MORE_CASES_FAILURE';

export const GET_NEW_CASES_REQUEST = 'CASE_GET_NEW_CASES_REQUEST';
export const GET_NEW_CASES_SUCCESS = 'CASE_GET_NEW_CASES_SUCCESS';
export const GET_NEW_CASES_FAILURE = 'CASE_GET_NEW_CASES_FAILURE';

export const SET_NEW_CASE_NOTIFICATION = 'CASE_SET_NEW_CASE_NOTIFICATION';

export const UPDATE_CASE_REQUEST = 'CASE_UPDATE_CASE_REQUEST';
export const UPDATE_CASE_SUCCESS = 'CASE_UPDATE_CASE_SUCCESS';
export const UPDATE_CASE_FAILURE = 'CASE_UPDATE_CASE_FAILURE';

export const DELETE_CASE_SUCCESS = 'CASE_DELETE_CASE_SUCCESS';

export const SELECT_CASE = 'CASE_SELECT_CASE';

export const SET_PAGE = 'CASE_SET_PAGE';
export const SET_FILTER = 'CASE_SET_FILTER';
export const SET_LOCAL_FILTER = 'CASE_SET_LOCAL_FILTER';

export const GET_CAMERAS_REQUEST = 'CASE_GET_CAMERAS_REQUEST';
export const GET_CAMERAS_SUCCESS = 'CASE_GET_CAMERAS_SUCCESS';
export const GET_CAMERAS_FAILURE = 'CASE_GET_CAMERAS_FAILURE';
export const SELECT_CAMERA = 'CASE_SELECT_CAMERA';

export const GET_TYPES_REQUEST = 'CASE_GET_TYPES_REQUEST';
export const GET_TYPES_SUCCESS = 'CASE_GET_TYPES_SUCCESS';
export const GET_TYPES_FAILURE = 'CASE_GET_TYPES_FAILURE';

export const UPDATE_CAMERAS_FROM_WS = 'CASE_UPDATE_CAMERAS_FROM_WS';

export const FALSE_DETECTION = 'false_alarm';
export const NO_ACTION_NEEDED = 'no_harm';
export const CALL_POLICE = 'call_police';
export const DISPATCH_OFFICER = 'dispatch_guard';
export const UPDATE_PRIORITY = 'update_priority';
export const DISPATCH_ROBOT = 'dispatch_robot';
export const ACCEPT_CASE = 'accept_case';
export const START = 'start';
export const OFFICER_ARRIVED = 'officer_arrived';
export const RESOLVE_CASE = 'resolve_case';
export const ADD_NOTE = 'add_note';
export const CLOSE_CASE = 'close_case';
export const VIEW_CASE = 'view_case';
export const CREATE_CASE = 'create_case';
export const UPDATE_TYPES = 'update_types';
export const NOTIFY_CUSTOMER = 'notify_customer';

