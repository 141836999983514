import React from 'react';
import { connect } from 'react-redux';

import InspectionSwitch from './InspectionSwitch';
import { inspectionActions } from 'src/redux/actions';

const mapStateToProps = state => {
  const { selectedRobot, showType, filters } = state.inspection;
  return { selectedRobot, filters, showType };
};

const mapDispatchToProps = dispatch => {
  return { 
    setShowType: showType => dispatch(inspectionActions.setShowType(showType)),
    getInspections: (filters, loadAll) => dispatch(inspectionActions.getInspections(filters, loadAll))
   };
};

class InspectionSwitchContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showType: props.showType
    };
  }

  componentDidUpdate(prevProps) {
    const { getInspections, selectedRobot, filters, showType } = this.props;
    if (prevProps.showType === 'list' && this.props.showType === 'map') {
      getInspections({...filters, robotId: selectedRobot.id}, showType === 'map');
    }
  }

  handleSwitch = event => {
    const showType = event.target.checked ? 'map' : 'list';
    this.setState({ showType }, () => this.props.setShowType(showType));
  }

  render() {
    const { showType } = this.state;

    return (
        <InspectionSwitch
          showType={showType}
          onSwitch={this.handleSwitch}
        />
    );
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InspectionSwitchContainer);
